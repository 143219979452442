.VideoSection {
  padding: 120px 48px;
  background: #0a1521;
  .subTitle {
    color: #fff;
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    text-align: center;
  }
  .title {
    color: #fff;
    font-size: 44px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 32px;
    margin-top: 16px;
  }
  .speech {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    .verticalLine {
      width: 1px;
      height: 140px;
      background-color: #808890;
    }
    p {
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
      text-align: center;
      color: #858d95;
      margin-bottom: 24px;
      margin-top: 32px;
    }

    img {
      margin: 0 auto;
      width: 278px;
      max-width: 100%;
    }
  }
}
.videoWrap {
  .btnVideoPlay {
    margin-top: 36px;
  }
  .videoBox {
    margin-top: 36px;
    width: 100%;
    max-width: 640px;
    aspect-ratio: 16 / 9;
    position: relative;
    margin: 0 auto;
    iframe {
      margin-top: 36px;
      width: 100%;
      height: 100%;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
@media all and (max-width: 750px) {
  .VideoSection {
    padding: 16vw 6.4vw;

    .subTitle {
      font-size: 3.7333vw;
      line-height: 4.5333vw;
    }
    .title {
      font-size: 5.8667vw;
      line-height: 7.4667vw;
      letter-spacing: -0.0667vw;
      margin-bottom: 4.2667vw;
      margin-top: 2.1333vw;
    }
    .speech {
      .verticalLine {
        width: 0.1333vw;
        height: 18.6667vw;
      }
      p {
        font-size: 3.7333vw;
        line-height: 4.5333vw;
        margin-bottom: 3.2vw;
        margin-top: 4.2667vw;
      }

      img {
        width: 37.0667vw;
      }
    }
  }
  .videoWrap {
    .btnVideoPlay {
      margin-top: 4.8vw;
    }
    .videoBox {
      margin-top: 4.8vw;

      iframe {
        margin-top: 4.8vw;
        width: 100%;
        height: 100%;
      }
    }
  }
}
