@import './variables.scss';

.container {
  position: relative;
  width: 100%;
  height: 858px;
  div {
    box-sizing: border-box;
  }
}
.inner {
  max-width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 90px 0 116px;
  box-sizing: border-box;
  z-index: 4;
}
.videoInner {
  max-width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  overflow: hidden;
  padding: 90px 0 116px;
  box-sizing: border-box;
  z-index: 2;
}
.video {
  position: absolute;
  right: -75px;
  top: 50%;
  transform: translate(0, -50%);
  margin-top: -20px;
  z-index: 2;
  width: 604px;
  video {
    width: 100%;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    background: linear-gradient(0deg, #fff 20%, rgba(255, 255, 255, 0) 100%);
  }
  &:after {
    content: '';
    display: inline-block;
    width: 960px;
    height: 856px;
    position: absolute;
    top: 148px;
    right: -260px;
    background: url($HOST_CDN + 'image-pc-video-dim.png') no-repeat center center;
    background-size: contain;
    z-index: 1;
  }
}
.bg {
  display: inline-block;
  width: 100%;
  height: 874px;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
  background: url($HOST_CDN + 'bg-pc-gradation.png') no-repeat center center;
  background-size: cover;
  z-index: 3;
  min-width: 1200px;
}
.left {
  max-width: 930px;
  position: relative;
  z-index: 33;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 40px;
}
.subTitle {
  color: #324053;
  font-size: 20px;
  letter-spacing: -0.6px;
  text-align: center;
  b {
    font-weight: 700;
  }
}
.title {
  color: #030617;
  font-size: 54px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -2.16px;
  margin-top: 8px;
  text-align: center;
}
.description {
  width: 732px;
  padding: 14px 0;
  margin: 52px auto 0;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    border-radius: 10px;
    opacity: 0.4;
    background: linear-gradient(
      90deg,
      rgba(255, 81, 0, 0) 0%,
      #e75318 9.5%,
      #1767e8 91%,
      rgba(0, 105, 255, 0) 100%
    );
  }
  &:after {
    top: auto;
    bottom: 0;
  }
  p {
    background: linear-gradient(90deg, #f30 9.8%, #0059ff 86.56%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
    background: linear-gradient(90deg, #f30 9.8%, #0059ff 86.56%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.cardImage {
  margin-top: 32px;
}
.sidebarContainer {
  margin: 0 auto;
  position: fixed;
  padding: 0 40px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  justify-content: flex-end;
  z-index: 6;
  box-sizing: border-box;
}
.sidebar {
  border-radius: 18px;
  border: 1px solid #aec8fd;
  background: #fff;
  width: 120px;
  height: 390px;
  flex-shrink: 0;
  padding: 22px 13px;
  box-sizing: border-box;
  z-index: 10;
  input {
    opacity: 0;
  }
  button {
    img {
      width: 44px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    gap: 14px;
    li {
      padding-top: 14px;
      + li {
        border-top: 1px solid rgba(206, 210, 218, 0.35);
      }
    }
  }
}
.sidebarTitle {
  color: #030617;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 10px;
  letter-spacing: -0.24px;
}
.name {
  margin-top: 6px;
  color: #506685;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.2px;
}
.sidebarBubble {
  position: absolute;
  top: -60px;
  right: 28px;
  width: 185px;
  max-width: 185px;
  animation: moveInOut 3s infinite;
}

.cardDescription {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--GrayScale-800---28284B, #28284b);
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  img {
    margin-right: 3px;
  }
}

@keyframes moveInOut {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0);
  }
}
