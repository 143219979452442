.footerMicGroup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 0 18px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

  .tooltip {
    position: absolute;
    bottom: 107px;
    left: 50%;
    width: 375px;
    height: 79px;
    text-align: center;
    margin-left: -187.5px;
    animation: dropdown 1s ease-out 4s forwards;

    &.hide {
      opacity: 0 !important;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .listeningTooltip {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 138px;
    text-align: center;

    .tooltipInner {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      max-width: 320px;
      padding: 12px 18px;
      box-sizing: border-box;
      border-radius: 10px;
      background: rgba(40, 40, 75, 0.9);
      text-align: left;
    }

    .loading {
      width: 16px;
      height: 16px;
      > div {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      flex: 1;
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;

      &.nowrap {
        white-space: nowrap;
      }
    }
  }

  .buttonMic {
    width: 115px;
    height: 115px;
    border-radius: 100%;
    position: relative;

    &.buttonLoading {
      display: flex;
      align-items: center;
      justify-content: center;
      .loading {
        width: 88.99px;
        height: 88.99px;
        border-radius: 50px;
        box-sizing: border-box;
        padding: 13px;
        pointer-events: none;
        background: linear-gradient(133deg, #fc7b6f 27.79%, #ff4f3f 84.99%);
        filter: drop-shadow(0px 4px 13px rgba(255, 92, 92, 0.25));
      }
    }

    svg {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }

  .controlButtons {
    position: relative;

    .buttonCancel,
    .buttonRestart {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      position: absolute;
      top: 50%;
    }
    .buttonCancel {
      width: 62px;
      height: 62px;
      color: #fff;
      background-color: #a0a0b6;
      left: -92px;
      margin-top: -31px;
    }
    .buttonRestart {
      width: 80px;
      height: 80px;
      color: #717188;
      background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/bg_re_recorder.svg');
      background-size: 74px 74px;
      background-repeat: no-repeat;
      background-position: center;
      right: -100px;
      margin-top: -40px;

      span {
        margin-top: 6px;
      }
    }
    .buttonMic {
      position: relative;
      &::before {
        display: block;
        width: 82px;
        height: 82px;
        border-radius: 100%;
        animation: pulse-animation 2s infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -41px 0 0 -41px;
        content: '';
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 11px 0 23px;

    .tooltip {
      bottom: 163px;
      width: 413px;
      height: 87px;
      margin-left: -206.5px;
    }

    .listeningTooltip {
      bottom: 202px;

      .tooltipInner {
        padding: 22px 26px;
        max-width: 620px;
        border-radius: 10px;
        gap: 10px;
      }

      .loading {
        width: 32px;
        height: 32px;
      }

      .text {
        font-size: 26px;
        font-weight: 500;
        line-height: 32px;
      }
    }

    .buttonMic {
      width: 168px;
      height: 168px;
      &.buttonLoading {
        .loading {
          width: 130px;
          height: 130px;
          border-radius: 70px;
          padding: 25px;
        }
      }
    }

    .controlButtons {
      gap: 42px;

      .buttonCancel,
      .buttonRestart {
        border-radius: 60px;
        font-size: 20px;
        line-height: 26px;
      }
      .buttonCancel {
        width: 84px;
        height: 84px;
        left: -126px;
        margin-top: -42px;
      }
      .buttonRestart {
        width: 102px;
        height: 102px;
        background-size: 84px;
        right: -135px;
        margin-top: -51px;

        span {
          margin-top: 4px;
        }
      }
      .buttonMic {
        &::before {
          width: 130px;
          height: 130px;
          margin: -65px 0 0 -65px;
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 1.467vw 0 3.067vw;

    .tooltip {
      bottom: 21.733vw;
      width: 55.067vw;
      height: 11.6vw;
      margin-left: -27.533vw;
    }

    .listeningTooltip {
      bottom: 26.933vw;

      .tooltipInner {
        gap: 1.333vw;
        padding: 2.933vw 3.467vw;
        border-radius: 1.333vw;
        max-width: 82.667vw;
      }

      .loading {
        width: 4.267vw;
        height: 4.267vw;
      }

      .text {
        font-size: 3.467vw;
        line-height: 4.267vw;
      }
    }

    .buttonMic {
      width: 22.4vw;
      height: 22.4vw;
      &.buttonLoading {
        .loading {
          width: 17.333vw;
          height: 17.333vw;
          border-radius: 100%;
          padding: 3.333vw;
        }
      }
    }

    .controlButtons {
      gap: 8vw;

      .buttonCancel,
      .buttonRestart {
        border-radius: 8vw;
        font-size: 2.667vw;
        line-height: 3.467vw;
      }
      .buttonCancel {
        width: 11.2vw;
        height: 11.2vw;
        left: -16.8vw;
        margin-top: -5.6vw;
      }
      .buttonRestart {
        width: 13.6vw;
        height: 13.6vw;
        background-size: 11.2vw;
        right: -18vw;
        margin-top: -6.8vw;

        span {
          margin-top: 0.533vw;
        }
      }
      .buttonMic {
        &::before {
          width: 17.333vw;
          height: 17.333vw;
          margin: -8.667vw 0 0 -8.667vw;
        }
      }
    }
  }
}

@keyframes dropdown {
  0% {
    opacity: 1;
  }
  100% {
    bottom: 87px;
    opacity: 0;
    display: none;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(5, 170, 255, 0);
  }
  50% {
    box-shadow: 0 0 0 17px rgba(5, 170, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(5, 170, 255, 0);
  }
}

@media screen and (max-width: 1024px) {
  @keyframes dropdown {
    0% {
      opacity: 1;
    }
    100% {
      bottom: 143px;
      opacity: 0;
      display: none;
    }
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(5, 170, 255, 0);
    }
    50% {
      box-shadow: 0 0 0 20px rgba(5, 170, 255, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0px rgba(5, 170, 255, 0);
    }
  }
}

@media screen and (max-width: 750px) {
  @keyframes dropdown {
    0% {
      opacity: 1;
    }
    100% {
      bottom: 19.067vw;
      opacity: 0;
      display: none;
    }
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(5, 170, 255, 0);
    }
    50% {
      box-shadow: 0 0 0 2.667vw rgba(5, 170, 255, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0px rgba(5, 170, 255, 0);
    }
  }
}
