.inner {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.btnWrap {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.5px;
  text-align: center;
  button {
    padding: 36px 101px;
    border-radius: 18px;
  }
  .lv1 {
    background-color: #ff4635;
  }

  .lv2 {
    background-color: #ff7700;
  }

  .lv3 {
    background-color: #06c000;
  }

  .lv4 {
    background-color: #00bab7;
  }

  .lv5 {
    background-color: #107dff;
  }

  .lv6 {
    background-color: #9c46ff;
  }
}
@media all and (max-width: 750px) {
  .inner {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    font-size: 2.1333vw;
    ul {
      margin-top: 2.6667vw;
      li {
        margin-top: 1.3333vw;
      }
    }
  }
  .btnWrap {
    font-size: 4.2667vw;

    line-height: 5.3333vw;
    letter-spacing: -0.0667vw;

    button {
      padding: 4.8vw 13.4667vw;
      border-radius: 2.4vw;
    }
  }
}
