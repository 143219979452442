.myCouponBox {
  padding: 1.7rem 0;
  span {
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 1.6rem;
    color: #000;
    em {
      font-weight: 700;
      color: #269cff;
    }
    & + span {
      padding-left: 1.3rem;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0.5rem;
        bottom: 0;
        width: 0.1rem;
        height: 1rem;
        margin: auto;
        border-right: 0.1rem solid #d4d4d4;
      }
    }
  }
}

.btnDownload {
  position: relative;
  height: 3.6rem;
  margin-left: 3rem;
  padding: 0.2rem 4rem 0 1.5rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #269cff;
  background: #fff
    url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_button_download.png)
    no-repeat calc(100% - 1rem) 50% / 1.7rem auto;
  border: 0.1rem solid #269cff;
  border-radius: 0.3rem;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    height: 5.2rem;
    padding: 0.2rem 5.4rem 0 2rem;
    font-size: 2rem;
    border: 0.2rem solid #269cff;
    border-radius: 0.6rem;
    background: #fff
      url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_button_download.png)
      no-repeat calc(100% - 1rem) 50% / 2.5rem auto;
  }
}

.applyCouponState {
  p {
    font-weight: 700;
    font-size: 1.2rem;
    color: #a0a0b6;
    line-height: 1.4;
    text-align: center;
    em {
      color: #269cff;
    }
  }
  @media screen and (max-width: 1024px) {
    p {
      min-height: 7.2rem;
      padding: 2.2rem 0;
      font-size: 2rem;
      background-color: #ececf6;
      box-sizing: border-box;
      border-radius: 0.5rem;
    }
  }
}

.toggleCouponList {
  display: none;
  padding: 5rem 4.2rem;
  background-color: #f7f7fc;
  &.active {
    display: block;
  }
  h4 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }
  li {
    padding: 4rem 0 3rem;
    .productItem {
      display: flex;
      margin-bottom: 3.4rem;
      .itemInfo {
        p {
          font-weight: 400;
          font-size: 2.2rem;
          text-align: left;
          strong {
            width: 100%;
            font-weight: 700 !important;
            font-size: 2.7rem !important;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            em {
              font-weight: 400;
              font-size: 2.4rem;
              color: #ff5647;
            }
          }
        }
      }
    }
  }
}

.textBookBox {
  position: relative;
  width: 100%;
  margin-top: -0.1rem;
  padding: 3.2rem 4.2rem;
  background-color: #f7f7fc;
  box-sizing: border-box;
  dl {
    dt {
      padding-bottom: 1.6rem;
      font-weight: 700;
      font-size: 1.6rem;
      color: #000;
      text-align: left;
      border-bottom: 0.1rem solid #e8e8f2;
    }
    dd {
      ul {
        li {
          border-bottom: 0.1rem solid #e8e8f2;
          &:last-child {
            border-bottom: 0;
          }
          label {
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 2rem 0 !important;
            input:checked + span {
              &:before {
                background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_active_96x96.png)
                  no-repeat 50% 50% / 100% auto;
                border: 0;
              }
            }
            span {
              display: inline-block;
              position: relative;
              width: 2.4rem;
              height: 2.4rem;
              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                border: 0.1rem solid #cfcfda;
                border-radius: 100%;
                box-sizing: border-box;
              }
              & + strong {
                margin-left: 1.1rem;
              }
            }
            .imgBox {
              margin: 0px 1.6rem 0px 1.1rem;
              width: 8rem;
              height: 5.5rem;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
              border-radius: 0.4rem;
            }
            input:checked + span + strong,
            input:checked + span + img + strong {
              font-weight: 700;
            }
            strong {
              display: inline-block;
              flex: 1 auto;
              font-weight: 400;
              font-size: 1.6rem;
              color: #000;
              text-align: left;
            }
            em {
              font-weight: 700;
              font-size: 1.8rem;
              color: #000;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    width: calc(100% + 8.4rem);
    margin: -4.7rem 0 -5rem -4.2rem;
    padding: 4.2rem 4rem 0;
    dl {
      dt {
        padding-bottom: 2.4rem;
        font-size: 2.4rem;
      }
      dd {
        ul {
          li {
            label {
              padding: 3.6rem 0 !important;
              span {
                width: 4.8rem;
                height: 4.8rem;
              }
              .imgBox {
                margin: 0 2.4rem;
                width: 11.8rem;
                height: 8rem;
              }

              strong {
                font-size: 2.4rem;
                color: #28284b;
                em {
                  font-size: 2.8rem;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}

.deliverySelectBox {
  position: relative;
  width: 62.8rem;
  padding: 0 !important;
  z-index: 2;
  select {
    width: 100%;
    padding: 1.4rem 1.5rem;
    outline: 0;
    border: 0.1rem solid #e7e7f0;
    border-radius: 0.2rem;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 1.6rem;
    color: #b7b7c8;
    background-color: transparent;
    &.bold {
      color: #000;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 1.4rem;
    bottom: 0;
    width: 0.5rem;
    height: 0.5rem;
    margin: auto;
    border-bottom: 0.1rem solid #000;
    border-left: 0.1rem solid #000;
    transform: rotate(-45deg);
    z-index: -1;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 1.6rem 0 0 0 !important;
    select {
      padding: 2.8rem 2.5rem;
      font-size: 2.9rem;
      border: 0.2rem solid #e7e7f0;
    }
    &:after {
      top: 0.4rem;
      right: 3.5rem;
      width: 1.2rem;
      height: 1.2rem;
      border-bottom: 0.2rem solid #000;
      border-left: 0.2rem solid #000;
    }
  }
}

.deliveryInputBox {
  position: relative;
  width: 62.8rem;
  margin-top: 1.2rem;
  z-index: 2;
  input {
    width: 100%;
    padding: 1.4rem 1.5rem;
    outline: 0;
    border: 0.1rem solid #e7e7f0;
    border-radius: 0.2rem;
    box-sizing: border-box;
    &::placeholder {
      font-weight: 400;
      font-size: 1.6rem;
      color: #b7b7c8;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    input {
      padding: 2.8rem 2.5rem !important;
      font-size: 2.9rem;
      border: 0.2rem solid #e7e7f0 !important;
      &::placeholder {
        font-size: 2.9rem;
      }
    }
  }
}

.buttonBlue {
  width: 20rem;
  height: 4.3rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #fff;
  background-color: #269cff;
  border-radius: 0.3rem;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 7.2rem;
    font-size: 2.2rem;
    border-radius: 0.4rem;
  }
}

.flexTd {
  display: flex;
  & > div:first-child {
    flex: 1 auto;
  }
}

.couponResultBox {
  display: block;
  width: 24rem;
  height: 100%;
  padding-top: 1.4rem;
  text-align: center;
  flex-shrink: 0;
  em {
    display: inline-block;
    margin-bottom: 0.6rem;
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
    color: #ff5647;
    background-color: #fddfdf;
    border-radius: 0.2rem;
  }
  strong {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    color: #ff5647;
  }
  @media screen and (max-width: 1024px) {
    display: block;
    width: 100%;
    text-align: left;
    padding-top: 0;
    em {
      margin: 0 1.5rem 0 0 !important;
      padding: 0.4rem 1rem !important;
      font-weight: 700;
      color: #ff5647 !important;
      border-radius: 0.4rem;
    }
    strong {
      font-size: 2.4rem !important;
      color: #ff5647 !important;
    }
  }
}

.totalDiscountCouponBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 9.4rem;
  text-align: right;
  strong {
    display: inline-block;
  }
  em {
    display: inline-block;
    margin: 0 4.6rem 0 1.5rem;
    font-weight: 700;
    font-size: 2.6rem;
    color: #000;
    letter-spacing: 0.1rem;
    &:after {
      content: '원';
      font-size: 2.2rem;
    }
  }
  @media screen and (max-width: 1024px) {
    height: 13.4rem;
    margin: 0 -4.2rem -5rem -4.2rem;
    padding: 0 4.2rem;
    strong {
      font-size: 3rem;
      font-weight: 700;
    }
    em {
      flex: 1 auto;
      margin: 0;
      font-weight: 700;
      font-size: 2.6rem;
      color: #28284b;
      &:after {
        font-size: 2.6rem;
      }
    }
    background-color: #fff;
  }
}

.couponStateBox {
  flex-shrink: 0;
  display: block;
  min-width: 100%;
  padding-top: 0.5rem;
  dl {
    dd {
      display: flex;
      align-items: flex-end;
      padding-top: 0.5rem;
      strong {
        font-weight: 400;
        flex: 1 auto;
        span {
          font-size: 2.6rem;
          font-weight: 400;
          &:before {
            content: '' !important;
          }
          & + span {
            display: inlnie-block;
            position: relative;
            margin-left: 1rem;
            padding-left: 1.2rem;
            font-weight: 400 !important;
            &:before {
              content: '' !important;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 0.1rem;
              height: 2rem;
              margin: auto;
              border-right: 0.1rem solid #d4d4d4;
            }
            &:after {
              display: none;
            }
          }
          em {
            font-weight: 700;
            color: #269cff;
          }
        }
      }
    }
  }
}

.hideIframe {
  display: none;
}
