.modalWrap {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
  padding: 57px 17px 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999999999;
  box-sizing: border-box;
}
.closeButton {
  position: absolute;
  top: 9px;
  right: 9px;
  width: 36px;
  img {
    width: 100%;
  }
}
.title {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.53px;
  font-weight: 700;
  text-align: center;
}
.homeButton {
  margin-top: 43px;
  border: 1px solid #e7e7f0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  .logo {
    width: 99px;
    flex-shrink: initial;
  }
  .more {
    display: flex;
    align-items: center;
    color: #28284b;
    font-size: 12px;
    img {
      width: 12px;
      margin-left: 3px;
    }
  }
}
.linkList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 43px;
  .button {
    a {
      display: block;
      background: #f7f7fc;
      border-radius: 10px;
      border: 1px solid #e7e7f0;
      padding: 15px 15px 16px 16px;
      text-align: left;
      height: 140px;
      box-sizing: border-box;
    }
    .symbol {
      width: 40px;
      margin: 0 0 0 auto;
      display: block;
    }
    .name {
      margin-top: 14px;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    .description {
      margin-top: 5px;
      color: #717188;
      font-size: 12px;
      line-height: 15px;
      white-space: pre-line;
      word-break: keep-all;
      font-weight: 400;
      letter-spacing: -0.2px;
    }
  }
}
@media screen and (min-width: 751px) {
  .modalWrap,
  .modal {
    display: none;
  }
}
