.reviewTitleBar {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #dfdfea;

  h2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 16px;
  }

  @media screen and (min-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
