.charItem {
  position: relative;
}
.charInfo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/dimmed.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.charInfoBox {
  padding-bottom: 183px;
}
.charInfoBox dt {
  color: #fff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.charInfoBox dd {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}
.charInfoBox ul {
  margin-top: 7px;
}
.charInfoBox ul li {
  color: var(--GrayScale-0---FFFFFF, #fff);

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  margin: 10px 5px 0;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.4);
}
.charBtnBox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
}
.charBtnBox .charBtn {
  display: block;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 251px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #ffb900;
  margin: 60px auto 0;
  position: relative;
}
.charBtnBox .btnViewInfo {
  color: #e7e7f0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.5px;
  text-decoration-line: underline;
  display: inline-flex;
  padding: 14px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 7px auto 8px;
}
.charHover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(37px);
  z-index: 3;
  text-align: center;
}
.charHover p {
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-top: 95px;
  margin-bottom: 38px;
}
.charHover span {
  color: #ffb900;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.charHoverInner {
  padding: 0 23px;
}
.charHover.hoverView {
  display: flex;
}
.hoverClose {
  color: #e7e7f0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.5px;
  text-decoration-line: underline;
  display: inline-flex;
  padding: 14px 22px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}
.lottieBubble {
  width: 104px;
  height: 47px;
  position: absolute;
  top: -36px;
  left: 50%;
  margin-left: -52px;
}

.lottieBubble > div {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .charInfoBox {
    padding-bottom: 229px;
  }
  .charInfoBox dt {
    text-align: center;
    font-size: 28px;
    margin-bottom: 19px;
  }
  .charInfoBox dd {
    font-size: 20px;
    line-height: 24px;
  }
  .charInfoBox ul {
    margin-bottom: 23px;
  }
  .charInfoBox ul li {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    padding: 4px 12px;
  }
  .charBtnBox .charBtn {
    font-size: 26px;
    line-height: 38px;
    width: 353px;
    height: 74px;
    margin: 80px auto 0;
  }
  .charBtnBox .btnViewInfo {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.5px;
    margin: 16px auto 34px;
  }
  .charHover p {
    font-size: 23px;
    font-weight: 400;
    line-height: 47px;
    margin-top: 70px;
    margin-bottom: 34px;
  }
  .charHover span {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
  }
  .charHoverInner {
    padding: 0 15px;
  }
  .charHover.hoverNotView {
    display: none;
  }
  .hoverClose {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.5px;
    display: inline-flex;
    padding: 14px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    bottom: 34px;
  }
  .lottieBubble {
    width: 158px;
    height: 71px;
    top: -52px;
    margin-left: -79px;
  }
}
@media screen and (max-width: 750px) {
  .charInfoBox {
    padding-bottom: 30.533vw;
  }
  .charInfoBox dt {
    text-align: center;
    font-size: 3.733vw;
    margin-bottom: 2.533vw;
  }
  .charInfoBox dd {
    font-size: 2.667vw;
    line-height: 3.2vw;
  }
  .charInfoBox ul {
    margin-bottom: 3.067vw;
  }
  .charInfoBox ul li {
    font-size: 2.667vw;
    line-height: 3.733vw;
    padding: 0.533vw 1.6vw;
    margin: 1.333vw 0.267vw 0;
  }
  .charBtnBox .charBtn {
    font-size: 3.467vw;
    line-height: 5.067vw;
    width: 47.067vw;
    height: 9.867vw;
    margin: 10.667vw auto 0;
  }
  .charBtnBox .btnViewInfo {
    font-size: 2.933vw;
    line-height: 3.733vw;
    letter-spacing: -0.067vw;
    margin: 2.133vw auto 4.533vw;
  }
  .charHover {
    border-radius: 5.333vw;
  }
  .charHover p {
    font-size: 3.067vw;
    line-height: 6.267vw;
    margin-top: 9.333vw;
    margin-bottom: 4.533vw;
  }
  .charHover span {
    font-size: 3.333vw;
    line-height: 5.067vw;
  }
  .charHoverInner {
    padding: 0 2vw;
  }
  .hoverClose {
    font-size: 2.933vw;
    line-height: 3.733vw;
    letter-spacing: -0.067vw;
    padding: 1.867vw 2.933vw;
    gap: 1.333vw;
    bottom: 1.067vw;
  }
  .lottieBubble {
    width: 21.067vw;
    height: 9.467vw;
    top: -6.933vw;
    margin-left: -10.533vw;
  }
}
