$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';

.csSearch {
  .searchInner {
    background-color: #fff;
    background-image: url('#{$img-cdn}img_cs_main.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 187px;
  }
  h3 {
    color: #000;
    font-size: 38px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -1px;
  }
}

@media screen and (max-width: 1280px) {
  .csSearch {
    .searchInner {
      padding: 0 40px;
      background-position: right 30px top;
    }

    .csSearchForm {
      margin-top: 36px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .csSearch {
    .searchInner {
      padding: 251px 40px 0;
      background-position: center top;
      min-height: auto;
    }

    h3 {
      font-size: 48px;
      line-height: 1.3;
      letter-spacing: -0.5px;
      text-align: center;
      height: 56px;
      &::before {
        display: none;
      }
    }

    .csSearchForm {
      gap: 25px;
      max-width: 100%;
      margin-top: 36px;
      margin-bottom: 24px;
      padding-left: 25px;

      svg {
        width: 40px;
      }

      input[type='text'] {
        height: 100px;
        font-size: 28px;
        line-height: 34px;
        &::placeholder {
          font-size: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .csSearch {
    .searchInner {
      padding: 33.467vw 5.333vw 0;
      background-size: 28.667vw;
    }
    h3 {
      font-size: 6.4vw;
      letter-spacing: -0.067vw;
      text-align: center;
      height: 7.467vw;
    }
    .csSearchForm {
      gap: 3.333vw;
      margin-top: 4.8vw;
      margin-bottom: 3.2vw;
      padding-left: 3.333vw;
      border-radius: 1.333vw;

      svg {
        width: 5.333vw;
      }

      input[type='text'] {
        height: 13.333vw;
        font-size: 3.733vw;
        line-height: 4.533vw;
        &::placeholder {
          font-size: 3.733vw;
        }
      }
    }
  }
}
