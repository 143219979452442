.skeletonList {
  display: block;
  width: 100%;
  max-width: 120rem;
  margin: 3rem auto;
  padding: 0 3rem;
  box-sizing: border-box;

  h1 {
    width: 40rem;
    margin-bottom: 2rem;
    font-size: 3rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: calc(25% - 1rem);
      padding: 1rem 0;
      box-sizing: border-box;
    }
  }

  @media all and (max-width: 1024px) {
    ul {
      li {
        width: calc(50% - 1rem);
      }
    }
  }
}
