.wrap {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999999;
  &.event {
    position: static;
    background-color: #030709;

    .modal {
      position: static;
      transform: none;
      margin-inline: auto;
    }
  }
}

.dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal {
  background-color: #fff;
  position: absolute;
  width: 550px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow-y: auto;
}

.inner {
  padding: 37px 30px 0 30px;
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -1.76px;
  margin-bottom: 37px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 26px 0;

  li {
    display: grid;
    grid-template-columns: 135px 1fr;
    align-items: center;
  }

  label {
    cursor: pointer;
    font-size: 20px;
    letter-spacing: -0.8px;
  }

  input {
    &[type='text'],
    &[type='tel'] {
      flex: 1;
      width: 100%;
      height: 53px;
      padding-inline: 16px;
      font-size: 18px;
      background-color: #fff;
      border: 1px solid #dcdcdc;
      box-sizing: border-box;
    }

    &[type='text']::placeholder,
    &[type='tel']::placeholder {
      color: #b7b7c8;
    }
  }

  select {
    display: block;
    width: 100%;
    height: 53px;
    padding-inline: 16px;
    font-size: 18px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    width: 100%;
    font-size: 18px;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_63x63_b.png)
      no-repeat 95% 50% / 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }
}

.agree {
  margin-top: 20px;
  margin-left: 135px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;

  li:nth-of-type(1) {
    font-weight: 600;
    font-size: 20px;
  }

  li:nth-of-type(2) {
    border-top: 1px solid #c1c1c1;
    padding-top: 13px;
  }

  label {
    display: block;
    font-size: 18px;
    letter-spacing: -1px;
    text-align: left;
    cursor: pointer;

    input[type='checkbox'] {
      width: 0;
      padding: 0;
      margin: 0;
      border: 0;

      + em {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        vertical-align: middle;
        cursor: pointer;
        background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png)
          no-repeat 42% 50% / 15px auto;
        border-radius: 50%;
        border: 1px solid #cfcfd8;
      }

      &:checked + em {
        background-color: #ffb800;
        border-color: #ffb800;
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/jan/ico_check.svg);
      }
    }

    button {
      color: #0166ff;
      margin-left: 8px;
    }
  }
}

.submitButton {
  bottom: 0;
  display: block;
  font-size: 28px;
  font-weight: 700;
  height: 78.333px;
  left: 0;
  letter-spacing: -1px;
  outline: 0;
  text-align: center;
  width: 100%;
  z-index: 2;

  svg {
    width: 20px;
    margin-left: 8px;
  }
}

@media all and (max-width: 750px) {
  .wrap.event {
    .modal {
      width: 100%;
    }
  }

  .modal {
    width: 73.333vw;
  }

  .inner {
    padding: 4.933vw 4vw 0 4vw;
  }

  .title {
    font-size: 4.8vw;
    letter-spacing: -0.235vw;
    margin-bottom: 4.933vw;
  }

  .fields {
    gap: 3.467vw 0;

    li {
      grid-template-columns: 18vw 1fr;
    }

    label {
      font-size: 2.667vw;
      letter-spacing: -0.107vw;
    }

    input {
      &[type='text'],
      &[type='tel'] {
        height: 7.067vw;
        padding-inline: 2.133vw;
        font-size: 2.4vw;
        border: 1px solid #dcdcdc;
      }
    }

    select {
      height: 7.067vw;
      padding-inline: 2.133vw;
      font-size: 2.4vw;
      border: 1px solid #dcdcdc;
      font-size: 2.4vw;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_63x63_b.png)
        no-repeat 95% 50% / 1.733vw;
    }
  }

  .agree {
    margin-top: 2.667vw;
    margin-left: 18vw;
    gap: 2.133vw;
    margin-bottom: 4vw;

    li:nth-of-type(1) {
      font-size: 2.667vw;
    }

    li:nth-of-type(2) {
      border-top: 1px solid #c1c1c1;
      padding-top: 1.733vw;
    }

    label {
      font-size: 2.4vw;
      letter-spacing: -1px;

      input[type='checkbox'] {
        + em {
          width: 3.2vw !important;
          height: 3.2vw !important;
          margin-right: 1vw;
          border-radius: 50%;
          border: 1px solid #cfcfd8;
        }

        &:checked + em {
          background-size: 2vw;
        }
      }

      button {
        color: #0166ff;
        margin-left: 1.067vw;
      }
    }
  }

  .submitButton {
    bottom: 0;
    display: block;
    font-size: 3.733vw;
    font-weight: 700;
    height: 13.333vw;
    left: 0;
    letter-spacing: -1px;
    outline: 0;
    text-align: center;
    width: 100%;
    z-index: 2;

    svg {
      width: 2.667vw;
      margin-left: 1.067vw;
    }
  }
}
