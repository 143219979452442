.container {
  position: relative;
  min-height: 48rem;
}

.disM {
  display: none;
}
.disP {
  display: block;
}
.historyList {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 12px;
  filter: drop-shadow(2px 2px 56px rgba(0, 0, 0, 0.04));
}
.disabled {
  color: #cfcfda;
  pointer-events: none;
}
.packageListContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px 28px;
}
.infoContainer {
  flex: 1;
}
.classInfo {
  display: flex;
  align-items: center;
}

.classInfo .badge {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 5px 8px;
  border-radius: 20px;
  margin-right: 12px;
}
.classInfo .date::before {
  content: '·';
  margin: 0 4px;
  font-weight: 900;
}
.packageBadge {
  color: #ff5647;
  background-color: #ffe4e4;
}
.oneBadge {
  color: #f3a100;
  background-color: #fff4cb;
}
.classInfo .teacher,
.classInfo .date {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: #a0a0b6;
}
.packageListContainer .titleBox {
  display: flex;
  align-items: center;
}
.packageListContainer .toggleBox button {
  margin-left: 15px;
  width: 22px;
  height: 22px;
  transition: all 0.5s;
  position: sticky;
}

.packageListContainer .toggleBox .toggleUp {
  transform: rotate(0);
}
.packageListContainer .toggleBox .toggleDwn {
  transform: rotate(-180deg);
}
.packageListContainer .title {
  flex: 1;
}
.packageListContainer .title p {
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  margin: 19px 0 16px;
}
.packageTitle {
  cursor: pointer;
}
.classInfo2 {
  display: flex;
  align-items: center;
  gap: 38px;
}
.classInfo2 p {
  font-size: 14px !important;
  font-weight: 700;
}
.classInfo2 .progress {
  color: #ff5647;
}
.classInfo2 p::before {
  font-size: 12px;
  font-weight: 400;
  margin-right: 11px;
  color: #000;
}
.classInfo2 .dday::before {
  content: '남은 수강일';
}
.classInfo2 .attend::before {
  content: '출석일';
}
.classInfo2 .progress::before {
  content: '달성율';
}
.btnBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  margin-left: 80px;
}
.btnBox button {
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  height: 37px;
  border-radius: 3px;
  border: 1px solid #cfcfda;
  line-height: normal;
}
.classListBox {
  background-color: #fafafc;
  border-radius: 0 0 10px 10px;
}
.classListContainer:first-child {
  border-top: 1px solid #e7e7f0;
}
.classListContainer {
  display: flex;
  justify-content: space-between;
  padding: 25px 28px;
  border-top: 1px dashed #e7e7f0;
}

.classListContainer .title {
  font-size: 16px;
  font-weight: 500;
}
.classListContainer .classInfo {
  margin: 6px 0 16px;
}
.classListContainer .classBtnBox {
  display: flex;
  align-items: self-start;
  font-size: 14px;
  font-weight: 500;
  color: #269cff;
  gap: 35px;
}
.classListContainer .classBtnBox button {
  display: flex;
  align-items: center;
}
.classListContainer .classBtnBox button picture {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}
@media screen and (max-width: 1024px) {
  .disM {
    display: block;
  }
  .disP {
    display: none;
  }
  .container {
    min-height: auto;
  }
  .packageListContainer {
    flex-direction: column;
    padding: 50px 42px;
  }
  .historyList {
    background-color: #fff;
    border-radius: 15px;
    margin-bottom: 20px;
  }
  .classInfo .badge {
    font-size: 20px;
    padding: 11px 14px;
    line-height: 1;
    border-radius: 30px;
    margin-right: 12px;
  }
  .classInfo .teacher,
  .classInfo .date {
    font-size: 22px;
  }
  .packageListContainer .titleBox {
    align-items: flex-start;
  }
  .packageListContainer .title p {
    font-size: 33px;
    font-weight: 700;
    line-height: normal;
    margin: 19px 0 30px;
  }
  .packageListContainer .toggleBox button {
    margin-left: 20px;
    margin-top: 28px;
    margin-right: 12px;
    width: 36px;
    height: 36px;
  }
  .packageListContainer .toggleBox button::after {
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    height: 10px;
    left: 31%;
    width: 10px;
  }
  .packageListContainer .toggleBox .toggleUp::after {
    top: 9px;
  }
  .packageListContainer .toggleBox .toggleDwn::after {
    top: 14px;
  }
  .btnBox {
    flex-direction: row;
    justify-content: flex-start;
    gap: 13px;
    margin-left: 0;
  }

  .btnBox button {
    font-size: 26px;
    padding: 20px;
    width: auto;
    height: auto;
    border-radius: 3px;
    border: 1px solid #cfcfda;
  }
  .btnBox button.active {
    display: none;
  }
  .classListBox {
    border-radius: 0 0 15px 15px;
  }
  .classListContainer {
    flex-direction: column;
    padding: 50px 42px;
    gap: 26px;
    border-top: 2px dashed #e7e7f0;
  }
  .classListContainer:first-child {
    border-top: 2px solid #e7e7f0;
  }
  .classListContainer .title {
    font-size: 27px;
    line-height: 39.96px;
  }
  .classListContainer .classInfo {
    margin: 7px 0 16px;
    line-height: 39.96px;
  }
  .classListContainer .classBtnBox {
    font-size: 23px;
    gap: 32px;
  }
  .classListContainer .classBtnBox button picture {
    width: 32px;
    height: 32px;
    margin-right: 7px;
  }
}
@media screen and (max-width: 750px) {
  .packageListContainer {
    padding: 5.6vw 6.6667vw;
  }
  .historyList {
    border-radius: 2vw;
    margin-bottom: 2.6667vw;
  }
  .classInfo .badge {
    font-size: 2.6667vw;
    padding: 1.4667vw 1.8667vw;
    border-radius: 4vw;
    margin-right: 1.6vw;
  }
  .classInfo .teacher,
  .classInfo .date {
    font-size: 2.9333vw;
  }

  .packageListContainer .title p {
    font-size: 4.4vw;
    margin: 2.5333vw 0 4vw;
  }
  .packageListContainer .toggleBox button {
    margin-left: 2.6vw;
    margin-top: 3.7333vw;
    margin-right: 1.4vw;
    width: 4.6667vw;
    height: 4.6667vw;
  }
  .packageListContainer .toggleBox button::after {
    border-bottom: 0.4vw solid #fff;
    border-right: 0.4vw solid #fff;
    height: 1.3333vw;
    width: 1.3333vw;
  }
  .packageListContainer .toggleBox .toggleUp::after {
    top: 1.2vw;
  }
  .packageListContainer .toggleBox .toggleDwn::after {
    top: 1.8667vw;
  }
  .btnBox {
    gap: 1.7333vw;
  }

  .btnBox button {
    font-size: 3.4667vw;
    padding: 2.6667vw;
    width: auto;
    height: auto;
    border-radius: 0.4vw;
    border: 0.1333vw solid #cfcfda;
  }
  .classListBox {
    border-radius: 0 0 2vw 2vw;
  }
  .classListContainer {
    flex-direction: column;
    padding: 5.6vw 6.6667vw;
    gap: 3.4667vw;
    border-top: 0.2667vw dashed #e7e7f0;
  }
  .classListContainer:first-child {
    border-top: 0.2667vw solid #e7e7f0;
  }
  .classListContainer .title {
    font-size: 3.6vw;
    line-height: 5.328vw;
  }
  .classListContainer .classInfo {
    margin: 0.9333vw 0 2.1333vw;
    line-height: 5.328vw;
  }
  .classListContainer .classBtnBox {
    font-size: 3.0667vw;
    gap: 4.2667vw;
  }
  .classListContainer .classBtnBox button picture {
    width: 4.2667vw;
    height: 4.2667vw;
    margin-right: 0.9333vw;
  }
}

.empty > div {
  padding: 0 !important;
}

@media all and (max-width: 1024px) {
  .empty {
    padding: 24rem 0 20rem !important;
  }
}
