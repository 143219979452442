.loadingBox {
  width: calc(100% - 10rem);
  position: relative;
  height: 40rem;

  @media all and (max-width: 1024px) {
    width: 100%;
    height: 50rem;
  }
}

.empty {
  > div {
    padding: 0 !important;
  }

  @media all and (max-width: 1024px) {
    padding: 24rem 2.8rem !important;
  }
}
