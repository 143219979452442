.searchNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  height: 60px;

  button {
    color: #8d8da0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    transition: color 0.3s;
    margin-top: 2px;

    &:hover {
      color: #000;
    }

    &:last-child {
      color: #269cff;
      &:hover {
        color: #0c68b5;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .searchNav {
    height: auto;
  }
}
