@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.modal-wrap {
  &.login {
    .modal-inner {
      height: 100%;
    }

    .modal-header {
      display: none;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 100%;
    }

    h3 {
      flex: 0 1 auto;
      width: 100%;
      height: 5.9rem;
      padding-top: 9.8rem;
      background: url('#{$img-cdn}/img_logo_744x177.png') no-repeat 50% 100% / 24.8rem auto;
    }

    .flex-box {
      flex: 1 1 auto;
      margin-top: 8.5rem;
    }

    .btn-flex-form {
      .btn-join {
        flex: 0 1 auto;
        width: auto;
      }
    }
  }

  .login-box {
    padding: 0 3.2rem;

    .btn-flex-form {
      padding: 0;

      span {
        font-weight: 400;
      }
    }
  }
}

.login-select-box {
  padding: 0 3.2rem;

  li {
    a {
      display: block;
      width: 100%;
      padding: 2.1rem 0;
      font-size: 1.7rem;
      font-weight: 400;
      color: #000;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 1.6rem 50%;
      background-size: 4rem;
      border-radius: 0.3rem;
      border-width: 1px;
      border-style: solid;
      border-color: #e7e7f0;
      text-align: center;
      cursor: pointer;

      &.kakao {
        background-color: #ffb900;
        background-image: url('#{$img-cdn}/ico_kakao_120x120.png');
        border-color: #ffb900;
      }

      &.apple {
        color: #fff;
        background-color: #000;
        background-image: url('#{$img-cdn}/ico_apple_120x120_w.png');
        border-color: #000;
      }
    }

    + li {
      margin-top: 1.8rem;
    }
  }

  &.other {
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;

      a {
        width: 6.4rem;
        height: 6.4rem;
        padding: 1.5rem 0;
        border: 1px solid #e7e7f0;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 4rem;
        box-sizing: border-box;

        &.facebook {
          background-image: url('#{$img-cdn}/ico_facebook_120x120.png');
        }

        &.naver {
          background-image: url('#{$img-cdn}/ico_naver_120x120.png');
        }

        &.kakao {
          background-color: transparent;
          background-image: url('#{$img-cdn}/ico_kakao_120x120_default.png');
        }

        &.apple {
          background-color: transparent;
          background-image: url('#{$img-cdn}/ico_ios_22x22.png');
          background-size: 2.7rem;
        }
      }

      + li {
        margin-top: 0;
        margin-left: 1.6rem;
      }
    }
  }
}

.find-account-box {
  margin-top: 3.4rem;
  text-align: center;

  li {
    display: inline-block;

    a,
    button {
      font-weight: 400;
      font-size: 1.5rem;
      cursor: pointer;
    }

    + li {
      &::before {
        content: '·';
        display: inline-block;
        margin: 0 0.5rem;
      }
    }
  }
}

.btn-flex-form {
  flex-wrap: inherit;
  justify-content: center;
  font-size: 1.5rem;

  span {
    flex: 0 1 auto;
    font-weight: 400;
  }

  .btn-login {
    margin-top: 40px;
  }

  .btn-join {
    flex: 0 1 auto;
    display: inline-block;
    width: auto;
    margin-left: 0.7rem;

    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.option-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  padding: 0 3.2rem;

  .find-account-box {
    margin-top: 0;
  }

  label {
    flex: 1 1 auto;
    font-size: 1.6rem;

    .chk {
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal-wrap {
    &.login {
      h3 {
        height: 9.2rem;
        padding-top: 14.4rem;
        background-size: 38.8rem auto;
      }

      .flex-box {
        margin-top: 15.8rem;
      }

      .btn-flex-form {
        font-size: 2.3rem;

        .btn-join {
          flex: 0 1 auto;
          width: auto;
          margin-left: 1rem;
        }
      }

      .login-box {
        padding: 0 4.2rem;
      }
    }
  }

  .login-select-box {
    padding: 0 3.2rem;

    li {
      a {
        padding: 3.3rem 0;
        font-size: 2.7rem;
        background-position: 2.3rem 50%;
        background-size: 6.4rem;
        border-radius: 0.4rem;
      }

      + li {
        margin-top: 2.8rem;
      }
    }

    &.other {
      text-align: center;

      li {
        display: inline-block;

        a {
          width: 10rem;
          height: 10rem;
          padding: 2.3rem 0;
          background-size: 6.3rem;

          &.apple {
            background-size: 4.3rem;
          }
        }

        &.naver {
          img {
            width: 4.5rem;
            height: 4.5rem;
          }
        }

        + li {
          margin-left: 2.6rem;
        }
      }
    }
  }

  .find-account-box {
    margin-top: 5.4rem;

    li {
      a,
      button {
        font-size: 2.3rem;
      }

      + li {
        &::before {
          content: '·';
          display: inline-block;
          margin: 0 1.1rem;
        }
      }
    }
  }

  .option-box {
    margin-top: 2.5rem;
    padding: 0 4.2rem;

    label {
      font-size: 2.5rem;

      input[type='checkbox'] {
        + .chk {
          margin-right: 1.6rem;
        }
      }
    }
  }
}

.social-list {
  display: grid;
  margin: 37px auto;
  max-width: 304px;
  grid-template-columns: repeat(4, 64px);
  gap: 16px;

  li {
    position: relative;

    .btn-naver {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
}

.signup-group {
  flex: 0 1 auto;
  padding: 0 32px 32px;

  .signup-img {
    display: block;
    margin: 0 auto;
    width: 296px;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;

    span {
      color: #ffa800;
      font-weight: 700;
    }
  }

  .btn-signup {
    width: 100%;
    padding: 20px 0;
    border: 1px solid #dfdfea;

    span {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .social-list {
    margin: 75px auto 45px;
    max-width: 420px;
    grid-template-columns: repeat(4, 84px);
    gap: 28px;
  }

  .signup-group {
    .signup-img {
      width: 456px;
    }
    p {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 28px;
    }

    .btn-signup {
      padding: 31px 0;

      span {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .btn-flex-form {
    .btn-login {
      margin-top: 5.333vw;
    }
  }
  .social-list {
    margin: 10vw auto 7.5vw;
    max-width: 56vw;
    grid-template-columns: repeat(4, 11.2vw);
    gap: 3.733vw;
  }

  .signup-group {
    .signup-img {
      width: 60.8vw;
    }
    p {
      font-size: 3.2vw;
      line-height: 4.533vw;
      margin-bottom: 3.733vw;
    }

    .btn-signup {
      padding: 4.133vw 0;

      span {
        font-size: 3.733vw;
        line-height: 4.533vw;
      }
    }
  }
}
