.tabMenuBox {
  padding: 0 0 4.8rem;
  text-align: center;

  @media screen and (max-width: 1024px) {
    padding: 10rem 0 5rem;
  }
}

.tabMenuList {
  display: inline-flex;
  position: relative;
  border-radius: 12rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid #28284b;
    border-radius: inherit;
  }
}

.tabMenuItem {
  position: relative;
  border-radius: inherit;

  &.active {
    a {
      font-weight: 700;
      color: #fff;
      background-color: #28284b;
    }
  }

  a {
    display: inline-block;
    height: 100%;
    padding: 1.4rem 3.4rem;
    font-weight: 500;
    font-size: 2rem;
    color: #28284b;
    border-radius: inherit;
    background-color: transparent;
    white-space: nowrap;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      padding: 1.6rem 3rem;
      font-size: 2.5rem;
    }
  }
}
