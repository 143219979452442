.toast {
  position: absolute;
  top: 100%;
  margin-top: 8px;

  display: inline-flex;
  padding: 17px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: rgba(40, 40, 75, 0.9);
  animation: fadeout 0.5s ease-out 1.5s forwards;

  p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 11px;

    p {
      font-size: 20px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 750px) {
    margin-top: 1.467vw;

    padding: 2.267vw 4.533vw;
    gap: 1.333vw;
    border-radius: 1.333vw;

    p {
      font-size: 2.667vw;
      line-height: 3.467vw;
    }
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
