.myLevel {
  background-color: #fff;
  .levelContent {
    .imageWrapper {
      position: relative;
      margin-bottom: 20px;
    }
    .exitButton {
      position: absolute;
      top: 34px;
      right: 34px;
    }
    .overlay {
      position: absolute;
      top: 50%;
      left: 60px;
      transform: translate(0, -50%);
      .badege {
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: #fff;
        border-radius: 30px;
        padding: 9px 27px;
        width: fit-content;
      }

      .badege--lv1 {
        background-color: #ff4635;
      }

      .badege--lv2 {
        background-color: #ff7700;
      }

      .badege--lv3 {
        background-color: #06c000;
      }

      .badege--lv4 {
        background-color: #00bab7;
      }

      .badege--lv5 {
        background-color: #107dff;
      }

      .badege--lv6 {
        background-color: #9c46ff;
      }
      .rate {
        font-size: 56px;
        font-weight: 700;
        line-height: 66px;
        text-align: left;
        margin: 16px 0 20px;
      }
      .mainText {
        font-size: 26px;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
      }
    }
  }
  .scoreBox {
    margin: 0 48px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; /* 세로 가운데 정렬 */
    background-color: #f7f7fc;
    border-radius: 32px;
    border: solid 2px #dfdfea;
    > .score,
    > .time {
      display: flex;
      flex-direction: column;
      text-align: center;
      flex: 1 1;
      padding: 44px 0;
      .scoretitle {
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        color: #8d8da0;
        margin-bottom: 12px;
        img {
          margin-right: 5px;
          margin-top: 2px;
          width: 32px;
          height: 32px;
        }
      }
      .scoreResult {
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        letter-spacing: -0.5px;
      }
    }

    .verticalLine {
      width: 1px;
      height: 72px;
      background-color: #dfdfea;
      padding: 1px;
    }
  }
}
@media all and (max-width: 750px) {
  .myLevel {
    .levelContent {
      .imageWrapper {
        margin-bottom: 2.6667vw;
      }
      .exitButton {
        width: 9.6vw;
        height: 9.6vw;
        top: 4.5333vw;
        right: 4.5333vw;
      }
      .overlay {
        left: 8vw;
        .badege {
          font-size: 4.2667vw;

          line-height: 5.164vw;

          border-radius: 4vw;
          padding: 1.2vw 3.6vw;
          width: fit-content;
        }

        .rate {
          font-size: 7.4667vw;
          line-height: 8.8vw;
          margin: 2.1333vw 0 2.6667vw;
        }
        .mainText {
          font-size: 3.4667vw;
          line-height: 4.8vw;
        }
      }
    }
    .scoreBox {
      margin: 0 6.4vw 0;
      border-radius: 4.2667vw;
      border: solid 0.2667vw #dfdfea;
      > .score,
      > .time {
        flex-direction: column;
        img {
          width: 4.267vw;
        }
        .scoretitle {
          font-size: 3.7333vw;
          line-height: 4.5333vw;
          margin-bottom: 1.6vw;
          img {
            margin-right: 0.6667vw;
            margin-top: 0.2667vw;
            width: 4.2667vw;
            height: 4.2667vw;
          }
        }
        .scoreResult {
          font-size: 6.4vw;
          line-height: 7.4667vw;
          letter-spacing: -0.0667vw;
        }
      }
      .score {
        padding: 5.8667vw 0;
      }
      .time {
        padding: 5.8667vw 0;
      }
      .verticalLine {
        width: 0.1333vw;
        height: 9.6vw;
        padding: 0.1333vw;
      }
    }
  }
}
