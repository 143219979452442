.csInner {
  box-sizing: content-box;
  max-width: 1194px;
  margin: 0 auto;
}

@media screen and (max-width: 1280px) {
  .csInner {
    padding: 0 40px;
  }
}

@media screen and (max-width: 1024px) {
  .csInner {
    max-width: 750px;
  }
}

@media screen and (max-width: 750px) {
  .csInner {
    padding: 0 5.333vw;
  }
}
