.main {
  background-color: #fff;
  padding: 31px 42px;
  min-height: 100vh;
  box-sizing: border-box;
}
.inner {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}
.section {
  h3 {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 47.36px;
    text-align: left;
    margin-bottom: 19px;
  }
}

@media all and (max-width: 750px) {
  .main {
    padding-top: 5.067vw;
    padding-left: 5.6vw;
    padding-right: 5.6vw;
  }
  .inner {
    width: 100%;
  }
  .section {
    h3 {
      font-size: 4.267vw;
      margin-bottom: 2.533vw;
    }
  }
}
