.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  gap: 12px;

  a {
    border-radius: 4px;
    background-color: #ffb900;
    width: 200px;
    height: 48px;
    color: #000;
    text-align: center;
    font-size: 16px;
    line-height: 1.375;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media all and (max-width: 1024px) {
  .buttons {
    max-width: 100%;
    margin-top: 12rem;
  }
}

@media all and (max-width: 500px) {
  .buttons {
    gap: 2.4vw;
    width: 100%;
    a {
      flex: 1;
      width: auto;
      font-size: 3.733vw;
      height: 12.8vw;
      border-radius: 1.2vw;
    }
  }
}
