.tabMenuList {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 0 4.2rem;
    border-bottom: 0.1rem solid #dfdfea;
    background-color: #fff;
  }
}

.tabMenuItem {
  & + & {
    margin-left: 4.8rem;
  }

  &.active {
    a {
      font-weight: 700;
      color: #000;
      border-bottom: 0.3rem solid #ffb900;
    }
  }

  a {
    display: block;
    padding: 0.2rem 0;
    font-size: 2rem;
    color: #a0a0b6;
  }

  @media screen and (max-width: 1024px) {
    a {
      padding: 4rem 0;
      font-size: 2.8rem;
    }

    &.active {
      a {
        border-bottom-width: 0.4rem;
      }
    }

    & + & {
      margin-left: 5.5rem;
    }
  }
}
