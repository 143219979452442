.modalWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.7);
  img {
    max-width: 100%;
  }
}

.modalContent {
  position: relative;
}
.btnClose {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -40px;
  right: 0;
}
.btnTodayClose {
  position: absolute;
  bottom: -26px;
  left: 0;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .modalContent {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 750px) {
  .modalContent {
    max-width: calc(100% - 20.8vw) !important;
  }
  .btnClose {
    width: 5.333vw;
    height: 5.333vw;
    top: -7.467vw;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .btnTodayClose {
    font-size: 3.2vw;
    bottom: -5.867vw;
  }
}
