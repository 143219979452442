.productListWrap {
  padding: 90px 0 100px;
  &:nth-child(odd) {
    background-color: #f7f7f7;
  }
  .innerCol {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;

    h3 {
      position: relative;
      display: inline-block;
      z-index: 0;
      margin-bottom: 36px;
      font-size: 38px;
      font-weight: 700;
      color: #000;

      &::before {
        display: inline-block;
        position: absolute;
        bottom: 3px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 14px;
        background: #ffb900;
        content: '';
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 8rem 0 9.5rem;

    .innerCol {
      padding: 0 20px;

      h3 {
        margin-bottom: 3.4rem;
        font-size: 3.8rem;

        &::before {
          height: 1.4rem;
        }
      }
    }
  }
}
.productList {
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
}

.swiperWrap {
  overflow: visible;
}
