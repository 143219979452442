.hgroup {
  position: relative;
  text-align: center;
  padding: 35px 0;
  h3 {
    color: #000;
    font-size: 32px;
    font-weight: 7 00;
    line-height: 110%;
  }
  button {
    position: absolute;
    left: 42px;
    top: 50%;
    transform: translateY(-50%);
  }
  span {
    color: #fff;
    background: #ff6f0f;
    padding: 4px 14px;
    border-radius: 30px;
  }
}
@media all and (max-width: 750px) {
  .main {
    padding-top: 5.067vw;
    padding-left: 5.6vw;
    padding-right: 5.6vw;
    span {
      padding: 0.5333vw 1.8667vw;
      border-radius: 4vw;
    }
  }
  .inner {
    width: 100%;
  }
  .hgroup {
    padding: 4.6667vw 0;
    h3 {
      font-size: 4.267vw;
    }
    button {
      width: 5.333vw;
    }
    span {
      padding: 0.533vw 1.867vw;
      border-radius: 4vw;
      font-size: 4.267vw;
    }
  }
}
