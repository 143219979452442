/* modal */
.modalWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
}
.modalContent {
  position: relative;
}
.btnClose {
  position: absolute;
  top: -5.5rem;
  right: 0;
}
.btnClose::after {
  content: '\00d7';
  font-size: 5rem;
  color: #fff;
  font-weight: 300;
}
.btnTodayClose {
  position: absolute;
  bottom: -28px;
  left: 0;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.btnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 1024px) {
  .btnClose {
    top: -4.5em;
  }
  .btnClose::after {
    font-size: 4em;
  }
  .btnTodayClose {
    font-size: 12px;
    bottom: -24px;
  }
}

/* modal tip */
.modalTips {
  width: 480px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.modalTips hgroup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  padding: 0 24px;
  border-bottom: 1px solid var(--GrayScale-200---DFDFEA, #dfdfea);
  position: relative;
}
.modalTips hgroup button {
  width: 44px;
  height: 44px;
  white-space: nowrap;
  text-indent: -1000px;
  overflow: hidden;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/btn_chat_close.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 24px;
}
.modalTips hgroup h5 {
  color: var(--Black---000000, #000);
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
}
.modalTips .content {
  padding: 52px 32px 57px;
  text-align: center;
}
.modalTips .content .tipThumb {
  width: 88px;
  height: 88px;
  margin: 0 auto 24px;
}
.modalTips .content p {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 8px;
}
.modalTips .content span {
  color: #717188;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
.modalTips .content .tipNoti {
  padding: 20px 28px;
  border-radius: 3px;
  background: #ffeeed;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 54px;
}
.modalTips .content .tipNoti i {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/info.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.modalTips .content .tipNoti p {
  color: #717188;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin: 0;
}
.modalTips .content .tipNoti span {
  color: #ff5647;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
}
.modalTips .btnFooter {
  padding: 0 32px 32px;
}
.modalTips .confirm {
  color: var(--Black---000000, #000);

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 164.706% */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 100%;
  background-color: #ffb900;
}

/* modal alert */
.modalAlert {
  width: 480px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.modalAlert .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 290px;
}
.modalAlert .content p {
  color: var(--Black---000000, #000);
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 5px;
}
.modalAlert .content span {
  color: var(--GrayScale-700---717188, #717188);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 38px;
}
.modalAlert .content span.subText {
  color: var(--GrayScale-500---A0A0B6, #a0a0b6);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-top: 15px;
}
.modalAlert .content p i {
  display: block;
  color: var(--GrayScale-700---717188, #717188);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
}
.modalAlert .btnGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid var(--Blue-Gray-4---DFDFEA, #dfdfea);
}
.modalAlert .btnGroup button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  color: var(--Black---000000, #000);
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}
.modalAlert .btnGroup button:first-child {
  border-right: 1px solid var(--Blue-Gray-4---DFDFEA, #dfdfea);
}

@media screen and (max-width: 1024px) {
  .modalAlert {
    width: 650px;
  }
  .modalAlert .content {
    height: 382px;
  }
  .modalAlert .content p {
    font-size: 38px;
    line-height: 62px;
    margin-bottom: 24px;
  }
  .modalAlert .content p i {
    color: var(--GrayScale-700---717188, #717188);
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.5px;
  }
  .modalAlert .content span {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
  }
  .modalAlert .content span.subText {
    font-size: 28px;
    line-height: 48px;
    letter-spacing: -0.5px;
    margin-top: 30px;
  }
  .modalAlert .btnGroup button {
    font-size: 29px;
    font-weight: 400;
    height: 119px;
  }
}
@media screen and (max-width: 750px) {
  .modalAlert {
    width: 86.667vw;
  }
  .modalAlert .content {
    height: 50.933vw;
  }
  .modalAlert .content p {
    font-size: 5.067vw;
    line-height: 8.267vw;
    margin-bottom: 3.2vw;
  }
  .modalAlert .content span {
    font-size: 4.267vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
  }
  .modalAlert .content span.subText {
    font-size: 3.733vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
    margin-top: 4vw;
  }
  .modalAlert .content p i {
    font-size: 4.267vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
  }
  .modalAlert .btnGroup button {
    font-size: 3.867vw;
    height: 15.867vw;
  }
  .modalTips {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 2.667vw 2.667vw 0 0;
  }
  .modalTips hgroup {
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0 3.2vw;
    height: 14.4vw;
  }
  .modalTips hgroup h5 {
    text-align: center;
    font-size: 4.133vw;
    line-height: normal;
  }
  .modalTips hgroup button {
    width: 9.6vw;
    height: 9.6vw;
    position: absolute;
    top: 2.4vw;
    right: 4.533vw;
  }
  .modalTips .content {
    padding: 9.067vw 5.333vw 13.333vw;
    text-align: center;
  }
  .modalTips .content .tipThumb {
    width: 22.133vw;
    height: 22.133vw;
    margin: 0 auto 4.267vw;
  }
  .modalTips .content p {
    margin-bottom: 3.2vw;
    font-size: 4.8vw;
    line-height: 7.333vw;
  }
  .modalTips .content span {
    font-size: 4vw;
    line-height: 6.133vw;
  }
  .modalTips .content .tipNoti {
    padding: 4vw 5.067vw;
    border-radius: 0.667vw;
    margin-top: 8vw;
  }
  .modalTips .content .tipNoti i {
    width: 5.333vw;
    height: 5.333vw;
    margin-right: 3.733vw;
  }
  .modalTips .content .tipNoti p {
    font-size: 3.2vw;
    line-height: 5.333vw;
    letter-spacing: -0.032vw;
  }
  .modalTips .content .tipNoti span {
    font-size: 3.2vw;
    line-height: 5.333vw;
    letter-spacing: -0.032vw;
  }

  .modalTips .btnFooter {
    padding: 0 5.333vw 8.667vw;
  }
  .modalTips .confirm {
    font-size: 3.6vw;
    height: 13.333vw;
    border-radius: 0.667vw;
  }
}

/* common */
.inner {
  margin: 0 auto;
  padding: 0 40px;
}
.titleGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.titleGroup h3 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
  letter-spacing: -0.5px;
}
.titleGroup::before {
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
  content: '';
}
.titleGroup p {
  margin-top: 10px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
@media screen and (max-width: 1024px) {
  .inner {
    max-width: 750px;
  }
  .titleGroup::before {
    width: 56px;
    height: 56px;
    background-size: 56px;
  }
  .titleGroup h3 {
    font-size: 44px;
    line-height: 62px;
  }
  .titleGroup p {
    margin-top: 20px;
    font-size: 26px;
    line-height: 37px;
  }
}
@media screen and (max-width: 750px) {
  .inner {
    padding: 0 5.333vw;
  }
  .titleGroup {
    margin-bottom: 6.667vw;
  }
  .titleGroup::before {
    width: 7.467vw;
    height: 7.467vw;
    margin-bottom: 2.667vw;
    background-size: 6.667vw;
  }
  .titleGroup h3 {
    font-size: 5.333vw;
    line-height: 6.4vw;
  }
  .titleGroup p {
    margin-top: 2.667vw;
    font-size: 3.467vw;
    line-height: 4.933vw;
  }
}
/* section01 */
.character {
  position: relative;
  padding: 70px 0 70px;
  background: #fff3d4;
  overflow: hidden;
}
.charContainer {
  width: 510px;
  height: 881px;
  margin: 0 auto;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/iphone_mockup.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.charContainer::before {
  display: block;
  width: 665px;
  height: 266px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/heart_dart.gif');
  position: absolute;
  top: -7px;
  left: -82px;
  content: '';
}
.charNoti {
  color: var(--GrayScale-800---28284B, #28284b);
  text-align: center;
  font-size: 14px;
  font-weight: 350;
  line-height: 26px;
  letter-spacing: -0.14px;
  position: absolute;
  bottom: -10px;
  width: 100%;
}
.charNoti span {
  display: block;
  text-align: center;
  margin-top: 12px;
}
.charCardBox {
  width: 302px;
  height: 636px;
  margin: 0 auto;
  padding-top: 54px;
}
.charCard {
  border-radius: 40px;
  overflow: hidden;
}
.imgTitle {
  margin: 0 auto;
  max-width: 520px;
  text-align: center;
}
.imgTitle h3 {
  margin-top: 14px;
}
.imgTitle h4 {
  color: var(--GrayScale-800---28284B, #28284b);
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.63px;
  margin-top: 14px;
}
.btnPrev,
.btnNext {
  position: absolute;
  top: 295px;
  width: 44px;
  height: 44px;
  overflow: hidden;
  text-indent: -1000px;
  white-space: nowrap;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  cursor: pointer;
}
.btnPrev {
  left: 23px;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/left_arrow.png');
}
.btnNext {
  right: 23px;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/right_arrow.png');
}
.pagination {
  display: none;
}
.pagination span.bulletActive,
.specialPagination span.bulletActive {
  background: #404040;
  opacity: 1;
}
@media screen and (max-width: 1024px) {
  .character {
    padding: 80px 0 120px 0;
  }
  .imgTitle {
    margin: 0 auto;
    max-width: 650px;
  }
  .imgTitle h2 img {
    width: 431px;
  }
  .imgTitle h3 {
    margin-top: 51px;
  }
  .imgTitle h4 {
    font-size: 29px;
    line-height: 43px;
    letter-spacing: -0.87px;
    margin-top: 10px;
  }
  .charContainer {
    width: 667px;
    height: 1113px;
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/m_iphone_mockup.png');
    background-size: contain;
    background-position: 0 0;
    margin-top: 29px;
  }
  .charContainer::before {
    width: 750px;
    height: 300px;
    top: -29px;
    left: -42px;
  }
  .charCardBox {
    width: 378px;
    height: 797px;
    padding-top: 56px;
  }
  .charNoti {
    font-size: 22px;
    line-height: 40px;
    letter-spacing: -0.22px;
    bottom: -60px;
  }
  .charNoti span {
    margin-top: 45px;
  }
  .charNoti span picture {
    display: block;
    max-width: 368px;
    margin: 0 auto;
  }
  .charCard {
    border-radius: 50px;
  }
  .btnPrev,
  .btnNext {
    display: none;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
  }
  .pagination span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }
}
@media screen and (max-width: 750px) {
  .character {
    padding: 10.667vw 0 21.333vw 0;
  }
  .imgTitle {
    margin: 0 auto;
    max-width: 86.667vw;
  }
  .imgTitle h2 img {
    width: 57.467vw;
  }
  .imgTitle h3 {
    margin-top: 2.8vw;
  }
  .imgTitle h4 {
    font-size: 3.867vw;
    line-height: 5.733vw;
    letter-spacing: -0.116vw;
    margin-top: 1.53vw;
  }
  .charContainer {
    width: 88.933vw;
    height: 141vw;
    margin-top: 3.867vw;
  }

  .charContainer .pagination {
    top: 1.867vw;
  }
  .charContainer .pagination span {
    display: block;
    width: 1.333vw;
    height: 1.333vw;
    margin: 0 0.533vw;
  }
  .charContainer .pagination span.bulletActive,
  .charContainer .specialPagination span.bulletActive {
    width: 4vw;
    border-radius: 0.7vw;
  }
  .charContainer::before {
    width: 100vw;
    height: 40vw;
    top: -3.867vw;
    left: -5.6vw;
  }
  .charCardBox {
    width: 50.4vw;
    height: 106.267vw;
    padding-top: 7.467vw;
  }
  .charNoti {
    font-size: 2.667vw;
    line-height: 3.467vw;
    letter-spacing: -0.029vw;
    bottom: -8vw;
  }
  .charNoti span {
    margin-top: 3.333vw;
  }
  .charNoti span picture {
    max-width: 49.067vw;
  }
  .charCard {
    border-radius: 6.667vw;
  }
}
/* section02 */
.special {
  padding: 70px 0;
  background-color: #ddedfc;
}
.special .titleGroup {
  margin-bottom: 42px;
}
.special .titleGroup::before {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/section02_badge_blue.svg');
}

.aiList {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 53px;
  position: relative;
}
.aiList :global .swiper-container {
  max-width: 1180px;
  margin: 0 auto;
}
.aiList :global .swiper-button-prev,
.aiList :global .swiper-button-next {
  width: 32px;
  height: 32px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_special_slide_arrow.svg');
}
.aiList :global .swiper-button-prev {
  left: 0;
}
.aiList :global .swiper-button-next {
  transform: scale(-1);
  right: 0;
}
.aiList :global .swiper-button-prev:after,
.aiList :global .swiper-button-next:after {
  display: none;
}

.specialItem {
  background: linear-gradient(180deg, #83a5f5 0%, #269cff 100%);
  border-radius: 20px;
  height: 354px;
  padding: 52px 0 0 30px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.specialItem:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 133px;
  background: linear-gradient(180deg, rgba(38, 156, 255, 0) 0%, #269cff 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.special .subTitle {
  font-size: 14px;
  line-height: 20px;
  color: #c9ebff;
}
.special .aiThumb {
  width: 253px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.special dt {
  margin-top: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  white-space: pre-line;
}
.special dd {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
  margin-top: 152px;
  position: relative;
  z-index: 4;
}
.special .infoText {
  color: #fff;
  opacity: 0.5;
  font-size: 10px;
  zoom: 0.8;
  font-weight: 400;
  line-height: 14px;
  display: block;
  margin-top: 8px;
}
.special .specialPagination {
  display: none;
}

@media screen and (max-width: 1024px) {
  .aiList {
    padding: 0;
  }
  .aiList :global .swiper-button-prev {
    left: -45px;
  }
  .aiList :global .swiper-button-next {
    right: -45px;
  }
  .special dd {
    font-size: 11px;
  }
  .special dt {
    font-size: 16px;
  }
  .special .subTitle {
    font-size: 13px;
  }
}
@media screen and (max-width: 750px) {
  .special {
    padding: 9.333vw 0;
  }
  .special .titleGroup {
    margin-bottom: 5.333vw;
  }
  .specialItem {
    height: 82.133vw;
    padding: 12.267vw 0 0 6.933vw;
  }
  .special .specialPagination {
    display: block;
    position: absolute;
    top: 4vw;
    right: 3.333vw;
    z-index: 9;
  }
  .special .specialPagination span {
    margin: 0 0.667vw;
    width: 1.333vw;
    height: 1.333vw;
    background: #fff;
  }
  .aiList {
    padding: 0;
  }
  .aiList :global .swiper-button-prev,
  .aiList :global .swiper-button-next {
    display: none;
  }
  .special .subTitle {
    font-size: 3.2vw;
    line-height: 4vw;
  }
  .special dt {
    margin-top: 3.2vw;
    font-size: 4.267vw;
    line-height: 5.333vw;
  }
  .special dd {
    font-size: 2.667vw;
    line-height: 3.467vw;
    margin-top: 34.4vw;
  }
  .special .aiThumb {
    width: 58.8vw;
  }
  .specialItem::after {
    height: 31.067vw;
  }
  .specialItem .infoText {
    font-size: 2.133vw;
    zoom: normal;
    margin-top: 1.867vw;
  }
}

/* section03 */
.always {
  background-color: #f7f7fc;
  padding: 70px 0;
}
.always .titleGroup::before {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/section03_badge_new.svg');
}
.gifContainer {
  margin-top: -10px;
}
.gifContainer ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  max-width: 1194px;
  margin: 0 auto;
}
.gifContainer ul li {
  position: relative;
}
.gifBox {
  width: 100%;
  height: 591px;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
}
.gifBoxDescription {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 24px;
}
.gifBoxDescription b {
  color: #269cff;
  font-weight: 500;
}
.gifBoxSubDescription {
  text-align: center;
  display: block;
  color: #8d8da0;
  margin-top: 12px;
  font-size: 12px;
  line-height: 19px;
}
@media screen and (max-width: 1024px) {
  .gifContainer ul {
    display: block;
  }
  .gifContainer ul li:first-child {
    margin-bottom: 100px;
  }
  .gifContainer ul li::before {
    left: 25px;
    top: -28px;
    width: 82px;
    height: 106px;
    background-size: 82px 106px;
  }
  .gifBox {
    height: auto;
    padding: 0 34px;
  }
  .gifBoxDescription {
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    margin-top: 24px;
  }
}
@media screen and (max-width: 750px) {
  .always {
    padding: 13.333vw 0;
  }
  .gifContainer ul li:first-child {
    margin-bottom: 6.667vw;
  }
  .gifContainer ul li::before {
    left: 3.333vw;
    top: -3.733vw;
    width: 10.933vw;
    height: 14.133vw;
    background-size: 10.933vw 14.133vw;
  }
  .gifBox {
    height: auto;
    padding: 0;
  }
  .gifBoxDescription {
    font-size: 3.733vw;
    line-height: 4.533vw;
    margin-top: 3.733vw;
  }
  .gifBoxSubDescription {
    margin-top: 2.133vw;
    font-size: 2.533vw;
    line-height: 4.133vw;
    white-space: pre-line;
  }
}
/* section04 */
.voice {
  padding: 100px 0;
  background-color: #000;
}
.voice .inner {
  max-width: 1194px;
  margin: 0 auto;
}
.voice .titleGroup h3 {
  color: #fff;
}
.voice .titleGroup::before {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/section04_badge.svg');
}
.accordion dl {
  position: relative;
}
.accordion dl::after {
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  content: '';
}
.accordion dt {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  padding: 30px 30px 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion dt button {
  display: block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-indent: -1000em;
  white-space: nowrap;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/section04_arrow.svg');
  background-size: 18px 29px;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  transition: all 0.3s;
}
.accordion dd {
  height: auto;
  min-height: 0;
  overflow: hidden;
  transition: all 0.3s;
}
.hide dt button {
  transform: rotate(0deg);
}
.hide dd {
  height: 0;
}
.deviceVoice li {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 30px;
  margin-top: 16px;
}
.deviceVoice li:first-child {
  margin-top: 0;
}
.deviceVoice li span {
  color: #f7f7fc;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.deviceNoti {
  margin-top: 20px;
  padding-left: 24px;
  padding-bottom: 40px;
}
.deviceNoti li {
  color: #cfcfda;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  list-style: disc;
  margin-top: 11px;
}
.deviceNoti li:first-child {
  margin-top: 0;
}
.deviceNoti li::marker {
  color: #cfcfda;
}

@media screen and (max-width: 1024px) {
  .accordion dt {
    font-size: 28px;
    line-height: 30px;
    padding: 40px 0 40px;
  }
  .deviceVoice li {
    grid-template-columns: 210px 1fr;
    gap: 20px;
    margin-top: 32px;
  }
  .deviceVoice li span {
    font-size: 22px;
    line-height: 38px;
  }
  .deviceNoti {
    margin-top: 40px;
  }
  .deviceNoti li {
    font-size: 20px;
    line-height: 32px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 750px) {
  .voice {
    padding: 13.333vw 0;
  }
  .accordion dt {
    font-size: 3.733vw;
    line-height: 4vw;
    padding: 5.333vw 0;
  }
  .accordion dt button {
    width: 4vw;
    height: 4vw;
    background-size: 2.4vw 3.867vw;
  }
  .deviceVoice li {
    grid-template-columns: 28vw 1fr;
    gap: 2.667vw;
    margin-top: 4.267vw;
  }
  .deviceVoice li span {
    font-size: 2.933vw;
    line-height: 5.067vw;
  }
  .deviceNoti {
    margin-top: 5.333vw;
  }
  .deviceNoti li {
    font-size: 2.667vw;
    line-height: 4.267vw;
    margin-top: 2.667vw;
  }
}

/* section05 */
.step {
  padding: 70px 0;
  background-color: #fff;
}
.step .titleGroup::before {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/section05_badge_new.svg');
}
.stepList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  max-width: 1194px;
  margin: 0 auto;
}
.stepItem {
  padding: 60px 40px 53px;
  background: rgba(247, 247, 252, 1);
  border-radius: 18px;
  border: 1px solid rgba(236, 236, 246, 1);
  height: 440px;
  box-sizing: border-box;
  position: relative;
}
.stepItem hgroup {
  padding-bottom: 24px;
  border-bottom: 1px solid #dfdfea;
  background-repeat: no-repeat;
  background-size: 110px 110px;
  background-position: right top 20px;
}
.stepItem hgroup i {
  color: rgba(113, 113, 136, 1);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  width: 82px;
  padding: 6px 9px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(231, 231, 240, 1);
}
.stepItem hgroup h4 {
  color: #000;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin-top: 19px;
}
.stepItem .bodyGroup {
  padding-top: 35px;
}
.stepItem .bodyGroup p {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 32px;
}
.stepItem .bodyGroup span {
  color: var(--GrayScale-700---717188, #717188);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 53px;
  left: 40px;
  display: block;
}
.stepList li:last-child .stepItem hgroup h4::after {
  display: inline-block;
  padding: 5px 8px 3px;
  margin-left: 8px;
  background-color: #ff5647;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  border-radius: 12px;
  color: #fff;
  vertical-align: middle;
  content: 'NEW';
}

.btnConversation {
  color: var(--GrayScale-900---000000, #000);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 287px;
  height: 60px;
  border-radius: 100px;
  background: var(--Primary-Starlight-Yellow---FFB900, #ffb900);
  margin: 50px auto 0;
}

@media screen and (max-width: 1200px) {
  .stepItem .bodyGroup p {
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .stepList {
    grid-template-columns: 1fr;
  }
  .stepItem {
    padding: 42px 40px;
    border-radius: 20px;
    height: auto;
  }
  .stepItem hgroup {
    padding-bottom: 25px;
    background-position: right 38px;
  }
  .stepItem hgroup i {
    font-size: 17px;
    width: 82px;
    padding: 6px 9px;
    border-radius: 10px;
  }
  .stepItem hgroup h4 {
    font-size: 34px;
    line-height: 50px;
    margin-top: 16px;
  }
  .stepItem .bodyGroup {
    padding-top: 25px;
  }
  .stepItem .bodyGroup p {
    font-size: 29px;
    line-height: 42px;
    margin-bottom: 28px;
  }
  .stepItem .bodyGroup span {
    font-size: 25px;
    line-height: 40px;
  }
  .btnConversation {
    font-size: 28px;
    line-height: 38px;
    width: 384px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 100px;
    margin: 64px auto 0;
  }

  .stepList li:last-child .stepItem hgroup h4::after {
    margin-left: 10px;
    padding: 6px 12px 4px;
    margin-top: -4px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and (max-width: 750px) {
  .step {
    padding: 9.333vw 0 13.333vw;
  }
  .stepList {
    gap: 2.4vw;
  }
  .stepItem {
    padding: 5.333vw 6vw 6vw;
    border-radius: 2.667vw;
  }
  .stepItem hgroup {
    padding-bottom: 3.333vw;
    background-size: 14.667vw 14.667vw;
    background-position: right 5.067vw;
  }
  .stepItem hgroup i {
    font-size: 2.267vw;
    width: 10.933vw;
    padding: 0.8vw 1.2vw;
    border-radius: 1.333vw;
  }
  .stepItem hgroup h4 {
    font-size: 4.533vw;
    line-height: 6.667vw;
    margin-top: 2.133vw;
  }
  .stepItem .bodyGroup {
    padding-top: 3.333vw;
  }
  .stepItem .bodyGroup p {
    font-size: 3.867vw;
    line-height: 5.6vw;
    margin-bottom: 3.733vw;
  }
  .stepItem .bodyGroup span {
    font-size: 3.2vw;
    line-height: 5.333vw;
    position: relative;
    bottom: auto;
    left: auto;
  }
  .btnConversation {
    font-size: 3.733vw;
    line-height: 5.067vw;
    width: 51.2vw;
    height: 12vw;
    flex-shrink: 0;
    border-radius: 13.333vw;
    margin: 8vw auto 0;
  }
  .stepList li:last-child .stepItem hgroup h4::after {
    margin-left: 1.333vw;
    padding: 0.8vw 1.6vw 0.533vw;
    margin-top: -0.533vw;
    border-radius: 2.133vw;
    font-size: 2.133vw;
    line-height: 2.933vw;
  }
}

/* notice section */
.notice {
  padding: 100px 0;
  background-color: #ececf6;
}
.notice .inner {
  max-width: 1194px;
  margin: 0 auto;
}
.notice dl {
  display: grid;
  grid-template-columns: 207px 1fr;
  gap: 62px;
}
.notice dl dt {
  color: #28284b;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
}
.numberNoti {
  padding-left: 20px;
}
.numberNoti li {
  color: #717188;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  list-style: decimal;
  margin-top: 21px;
}
.numberNoti li:first-child {
  margin-top: 0;
}
.numberNoti li p {
  color: #8d8da0;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  margin-top: 4px;
}
.discNoti {
  padding-left: 16px;
  margin-top: 40px;
}
.discNoti li {
  color: #28284b;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  list-style: disc;
}
@media screen and (max-width: 1024px) {
  .notice dl {
    grid-template-columns: 1fr;
    gap: 46px;
  }
  .notice dl dt {
    font-size: 30px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: -0.3px;
  }
  .numberNoti li {
    font-size: 22px;
    line-height: 32px;
    margin-top: 32px;
  }
  .numberNoti li p {
    font-size: 22px;
    line-height: 36px;
    margin-top: 9px;
  }
  .discNoti li {
    font-size: 22px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: -0.22px;
  }
}
@media screen and (max-width: 750px) {
  .notice {
    padding: 13.333vw 0;
  }
  .notice dl {
    gap: 6.133vw;
  }
  .notice dl dt {
    font-size: 4vw;
    font-weight: 700;
    line-height: 5.467vw;
    letter-spacing: -0.04vw;
  }
  .numberNoti li {
    font-size: 2.933vw;
    line-height: 4.267vw;
    margin-top: 4.267vw;
  }
  .numberNoti li p {
    font-size: 2.933vw;
    line-height: 4.8vw;
    margin-top: 1.2vw;
  }
  .discNoti {
    margin-top: 5.333vw;
  }
  .discNoti li {
    font-size: 2.933vw;
    font-weight: 700;
    line-height: 5.467vw;
    letter-spacing: -0.029vw;
  }
}

/* recommendLecture */
.recommendLecture {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.recommendLecture li {
  margin-top: 16px;
}
.recommendLecture li img {
  width: 100%;
}
.recommendLecture li .thumb {
  position: relative;
}
.recommendLecture li .badge {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  display: inline-flex;
  padding: 3px 4px;
  align-items: flex-start;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.recommendLecture li .badge.new {
  background: var(--Accent-Green---17C624, #17c624);
}
.recommendLecture li .badge.best {
  background: var(--Accent-Blue---269CFF, #269cff);
}
.recommendLecture h5 {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 14px;
}
.recommendLecture p {
  color: #717188;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 8px;
}
/* recommend */
.modalRecommend {
  width: 480px;
  max-height: 820px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.modalRecommend .recommendHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  height: 64px;
}
.modalRecommend .recommendHeader button {
  width: 44px;
  height: 44px;
  white-space: nowrap;
  text-indent: -1000px;
  overflow: hidden;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/btn_chat_close.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.modalRecommend .scrollBox {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  max-height: calc(820px - 64px);
}
.recommendContainer {
  padding: 20px 32px 50px;
}

.modalRecommend hgroup img {
  display: block;
  width: 88px;
  height: 88px;
  margin: 0 auto;
}
.modalRecommend hgroup h4 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.21px;
  margin-top: 22px;
}

.modalRecommend hgroup p {
  color: #717188;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}

.modalRecommend .recommendNoti {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px 17px;
  border-radius: 5px;
  background: #fff8e5;
  margin-top: 32px;
  margin-bottom: 20px;
}
.modalRecommend .recommendNoti i {
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 12px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_info.png') no-repeat;
  background-size: cover;
}
.modalRecommend .recommendNoti span {
  color: #ffb900;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.13px;
  flex: 1;
}

@media screen and (max-width: 750px) {
  .recommendLecture {
    gap: 18px;
  }
  .recommendLecture li {
    margin-top: 5.6vw;
  }
  .recommendLecture li .badge {
    font-size: 2.133vw;
    line-height: 2.933vw;
    border-radius: 0.667vw;
    top: 1.6vw;
    left: 1.6vw;
  }
  .recommendLecture h5 {
    font-size: 3.733vw;
    line-height: 4.533vw;
    margin-top: 2.133vw;
  }
  .recommendLecture p {
    font-size: 2.667vw;
    line-height: 3.467vw;
    margin-top: 1.867vw;
  }
  .modalRecommend {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
  .modalRecommend .scrollBox {
    max-height: calc(100% - 14.4vw);
  }
  .modalRecommend .recommendHeader {
    padding: 0 4.533vw;
    height: 14.4vw;
  }
  .modalRecommend .recommendHeader button {
    width: 9.6vw;
    height: 9.6vw;
  }
  .recommendContainer {
    padding: 2.667vw 5.333vw 6.667vw;
  }
  .modalRecommend hgroup h4 {
    font-size: 5.467vw;
    line-height: 8.8vw;
  }

  .modalRecommend hgroup p {
    font-size: 4.267vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
    margin-top: 2.133vw;
  }
  .modalRecommend .recommendNoti {
    padding: 2.933vw 5.333vw;
  }
  .modalRecommend .recommendNoti i {
    width: 5.333vw;
    height: 5.333vw;
    margin-right: 3.2vw;
  }
  .modalRecommend .recommendNoti span {
    font-size: 3.067vw;
    line-height: 4.533vw;
    letter-spacing: -0.031vw;
  }
}

/* toast message */
.toastMsg {
  color: var(--GrayScale-0---FFFFFF, #fff);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  padding: 10px 30px;
  border-radius: 58px;
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(40, 40, 75, 0.8);
  white-space: nowrap;
}
.transBtn {
  display: block;
  text-align: right;
}
.transBtn button {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 24px;
  border-radius: 30px;
}
.transBtn.transAi button {
  color: var(--GrayScale-600---8D8DA0, #8d8da0);
  background-color: #dfdfea;
}
.transBtn.transUser button {
  color: #b16915;
  background-color: #f9a400;
}
/* ETD-3489 last update */
.transBtn.unable button {
  color: #ffb900;
  background-color: #28284b;
}
@media screen and (max-width: 1024px) {
  .toastMsg {
    font-size: 28px;
    line-height: 34px;
    border-radius: 58px;
  }
  .transBtn button {
    font-size: 16px;
    line-height: 22px;
    width: 60px;
    height: 30px;
    border-radius: 30px;
  }
}
@media screen and (max-width: 750px) {
  .toastMsg {
    font-size: 3.733vw;
    line-height: 4.533vw;
    border-radius: 7.733vw;
  }
  .transBtn button {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    width: 37px;
    height: 22px;
    border-radius: 30px;
  }
}

.share {
  position: absolute;
  top: 45px;
  right: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.share::after {
  position: absolute;
  right: 0;
  top: 64px;
  display: block;
  width: 114px;
  height: 38px;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/share_bubble.png');
  background-size: 114px 38px;
  background-position: 0 0;
  background-repeat: no-repeat;
  content: '';
}
.kakao,
.band,
.copy {
  display: block;
  width: 54px;
  height: 54px;
  overflow: hidden;
  background-size: 54px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -1000px;
  white-space: nowrap;
}
.copy input {
  display: none;
}
.kakao {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_share_kakao.png');
}
.band {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_share_band.png');
}
.copy {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_share_copy.png');
}
.kakao:hover {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_share_kakao_h.png');
}
.band:hover {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_share_band_h.png');
}
.copy:hover {
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_share_copy_h.png');
}

@media screen and (max-width: 1024px) {
  .btnShare {
    position: absolute;
    top: 30px;
    right: 30px;
    display: block;
    width: 76px;
    height: 76px;
    overflow: hidden;
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_share.png');
    background-size: 76px;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -1000px;
    white-space: nowrap;
  }
  .share {
    position: static;
    gap: 30px;
  }
  .share::after {
    display: none;
  }
  .kakao,
  .band,
  .copy {
    width: 128px;
    height: 128px;
    background-size: 128px;
  }
  .kakao,
  .kakao:hover {
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_m_share_kakao.png');
  }
  .band,
  .band:hover {
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_m_share_band.png');
  }
  .copy,
  .copy:hover {
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_m_share_copy.png');
  }
  .shareModal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 650px;
    height: 480px;
    border-radius: 32px;
    background-color: #fff;
  }
  .shareModal h3 {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin-bottom: 56px;
  }
  .btnShareClose {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 70px;
    height: 70px;
    white-space: nowrap;
    text-indent: -1000px;
    overflow: hidden;
    background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_modal_close.svg')
      center / cover no-repeat;
  }
}
@media screen and (max-width: 750px) {
  .btnShare {
    top: 4vw;
    right: 4vw;
    width: 10.133vw;
    height: 10.133vw;
    background-size: 10.133vw;
  }
  .share {
    gap: 4vw;
  }
  .kakao,
  .band,
  .copy {
    width: 17.067vw;
    height: 17.067vw;
    background-size: 17.067vw;
  }
  .shareModal {
    width: 86.667vw;
    height: 64vw;
    border-radius: 4.267vw;
  }
  .shareModal h3 {
    font-size: 5.333vw;
    line-height: 7.467vw;
    margin-bottom: 7.467vw;
  }
  .btnShareClose {
    top: 2.667vw;
    right: 2.667vw;
    width: 9.333vw;
    height: 9.333vw;
  }
}
