.countItem {
  flex: 0 auto;
  position: relative;
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 1.6rem;
  color: #717188;

  @media (max-width: 1024px) {
    font-size: 2.2rem;
  }
}
