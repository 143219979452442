$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/';
.modalAinadoo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);

  .ainadooNotice {
    padding-top: 33px;
    position: relative;

    &::before {
      width: 64px;
      height: 64px;
      position: absolute;
      top: 0;
      left: 123px;
      background-image: url('#{$img-path}img_notice_mark.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      content: '';
    }

    .noticeBox {
      width: 310px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 22px;
      padding: 60px 24px 25px;
      box-sizing: border-box;
      border-radius: 10px;

      .noticeTitle {
        color: var(--GrayScale-900---000000, #000);
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.5px;
        text-align: center;
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 22px;

        dl {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 3px;

          dt {
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 2px;

            color: #000;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          dd {
            text-align: center;
            color: #5d5d71;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
          }

          &:last-child {
            dd {
              &::before {
                display: inline-block;
                width: 12px;
                height: 12px;
                background-image: url('#{$img-path}ico_notice_mark.svg');
                background-size: cover;
                background-repeat: no-repeat;
                vertical-align: top;
                margin: 1px 2px 0 0;
                content: '';
              }
            }
          }
        }
      }

      .btnClose {
        width: 262px;
        height: 48px;
        border-radius: 24px;
        background: #ffb900;
        flex-shrink: 0;

        color: #000;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;

        margin-top: 4px;
      }
    }
  }

  .ainadooBack {
    display: flex;
    width: 310px;
    padding: 48px 24px 32px 24px;
    flex-direction: column;
    gap: 10px;
    border-radius: 12px;
    background: #fff;

    h3 {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.5px;
    }

    p {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #717188;
      margin-top: 8px;
    }

    .btnGroup {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
      margin-top: 32px;

      button {
        display: grid;
        place-items: center;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #dfdfea;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        &.btnConfirm {
          background-color: #ffb900;
          border-color: #ffb900;
        }
      }
    }
    .btnBox {
      margin-top: 32px;

      button {
        display: grid;
        place-items: center;
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #dfdfea;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        &.btnConfirm {
          background-color: #ffb900;
          border-color: #ffb900;
        }
      }
    }
  }

  .ainadooFeedback {
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    width: 100%;
    max-width: 750px;
    background-color: #fff;
    border-radius: 20px 20px 0 0;

    .titleBox {
      position: relative;
      border-bottom: 1px solid #dfdfea;

      h3 {
        padding: 16px 0;
        font-size: 14px;
        text-align: center;
      }

      .btnClose {
        position: absolute;
        top: 50%;
        right: 6px;
        width: 40px;
        height: 40px;
        font-size: 0;
        transform: translateY(-50%);
        background-image: url('#{$img-path}ico_close.svg');
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .feedbackBox {
      padding: 38px 24px 15px;
    }

    .ratingBox {
      .ratingTitle {
        margin-bottom: 12px;
        font-size: 20px;
        text-align: center;
        letter-spacing: -0.05em;
      }

      .starsBox {
        display: flex;
        justify-content: center;
        gap: 8px;

        span {
          cursor: pointer;
        }
      }
    }

    .tagsBox {
      margin-top: 43px;
      text-align: center;

      > strong {
        font-size: 14px;
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 8px 5px;
        margin-top: 16px;

        label {
          span {
            cursor: pointer;
            display: inline-block;
            padding: 7px 12px;
            font-size: 12px;
            color: #28284b;
            border-radius: 20em;
            border: 1px solid #dfdfea;
          }

          input[type='checkbox']:checked + span {
            color: #fff;
            background-color: #28284b;
            border-color: #28284b;
          }
        }
      }
    }

    .textBox {
      margin-top: 22px;

      .innerText {
        position: relative;
      }

      textarea {
        resize: none;
        width: 100%;
        min-height: 122px;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid #e7e7f0;
        box-sizing: border-box;
      }

      .textLength {
        position: absolute;
        bottom: 12px;
        right: 12px;
        font-size: 12px;
        color: #b7b7c8;

        span {
          color: #000;
        }
      }

      .textCaution {
        margin-top: 8px;
        font-size: 12px;
        color: #ff5647;
      }
    }

    .btnSubmit {
      width: 100%;
      padding: 17px 0;
      font-size: 16px;
      color: #fff;
      background-color: #ffb900;

      &:disabled {
        cursor: default;
        background-color: #cfcfda;
      }
    }
  }
}
