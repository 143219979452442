.faqSearchList {
  margin-bottom: 76px;
  .searchTitle {
    display: flex;
    flex-direction: column;
    gap: 38px;

    h4 {
      color: #000;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.5px;
      span {
        color: #ffb900;
      }
    }
    .searchCount {
      color: #000;
      font-size: 25px;
      font-weight: 700;
    }
  }

  .searchEmpty {
    .notResult {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 18px;
      padding: 110px 0 210px;

      p {
        color: #000;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -0.5px;
      }
    }

    .emptyIcon {
      width: 150px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .searchTip {
      strong {
        color: #717188;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.2px;
      }
      ul {
        margin-top: 12px;
        padding-left: 18px;
        li {
          list-style: disc;
          color: #a0a0b6;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.3px;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .faqSearchList {
    margin-bottom: 100px;

    .searchTitle {
      gap: 10px;
      margin-bottom: 42px;
      padding: 0;

      h4 {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.5px;
      }
      .searchCount {
        font-size: 28px;
        font-weight: 400;
      }
    }

    .searchEmpty {
      padding: 0 40px;
      .notResult {
        gap: 24px;
        padding: 175px 0 280px;

        p {
          font-size: 35px;
          line-height: 48px;
          letter-spacing: -0.5px;
        }
      }

      .searchTip {
        strong {
          font-size: 28px;
          letter-spacing: 0.2px;
        }
        ul {
          margin-top: 20px;
          padding-left: 18px;
          li {
            font-size: 24px;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .faqSearchList {
    margin-bottom: 13.333vw;

    .searchListInner {
      margin: 0 -5.333vw;
    }

    .searchTitle {
      gap: 1.333vw;
      margin-bottom: 5.6vw;
      padding: 0 5.6vw;
      h4 {
        font-size: 5.333vw;
        line-height: 6.4vw;
        letter-spacing: -0.067vw;
      }
      .searchCount {
        font-size: 3.733vw;
      }
    }

    .searchEmpty {
      padding: 0 5.333vw;
      .notResult {
        gap: 3.2vw;
        padding: 23.333vw 0 37.333vw;

        .emptyIcon {
          width: 23.733vw;
          height: 23.733vw;
        }

        p {
          font-size: 4.667vw;
          line-height: 6.4vw;
          letter-spacing: -0.5px;
        }
      }

      .searchTip {
        strong {
          font-size: 3.733vw;
          letter-spacing: 0.2px;
        }
        ul {
          margin-top: 2.667vw;
          padding-left: 2.667vw;
          li {
            font-size: 3.2vw;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
}
