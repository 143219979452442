.reviewTextarea {
  background-color: #fff;
  textarea {
    height: 250px;
    width: 100%;
    padding: 24px 16px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    resize: none;

    font-size: 14px;
    line-height: 1.4;
    color: #000;

    &::placeholder {
      color: #b7b7c8;
    }
  }
  .textLength {
    padding: 4px 16px 24px;
    text-align: right;
    font-size: 12px;
    color: #b7b7c8;

    em {
      color: #000;
    }
  }
}
