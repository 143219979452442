.noticeBox {
  padding: 3.7rem 3.2rem;

  dl {
    dt,
    dd {
      font-weight: 400;
      font-size: 1.6rem;
      color: #000;
      line-height: 1.4;
    }

    dd {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 6.1rem 4.2rem;

    dl {
      dt,
      dd {
        font-size: 2.8rem;
      }

      dd {
        margin-top: 2.5rem;
      }
    }
  }
}
