.relatedFaq {
  margin-left: 54px;
  margin-top: 20px;
  max-width: 358px;
  box-sizing: border-box;

  dt {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #a0a0b6;
  }

  dd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    height: 52px;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #28284b;

    cursor: pointer;

    span {
      flex: 1;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: #ffb900;
      border-radius: 20px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    + dd {
      border-top: 1px solid #ececf6;
    }
  }

  @media screen and (max-width: 750px) {
    margin-top: 4vw;
    margin-left: 10.4vw;
    max-width: 63.733vw;

    dt {
      font-size: 2.667vw;
      line-height: 3.467vw;
    }

    dd {
      height: auto;
      padding: 3.067vw 0;
      gap: 3.467vw;

      font-size: 3.2vw;
      line-height: 4vw;

      i {
        width: 4.267vw;
        height: 4.267vw;
        border-radius: 2.133vw;

        svg {
          width: 3.467vw;
          height: 3.467vw;
        }
      }
    }
  }
}
