.aiShare {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  height: 810px;

  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.scroll {
  flex: 1;
  overflow: auto;
}
.header {
  width: 100%;
}
.header h2 {
  width: 100%;
  height: 64px;
  overflow: hidden;
  border-bottom: 1px solid #dfdfea;
  font-size: 19px;
  font-weight: 400;
  line-height: 70px;
  text-align: center;
}
.header button {
  position: absolute;
  top: 10px;
  right: 24px;
  display: block;
  width: 44px;
  height: 44px;
  white-space: nowrap;
  text-indent: -1000px;
  overflow: hidden;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_modal_close.svg')
    center / cover no-repeat;
}
.title {
  padding: 27px 0 46px;
  text-align: center;
}
.title picture {
  display: block;
  width: 130px;
  margin: 0 auto;
}
.title h3 {
  color: #000;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 39px;
  margin: 18px 0 16px;
}
.title h3 span {
  color: #ffb900;
}
.subText {
  padding: 0 32px 58px;
}
.subText dl {
  background-color: #f7f7fc;
  border-radius: 6px;
  padding: 34px 24px 56px;
}
.subText dt {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 10px;
}
.subText dd {
  color: #717188;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.14px;
}
.subText dt picture {
  display: block;
  width: 124px;
  margin-bottom: 24px;
}
.subText dt:first-child + dd::after {
  display: block;
  width: 100%;
  height: 0.5px;
  background-color: #b7b7c8;
  margin: 32px 0;
  content: '';
}
.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 32px 32px;
  text-align: center;
}
.btnGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 24px;
}
.copy,
.kakao {
  height: 64px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
.copy {
  border: 1px solid #dfdfea;
}
.copy input {
  display: none;
}
.kakao {
  background-color: #ffb900;
}
.band {
  color: #717188;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
}
@media screen and (max-width: 750px) {
  .aiShare {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    border-radius: 0;
  }
  .scroll {
    overflow: auto;
  }
  .header h2 {
    height: 13.867vw;
    font-size: 3.733vw;
    font-weight: 500;
    line-height: 14.667vw;
    letter-spacing: -0.037vw;
  }
  .header button {
    top: 2.4vw;
    right: 4.533vw;
    width: 9.6vw;
    height: 9.6vw;
  }
  .title {
    padding: 9.333vw 0 10.667vw;
  }
  .title picture {
    width: 32.533vw;
  }
  .title h3 {
    font-size: 5.867vw;
    line-height: 8.267vw;
    margin: 3.6vw 0 4.8vw;
  }
  .subText {
    padding: 0 5.333vw 8vw;
  }
  .subText dl {
    border-radius: 1.333vw;
    padding: 8.267vw 5.333vw;
  }
  .subText dt {
    font-size: 4.267vw;
    line-height: 6.133vw;
    margin-bottom: 2.667vw;
    letter-spacing: -0.067vw;
  }
  .subText dd {
    font-size: 3.2vw;
    line-height: 5.333vw;
    letter-spacing: -0.032vw;
  }
  .subText dt picture {
    width: 23.2vw;
    margin-bottom: 5.333vw;
  }
  .subText dt:first-child + dd::after {
    margin: 7.467vw 0;
  }
  .footer {
    padding: 3.333vw 5.333vw 5.733vw;
    text-align: center;
  }
  .btnGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.067vw;
    margin-bottom: 3.2vw;
  }
  .copy,
  .kakao {
    height: 12.8vw;
    border-radius: 0.533vw;
    font-size: 3.733vw;
    line-height: 4.533vw;
  }
  .band {
    font-size: 3.2vw;
    line-height: 4.533vw;
  }
}
/* ETD-3489 last update */

.animationBox {
  width: 100%;
  height: 297px;
  margin-top: -40px;
  position: relative;
  overflow: hidden;
}

.animationBox canvas {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -90px;
}

@media screen and (max-width: 750px) {
  .animationBox {
    height: 61.867vw;
    margin-top: -8.667vw;
  }

  .animationBox canvas {
    bottom: -18.933vw;
  }
}
