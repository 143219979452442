.btnModalClose {
  position: absolute;
  top: 2.2rem;
  right: 2rem;
  width: 4.4rem;
  height: 4.4rem;
  z-index: 10;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 2.8rem;
    height: 1px;
    margin: auto;
    border-bottom: 2px solid #000;
  }

  &:before {
    left: 0;
    right: 2px;
    transform: rotate(-45deg);
  }

  &:after {
    left: 0;
    right: 0;
    transform: rotate(45deg);
  }

  span {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
    right: 2.5rem;
    width: 7.2rem;
    height: 7.2rem;

    &:before,
    &:after {
      width: 3.4rem;
      border-bottom: 1px solid #000;
    }
  }
}

.saleInfoBox {
  width: 48rem;
  height: 35rem;
  padding: 0 2rem;
  font-size: 2rem;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    width: 65rem;
    height: 48rem;
    padding: 0 2.5rem;
    font-size: 3rem;
  }
}

.modalHeader {
  margin-bottom: 3rem;
  padding: 3.3rem 0 3.2rem 2rem;
  font-size: 2.4rem;
  border-bottom: 1px solid #dfdfea;

  @media screen and (max-width: 1024px) {
    margin-bottom: 5.9rem;
    padding: 4.2rem 0 4.2rem 3rem;
    font-size: 3.2rem;
  }
}

.modalContent {
  position: relative;
  padding: 0;
  overflow: auto;
  box-sizing: border-box;

  dl {
    display: flex;
    padding: 1.2rem 2.2rem;
    justify-content: space-between;
    align-items: center;
  }

  dt {
    color: #717188;
  }

  dd {
    span,
    strong {
      &:after {
        content: '원';
      }
    }
    mark {
      display: inline-block;
      margin-right: 0.9rem;
      color: #ff5647;
      background-color: transparent;
      font-size: 2rem;
      font-weight: 700;
    }
    strong {
      font-size: 700;

      &:before {
        content: '월';
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    dl {
      padding: 1.5rem 3rem;
    }

    dd {
      mark {
        font-size: 3rem;
      }
    }
  }
}
