.skeletonBox {
  position: relative;
  margin-bottom: 1.5rem;
  padding: 3.5rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
  overflow: hidden;

  :global(.react-loading-skeleton) {
    height: 1.4rem;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div {
        margin-left: 2rem;
      }
    }

    & + p {
      margin-top: 1.5rem;
    }
  }

  p {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 4rem;

    :global(.react-loading-skeleton) {
      height: 2.8rem;
    }

    > div {
      align-items: flex-start;

      & + p {
        margin-top: 3rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  li:has(.skeletonBox):first-of-type {
    margin-top: 4.2rem;
  }
}
