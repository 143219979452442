@import './variable.scss';

.submain-banner-col {
  .inner-col {
    max-width: 100%;
    width: 100%;
    // height: 51.2rem;
    margin-bottom: 10rem;
    padding: 0;

    div > a {
      display: block;
      width: 100%;
      // height: 51.2rem;
      background-color: #ccc;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    .swiper-pagination {
      display: none !important;
    }
  }

  &.store {
    .banner-slide {
      .swiper-container {
        padding-bottom: 3rem;

        .swiper-pagination {
          display: block !important;
        }

        .swiper-pagination-bullet {
          width: 0.8rem;
          height: 0.8rem;
          bottom: 0;
          background-color: $gray-400;
          opacity: 1;
        }

        .swiper-pagination-bullet-active {
          background-color: $gray-800;
        }
      }

      .slide-item {
        img {
          width: 1920px;
        }
      }
    }
    .banner-col + div {
      background-color: #191a26;
      text-align: center;
    }
  }
}
.class-list-col {
  margin: 0 4.2rem;

  h2 {
    margin-top: 8rem;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    text-transform: uppercase;
  }
  .class-items {
    font-size: 1.6rem;
    overflow: hidden;

    & + h2 {
      margin-top: 4rem;
    }

    & ~ .list-more-box {
      & + h2 {
        margin-top: 8rem;
      }
    }

    li {
      float: left;
      width: 23.5%;
      max-height: 34.8rem;
      margin: 0 2% 6rem 0;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .icon-lists {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        align-items: center;

        span {
          width: 6.4rem;
          height: 2.6rem;
          line-height: 2.6rem;
          font-weight: 400;
          font-size: 1.3rem;
          color: #fff;
          text-transform: uppercase;
          text-align: center;

          &.ranking {
            background-color: #ff5647;
          }

          &.package {
            background-color: #28284b;
          }

          &.course {
            background-color: #269cff;
          }
        }
      }

      .class-num {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 3.8rem;
        font-size: 1.2rem;
        line-height: 3.8rem;
        color: #fff;
        background: rgba(59, 59, 77, 0.9);
        text-align: center;
        transform: translateY(100%);
        transition: all 0.3s;
      }
    }

    .early {
      box-sizing: border-box;
      position: absolute;
      bottom: 0.6rem;
      right: 0.6rem;
      z-index: 1;
      width: 6.4rem;
      height: 2.6rem;
      line-height: 2.6rem;
      font-weight: 400;
      font-size: 1.3rem;
      color: #a0a0b6;
      border-radius: 0.2rem;
      border: 0.1rem solid #e7e7f0;
      background-color: #fff;
      text-align: center;
    }

    a {
      display: block;
      overflow: hidden;

      &:hover {
        img {
          // transform: scale(1.2);
        }

        .class-num {
          transform: none;
        }
      }

      & > div {
        position: relative;
        overflow: hidden;
        border-radius: 0.5rem;
      }

      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      picture > img {
        width: 100%;
        height: 100%;
      }

      picture + div {
        &::after {
          content: '';
          display: block;
          padding-bottom: 67%;
        }

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 3;

          // & > span {
          //   display: inline-block;
          //   width: 11rem;
          //   padding: 0.8rem 0;
          //   font-weight: 700;
          //   font-size: 1.8rem;
          //   color: #fff;
          //   background-color: #ff5647;
          //   border-radius: 2rem;
          //   text-align: center;
          // }

          // & > .wating {
          //   color: #28284B;
          //   background-color: #fff;
          // }
        }
      }

      & .thumb {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: inherit;
        transform: scale(1);
        transition: transform 0.5s;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .submain-banner-col {
    .inner-col {
      margin-bottom: 5rem;
    }

    &.store {
      .banner-slide {
        .swiper-container {
          padding-bottom: 5rem;

          .swiper-pagination-bullet {
            width: 1.2rem;
            height: 1.2rem;
          }
        }

        .slide-item {
          img {
            width: 750px;
          }
        }
      }
    }
  }

  .class-list-col {
    .inner-col {
      padding: 0;
    }

    & + .home-col {
      margin-top: 3.6rem;
    }

    h2 {
      font-size: 2.8rem;
    }
    .class-items {
      & + h2 {
        margin-top: 4.5rem;
      }

      & ~ .list-more-box {
        & + h2 {
          margin-top: 10rem;
        }
      }

      li {
        width: 49%;
        max-height: inherit;
        margin-bottom: 6.4rem;

        & > a > div {
          border-radius: 1rem;
        }

        &:nth-child(4n) {
          margin-right: 2%;
        }

        &:nth-child(even) {
          margin: 0;
        }

        .icon-lists {
          span {
            width: 10rem;
            height: 4rem;
            line-height: 4rem;
            font-size: 2.2rem;
          }
        }

        .class-num {
          // display: none;
        }
      }

      .early {
        bottom: 1.2rem;
        right: 1.2rem;
        width: 10rem;
        height: 4rem;
        line-height: 4rem;
        font-size: 2.2rem;
        border-radius: 0.2rem;
        border: 0.2rem solid #e7e7f0;
      }

      a {
        picture {
          min-height: 22rem;
          height: auto;
        }

        picture + div {
          &::after {
            padding-bottom: 68%;
          }

          & > div {
            height: auto;

            & > span {
              width: 13.8rem;
              padding: 0.8rem 0;
              font-size: 2.2rem;
            }
          }
        }
      }
    }
  }
}
