.btn {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border-radius: 15px;
  background-color: #05aaff;
}
.btn svg {
  vertical-align: middle;
}

.btn.pulse {
  animation: pulse-animation 2s infinite;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@media screen and (max-width: 1024px) {
  .btn {
    width: 44px;
    height: 44px;
    border-radius: 22px;
  }
}
@media screen and (max-width: 750px) {
  .btn {
    width: 5.8667vw;
    height: 5.8667vw;
    border-radius: 3vw;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(5, 170, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(5, 170, 255, 0);
  }
}
