.step > span {
  position: absolute;
  z-index: 1;
}
.step > .btn01,
.step > .btn02 {
  z-index: 2;
  cursor: pointer;
}
.step01 .btn01 {
  left: 625px;
  top: 186px;
}
.step01 .btn02 {
  left: 657px;
  top: 373px;
}
.step01 .tooltip01 {
  left: 631px;
  top: 225px;
}
.step01 .tooltip02 {
  left: 657px;
  top: 313px;
}
.step01 .tooltip01 picture {
  width: 310px;
}
.step02 .btn01 {
  left: 858px;
  top: 178px;
}
.step02 .tooltip01 {
  left: 656px;
  top: 212px;
}
.step03 .btn01 {
  left: 643px;
  top: 461px;
}
.step03 .tooltip01 {
  left: 674px;
  top: 404px;
}
.step03 .tooltip01 picture {
  width: 289px;
}
.step04 .btn01 {
  left: 733px;
  top: 246px;
}
.step04 .btn02 {
  left: 607px;
  top: 511px;
}
.step04 .tooltip01 {
  left: 740px;
  top: 177px;
}
.step04 .tooltip02 {
  left: 608px;
  top: 450px;
}
.step04 .tooltip01 picture {
  width: 265px;
}
.step04 .tooltip02 picture {
  width: 379px;
}
.step05 .btn01 {
  left: 836px;
  top: 380px;
}
.step05 .tooltip01 {
  left: 558px;
  top: 424px;
}
.step06 .btn01 {
  left: 515px;
  top: 429px;
}
.step06 .tooltip01 {
  left: 280px;
  top: 468px;
}

@media screen and (max-width: 1024px) {
  .step01 .btn01 {
    left: 59.5384%;
    top: 61.6279%;
  }
  .step01 .btn02 {
    left: 63.3846%;
    top: 79.186%;
  }
  .step01 .tooltip01 {
    left: 32.1538%;
    top: 68.4883%;
  }
  .step01 .tooltip02 {
    left: 34%;
    top: 84.5348%;
  }
  .step02 .btn01 {
    left: 88.6153%;
    top: 60.3488%;
  }
  .step02 .tooltip01 {
    left: 51.0769%;
    top: 65.9302%;
  }
  .step03 .btn01 {
    left: 58.923%;
    top: 87.2093%;
  }
  .step03 .tooltip01 {
    left: 36%;
    top: 76.7441%;
  }
  .step04 .btn01 {
    left: 74.4615%;
    top: 66.3953%;
  }
  .step04 .btn02 {
    left: 57.5384%;
    top: 91.3953%;
  }
  .step04 .tooltip01 {
    left: 41.5384%;
    top: 56.7441%;
  }
  .step04 .tooltip02 {
    left: 21.3846%;
    right: -2.3076%;
    top: 82.9069%;
  }
  .step01 .tooltip01 picture,
  .step03 .tooltip01 picture,
  .step04 .tooltip01 picture,
  .step04 .tooltip02 picture {
    width: auto;
  }
  .step05 .btn01 {
    left: 85.5384%;
    top: 74.5348%;
  }
  .step05 .tooltip01 {
    left: 32%;
    top: 64.8837%;
  }
  .step06 .btn01 {
    left: 376px;
    top: 594px;
  }
  .step06 .tooltip01 {
    left: 50px;
    top: 500px;
  }
  .step06 .tooltip01 picture {
    width: 381px;
  }
}
@media screen and (max-width: 750px) {
  .step01 .tooltip01 img {
    width: 57.6vw;
  }
  .step01 .tooltip02 img {
    width: 50.2667vw;
  }
  .step02 .tooltip01 img {
    width: 43.2vw;
  }
  .step03 .tooltip01 img {
    width: 54.8vw;
  }
  .step04 .tooltip01 img {
    width: 49.7333vw;
  }
  .step04 .tooltip02 img {
    width: 72vw;
  }
  .step05 .tooltip01 img {
    width: 59.4667vw;
  }
  .step06 .btn01 {
    left: 50.133vw;
    top: 79.2vw;
  }
  .step06 .tooltip01 {
    left: 6.667vw;
    top: 66.667vw;
  }
  .step06 .tooltip01 picture {
    width: 50.8vw;
  }
}
