$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/';
.qnaTip {
  position: fixed;
  top: 52px;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  height: 36px;
  background-color: #f7f7fc;
  background-image: url('#{$img-path}ico_info.svg');
  background-size: 16px;
  background-position: 20px 10px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-left: 40px;

  .qnaTipText {
    color: #717188;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .btnInquiry {
    color: #269cff;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
  }

  @media screen and (min-width: 768px) {
    top: 75px;
  }
}
