.searchPopular {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;

  button {
    display: flex;
    padding: 0 22px;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #cfcfda;
    background: #fff;
    color: #28284b;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.5px;
    white-space: nowrap;

    span {
      display: block;
      margin-top: 2px;
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 20px 8px;
    button {
      height: 50px;
      font-size: 24px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 750px) {
    gap: 2.667vw 1.067vw;
    button {
      height: 6.667vw;
      padding: 0 2.933vw;
      font-size: 3.2vw;
      line-height: 3.467vw;
    }
  }
}
