.thumbBox {
  i {
    display: inline-block;
    position: relative;
    width: 3.8rem;
    height: 3.8rem;
    background-color: #cfcfda;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: inherit;
    }
  }

  span {
    width: 3.8rem;
    height: 3.8rem;
  }

  @media screen and (max-width: 1024px) {
    i {
      width: 6rem;
      height: 6rem;
    }
  }
}
