.levelProduct {
  padding: 120px 48px;
  background-color: #eef1f6;
  .levelTitle {
    p {
      font-size: 28px;
      font-weight: 400;
      line-height: 34px;
      text-align: center;
    }
    h4 {
      margin: 16px 0 60px;
      font-size: 44px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }
  .recommendedProducts {
    background-color: #fff;
    padding: 57px;
    border-radius: 48px;
    .productDetails {
      margin-top: 50px;
      strong {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.5px;
        text-align: left;
      }
      .productDescription {
        margin-top: 32px;
        font-size: 28px;
        font-weight: 400;
        line-height: 41.44px;
        letter-spacing: -0.04em;
        text-align: left;
        span {
          display: block;
          margin-top: -26px;
        }
      }
    }
  }
}
@media all and (max-width: 750px) {
  .levelProduct {
    padding: 16vw 6.4vw;

    .levelTitle {
      p {
        font-size: 3.7333vw;
        line-height: 4.5333vw;
      }
      h4 {
        margin: 2.1333vw 0 8vw;
        font-size: 5.8667vw;
        line-height: 7.4667vw;
        letter-spacing: -0.0667vw;
      }
    }
    .recommendedProducts {
      padding: 7.6vw;
      border-radius: 6.4vw;
      .productDetails {
        margin-top: 6.6667vw;
        strong {
          font-size: 5.3333vw;

          line-height: 6.4vw;
          letter-spacing: -0.0667vw;
        }
        .productDescription {
          margin-top: 4.2667vw;
          font-size: 3.7333vw;
          line-height: 5.5253vw;
          letter-spacing: -0.04em;
          span {
            margin-top: -3.4667vw;
          }
        }
      }
    }
  }
}
