.faqMain {
  padding-bottom: 76px;
}
@media screen and (max-width: 1024px) {
  .faqMain {
    padding-top: 108px;
    padding-bottom: 105px;
  }
}
@media screen and (max-width: 750px) {
  .faqMain {
    padding-top: 14.4vw;
    padding-bottom: 14vw;
    .faqListWrap {
      .csInner {
        padding: 0;
      }
    }
  }
}
