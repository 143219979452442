.dataNone {
  text-align: center;
  padding: 200px 0;
  color: #222;
  font-size: 14;
}
.loadingBox {
  max-width: 1200px;
  height: 100vh;
}
.contentsContainer {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}
.contentsInner {
  max-width: 78.6rem;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 12rem;
}
.topImageSection {
  position: relative;
  max-width: 1920px;
  height: 536px;
  margin: 0 auto;
  padding-top: 32px;
  overflow: hidden;
  .sliderOptionNone {
    :global .swiper-button-next,
    :global .swiper-button-prev,
    :global .swiper-pagination {
      display: none;
    }
  }
  :global .swiper-button-disabled {
    display: none;
  }
  :global .swiper-container {
    width: 786px;
    overflow: visible;
    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.7) 100%);
      position: absolute;
      top: 0;
      left: -100%;
      z-index: 10;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0.7) 100%
      );
      position: absolute;
      top: 0;
      right: -100%;
      z-index: 10;
    }
  }
  :global .swiper-button-prev {
    left: -30px;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.17);
    z-index: 99;
    &:after {
      width: 20px;
      height: 20px;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-slider-arrow.png)
        no-repeat;
      background-size: contain;
      content: '';
      transform: rotate(180deg);
    }
  }
  :global .swiper-button-next {
    right: -30px;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    z-index: 99;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.17);
    &:after {
      width: 20px;
      height: 20px;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-slider-arrow.png)
        no-repeat;
      background-size: contain;
      content: '';
    }
  }
  .imageSwiperSlide {
    max-height: 536px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    opacity: 0.5;
    img {
      filter: grayscale(100%);
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  :global .swiper-slide-active {
    opacity: 1;
    img {
      filter: none;
    }
  }
  :global .swiper-pagination {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    top: 20px;
    text-align: right;
    padding-right: 20px;
    box-sizing: border-box;
  }
  :global .swiper-pagination-inner {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 44px;
    height: 28px;
    border-radius: 14px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .hashList {
    position: absolute;
    color: #fff;
    font-size: 1.4rem;
    top: 3.5rem;
    left: 4.5rem;
    z-index: 2;
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      width: 80%;
    }
  }
}
.tabSection {
  transition: all 0.3s;
  background-color: #fff;
  border-top: 0.2rem solid #ececf6;
  border-bottom: 0.2rem solid #ececf6;
  width: 100%;

  &.fixed {
    position: fixed;
    width: 100%;
    z-index: 3;
  }

  ul {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    li {
      flex: 1;
      text-align: center;
      z-index: 8;

      button {
        display: block;
        padding: 20px 0;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
      }

      &.active {
        /* background: #28284b; */
        border-bottom: 0.2rem solid #28284b;
        margin-bottom: -0.2rem;
        button {
          font-weight: 700;
        }
      }
    }
  }
}
.detailInfo {
  padding-top: 70px;
  .title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.3rem solid #000;
    padding: 0 0 1.8rem;
    h1 {
      font-size: 25px;
    }
    .btnShare {
      width: 28px;
      height: 28px;
      text-indent: -999%;
      overflow: hidden;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-share.png)
        no-repeat;
      background-size: contain;
    }
  }

  img {
    width: 100% !important;
    height: auto !important;
  }

  .storeDetail {
    * {
      margin: 0;
      padding: revert;
      line-height: 1.45;
      font-size: 16px;
      vertical-align: bottom;
      font-family: 'NotoSansCJKkr', 'Inter', sans-serif !important;
    }
    p {
      span,
      strong {
        font-size: inherit;
        margin: 0;
        padding: 0;
      }
      :has(img) {
        margin-top: 0;
      }
    }
    br,
    .empty_block {
      margin: 14px 0;
      font-size: 0;
      line-height: 0;
      display: block;
    }
    img {
      width: 100% !important;
      height: auto !important;
      margin: 0;
      max-width: 100%;
    }
    table {
      all: revert;
      max-width: 100% !important;
      table-layout: fixed;
      border-width: 1px;
      border-style: solid;
      border-color: initial;
      border-spacing: 0px;
      border-collapse: collapse;
      th,
      td {
        all: revert;
        border-width: 1px;
        border-style: solid;
        border-color: #cfcfda;
        padding: 10px;
        vertical-align: top;
        word-wrap: break-word;
        word-break: break-all;
      }
      th {
        background-color: #f7f7fc;
        font-weight: bold;
      }
    }
  }
  .curriculum {
    display: flex;
    justify-content: space-between;
    padding: 41px 0;
    flex-wrap: wrap;
    .curriculumItem {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 15px;
      display: flex;
      width: 50%;
      i {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 6px;
        flex: none;
        &.count {
          background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-info-count.png);
        }
        &.period {
          background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-info-period.png);
        }
        &.level {
          background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-info-level.png);
        }
        &.benefit {
          background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-info-benefit.png);
        }
      }
      span {
        color: #a0a0b6;
        display: inline-block;
        margin-right: 18px;
        flex: none;
        min-width: 54px;
      }
      b {
        color: #28284b;
        font-weight: 700;
      }
    }
  }
}
.classInfoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 70px;

  .classTitle {
    text-align: center;
    margin: 32px 0;
    .category {
      color: #8d8da0;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
    .title {
      margin-top: 6px;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.5px;
      font-weight: 500;
      max-width: 100%;
      word-break: keep-all;
      max-width: 500px;
    }
    .titleDescription {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      .iconStar {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/icon-review-star.png)
          no-repeat;
        background-size: cover;
        margin-top: -3px;
        margin-right: 4px;
      }
      .teacher {
        margin-left: 12px;
        font-weight: 400;
      }
    }
  }
  .couponButton {
    width: 360px;
    height: 56px;
    margin-bottom: 10px;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/bg-coupon-download-button.png)
      no-repeat;
    background-size: contain;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/image-coupon.png)
        no-repeat;
      background-size: cover;
      margin-right: 8px;
      margin-top: -2px;
    }
  }
}
.installmentsButton {
  margin-top: 16px;
  position: relative;
  &.fixed {
    width: 10rem;
    margin-left: 2rem;
  }
  span {
    color: #8d8da0;
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
.priceButton {
  width: 360px;
  height: 56px;
  background: #28284b;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  em {
    color: #ffb900;
  }
  .iconTimeSale {
    display: inline-block;
    width: 52px;
    height: 52px;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/bg-time-sale.png)
      no-repeat;
    background-size: cover;
    position: absolute;
    top: -12px;
    right: -28px;
    white-space: pre-line;

    .contents {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .image {
      width: 36px;
      height: 36px;
    }
    .text {
      width: 26px;
      font-size: 14px;
      color: #fff;
      font-weight: 700;
      white-space: pre-line;
      font-style: initial;
    }
  }
}
.floatingPrice {
  background-color: rgba(40, 40, 75, 0.7);
  padding: 8px 8px 8px 21px;
  box-sizing: border-box;
  max-width: 786px;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  position: fixed;
  display: block;
  bottom: 8px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .installmentsButton {
    margin-top: 0;
    span {
      color: #ececf6;
    }
  }
}
.scrollToTop {
  position: fixed;
  right: 50%;
  height: 0;
  transform: translateX(-50%);
  bottom: 136px;
  left: 50%;
  width: 100%;
  max-width: 19200px;
  text-align: right;
  z-index: 0;
  padding-right: 193px;
  button {
    display: inline-block;
    width: 56px;
    height: 56px;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/image-top-button.png)
      no-repeat;
    background-size: contain;
    text-indent: -999999em;
  }
}
@media screen and (max-width: 1024px) {
  .contentsInner {
    padding: 0 0 13.333vw;
    box-sizing: border-box;
    max-width: 100%;
  }
  .detailInfo {
    padding: 13.333vw 5.6vw 0;
    .curriculum {
      padding: 4.004vw 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .topImageSection {
    height: auto;
    padding-top: 0;
    :global .swiper-container {
      height: auto;
      width: 100%;
      &:after,
      &:before {
        display: none;
      }
    }
    :global .swiper-button-prev,
    :global .swiper-button-next {
      display: none;
    }
    :global .swiper-pagination {
      width: 100%;
      top: 2.667vw;
      padding-right: 2.667vw;
    }
    :global .swiper-pagination-inner {
      font-size: 2.667vw;
      height: 5.333vw;
      line-height: 6vw;
      width: 8.133vw;
      border-radius: 2.667vw;
    }
    .imageSwiperSlide {
      border-radius: 0;
      img {
        filter: none;
        width: 100%;
      }
    }
  }
  .classInfoSection {
    align-items: flex-start;
    padding: 0 5.6vw;
    .classTitle {
      text-align: left;
      margin: 5.333vw 0 8vw;
      .category {
        font-size: 3.733vw;
        line-height: 4.533vws;
        color: #a0a0b6;
      }
      .title {
        margin-top: 2.667vw;
        font-size: 5.333vw;
        line-height: 7.467vw;
        max-width: 100%;
      }
      .titleDescription {
        margin-top: 4.267vw;
        justify-content: flex-start;
        font-size: 3.733vw;
        .iconStar {
          width: 4.8vw;
          height: 4.8vw;
          margin-top: -0.5vw;
          margin-right: 0.533vw;
        }
      }
    }

    .couponButton {
      width: 100%;
      height: 12.8vw;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/store/detail/bg-coupon-download-button-m.png)
        no-repeat;
      background-size: contain;
      font-size: 3.733vw;
      i {
        width: 5.333vw;
        height: 5.333vw;
      }
    }
  }

  .detailInfo {
    .title {
      padding-bottom: 3.2vw;
      margin-bottom: 2.533vw;
      border-width: 0.4vw;
      .btnShare {
        width: 4.8vw;
        height: 4.8vw;
      }
      h1 {
        font-size: 4.267vw;
        line-height: 6.267vw;
      }
    }
    .curriculum {
      padding: 5.467vw 0;
      .curriculumItem {
        margin-bottom: 4.801vw;
        font-size: 3.301vw;
        line-height: 4.667vw;
        align-items: flex-start;
        i {
          width: 5.333vw;
          height: 5.333vw;
          margin-right: 1.2vw;
        }
        span {
          margin-right: 2.667vw;
          min-width: 10vw;
        }
      }
    }
    .storeDetail {
      table {
        width: 100% !important;
      }
    }
  }

  .installmentsButton {
    line-height: 4.533vw;
    margin: 5.333vw auto 0;
    span {
      font-size: 3.733vw;
    }
  }

  .tabSection {
    border-top: 0;
    border-bottom: 0.3rem solid #ececf6;

    ul {
      li {
        button {
          padding: 3.333vw 0;
          font-size: 3.2vw;
        }
        &.active {
          border-bottom: 0.3rem solid #28284b;
          margin-bottom: -0.3rem;
        }
      }
    }
  }

  .priceButton {
    width: 100%;
    height: 12.8vw;
    font-size: 3.733vw;
    border-radius: 0.533vw;
    .iconTimeSale {
      width: 9.6vw;
      height: 9.6vw;
      right: -3vw;
      top: -2vw;
      .text {
        width: 4.933vw;
        font-size: 2.5vw;
      }
      .image {
        width: 6.4vw;
        height: 6.4vw;
      }
    }
  }
  .contentsInner {
    max-width: 100%;
  }
  .scrollToTop {
    display: none;
  }

  .hashList {
    font-size: 2.4rem;
    left: 4.2rem;
  }
  .floatingPrice {
    padding: 1.6rem 4.267vw;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    max-width: 100%;
    bottom: env(safe-area-inset-bottom, 0);

    &::after {
      width: 100vw;
      height: env(safe-area-inset-bottom, 0);
      background: #fff;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      content: '';
    }

    .inner {
      padding: 0 3.2rem;
    }

    .priceButton {
      width: initial;
      height: 9.6vw;
      font-size: 3.733vw;
      padding: 0 4.4vw;
      .mobileN {
        display: none;
      }
      .iconTimeSale {
        top: -7vw;
      }
    }

    .installmentsButton {
      margin: 0;
    }
  }
}
