@import '../../exam//styles/variables.scss';

.container {
  display: block;
  padding: 100px 0;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
}
.description {
  width: 190px;
  margin: 0 auto;
}
.title {
  margin-top: 14px;
  color: #161c22;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px;
}
.list {
  margin: 60px auto 0;
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 844px;
  li {
    border-radius: 18px;
    border: 1px solid rgba(3, 6, 23, 0.1);
    background: #f6f8fa;
  }
}
.textWrap {
  padding: 40px 0 60px;
  box-sizing: border-box;
  .name {
    color: #161c22;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.5px;
  }
  .time {
    margin-top: 4px;
    color: #161c22;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .checkWrap {
    display: inline-block;
    margin: 28px auto 0;
  }
  .check {
    display: flex;
    gap: 6px;
    color: #324053;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    img {
      width: 20px;
      height: 20px;
      flex: none;
    }
    & + .check {
      margin-top: 10px;
    }
  }
}
.button {
  margin: 68.002px auto 0;
  border-radius: 109.268px;
  background: linear-gradient(100deg, #ff7214 2.72%, #ff3c00 95.29%);
  width: 333.998px;
  height: 80.002px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.465px;
  &.conversation {
    background: linear-gradient(100deg, #5199ff 2.72%, #3570f2 52.71%, #1d4de8 95.29%);
  }
  &.show {
    display: block;
  }
}
@media screen and (max-width: 750px) {
  .container {
    padding: 16vw 0;
  }
  .description {
    width: 37.067vw;
  }
  .title {
    margin-top: 3.2vw;
    font-size: 7.467vw;
    line-height: 8.533vw;
    letter-spacing: -0.067vw;
    font-size: 7.467vw;
    line-height: 8.533vw;
    letter-spacing: -0.067vw;
  }
  .list {
    margin-top: 10.133vw;
    padding: 0 10vw;
    grid-template-columns: none;
    gap: 5.333vw;
    li {
      border-radius: 3.733vw;
      border: 0.267vw solid rgba(3, 6, 23, 0.1);
      background: #f6f8fa;
    }
  }
  .textWrap {
    padding: 8.267vw 0 7.2vw;
    box-sizing: border-box;
    .name {
      color: #161c22;
      font-size: 5.867vw;
      font-weight: 700;
      line-height: 6.935vw;
      letter-spacing: -0.062vw;
    }
    .time {
      margin-top: 0.933vw;
      color: #161c22;
      text-align: center;
      font-size: 3.2vw;
      line-height: 4vw;
      margin-bottom: 4.533vw;
    }
    .checkWrap {
      display: inline-block;
      margin: 0 auto;
    }
    .check {
      display: flex;
      gap: 1.333vw;
      color: #324053;
      font-size: 3.733vw;
      font-weight: 500;
      line-height: 4.533vw;
      letter-spacing: -0.133vw;
      text-align: left;
      img {
        width: 4.267vw;
        height: 4.267vw;
        flex: none;
      }
      & + .check {
        margin-top: 2.667vw;
      }
    }
  }
  .button {
    margin: 9.067vw auto 0;
    border-radius: 14.569vw;
    background: linear-gradient(100deg, #ff7214 2.72%, #ff3c00 95.29%);
    width: 44.533vw;
    height: 10.667vw;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    font-size: 4vw;
    font-weight: 700;
    letter-spacing: -0.062vw;
    &.conversation {
      background: linear-gradient(100deg, #5199ff 2.72%, #3570f2 52.71%, #1d4de8 95.29%);
    }
  }
}
