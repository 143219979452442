.starPoint {
  position: relative;
  overflow: hidden;

  .starTrack {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: auto 100%;
    z-index: 2;
  }

  .starFill {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: auto 100%;
    max-width: 100%;
    text-indent: -9999rem;
    overflow: hidden;
  }
}
