$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
.help-col {
  h3 {
    padding-top: 7.3rem;
    font-weight: 700;
    font-size: 2rem;
  }

  &.device {
    background-color: #f7f7fc;

    .inner-col {
      background-color: #f7f7fc;

      #lnb {
        .lnb-col {
          background-color: #f7f7fc;
        }
      }
    }
  }

  #lnb + .content {
    padding-bottom: 17.7rem;
  }
}

.help-info-col {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 2.1rem;

  p {
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 1.6rem;

    strong {
      display: block;
      margin-bottom: 1.4rem;
      font-weight: 700;
      font-size: 3rem;
    }
  }

  .help-btn-area {
    flex: 0 0 auto;

    button {
      display: block;
      width: 27.2rem;
      padding: 1.2rem 0;
      font-weight: 400;
      font-size: 1.6rem;
      background-color: #fff;
      border: 0.1rem solid #dfdfea;
      border-radius: 0.4rem;

      &.btn-consulting-call {
        display: none;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 2.8rem;
        height: 2.8rem;
        margin-top: -0.25rem;
        margin-right: 1rem;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 100%;
        vertical-align: middle;
      }

      &.btn-consulting-kakao {
        &::before {
          background-image: url('#{$img-cdn}/ico_consulting_kakao_153x153.png');
        }
      }

      &.btn-inquiry {
        &::before {
          background-image: url('#{$img-cdn}/ico_inquiry_114x114.png');
          background-size: 2rem;
        }
      }

      + button {
        margin-top: 0.9rem;
      }
    }
  }
}

.help-list-col {
  margin-top: 6.1rem;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4.8rem 0 5.8rem;
    border-top: 0.2rem solid #000;

    li {
      flex: 1 1 auto;
      font-weight: 700;
      font-size: 1.6rem;
      background-repeat: no-repeat;
      background-position: 50% 0;
      text-align: center;

      &.device {
        background-image: url('#{$img-cdn}/ico_service_device.png');
        background-size: 8.3rem auto;
      }

      &.faq {
        background-image: url('#{$img-cdn}/ico_service_faq_213x171.png');
        background-size: 7.1rem auto;
      }

      &.notice {
        background-image: url('#{$img-cdn}/ico_service_notice_231x165.png');
        background-size: 7.7rem auto;
      }

      &.refund {
        background-image: url('#{$img-cdn}/ico_service_refund_231x165.png');
        background-size: 7.7rem auto;
      }

      a {
        display: block;
        padding-top: 9rem;
      }
    }

    + ul {
      padding: 0;
      border-top: 0.1rem solid #dfdfea;
      border-bottom: 0.1rem solid #dfdfea;

      li {
        font-weight: 700;

        + li {
          border-left: 0.1rem solid #dfdfea;
        }

        a {
          padding: 2.2rem 0;
        }
      }
    }
  }
}

.app-download-col {
  margin: 7rem 0 17rem;

  p {
    font-weight: 700;
    font-size: 2rem;
  }

  .download-btn-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 3.7rem;

    button {
      flex: 0 1 auto;
      width: 49%;
      height: 6.8rem;
      font-weight: 700;
      font-size: 1.6rem;
      background-color: #ffb900;
      border-radius: 0.4rem;

      &.btn-aos {
        background: #28284b url('#{$img-cdn}/img_google_play.png') no-repeat 50% 50% / 14.2rem auto;
      }

      &.btn-ios {
        background: #28284b url('#{$img-cdn}/img_app_store.png') no-repeat 50% 50% / 13.4rem auto;
      }

      // + button{
      //     margin-left: 1.6rem;
      // }
    }
  }
}

.device-col {
  padding-top: 3.8rem;

  .device-title {
    font-size: 1.4rem;
    color: #9999aa;

    span {
      color: #000;
    }

    span + span {
      display: inline-block;
      margin-left: 0.25rem;
    }
  }
  .device-box {
    position: relative;

    ul + ul {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      margin: 0;
      box-sizing: border-box;
      box-shadow: 0 0 1rem #eee;
    }
  }

  .device-list {
    margin-top: 1.5rem;

    li {
      position: relative;
      min-height: 9.6rem;
      font-size: 1.6rem;
      color: #a0a0b6;
      border: 0.2rem dashed #cfcfda;
      border-radius: 0.6rem;
      box-sizing: border-box;
      text-align: center;

      + li {
        margin-top: 1.5rem;
      }

      &.active {
        border: none;
      }

      p {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1.8rem;
        margin: auto;
      }

      label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        height: 9.6rem;
        padding: 0 3.5rem 0 3.6rem;
        background-color: #fff;
        border-radius: 0.6rem;
        box-sizing: border-box;
        cursor: pointer;

        input[type='checkbox'] {
          display: none;

          + span {
            -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1.8rem;
            background-color: #ececf6;
            background-image: url('#{$img-cdn}/ico_check_216x216w.png');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: auto 1rem;
            border: 0.1rem solid #ececf6;
            border-radius: 50%;
          }

          &:checked {
            + span {
              background-color: #ffb900;
              background-image: url('#{$img-cdn}/ico_check_38x30b.png');
              border-color: #ffb900;
            }
          }

          &:disabled {
            + span {
              background-color: #fff;
              border-color: #ececf6;
            }
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 2.2rem;
          bottom: 0;
          width: 6.5rem;
          height: 6.5rem;
          margin: auto;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 100%;
        }

        &.progress {
          dt {
            &::before {
              content: '처리중';
              color: #ff5647;
            }
          }
        }
      }

      &.phone {
        label {
          &::before {
            background-image: url('#{$img-cdn}/ico_device_phone_65x65.png');
          }
        }
      }

      &.tablet {
        label {
          &::before {
            background-image: url('#{$img-cdn}/ico_device_tablet_65x65.png');
          }
        }
      }

      &.desktop {
        label {
          &::before {
            background-image: url('#{$img-cdn}/ico_device_desktop_65x65.png');
          }
        }
      }

      dl {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-weight: 700;
        text-align: left;

        dt {
          margin-top: 0.4rem;
          font-size: 1.4rem;
          color: #000;

          &::before {
            content: '정상';
            display: block;
            margin-bottom: 0.3rem;
            font-weight: 700;
            font-size: 1.2rem;
            color: #a0a0b6;
          }
        }

        dd {
          margin-top: 0.7rem;
          font-weight: 400;
          font-size: 1.1rem;
        }
      }
    }
  }

  .device-notice-box {
    margin-top: 3.7rem;
    color: #717188;

    p {
      font-weight: 700;
      font-size: 1.6rem;
    }

    ul {
      margin-top: 1.5rem;

      li {
        font-size: 1.2rem;

        &::before {
          content: '-';
          display: inline-block;
          margin-right: 0.2rem;
          line-height: 1.8;
        }
      }
    }
  }

  .btn-reset {
    display: block;
    width: 27rem;
    margin: 5rem auto 0;
    padding: 2.2rem 0;
    font-weight: 400;
    font-size: 2rem;
    background-color: #ffb900;
    border-radius: 0.3rem;
  }
}

.service-center {
  margin-left: 32.2rem;
}

@media screen and (max-width: 1024px) {
  .help-col {
    padding-bottom: 6.7rem;

    h3 {
      display: none;
    }

    &.device {
      background-color: #f1f1f9;

      .inner-col {
        background-color: #f1f1f9;
      }
    }
  }

  .help-info-col {
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 0;
    padding: 7rem 4.2rem 7.6rem;
    background-color: #f7f7fc;

    p {
      font-size: 3rem;

      strong {
        display: none;
      }

      span {
        display: block;
        line-height: 1.5;
      }
    }

    .help-btn-area {
      width: 100%;
      margin-top: 6rem;

      button {
        width: 100%;
        padding: 2.8rem 0;
        font-size: 2.6rem;
        border-width: 1px;
        border-radius: 0.5rem;

        &::before {
          width: 5.1rem;
          height: 5.1rem;
          margin-top: -0.25rem;
          margin-right: 1rem;
        }

        &.btn-consulting-call {
          display: block;
          color: #fff;
          background-color: #28284b;
          border: 0.4rem solid #28284b;

          &::before {
            width: 2.8rem;
            height: 3.5rem;
            background-image: url('#{$img-cdn}/ico_tell_84x105.png');
          }
        }

        &.btn-inquiry {
          &::before {
            background-size: 3.8rem;
          }
        }

        + button {
          margin-top: 1.2rem;
        }
      }
    }
  }

  .help-list-col {
    margin-top: 0;

    ul {
      flex-direction: column;
      padding: 0;
      border-top: none;

      li {
        width: 100%;
        font-weight: 700;
        font-size: 2.7rem;
        text-align: left;
        border-bottom: 0.1rem solid #e5e5e5;

        &.device {
          background-image: none;
        }

        &.faq {
          background-image: none;
        }

        &.notice {
          background-image: none;
        }

        &.refund {
          background-image: none;
        }

        a,
        button {
          position: relative;
          padding: 4rem 4.2rem;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 5.6rem;
            bottom: 0;
            width: 1.6rem;
            height: 2.8rem;
            margin: auto;
            background: url('#{$img-cdn}/ico_arrow_10x14g.png') no-repeat 50% 0 / 100%;
          }
        }
      }

      + ul {
        border: none;

        li {
          font-weight: 400;

          + li {
            border-left: none;
          }

          a,
          button {
            width: 100%;
            padding: 4rem 4.2rem;
            font-weight: 700;
            box-sizing: border-box;
            text-align: left;
          }
        }
      }
    }
  }

  .app-download-col {
    margin: 5.8rem 0 7rem;
    padding: 0 4.2rem;

    p {
      font-size: 2.7rem;
    }

    .download-btn-area {
      button {
        height: 10rem;
        font-size: 2.7rem;
        border-radius: 0.8rem;

        &.btn-aos {
          background-size: 18.3rem auto;
        }

        &.btn-ios {
          background-size: 17.3rem auto;
        }
      }
    }
  }

  .device-col {
    .device-title {
      display: none;
    }

    .device-list {
      padding: 0 3.1rem;

      li {
        min-height: 20rem;
        border-width: 0.3rem;
        border-radius: 1.4rem;

        p {
          height: 3.2rem;
          font-size: 2.7rem;
        }

        label {
          height: 20rem;
          padding: 0 3.8rem;
          border-radius: 1.4rem;

          input[type='checkbox'] {
            + span {
              display: block;
              width: 5rem;
              height: 5rem;
              margin-right: 3rem;
              background-size: auto 2.1rem;
              border-width: 0.4rem;
            }
          }

          &::before {
            right: 4.4rem;
            width: 12.5rem;
            height: 12.5rem;
          }
        }

        dl {
          dt {
            font-size: 2.9rem;

            &::before {
              margin-bottom: 0.8rem;
              font-size: 2.5rem;
            }
          }

          dd {
            margin-top: 1.4rem;
            font-size: 2.3rem;
          }
        }

        + li {
          margin-top: 2rem;
        }
      }
    }

    .device-notice-box {
      margin-top: 11.7rem;
      padding: 0 5.2rem;

      p {
        font-size: 2.9rem;
      }

      ul {
        li {
          font-size: 2.5rem;
        }
      }
    }

    .btn-reset {
      width: 88%;
      padding: 3.4rem 0;
      font-size: 2.7rem;
    }
  }

  .service-center {
    margin-left: 0;
  }
}
