@import '/src/assets/styles/variable.scss';

.container {
  width: 100%;
  margin: 14px 0 64px;
  overflow: hidden;
}
.deleteButton {
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
  position: absolute;
  svg {
    width: 100%;
    height: 100%;
  }
}
.imageList {
  white-space: nowrap;
  overflow-x: auto;
  padding: 0 32px;
  margin-top: 24px;
  flex: 1;
  scrollbar-color: #fff #e0e0e0;
  scrollbar-width: thin;
  padding-bottom: 5px;

  li {
    display: inline-block;
    position: relative;
    width: 93px;
    height: 93px;
    background-color: $gray-400;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    img {
      height: 100%;
      width: 100%;
    }
    & + li {
      margin-left: 7px;
    }
  }
}

.attachButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #fff;
  box-sizing: border-box;
  background-image: repeating-linear-gradient(
      0deg,
      #8d8da0,
      #8d8da0 6px,
      transparent 6px,
      transparent 11px,
      #8d8da0 11px
    ),
    repeating-linear-gradient(
      90deg,
      #8d8da0,
      #8d8da0 6px,
      transparent 6px,
      transparent 11px,
      #8d8da0 11px
    ),
    repeating-linear-gradient(
      180deg,
      #8d8da0,
      #8d8da0 6px,
      transparent 6px,
      transparent 11px,
      #8d8da0 11px
    ),
    repeating-linear-gradient(
      270deg,
      #8d8da0,
      #8d8da0 6px,
      transparent 6px,
      transparent 11px,
      #8d8da0 11px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  color: var(--GrayScale-900---000000, #000);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin: 0 32px;
  height: 72px;
  cursor: pointer;
}
.infoDescription {
  color: var(--Accent-Red---FF5647, #ff5647);
  font-size: 16px;
  line-height: 22px;
  margin: 15px 32px 0;
  display: block;
}

@media screen and (max-width: 750px) {
  .container {
    margin: 6.667vw 0 10.4vw;
  }
  .attachButton {
    margin: 0 5.6vw 0;
    height: 16vw;
    font-size: 4.267vw;
    background-image: repeating-linear-gradient(
        0deg,
        #8d8da0,
        #8d8da0 3px,
        transparent 3px,
        transparent 6px,
        #8d8da0 6px
      ),
      repeating-linear-gradient(
        90deg,
        #8d8da0,
        #8d8da0 3px,
        transparent 3px,
        transparent 6px,
        #8d8da0 6px
      ),
      repeating-linear-gradient(
        180deg,
        #8d8da0,
        #8d8da0 3px,
        transparent 3px,
        transparent 6px,
        #8d8da0 6px
      ),
      repeating-linear-gradient(
        270deg,
        #8d8da0,
        #8d8da0 3px,
        transparent 3px,
        transparent 6px,
        #8d8da0 6px
      );
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    svg {
      width: 5.867vw;
      height: 5.867vw;
      margin-right: 0.933vw;
    }
  }
  .imageList {
    margin-top: 5.333vw;
    padding: 0 5.6vw 1vw;

    li {
      width: 19.467vw;
      height: 19.467vw;
      & + li {
        margin-left: 1.333vw;
      }
    }
  }
  .infoDescription {
    font-size: 3.2vw;
    line-height: 4vw;
    margin: 3vw 0 0 5.6vw;
  }
  .deleteButton {
    width: 5.867vw;
    height: 5.867vw;
  }
}
