$HOST_CDN: 'https://english.yanadoocdn.com/upload/yanadoo/new/common/';
$COMMON_HEADER_HOST_CDN: 'https://english.yanadoocdn.com/upload/yanadoo/new/common/header/';

.subHeaderCompany {
  transition: 0.2s;
  margin: 0 auto;

  .inner {
    width: 100%;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
  .companyDescription {
    font-weight: 400;
    font-size: 1.2rem;
    color: #000;
    opacity: 0.6;
  }
  .companyList {
    display: flex;
    align-items: center;
    margin-right: -1.6rem;

    .companyItem {
      position: relative;
      &.active {
        .companyItemLink {
          font-weight: 700;
        }
      }
      .companyItemLink {
        display: inline-block;
        padding: 1rem 1.6rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #000;
        opacity: 0.6;

        &:hover {
          font-weight: 700;
          opacity: 1;
        }
      }

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -1px;
        top: 50%;
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background: #000;
        opacity: 0.2;
        transform: translateY(-50%);
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
      &.selected {
        .companyItemLink {
          font-weight: 700;
          opacity: 1;
        }
      }
    }
  }
}
.subHeaderGnb {
  display: block;
  position: relative;
  z-index: 2;
  .inner {
    max-width: 1920px;
    padding: 0 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
  }
  .leftArea {
    max-width: 100%;
    position: relative;
    width: 100%;
    z-index: 4;
    display: flex;
    flex: 1 1 0%;
    height: 7.2rem;
    -webkit-box-align: center;
    align-items: center;
  }
  .logo {
    height: 37px;
    position: relative;
    z-index: 10;
    img {
      height: 37px;
    }
    &.yafit {
      height: 22px;
      img {
        height: 22px;
      }
    }
  }
  .gnbList {
    display: none;
    transition: 0.3s;
    display: block;
    margin: 0 0 0 5.6rem;
    white-space: nowrap;
    left: 236px;
    position: absolute;
    width: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .gnbItem {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      &:last-child {
        margin-right: 4rem;
      }
      &.eng {
        .gnbName:after {
          background-color: #ffb900;
        }
      }
      &.yu {
        .gnbName:after {
          background-color: #ff5647;
        }
      }
      &.yafit {
        .gnbName:after {
          background-color: #00cc66;
        }
      }
      .gnbLink {
        display: block;
        height: auto;
        padding: 2.8rem 2.4rem 2.6rem;
        font-weight: 500;
        font-size: 1.6rem;
        position: relative;
        & + ul:hover {
          display: block;
        }
        .gnbName {
          display: inline-block;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: 0.2rem;
            left: 0px;
            width: 100%;
            border-bottom: 0px solid transparent;
            transition: all 0.2s ease 0s;
            opacity: 0.5;
            z-index: -1;
          }
        }
        &:hover {
          font-weight: 700;
          .gnbName:after {
            border-width: 0.5rem;
          }

          & + ul {
            display: block;
          }
        }
      }
    }
  }

  .depthMenuList {
    display: none;
    position: absolute;
    top: 6rem;
    left: 0px;
    width: max-content;
    padding: 1.6rem 0px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
    box-sizing: border-box;
    z-index: 2;

    .depthMenuItem {
      position: relative;
      padding: 0.8rem 2.4rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1;
      &.new {
        a:after {
          content: '';
          display: inline-block;
          position: relative;
          width: 3rem;
          height: 1.5rem;
          margin-left: 0.5rem;
          background: url($HOST_CDN + 'ico_gnb_new_60x30.png') 0 50% / auto 100%;
          vertical-align: bottom;
        }
      }
      &.hot {
        a:after {
          content: '';
          display: inline-block;
          position: relative;
          width: 3rem;
          height: 1.5rem;
          margin-left: 0.5rem;
          background: url($HOST_CDN + 'ico_gnb_hot_60x30.png') 0 50% / auto 100%;
          vertical-align: bottom;
        }
      }
      &.best {
        a:after {
          content: '';
          display: inline-block;
          position: relative;
          width: 3rem;
          height: 1.5rem;
          margin-left: 0.5rem;
          background: url($HOST_CDN + 'ico_gnb_best_60x30.png') 0 50% / auto 100%;
          vertical-align: bottom;
        }
      }
      &.premium {
        a:after {
          content: '';
          display: inline-block;
          position: relative;
          width: 5.4rem;
          height: 1.5rem;
          margin-left: 0.5rem;
          background: url($HOST_CDN + 'ico_gnb_premium_102x28.png') 0 50% / auto 100%;
          vertical-align: bottom;
        }
      }
      &.event {
        a:after {
          content: '';
          display: inline-block;
          position: relative;
          width: 3.5rem;
          height: 1.5rem;
          margin-left: 0.5rem;
          background: url($HOST_CDN + 'ico_gnb_event_70x30.png') 0 50% / auto 100%;
          vertical-align: bottom;
        }
      }
      &.soldout {
        a:after {
          content: '';
          display: inline-block;
          position: relative;
          width: 2.5rem;
          height: 1.5rem;
          margin-left: 0.5rem;
          background: url($HOST_CDN + 'ico_gnb_soldout_46x28.png') 0 50% / auto 100%;
          vertical-align: bottom;
        }
      }
      &.free {
        a:after {
          content: '';
          display: inline-block;
          position: relative;
          width: 3.6rem;
          height: 1.4rem;
          margin-left: 0.5rem;
          background: url($HOST_CDN + 'ico_gnb_free.png') 0 50% / auto 100%;
          vertical-align: bottom;
        }
      }
    }
  }
  .buttonGnbNext {
    position: absolute;
    bottom: 0.2rem;
    right: 0vw;
    width: 5rem;
    height: calc(100% - 0.4rem);
    background-color: transparent;
    text-indent: -999rem;
    &:before {
      content: '';
      position: absolute;
      inset: 0vw;
      width: 1.1rem;
      height: 2rem;
      margin: auto;
      background: url($HOST_CDN + 'ico_gnb_arrow_22x40.png') 50% 50% / 100% no-repeat;
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, rgb(255, 255, 255) 80%, rgba(255, 255, 255, 0) 100%);
    }
  }
  .buttonGnbPrev {
    position: absolute;
    bottom: 0.2rem;
    left: 23rem;
    width: 5rem;
    height: calc(100% - 0.4rem);
    background-color: transparent;
    text-indent: -999rem;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1.1rem;
      height: 2rem;
      margin: auto;
      background: url($HOST_CDN + 'ico_gnb_arrow_22x40.png') no-repeat 50% 50% / 100% auto;
      transform: rotate(180deg);
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 80%, rgb(255, 255, 255, 0) 100%);
    }
  }
  .modalDim {
    display: none;
  }
}
.dropdownButton {
  display: none;
}

@media screen and (max-width: 1680px) {
  .subHeaderCompany {
    .inner {
      padding: 0 40px;
    }
  }
  .subHeaderGnb {
    .inner {
      padding: 0 40px;
      contain: layout;
    }
    .leftArea {
      &:after,
      &:before {
        background-color: #fff;
        bottom: 0;
        content: '';
        height: 4.8em;
        position: absolute;
        width: 276px;
      }
      &:after {
        right: -260px;
        z-index: 4;
      }
      &:before {
        left: -40px;
        z-index: 5;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .subHeaderCompany {
    padding: 2.1rem 0;
    .inner {
      padding: 0px 20px;
      justify-content: flex-end;
      .companyDescription {
        display: none !important;
      }
      .companyList {
        .companyItemLink {
          font-size: 2.4rem;
        }
      }
    }
  }
  .subHeaderGnb {
    padding: 0;
    .inner {
      padding: 0;
      contain: initial;
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    .buttonGnbNext,
    .buttonGnbPrev {
      display: none !important;
    }

    .gnbList {
      margin: 0;
      padding: 0 11px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      white-space: nowrap;
      position: static;

      .gnbItem {
        position: unset;
        &:last-child {
          margin-right: 0;
        }
        &.eng {
          .gnbLink:after {
            background-color: #ffb900;
          }
        }
        &.yu {
          .gnbLink:after {
            background-color: #ff5647;
          }
        }
        &.yafit {
          .gnbLink:after {
            background-color: #00cc66;
          }
        }
        .gnbLink {
          position: relative;
          padding: 1.2rem 2rem 2.6rem;
          font-size: 2.8rem;
          box-sizing: border-box;
          font-weight: 400;
          color: #333333;
          -webkit-tap-highlight-color: transparent;
          &:hover {
            font-weight: 400;
            .gnbName:after {
              display: none;
            }
            & + ul {
              display: none;
            }
          }

          & + ul:hover {
            display: none;
          }
          &.selected {
            font-weight: 700;
            color: #000;
            &:after {
              content: '';
              position: absolute;
              right: 0px;
              bottom: 0px;
              left: 0px;
              width: calc(100% - 4rem);
              height: 0.6rem;
              margin: auto;
            }
          }
          &.active {
            color: #000;
            font-weight: 700;
            + ul {
              display: block;
            }
          }
        }
      }
    }
    .depthMenuList {
      width: 100%;
      top: 100%;
      padding: 2rem 0px;
      background: rgb(247, 247, 252);
      border-radius: 0px;
      box-shadow: unset;
      .depthMenuItem {
        padding: 2.8rem 4.2rem;
        font-weight: 400;
        font-size: 2.8rem;
        line-height: 1;
        box-sizing: border-box;
        &.new {
          a:after {
            width: 6rem;
            height: 3rem;
            margin-left: 1rem;
          }
        }
        &.hot {
          a:after {
            width: 6rem;
            height: 3rem;
            margin-left: 1rem;
          }
        }
        &.best {
          a:after {
            width: 6rem;
            height: 3rem;
            margin-left: 1rem;
          }
        }
        &.premium {
          a:after {
            width: 9rem;
            height: 3rem;
            margin-left: 1rem;
          }
        }
        &.event {
          a:after {
            width: 6rem;
            height: 3rem;
            margin-left: 1rem;
          }
        }
        &.soldout {
          a:after {
            width: 5rem;
            height: 3rem;
            margin-left: 1rem;
          }
        }
        &.free {
          a:after {
            width: 7rem;
            height: 3rem;
            margin-left: 1rem;
          }
        }
      }
    }
    .logo {
      height: 30px;
      margin: 11px 0;
      width: auto;
      img {
        height: 30px;
      }
    }
    .modalDim {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: -1;
    }
    .leftArea {
      display: block;
      flex: 1;
      position: relative;
      width: 100%;
      padding: 0;
      height: 17.6rem;
      background: #fff;

      &:before,
      &:after {
        display: none;
      }

      span[class^='ico'] {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  .subLogo {
    display: flex;
    padding: 0 21px;
  }
  .B2bLink {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .subHeaderGnb {
    .leftArea {
      height: auto;
    }
    .dropdownButton {
      display: flex;
      align-items: center;
      margin-left: 6px;
      width: 16px;
      margin-top: 1px;
      -webkit-tap-highlight-color: transparent;
    }
    .gnbList {
      &.hasDragNext:after {
        content: '';
        width: 40px;
        height: 38px;
        display: inline-block;
        background: url($COMMON_HEADER_HOST_CDN + 'icon-gnb-more.png');
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 99;
        background-size: contain;
      }
      &.hasDragPrev:before {
        content: '';
        width: 40px;
        height: 38px;
        display: inline-block;
        background: url($COMMON_HEADER_HOST_CDN + 'icon-gnb-more.png');
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99;
        background-size: contain;
        transform: scaleX(-1);
      }
    }
  }
}
