.container {
  max-width: 1278px;
  padding: 96px 42px 112px;
  margin: 0 auto;
}
.title {
  font-size: 32px;
  font-weight: 700;
}
.titleHighlight {
  color: #ff5647;
}
.filterRow {
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.count {
  font-weight: 700;
  font-size: 25px;
}
.orderButtons {
  font-size: 14px;
  line-height: 16px;
  color: #b7b7c8;
  font-weight: 500;
}
.activeButton {
  color: #000;
}
.orderButtons button + button {
  padding-left: 18px;
  margin-left: 18px;
  border-left: 1px solid #cfcfda;
}
/* search None */
.resultNone {
  text-align: center;
  margin: 94px auto 46px;
}
.resultNone img {
  width: 180px;
  height: 180px;
}
.description {
  margin-top: 18px;
  font-size: 22px;
  letter-spacing: -0.5px;
  font-weight: 700;
  line-height: 33px;
}
.tip {
  margin-top: 209px;
  text-align: left;
}
.tipTitle {
  font-size: 16px;
  font-weight: 700;
  color: #717188;
}
.tipList {
  margin-top: 12px;
  color: #a0a0b6;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.tipList li:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #a0a0b6;
  margin: 0 10px;
  vertical-align: middle;
}
/* list */
.classList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 36px 30px;
  margin-top: 20px;
}
.loadingContainer {
  position: relative;
  height: 420px;
  > div {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1024px) {
  .classList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 9.8vw 5.6vw 10.6vw;
  }
  .title {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
  .filterRow {
    margin-top: 6.1vw;
  }
  .count {
    font-size: 3.7vw;
  }
  .orderButtons {
    font-size: 3.2vw;
  }
  /* list */
  .classList {
    margin-top: 2.8vw;
    gap: 6.1vw 2.5vw;
  }
  /* search None */
  .resultNone {
    margin: 16.267vw auto 1.8vw;
  }
  .resultNone img {
    width: 37.333vw;
    height: 37.333vw;
  }
  .description {
    font-size: 4.7vw;
    margin-top: 3.2vw;
  }
  .tip {
    margin-top: 16.8vw;
  }
  .tipTitle {
    font-size: 3.7vw;
    line-height: 5.5vw;
  }
  .tipList {
    margin-top: 2.7vw;
    font-size: 3.2vw;
    line-height: 4.7vw;
  }
  .tipList li:before {
    width: 3px;
    height: 3px;
    margin: 0 7px;
  }
}
