.paginationWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 26px;
  margin-top: 20px;

  .pagingBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
  }

  .btnArrow {
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.btnNext {
      transform: rotate(180deg);
    }
  }

  .numbers {
    display: flex;
    flex-direction: row;
    gap: 19px;

    .number {
      color: #28284b;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      border-radius: 20px;

      &.active {
        color: #fff;
        background-color: #28284b;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 25px;
    margin-top: 40px;

    .pagingBtn {
      height: 64px;
      width: 64px;
    }

    .numbers {
      gap: 32px;
      .number {
        font-size: 28px;
        line-height: 34px;
        border-radius: 32px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    gap: 3.333vw;
    margin-top: 5.333vw;

    .pagingBtn {
      height: 8.533vw;
      width: 8.533vw;
    }

    .numbers {
      gap: 4.267vw;
      .number {
        font-size: 3.733vw;
        line-height: 4.533vw;
        border-radius: 4.267vw;
      }
    }
  }
}
