$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
.reviewAttachment {
  border-top: 1px solid #dfdfea;
  .fileInput {
    padding: 12px 16px;
    background-color: #f7f7fc;

    label {
      font-size: 14px;
      color: #a0a0b6;
      cursor: pointer;

      &::before {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background: url('#{$img-cdn}/ico_image_81x81.png') no-repeat 50% 0 / 100%;
        vertical-align: middle;
        content: '';
      }
    }
  }
  .filePreview {
    padding: 12px 16px;
    background-color: #fff;

    ul {
      display: flex;
      flex-direction: row;
      gap: 4px;

      li {
        flex: 1;
        height: 72px;
        max-width: 72px;
        overflow: hidden;
        position: relative;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        button {
          position: absolute;
          top: 0;
          right: 0;
          height: 32px;
          width: 32px;
          background: url('#{$img-cdn}/ico_trash.png') no-repeat 50% 0 / 100%;
        }
      }
    }
  }
}
