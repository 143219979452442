.chat {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  height: 100%;
  transform: translate(-50%, -50%);
}
.container {
  overflow: hidden;
  position: relative;
  height: 100dvh;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 165px;
    background: linear-gradient(180deg, rgba(207, 206, 202, 0) 0%, rgba(207, 206, 202, 1) 99%);
    z-index: 1;
    max-width: 750px;
  }
}
.exitButton {
  width: 72px;
  height: 72px;
  position: fixed;
  right: 34px;
  top: 34px;
  z-index: 222;
}
.quizDescription {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  padding: 30px 40px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  border-radius: 20px;
  border: 1px solid #dadada;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);
  bottom: 70px;
  max-width: calc(100% - 84px);
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  color: var(--GrayScale-900---000000, #000);
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  line-height: 40px;
  word-break: keep-all;
  z-index: 2;
  max-height: 500px;
  transition: max-height 0.8s ease;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 14px;
  }
  &.fadeOutFirst {
    overflow: hidden;
    min-height: 222px;
    max-height: 222px;
    animation: none;
    &.conversation {
      max-height: 188px;
    }
    .quizDescriptionFirst {
      animation: fadeOut 1.8s forwards;
    }
  }
  &.fadeOutSecond {
    min-height: 222px;
    animation: fadeOut 2.4s forwards;
    &.conversation {
      max-height: 188px;
    }
  }
  .quizDescriptionFirst {
    background: #fff;
    position: relative;
    z-index: 2;
  }
  .quizDescriptionSecond {
    position: absolute;
    z-index: 1;
    padding: 30px 40px;
    bottom: 0;
  }
  &.fadeOut {
    animation: fadeOut 1.6s forwards;
    transition: transform 0.7s ease;
  }
  &.fadeIn {
    animation: fadeIn 0.7s forwards;
  }
  &.back {
    z-index: 1;
    box-shadow: none;
  }
  b {
    font-weight: 600;
  }
  p {
    white-space: pre-line;
    font-size: 28px;
  }
}
.examTitle {
  z-index: 1;
  position: fixed;
  left: 50%;
  height: 96px;
  transform: translateX(-50%);
  top: 34px;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background: rgba(22, 28, 34, 0.8);
  display: inline-flex;
  gap: 10px;
  padding: 34px 30px 34px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.5px;
  box-sizing: border-box;
  transition: all 0.5s ease;
  z-index: 3;
  &.fadeOut {
    opacity: 0;
  }
  &.fadeIn {
    opacity: 1;
  }
}
.examTitleBg {
  width: 100%;
  height: 180px;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;
}
.toastMessage {
  padding: 26px 0;
  gap: 10px;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background: rgba(22, 28, 34, 0.8);
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  position: fixed;
  left: 50%;
  top: 34px;
  margin-left: -220px;
  z-index: 99;
  width: 440px;
  transform: translateY(-150%);
  box-sizing: border-box;
  text-align: center;

  b {
    font-weight: 700;
  }
  &.slideDown {
    animation: slideDown 0.5s forwards;
  }
  &.slideUp {
    animation: slideUp 0.5s forwards;
  }
}

@media screen and (max-width: 750px) {
  .exitButton {
    width: 9.6vw;
    height: 9.6vw;
    right: 4.533vw;
    top: 4.533vw;
  }
  .examTitleBg {
    height: 24vw;
  }
  .container {
    &:after {
      height: 22vw;
    }
  }
  .quizDescription {
    padding: 4vw 5.333vw;
    gap: 1.867vw;
    border-radius: 2.667vw;
    border: 0.133vw solid #dadada;
    box-shadow: 0vw 0.533vw 4.533vw 0vw rgba(0, 0, 0, 0.25);
    bottom: 9.333vw;
    max-width: calc(100% - 11.2vw);
    font-size: 4vw;
    line-height: 5.333vw;
    > div {
      gap: 1.867vw;
    }
    &.fadeOutFirst {
      max-height: 32vw;
      min-height: 32vw;
      &.conversation {
        max-height: 25.5vw;
        min-height: 25.5vw;
      }
    }
    &.fadeOutSecond {
      min-height: 32vw;
      &.conversation {
        min-height: 25.5vw;
      }
    }
    p {
      font-size: 3.733vw;
    }
    .quizDescriptionSecond {
      padding: 4vw 5.333vw;
    }
  }
  .examTitle {
    top: 4.533vw;
    height: 12.8vw;
    border-radius: 2.667vw;
    border: 0.267vw solid rgba(0, 0, 0, 0.2);
    gap: 1.333vw;
    padding: 0 4.533vw 0 4.533vw;
    font-size: 4.8vw;
    letter-spacing: -0.067vw;
  }
  .toastMessage {
    padding: 3.467vw 0;
    border-radius: 2.667vw;
    border: 0.267vw solid rgba(0, 0, 0, 0.2);
    background: rgba(22, 28, 34, 0.8);
    color: #fff;
    font-size: 3.733vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4.533vw;
    top: 4.533vw;
    width: 67.6vw;
    margin-left: -33.733vw;

    b {
      font-weight: 700;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-150%);
  }
}
