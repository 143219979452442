.inner {
  max-width: 564px;
  margin: 0 auto;
  box-sizing: border-box;
}
.aiChat {
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.chatHeader {
  width: 100%;
  position: relative;
  background-color: #fff;
  &::after {
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    content: '';
  }
  hgroup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;

    position: relative;

    h3 {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }
  }
  .btnClose,
  .btnShare,
  .btnSpeaker {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .btnClose {
    position: absolute;
    top: 22px;
    left: 0;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;

    position: absolute;
    top: 22px;
    right: 0;
  }
}

.chatContainer {
  flex: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  &.audioChatContainer {
    .inner {
      padding-bottom: 120px;
    }
  }
}
.chatFooter {
  width: 100%;
  padding: 22px 0 49px 0;
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .aiChat {
    top: 0 !important;
  }
  .inner {
    padding: 0 22px;
    max-width: 750px;
  }
  .chatContainer {
    &.audioChatContainer {
      .inner {
        padding-bottom: 160px;
      }
    }
  }
  .chatHeader {
    &::after {
      display: none;
    }
    hgroup {
      height: 105px;
      padding: 0 26px;
      h3 {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .btnClose,
    .btnShare,
    .btnSpeaker {
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .btnClose {
      top: 32px;
      left: 26px;
    }
    .buttons {
      gap: 28px;
      top: 32px;
      right: 26px;
    }
  }
  .chatFooter {
    padding: 10px 0 20px;
    .inner {
      padding: 0 24px 0 40px;
    }
  }
}
@media screen and (max-width: 750px) {
  .inner {
    padding: 0 2.933vw;
  }
  .chatContainer {
    &.audioChatContainer {
      .inner {
        padding-bottom: 21.333vw;
      }
    }
  }
  .chatHeader {
    hgroup {
      height: 14vw;
      padding: 0 3.467vw;
      h3 {
        font-size: 3.2vw;
        line-height: 4vw;
      }
    }
    .btnClose,
    .btnShare,
    .btnSpeaker {
      svg {
        width: 5.333vw;
        height: 5.333vw;
      }
    }
    .btnClose {
      top: 4.267vw;
      left: 3.467vw;
    }
    .buttons {
      gap: 3.733vw;
      top: 4.267vw;
      right: 3.467vw;
    }
  }
  .chatFooter {
    padding: 1.333vw 0 2.667vw;
    .inner {
      padding: 0 3.2vw 0 5.333vw;
    }
  }
}
