/* common */
.hide {
  display: none !important;
}
.disD {
  display: block;
}
.disM {
  display: none !important;
}
.tPoint {
  color: #ffea29;
}
.englishHome picture {
  display: block;
  text-align: center;
}
/* wrap, inner */
.wrap {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.wrap * {
  letter-spacing: -0.04em;
}
.inner {
  max-width: 940px;
  margin: 0 auto;
  padding: 0 60px;
  box-sizing: border-box;
}
/* swiper banner */
.slideSection {
  position: relative;
}
.swiperItem {
  display: block;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 500px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 30px;
  z-index: 2;
}
.bullet {
  display: block;
  height: 8px;
  width: 8px;
  margin: 0 5px;
  border-radius: 4px;
  background-color: #fff;
  opacity: 0.4;
  transition: all 0.3s;
}
.bulletActive {
  width: 32px;
  opacity: 1;
}
.bannerImage {
  height: 100%;
  max-width: initial;
}
.bannerImage img {
  object-fit: cover;
  height: 100%;
}

/* section01 - brand */
.brand .inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 100px 60px 110px 0;
}
.brand .titleGroup {
  margin-top: 61px;
}
.brand .titleGroup .title {
  font-size: 44px;
  font-weight: 700;
  line-height: 62px;
}
.brand .titleGroup .title span {
  display: block;
  font-size: 24px;
  font-weight: 400;
}
.brand .titleGroup .description {
  margin-top: 60px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.brand .titleGroup .description span {
  display: block;
  margin-top: 12px;
  font-size: 16px;
  color: #b3b3b3;
}
.brand .cardList li {
  opacity: 0;
  transform: translateY(30px);
  transition: all ease-in 0.3s;
}
.brand .cardList li:nth-child(2),
.brand .cardList li:nth-child(3) {
  margin-top: 18px;
}
.brand .cardList li.active {
  transform: translateY(0);
  opacity: 1;
}

/* section02 - yutube */
.yutube {
  background-color: #191c20;
}
.yutube .inner {
  padding: 130px 60px 120px;
}
.yutube hgroup .title {
  font-size: 44px;
  font-weight: 700;
  color: #fff;
  letter-spacing: -0.02em;
  text-align: center;
}
.yutube hgroup .title span {
  display: block;
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: 500;
  color: #ffea29;
}
.digitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
}
.digitContainer .title {
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}
.digitContainer .date {
  display: block;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #fff;
}
.digitBox {
  position: relative;
}
.digitBox::after {
  position: absolute;
  top: -90px;
  right: 8px;
  display: block;
  width: 94px;
  height: 99px;
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/english/ico_count_up.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  content: '';
}
.top3 {
  margin-top: 115px;
}
.top3 .title {
  font-size: 38px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 60px;
  text-align: center;
}
.top3 .title span {
  display: block;
  margin-bottom: 20px;
  font-size: 22px;
  color: #ececec;
}
.shortsList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 820px;
  margin: 0 auto;
}
.shortsList li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 78px;
  opacity: 0;
  transition: all 0.7s;
  transform: translateY(50px);
}
.shortsList li:nth-child(1) {
  transform: translateY(0);
  order: 2;
}
.shortsList li:nth-child(2),
.shortsList li:nth-child(3) {
  transition-delay: 0.7s;
}
.shortsList li:nth-child(2) {
  order: 1;
}
.shortsList li:nth-child(3) {
  order: 3;
}
.shortsList li.active {
  opacity: 1;
  transform: translateY(0);
}
.shortsList li:nth-child(1).active {
  transform: translateY(-78px);
}
/* section03 - lecture */
.lecture {
  padding: 130px 0 120px;
  background: #f6f6f6;
}
.lecture .inner {
  padding: 0;
}
.lecture hgroup .title {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 80px;
  line-height: 1.4;
}
.lecture hgroup .title span {
  display: block;
  font-size: 22px;
  font-weight: 400;
  margin-top: 24px;
  line-height: 1.2;
}
.lectureList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  max-width: 834px;
  margin: 0 auto;
}
.lectureList li picture {
  border-radius: 15px;
  box-shadow: 6px 10px 30px 0px rgba(241, 241, 241, 0.63);
}
/* section04 - motivation */
.motivation {
  padding: 130px 0;
}
.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* section05 - bigData */
.bigData {
  background: #191c20;
  padding: 140px 0 130px;
}
.bigData .inner {
  padding: 0;
}
.bigData .title {
  margin: 0 0 60px 0;
}
.bigData .stepBox {
  position: relative;
}
/* section06 - change */
.change {
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/english/bg_section_06.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.change .imgText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 370px;
}
/* section08 - result */
.result {
  background: #ffea29;
  padding: 125px 0 30px;
}
.result .countList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 21px;
}
.result .countList li {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.3s;
}
.result .countList li.active {
  opacity: 1;
  transform: translateY(0);
}
.result .summary {
  margin-top: 90px;
  font-size: 16px;
  opacity: 0.3;
  text-align: center;
}
/* section09 - teacher */
.teacher {
  padding: 100px 0 140px;
}
/* section07 - review */
.review {
  padding: 120px 0;
}
.review hgroup .title {
  margin-bottom: 60px;
  font-size: 44px;
  font-weight: 700;
  text-align: center;
}
.review hgroup .title span {
  display: block;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 22px;
}
.review .mainVideo {
  margin-bottom: 20px;
}
.review .videoList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.review .more {
  margin-top: 100px;
}
.review .more .title {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 36px;
}
.review .more .title span {
  display: block;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 500;
}
/* section09 - levelTest */
.levelTest {
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/english/bg_level_test.png);
  background-size: 2000px 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #191c20;
}
.levelTest .inner {
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}
/* contact */
.contact {
  padding: 140px 0;
  background-color: #f5f6f7;
}
.contact .inner {
  padding: 0;
}
.contact .titleGroup {
  margin-bottom: 50px;
  text-align: center;
}
.contact .titleGroup h6 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 500;
}
.contact .titleGroup h5 {
  font-size: 20px;
  margin-top: 15px;
}
.contact .titleGroup h3 {
  font-size: 48px;
  line-height: 1.4em;
}
.contact .titleGroup h3 strong {
  color: #ff6535;
}
.contact .titleGroup .emoji {
  display: inline-block;
  margin-left: 10px;
  transform: translateY(4px);
}
.contact .titleGroup.white h6 {
  color: #fff;
}
.contact .titleGroup.white h3 {
  color: #fff;
}
.contact .titleGroup.white h3 strong {
  color: #ffed52;
}
.form {
  position: relative;
  width: 880px;
  padding-block: 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 24px;
}

@media screen and (max-width: 1024px) {
  /* common */
  .disM {
    display: block !important;
  }
  .wrap {
    max-width: 750px;
    margin: 0 auto;
  }
  .inner {
    padding-left: 50px;
    padding-right: 50px;
  }
  .swiperItem {
    height: auto;
  }
  /* section01 - brand */
  .brand .inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 145px 50px;
  }
  .brand .titleGroup {
    margin-top: 0;
    text-align: center;
  }
  .brand .titleGroup .title {
    font-size: 66px;
    line-height: 88.65px;
  }
  .brand .titleGroup .title span {
    font-size: 36px;
    line-height: 66px;
  }
  .brand .titleGroup .description {
    margin-top: 20px;
    font-size: 34px;
    line-height: 42.79px;
  }
  .brand .titleGroup .description span {
    font-size: 24px;
  }
  .brand .cardList {
    margin-top: 80px;
  }
  /* section02 - yutube */
  .yutube {
    overflow: hidden;
  }
  .yutube .inner {
    padding: 140px 50px 145px;
  }
  .yutube hgroup .title {
    font-size: 66px;
  }
  .yutube hgroup .title span {
    font-size: 36px;
  }
  .digitContainer {
    margin-top: 95px;
  }
  .digitContainer .title {
    font-size: 34px;
  }
  .digitContainer .date {
    margin-top: 0;
    font-size: 24px;
    color: #b3b3b3;
    text-align: center;
  }
  .digitBox {
    width: 100%;
    border-radius: 20px;
    padding: 50px 0 40px;
    background: #f6f6f6;
  }
  .digitBox::after {
    top: -100px;
    right: -18px;
    width: 134px;
    height: 140px;
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/english/ico_m_count_up.png);
  }
  .top3 .title {
    margin-bottom: 65px;
    font-size: 52px;
  }
  .top3 .title span {
    font-size: 32px;
    margin-bottom: 28px;
  }
  .shortsList {
    display: block;
    white-space: nowrap;
    margin: 0 -50px;
    padding: 0 50px;
    overflow: auto;
  }
  .shortsList li,
  .shortsList li.active {
    opacity: 1;
    padding: 0;
    transform: translateY(0) !important;
  }
  .shortsList li {
    width: 310px;
    margin-left: 30px;
    display: inline-block;
  }
  .shortsList li:nth-child(1) {
    order: 1;
    margin-left: 0;
  }
  .shortsList li:nth-child(2) {
    order: 2;
  }
  .shortsList li:nth-child(3) {
    order: 3;
  }
  /* section03 - lecture */
  .lecture {
    padding: 145px 0 140px;
  }
  .lecture .inner {
    padding: 0 50px;
  }
  .lectureList {
    max-width: 100%;
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .lecture hgroup .title {
    font-size: 66px;
    margin-bottom: 70px;
  }
  .lecture hgroup .title span {
    font-size: 36px;
    margin-top: 40px;
  }
  /* section04 - motivation */
  .motivation {
    padding: 145px 0;
  }
  .imgContainer picture {
    margin-left: 31px;
  }
  /* section05 - bigData */
  .bigData {
    padding: 145px 0 145px;
  }
  .bigData .inner {
    padding: 0 50px;
  }
  .bigData .title {
    margin: 0 0 80px 0;
  }
  /* section06 - change */
  .change {
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/english/bg_m_section_06.png);
  }
  .change .imgText {
    height: 475px;
  }
  /* section07 - review */
  .review {
    overflow: hidden;
    padding: 145px 0;
  }
  .review hgroup .title {
    font-size: 66px;
    margin-bottom: 80px;
  }
  .review hgroup .title span {
    font-size: 36px;
    margin-bottom: 35px;
  }
  .review .mainVideo {
    margin-bottom: 30px;
  }
  .review .videoList {
    display: block;
    margin: 0 -50px;
    padding: 0 50px;
    overflow: auto;
    white-space: nowrap;
  }
  .review .videoList li {
    display: inline-block;
    margin-left: 30px;
  }
  .review .videoList li:first-child {
    margin-left: 0;
  }
  .review .more {
    margin-top: 110px;
  }
  .review .more .title {
    font-size: 48px;
  }
  .review .more .title span {
    font-size: 32px;
  }
  /* section08 - result */
  .result {
    padding: 110px 0 30px;
  }
  .result .countList {
    gap: 33px;
  }
  .result .summary {
    margin-top: 80px;
    font-size: 22px;
  }
  /* section09 - teacher */
  .teacher {
    padding: 130px 0 145px;
  }
  .teacher .teacherImg {
    padding: 0 50px;
  }
  /* section10 - levelTest */
  .levelTest {
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/english/bg_m_level_test.png);
    background-size: 750px 570px;
  }
  .levelTest .inner {
    display: block;
    height: 570px;
    padding: 0 50px;
  }
  .levelTest .btnLvTest {
    display: inline-block;
    margin-top: 95px;
  }
  /* contact */
  .contact .inner {
    padding: 0 50px;
  }
  .contact .titleGroup h3 {
    font-size: 66px;
  }
  .contact .titleGroup h6 {
    font-size: 36px;
  }
}
@media screen and (max-width: 750px) {
  .inner {
    padding-left: 6.6667vw;
    padding-right: 6.6667vw;
  }
  .swiperItem {
    height: 80vw;
  }
  .bannerImage picture {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  /* section01 - brand */
  .brand .inner {
    padding: 19.3333vw 6.6667vw;
  }
  .brand .titleGroup .title {
    font-size: 8.8vw;
    line-height: 11.82vw;
  }
  .brand .titleGroup .title span {
    font-size: 4.8vw;
    line-height: 8.8vw;
  }
  .brand .titleGroup .description {
    margin-top: 2.6667vw;
    font-size: 4.5333vw;
    line-height: 5.7053vw;
  }
  .brand .titleGroup .description span {
    font-size: 3.2vw;
  }
  .brand .cardList {
    margin-top: 10.6667vw;
  }
  /* section02 - yutube */
  .yutube .inner {
    padding: 18.6667vw 6.6667vw 19.3333vw;
  }
  .yutube hgroup .title {
    font-size: 8.8vw;
  }
  .yutube hgroup .title span {
    font-size: 4.8vw;
  }
  .digitContainer {
    margin-top: 11.6667vw;
  }
  .digitContainer .title {
    margin-bottom: 5.3333vw;
    font-size: 4.5333vw;
  }
  .digitContainer .date {
    font-size: 3.2vw;
  }
  .digitBox {
    border-radius: 2.6667vw;
    padding: 4vw 0 5vw;
  }
  .digitBox::after {
    top: -13.3333vw;
    right: -12.4px;
    width: 17.8667vw;
    height: 18.6667vw;
  }
  .top3 {
    margin-top: 15.3333vw;
  }
  .top3 .title {
    font-size: 6.9333vw;
    margin-bottom: 8.6667vw;
  }
  .top3 .title span {
    font-size: 4.5333vw;
    margin-bottom: 3vw;
  }
  .shortsList {
    padding: 0 6.6667vw;
    margin: 0 -6.6667vw;
  }
  .shortsList li {
    width: 41.3333vw;
    margin-left: 4vw;
  }
  /* section03 - lecture */
  .lecture {
    padding: 19.3333vw 0 18.6667vw;
  }
  .lecture .inner {
    padding: 0 6.6667vw;
  }
  .lectureList {
    gap: 5.3333vw;
  }
  .lecture hgroup .title {
    font-size: 8.8vw;
    margin-bottom: 9.3333vw;
    line-height: 1.2;
  }
  .lecture hgroup .title span {
    font-size: 4.8vw;
    margin-top: 5.3333vw;
  }
  /* section04 - motivation */
  .motivation {
    padding: 19.3333vw 0;
  }
  .imgContainer {
    padding: 0 4.9333vw 0 9.0667vw;
  }
  .imgContainer picture {
    margin: 0;
  }
  /* section05 - bigData */
  .bigData {
    padding: 19.3333vw 0;
  }
  .bigData .inner {
    padding: 0 6.6667vw;
  }
  .bigData .title {
    width: 67.2vw;
    margin: 0 auto 10.6667vw;
  }
  /* section06 - change */
  .change .imgText {
    height: 63.3333vw;
  }
  .change .imgText img {
    max-width: 67.2vw;
  }
  /* section07 - review */
  .review {
    padding: 19.3333vw 0;
  }
  .review hgroup .title {
    font-size: 8.8vw;
    margin-bottom: 10.6667vw;
  }
  .review hgroup .title span {
    font-size: 4.8vw;
    margin-bottom: 4.6667vw;
  }
  .review .mainVideo {
    margin-bottom: 4vw;
  }
  .review .videoList {
    margin: 0 -6.6667vw;
    padding: 0 6.6667vw;
  }
  .review .videoList li {
    width: 41.3333vw;
    margin-left: 4vw;
  }
  .review .more {
    margin-top: 14.6667vw;
  }
  .review .more .title {
    font-size: 6.4vw;
    margin-bottom: 5.3333vw;
  }
  .review .more .title span {
    font-size: 4.2667vw;
    margin-bottom: 2.6667vw;
  }
  .review .more .btnReview {
    display: block;
    width: 65.6vw;
    margin: 0 auto;
  }

  /* section08 - result */
  .result {
    padding: 14.6667vw 0 4vw;
  }
  .result .countList {
    gap: 4.4vw;
  }
  .result .summary {
    margin-top: 10.6667vw;
    font-size: 2.9333vw;
  }
  /* section09 - teacher */
  .teacher {
    padding: 17.3333vw 0 19.3333vw;
  }
  .teacher .teacherImg {
    padding: 0 6.6667vw;
  }
  /* section10 - levelTest */
  .levelTest {
    background-size: cover;
  }
  .levelTest .inner {
    display: block;
    height: 76vw;
    padding: 0 6.6667vw;
  }
  .levelTest .btnLvTest {
    display: inline-block;
    width: 60.9333vw;
    margin-top: 12.6667vw;
  }
  /* contact */
  .contact {
    padding: 18.667vw 0;
  }
  .contact .inner {
    padding: 0 6.6667vw;
  }
  .contact .titleGroup {
    margin-bottom: 8vw;
  }
  .contact .titleGroup h5 {
    font-size: 4.267vw;
    margin-top: 2.4vw;
    font-weight: 500;
  }
  .contact .titleGroup h6 {
    font-size: 4.267vw;
    margin-bottom: 2.4vw;
    font-weight: 500;
  }
  .contact .titleGroup h3 {
    font-size: 9.333vw;
  }
  .contact .titleGroup .emoji {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: translateY(1vw);
    width: 9vw;
    margin-left: 1.067vw;
  }
  .form {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 2.667vw;
    padding: 0;
  }
}
