.chatTopQna {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding-top: 40px;

  p {
    color: #a0a0b6;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    span {
      font-size: 12px;
    }
  }

  h5 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.5px;
  }
}
