@charset "UTF-8";
@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.order-col {
  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 2rem;

    .button {
      @include button(9rem, 3rem, $dark, $white, 1.4rem, 0.3rem);

      & + .button {
        margin-left: 0.8rem;
      }
    }

    .btn-group {
      flex: 0 0 auto;
      width: auto;
      margin: 0 0 0 auto;
      white-space: nowrap;

      .button {
        font-weight: 400;
      }
    }
  }

  .no-content {
    padding: 14rem 0 20rem;
  }

  .goods-notice {
    margin-top: 1rem;
    padding-left: 6rem;
    color: #ff5647;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.order-list-col {
  padding: 8rem 0 12rem;

  .period-search-col {
    margin-top: 2rem;
  }
}

.period-search-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.5rem;
  background-color: #f7f7fc;

  .period {
    .btn-period {
      display: inline-block;
      font-weight: 400;
      font-size: 1.4rem;
      color: #a0a0b6;

      & + .btn-period {
        margin-left: 3rem;
      }

      &.active {
        font-weight: 700;
        color: #000;
      }
    }
  }

  .date-picker {
    display: flex;
    align-items: center;

    .react-datepicker-wrapper {
      max-width: 13.8rem;
      padding: 0.6rem 0 0.6rem 1.6rem;
      background-color: #fff;
      background: #fff url('#{$img-cdn}/ico_calendar_16x18b_v2.png') no-repeat 93% 50%/1.8rem;
      border: 0.1rem solid #dfdfea;
      box-sizing: border-box;

      input {
        font-weight: 400;
        font-size: 1.4rem;
        color: #000;
      }
    }

    .wave {
      margin: 0 0.8rem;
    }

    .btn {
      width: auto;
      margin-left: 2rem;
      padding: 0.6rem 3.6rem;
      font-size: 1.4rem;
    }
  }
}

.order-lists {
  margin-top: 6rem;
  border-bottom: 0.2rem solid #000;

  li {
    border-top: 0.2rem solid #000;
  }
}

.order-tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.7rem 2.7rem;

  span {
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1.4rem;
    color: #a0a0b6;
  }

  .notice {
    margin-left: 5.4rem;
    font-weight: 400;
    color: #ff5647;
  }

  .light {
    font-weight: 400;
  }

  .btn-order-detail {
    position: relative;
    white-space: nowrap;

    &::after {
      // 일단 넣어둠 추후에 아이콘으로 변경 예정
      content: '>';
      display: inline-block;
      margin-left: 0.6rem;
      font-weight: 700;
      font-size: 1.4rem;
      vertical-align: middle;
      color: $gray-700;
    }
  }
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2.5rem;
  border-top: 0.1rem solid #e7e7f0;

  .order-desc {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;

    .thumb {
      flex: 0 0 auto;
      position: relative;
      width: 11rem;
      height: 11rem;
      margin-right: 2.7rem;
      border-radius: 0.4rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      background-image: url('https://dimg.donga.com/wps/NEWS/IMAGE/2020/02/09/99608854.4.png');

      .delivery-state-mobile {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.6rem;
        font-weight: 700;
        font-size: 2rem;
        color: #fff;
        text-align: center;
        background-color: #6dd75f;

        &.positive {
          background-color: #28284b;
        }
      }
    }

    .order-state-mobile {
      font-weight: 700;
      font-size: 2.2rem;
      color: #a0a0b6;

      &.cancel {
        color: #ff5647;
      }
    }

    .desc {
      flex: 1 1 auto;
      align-self: flex-start;
      margin-top: 0.6rem;
      overflow: hidden;

      .tit {
        display: block;
        font-weight: 700;
        font-size: 1.8rem;
      }

      .quantity {
        display: block;
        margin-top: 0.8rem;
        font-weight: 400;
        font-size: 1.4rem;
        color: #a0a0b6;

        & + .price {
          margin-top: 2.2rem;
        }
      }

      .price {
        margin-top: 4.4rem;

        span {
          display: block;
        }

        .payment-price {
          font-weight: 400;
          font-size: 1.4rem;
          color: #a0a0b6;
          text-decoration: line-through;
        }

        .discount-price {
          font-weight: 700;
          font-size: 1.8rem;

          strong {
            font-weight: 700;
            font-size: 2.2rem;
          }
        }
      }
    }

    .state {
      flex: 0 0 auto;
      margin-left: 7rem;
      min-width: 9rem;
      text-align: center;

      span {
        display: block;
        text-align: center;
      }

      .order-state {
        font-weight: 700;
        font-size: 1.6rem;
        color: #a0a0b6;

        &.cancel {
          color: #ff5647;
        }
      }

      .delivery-state {
        display: inline-block;
        width: auto;
        margin-top: 1.7rem;
        padding: 0.6rem 1.55rem 0.5rem;
        font-weight: 700;
        font-size: 1.3rem;
        color: #fff;
        border-radius: 0.3rem;
        background-color: #6dd75f;

        &.positive {
          background-color: #28284b;
        }
      }
    }
  }

  .btn-flex-form {
    flex: 0 0 auto;
    display: block;
    width: 12rem;
    margin: 0 0 0 6.5rem;
    text-align: center;

    .button {
      @include button(12rem, 3rem, $dark, $white, 1.4rem, 0.3rem);

      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 400;
      }

      & + .button {
        margin-top: 1rem;
        margin-left: 0;
      }
    }

    .slash {
      display: inline-block;
      width: 0.8rem;
      height: 0.2rem;
      background-color: #000;
    }
  }

  .delivery-state-mobile,
  .order-state-mobile {
    display: none;
  }
}

// 주문상세 - 입금 정보
.order-account-info {
  padding: 2.5rem;
  background-color: #f7f7fc;

  dl {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    dt {
      flex: 0 1 15%;
      width: 5.5rem;
      font-weight: 400;
      font-size: 1.5rem;
      color: #a0a0b6;
    }

    dd {
      flex: 1 1 auto;
      margin-left: 1.2rem;
      font-weight: 400;
      font-size: 1.5rem;
    }

    & + dl {
      margin-top: 0.9rem;
    }
  }

  .noti {
    margin-top: 3rem;
    font-weight: 400;
    font-size: 1.3rem;
    color: #ff5647;
  }

  & + .order-item {
    border-top: none;
  }
}

.order-cancel-col {
  margin-top: 2rem;
  border-top: 0.3rem solid #000;
  border-bottom: 0.1rem solid #b7b7c8;

  & + h3 {
    margin-top: 8rem;
  }

  & + .btn-flex-form {
    margin-top: 6rem;
    padding: 0 19.5rem;
  }

  .cancel-info {
    padding: 2.5rem 2.7rem;
    box-sizing: border-box;

    dl {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & + dl {
        margin-top: 1.6rem;
      }

      dt,
      dd {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.8;
      }

      dt {
        color: #717188;
      }

      .payment-method {
        display: inline-block;
        vertical-align: middle;

        &::before {
          content: '';
          display: inline-block;
          width: 3.4rem;
          height: 3.4rem;
          margin-top: -0.2rem;
          margin-right: 0.5rem;
          background-image: url('#{$img-cdn}/ico_card_50x50b.png');
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 100% auto;
          vertical-align: middle;
        }

        &.no-bankbook {
          &::before {
            background-image: url('#{$img-cdn}/ico_no_bankbook_50x50b.png');
          }
        }
      }

      .date {
        font-weight: 400;
        font-size: 1.2rem;
        color: #28284b;
        text-align: right;
      }

      &.discount-detail {
        margin-top: 1.3rem;

        & + .discount-detail {
          margin-top: 0.7rem;
        }

        dt,
        dd {
          font-weight: 400;
          font-size: 1.5rem;
          color: #b7b7c8;
        }

        dt {
          position: relative;
          padding-left: 1.5rem;

          &::before {
            content: 'ㄴ';
            position: absolute;
            top: 0;
            left: 0;
            color: #b7b7c8;
          }
        }
      }
    }

    &.delivery {
      dl {
        justify-content: flex-start;

        & + dl {
          margin-top: 3.8rem;
        }

        dt {
          flex: 0 0 auto;
          width: 12.5rem;
        }
      }
    }
  }
}

.order-cancel-box {
  padding: 2.5rem 2.7rem;
  border-top: 0.1rem solid #dfdfea;

  .tit {
    display: block;
    font-weight: 700;
    font-size: 1.4rem;
  }

  .modal-select-box {
    margin-top: 0.8rem;
    padding: 1.7rem 4rem 1.7rem 1.5rem;
    font-size: 1.6rem;

    & + .basic-textarea {
      border-top: none;
      font-size: 1.6rem;
    }
  }

  .basic-textarea {
    height: 12.9rem;
  }
}

.payment-info {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .cancel-info {
    flex: 1 1 auto;

    dl {
      dd {
        font-weight: 700;
        color: $gray-900;
      }

      &:first-child {
        dd {
          font-weight: 400;
          text-align: right;
        }
      }
    }
  }

  .cancel-price {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 0 1 30rem;
    flex-direction: column;
    padding: 3.5rem 3.1rem;
    background-color: #f7f7fc;
    box-sizing: border-box;

    span {
      font-weight: 700;
      font-size: 2rem;
      text-align: right;
    }

    strong {
      display: inline-block;
      margin-top: 0.8rem;
      font-weight: 700;
      font-size: 2.6rem;
    }
  }
}

.goods-info {
  padding: 0 36.5rem 3rem 16rem;
  box-sizing: border-box;

  &.mobile {
    display: none;
    padding: 0;
    margin-top: 3.3rem;

    li {
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  li {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 7rem;
    position: relative;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.4;

    & + li {
      margin-top: 0.7rem;
    }

    &::before {
      position: absolute;
      left: 0;
      font-weight: 700;
      color: #28284b;
    }

    &:first-child {
      &:before {
        content: '구성';
        position: absolute;
        top: 0;
      }
    }

    &.option {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 0.1rem solid #e7e7f0;
      padding-right: 8rem;

      &::before {
        content: '옵션';
        top: 2rem;
      }

      & + .option {
        margin-top: 0.7rem;
        padding-top: 0;
        border: none;

        &::before {
          content: '';
        }

        .option-quantity {
          top: 0;
        }
      }
    }

    .option-quantity {
      position: absolute;
      top: 2rem;
      right: 0;
      font-weight: 400;
      font-size: 1.4rem;
      color: #a0a0b6;
    }
  }
}

.goods-info-mobile {
  display: none;
  margin-top: 3.3rem;

  dl {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    & + dl {
      margin-top: 3.2rem;
      padding-top: 3.2rem;
      border-top: 0.2rem solid #e7e7f0;
    }
  }

  dt {
    flex: 0 0 6.6rem;
    font-weight: 700;
    font-size: 2rem;
    color: #28284b;
    line-height: 1.5;
  }

  dd {
    position: relative;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.5;

    &.option {
      padding-right: 13rem;
    }

    .option-quantity {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 400;
      font-size: 2rem;
      color: #a0a0b6;
    }
  }
}

@media screen and (max-width: 1024px) {
  .order-col {
    h3 {
      display: none;

      &.tit-mobile {
        display: flex;
        padding: 0 4.2rem;
        font-weight: 700;
        font-size: 3rem;

        &.bdt {
          padding-top: 6rem;
          border-top: 1.2rem solid #ececf6;
        }

        .button {
          width: 17.4rem;
          height: 5.8rem;
          font-size: 2.7rem;
        }
      }
    }

    .no-content {
      padding: 30rem 0;
    }

    .goods-notice {
      color: #ff5647;
      font-size: 2rem;
    }
  }

  .order-list-col {
    padding: 0 0 6rem;
    border-bottom: 0.6em solid #ececf6;

    .period-search-col {
      margin-top: 0;
      padding: 3.6rem 4.2rem;

      .btn-period {
        font-size: 2.9rem;

        & + .btn-period {
          margin-left: 6.4rem;
        }
      }
    }
  }

  .period-select-col {
    position: relative;

    select {
      display: block;
      width: 100%;
      padding: 3.8rem 4.2rem;
      font-weight: 700;
      font-size: 2.7rem;
      color: #000;
      background-color: #f7f7fc;
      border: 0;
    }

    &::before {
      content: '';
      position: absolute;
      right: 5.2rem;
      top: 50%;
      margin-top: -2rem;
      width: 2rem;
      height: 4rem;
      background: url('#{$img-cdn}/ico_arrow_36x21b.png') 50% 50% / 100% auto no-repeat;
    }
  }

  .order-lists {
    margin-top: 0;
    border-bottom: none;

    li {
      padding: 5rem 4.2rem 4.5rem;
      border-top: none;
      border-bottom: 1.2rem solid #ececf6;
    }
  }

  .order-tit {
    align-items: flex-start;
    padding: 0;

    .btn-order-detail {
      &::after {
        font-size: 2.2rem;
      }
    }

    .date {
      display: flex;
      flex-direction: column;
    }

    span {
      font-size: 2.4rem;
    }

    .notice {
      margin-left: 0;
      margin-top: 2rem;
    }
  }

  .order-item {
    display: block;
    padding: 3.4rem 0 0;
    border-top: none;

    & + .order-item {
      margin-top: 4.5rem;
      border-top: 0.2rem solid #e7e7f0;
    }

    .order-desc {
      align-items: flex-start;
      max-width: 100%;

      .goods-info-mobile {
        display: block;
      }

      .thumb {
        width: 14rem;
        height: 14rem;
        border-radius: 0.5rem;
        overflow: hidden;
      }

      .desc {
        max-width: 100%;
        margin-top: 0;

        .tit {
          font-size: 2.7rem;
          margin-top: 1.3rem;
        }

        .quantity {
          margin-top: 3.6rem;
          font-weight: 400;
          font-size: 2.2rem;

          & + .price {
            margin-top: 5rem;
          }
        }

        .price {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          flex-direction: row-reverse;
          margin-top: 5rem;

          .payment-price {
            margin-left: 0.4rem;
            font-weight: 400;
            font-size: 2.4rem;
          }

          .discount-price {
            font-weight: 700;
            font-size: 3.2rem;

            strong {
              font-size: 3.2rem;
            }
          }
        }
      }

      .state {
        display: none;
      }
    }

    .btn-flex-form {
      display: flex;
      width: 100%;
      margin-top: 3.6rem;
      margin-left: 0;

      .button {
        width: 100%;
        height: 7.2rem;
        line-height: 7.1rem;
        font-size: 2.3rem;
        border-radius: 0.5rem;

        & + .button {
          margin-top: 0;
          margin-left: 1rem;
        }
      }

      .slash {
        display: none;
      }
    }

    .delivery-state-mobile,
    .order-state-mobile {
      display: block;
    }

    .order-state-mobile {
      & ~ .price {
        margin-top: 3.2rem !important;
      }
    }
  }

  .goods-info {
    display: none;

    &.mobile {
      display: block;
    }

    li {
      padding-left: 9rem;

      .option-quantity {
        font-weight: 400;
        font-size: 2.2rem;
      }
    }
  }

  // 주문상세 - 입금 정보
  .order-account-info {
    margin-top: 2.4rem;
    padding: 4.2rem;

    dl {
      dt {
        width: 9.6rem;
        font-size: 2.6rem;
      }

      dd {
        margin-left: 2rem;
        font-size: 2.6rem;
      }

      & + dl {
        margin-top: 2.5rem;
      }
    }

    .noti {
      margin-top: 4.2rem;
      font-size: 2.2rem;
    }
  }

  .order-cancel-col {
    margin-top: 0;
    padding: 5rem 4.2rem 0;
    border: none;

    &.payment {
      padding: 0;
    }

    & + h3 {
      margin-top: 6rem;
    }

    & + .btn-flex-form {
      margin-top: 0;
      padding: 0 4.2rem;
    }

    .order-item {
      padding-top: 3.4rem;

      & + .order-item {
        padding-top: 4.5rem;
      }

      .order-desc {
        .quantity {
          position: absolute;
          right: 0;
          top: 7.5rem;
        }
      }
    }

    .cancel-info {
      padding: 0;

      dl {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        & + dl {
          margin-top: 2rem;
        }

        dt,
        dd {
          font-size: 2.6rem;
        }

        .payment-method {
          &::before {
            width: 5rem;
            height: 5rem;
            margin-top: -0.25rem;
            margin-right: 1rem;
          }
        }

        .date {
          display: none;
        }

        &.discount-detail {
          margin-top: 2.3rem;

          dt,
          dd {
            font-size: 2.4rem;
          }

          dt {
            padding-left: 2.5rem;
          }
        }
      }

      &.delivery {
        dl {
          & + dl {
            margin-top: 0;
          }

          &.requests-mobile {
            display: block;
            margin-top: 6rem;

            dt {
              display: block;
              width: 100%;
              font-weight: 700;
              font-size: 3rem;
              color: #000;
            }

            dd {
              margin-top: 2rem;
            }
          }

          dt {
            display: none;
          }

          dd {
            color: #717188;
          }
        }
      }
    }
  }

  .order-cancel-box {
    padding: 0;
    border-top: none;

    .tit {
      display: none;
    }

    .modal-select-box {
      margin-top: 4.5rem;
      padding: 2.8rem 4rem 2.8rem 2.5rem;
      font-size: 3rem;

      & + .basic-textarea {
        font-size: 3rem;
      }
    }

    .basic-textarea {
      height: 31.4rem;
    }
  }

  .payment-info {
    display: block;

    .cancel-info {
      padding: 3.9rem 4.2rem;
    }

    .cancel-price {
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 4.4rem 4.2rem 5.5rem;
      background-color: #fff;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 2.1em;
        right: 2.1em;
        height: 1px;
        background-color: #dfdfea;
      }

      span {
        font-size: 3rem;
      }

      strong {
        font-size: 4rem;
      }
    }
  }
}
