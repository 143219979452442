.customButton {
  padding: 0.6rem 1.7rem 0.5rem 1.7rem;
  border-radius: 0.3rem;

  &.sm {
    font-size: 1.1rem;

    @media screen and (max-width: 1024px) {
      font-size: 2.2rem;
    }
  }

  &.md {
    font-size: 1.4rem;

    @media screen and (max-width: 1024px) {
      font-size: 2.8rem;
    }
  }

  &.lg {
    font-size: 1.8rem;

    @media screen and (max-width: 1024px) {
      font-size: 3.6rem;
    }
  }

  &.dark {
    background-color: #28284b;
    color: #fff;
  }
}
