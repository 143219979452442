.digit {
  position: relative;
  display: inline-block;
}
:global .digit-box {
  display: inline-block;
  height: 90px;
  vertical-align: top;
  overflow: hidden;
  background-color: #fafafa;
  border-radius: 8px;
}
:global .digit-box span {
  display: block;
  font-size: 60px;
  font-weight: 600;
  width: 75px;
  height: 90px;
  line-height: 98px;
  position: relative;
  text-align: center;
  top: 0;
}
:global .digit-box:not(:first-child) {
  margin-left: 8px;
}
:global .digit-box:nth-last-child(3n) {
  margin-left: 24px;
}
:global .digit-box:nth-last-child(3n)::before {
  content: ',';
  position: absolute;
  bottom: -15px;
  margin-left: -18px;
  color: #fff;
  font-size: 70px;
}
:global .digit-box:first-child {
  margin-left: 0 !important;
}
:global .digit-box:first-child::before {
  display: none;
}

@media screen and (max-width: 1024px) {
  .digit {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  :global .digit-box:not(:first-child) {
    margin-left: 0;
  }
  :global .digit-box {
    height: 100px;
  }
  :global .digit-box span {
    font-size: 75px;
    width: auto;
    height: 100px;
    line-height: 108px;
  }
  :global .digit-box:nth-last-child(3n) {
    margin-left: 34px;
  }
  :global .digit-box:nth-last-child(3n)::before {
    bottom: 0;
    margin-left: -24px;
    font-size: 75px;
    color: #000;
  }
}
@media screen and (max-width: 750px) {
  :global .digit-box:not(:first-child) {
    margin-left: 0;
  }
  :global .digit-box {
    height: 13.3333vw;
  }
  :global .digit-box span {
    font-size: 10vw;
    width: auto;
    height: 13.3333vw;
    line-height: 14.4vw;
  }
  :global .digit-box:nth-last-child(3n) {
    margin-left: 3.5333vw;
  }
  :global .digit-box:nth-last-child(3n)::before {
    bottom: -1.5vw;
    margin-left: -3vw;
    font-size: 14vw;
    color: #000;
  }
}
