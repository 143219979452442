.imgBox {
  display: flex;
  width: 100%;
  height: 80.6rem;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  overflow: hidden;

  div {
    background-size: cover;
    background-position: 50% 50%;
  }

  &.item1 {
    div {
      width: inherit;
      height: inherit;
    }
  }

  &.item2 {
    div {
      display: inline-block;
      width: calc(50% - 0.2rem);
      height: inherit;

      &:first-child {
        margin-right: 0.4rem;
      }
    }
  }

  &.item3 {
    div {
      display: inline-block;
      width: calc(50% - 0.2rem);
      height: calc(50% - 0.2rem);

      &:first-child {
        height: inherit;
        margin-right: 0.4rem;
      }

      &:nth-child(2) {
        margin-bottom: 0.4rem;
      }
    }
  }

  &.item4 {
    div {
      display: inline-block;
      width: 33%;
      height: calc(33.333% - 0.33rem);

      &:first-child {
        width: calc(67% - 0.5rem);
        height: inherit;
        margin-right: 0.5rem;
      }
    }
  }

  &.item5 {
    div {
      display: inline-block;
      width: 33%;
      height: calc(33.333% - 0.33rem);

      &:first-child {
        margin-bottom: 0.4rem;
      }

      &:first-child,
      &:nth-child(2) {
        width: calc(67% - 0.5rem);
        height: calc(50% - 0.2rem);
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 66.6rem !important;
    margin-bottom: 7.6rem;
  }
}
