.buttonListBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7rem;
  padding: 0 3.5rem;
  border-top: 1px solid #dfdfea;
  box-sizing: border-box;

  div {
    display: flex;
    align-items: center;

    span {
      & + span {
        margin-left: 1rem;
      }
    }

    button {
      & + a {
        margin-left: 1.6rem;
      }
    }

    a {
      position: relative;
      font-weight: 700;
      font-size: 1.6rem;
      color: #8d8da0;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 1.3rem 4rem;
    height: 10rem;

    div {
      span {
        & + span {
          margin-left: 1.4rem;
        }
      }

      button {
        & + a {
          margin-left: 2.6rem;
        }
      }

      a {
        font-size: 2.2rem;

        &::before {
          width: 3.8rem;
          height: 3.8rem;
        }
      }
    }
  }
}

.commentLink {
  padding: 0;

  &::before {
    content: '';
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -0.2rem;
    margin-right: 0.2rem;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_comment_114x114.png)
      50% 50% no-repeat;
    background-size: 100% auto;
    vertical-align: middle;
  }
}

.shareButton {
  width: 2.4rem;
  height: 2.4rem;
  background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_share_114x114_w.png)
    50% 50% no-repeat;
  background-size: 100% auto;

  span {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: 3.8rem;
    height: 3.8rem;
  }
}

.commentButton {
  position: relative;
  margin-left: 1.6rem;
  padding: 0;
  font-weight: 700;
  font-size: 1.6rem;
  color: #8d8da0;

  &::before {
    content: '';
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -0.2rem;
    margin-right: 0.2rem;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_comment_114x114.png)
      50% 50% no-repeat;
    background-size: 100% auto;
    vertical-align: middle;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 2.6rem;
    font-size: 2.2rem;

    &::before {
      width: 3.8rem;
      height: 3.8rem;
    }
  }
}
