.commentItem {
  display: flex;

  & + div {
    margin-top: 1.8rem;
  }

  &.reply {
    padding-left: 5.8rem;
  }

  @media (max-width: 1024px) {
    & + div {
      margin-top: 2.6rem;
    }

    &.reply {
      padding-left: 8rem;
    }
  }
}

.contentsBox {
  width: 100%;
  padding: 1.8rem;
  background-color: #efeff6;
  font-size: 1.6rem;
  border-radius: 1rem;
  line-height: 1.5;
  box-sizing: border-box;

  strong {
    display: inline-block;
    margin-right: 1rem;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    padding: 2.6rem;
    font-size: 2.6rem;
  }
}

.descBox {
  flex-grow: 1;
  margin-left: 1.2rem;

  @media (max-width: 1024px) {
    margin-left: 1.4rem;
  }
}

.iconBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0.7rem 0 0.7rem;
  color: #8d8da0;
  font-size: 1.4rem;

  div {
    button {
      position: relative;

      &::after {
        content: '·';
        display: inline-block;
        margin: auto 0.3rem;
      }
    }

    & + button {
      padding: 0 1.6rem;

      span {
        display: inline-block;
        position: relative;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        background-color: #a0a0b6;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: #a0a0b6;
        }

        &::before {
          left: -0.6rem;
        }

        &::after {
          right: -0.6rem;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    font-size: 2.2rem;
  }
}
