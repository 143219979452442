@import './variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100dvh;
  text-align: center;
  &.conversation {
    p b {
      color: #5199ff;
    }
    ul li:before {
      background: url($HOST_CDN + 'icon-analyzing-check-blue.png') no-repeat;
      background-size: contain;
    }
  }
}
.title {
  color: var(--TextColor-Primary, #000);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.5px;
  margin-top: 18px;
  b {
    color: #ff6f0f;
  }
}
.list {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 54px;
  align-items: flex-start;
  li {
    color: var(--GrayScale-900---000000, #000);
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.5px;
    position: relative;
    opacity: 0;
    &:before {
      content: '';
      display: inline-block;
      width: 44px;
      height: 44px;
      background: url($HOST_CDN + 'icon-analyzing-check.png') no-repeat;
      background-size: contain;
      vertical-align: middle;
      padding-right: 21px;
    }
  }
  &.active {
    li {
      &:nth-of-type(1) {
        animation: fadeIn 0.5s 1.5s forwards;
      }
      &:nth-of-type(2) {
        animation: fadeIn 0.5s 3.5s forwards;
      }
      &:nth-of-type(3) {
        animation: fadeIn 0.5s 5s forwards;
      }
    }
  }
}
.imageWrap {
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 340px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 750px) {
  .title {
    font-size: 6.4vw;
    line-height: 7.467vw;
    letter-spacing: -0.067vw;
    margin-top: 2.4vw;
  }
  .list {
    margin-top: 16vw;
    gap: 7.2vw;
    li {
      font-size: 4.8vw;
      line-height: 5.867vw;
      letter-spacing: -0.067vw;
      &:before {
        width: 5.867vw;
        height: 5.867vw;
        padding-right: 2.8vw;
      }
    }
  }
  .imageWrap {
    img {
      width: 45.333vw;
    }
  }
}
