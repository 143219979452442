$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';
.chatTopFaq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
  padding-top: 0;
  padding-bottom: 58px;

  &.webview {
    padding-top: 42px;
  }

  .faqThumb {
    display: block;
    height: 86px;
    width: 86px;
  }

  .faqTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      height: 28px;
      margin-bottom: 17px;
      padding: 0 10px;
      background-color: #e4edff;
      border-radius: 47px;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #717188;

      span {
        display: block;
        padding-top: 3px;
      }

      &::before {
        display: block;
        width: 14px;
        height: 14px;
        background: url('#{$img-path}ico_glitter.svg') center / cover no-repeat;
        content: '';
      }
    }

    .faqSmallTitle {
      margin-bottom: 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #8d8da0;
    }

    .faqBigTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.5px;
      color: #000;
    }
  }

  @media screen and (max-width: 1024px) {
    padding-top: 32px;
    &.webview {
      padding-top: 32px;
    }
  }
  @media screen and (max-width: 750px) {
    padding: 7.2vw 5.6vw 9.867vw;
    &.webview {
      padding: 7.2vw 5.6vw 9.867vw;
    }

    .faqThumb {
      width: 14.133vw;
      height: 14.133vw;
    }

    .faqTitle {
      .badge {
        gap: 0.667vw;
        height: 5.067vw;
        padding: 0 1.867vw;
        margin-bottom: 3.333vw;
        font-size: 2.667vw;
        line-height: 3.467vw;

        span {
          padding-top: 0.4vw;
        }

        &::before {
          width: 3.2vw;
          height: 3.2vw;
        }
      }
      .faqSmallTitle {
        margin-bottom: 1.2vw;
        font-size: 2.133vw;
        line-height: 2.933vw;
      }
      .faqBigTitle {
        font-size: 4.8vw;
        line-height: 5.867vw;
        letter-spacing: -0.067vw;
      }
    }
  }
}
