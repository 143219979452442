.container {
  padding: 100px 0 80px;
  box-sizing: border-box;
}
.subTitle {
  color: #717b8d;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
}
.title {
  margin-top: 8px;
  color: #161c22;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px;
  word-break: keep-all;
}
.productList {
  margin-top: 32px;
  display: flex;
  gap: 18px;
  &.full {
    li {
      width: 100%;
      display: flex;
      text-align: left;
      gap: 12px;
      min-height: 267px;
      padding: 0 70px 0 0;
      align-items: center;
      img {
        height: 267px;
        width: 324px;
      }
      p {
        margin-top: 0;
        & + p {
          margin-top: 8px;
        }
      }
    }
  }
  li {
    min-height: 448px;
    border-radius: 16px;
    box-sizing: border-box;
    width: 50%;
    position: relative;
    padding: 0 32px 40px;
    box-sizing: border-box;
    text-align: left;
    &.aiRealTalk {
      background: linear-gradient(181deg, #d68bfb 3.16%, #8eadf3 99.79%);
      img {
        display: block;
        height: 267px;
        position: relative;
        left: auto;
        top: auto;
        width: 324px;
        flex: none;
      }
      .textWrap {
        margin-top: 0;
      }
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .textWrap {
      position: relative;
      z-index: 2;
      margin-top: 256px;
    }
    .name {
      margin-top: 28px;
      font-family: Inter;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.5px;
      word-break: keep-all;
      white-space: pre-line;
      em {
        display: none;
      }
    }
    .description {
      margin-top: 8px;
      font-size: 20px;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding: 13.333vw 0 10.667vw;
  }
  .subTitle {
    font-size: 3.733vw;
    line-height: 4.533vw;
  }
  .title {
    margin-top: 1.067vw;
    font-size: 5.333vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
  }
  .productList {
    margin-top: 4.267vw;
    gap: 2.4vw;
    &.full {
      li {
        gap: 1.6vw;
        min-height: 35.6vw;
        padding: 0 9.333vw 0 0;
        img {
          height: 35.6vw;
          width: 43.2vw;
        }
        p {
          & + p {
            margin-top: 1.067vw;
          }
        }
      }
    }
    li {
      min-height: 59.733vw;
      border-radius: 2.133vw;
      padding: 0 4.267vw 5.333vw;
      &.aiRealTalk {
        img {
          height: 35.6vw;
          width: 43.2vw;
        }
      }

      .textWrap {
        margin-top: 34.133vw;
      }
      .name {
        margin-top: 3.733vw;
        font-size: 4.267vw;
        line-height: 5.333vw;
        letter-spacing: -0.067vw;
      }
      .description {
        margin-top: 1.067vw;
        font-size: 2.667vw;
        line-height: 3.467vw;
      }
    }
  }
}
