.chatTopInfo {
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 92px;
  max-width: 750px;
  margin: 0 auto;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
  }

  .dot {
    display: inline-block;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #000;
  }
  .aiImg {
    width: 74px;
    height: 74px;
  }
  .aiName {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-top: 11px;
  }
  .aiDesc {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    margin-top: 7px;
  }
  .aiHash {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 26px;
    span {
      color: #28284b;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      display: inline-flex;
      padding: 4px 6px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border-radius: 3px;
      background: #ececf6;
      em {
        color: #28284b;
        font-size: 10px;
        font-weight: 700;
        line-height: 14px;
      }
    }
  }
  .aiNoti {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin-top: 23px;
    p {
      color: #a0a0b6;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      display: inline-block;
      padding: 0 21px;
      background-color: #fff;
      position: relative;
      z-index: 1;
      span {
        display: block;
        &:last-child {
          font-weight: 700;
        }
      }
    }
    &::after {
      display: block;
      width: 750px;
      height: 1px;
      background-color: #e7e7f0;
      position: absolute;
      top: 50%;
      left: 0;
      content: '';
    }
  }
}
