.skeletonBox {
  padding: 1.5rem 0;
  border-bottom: 0.1rem solid #eee;
  .react-loading-skeleton {
    height: 1.4rem;
  }
  dl {
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0.5rem 0;
    justify-content: center;
    align-items: center;
    dt {
      width: 50%;
      span {
        margin-right: 0.5rem;
      }
    }
    dd {
      width: 50%;
      text-align: right;
      span {
        margin-left: 0.5rem;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 4rem;
    .react-loading-skeleton {
      height: 2.8rem;
    }
  }
}

.boardList {
  display: block;
  width: 100%;
  border-top: 0.3rem solid #000;
  .noContent {
    padding: 8rem 0 12rem;
  }
  @media screen and (max-width: 1024px) {
    border-top: 0.1rem solid #e7e7f0;
    .noContent {
      padding: 0;
    }
  }
}

.boardBox {
  padding: 8rem 0;
  .boardInfo {
    display: flex;
    margin-bottom: 2rem;
    justify-content: flex-start;
    align-items: end;
    p {
      flex: 1 auto;
      font-weight: 400;
      font-size: 1.4rem;
      color: #717188;
      &:before {
        content: '- ';
      }
    }
  }
  .pagination {
    padding-bottom: 6rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 0 3.2rem;
    .boardInfo {
      display: none;
    }
  }
}
