.modal {
  background: #fff;
  border-radius: 16px;
}
.content {
  position: relative;
  padding: 64px;
  text-align: center;
}
.content h3 {
  font-size: 24px;
  line-height: 1.4;
}
.content p {
  margin-top: 16px;
  font-size: 16px;
}
.btnMore {
  display: block;
  width: 100%;
  padding: 12px 0;
  margin-top: 32px;
  background: #ff5647;
  border-radius: 8px;
  font-size: 16px;
  color: #fff;
}
.btnClose {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -1000px;
  background-image: url('https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_close_72x72b.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
  .content {
    padding: 32px;
  }
}
