.cardImageBox {
  position: relative;
  margin-bottom: 5rem;

  ul {
    display: flex;
    position: relative;
    margin: 0 -0.4rem;
    padding-left: 27.2rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      flex: 1 1 20%;
      position: relative;
      margin: 0.4rem;
      padding-bottom: 23.3%;
      background-color: #e7e7f0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      &:first-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 26.4rem;
      }

      &.noImage {
        background-size: 26% auto;
      }

      span {
        position: absolute;
        bottom: 0;
        right: 0;
        min-width: 2.8rem;
        padding: 0.64rem 0 0.5rem;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.4rem;
        color: #fff;
        background-color: rgba(40, 40, 75, 0.6);
        text-align: center;
        box-sizing: border-box;
      }
    }
  }

  button {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    cursor: pointer;

    &.btnMore {
      &::before {
        content: '+ 더보기';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        font-weight: 700;
        font-size: 1.8rem;
        color: #fff;
        background-color: rgba(40, 40, 75, 0.6);
      }
    }
  }
}

.blind {
  display: none;
}

@media screen and (max-width: 1024px) {
  .cardImageBox {
    margin-bottom: 6rem;

    ul {
      margin: 0 -0.4rem;
      padding-left: 0;

      li {
        flex: 1 1 30%;
        margin: 0.4rem;
        padding-bottom: 32.05%;

        &:first-child {
          position: relative;
          top: auto;
          left: auto;
          bottom: auto;
          width: auto;
        }

        &.noImage {
          background-size: 26% auto;
        }

        span {
          min-width: 4.4rem;
          padding: 1rem 1rem;
          font-size: 2rem;
        }
      }
    }

    button.btnMore::before {
      font-size: 2.4rem;
    }
  }
}
