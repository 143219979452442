.btnListBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 14rem 0;
  border-top: 1px solid #b7b7c8;

  a,
  button {
    font-weight: 400;
    font-size: 2rem;

    &:first-child {
      padding-left: 4rem;

      &::before {
        left: 0;
      }
    }

    &:last-child {
      padding-right: 4rem;

      &::before {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 5rem 4.2rem 0;

    a,
    button {
      font-size: 2.7rem;
    }
  }
}

.buttonItem {
  position: relative;
  color: #28284b;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 5rem;
    height: 4rem;
    margin-top: -2.1rem;
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_arrow_24x42_b.png);
    background-repeat: no-repeat;
    background-size: auto 1.6rem;
    background-position: 50% 50%;
  }

  @media screen and (max-width: 1024px) {
    &::before {
      width: 5rem;
      height: 4rem;
      margin-top: -2rem;
      background-size: auto 2.2rem;
    }
  }
}

.linkItem {
  display: inline-block;
  width: 27rem;
  height: 6.8rem;
  padding: 2rem 0 1.8rem;
  text-align: center;
  border: 1px solid #cfcfda;
  border-radius: 0.3rem;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    width: 32.4rem;
    height: 10rem;
    padding: 3.2rem 0 2.9rem;
    border-radius: 0.6rem;
    box-sizing: border-box;
  }
}
