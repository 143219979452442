.csNoticeList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 88px;

  .csNoticeItem {
    display: block;
    min-width: 1px;
    cursor: pointer;
    dl {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    dt {
      color: #717188;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    dd {
      color: #b7b7c8;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
    .csNoticeItem {
      dl {
        gap: 19px;
      }
      dt {
        color: #28284b;
        font-size: 28px;
        font-weight: 400;
        line-height: 34px;
      }
      dd {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.5px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    gap: 6.667vw;
    .csNoticeItem {
      dl {
        gap: 2.533vw;
      }
      dt {
        font-size: 3.733vw;
        line-height: 4.533vw;
      }
      dd {
        font-size: 2.667vw;
        line-height: 3.467vw;
        letter-spacing: -0.067vw;
      }
    }
  }
}
