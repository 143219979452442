.innerCol {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;

  @media screen {
    padding: 0 20px;
  }
}
.categoryBtnNav {
  padding: 60px 0 20px;
  h3 {
    font-size: 22px;
    margin-bottom: 30px;
  }
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 113px;
    margin-right: 20px;
    border-radius: 8px;
    background-color: #f7f7f7;
    font-size: 18px;

    &.active {
      background-color: #ffb900;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 5.5rem 0 0;
    h3 {
      margin-bottom: 3rem;
      font-size: 3.2rem;
    }
    button {
      width: 16rem;
      height: 6.5rem;
      margin-right: 1.5rem;
      border-radius: 1rem;
      font-size: 2.4rem;
    }
  }
}

.categoryListNav {
  padding: 108px 0 124px;
  h3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 70px;
    font-size: 38px;
    letter-spacing: -1px;
    text-align: center;

    span {
      font-size: 24px;
      font-weight: 400;
      color: #717188;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 650px;
    margin: 0 auto;

    li {
      flex: 1;

      button {
        position: relative;
        display: flex;
        align-items: center;
        height: 100px;
        width: 100%;
        padding-left: 104px;
        border-radius: 12px;
        background-color: #f7f7fc;

        font-size: 24px;
        font-weight: 700;
        color: #28284b;
        letter-spacing: -1px;

        &:hover {
          background-color: #ffb900;
        }

        &::before {
          content: '';
          position: absolute;
          left: 52px;
          top: 35px;
        }
        &::after {
          content: '';
          position: absolute;
          right: 50px;
          top: 50%;
          width: 12px;
          height: 19px;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2022/feb/ico_arrow_23x38.png)
            no-repeat 50%/100%;
          transform: translateY(-50%);
        }

        &.level::before {
          width: 31px;
          height: 31px;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2022/feb/ico_level_62x62.png)
            no-repeat 50%/100%;
        }
        &.price::before {
          width: 32px;
          height: 31px;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2022/feb/ico_price_64x62.png)
            no-repeat 50%/100%;
        }
        &.period::before {
          width: 32px;
          height: 34px;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2022/feb/ico_time_64x68.png)
            no-repeat 50%/100%;
        }
        &.classType::before {
          top: 48%;
          width: 35px;
          height: 34px;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2022/feb/ico_way_69x68.png)
            no-repeat 50%/100%;
          transform: translateY(-50%);
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 16rem 0 19rem;

    h3 {
      font-size: 5rem;
      gap: 1.6rem;
      margin-bottom: 11rem;

      span {
        font-size: 3.2rem;
      }
    }

    ul {
      gap: 2.1rem;
      width: 100%;

      li {
        button {
          height: 80px;
          padding: 0 13.6rem;
          font-size: 3.5rem;

          &::before {
            width: 4.2rem !important;
            height: 4.2rem !important;
            left: 5.2rem;
            top: 6.5rem;
          }

          &::after {
            width: 1.8rem;
            height: 3.6rem;
            right: 5rem;
          }
        }
      }
    }
  }
}
