@import 'variable';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
.cart-col {
  text-align: center;

  h2 {
    display: none;
  }

  .inner-col {
    text-align: center;

    & > button {
      width: 48.9rem;
      height: 6.8rem;
      margin: 6.1rem 0 7.9rem;
      background-color: $warning;
      font-size: 2rem;

      &.disabled {
        color: $dark;
        background-color: $gray-500;
        border: 1px solid $gray-500;
      }
    }
  }

  .no-content {
    margin: 0 0 8rem;
    padding: 11.8rem 0;
    border-top: 2px solid #000;
    border-bottom: 1px solid $gray-700;

    .ico {
      display: inline-block;
      width: 6.1rem;
    }
    a {
      display: inline-block;
      height: 6.8rem;
      margin: 5rem 0 10rem;
      padding: 0 3rem;
      font-size: 2rem;
      font-weight: 700;
      border: 1px solid $gray-500;
      border-radius: 0.5rem;
      line-height: 6.8rem;
    }
  }

  .item-img {
    @include item-img-box(13rem, 8.8rem);
    margin: 0 2.5rem;
    background-color: #e7e7f0;
  }

  .item-info {
    @include item-info-box(1.4rem);
    p:first-child {
      margin-bottom: 0.8rem;
    }

    em {
      margin-left: 1rem;
      color: $gray-700;
    }

    strong {
      display: inline-block;
      font-size: 1.8rem;
    }

    .material-counter {
      margin-bottom: 2rem;
    }

    .tag-group {
      padding-top: 1.2rem;
      .tag-item {
        display: inline-block;
        padding: 0.5rem 0.6rem;
        font-weight: 700;
        font-size: 1.1rem;
        color: #269cff;
        background-color: #f9f5ff;
        border-radius: 0.2rem;

        & + .tag-item {
          margin-left: 0.4rem;
        }
      }
    }

    p:last-child span {
      display: inline-block;
      margin-top: 0.8rem;
      color: $gray-600;
      font-weight: 700;
    }

    .item-detail {
      font-size: 1.4rem;

      & > p,
      & > div {
        padding-left: 6.1rem;
      }

      & > p:first-of-type {
        position: relative;

        &:before {
          content: '구성';
          position: absolute;
          left: 0;
          color: $gray-900;
          font-weight: 700;
        }
      }

      & > .option:first-of-type {
        position: relative;
        margin-top: 1.8rem;
        padding-top: 1.8rem;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          //border-top: 1px solid $gray-300;
        }

        &:after {
          content: '옵션';
          display: inline-block;
          position: absolute;
          left: 0;
          color: $gray-900;
          font-weight: 700;
        }
      }

      .option {
        label {
          padding: 0;
        }
      }

      dl {
        display: flex;
      }

      dl + dl {
        margin-top: 1.8rem;
        padding-top: 1.8rem;
        border-top: 1px solid #e7e7f0;
      }

      dt,
      dd {
        flex-direction: column;
      }

      dt {
        flex: 0 0 5rem;
        font-weight: 700;
        color: $gray-900;
      }

      p {
        margin-bottom: 0.8rem;
      }
    }

    .option {
      display: flex;

      strong,
      em {
        &:after {
          @include price;
        }
      }
      em {
        text-decoration: line-through;
      }
    }

    .item-list {
      margin: 0 3.5rem 0 0.5rem;
    }

    &.delivery {
      p {
        strong {
          margin: 0;
        }
      }
    }
  }

  .item-price {
    text-align: center;

    em,
    strong {
      @include price;
      display: inline-block;
      vertical-align: middle;
    }

    em {
      margin-bottom: 0.5rem;
      color: $gray-700;
      text-decoration: line-through;
    }

    strong {
      font-size: 2.2rem;
      span {
        display: inline-block;
        margin-right: 0.3rem;
        font-size: 1.8rem;
      }

      & + p {
        color: #717188;
      }

      & + p strong {
        margin-right: 0.5rem;
        font-size: 1.4rem;
        font-weight: normal;

        &::before {
          content: '총';
          display: inline-block;
          margin-right: 0.25rem;
        }

        &::after {
          font-size: inherit !important;
          font-weight: normal !important;
        }

        & + span {
          margin-right: 0 !important;
          font-size: 1.4rem;
        }
      }

      & + p + p span {
        display: inline-block;
        margin: 0.7rem 0 0 !important;
        color: 717188;
        font-size: 1.2rem !important;
        font-weight: normal !important;

        &::before {
          content: '※';
        }
      }
    }

    & > button {
      display: inline-block;
      width: 9.1rem;
      height: 3rem;
      margin-top: 1.5rem;
      color: $white;
      background-color: $gray-900;
      font-size: 1.4rem;
      border-radius: 0.5rem;
      text-align: center;
    }
  }

  .tb-default {
    table {
      width: 100%;
      font-size: 1.6rem;
      border-top: 3px solid $dark;
      border-bottom: 1px solid $gray-600;
      text-align: left;
    }
    thead {
      border-bottom: 1px solid $gray-300;
    }

    thead,
    tbody {
      & > tr {
        border-bottom: 1px solid $gray-300;

        &:last-child {
          border-bottom: 0;
        }
        & > th {
          height: 7rem;
          padding: 0 2.1rem;
          font-weight: 400;
        }

        td {
          label {
            display: inline-block;
            padding: 3.5rem 0 0 2.1rem;

            &.check-fold {
              padding: 0;
              white-space: nowrap;
            }
          }
        }
      }
    }

    &.cart {
      td:first-child,
      td:nth-child(2) {
        vertical-align: middle;
      }

      span.delivery {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    &.order {
      th,
      td {
        text-align: center;
      }
      td {
        position: relative;
        padding: 5.7rem 0;
        font-size: 2.6rem;

        &:after {
          content: '+';
          position: absolute;
          right: 0;
          color: $gray-700;

          &:first-child {
            content: '+';
          }
        }
        &:nth-child(2):after {
          content: '-';
        }
        &:nth-child(3):after {
          content: '=';
        }
        &:last-child:after {
          content: '';
        }
      }
      span {
        @include price;
        font-weight: 700;
        &::after {
          font-weight: 700;
          font-size: 2.2rem;
        }
      }
      strong {
        @include price;
        font-weight: 700;
        color: $danger;

        em {
          display: inline-block;
          margin-right: 0.2rem;
          font-size: 2.4rem;
        }

        &::after {
          font-weight: 700;
          font-size: 2.2rem;
        }

        & + strong:after,
        span:before,
        em:before,
        em:after {
          content: '';
        }

        & + em {
          display: block;
          width: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin: 0.5rem 0 0;
          color: #717188;
          font-size: 1.4rem;
          font-weight: 400;
        }
      }

      label {
        display: block;
        margin: 3rem 0;
        text-align: left;
        span {
          font-weight: 700;
          font-size: 1.5rem;
          &::after {
            content: '';
          }
        }
        em {
          font-weight: 400;
          font-size: 1.4rem;
          color: #717188;
        }
      }
    }

    &.cart-detail {
      th,
      td {
        text-align: left;
      }
      th {
        padding-left: 2.5rem;
        em {
          position: relative;
          &:before {
            content: '*';
            position: absolute;
            top: 0;
            left: -1rem;
            font-weight: 400;
            font-size: 1.6rem;
            color: #ff5647;
          }
        }
      }

      td {
        padding: 2rem 0;
        vertical-align: middle;
        &.none-padding {
          padding: 0;
        }
      }

      input {
        width: 100%;
        font-size: 1.6rem;

        &::placeholder {
          color: $gray-700;
          font-weight: 400;
        }
      }

      .item-price {
        padding-right: 2.1rem;
        text-align: right;

        p:nth-child(2) span {
          margin-right: 0.5rem;
          font-weight: 400;
          font-size: 1.4rem;
          vertical-align: middle;
        }

        em {
          font-weight: 700;
          font-size: 1.4rem;
        }

        strong {
          &::after {
            font-weight: 700;
            font-size: 1.8rem;
          }
        }
      }

      .address {
        td {
          padding: 2.1rem 0;
        }

        .flex-form {
          display: flex;
          justify-content: space-between;

          .btn-address {
            flex: 1 0 auto;
            width: 9.1rem;
            margin-right: 2rem;
          }

          .zone-code {
            width: 10%;
          }
        }
      }
    }

    .ico-member {
      display: inline-block;
      position: relative;
      height: 1.8rem;
      margin: 3rem 1rem 0 0;
      font-weight: 700;
      font-style: normal;
      line-height: 1.8rem;
      text-align: center;
      vertical-align: middle;

      &:before {
        content: '입학회원';
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-right: 0.7rem;
        padding: 0 0.4rem;
        color: #0074d6;
        background-color: #c8e1ff;
        font-weight: 700;
        font-size: 1rem;
        border-radius: 0.2rem;
        box-sizing: border-box;
        text-align: center;
        vertical-align: middle;
      }

      &.marginNone {
        margin: 0 1rem 0 0;
      }
    }

    .total-discount {
      padding: 0 4.5rem;
      font-size: 2.6rem;
      text-align: right;

      strong {
        @include price;
        color: $dark;
        letter-spacing: 1px;

        &:before {
          content: '총 할인 받을 금액';
          display: inline-block;
          margin-right: 1.5rem;
          color: $dark;
          font-size: 1.6rem;
          letter-spacing: 0;
        }
        &::after {
          font-weight: 700;
          font-size: 2.2rem;
        }
      }
    }

    .use-coupont-list {
      display: none;
      background-color: $gray-100;
      border: 0;

      &.active {
        display: table-row;
      }
      & > td {
        padding: 0 4rem;
      }
      table {
        margin: 0 auto;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        td {
          padding: 3rem 0;
        }
        & + h4 {
          margin-top: 4.6rem;
        }
      }

      select {
        color: #717188;
        background-position: 95% 45%;
        background-size: 1rem;
      }

      .item-img {
        @include item-img-box(9.8rem, 6.8rem);
        margin: 0;
      }

      .item-info {
        padding-top: 0.4rem;
        @include item-info-box(1.2rem);
        em {
          margin: 0;
        }

        strong {
          margin: 0;
          font-size: 1.6rem;
          em {
            display: inline-block;
            margin-top: 0.6rem;
            font-weight: 400;
            font-size: 1.2rem;
            color: #ff5647;
          }
        }
      }

      .no-item {
        color: $gray-700;
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }

  .btn-address {
    padding: 0.7rem 1.8rem;
    color: $white;
    background-color: $gray-900;
    font-size: 1.4rem;
    border-radius: 0.5rem;

    & + input {
      margin: 1rem 0;
    }
  }

  .btn-coupon-download {
    display: inline-block;
    margin-left: 1.2rem;
    padding: 1rem 3rem 1rem 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1;
    color: #fff;
    background: #28284b url('#{$img-cdn}/ico_download_type2_48x42w.png') no-repeat 88% 50% / 1.6rem
      auto;
    border-radius: 0.3rem;
  }

  .payment {
    button {
      @include button(31.4rem, 5rem, $white, $dark, 1.6rem, 0.5rem);
    }
  }

  .banner-col {
    max-width: 1200px;
    margin: 0 auto 8rem;
    padding: 0 3rem;
  }

  .btn-select-delete {
    padding: 1rem;
    font-size: 1.6rem;
  }
}

.ipt-group {
  label {
    display: inline-block;
    min-width: 11.7rem;
    margin: 4.2rem 6.3rem 0 0;

    &:last-child {
      margin-right: 0;
    }

    .chk + span {
      font-size: 1.6rem;
    }
  }
  .ico-payment {
    display: inline-block;
    width: calc(24.6rem / 3);
    height: calc(10.2rem / 3);
    background-size: 100%;
    background-position: 0 50%;
    background-repeat: no-repeat;
    vertical-align: middle;

    &.kakao {
      background-image: url('#{$img-cdn}/ico_pay_kakao_246x102.png');
    }

    &.naver {
      background-image: url('#{$img-cdn}/ico_pay_naver_246x102.png');
    }

    &.samsung {
      background-image: url('#{$img-cdn}/ico_pay_samsung_246x102.png');
    }

    &.toss {
      background-image: url('#{$img-cdn}/ico_pay_toss_246x102.png');
    }
  }
}

.box-tb-col {
  padding-top: 8rem;
  text-align: left;

  h3 {
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 3rem;
    text-align: left;
  }

  &.desc {
    h3 {
      margin: 0;
    }
  }

  .delivery-chk-box {
    font-size: 1.4rem;
    text-align: left;

    label {
      margin-right: 5.5rem;
      &:not(:last-child) {
        margin-bottom: 0.75em;
      }
    }
  }

  .check-fold {
    margin-left: 2.1rem;

    input[type='checkbox'] + .chk,
    input[type='radio'] + .chk {
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 1rem;
      background: #fff;
      border: 0.1rem solid #a0a0b6;
      border-radius: 100%;
      &::before {
        display: none;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0.3rem;
        left: 0;
        width: 0.6rem;
        height: 0.6rem;
        margin: auto;
        border-bottom: 0.1rem solid #717188;
        border-right: 0.1rem solid #717188;
        transform: rotate(45deg);
      }
    }

    input[type='checkbox']:checked + .chk,
    input[type='radio']:checked + .chk {
      &::after {
        top: 0.3rem;
        bottom: 0;
        transform: rotate(-135deg);
      }
    }

    span {
      font-size: 1.6rem !important;
      & + span {
        position: relative;
        margin-left: 1rem;
        padding-left: 1rem;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 0.1rem;
          height: 1rem;
          margin: auto;
          border-left: 0.1rem solid #d4d4d4;
        }
      }
    }
  }

  .desc-box {
    margin-bottom: 2.8rem;
    text-align: left;

    p {
      padding-left: 1rem;
      font-size: 1.4rem;
      color: $gray-900;
      line-height: 1.5;

      &:before {
        content: '-';
        display: inline-block;
        text-indent: -1rem;
      }
    }

    &.right {
      margin-bottom: 1.2rem;
      text-align: right;

      p:before {
        content: '※';
        margin-right: 0.5rem;
      }
    }
  }
}

.full-banner-box {
  margin: 7rem auto 8rem;
  a {
    display: block;
  }
}

.certification-box {
  position: relative;
  width: 100%;
  margin-top: 3.2rem;
  padding: 5.2rem 13.4rem;
  border: 0.3rem solid #ececf6;
  border-radius: 1rem;
  box-sizing: border-box;
  p {
    position: relative;
    padding: 1rem 15rem 1rem 0;
    font-weight: 400;
    font-size: 1.6rem;
    text-align: left;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -6rem;
      width: 3.6rem;
      height: 3.6rem;
      margin: auto;
      background: url('#{$img-cdn}/ico_notice_144x144.png') no-repeat 50% 50% / 100%;
      border-radius: 100%;
    }
    strong {
      font-weight: 700;
    }
  }
  button {
    position: absolute;
    top: 0;
    right: 7rem;
    bottom: 0;
    width: 20rem;
    height: 5rem;
    font-weight: 400;
    font-size: 1.6rem;
    color: #000;
    margin: auto;
    &.btn-complete {
      padding-left: 1.5rem;
      color: #000;
      background-color: #fff;
      border: 0.1rem solid #e7e7f0;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2rem;
        height: 1.5rem;
        margin: auto 0 auto -2.5rem;
        background: url('#{$img-cdn}/ico_check_99x81_green.png') no-repeat 50% 50% / 1.2rem auto;
      }
    }
  }
}

.pay-type-box {
  display: block;
  border-top: 3px solid $dark;
  border-bottom: 1px solid $gray-600;
  text-align: center;

  .ipt-group {
    padding-left: 2rem;
  }

  label {
    display: inline-block;
    cursor: pointer;
    margin: 2rem 0;

    input {
      position: relative;
      &:checked + strong {
        color: #fff;
        background-color: #28284b;
        border: 0.2rem solid #28284b;
      }
    }

    input[type='radio'] + .chk {
      width: 2.4rem;
      height: 2.4rem;
    }

    strong {
      display: inline-block;
      width: 31.4rem;
      padding: 1.6rem 0;
      font-weight: 400;
      font-size: 1.6rem;
      color: #717188;
      text-align: center;
      background-color: #fff;
      border: 0.2rem solid #a0a0b6;
      border-radius: 0.4rem;
    }
    & + label {
      margin-left: 3rem;
    }
    span {
      display: inline-block;
      position: relative;
      padding: 0.8rem 0;
      font-weight: 400;
      font-size: 1.6rem;
      color: #000;
    }
  }
}

.badge-gra {
  display: inline-block;
  position: relative;
  margin-left: 1.9rem;
  padding: 0.8rem 1rem 0.8rem 2rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: $white;
  border-radius: 3px;
  background: linear-gradient(120deg, #ff5647, #ffa114, #ffa114, #ff5647);
  background-size: 500% 500%;
  animation: gradient 3s ease infinite;
  vertical-align: top;

  .df-txt {
    content: 'M';
    display: inline-block;
    color: $warning;
    margin-right: 0.5rem;
  }
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    border-top: 1.83rem solid $white;
    border-bottom: 1.83rem solid $white;
    border-right: 1.5rem solid transparent;
    transform: translateY(-50%);
  }
}
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@media screen and (max-width: 1024px) {
  dd {
    &.befor {
      content: '(-)';
    }
    &.after {
      @include price;
    }
  }

  .cart-col {
    .no-content {
      margin: 10rem 0;
      padding: 11.8rem 0;

      .ico {
        display: inline-block;
        width: 9.1rem !important;
        margin-bottom: 3.8rem !important;
      }

      .desc {
        font-size: 2.4rem !important;
      }

      a {
        display: inline-block;
        height: 10rem;
        margin: 5rem 0 33.3rem;
        padding: 0 3rem;
        font-size: 2.7rem;
        font-weight: 700;
        border: 1px solid $gray-500;
        border-radius: 0.5rem;
        line-height: 10rem;
      }
    }
  }

  .cart-col {
    font-size: 2rem;
    line-height: 3rem;

    .inner-col {
      text-align: left;

      & > .btn-accordion {
        position: absolute;
        top: -1.5rem;
        right: -1rem;
        width: 5rem;
        height: 5rem;
        margin: 0;
        background: #fff url('#{$img-cdn}/ico_arrow_63x63_b.png') no-repeat 50% 50%;
        background-size: 2rem auto;
        text-indent: -9999rem;
        transform: rotate(180deg);
        transition: 0.5s;
        overflow: hidden;
      }
    }

    h3 {
      margin-bottom: 2.4rem;
      font-size: 3rem;
    }

    .total-chk {
      display: flex;
      padding: 3.5rem 4.2rem;
      color: $default;
      background-color: $gray-100;
      font-size: 2.7rem;

      label,
      button {
        flex: 1;
        align-content: space-between;
      }

      button {
        text-align: right;
      }
    }

    .btn-block.disabled {
      color: $dark;
      background-color: $gray-500;
      border-color: $gray-500;
    }

    .item-box {
      display: flex;
    }

    .item-info {
      @include item-info-box(1.4rem);
      flex: 0 0 80%;

      em {
        margin: 0 0 1rem;
        color: $gray-900;
        font-size: 2.2rem;
      }
    }

    .item-detail {
      dl {
        display: flex;
        margin-left: 16.1rem;
        font-size: 2rem;

        & + dl {
          padding-top: 3.2rem;
          border-top: 1px solid #e7e7f0;
        }
      }
      dt {
        color: $gray-900;
        width: 6.7rem;
        font-weight: 700;
      }
      dd {
        width: 100%;
        p {
          margin-bottom: 1.5rem;

          strong,
          em {
            @include price;
          }
          em {
            margin-left: 1rem;
            color: $gray-700;
            text-decoration: line-through;
          }
        }
      }

      .option {
        display: flex;
        flex-wrap: nowrap;

        .item-list {
          flex: 2 1 0;

          strong {
            margin-right: 1.1rem;
            font-weight: 700;
          }

          em {
            color: $gray-700;
            text-decoration: line-through;
          }
        }
      }
    }

    .cart-item {
      flex: 0 0 70%;

      &.delivery {
        font-size: 2.6rem;

        h3 + p {
          &:before {
            content: '※';
          }
          font-size: 2.4rem;
          color: $gray-800;
        }
      }

      > ul > li {
        padding: 5rem 4.2rem;
        border-bottom: 1.2rem solid $gray-200;
      }

      .item-img {
        @include item-img-box(16rem, 11rem);
        flex-shrink: 0;
        margin: 0 2.5rem 0 0;

        &.delivery {
          &:after {
            height: 3.6rem;
            font-size: 2rem;
            line-height: 3.6rem;
          }
        }
      }
    }

    .cart-item li {
      position: relative;

      & > label {
        display: block;
        position: relative;
        // top: 4.2rem;
        // right: 3rem;
      }
      & > label > .chk {
        position: absolute;
        top: 0;
        right: 0;
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 0;
      }

      .item-detail {
        // margin-left: 16rem;

        & > p,
        & > div {
          margin-bottom: 1.5rem;
          //padding-left: 6.1rem;
          padding-left: 0;
        }

        & > p:first-child {
          position: relative;

          &:before {
            content: '구성';
            position: absolute;
            top: 0;
            left: 0;
            color: $gray-900;
            font-weight: 700;
          }
        }

        .option:first-of-type {
          position: relative;
          //margin-top: 1.8rem;
          //padding-top: 1.8rem;
          margin-top: unset;
          padding-top: unset;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            //border-top: 1px solid $gray-300;
          }

          &:after {
            //content: "옵션";
            content: '';
            position: absolute;
            top: 1.8rem;
            left: 0;
            color: $danger;
            font-weight: 700;
          }
        }
        /*input:checked + .chk {
              background-color: $warning;
          }
          .chk {
              @include chk-default(3.6rem, 3.6rem, 1rem);
          }*/
      }
    }

    &.detail {
      .card {
        padding: 5rem 4.2rem;
        background-color: $white;
        font-size: 2.6rem;
        border-top: 1.2rem solid $gray-200;

        &:first-of-type {
          border-bottom: 0;
        }

        h3 {
          margin-bottom: 4.9rem;
          font-size: 3rem;
          font-weight: 700;
          color: $dark;
        }

        .btn-block {
          margin-top: 5rem;
          font-weight: 400;
        }

        label {
          font-size: 2.7rem;

          .chk {
            & + span {
              font-size: 2.7rem;
            }
          }
        }

        &.shipping {
          padding: 5rem 0;

          h3 {
            padding: 0 4.2rem;
            box-sizing: border-box;
          }

          .btn-accordion {
            right: 3rem;
          }

          .shipping-address-box {
            > p {
              padding: 0 4.2rem;
            }

            .address-info {
              margin-top: 5rem;
              padding: 4.5rem 4.2rem;
              background-color: $gray-100;

              p {
                line-height: 1.5;
              }
            }

            dl {
              padding: 0 4.2rem;
              box-sizing: border-box;
            }
          }
        }
      }

      .price-sort {
        color: $gray-800;

        li {
          display: flex;
          justify-content: space-between;
          strong {
            color: #28284b;
          }

          span + span {
            @include price;
          }
        }
      }

      .descount-box {
        li {
          span + span {
            position: relative;
            font-weight: 700;
            color: #28284b;

            &:before {
              content: '(-)';
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
        }

        .ico-mark {
          position: absolute;
          font-size: 2rem;
        }
      }

      .order-list-box {
        p {
          position: relative;
          margin-top: 4.6rem;
          padding-top: 4.4rem;
          color: $dark;
          font-size: 3rem;
          font-weight: 700;
          border-top: 2px solid $gray-400;
          text-align: right;

          &:before {
            content: '최종 결제 금액';
            position: absolute;
            left: 0;
          }

          strong {
            color: #ff5647;
            font-size: 4rem;
            font-weight: 700;
            @include price;

            span {
              display: block;
            }

            & + span {
              display: block;
              margin-top: 1rem;
              color: #717188;
              font-size: 2rem;
              font-weight: 400;
            }
          }
        }
      }

      .pay-chk-box {
        .ipt-group {
          margin-top: 5.1rem;

          label {
            width: 33.3%;
            margin: 0 0 5.8rem;
            .chk:before {
              height: 2rem;
            }
          }

          span {
            width: 12.3rem;
            height: 5.1rem;
            vertical-align: middle;
          }
        }
      }

      .order-btn-box {
        label {
          span {
            margin-left: 1rem;
            font-weight: 700;
          }
          p {
            padding: 1.1rem 5.6rem 0;
            color: $gray-700;
            font-weight: 400;
          }
        }

        div {
          margin: 7.5rem 0 4.6rem;
          color: $gray-800;

          p:before {
            content: '·';
            display: inline-block;
            margin-right: 0.5rem;
          }
        }

        .btn-block {
          margin-top: 2.4rem;
          &.btn-complete {
            position: relative;
            font-weight: 700;
            font-size: 2.7rem;
            color: #000;
            background-color: #fff;
            border: 0.1rem solid #e7e7f0;
            &::before {
              content: '';
              position: absolute;
              width: 4rem;
              height: 3rem;
              margin-left: -4.5rem;
              background: url('#{$img-cdn}/ico_check_99x81_green.png') no-repeat 50% 50% / 2.4rem
                auto;
            }
          }
        }

        button + button {
          color: $white;
          background-color: $gray-500;
          border-color: $white;

          &.active {
            color: $dark;
            background-color: $warning;
            border-color: $warning;
          }
        }
      }

      .item-box {
        margin-bottom: 4.5rem;
        padding-bottom: 4.5rem;
        border-bottom: 1px solid $gray-200;

        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
      .item-info {
        flex: auto;
        overflow: hidden;
        p {
          strong {
            margin-bottom: 0;
          }

          &:first-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            strong {
              display: inline-block;
              width: calc(100% - 2rem);
              font-size: 2.7rem;
              font-weight: 400;
            }
          }
        }
      }

      .item-price {
        margin-top: 0.5rem;

        div {
          position: relative;

          &:not(.material-counter) {
            margin-left: 0;
          }
        }

        p {
          em {
            display: inline-block;
            margin: 1rem 0 0;
            font-size: 2.4rem;
            color: #717188;
            text-decoration: none;

            & + em {
              margin-left: 0.3rem;

              &:after {
                content: '';
              }
            }
          }
        }
        p + p strong {
          margin-top: 1.4rem;
          font-weight: normal;

          &::before {
            content: '총';
            display: inline-block;
            margin-right: 1.4rem;
          }
        }

        strong {
          font-size: 3.2rem;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;

          & + span {
            display: inline-block;
            font-size: 2.4rem;
            vertical-align: middle;
          }

          & + em {
            display: inline-block;
            margin: 0.5rem 0 0.2rem;
            color: #717188;
            font-size: 2.4rem;
            vertical-align: middle;
          }

          & + em + span {
            position: absolute;
            top: 0.7rem;
            right: 0;
            bottom: 0;
            color: $gray-700;
            margin: auto 0;
            font-size: 2.2rem;
            vertical-align: middle;

            &:before {
              content: '수량';
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
        }
        .ico-member {
          display: none;
          width: 3rem;
          height: 3rem;
          margin-right: 1.2rem;
          line-height: 3rem;

          &:before {
            font-size: 2rem;
          }
        }
      }
      .item-img {
        @include item-img-box(16rem, 11rem);
        //display: none;
        flex-shrink: 0;
        margin: 0 2.5rem 0 0;
      }
    }

    .item-price {
      display: flex;
      margin-top: 5.4rem;
      padding-right: 5.1rem;

      div {
        &:not(.material-counter) {
          flex-grow: 1;
          //   margin-left: 16rem;
          text-align: left;
        }

        &.material-counter {
          flex-grow: 6.8rem;
        }
      }

      div,
      button {
        align-content: center;
        align-items: center;
      }

      em {
        font-size: 2rem;
      }

      strong {
        font-size: 3.6rem;
        vertical-align: middle;
      }

      & > button {
        width: 17.4rem;
        height: 5.8rem;
        font-size: 2.6rem;
        line-height: 5.8rem;
      }
    }

    .order-list-col {
      padding: 0;

      h3 {
        margin-bottom: 2.7rem;
        font-size: 3rem;
        font-weight: 700;
      }
    }

    .order-item {
      padding: 4.95rem 4.2rem 1.5rem;

      li {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 3rem;
        font-size: 2.6rem;

        span {
          flex-grow: 1;
          & + span {
            @include price;
            text-align: right;
          }
        }

        &:last-of-type,
        & li {
          span + span:before {
            content: '(-)';
            display: inline-block;
            margin-right: 0.5rem;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          margin-top: 2.3rem;
          color: $gray-600;

          li {
            margin-bottom: 2.3rem;
            font-size: 2.4rem;

            &:before {
              content: 'ㄴ';
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .user-info {
      color: $gray-800;
      font-size: 2.6rem;

      p {
        line-height: 1.5;
      }
    }

    .price-item {
      padding: 5.3rem 4.2rem 8rem;
      background-color: $gray-100;
      font-size: 4rem;
      font-weight: 700;

      p {
        @include price;

        position: relative;
        text-align: right;
        color: $danger;

        &:before {
          content: '총 주문 금액';
          position: absolute;
          left: 0;
          font-size: 3rem;
          font-weight: 700;
          color: $dark;
        }
      }

      button {
        display: block;
        width: 100%;
        height: 10rem;
        margin: 5.3rem 0 0;
        background-color: $warning;
        font-weight: 400;
        font-size: 3.2rem;
      }
    }

    .shipping-address-box {
      display: block;

      > p {
        span {
          display: none;
        }
      }

      .info {
        em {
          display: block;
        }

        span {
          display: none;
        }
      }

      .default-address {
        width: 100%;
        margin: 5.1rem 0 0 -4.2rem;
        padding: 3.8rem 4.2rem;
        background-color: $gray-100;
        p {
          font-weight: 400;
          font-size: 2.6rem;
          line-height: 4.6rem;
        }

        + dl {
          display: block;
        }
      }

      .update-info-check {
        display: block;
        margin-top: 3rem;

        input[type='checkbox'] {
          font-size: 2.6rem;
          color: $gray-800;

          + .chk {
            width: 3.6rem;
            height: 3.6rem;
            margin-right: 2rem;

            &::before {
              width: 2rem;
              height: 1.8rem;
            }
          }
        }
      }

      .address {
        input[type='text'] {
          margin-top: 4rem;
        }

        p {
          em {
            font-weight: 400;
            font-size: 2.4rem;
            color: #333;
            &::before {
              content: '※';
              margin-right: 0.2rem;
            }
          }
        }

        .flex-form {
          display: flex;
          align-items: flex-end;

          input[type='text'] {
            flex: 1 1;
            width: auto;
            margin-top: 0;
          }

          button {
            margin-left: 2rem;
            padding: 3.5rem 2.4rem;
            font-weight: 400;
            font-size: 2.7rem;
            color: #000;
            background-color: #ffb900;
            border-radius: 0.5rem;
          }
        }
      }
    }

    .full-banner-box {
      margin: 6rem -4.2rem -5rem -4.2rem;
      a {
        display: block;
      }
    }

    .fold {
      .btn-accordion {
        transform: rotate(0deg);
      }

      .user-info {
        height: 3rem;
        overflow: hidden;
        p {
          display: inline-block;
          max-width: 50%;
          margin-bottom: 5rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          box-sizing: border-box;
        }
        p + p {
          padding-left: 4rem;
        }
      }

      .shipping-address-box {
        > p {
          span {
            display: block;
          }
        }

        em {
          display: none;
        }

        span {
          display: block;
        }
      }

      form {
        display: none;
      }

      // .default-address {
      //   display: none;

      //   + dl{
      //     display: none;
      //   }
      // }

      .pay-type-box {
        & > label {
          display: none;
        }
        .ipt-group {
          label {
            position: absolute;
            top: 0;
            left: 0;
            span {
              display: none;
            }
            input {
              &:checked + strong {
                display: inline-block;
              }
              &:checked + span {
                display: inline-block;
                margin: 0;
                padding-left: 0.3rem;
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }

      .descount-box {
        li {
          display: none;
        }
        .descount-total {
          display: block;
          margin-bottom: 0;
          span {
            display: none;
            & + span {
              display: block;
              &::before {
                content: '-';
              }
            }
          }
        }
        button {
          display: none;
        }
      }

      .order-list-box {
        ul {
          display: none;
        }
        p {
          padding-top: 0;
          text-align: left;
          border-top: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    .btn-coupon-download {
      display: inline-block;
      margin-left: 1.2rem;
      padding: 1.4rem 4.2rem 1.4rem 1.6rem;
      font-weight: 400;
      font-size: 2.2rem;
      background: #28284b url('#{$img-cdn}/ico_download_type2_48x42w.png') no-repeat 90% 50% /
        2.3rem auto;
      border-radius: 0.5rem;
    }

    .banner-col {
      max-width: 100%;
      margin: 0 auto;
      padding: 0;
    }
  }

  .pay-type-box {
    border-top: 0;
    border-bottom: 0;
    text-align: left;

    label {
      display: inline-block;
      width: calc(50% - 0.6rem);

      input {
        position: absolute;
        &:checked + strong {
          color: #fff;
          background-color: #28284b;
          border: 0.2rem solid #28284b;
        }

        &:checked + span {
          &::before {
            background-image: url('#{$img-cdn}/ico_check_on_96x96.png');
          }
        }
      }
      strong {
        display: inline-block;
        width: 100%;
        padding: 2.4rem 0;
        font-size: 2.2rem;
        font-weight: 400;
        border-radius: 0.5rem;
      }
      & + label {
        margin-left: 1.2rem;
      }
      span {
        padding: 1rem 0;
        font-size: 2.6rem;

        &::before {
          left: -5rem;
          width: 3.4rem;
          height: 3.4rem;
        }
      }
    }
    .ipt-group {
      label {
        width: auto;
        margin: 5rem 4rem 0 0;
        margin: 0 2rem 0 0;

        .ico-payment {
          width: calc(24.6rem / 2);
          height: calc(10.2rem / 2);
        }
      }
    }
  }

  .badge-gra {
    padding: 0.4rem 1rem 0.3rem 2rem;
    font-size: 2.2rem;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 0;
      height: 0;
      border-top: 1.83rem solid $white;
      border-bottom: 1.83rem solid $white;
      border-right: 1.5rem solid transparent;
      transform: translateY(-50%);
    }
  }
}

.test + span {
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
}

.ico-delivery {
  display: inline-block;
  width: 14rem;
  margin-bottom: 3.6rem;
  padding: 0.5rem 0;
  background-color: $warning;
  font-size: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}

// 추가 교재 구매 리스트
// TODO: cart.scss 수정 필요
.add-book-list {
  .imgBox {
    margin: 0px 1.6rem 0px 1.1rem;
    width: 8rem;
    height: 5.5rem;

    img {
      object-fit: cover;
      object-position: center;
      -o-object-fit: cover;
      -o-object-position: center;
      width: 100%;
      height: 100%;
    }
    & + strong {
      flex: 1;
      display: block;
      padding: 0 8px 0 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .add-book-list {
    .imgBox {
      margin: 0 2.4rem;
      width: 11.8rem;
      height: 8rem;
    }
  }
}
