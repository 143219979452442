:global body.fixed #root {
  padding-top: 0 !important;
}

.main {
  background-color: #171720;
  padding-top: 48px;
  padding-bottom: 80px;
  min-height: 100vh;
  box-sizing: border-box;

  &.lv1 {
    --levelColor: #ff6255;
  }

  &.lv2 {
    --levelColor: #ff9925;
  }

  &.lv3 {
    --levelColor: #ffc700;
  }

  &.lv4 {
    --levelColor: #8fd945;
  }

  &.lv5 {
    --levelColor: #4e9fff;
  }

  &.lv6 {
    --levelColor: #b574ff;
  }
}

.inner {
  background-color: #171720;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.goals {
  margin-bottom: 56px;
  padding: 0 36px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    box-sizing: border-box;
  }
  .date {
    color: #717188;
    font-size: 16px;
    line-height: 125%;
  }

  .user {
    margin-top: 44px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 125%;
    box-sizing: border-box;
    strong {
      font-size: 36px;
      display: block;
      font-weight: 600;
      margin-bottom: 4px;
      line-height: 130%;
    }
  }

  .level {
    position: relative;
    margin-top: 32px;
    padding: 26px 43px;
    background-color: #1f1f2b;
    border-radius: 30px;
    overflow: hidden;
    height: 224px;
    box-sizing: border-box;

    .jpg,
    .gif {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 370px;
      height: 189.5px;
      object-fit: cover;
    }

    .gif {
      z-index: 2;
    }

    .jpg {
      z-index: 1;
      animation: showDelay 0s forwards;
      animation-delay: 0.3s;
    }
  }
}

@keyframes showDelay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.levelText {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  p {
    width: 76px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 2em;
    background-color: var(--levelColor);
    margin: auto;
    text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  h3 {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
  }
}

.contents {
  background-color: #fff;
  padding: 66px 0 0 0;
  border-radius: 34px;
  overflow: hidden;

  section {
    padding-left: 28px;
    padding-right: 28px;
    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }
}

.hgroup {
  text-align: center;
  margin-bottom: 64px;
  h3 {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 6px;
  }
  h6 {
    color: #717188;
    text-align: center;
    font-size: 18px;
    line-height: 145%;
    margin-bottom: 6px;
    font-weight: 400;
  }
}

.average {
  display: flex;
  flex-direction: column;
  gap: 38px 0;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    p {
      &:first-child {
        color: #717188;
        font-size: 20px;
        font-weight: 500;
      }

      &:last-child {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        span {
          color: #b7b7c8;
        }
      }
    }
  }

  .track {
    width: 100%;
    height: 21px;
    background-color: #f2f3f5;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    height: 100%;
    background-color: var(--levelColor);
    border-radius: 5px;
  }

  .param {
    color: #b7b7c8;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    line-height: 162%;
  }
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 29px;

  button {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    border-color: #28284b;
    background-color: #28284b;
    padding: 0 18px;
    height: 42px;
    transition: all ease 0.3s;
  }
}

.grading {
  .hgroup {
    margin-bottom: 38px;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 9px;

    li {
      width: 33.3333%;
      border-radius: 16px;
      background: #fff;
      height: 216px;
      text-align: center;
      padding-top: 27px;
      box-sizing: border-box;
      border: 1px solid #e7e7f0;
    }

    p {
      color: #717188;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 4px;
    }
    h5 {
      margin-bottom: 19px;
      color: #717188;
      font-size: 18px;
      font-weight: 500;
    }
    h3 {
      font-size: 34px;
      margin-bottom: 33px;
      span {
        color: #dfdfea;
      }
    }

    figure {
      text-align: center;
      img {
        height: 32px;
      }
    }
  }
}

.assessment {
  .hgroup {
    margin-bottom: 47px;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  li {
    background-color: #f7f7fc;
    padding: 20px 40px 20px 20px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      right: 21px;
      top: 50%;
      width: 20px;
      height: 20px;
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/levelTestAdvanced/2024/ico_eval-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: grayscale(1);
      transform: translateY(-50%);
    }

    &.active {
      border: 2px solid #b7b7c8;
      background: #fff;
      h4 {
        color: #28284b;
        font-size: 20px;
        font-weight: 700;
      }
      p {
        display: block;
      }

      &::before {
        transform: rotate(-180deg);
        filter: brightness(0);
        top: 15px;
      }
    }
  }

  h4 {
    color: #717188;
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
  }
  p {
    color: #000;
    font-size: 16px;
    line-height: 150%;
    margin-top: 8px;
    display: none;
    word-break: keep-all;
  }
}

.download {
  margin-top: 54px;
  text-align: center;
  p {
    color: #717188;
    font-size: 16px;
    line-height: 137.5%;
    margin-bottom: 24px;
  }

  button {
    width: 268px;
    height: 64px;
    border-radius: 12px;
    background: #28284b;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
    color: #fff;
    font-size: 20px;

    img {
      margin-right: 8px;
    }
  }
}

.banner {
  cursor: pointer;
}

.lineChart {
  overflow: hidden;
  .hgroup {
    margin-bottom: 44px;
  }

  canvas {
    position: relative;
    z-index: 2;
  }

  .labels {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    position: relative;
    z-index: 1;

    li {
      color: #a0a0b6;
      font-size: 20px;
      font-weight: 600;
      position: relative;

      &.active {
        color: #28284b;
        &::before {
          content: '';
          height: 206px;
          width: 2px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CFCFDAFF' stroke-width='4' stroke-dasharray='3%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          position: absolute;
          z-index: 1;
          transform: translateX(-50%);
          bottom: 46px;
        }
      }
    }

    line {
      stroke: rgb(0, 0, 0);
      stroke-width: 2;
    }

    &.lv1 {
      li.active::before {
        left: 23%;
      }
    }
    &.lv2 {
      li.active::before {
        left: 38%;
      }
    }
    &.lv3 {
      li.active::before {
        left: 52%;
      }
    }
    &.lv4 {
      li.active::before {
        left: 61%;
      }
    }
    &.lv5 {
      li.active::before {
        left: 70%;
      }
    }
    &.lv6 {
      li.active::before {
        left: 81%;
      }
    }
  }
}

.radarChart {
  margin-bottom: 30px;
}

.recommend {
  border-top: 16px solid #f5f5f7;
  padding-top: 80px;
  padding-left: 50px !important;
  padding-right: 50px !important;

  .hgroup {
    margin-bottom: 24px;
  }
}

.recommendProduct {
  figure {
    margin-bottom: 33px;
    border-radius: 16px;
    overflow: hidden;
  }
  p {
    color: #28284b;
    font-size: 18px;
    line-height: 144%;
    margin-bottom: 30px;
  }
  ul {
    gap: 24px 0;
    display: flex;
    flex-direction: column;
    li {
      font-size: 18px;
      color: #28284b;
      font-weight: 500;
      line-height: 111%;
    }
  }
}

.recommendLevel {
  margin-top: 80px;

  figure {
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 32px;
  }
  p {
    color: #28284b;
    font-size: 18px;
    font-weight: 500;
    line-height: 144%;
  }
  strong {
    display: inline-block;
    margin-bottom: 8px;
  }
}

.recommendButtons {
  display: flex;
  gap: 0 18px;
  margin-top: 48px;
  button {
    flex: 1;
    height: 64px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;

    &:first-child {
      background-color: #ececf6;
    }
    &:last-child {
      color: #fff;
      background-color: #28284b;
    }
  }
}

.shareModal {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 2.667px 0px rgba(0, 0, 0, 0.27);
  height: 320px;
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #000;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 142%;
    margin-bottom: 38px;
  }

  :global .share-item li button {
    width: 86px;
    height: 86px;
  }
}

@media all and (max-width: 750px) {
  .main {
    padding-top: 5.867vw;
    padding-bottom: 0;
  }
  .inner {
    width: 100%;
  }
  .goals {
    padding: 0 7.333vw;
    margin-bottom: 9.733vw;

    .date {
      font-size: 3.2vw;
    }

    button {
      img {
        width: 6.4vw;
      }
    }
    .user {
      font-size: 4.8vw;
      strong {
        font-size: 7.467vw;
        margin-bottom: 2.667vw;
      }
    }

    .level {
      padding: 3.467vw 5.733vw;
      border-radius: 4vw;
      height: 44.8vw;

      .jpg,
      .gif {
        width: calc(100% - 11.467vw);
        height: calc(100% - 6.933vw);
      }
    }
  }

  .levelText {
    bottom: 7vw;
    p {
      width: 15.6vw;
      height: 7.6vw;
      text-shadow: 0px 0px 0.933vw rgba(0, 0, 0, 0.17);
      font-size: 4.267vw;
      margin-bottom: 3.333vw;
    }
    h3 {
      font-size: 4.8vw;
    }
  }

  .contents {
    padding: 13.333vw 0 0 0;
    border-radius: 4.533vw 4.533vw 0 0;

    section {
      padding-left: 5.6vw;
      padding-right: 5.6vw;
      &:not(:last-child) {
        margin-bottom: 16vw;
      }
    }
  }

  .hgroup {
    margin-bottom: 11.2vw;
    h3 {
      font-size: 5.333vw;
      margin-bottom: 2.667vw;
    }
    h6 {
      font-size: 3.733vw;
      margin-bottom: 1.333vw;
    }
  }

  .average {
    gap: 7.467vw 0;
    .title {
      margin-bottom: 3.2vw;
      p {
        &:first-child,
        &:last-child {
          font-size: 4.267vw;
        }
      }
    }

    .track {
      height: 4.267vw;
      border-radius: 1.067vw;
      margin-bottom: 0.8vw;
    }

    .fill {
      border-radius: 1.067vw;
    }

    .param {
      font-size: 3.2vw;
    }
  }

  .lineChart {
    .hgroup {
      margin-bottom: 8.933vw;
    }
    .labels {
      margin-top: 4.4vw;
      li {
        font-size: 3.733vw;

        &.active {
          &::before {
            height: 41vw;
            bottom: 10.5vw;
            width: 2px;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CFCFDAFF' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            background-size: 1.6vw;
          }
        }
      }

      &.lv1 {
        li.active::before {
          left: 30%;
        }
      }
      &.lv2 {
        li.active::before {
          left: 43%;
        }
      }
      &.lv3 {
        li.active::before {
          left: 49%;
        }
      }
      &.lv4 {
        li.active::before {
          left: 54%;
        }
      }
      &.lv5 {
        li.active::before {
          left: 62%;
        }
      }
      &.lv6 {
        li.active::before {
          left: 66%;
        }
      }
    }
  }

  .btnWrap {
    margin-top: 5.867vw;

    button {
      font-size: 3.2vw;
      height: 8.533vw;
      padding: 0 3.6vw;
      border-radius: 1.6vw;
    }
  }

  .grading {
    .hgroup {
      margin-bottom: 7.6vw;
    }
    ul {
      gap: 0 1.867vw;
      li {
        padding-top: 5.467vw;
        height: 43.2vw;
        border-radius: 3.2vw;
      }
      p {
        font-size: 3.2vw;
        margin-bottom: 0.267vw;
      }
      h5 {
        font-size: 3.733vw;
        margin-bottom: 3.733vw;
      }
      h3 {
        font-size: 6.933vw;
        margin-bottom: 6.667vw;
      }
      figure {
        img {
          height: 6.4vw;
        }
      }
    }
  }

  .assessment {
    .hgroup {
      margin-bottom: 2.667vw;
    }
  }

  .accordion {
    gap: 2.133vw 0;
    li {
      border-radius: 2.133vw;
      padding: 4vw 10.933vw 4vw 5.6vw;
      &.active {
        padding: 5.6vw 10.933vw 5.6vw 5.6vw;
        h4 {
          margin-bottom: 1.6vw;
          font-size: 4.267vw;
        }

        &::before {
          top: 3.028vw;
        }
      }
      &::before {
        width: 4vw;
        height: 4vw;
        right: 4.267vw;
      }
    }
    h4 {
      font-size: 3.733vw;
    }
    p {
      font-size: 3.2vw;
    }
  }

  .radarChart {
    margin-bottom: 3.333vw;
  }

  .download {
    margin-top: 9.333vw;
    p {
      font-size: 3.2vw;
      margin-bottom: 4.8vw;
    }

    button {
      width: 53.333vw;
      height: 12.8vw;
      border-radius: 2.4vw;
      font-size: 4.267vw;

      img {
        width: 4.8vw;
        margin-right: 1.067vw;
      }
    }
  }

  .recommend {
    border-width: 2.133vw;
    padding-top: 10.667vw;
    padding-left: 9.867vw !important;
    padding-right: 9.867vw !important;
  }

  .recommendProduct {
    figure {
      margin-bottom: 6.4vw;
      border-radius: 3.2vw;
    }
    p {
      font-size: 3.733vw;
      line-height: 135%;
      margin-bottom: 6.4vw;
    }
    ul {
      gap: 4.5vw 0;
      li {
        font-size: 3.733vw;
        line-height: 107%;
      }
    }
  }

  .recommendLevel {
    margin-top: 16vw;

    figure {
      border-radius: 3.2vw;
      margin-bottom: 6.4vw;
    }
    p {
      font-size: 3.733vw;
      line-height: 135%;
    }
    strong {
      margin-bottom: 1.6vw;
    }
  }

  .recommendButtons {
    margin-top: 8vw;
    gap: 0 2.4vw;

    button {
      height: 12.267vw;
      border-radius: 1.6vw;
      font-size: 4.267vw;
    }
  }
}

@media all and (max-width: 480px) {
  .accordion li.active {
    border-width: 1px;
  }

  .lineChart {
    .labels {
      li {
        &.active {
          &::before {
            height: 40vw;
            bottom: 11vw;
          }
        }
      }
    }
  }
}
