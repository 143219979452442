.container {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
  padding: 58px 28px 50px;
  box-sizing: border-box;
  border-radius: 40px 40px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.25);
  transform: translateY(100%);
  animation: slideUp 0.7s forwards;

  &.slideDown {
    animation: slideDown 0.7s forwards;
  }
  &.slideUp {
    animation: slideUp 0.7s forwards;
  }
}
.quizIndex {
  display: inline-flex;
  padding: 12px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 500px;
  background: #ccedff;
  box-sizing: border-box;
  color: #3396ff;
  font-family: Inter;
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.52px;
  position: absolute;
  top: 28px;
  left: 28px;
}
.question {
  white-space: pre-line;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1.28px;
  opacity: 0.5;
}
.engText {
  margin-top: 35px;
  color: #000;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  transition: all ease 0.3s;
}
.korText {
  margin-top: 6px;
  color: #000;
  font-size: 32px;
  line-height: 47px;
}
.buttonWrap {
  display: flex;
  gap: 24px;
  margin-top: 67px;
  justify-content: center;
}
.oxButton {
  width: 260px;
  height: 168px;
  border-radius: 30px;
  border: 0.27vw solid rgba(0, 0, 0, 0.1);
  &:first-of-type {
    background: #269cff;
    svg {
      width: 109px;
      height: 109px;
    }
  }
  &:last-of-type {
    background: #ff5647;
    svg {
      width: 109px;
      height: 109px;
    }
  }
  &.disabled {
    background: var(--GrayScale-500---A0A0B6, #a0a0b6);

    svg path,
    circle {
      stroke: #dfdfea;
    }
  }
  &.selected {
    &:first-of-type {
      background: #269cff;
      svg {
        width: 109px;
        height: 109px;
      }
    }
    &:last-of-type {
      background: #ff5647;
      svg {
        width: 109px;
        height: 109px;
      }
    }
  }
}
.textButton {
  width: 100%;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.26);
  background: #fff;
  height: 88px;
  color: var(--GrayScale-900---000000, #000);
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.5px;
  &.disabled {
    border: 2px solid rgba(0, 0, 0, 0.26);
    background: var(--GrayScale-50---ECECF6, #ececf6);
    color: #b7b7c8;
  }
  &.selected {
    color: #fff;
    border: 0.27vw solid rgba(0, 0, 0, 0.26);
    background: #269cff;
  }
}
.blankButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 14px;
  margin-top: 46px;
}
.translateButtons {
  display: grid;
  gap: 20px;
  margin-top: 46px;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
@media screen and (max-width: 750px) {
  .container {
    padding: 7.733vw 3.733vw 6.667vw;
    border-radius: 5.333vw 5.333vw 0vw 0vw;
    box-shadow: 0vw 0.533vw 3.6vw 0vw rgba(0, 0, 0, 0.25);
  }

  .quizIndex {
    padding: 1.6vw 2.667vw;
    gap: 1.067vw;
    border-radius: 66.667vw;
    font-size: 3.467vw;
    line-height: 3.733vw;
    letter-spacing: -0.069vw;
    top: 3.733vw;
    left: 3.733vw;
  }
  .question {
    font-size: 4.267vw;
    letter-spacing: -0.171vw;
  }
  .engText {
    margin-top: 4.667vw;
    font-size: 6.4vw;
  }
  .korText {
    margin-top: 0.8vw;
    font-size: 4.267vw;
    line-height: 6.267vw;
  }
  .buttonWrap {
    gap: 3.2vw;
    margin-top: 8.933vw;
  }
  .oxButton {
    width: 34.667vw;
    height: 22.4vw;
    border-radius: 4vw;
    &:first-of-type {
      svg {
        width: 14.533vw;
        height: 14.533vw;
      }
    }
    &:last-of-type {
      svg {
        width: 14.533vw;
        height: 14.533vw;
      }
    }
    &.selected {
      &:first-of-type {
        background: #269cff;
        svg {
          width: 14.533vw;
          height: 14.533vw;
        }
      }
      &:last-of-type {
        background: #ff5647;
        svg {
          width: 14.533vw;
          height: 14.533vw;
        }
      }
    }
  }

  .blankButtons {
    gap: 2.667vw 1.867vw;
    margin-top: 6.133vw;
  }
  .translateButtons {
    gap: 2.667vw;
    margin-top: 6.133vw;
  }
  .textButton {
    border-radius: 2.667vw;
    border: 0.267vw solid rgba(0, 0, 0, 0.26);
    height: 11.733vw;
    font-size: 4.8vw;
    line-height: 5.867vw;
    letter-spacing: -0.067vw;
    &.disabled {
      border: 0.267vw solid rgba(0, 0, 0, 0.26);
    }
  }
}
