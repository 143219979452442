$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';

.faqSearchSection {
  margin-bottom: 76px;
  padding: 57px 0 47px;
  background-color: #f7f7fc;

  .csInner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 33px;
  }

  .searchNav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    height: 60px;

    button {
      color: #8d8da0;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      transition: color 0.3s;
      margin-top: 2px;

      &:hover {
        color: #000;
      }

      &:last-child {
        color: #269cff;
        &:hover {
          color: #0c68b5;
        }
      }
    }
  }

  .faqSearchWrap {
    flex: 1;
    width: 100%;
  }
  .faqSearchBox {
    max-width: 100%;
    margin: 0 0 20px 0;
  }
}
@media screen and (max-width: 1024px) {
  .faqSearchSection {
    margin-bottom: 0;
    padding: 50px 0;
    background-color: #fff;
    .csInner {
      flex-direction: column;
      gap: 20px;
    }
    .searchNav {
      height: auto;
    }
    .faqSearchWrap {
      flex: auto;
    }
    .faqSearchBox {
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 750px) {
  .faqSearchSection {
    padding: 6.667vw 0;

    .faqSearchBox {
      margin-bottom: 3.2vw;
    }
  }
}
