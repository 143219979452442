.mobileTitleBar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 108px;
  background-color: #fff;
  z-index: 999;

  button {
    position: absolute;
    left: 36px;
    top: 16.5px;
    display: block;
    width: 75px;
    height: 75px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    color: #000;
    font-size: 29px;
    font-weight: 700;
    margin-top: 2px;
  }

  @media screen and (max-width: 750px) {
    height: 14.4vw;

    button {
      left: 4.8vw;
      top: 2.2vw;
      width: 10vw;
      height: 10vw;
    }

    h3 {
      font-size: 3.867vw;
    }
  }

  @media screen and (max-width: 360px) {
    height: 52px;

    button {
      width: 24px;
      height: 24px;
      left: 20px;
      top: 14px;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
