$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';

body {
  &.fixed {
    .container {
      &.lnb-fixed {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;

        #lnb {
          .lnb-col {
            position: fixed;
            top: 10.9rem;
            z-index: 5;

            &.remove {
              position: relative;
              top: 0;
            }
          }
        }
      }
    }
  }
}

.container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  > section {
    flex: 1 1 auto;
    align-self: flex-start;
    width: 70%;
  }
}

.user-thumb {
  display: inline-block;
  position: relative;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  &::before {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    right: -0.8rem;
    z-index: 2;
    width: 3.6rem;
    height: 3.6rem;
    background: url('#{$img-cdn}/ico_pencil_169x170.png') 50% 50% / 100% auto no-repeat;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: inherit;
  }
}

.fixed {
  #lnb {
    .lnb-col {
      position: fixed;
      top: 10.9rem;
    }
  }
}

.scroll-bottom {
  .mypage-main,
  .mypage-sub {
    align-items: end;
    #lnb {
      .lnb-col {
        position: relative;
        top: auto;
      }
    }
  }
}

#lnb {
  position: relative;
  flex: 0 0 auto;
  width: 31.2rem;
  height: calc(100vh - 12rem);
  min-height: 64rem;

  .lnb-col {
    position: relative;
    width: 31.2rem;
    padding: 8rem 11rem 8rem 0;
    box-sizing: border-box;
  }

  .lnb-user-col {
    .nickname {
      display: block;
      margin-top: 1.3rem;
      font-weight: 700;
      font-size: 3rem;
    }

    p {
      margin-top: 0.9rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: #a0a0b6;
    }

    // .user-point {
    //   width: 20rem;
    //   margin-top: 2.1rem;
    //   padding: 0.9rem 1.7rem;
    //   background-color: #28284B;
    //   border-radius: 0.5rem;
    //   box-sizing: border-box;

    //   span {
    //     display: inline-block;
    //     font-weight: 900;
    //     font-size: 1.9rem;
    //     color: #fff;
    //     vertical-align: middle;

    //     &::before {
    //       content: '';
    //       display: inline-block;
    //       width: 1.8rem;
    //       height: 1.8rem;
    //       margin: -0.3rem 0.9rem 0 0;
    //       vertical-align: middle;
    //       background: url('#{$img-cdn}/ico_point_54x54.png') 50% 50% / 100% auto
    //         no-repeat;
    //       image-rendering: -webkit-optimize-contrast;
    //       image-rendering: -ms-optimize-contrast;
    //     }
    //   }
    // }

    .user-yta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2.5rem;
      padding: 1.3rem 1.2rem;
      background-color: #e4f0ff;
      border-radius: 0.5rem;

      &.normal {
        background-color: #ececf6;
        strong {
          color: #717188;
        }
      }
      strong {
        font-weight: 700;
        color: #008bff;
        font-size: 1.2rem;
      }

      button {
        font-weight: 700;
        color: #28284b;
        font-size: 1rem;
        text-decoration: underline;
        white-space: nowrap;
      }
    }
  }

  .lnb-menu-col {
    margin-top: 7.2rem;

    li {
      & + li {
        margin-top: 2.6rem;
      }
    }

    a,
    .btn-logout {
      display: inline-block;
      position: relative;
      font-weight: 700;
      font-size: 1.5rem;

      &.active {
        font-weight: 700;
        border-bottom: 1px solid #000;
      }
    }

    .btn-logout {
      width: 100%;
      text-align: left;
    }
  }

  .lnb-notice-col {
    margin-top: 9.4rem;

    span {
      display: block;
      font-weight: 400;
      font-size: 1.5rem;
      color: #28284b;
    }

    a {
      display: inline-block;
      margin-top: 0.8rem;
      font-weight: 700;
      font-size: 2.4rem;
      color: #28284b;
    }

    p {
      margin-top: 1.4rem;
      font-weight: 400;
      font-size: 1rem;
      color: #a0a0b6;
    }
  }

  .lnb-btn-group {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  #lnb {
    display: block;
    width: 100%;
    height: auto;
    background-color: #fff;

    .lnb-col {
      position: relative !important;
      width: 100%;
      padding: 0;
    }

    .lnb-user-col {
      position: relative;
      padding: 8.8rem 4.2rem 4.2rem 4.2rem;
      text-align: center;

      .user-thumb {
        width: 15.6rem;
        height: 15.6rem;

        &::before {
          bottom: -1.5rem;
          right: -1.5rem;
          width: 7.6rem;
          height: 7.6rem;
        }
      }

      .nickname {
        margin-top: 2.6rem;
        font-size: 3.8rem;
      }

      p {
        margin-top: 1.2rem;
        font-size: 2.3rem;
      }

      // .user-point {
      //   width: 100%;
      //   margin-top: 6.7rem;
      //   padding: 0;
      //   background-color: transparent;

      //   span {
      //     font-size: 3.3rem;
      //     color: #28284B;

      //     &::before {
      //       width: 4.5rem;
      //       height: 4.5rem;
      //       margin: -0.4rem 1.7rem 0 0;
      //     }
      //   }
      // }

      .user-yta {
        margin-top: 7rem;
        padding: 3rem 4rem;
        border-radius: 1rem;

        strong {
          font-size: 2.6rem;
        }

        button {
          font-size: 2.3rem;
        }
      }
    }

    .lnb-menu-col {
      margin-top: 0;
      border-bottom: 0.1rem solid #e5e5e5;

      li {
        &.active {
          a {
            border-bottom: none;
          }
        }

        &:first-child,
        &:nth-child(2) {
          border-top: 1.3rem solid #ececf6;
        }

        & + li {
          margin-top: 0;
          border-top: 0.1rem solid #e5e5e5;
        }

        a,
        .btn-logout {
          display: block;
          padding: 4.2rem 7rem 4.1rem 4.2rem;
          font-size: 2.7rem;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 3.1rem;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: -0.45rem;
            border: solid #a9a9a9;
            border-width: 0.3rem 0.3rem 0 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }

    .lnb-btn-group {
      display: block;
      padding: 6rem 4.2rem;

      .btn {
        display: block;
        height: 9rem;
        padding: 2.9rem 1rem 2.8rem;
        font-size: 2.6rem;
        border-radius: 0.5rem;

        & + .btn {
          margin-top: 1.3rem;
        }

        &.kakao {
          span {
            &::before {
              width: 3.85rem;
              height: 3.85rem;
              margin: -0.5rem 1.3rem 0 0;
              background-image: url('#{$img-cdn}/ico_consulting_115x115.png');
            }
          }
        }

        span {
          &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 2.8rem;
            height: 3.5rem;
            margin-right: 2.3rem;
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: 50% 50%;
            background-image: url('#{$img-cdn}/ico_phone_84x106w.png');
          }
        }
      }
    }

    .lnb-notice-col {
      display: none;
    }
  }

  .container {
    display: block;

    &.mypage-main {
      > section {
        display: none;
      }
    }

    &.mypage-sub {
      #lnb {
        display: none;
      }
    }

    > section {
      width: 100%;
      max-width: 100%;
    }
  }
}
