$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/';
.modalWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .dimmed {
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .ainadooFeedback {
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    z-index: 1;
    width: 100%;
    max-width: 750px;
    background-color: #fff;
    border-radius: 20px 20px 0 0;

    .titleBox {
      position: relative;
      border-bottom: 1px solid #dfdfea;

      h3 {
        padding: 16px 0;
        font-size: 14px;
        text-align: center;
      }

      .btnClose {
        position: absolute;
        top: 50%;
        right: 6px;
        width: 40px;
        height: 40px;
        font-size: 0;
        transform: translateY(-50%);
        background-image: url('#{$img-path}ico_close.svg');
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .feedbackBox {
      padding: 38px 24px 15px;
    }

    .btnSubmit {
      width: 100%;
      padding: 17px 0;
      font-size: 16px;
      color: #fff;
      background-color: #ffb900;

      &:disabled {
        cursor: default;
        background-color: #cfcfda;
      }
    }
  }
}
