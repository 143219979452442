/* cdn: https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ */
.titleBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;

  h1 {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding-top: 4px;
  }
  .btnBack {
    display: block;
    width: 40px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-indent: -1000px;
    position: absolute;
    top: 6px;
    left: 12px;
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/btn_back.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .btnInfo {
    position: absolute;
    top: 6px;
    right: 12px;
    width: 40px;
    height: 40px;
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/ico_notice_mark.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    white-space: nowrap;
    text-indent: -10000px;
  }

  @media screen and (min-width: 768px) {
    height: 75px;

    h1 {
      font-size: 21px;
      line-height: 26px;
      letter-spacing: -0.5px;
    }

    .btnBack {
      width: 32px;
      height: 32px;
      background-size: cover;
      top: 22px;
      left: 39px;
    }
  }
}
