.introVideo {
  padding: 16rem 0;
  background-color: #f7f7fc;

  dl {
    dt {
      font-weight: 400;
      font-size: 2.8rem;
      color: #28284b;
      text-align: center;

      strong {
        display: block;
        font-weight: 700;
        font-size: 4.8rem;
        color: #000;
        text-align: center;
      }
    }

    dd {
      padding-top: 7.2rem;

      .mediaBox {
        width: 88.6rem;
        margin: auto;

        iframe {
          width: 100%;
          height: 50rem;
        }
      }
    }
  }
}

.imgWrap {
  text-align: center;
}

.mallBanner {
  width: 100%;
  height: 42rem;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 50% 0;
}

.introTemplete {
  .inner {
    display: block;
    width: 100%;
    max-width: 120rem;
    margin: auto;

    img {
      display: block;
      width: 100%;
    }

    .mediaBox {
      display: block;
      width: 75rem;
      height: 52rem;
      margin: auto;
      background-color: #000;

      & > div {
        width: 100%;
        height: 100%;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  a {
    display: block;
    width: 70rem;
    margin: auto;
    padding: 3rem 0;
    font-weight: 500;
    font-size: 2.8rem;
    color: #1a1a1a;
    background-color: #ffb900;
    text-align: center;
  }
}

.introContentImg {
  img {
    display: block;
    max-width: 100%;
    margin: auto;
  }
}

.introBottomBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  background-color: #28284b;
  z-index: 10;

  p {
    font-weight: 700;
    font-size: 2.4rem;
    color: #fff;
    line-height: 1;

    & + a {
      margin-left: 5rem;
    }
  }

  a {
    display: inline-block;
    position: relative;
    padding: 1.8rem 4.8rem 1.6rem 2.3rem;
    font-weight: 700;
    font-size: 2rem;
    color: #28284b !important;
    background-color: #ffb900;
    border-radius: 12rem;

    &:after {
      content: '';
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin-left: 0.6rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: url('https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_right.png')
        no-repeat center / contain;
    }
  }

  .ddayBoxWrap {
    display: flex;
    align-items: center;

    .txt {
      color: #cfcfda;
    }

    .ddayBox {
      margin-left: 1.6rem;
      margin-right: 5rem;
      font-size: 2.8rem;
      line-height: 3.6rem;
      font-weight: bold;
      color: #fff;

      .dash {
        display: inline-block;
        width: 23px;
        text-align: center;
      }

      span:not(.dash) {
        position: relative;
        display: inline-block;
        width: 3.6rem;
        height: 4.8rem;
        margin: 0 1px;
        border-radius: 0.4rem;
        text-align: center;
        line-height: 4.6rem;
        border: 2px solid #22223f;
        background-color: #484873;
        box-sizing: border-box;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-top: -1px;
        }

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: #22223f;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .introVideo {
    dl {
      width: calc(100% - 6.4rem);
      max-width: calc(150rem - 6.4rem);
      margin: auto;
      box-sizing: border-box;

      dt {
        font-size: 2.8rem;
        text-align: left;

        strong {
          padding-top: 1rem;
          font-size: 4.8rem;
          text-align: left;
          word-break: keep-all;
        }
      }

      dd {
        .mediaBox {
          width: 100%;

          iframe {
            height: 53vw;
            max-height: 81rem;
          }
        }
      }
    }
  }

  .introBottomBar {
    height: 13.8rem;
    justify-content: space-between;

    p {
      flex: 1 auto;
      padding-left: 4.2rem;
      font-size: 2.8rem;
      text-align: left;
      box-sizing: border-box;

      strong {
        display: block;
        padding: 0.4rem 0;
        text-align: left;
      }

      & + a {
        margin-left: 0;
      }
    }

    a {
      margin-right: 4.2rem;
      padding: 2rem 7rem 1.8rem 4.1rem;
      font-size: 2.8rem;

      &:after {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .ddayBoxWrap {
      .txt {
        width: 10rem;
        line-height: 3.4rem;
        flex-shrink: 0;
      }

      .ddayBox {
        margin-left: 2.4rem;
        flex-shrink: 0;

        span:not(.dash) {
          width: 4.8rem;
          height: 6.4rem;
          font-size: 3.6rem;
          line-height: 6.2rem;
        }

        .dash {
          width: 3.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .introBottomBar {
    height: 18.4vw;

    p {
      padding: 0 0 0 5.6vw;
      font-size: 3.73vw;
    }

    a {
      margin-right: 5.6vw;
      padding: 2.67vw 8.33vw 2.53vw 5.47vw;
      font-size: 3.73vw;

      &:after {
        width: 3.2vw;
        height: 3.2vw;
      }
    }

    .ddayBoxWrap {
      .txt {
        width: 13.33vw;
        line-height: 4.53vw;
      }

      .ddayBox {
        margin-left: 3.2vw;
        margin-right: 0;

        span:not(.dash) {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 6.4vw;
          height: 8.53vw;
          font-size: 4.8vw;
          line-height: 1;
        }

        .dash {
          width: 4.13vw;
        }
      }
    }
  }
}
