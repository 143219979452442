.detailBoardBox {
  padding: 8rem 0 10rem;

  h3 {
    padding-bottom: 1.3rem;
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 2rem;
    border-bottom: 3px solid #000;
  }

  :global(.pagination) {
    padding: 0 0 3rem;
  }

  .imgViewBox > div {
    height: 83.8rem;
  }

  @media (max-width: 1024px) {
    padding-top: 9.4rem;

    h3 {
      display: none;
    }

    :global(.pagination) {
      padding: 5rem 0 7rem;
    }

    .imgViewBox > div {
      margin-bottom: 0;
    }
  }
}

.titleBox {
  padding: 0 1.2rem 3rem;
  border-bottom: 1px solid #e7e7f0;

  > p {
    display: block;
    position: relative;
    padding-right: 5rem;
    font-weight: 700;
    font-size: 1.6rem;
    color: #a0a0b6;

    strong {
      position: absolute;
      top: 0;
      right: 0;
      color: #17c624;
    }

    + p {
      margin-top: 1rem;
      font-size: 2rem;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }

  @media (max-width: 1024px) {
    padding: 0 4.2rem 5rem;

    > p {
      font-size: 2.5rem;
      padding-right: 0;

      + p {
        margin-top: 2rem;
        font-size: 4rem;
      }
    }

    > div {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 3.8rem;
    }
  }
}

.profileBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  strong {
    margin-left: 1.3rem;
    font-weight: 400;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    margin-top: 3.4rem;

    strong {
      margin-left: 1.8rem;
      font-size: 2.4rem;
    }
  }
}

.infoBox {
  font-weight: 400;
  font-size: 1.2rem;
  color: #b7b7c8;

  i {
    display: inline-block;
    margin: 0 0.5rem;
  }

  @media (max-width: 1024px) {
    span {
      font-size: 2.3rem;
    }

    i {
      margin: 0 1.4rem;
    }
  }
}

.contentBox {
  padding: 3rem 2.5rem;

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  .commentCountItem {
    margin-top: 4.6rem;
    font-size: 2rem;
    font-weight: 500;
    line-height: normal;
  }

  @media (max-width: 1024px) {
    padding: 5rem 4.2rem;

    p {
      font-size: 2.6rem;
    }

    .commentCountItem {
      margin-top: 5.1rem;
      font-size: 3.2rem;
    }
  }
}

.commentListBox {
  margin-top: 1rem;
  background-color: #f7f7fc;

  @media (max-width: 1024px) {
    padding: 0 4.2rem;
  }
}

.smInnerBox {
  position: relative;
  max-width: 948px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 0;
  }
}
