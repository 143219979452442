.modalAiTravel {
  * {
    box-sizing: border-box;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);

  &.complete {
    background-color: transparent;
    img {
      width: 212px;
    }
  }

  .modalContent {
    display: flex;
    width: 310px;
    padding: 48px 24px 32px 24px;
    flex-direction: column;
    gap: 10px;
    border-radius: 12px;
    background: #fff;

    h3 {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.5px;
    }

    p {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #717188;
    }

    .btnGroup {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
      margin-top: 32px;

      button {
        display: grid;
        place-items: center;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #dfdfea;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        &.btnConfirm {
          background-color: #ffb900;
          border-color: #ffb900;
        }
      }
    }
    .btnBox {
      margin-top: 32px;

      button {
        display: grid;
        place-items: center;
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #dfdfea;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        &.btnConfirm {
          background-color: #ffb900;
          border-color: #ffb900;
        }
      }
    }
  }

  &.feedbackLoading {
    background-color: transparent;
    .loadingContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 212px;
      height: 212px;
      overflow: hidden;
      border-radius: 500px;
      background: rgba(40, 40, 75, 0.8);

      .loadingImg {
        width: 180px;
        height: 108px;
        margin-top: -13px;
      }

      .loadingTxt {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: -8px;
        dt {
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.5px;
        }
        dd {
          color: #fff;
          font-size: 10px;
          font-weight: 300;
          line-height: 14px;
        }
      }
    }
  }
}
