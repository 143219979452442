@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

/* review-list-col */
.review-list-col {
  padding-top: 10rem;

  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.5rem;
    font-weight: 700;
    padding: 0 0 1.8rem;
    border-bottom: 0.3rem solid #000;

    .button {
      @include button(10.5rem, 4rem, $white, $gray-900, 1.6rem, 0.3rem);
    }
  }

  .review-box {
    div {
      display: block;
      margin: 0;
      padding: 0 2rem;
      margin-bottom: 3rem;
      max-height: 12.5rem;
      overflow: hidden;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.5;
      color: #3b3b4b;

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
        text-overflow: ellipsis;
        word-break: keep-all;
      }

      &.active {
        height: auto;
        max-height: 100%;

        p {
          -webkit-line-clamp: initial;
        }
      }
    }
  }

  .answer-box {
    padding: 4.5rem 0 4rem;
    background-color: #f7f7fc;
  }

  button {
    margin: 0;
    padding: 0 0;
    font-weight: 700;
    font-size: 1.6rem;
    background-color: transparent;
    border-radius: 0.5rem;
  }
}
.review-list-col .point-box {
  display: flex;
  width: 100%;
  padding: 1.9rem 2.5rem;
  box-sizing: border-box;
  border-bottom: 1px solid #e7e6f0;
  align-items: center;
}
.review-list-col .point-box strong {
  padding-top: 0.5rem;
  padding-right: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  font-size: 1.8rem;
}
.review-list-col .point-box strong em {
  font-style: normal;
  font-weight: 700;
}
.review-list ul {
  margin-bottom: 3rem;
}
.review-list-col .review-list li {
  padding: 3rem 0 0;
  border-bottom: 1px solid #e7e6f0;
  box-sizing: border-box;
}
.review-list-col .review-list dt {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 2rem;
  overflow: hidden;
}
.review-list-col .review-list dt strong {
  display: flex;
  padding-left: 1.5rem;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.4;
  color: #000;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  letter-spacing: -0.2px;
}
.review-list-col .review-list dt strong span {
  font-weight: 400;
  font-size: 1.3rem;
  color: #a0a0b6;
}
.review-list-col .profile-img {
  display: inline-block;
  position: relative;
  width: 5.4rem;
  height: 5.4rem;
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 100%;
  text-indent: -9999px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1) inset;
}
.review-list-col .profile-img.self:before {
  content: '나';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 3.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  text-align: center;
  text-indent: 0;
}
.review-list-col .review-info {
  max-width: 30rem;
  line-height: 1.4;
  text-align: right;

  .reply-state {
    display: inline-block;
    margin-right: 0.9rem;
    color: #a0a0b6;
    font-weight: 700;
    font-size: 1.4rem;
    vertical-align: bottom;

    &.active {
      color: #17c624;
    }
  }
}
.review-list-col .review-info p {
  margin-top: 0.4rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: #717188;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.review-list-col .review-list dd {
  padding-top: 1.5rem;

  dt {
    padding: 0;
  }
}
.review-list-col .review-list dd > p {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #28284b;
}
.review-list-col .review-list dd dl {
  position: relative;
  margin-top: 3.5rem;
  padding: 3rem 2.5rem 3rem 5.9rem;
}
.review-list-col .review-list dd dl:before {
  content: '';
  position: absolute;
  top: 3rem;
  left: 3rem;
  width: 3rem;
  height: 3rem;
  background: url('#{$img-cdn}/ico_reply_90x90.png') no-repeat 0 0.5rem;
  background-size: 100%;
}
.review-list-col .btn-more {
  width: 100%;
  padding: 2rem;
  background-color: #f7f7fc;
  border-radius: 0.4rem;
  outline: 0;
}
.review-list-col .star-point {
  display: inline-block;
  position: relative;
  width: 12.5rem;
  height: 2.5rem;
  background-color: #e7e7f0;
  overflow: hidden;
}
.review-list-col .star-point:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('#{$img-cdn}/bg_star_100x100.png') repeat-x 0 0;
  background-size: 20%;
  z-index: 1;
}
.review-list-col .star-point span {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  background-color: #ffb900;
  text-indent: -9999px;
}
.review-list-col .review-info .star-point {
  display: inline-block;
  width: 9rem;
  height: 1.8rem;
}
.review-list-col h1 button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 4rem;
  margin: auto;
  padding: 1.1rem 2.3rem;
  font-weight: 700;
  font-size: 1.6rem;
  color: #fff;
  background-color: #28284b;
  border-radius: 0.3rem;
}
.review-list-col textarea {
  width: 100%;
  height: 15rem;
  padding: 2rem;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.4;
  box-sizing: border-box;
  border: 1px solid #e7e7f0;
  border-radius: 0;
  outline: 0;
  resize: none;
}
.review-list-col .review-info button {
  position: relative;
  padding: 1rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: #000;
}
.review-list-col .review-info button + button:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.1rem;
  height: 1rem;
  margin: auto;
  border-left: 0.1rem solid #dfdfea;
}
.review-list-col .review-info .btn-delete {
  color: #ff5647;
}
.review-list-col dd dl .profile-img {
  background-color: #fff;
  background-image: url(https://english.yanadoocdn.com/images/cmmn/yanadoo_brand_logo_transparent.png);
}

@media screen and (max-width: 1024px) {
  .review-list-col {
    padding: 10rem 4rem 0;

    h3 {
      font-size: 3.2rem;
      font-weight: 700;
      padding: 0 0 3rem;

      .button {
        @include button(16.2rem, 6.2rem, $white, $gray-900, 2.5rem, 0.5rem);
      }
    }

    .review-box {
      div {
        display: block;
        max-height: 32rem;
        margin-bottom: 4rem;
        padding: 0;
        font-size: 2.6rem;

        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          white-space: normal;
          text-overflow: ellipsis;
          word-break: keep-all;
        }

        &.active {
          height: auto;
          max-height: 100%;

          p {
            -webkit-line-clamp: initial;
          }
        }
      }

      button {
        margin-left: 0;
        font-size: 2.6rem;
      }
    }

    .answer-box {
      padding: 4.5rem 4.9rem 4rem 9.1rem !important;
    }

    button {
      padding: 0.5rem 1rem;
      font-size: 2.2rem;
      border-radius: 0.5rem;

      & + button {
        margin-left: 0.5rem;
      }
    }
  }

  .review-list-col .point-box {
    padding: 3rem 0;
  }
  .review-list-col .point-box strong {
    padding-top: 0;
    padding-right: 1rem;
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 1.3;
  }
  .review-list-col .point-box strong em {
    font-weight: 700;
  }
  .review-list-col .star-point {
    width: 12.5rem;
    height: 2.5rem;
  }
  .review-list-col .btn-more {
    padding: 2.5rem;
    border-radius: 0.5rem;
  }
  .review-list-col .review-list dd > p {
    font-size: 2.2rem;
    line-height: 1.7;
  }
  .review-list-col .review-list dt {
    padding: 0;

    strong {
      padding-left: 2.2rem;
      font-size: 2.6rem;
    }
  }
  .review-list-col .profile-img {
    width: 8.2rem;
    height: 8.2rem;
  }
  .review-list-col .profile-img.self:before {
    font-size: 2.6rem;
  }
  .review-list-col .review-list dt strong span {
    margin-top: 0.3rem;
    font-size: 2rem;
  }
  .review-list-col .review-list dd dl {
    margin-top: 4rem;
    padding-left: 5rem;
  }
  .review-list-col .review-list dd dl:before {
    top: 5.2rem;
    left: 4.2rem;
    width: 4rem;
    height: 4rem;
  }
  .review-list-col .review-info {
    max-width: 25rem;

    .reply-state {
      font-size: 2rem;
    }

    &.star {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      text-align: left;
    }

    .star-point {
      flex: 0 0 auto;
      width: 12.5rem;
      height: 2.5rem;
      margin-right: 1.6rem;
    }
  }
  .review-list-col .review-info p {
    font-size: 2rem;
  }
  .review-list-col .review-list li {
    padding: 4rem 0 0;
  }
  .review-list-col .review-list dd {
    padding-top: 2.4rem;
  }
  .review-list-col textarea {
    height: 26.2rem;
    margin-bottom: 4rem;
    padding: 2rem 2.5rem;
    font-size: 2.2rem;
  }
  .review-list-col .review-info button {
    padding: 0 2rem;
    font-weight: 700;
    font-size: 2rem;
  }
  .review-list-col .review-info button + button {
    padding-right: 0;
  }
  .review-list-col .review-info button + button:before {
    height: 1.4rem;
  }
}
