.container {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
  padding: 72px 150px 72px;
  box-sizing: border-box;
  border-radius: 40px 40px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.25);
  transform: translateY(100%);
  animation: slideUp 0.7s forwards;
  &.slideDown {
    animation: slideDown 0.7s forwards;
  }
  &.slideUp {
    animation: slideUp 0.7s forwards;
  }
}

.description {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  white-space: pre-line;
  min-height: 52px;
  margin-bottom: 52px;
}
.questionIndex {
  position: fixed;
  top: 34px;
  left: 50%;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background: rgba(22, 28, 34, 0.8);
  padding: 26px 34px;
  color: #fff;
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  transform: translateX(-50%) translateY(-150%);
  z-index: 22;
  &.show {
    animation: show 0.7s forwards;
  }
  &.hide {
    animation: hide 0.7s forwards;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}
.button {
  width: 130px;
  height: 130px;
  background: #b7b7c8;
  border-radius: 50%;
  margin: 0 auto;
  &.prepareEnd {
    background: #ff5647;
  }
  &.recording {
    background: #3396ff;
  }
  img {
    width: 55px;
    height: 55px;
  }
}
.cancelButton {
  color: var(--GrayScale-600---8D8DA0, #8d8da0);
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -1.12px;
  margin-right: 16px;
}
.reButton {
  color: #3396ff;
  text-align: right;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -1.12px;
}

@media screen and (max-width: 750px) {
  .container {
    padding: 9.6vw 20vw 9.6vw;
    border-radius: 5.333vw 5.333vw 0vw 0vw;
    box-shadow: 0vw 0.533vw 3.6vw 0vw rgba(0, 0, 0, 0.25);
  }
  .questionIndex {
    top: 4.533vw;
    border-radius: 2.667vw;
    padding: 3.467vw 4.533vw;
    font-size: 4.8vw;
    line-height: 5.867vw;
    letter-spacing: -0.067vw;
  }
  .description {
    font-size: 5.333vw;
    line-height: 6.933vw;
    min-height: 6.933vw;
    margin-bottom: 6.933vw;
  }
  .button {
    width: 17.333vw;
    height: 17.333vw;
    position: relative;
    img {
      width: 7.333vw;
      height: 7.333vw;
    }
    &.recording {
      &:after {
        content: '';
        width: 23.467vw;
        height: 23.467vw;
        background: rgba(38, 156, 255, 0.1);
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-radius: 50%;
        animation: blink 2s infinite;
      }
    }
  }
  .cancelButton {
    font-size: 3.733vw;
    line-height: 5.6vw;
    letter-spacing: -0.149vw;
    margin-right: 2.133vw;
  }
  .reButton {
    font-size: 3.733vw;
    line-height: 5.6vw;
    letter-spacing: -0.149vw;
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes show {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-150%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
@keyframes hide {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-150%);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
}
