@charset "UTF-8";
@import './variable.scss';

.coupon-col {
  background-color: #f7f7fc;

  &.voucher {
    background-color: #fff;

    .btn-flex-form {
      display: none;
      background-color: #f7f7fc;

      .btn {
        flex: 1 1 50% !important;
      }
    }

    .tab-content {
      .toggle-col {
        .btn {
          width: 11.3rem;
          padding: 1.2rem 1rem 1rem;
          font-size: 1.4rem;
        }
      }

      .table-form {
        .btn {
          width: auto;
          padding: 0.9rem 1.8rem;
          font-size: 1.4rem;
          white-space: nowrap;
          border-color: #cfcfda;
        }
      }
    }
  }

  .inner-col {
    background-color: inherit;
  }

  .coupon-lists-col {
    padding: 8rem 0 12rem;

    .coupon-lists {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      padding: 0;

      li {
        flex: 0 1 46%;
        margin-bottom: 2rem;
      }
    }

    .no-content {
      padding: 13.7rem 0;
    }
  }

  .toggle-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;

    strong {
      font-weight: 700;
      font-size: 1.6rem;
      color: #717188;
    }

    .coupon-toggle-menu {
      overflow: hidden;
      position: relative;
      background-color: #fff;
      border-radius: 11.5rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        bottom: 0;
        left: 0;
        border-radius: 10rem;
        background-color: #ffb900;
        -webkit-transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s 0.2s ease,
          0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s 0.2s ease,
          0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-transition-property: left, right;
        transition-property: left, right;
        transition-property: left, right;
        transition-property: left, right;
      }

      &.active {
        &::before {
          left: 50%;
          right: 0;
          -webkit-transition-property: right, left;
          transition-property: right, left;
          transition-property: right, left;
          transition-property: right, left;
        }
      }

      .toggle-item {
        display: inline-block;
        width: 8.6rem;
        padding: 1rem 0 0.8rem;
        font-weight: 400;
        font-size: 1.4rem;
        color: #a0a0b6;
        white-space: nowrap;
        text-align: center;
        transition: all 0.3s;

        span {
          position: relative;
          z-index: 2;
        }

        &.active {
          font-weight: 400;
          color: #000;
        }
      }
    }
  }

  .tab-content,
  .coupon-tab-content {
    display: none;

    &.active {
      display: block;
    }
  }

  .table-form-mobile {
    padding: 0 4.2rem;

    .table-lists {
      border-top: 0.3rem solid #000;
      border-bottom: none;

      li {
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 4.8rem;

          .date {
            font-size: 2.2rem;
            color: #717188;

            &::before {
              content: '등록';
              display: inline-block;
              margin: -0.25rem 0.7rem 0 0;
              vertical-align: middle;
              font-size: 2.2rem;
              color: #717188;
            }
          }

          .btn {
            width: 15.4rem;
            padding: 1.3rem 0;
            font-weight: 400;
            font-size: 2.2rem;
            border-radius: 0.5rem;
            border-color: #cfcfda;
            span {
              color: #000;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.tab-menu-col {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;

  li {
    & + li {
      margin-left: 4.8rem;
    }

    .tab-menu {
      display: block;
      padding: 0.2rem 0;
      font-size: 2rem;
      color: #a0a0b6;
    }

    &.active {
      .tab-menu {
        font-weight: 700;
        color: #000;
        border-bottom: 0.3rem solid #ffb900;
      }
    }
  }
}

.btn-flex-form {
  &.btn-coupon {
    display: block;
    padding: 3.3rem 0;

    .btn {
      padding: 1.1rem 5.5rem 1.1rem;
      font-size: 1.4rem;
      border-radius: 0;
      border: 1px solid #cfcfda;

      span {
        font-weight: 400;
      }

      & + .btn {
        margin-left: 1rem;
        padding: 1.1rem 3.5rem 1.1rem;
        border-color: transparent;
      }
    }
  }
}

.coupon-notice-col {
  margin-top: 7rem;

  strong {
    display: block;
    font-weight: 700;
    font-size: 1.8rem;
    color: #717188;
  }

  .coupon-notice-lists {
    margin-top: 1.2rem;

    li {
      position: relative;
      padding-left: 1rem;
      font-weight: 400;
      font-size: 1.6rem;
      color: #717188;
      line-height: 1.5;

      span {
        font-weight: 500;
      }

      & + li {
        margin-top: 0.2rem;
      }

      &::before {
        content: '-';
        position: absolute;
        top: 5%;
        left: 0;
        color: #717188;
      }

      &.noti {
        &::before {
          content: '*';
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal-content {
    &.coupon {
      height: 100%;
      background-color: #f1f1f9;
    }
  }

  .tab-menu-col {
    padding: 0 4.2rem;
    border-bottom: 0.1rem solid #dfdfea;
    background-color: #fff;

    li {
      & + li {
        margin-left: 5.5rem;
      }

      .tab-menu {
        padding: 4rem 0;
        font-size: 2.8rem;
      }

      &.active {
        .tab-menu {
          border-bottom: 0.4rem solid #ffb900;
        }
      }
    }
  }

  .coupon-col {
    background-color: #f1f1f9;
    &.voucher {
      .btn-flex-form {
        display: flex;
      }

      .toggle-col {
        .btn {
          display: none;
        }
      }
    }

    .toggle-col {
      margin-top: 2.8rem;
      padding: 2.2rem 4.2rem;

      strong {
        font-size: 2.5rem;
      }

      .coupon-toggle-menu {
        border-radius: 10rem;

        .toggle-item {
          width: 13.7rem;
          padding: 1.65rem 0 1.6rem;
          font-size: 2.5rem;
        }
      }
    }

    .coupon-lists-col {
      padding: 0 0 6rem 0;

      .coupon-lists {
        display: block;
        margin-top: 0;
        padding: 0 3rem;

        li {
          & + li {
            margin-top: 2.5rem;
          }
        }
      }
    }
  }

  .btn-flex-form {
    &.btn-coupon {
      display: flex;
      padding: 4rem;
      margin-top: 0;
      background-color: #fff;

      .btn {
        padding: 2.2rem 1rem 1.9rem;
        font-size: 2.7rem;

        &.active,
        &.positive {
          flex: 0 0 auto;
          width: 21rem;
          padding: 2.2rem 1rem 1.9rem;
          border-color: transparent;
        }
      }
    }
  }

  .coupon-notice-col {
    padding: 0 4.2rem;

    strong {
      font-size: 2.7rem;
    }

    .coupon-notice-lists {
      margin-top: 3rem;

      li {
        padding-left: 2rem;
        font-size: 2.5rem;
        line-height: 1.6;

        & + li {
          margin-top: 1rem;
        }
      }
    }
  }
}
