.sectionTitle {
  h3 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin-bottom: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .sectionTitle {
    h3 {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.5px;
      text-align: center;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 750px) {
  .sectionTitle {
    h3 {
      font-size: 5.333vw;
      line-height: 6.4vw;
      letter-spacing: -0.067vw;
      margin-bottom: 5.333vw;
    }
  }
}
