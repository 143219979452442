// .srrTip {
//   position: fixed;
//   top: 52px;
//   left: 0;
//   right: 0;
//   z-index: 2;
//   background-color: #e7e7f0;
// }

$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/';

.srrTip {
  position: fixed;
  top: 52px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #f7f7fc;
  border-top: 1px solid #e7e7f0;
  border-bottom: 1px solid #e7e7f0;
  box-sizing: border-box;

  .tipHeader {
    display: flex;
    height: 52px;
    align-items: center;
    position: relative;
    h4 {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.5px;
      padding-left: 24px;
      display: flex;
      align-items: center;
      gap: 4px;
      .tipImg {
        width: 24px;
      }
    }
    .btnArrow {
      display: block;
      width: 24px;
      height: 24px;
      white-space: nowrap;
      text-indent: -1000px;
      position: absolute;
      top: 50%;
      right: 16px;
      overflow: hidden;
      margin-top: -12px;
      background-image: url('#{$img-path}btn_toggle.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      transform: rotate(0deg);
      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .tipListWrap {
    padding: 0 24px 24px;
    display: none;
    .tipList {
      padding-top: 24px;
      padding-left: 20px;
      border-top: 1px solid #e7e7f0;
      li {
        list-style: disc;
        color: #717188;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.5px;
      }
    }

    &.open {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    top: 75px;
    .tipHeader {
      height: 72px;
      h4 {
        font-size: 21px;
        line-height: 26px;
        padding-left: 36px;
        gap: 8px;
        .tipImg {
          width: 32px;
        }
      }
      .btnArrow {
        background-size: 24px;
        right: 36px;
      }
    }

    .tipListWrap {
      padding: 0 36px 30px;
      .tipList {
        padding-top: 30px;
        li {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}
