.speakerBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.audioBtn,
.icoSpeaker,
.soundLottie {
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.soundLottie > div {
  width: 100%;
  height: 100%;
}
.speakerBox svg {
  width: 33px !important;
  transform: translate(-4px, -1px) !important;
}

@media screen and (max-width: 1024px) {
  .audioBtn,
  .icoSpeaker,
  .soundLottie {
    width: 30px;
    height: 30px;
  }
  .speakerBox svg {
    width: 41px !important;
    transform: translate(-5px, -1px) !important;
  }
}
@media screen and (max-width: 750px) {
  .audioBtn,
  .icoSpeaker,
  .soundLottie {
    width: 22px;
    height: 22px;
  }
  .speakerBox svg {
    width: 29px !important;
    transform: translate(-3px, -1px) !important;
  }
}
