.container {
  background-color: #fff;
  max-width: 550px;
}

.form {
  position: relative;
  background-color: #fff;
  overflow: auto;
}

.list {
  gap: 20px;
  padding: 35px 30px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: -0.04em;
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  p {
    margin-left: 3px;
    strong {
      color: #0066ff;
      font-weight: 500;
    }
  }
}

.choice {
  display: flex;
  gap: 0 16px;
  width: 400px;
  flex-wrap: wrap;
}

.label {
  display: block;
  font-size: 18px;
  line-height: 38.48px;
  letter-spacing: -0.04em;
  text-align: left;
  cursor: pointer;

  input[type='radio'] {
    width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  input[type='radio'] + em {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
    cursor: pointer;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png)
      no-repeat 42% 50% / 15px auto;
    border-radius: 50%;
    border: 1px solid #cfcfd8;
  }
  input[type='radio']:checked + em {
    background-color: #0066ff;
    border-color: #0066ff;
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/macbookPackage/2024/jun/ico_check_38x30b.png);
  }
}

.submitButton {
  color: #fff;
  background-color: #0066ff;
  display: block;
  font-size: 26px;
  font-weight: 700;
  height: 78px;
  letter-spacing: -1px;
  outline: 0;
  text-align: center;
  width: 100%;
  z-index: 2;
}

@media all and (max-width: 750px) {
  .container {
    max-width: 73.333vw;
  }

  .list {
    gap: 2.667vw;
    padding: 4.667vw 4vw;
  }

  .title {
    font-size: 2.667vw;
    letter-spacing: -0.04em;
    margin-bottom: 0.667vw;
    p {
      margin-left: 0.4vw;
    }
  }

  .choice {
    gap: 0 2.133vw;
    width: 53.333vw;
  }

  .label {
    font-size: 2.4vw;
    line-height: 5.131vw;
    letter-spacing: -0.04em;
    input[type='radio'] + em {
      width: 3.2vw;
      height: 3.2vw;
      margin-right: 0.667vw;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png)
        no-repeat 42% 50% / 2vw auto;
    }
  }

  .submitButton {
    font-size: 3.467vw;
    height: 10.4vw;
    letter-spacing: -0.133vw;
  }
}
