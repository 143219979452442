.modal {
  width: 480px;
  background-color: #fff;
  border-radius: 12px;

  hgroup {
    padding-block: 64px;
    text-align: center;
    h3 {
      color: #000;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.28;
      letter-spacing: -0.5px;
    }
    p {
      color: #717188;
      font-size: 24px;
      line-height: 1.25;
      letter-spacing: -0.5px;
      margin-top: 16px;
    }
  }

  .btns {
    display: flex;
    > button {
      flex: 1;
      border-top: 1px solid #dfdfea;
      height: 89px;
      text-align: center;
      font-size: 20px;
      letter-spacing: -0.5px;

      &:first-child {
        border-right: 1px solid #dfdfea;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .modal {
    width: 65rem;
    border-radius: 2.4rem;

    hgroup {
      padding-block: 10.4rem;
      h3 {
        font-size: 3.8rem;
      }
      p {
        font-size: 3.2rem;
      }
    }

    .btns {
      > button {
        height: 12.1rem;
        font-size: 2.8rem;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .modal {
    width: 80vw;
    border-radius: 3.2vw;

    hgroup {
      padding-block: 13.867vw;
      h3 {
        font-size: 5.067vw;
      }
      p {
        font-size: 4.267vw;
      }
    }

    .btns {
      > button {
        height: 16.133vw;
        font-size: 3.733vw;
      }
    }
  }
}
