@import './variable.scss';

.class-list-area {
  padding: 3.5rem 2.8rem;
  background-color: $gray-100;

  button {
    font-weight: 400;
  }

  ul {
    li {
      position: relative;
      padding: 0 2.8rem 0 3.2rem;
      background-color: $white;
      border-radius: 0.7rem;
      box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.07);

      &.proceed {
        button {
          background-color: $warning;
          border: 1px solid $warning;
        }
      }

      &.refund {
        button {
          background-color: $danger;
          color: $white;
          border: 1px solid $danger;
        }
      }

      &.before-proceed {
        button {
          background-color: $gray-500;
          color: $white;
          border: 1px solid $gray-500;
          cursor: default;
        }
      }

      + li {
        margin-top: 1.2rem;
      }
    }
  }

  .class-info-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 13.3rem;
    padding: 2.5rem 0;
    box-sizing: border-box;

    dt {
      flex: 1;
      padding-right: 3rem;

      p {
        max-height: 6rem;
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.4;
        overflow: hidden;
      }

      span {
        display: inline-block;
        margin-top: 0.8rem;
        font-weight: 400;
        font-size: 1.2rem;
        color: $gray-700;

        &.class-status {
          margin-top: 0;
          margin-bottom: 1.5rem;
          font-weight: 700;
        }
      }

      .package-name {
        margin-bottom: 1.2rem;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }

    dd {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      flex: 1 1 20%;
      text-align: center;

      p {
        flex: 0 1 auto;
        font-weight: 700;
        font-size: 1.8rem;

        &::before {
          display: block;
          margin-bottom: 1.5rem;
          font-weight: 400;
          font-size: 1.2rem;
          color: $dark;
        }

        &.dday {
          &::before {
            content: '남은 수강일';
          }

          span {
            &::before {
              content: 'D-';
              display: inline-block;
            }
          }
        }

        &.attend {
          &::before {
            content: '출석일';
          }
        }

        &.progress {
          color: $danger;

          &::before {
            content: '달성율';
          }

          span {
            &::after {
              content: '%';
              display: inline-block;
            }
          }
        }
      }

      em {
        position: absolute;
        bottom: 2.5rem;
        right: 4.1rem;
        font-size: 1rem;
      }
    }
  }

  .end-list {
    .class-info-box {
      dt {
        span {
          &.class-status {
            color: $success;
          }
        }
      }

      dd {
        p {
          color: $gray-700;
        }
      }
    }
  }

  .btn-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
      flex: 0 1 auto;
      width: 12rem;
      padding: 1rem 0;
      font-size: 1.4rem;
      border-radius: 0.3rem;
      background-color: $white;
      border: 1px solid $gray-400;

      + button {
        margin-top: 1rem;
      }

      &.btn-review {
        color: $white;
        background-color: $gray-900;
        border-color: $gray-900;
      }

      &.disabled {
        background-color: $gray-500;
        border-color: $gray-500;
      }
    }
  }

  .none {
    padding: 10rem 0;
    font-size: 1.6rem;
    color: $gray-700;
    text-align: center;
    border: 1px dashed $gray-500;
    box-sizing: border-box;
  }

  .myclass-noti-box {
    background-color: rgba(38, 156, 255, 0.1);
    border-radius: 0.4rem;
    padding-block: 1rem;
    flex: 1;
    max-width: 30.9rem;
    margin-left: 6.4rem;

    .myclass-noti-text {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.6rem;
      color: #269cff;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 142%;
    }
  }
}

.history {
  .class-list-area {
    padding: 0;
  }
}

@media screen and (max-width: 1280px) {
  .class-list-area {
    .myclass-noti-box {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .class-list-area {
    display: block;
    padding: 5rem 3.1rem 7rem;
    background-color: #f1f1f9;

    ul {
      li {
        padding: 0;
        border-radius: 1.4rem;
        box-shadow: 0 0 1.7rem rgba(0, 0, 0, 0.07);

        a {
          display: block;
          min-height: 34.5rem;
          padding: 0;
          &.is-srr-only {
            min-height: auto;
          }
        }
      }
    }

    .class-info-box {
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      height: 100%;
      padding: 3.8rem 4.3rem 6.1rem;

      dt {
        width: 100%;
        min-height: 8.5rem;
        padding: 0 2rem;
        box-sizing: border-box;

        p {
          font-size: 3.1rem;
        }

        span {
          margin-top: 1.5rem;
          font-size: 2.2rem;
        }

        .package-name {
          display: block;
          margin-top: 0;
          margin-bottom: 1.8rem;
          font-size: 2.5rem;
        }
      }

      dd {
        flex-wrap: wrap;
        width: 100%;
        margin-top: 3.3rem;
        padding: 6rem 2rem 0;
        border-top: 1px solid $gray-300;
        box-sizing: border-box;

        p {
          flex: 0 0 auto;
          font-size: 3.3rem;

          &::before {
            margin-bottom: 1.8rem;
            font-size: 2.2rem;
          }
        }
      }

      &.is-srr-only {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        dt {
          flex: 1;
          width: auto;
          box-sizing: border-box;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        dd {
          padding: 0;
          border: none;
          margin-left: auto;
          margin-top: 0;
          width: auto;
          flex: 0 0 auto;
          padding-right: 1.9rem;
          p {
            padding: 0;
          }
        }
      }
    }

    .btn-form {
      width: 100%;
    }
  }

  .history {
    .class-list-area {
      ul {
        li {
          a {
            height: 42.1rem;
            padding: 0;
          }
        }
      }

      .class-info-box {
        padding: 0;

        dt {
          position: relative;
          padding: 4.1rem 4.2rem 3.5rem;

          p {
            font-size: 3.3rem;
          }

          span {
            font-size: 2.5rem;

            &.date {
              position: absolute;
              right: 4.2rem;
              bottom: 0;
            }
          }

          em {
            &::before {
              display: none;
            }
          }
        }

        dd {
          flex: 0 1 auto;
          align-items: initial;
          padding: 0;

          p {
            display: none;
          }
        }
      }

      .btn-form {
        flex-direction: row;

        button {
          width: 50%;
          padding: 3.1rem 0;
          font-weight: 400;
          font-size: 2.5rem;
          color: $dark;
          background-color: transparent;
          border: none;
          border-radius: 0;

          + button {
            margin-top: 0;
            border-left: 1px solid $gray-300;
          }

          &.disabled {
            color: #cfcfda;
          }
        }

        &.only {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
