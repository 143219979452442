.row {
  display: grid;
  grid-template-columns: 135px 1fr;
}
.selectRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.label {
  font-size: 20px;
  letter-spacing: -0.3px;
  flex: none;
  text-align: left;
  display: flex;
  align-items: center;
  .labelDescription {
    margin-top: 0.3rem;
    font-size: 1.5rem;
    color: #ababab;
    display: block;
  }
}

.inputBox {
  width: 100%;
  height: 70px;
  font-size: 18px;
  color: #000;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  padding: 0 14px;
}

.phoneSelect + input {
  margin-left: -1px;
}

.inputBox + .inputBox {
  margin-left: -2px;
}

.phoneSelect {
  width: 100%;
  height: 70px;
  font-size: 18px;
  color: #000;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  padding: 0 14px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/promotion/quizEvent/icon-select-arrow.png')
    no-repeat top 30px right 11px;
  outline: none;
}

.terms {
  text-align: center;
}

.termsLabel {
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.termsLabel:before {
  content: '';
  display: inline-block;
  width: 27px;
  height: 27px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/promotion/quizEvent/bg-checkbox.png')
    no-repeat;
  background-size: contain;
  vertical-align: bottom;
  margin-right: 13px;
}

.terms input[type='checkbox'] + label {
  cursor: pointer;
  display: inline-block;
}

.terms input[type='checkbox']:checked + label:before {
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/promotion/quizEvent/bg-checkbox-active.png')
    no-repeat;
  background-size: contain;
}

.termsButton {
  color: #0038de;
  font-size: 17px;
  margin-left: 8px;
  font-weight: 500;
  position: relative;
  letter-spacing: -0.5px;
  display: inline-block;
}

.termsButton:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #0038de;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.counselSelect {
  display: block;
  width: 100%;
  height: 7rem;
  padding: 0 0.7rem;
  font-size: 1.8rem;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.formInterval {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .label {
    font-size: 2.6rem;
  }
  .row {
    grid-template-columns: 18rem 1fr;
  }
  .inputBox {
    height: 8rem;
    padding: 0 0.7rem;
    font-size: 2.4rem;
  }

  .phoneSelect {
    height: 8rem;
    font-size: 2.4rem;
    background-position: top 5.3vw right 2vw;
    background-size: 1.7vw auto;
    padding: 0 0.7rem;
  }

  .counselSelect {
    font-size: 2.4rem;
    padding: 0 0.7rem;
  }
  .termsLabel {
    font-size: 2.7vw;
  }

  .termsLabel:before {
    width: 4.3vw;
    height: 4.3vw;
    margin-right: 1.7vw;
  }

  .termsButton {
    font-size: 2.7vw;
  }
}
