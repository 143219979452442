$HOST_CDN: 'https://english.yanadoocdn.com/upload/yanadoo/new/common/header/';
$ICON_CLOSE: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_close_201x201.png';

.mypageButton {
  position: relative;
  padding: 0px 0px 0px 16px;
  display: inline-block;
  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0px;
    margin-top: -9px;
    background-color: rgb(207, 207, 218);
  }
  &.active {
    .buttonList {
      display: block;
    }
  }
  .buttonMyPage {
    display: inline-block;
    vertical-align: middle;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    box-sizing: border-box;
    border-radius: 100%;
  }
  .buttonList {
    display: none;
    position: absolute;
    top: 3rem;
    right: 0rem;
    width: 12.5rem;
    padding: 2rem 0px 0.4em;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
    box-sizing: border-box;
    z-index: 2;
  }
  .button {
    text-align: right;
    display: block;
    height: unset;
    padding: 0rem 2rem 1.5rem;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
    &:hover {
      font-weight: 700;
    }
  }
}
.loginButton {
  position: relative;
  display: block;
  padding: 0 16px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  margin-right: -16px;
  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0px;
    margin-top: -9px;
    background-color: rgb(207, 207, 218);
  }
}

.navDim {
  display: none !important;
  position: fixed;
  inset: 0px;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  opacity: 0.7;
  z-index: 3;

  .closeIcon {
    display: block;
    position: absolute;
    top: 50%;
    right: 10%;
    width: 7rem;
    height: 7rem;
    background: #000 url($ICON_CLOSE) no-repeat center / 100%;
  }

  &.active {
    display: block !important;
  }
}

.buttonEventContainer {
  position: relative;
  display: flex;
  align-items: center;

  .buttonEvent {
    font-weight: 500;
    font-size: 15px;
    padding: 16px;

    &:hover {
      & + ul {
        display: block;
      }
    }
    & + ul:hover {
      display: block;
    }
    & + ul {
      display: none;
      position: absolute;
      top: 50px;
      right: 0;
      padding: 1.4rem 0;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 0px #00000033;
      box-sizing: border-box;
      z-index: 2;

      li {
        &:hover {
          background-color: #ececf6;
        }
      }
      li > a {
        display: block;
        width: max-content;
        padding: 0.6rem 2rem;
        font-weight: 500;
        font-size: 15px;
        text-align: right;

        &:hover {
          font-weight: 700;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .mypageButtonMobile {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    box-sizing: border-box;
    border-radius: 100%;
  }
  .buttonEvent {
    display: none;

    & + ul {
      display: none;
    }
  }
  .hamburgerButton {
    margin-left: 12px;
    height: 20px;
    .icon {
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 50% 50%;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      background-image: url($HOST_CDN + 'icon-hamburger.png');
    }
  }
}
@media only screen and (max-width: 375px) {
  .navDim {
    .closeIcon {
      right: 10px;
    }
  }
}
