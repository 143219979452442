.dim {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  transition: top 0.3s ease;

  div,
  input {
    box-sizing: border-box;
  }
}

.container {
  width: 100%;
  min-height: 288px;
  background: #fff;
}

.inner {
  max-width: 1284px;
  margin: 0 auto;
  padding: 37px 42px 24px;
}

.title {
  font-size: 28px;
  line-height: 41px;
  font-weight: 700;
}

.input {
  margin-top: 9px;
  border-bottom: 2px solid #000;
  padding: 12px 24px;
  width: 100%;
  font-size: 22px;
  caret-color: #ffb900;

  &::placeholder {
    color: #b7b7c8;
  }
}

.recommended {
  display: flex;
  margin-top: 20px;
}

.recommendedTitle {
  font-weight: 500;
  padding: 0 18px;
  font-size: 16px;
  flex: none;
  margin-top: 7px;
}

.recommendedItem {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
}

.recommendedButton {
  padding: 5px 18px;
  border-radius: 17px;
  border: 1px solid #e7e7f0;
  background: #f7f7fc;
  color: #717188;
  font-size: 16px;
}

.closeButton {
  display: flex;
  align-items: center;
  margin: 23px auto 0;
  font-size: 20px;
  font-weight: 500;
  justify-content: center;

  img {
    width: 22px;
    height: 22px;
    margin-left: 9px;
  }
}

.closeButtonM {
  display: none;
}

@media screen and (max-width: 1180px) {
  .dim {
    top: 113px;
  }
}

@media screen and (max-width: 1024px) {
  .dim {
    top: 138px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 100%;
  }

  .dim {
    top: 138px;
  }

  .inner {
    padding: 2.5vw 3.2vw;
  }

  .title {
    font-size: 3.3vw;
  }

  .input {
    margin-top: 0.6vw;
    padding: 2.4vw 1.8vw;
    font-size: 3.8vw;
  }

  .recommended {
    display: block;
    margin-top: 7.7vw;
  }

  .recommendedTitle {
    padding: 0;
    font-size: 3.3vw;
  }

  .recommendedList {
    margin-top: 4.8vw;
    padding: 0 3.2vw;
  }

  .recommendedItem {
    margin: 0 1.4vw 3.7vw 0;
  }

  .recommendedButton {
    padding: 1.3vw 3.4vw;
    font-size: 3.6vw;
    border-radius: 4vw;
  }

  .closeButtonM {
    display: block;
    position: absolute;
    bottom: 2.8vw;
    right: 1vw;
    width: 4.8vw;
    height: 4.8vw;
  }

  .inputContainer {
    position: relative;
  }

  .closeButton {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .dim {
    top: 129px;
  }
}

.btnSearch {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 16px;

  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: -15px;
    margin-top: -9px;
    background-color: #cfcfda;
  }

  @media screen and (max-width: 1024px) {
    margin: 0;
    margin-right: 12px;
    width: 20px;
    height: 20px;

    &:after {
      display: none;
    }
  }
}
