.infoFullBox {
  background-color: #ececf6;

  p {
    padding: 1.6rem 2rem 1.4rem 3.6rem;
    font-size: 1.4rem;
    color: #717188;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_info_30x30.png)
      no-repeat 1rem 1.45rem / 1.8rem auto;
  }

  @media screen and (max-width: 1024px) {
    p {
      padding: 3.2rem 4rem 2.8rem 8.4rem;
      font-size: 2.5rem;
      background: {
        position: 4.2rem 3rem;
        size: 3rem auto;
      }
    }
  }
}

.smInnerBox {
  position: relative;
  max-width: 948px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }
}
