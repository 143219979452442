.modalSection {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999999999;
  background-color: #00000099;

  :global(.swiper-container) {
    height: 100%;
    padding: 10.8rem 0;
    box-sizing: border-box;

    :global(.swiper-button-prev) {
      left: 6rem;
    }

    :global(.swiper-button-next) {
      right: 6rem;
    }

    :global(.swiper-button-prev),
    :global(.swiper-button-next) {
      color: #fff;

      &::after {
        font-size: 3rem;
      }
    }
  }

  :global(.swiper-pagination) {
    display: inline-block;
    top: 3.6rem;
    left: 5rem;
    bottom: auto;
    width: auto;
    font-weight: 700;
    font-size: 2rem;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    :global(.swiper-pagination) {
      display: block;
      width: 100%;
      left: 0;
      font-size: 3.1rem;
    }

    :global(.swiper-button-prev),
    :global(.swiper-button-next) {
      display: none;
    }
  }
}

.slideItem {
  display: block;
  max-width: 90rem;
  height: 100%;
  margin: 0 auto;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
  }
}

.buttonClose {
  position: absolute;
  top: 3rem;
  right: 5rem;
  z-index: 10;
  width: 2.8rem;
  height: 2.8rem;
  padding: 1rem;
  background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_close_90x90w.png)
    50% 50%/100% auto no-repeat;

  span {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    top: 3.7rem;
    right: 5.3rem;
    width: 3.4rem;
    height: 3.4rem;
  }
}
