.chatModeTap {
  position: absolute;
  top: 68px;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;

  box-sizing: border-box;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 9999999;

  .tapContainer {
    padding: 4px 5px;
    background-color: #ececf6;
    border-radius: 40px;
    border: 2px solid #dfdfea;
    overflow: hidden;

    button {
      width: 157px;
      height: 46px;

      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      color: #8d8da0;

      transition: all 0.3s;

      span {
        font-size: 12px;
        font-weight: 400;
      }

      &.active {
        color: #fff;
        background-color: #269cff;
        border-radius: 60px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    top: 105px;
    padding: 8px 0;

    .tapContainer {
      padding: 5px;
      border-radius: 40px;
      border: 2px solid #dfdfea;

      button {
        width: 182px;
        height: 60px;

        font-size: 20px;
        line-height: 26px;

        span {
          font-size: 16px;
        }

        &.active {
          border-radius: 60px;
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    top: 14vw;
    padding: 1.067vw 0;

    .tapContainer {
      padding: 0.667vw;
      border-radius: 5.333vw;
      border: 0.267vw solid #dfdfea;

      button {
        width: 24.267vw;
        height: 8vw;

        font-size: 2.667vw;
        line-height: 3.467vw;

        span {
          font-size: 2.133vw;
        }

        &.active {
          border-radius: 8vw;
        }
      }
    }
  }
}
