.container {
  background: #fff;
  padding: 70px 42px 230px;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 247px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &.quiz {
    &:before {
      background: linear-gradient(180deg, #ffead7 0%, #fff 100%);
    }
    h1 {
      color: #ff6f0f;
    }
  }
  &.conversation {
    &:before {
      background: linear-gradient(180deg, #d7e8ff 0%, #fff 100%);
    }
    h1 {
      color: #5199ff;
    }
    ul li > svg circle {
      fill: #5199ff;
      stroke: #5199ff;
    }
  }
}
.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  z-index: 2;
  position: relative;
}
.description {
  margin-top: 12px;
  color: #030618;
  font-size: 61px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: -2px;
  z-index: 2;
  position: relative;
}
.targetList {
  margin-top: 68px;
  display: grid;
  gap: 30px;
  justify-content: center;
  li {
    color: #3f3f3f;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    display: flex;
    align-items: center;
    gap: 22px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
.subDescription {
  margin-top: 109px;
  color: #030617;
  font-size: 47px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: -2px;
  b {
    color: var(--Accent-Red---FF5647, #ff5647);
  }
}
.precautionList {
  margin-top: 40px;
  border-radius: 20px;
  border: 2px solid var(--GrayScale-200---DFDFEA, #dfdfea);
  background: var(--GrayScale-20---F7F7FC, #f7f7fc);
  padding: 60px 38px 70px;
  display: grid;
  gap: 56px;
  text-align: left;
  li {
    span {
      border-radius: 45.5px;
      background: #ff6f62;
      display: inline-flex;
      padding: 10px 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      box-sizing: border-box;
      svg {
        width: 32px;
        height: 32px;
      }
    }
    p {
      margin-top: 16px;
      color: #3f3f3f;
      font-size: 28px;
      line-height: 34px;
      b {
        font-weight: 700;
      }
    }
  }
}
.buttonWrap {
  margin-top: 80px;
  display: flex;
  gap: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 60%);
  padding: 37px 36px 37px;
  box-sizing: border-box;
  max-width: 750px;

  button {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.5px;
    border-radius: 18px;
    height: 112px;
    &:disabled {
      background: #5199ff;
      font-size: 28px;
      span {
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 26.003px;
        margin-bottom: 7px;
      }
    }
    &:first-of-type {
      width: 164px;
      border: 2px solid #d5dae1;
      background: var(--GrayScale-0---FFFFFF, #fff);
      color: #161c22;
      flex: none;
    }
    & + button {
      width: 100%;
      background: #161c22;
      color: #fff;
    }
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding: 9.33vw 5.6vw 30.67vw;
    &:before {
      height: 32.93vw;
    }
  }
  .title {
    font-size: 3.73vw;
    line-height: 4.53vw;
  }
  .description {
    margin-top: 1.6vw;
    font-size: 8.13vw;
    line-height: 10.4vw;
    letter-spacing: -0.27vw;
  }
  .targetList {
    margin-top: 9.07vw;
    gap: 4vw;
    li {
      font-size: 3.73vw;
      line-height: 4.53vw;
      gap: 2.93vw;
      svg {
        width: 5.33vw;
        height: 5.33vw;
      }
    }
  }
  .subDescription {
    margin-top: 14.53vw;
    font-size: 6.27vw;
    line-height: 8.27vw;
    letter-spacing: -0.27vw;
  }
  .precautionList {
    margin-top: 5.33vw;
    border-radius: 2.67vw;
    border: 0.27vw solid var(--GrayScale-200---DFDFEA, #dfdfea);
    background: var(--GrayScale-20---F7F7FC, #f7f7fc);
    padding: 8vw 4.5vw 9.33vw;
    gap: 7.47vw;
    li {
      span {
        border-radius: 6.07vw;
        padding: 1.33vw 2.93vw;
        gap: 1.33vw;
        font-size: 3.73vw;
        line-height: 4.53vw;
        svg {
          width: 4.27vw;
          height: 4.27vw;
        }
      }
      p {
        margin-top: 2.13vw;
        font-size: 3.73vw;
        line-height: 4.53vw;
      }
    }
  }
  .buttonWrap {
    margin-top: 10.67vw;
    gap: 2.67vw;
    padding: 4.93vw 4.8vw 4.93vw;

    button {
      font-size: 4.27vw;
      line-height: 5.33vw;
      letter-spacing: -0.07vw;
      border-radius: 2.4vw;
      height: 14.93vw;
      &:first-of-type {
        width: 21.87vw;
        border: 0.27vw solid #d5dae1;
      }
      &:disabled {
        font-size: 3.733vw;
        span {
          font-size: 2.667vw;
          line-height: 3.467vw;
          margin-bottom: 0.933vw;
        }
      }
    }
  }
}
