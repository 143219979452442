$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/';
$profile-backgrounds: (
  emily: '#{$img-path}bg_emily_rose.png',
  tom: '#{$img-path}bg_tom_kim.png',
  jenny: '#{$img-path}bg_jenny_jung.png',
  laura: '#{$img-path}bg_laura_parker.png',
  rachael: '#{$img-path}bg_rachael_smith.png',
  lily: '#{$img-path}bg_lily_thompson.png',
  tony: '#{$img-path}bg_tony_scott.png',
  sam: '#{$img-path}bg_sam_anderson.png'
);

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalAudioMode {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  @include flex-center;

  background-color: rgba($color: #000000, $alpha: 0.6);

  .modeContainer {
    @include flex-center;
    flex-direction: column;

    // pc 기준
    width: 480px;
    height: 598px;

    background-color: #000;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    color: #fff;
    animation: bg-motion 3s forwards;

    @each $name, $image in $profile-backgrounds {
      &.#{$name} {
        background-image: url(#{$image});
      }
    }

    .modeTitle {
      margin-bottom: 45px;

      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.5px;
    }

    .aiProfile {
      @include flex-center;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 36px;

      .profileImg {
        width: 178px;
        height: 178px;
        position: relative;

        img {
          display: block;
          width: 130px;
          height: 130px;

          position: absolute;
          top: 24px;
          left: 24px;
        }

        &::before {
          position: absolute;
          left: 8px;
          bottom: 14px;
          z-index: 2;

          display: block;
          width: 60px;
          height: 60px;

          background-image: url('#{$img-path}ico_mic.svg');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          content: '';
        }
      }

      .profileName {
        font-size: 19px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.5px;
      }
    }

    .noti {
      @include flex-center;
      flex-direction: column;
      gap: 7px;
      margin-bottom: 28px;

      dt {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      dd {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
      }
    }

    .btnChangeMode {
      @include flex-center;
      gap: 5px;
      width: 348px;
      height: 48px;

      background-color: #fff;
      border-radius: 54px;

      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      color: #28284b;

      &::before {
        display: block;
        width: 30px;
        height: 16.5px;
        margin-top: -1.5px;
        background: url('#{$img-path}ico_keyboard.svg') center no-repeat;
        background-size: 30px auto;
        content: '';
      }
    }
  }

  @media screen and (max-width: 767px) {
    .modeContainer {
      width: 100%;
      height: 100%;
      border-radius: 0;
      .modeTitle {
        margin-bottom: 21.333vw;
        font-size: 6.4vw;
        line-height: 7.467vw;
        letter-spacing: -0.067vw;
      }

      .aiProfile {
        gap: 3.733vw;
        margin-bottom: 17.333vw;

        .profileImg {
          width: 41.6vw;
          height: 41.6vw;

          svg {
            width: 100%;
            height: 100%;
          }

          img {
            width: 30.4vw;
            height: 30.4vw;
            top: 5.6vw;
            left: 5.6vw;
          }

          &::before {
            left: 0.4vw;
            bottom: 4vw;
            width: 13.333vw;
            height: 13.333vw;
          }
        }

        .profileName {
          font-size: 4vw;
          line-height: 5.333vw;
          letter-spacing: -0.067vw;
        }
      }

      .noti {
        gap: 2.667vw;
        margin-bottom: 8vw;

        dt {
          font-size: 3.2vw;
          line-height: 4vw;
        }
        dd {
          font-size: 2.667vw;
          line-height: 3.467vw;
        }
      }

      .btnChangeMode {
        gap: 0.667vw;
        width: 58.933vw;
        height: 8.533vw;
        border-radius: 1000px;
        font-size: 3.2vw;
        line-height: 4vw;

        &::before {
          width: 4vw;
          height: 2.2vw;
          margin-top: -0.2vw;
          background-size: 4vw auto;
        }
      }
    }
  }
}

@keyframes bg-motion {
  0% {
    background-size: 100% auto;
  }
  100% {
    background-size: 150% auto;
  }
}
