$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/';
$profile-backgrounds: (
  emily: '#{$img-path}profile_emily_rose.png',
  tom: '#{$img-path}profile_tom_kim.png',
  jenny: '#{$img-path}profile_jenny_jung.png',
  laura: '#{$img-path}profile_laura_parker.png',
  rachael: '#{$img-path}profile_rachael_smith.png',
  lily: '#{$img-path}profile_lily_thompson.png',
  tony: '#{$img-path}profile_tony_scott.png',
  sam: '#{$img-path}profile_sam_anderson.png'
);

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalProfile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  @include flex-center;

  background-color: rgba($color: #000000, $alpha: 0.6);

  .modalContainer {
    position: relative;
    @include flex-center;
    flex-direction: column;

    // pc 기준
    width: 480px;
    height: 598px;
    overflow: hidden;

    background-color: #000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    color: #fff;

    @each $name, $image in $profile-backgrounds {
      &.#{$name} {
        background-image: url(#{$image});
      }
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('#{$img-path}profile_pc_dim.png') center no-repeat;
      background-size: cover;
      content: '';
    }

    .btnClose {
      position: absolute;
      top: 24px;
      right: 32px;
      width: 24px;
      height: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-indent: -1000px;
      background: url('#{$img-path}btn_close.svg') center no-repeat;
    }

    .profileContent {
      position: absolute;
      left: 0;
      bottom: 60px;

      width: 100%;

      text-align: center;

      .profile {
        .name {
          font-size: 28px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.5px;
          text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.45);
        }
        .info {
          @include flex-center;
          flex-wrap: wrap;
          gap: 12px;
          margin-top: 2px;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.45);

          .dot {
            display: block;
            width: 2px;
            height: 2px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 2px;

            text-indent: -1000px;
            white-space: nowrap;
          }
        }
        .hash {
          @include flex-center;
          flex-wrap: wrap;
          gap: 5px;
          margin-top: 24px;

          span {
            display: inline-flex;
            align-items: center;
            height: 32px;
            padding: 0 12px;
            background-color: rgba($color: #000000, $alpha: 0.6);
            border-radius: 6px;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }

      .helloText {
        @include flex-center;
        flex-direction: column;
        margin-top: 24px;

        p {
          height: 22px;
          overflow: hidden;
          white-space: pre-line;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;

          transition: height 0.3s;

          &.active {
            height: 66px;
            &.two {
              height: 44px;
            }
          }
        }
        button {
          @include flex-center;
          gap: 6px;
          margin-top: 4px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #b7b7c8;

          &::after {
            display: block;
            width: 12px;
            height: 12px;
            background: url('#{$img-path}ico_arrow.svg') center no-repeat;
            background-size: cover;
            transition: all 0.3s;

            content: '';
          }

          &.active {
            &::after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .modalContainer {
      width: 100%;
      height: 100%;
      border-radius: 0;

      &::before {
        background: url('#{$img-path}profile_m_dim.png') center no-repeat;
      }

      .btnClose {
        width: 5.333vw;
        height: 5.333vw;
        left: 3.467vw;
        top: 4.267vw;
      }

      .profileContent {
        bottom: 15.733vw;
        .profile {
          .name {
            font-size: 6.4vw;
            line-height: 7.467vw;
            letter-spacing: -0.067vw;
          }
          .info {
            margin-top: 1.333vw;
            font-size: 3.2vw;
            line-height: 4vw;
          }
          .hash {
            margin-top: 6.933vw;
            span {
              height: 8.267vw;
              padding: 0 2.667vw;
              font-size: 3.733vw;
              line-height: 4.533vw;
            }
          }
        }
        .helloText {
          margin-top: 6.933vw;
          p {
            height: 4.533vw;
            font-size: 3.733vw;
            line-height: 4.533vw;

            &.active {
              height: 13.6vw;
              &.two {
                height: 9.067vw;
              }
            }
          }

          button {
            margin-top: 2.133vw;
            font-size: 3.2vw;
            line-height: 4vw;

            &::after {
              width: 3.2vw;
              height: 3.2vw;
            }
          }
        }
      }
    }
  }
}
