.viewBtn {
  display: flex;
  width: 100%;
  padding: 0 2rem 3rem 2rem !important;
  flex-wrap: nowrap;
  border-radius: 0 !important;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: 1024px) {
    padding: 0 0 4rem 0 !important;
  }
}

.itemThumb {
  width: 7rem;
  height: 7rem;

  & + & {
    margin-left: 0.5rem;
  }

  @media all and (max-width: 1024px) {
    flex: 0 0 auto;
    width: 14.6rem;
    height: 14.6rem;

    & + & {
      margin-left: 1rem;
    }
  }
}
