.boardBox {
  padding: 8rem 0;
  background-color: #f7f7fc;

  h3 {
    margin-bottom: 1.4rem;
    font-weight: 700;
    font-size: 1.8rem;
    color: #28284b;
  }

  .pagination {
    padding-bottom: 6rem;
  }

  @media (max-width: 1024px) {
    padding: 0 4.2rem 3.2rem;

    h3 {
      margin-bottom: 2.4rem;
      font-size: 2.8rem;
    }
  }
}

.boardList {
  display: block;
  width: 100%;

  .noContent {
    padding: 8rem 0 12rem;
  }

  @media (max-width: 1024px) {
    border-top: 0.1rem solid #e7e7f0;

    .noContent {
      padding: 0;
    }
  }
}

.boardListBox {
  position: relative;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 1024px) {
    margin-bottom: 3rem;
    box-shadow: 0.1rem 0.1rem 2.4rem rgba(0, 0, 0, 0.1);
  }
}

.cardInnerBox {
  position: relative;
  max-width: 866px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 0;
  }
}
