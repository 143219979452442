.container {
  padding-top: 64px;
  text-align: center;
}
.pagination {
  color: #717188;
  font-size: 21px;
  font-weight: 500;
  line-height: 23px;
}
.description {
  margin-top: 56px;
  color: #74abff;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.5px;
}
.title {
  margin-top: 10px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  color: #fff;
  white-space: pre-line;
}
.buttonGroup {
  margin: 56px 0 31px;
}
.buttonGroup button {
  font-weight: 400;
  display: block;
  text-align: center;
  height: auto;
  font-size: 20px;
  line-height: 26px;
}
.buttonGroup button + button {
  margin-top: 16px;
  text-align: center;
}

@media screen and (max-width: 750px) {
  .container {
    padding: 5.067vw 0;
  }
  .pagination {
    font-size: 4.267vw;
    line-height: 4.533vw;
  }
  .description {
    margin-top: 11.2vw;
    font-size: 3.733vw;
    line-height: 5.067vw;
    letter-spacing: -0.1vw;
  }
  .title {
    margin-top: 2.133vw;
    font-size: 6.933vw;
    line-height: 8.533vw;
  }
  .buttonGroup {
    margin: 11.2vw 0 6vw;
  }
  .buttonGroup button {
    font-size: 4.267vw;
    line-height: 5.333vw;
    padding: 5.333vw 5vw;
    border-radius: 2.133vw;
  }
  .buttonGroup button + button {
    margin-top: 3.2vw;
  }
}
