.terms,
.privacy {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: 32px;
}
.tabMenu {
  display: flex;
  flex-direction: row;
  padding: 32px;
}
.tabMenu li {
  width: 50%;
  padding: 16px 0 12px;
  background-color: #f8f8f8;
  text-align: center;
  cursor: pointer;
  border: 1px solid #dcdcdc;
}
.tabMenu li:first-child {
  border-right: 0;
}
.tabMenu li span {
  font-size: 14px;
  font-weight: 400;
}
.tabMenu li.active {
  background-color: #ffb900;
}
.tabMenu li.active span {
  font-weight: 700;
}
.scroll {
  padding: 0 32px 32px;
  height: 500px;
  overflow: auto;
}

.privacy {
  padding: 32px;
}
.privacy hgroup h3 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dcdcdc;
}
.privacy p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.privacy dl {
  display: grid;
  grid-template-columns: 110px 1fr;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #dcdcdc;
  margin: 8px 0;
}
.privacy dl dt,
.privacy dl dd {
  font-size: 14px;
  line-height: 20px;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #dcdcdc;
  padding: 8px;
}
.privacy dl dt {
  font-weight: 700;
}
.privacy dl dd {
  font-weight: 400;
  border-left: 0;
}

@media screen and (max-width: 750px) {
  .terms,
  .privacy {
    width: 100%;
    padding-bottom: 24px;
  }
  .tabMenu {
    padding: 24px 16px;
  }
  .scroll {
    padding: 0 16px 16px;
    height: 60vh;
  }
  .privacy {
    padding: 24px 16px;
  }
}
@media screen and (max-width: 450px) {
}
