@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.myclass-col {
  #lnb + .content {
    padding-bottom: 17.7rem;
  }

  .search-box {
    position: absolute;
    top: -5.5rem;
    right: 0;

    select {
      display: inline-block;
      width: 30.6rem;
      padding: 1.2rem 1.5rem 1.1rem;
      border-radius: 2000em;
    }
  }

  .tab-qna {
    position: relative;
    .search-box {
      position: static;
    }
  }

  .tab-course-list {
    .search-box {
      top: 0;
    }
  }
}

.class-empty {
  &.myclass,
  &.history {
    text-align: center;

    p {
      line-height: 1.4;
      word-break: keep-all;
    }
  }

  &.myclass {
    padding-top: 19rem;
  }

  &.expiration {
    padding: 13% 0;
    text-align: center;

    .title {
      color: #000;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.25;
      letter-spacing: -0.5px;
      br {
        display: none;
      }
    }

    .desc {
      color: #a0a0b6;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: -0.5px;
      br {
        display: none;
      }
    }

    .btn {
      width: 200px;
      height: 48px;
      margin: 28px auto 0 auto;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .call {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.history {
    padding: 6.7rem 0 0 0;
  }
}

.search-box {
  select {
    display: inline-block;
    width: 13.8rem;
    padding: 0.7rem 1.5rem 0.6rem;
    font-weight: 400;
    font-size: 1.4rem;
    border: 1px solid #dfdfea;
    background: url('#{$img-cdn}/ico_check_36x21.png') 91% 50% / 1.2rem auto no-repeat;

    & + select {
      margin-left: 1rem;
    }
  }
}

.myclass-tab-area {
  padding: 8rem 0 3rem;

  li {
    display: inline-block;
    font-weight: 700;
    font-size: 2rem;

    a {
      display: block;
      color: $gray-700;
    }

    &.active {
      font-weight: 700;

      a {
        padding-bottom: 0.2rem;
        border-bottom: 0.3rem solid $warning;
        color: $dark;
        white-space: nowrap;
      }
    }

    + li {
      margin-left: 4.8rem;
    }
  }
}

.myclass-tab-area-depth2 {
  padding-top: 8rem;

  li {
    display: inline-block;
    font-weight: 700;
    font-size: 2rem;

    a {
      display: block;
      color: $gray-700;
    }

    &.active {
      font-weight: 700;

      a {
        padding-bottom: 1rem;
        border-bottom: 0.3rem solid $dark;
        color: $dark;
        white-space: nowrap;
      }
    }

    + li {
      margin-left: 4.8rem;
    }
  }
}

.myclass-content-box {
  .tab-content {
    display: none;

    &.active {
      display: block;
    }

    .btn-membership {
      width: 27.6rem;
      margin-top: 2.6rem;
      padding: 2.2rem 0;
      font-weight: 400;
      font-size: 2rem;
      background-color: $warning;
      border-radius: 0.3rem;
    }

    .app-download {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 2.5rem 3rem;
      border: 1px solid $gray-300;

      p {
        flex: 0 1 auto;

        strong {
          display: block;
          font-weight: 700;
          font-size: 1.6rem;
        }

        span {
          display: block;
          margin-top: 1.3rem;
          font-size: 1.4rem;
          line-height: 1.5;
          color: $gray-900;
        }
      }

      img {
        flex: 0 1 auto;
        width: 8rem;
        height: 8rem;
      }
    }
  }

  &.myclass-detail {
    .tab-content {
      border-top: 0.3rem solid $dark;

      &.tab-qna,
      &.tab-materials {
        border-top: 0;
      }

      &.ynd2 {
        border-top: none;
      }

      .table-form {
        margin-top: 0;
      }
    }
  }
}

.class-content {
  border-top: 0.3rem solid $dark;
  border-bottom: 1px solid $gray-600;

  > div {
    border-bottom: 1px solid $gray-300;

    &:last-child {
      border-bottom: none;
    }

    .inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-inline: 10.4rem 2.8rem;
      background-repeat: no-repeat;
      background-size: 6rem;
      background-position: 2.8rem 50%;
      height: 120px;

      &.is-pc-support {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/pc/myClass/ico_class_pc.svg);
      }

      &.is-srr-only {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/pc/myClass/ico_class_srr.png);
      }

      button {
        flex: 0 1 auto;
      }
    }
  }

  p {
    flex: 1;
    font-size: 1.4rem;
    color: #28284b;
    padding-right: 1rem;

    strong {
      display: block;
      color: #000;
      margin-bottom: 1.3rem;
      font-weight: 700;
      font-size: 1.8rem;
    }
  }

  .mypage-box {
    .go-mypage {
      width: 16rem;
      padding: 1.3rem 0;
      font-weight: 400;
      font-size: 1.2rem;
      background-color: $warning;
      border-radius: 0.3rem;
      text-align: center;
    }
  }
}

.class-box {
  padding: 0;

  p {
    flex: 0 1 auto;
    font-size: 1.4rem;
  }

  .btn-toggle {
    width: auto;
    padding: 1.5rem 0;
    font-weight: 700;
    font-size: 1.4rem;

    &::after {
      content: '';
      display: inline-block;
      width: 1.2rem;
      height: 0.9rem;
      margin-left: 1rem;
      font-weight: 700;
      background: url('#{$img-cdn}/ico_check_36x21.png') no-repeat 100% 50% / 100%;
    }
  }

  &.myclass {
    .class-list-area {
      display: none;
    }
  }

  &.history {
    padding: 3.5rem 2.8rem;
    background-color: $gray-100;
  }

  &.active {
    .btn-toggle {
      &::after {
        transform: rotate(-180deg);
      }
    }

    .class-list-area {
      display: block;
    }
  }
}

.qr-code-area {
  margin: 0 2.8rem 3.2rem 2.8rem;
  padding: 2.9rem 4.8rem 2.9rem 3.2rem;
  background-color: #e9f5ff;
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  hgroup {
    h4 {
      color: #269cff;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.5;
    }
    p {
      color: #28284b;
      font-size: 1.4rem;
      line-height: 1.43;
      margin-top: 1.2rem;
    }
    + img {
      width: 8rem;
      height: 8rem;
    }
  }
}

.class-detail-area {
  border-top: 0.3rem solid $dark;

  .class-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 3.6rem 2.5rem;
    background-color: $gray-100;

    dl {
      flex: 1;

      dt {
        p {
          font-weight: 700;
          font-size: 2rem;
        }
      }

      dd {
        margin-top: 1.7rem;

        p {
          display: inline-block;
          font-weight: 700;
          font-size: 1.4rem;
          color: $gray-800;

          + p {
            &::before {
              content: ',';
              display: inline-block;
              margin-right: 0.8rem;
            }
          }
        }

        em {
          + em {
            &::before {
              content: '·';
              margin: 0 0.3rem;
            }
          }
        }
      }
    }

    .btn-class-review {
      flex: 0 1 auto;
      width: 16rem;
      padding: 1.3rem 0;
      font-weight: 700;
      font-size: 1.2rem;
      background-color: $warning;
      border-radius: 0.3rem;
    }
  }

  .class-check {
    border: 1px solid $gray-300;
    display: flex;
    padding: 2.5rem;

    .class-check__icon {
      width: 3.7rem;
      height: 3.7rem;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_alert.svg)
        no-repeat 50% 50% / 100%;
    }

    > p {
      margin-left: 2.5rem;
      color: #717188;
      font-size: 1.2rem;
      line-height: 1.33;
      strong {
        color: #ff5647;
        font-weight: 700;
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
        display: block;
      }
    }
  }
}

.tab-course-list {
  position: relative;

  .btn-all-download {
    > a {
      // position: absolute;
      // top: -6.8rem;
      // right: 0;
      display: block;
      width: 16rem;
      padding: 1.2rem 0;
      font-size: 1.2rem;
      border: 1px solid $gray-500;
      border-radius: 2000em;
      text-align: center;
    }
  }

  .list-area {
    li {
      position: relative;
      border-bottom: 1px solid $gray-300;
    }

    .list-btn-area {
      position: absolute;
      top: 2.4rem;
      right: 2.5rem;

      button {
        vertical-align: middle;

        &.btn-qna {
          margin-left: 3rem;
          font-weight: 700;
          font-size: 1.3rem;
          text-decoration: underline;
        }

        + button {
          margin-left: 2rem;
        }
      }

      .file-download-box {
        display: inline-block;
        position: relative;
        vertical-align: middle;

        button {
          width: 2.1rem;
          height: 2.1rem;
          background: url('#{$img-cdn}/ico_downloadzip_72x72b.png') no-repeat 50% 0 / 2.1rem auto;
        }

        .file-list {
          display: none;
          position: absolute;
          top: 1.5rem;
          right: -11.5rem;
          width: 25rem;
          height: 24rem;
          padding-top: 4rem;
          background: url('#{$img-cdn}/bg_downloadzip.png') no-repeat 50% 0 / 100%;
          z-index: 1;

          &.active {
            display: block;
          }

          .box-title {
            display: block;
            width: 85%;
            height: 4.2rem;
            margin: 0 auto;
            padding: 1.4rem 1.9rem;
            font-size: 1.2rem;
            box-sizing: border-box;
          }

          ul {
            width: 85%;
            height: 16rem;
            margin: 0 auto;
            font-size: 1.2rem;
            box-sizing: border-box;
            overflow-y: scroll;
          }

          li {
            border-bottom: none;

            a {
              width: 100%;
              height: 4.3rem;
              padding: 1.4rem 1.9rem;
              background: url('#{$img-cdn}/ico_download_54x54b.png') no-repeat 90% 45% / 1.8rem auto;
              box-sizing: border-box;
            }

            span {
              display: block;
              padding-right: 3rem;
            }
          }
        }
      }

      .btn-download {
        display: inline-block;
        width: 2.1rem;
        height: 2.1rem;
        background: url('#{$img-cdn}/ico_downloadzip_72x72b.png') no-repeat 50% 0 / 2.1rem auto;
        vertical-align: middle;
      }
    }
  }

  dl {
    padding: 3rem 0;
    dt {
      padding: 0 2.5rem;

      p {
        display: inline-block;
        max-width: 76.5%;
        font-weight: 700;
        font-size: 2rem;
      }
    }

    dd {
      margin-top: 1.2rem;
      padding: 0 7rem;

      p {
        display: inline-block;
        max-width: 80%;
        font-size: 1.5rem;

        &.progress-rate {
          margin-top: 3.8rem;
          color: $gray-900;

          span {
            font-weight: 700;

            &::after {
              content: '%';
              display: inline-block;
            }
          }
        }

        &.item {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 0.8rem;
        }
      }

      .asset-item {
        flex: 0 1 auto;
        position: relative;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        color: $gray-500;
        background-color: $white;
        border: 1px solid $gray-500;
        border-radius: 10rem;

        &.active {
          color: $white;
          background-color: $success;
          border: 1px solid $success;
        }

        + .asset-item {
          margin-left: 1.5rem;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1.6rem;
            width: 1.5rem;
            height: 0.1rem;
            margin: auto;
            background-color: $gray-500;
          }
        }
      }
    }

    .time {
      display: block;
      margin-top: 1.2rem;
      font-weight: 700;
      font-size: 1.4rem;
      color: $gray-700;
    }
  }

  .sub-list {
    margin-top: 4.4rem;

    div {
      position: relative;
      + div {
        margin-top: 3rem;
      }
    }

    .title {
      display: block;
      width: auto;
      font-weight: 700;
      font-size: 2rem;
    }

    .time {
      margin-top: 1.2rem;
    }
  }

  .btn-play {
    display: inline-block;
    width: 3.4rem;
    height: 3.4rem;
    margin-top: -0.4rem;
    margin-left: 0.7rem;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 100%;
    vertical-align: top;

    &.video {
      background-image: url('#{$img-cdn}/ico_btn_play_102x102.png');
    }

    &.audio {
      background-image: url('#{$img-cdn}/ico_btn_audio_102x102.png');
    }
  }

  .class-ynd2 {
    dt {
      padding: 0 0 1rem;
      border-bottom: 0.2rem solid #777;

      p {
        font-size: 2.5rem;
      }
    }

    dd {
      padding: 0;
    }

    ul {
      li {
        padding: 2rem 2.5rem;

        p {
          display: block;

          &.subjects-title {
            display: inline-block;
            max-width: 76.5rem;
            font-weight: 700;
            font-size: 2rem;
          }

          &.subjects-info {
            margin-top: 1.2rem;
            font-size: 1.4rem;
            color: $gray-700;

            span {
              &:first-child {
                margin-right: 1rem;
                font-weight: 700;
              }
            }
          }

          &.progress-rate {
            margin-top: 3.8rem;
            color: $gray-900;
          }
        }
      }
    }
  }
}

.toggle-btn-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.7rem;

  .user-name-area {
    font-size: 1.6rem;
    color: $gray-800;
  }

  .toggle-menu {
    overflow: hidden;
    position: relative;
    background-color: $white;
    border-radius: 11.5rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 50%;
      bottom: 0;
      left: 0;
      border-radius: 10rem;
      background-color: #ffb900;
      -webkit-transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s 0.2s ease,
        0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s 0.2s ease,
        0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-transition-property: left, right;
      transition-property: left, right;
      transition-property: left, right;
      transition-property: left, right;
    }

    &.active {
      &::before {
        left: 50%;
        right: 0;
        -webkit-transition-property: right, left;
        transition-property: right, left;
        transition-property: right, left;
        transition-property: right, left;
      }
    }

    .toggle-item {
      display: inline-block;
      width: 8.6rem;
      padding: 1rem 0 0.8rem;
      font-weight: 400;
      font-size: 1.4rem;
      color: #a0a0b6;
      white-space: nowrap;
      transition: all 0.3s;

      span {
        position: relative;
        z-index: 2;
      }

      &.active {
        font-weight: 400;
        color: $dark;
      }
    }
  }
}

.tab-materials {
  .table-form {
    tbody {
      tr {
        td {
          padding: 1.8rem 2.5rem;
        }
      }
    }

    .title {
      text-align: left;
    }
  }

  .btn-download {
    width: 3.4rem;
    height: 3.4rem;
    background: url('#{$img-cdn}/ico_download_102x102.png') no-repeat 50% 0 / 3.4rem auto;
  }
}

.tab-course-list,
.tab-qna {
  .btn-wrap {
    display: flex;
    align-items: center;
    position: absolute;
    top: -5rem;
    right: 0;
    gap: 0.8rem;

    .learn-inquiry {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2000em;
      background: radial-gradient(229.15% 145.73% at 5.08% 0%, #d6ecfd 0%, #cecef6 100%);
      width: 11.3rem;
      padding: 1.2rem 0;
      font-size: 1.2rem;
      font-weight: 600;
      gap: 0.4rem;
    }
  }
}

.modal-content {
  .learning-inquiry {
    padding: 3.8rem 0 0;

    .title-area {
      border-bottom: 0.1px solid $gray-400;
      padding-bottom: 3.9rem;

      p {
        padding: 0 3.2rem;
        font-weight: 700;
        font-size: 2.5rem;

        + p {
          margin-top: 1.4rem;
          font-size: 1.6rem;
          color: $gray-700;
        }
      }
    }

    dl {
      padding: 0 3.2rem;
    }

    .inquiry-area {
      dd {
        position: relative;
        margin-top: 1.5rem;
        padding: 0 0 4rem;
        border: 1px solid $gray-300;
      }

      textarea {
        width: 100%;
        height: 26.3rem;
        padding: 1.5rem 1.5rem 0;
        font-size: 1.9rem;
        line-height: 1.5;
        border: none;
        box-sizing: border-box;
        resize: none;
      }

      p {
        position: absolute;
        bottom: 1rem;
        right: 2rem;
        font-size: 1.6rem;
        color: $gray-600;

        span {
          color: $dark;
        }
      }
    }

    .notice {
      margin-top: 1.5rem;
      padding: 3.7rem 3.2rem 6.5rem;
      font-size: 1.6rem;
      color: #e7e7f0;
      background-color: #28284b;

      em,
      strong {
        display: block;

        &::before {
          content: '※';
          display: inline-block;
        }
      }

      strong {
        margin-top: 2.5rem;
        font-weight: 700;
        font-size: 1.7rem;
      }

      ul {
        margin-top: 1.5rem;

        li {
          padding-left: 1.3rem;
          text-indent: -0.5rem;
          line-height: 1.7;

          &::before {
            content: '-';
            display: inline-block;
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
}

.myclass-content-box.myclass-detail .tab-content.tab-course-list,
.tab-qna .table-form thead {
  border-top: 1px solid #cfcfda;
}

.ai-nadoo-modal-dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 998;
}

.ai-nadoo-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48rem;
  border-radius: 1.2rem;
  overflow: hidden;
  z-index: 999;

  > div {
    > div {
      background-color: #f7f7fc;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2.7rem;
      > p {
        color: #717188;
        font-size: 1.2rem;
      }
      > a {
        color: #269cff;
        font-size: 1.4rem;
        font-weight: 500;
        text-decoration-line: underline;
        text-underline-offset: 0.2rem;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 2.8rem;
    right: 3.6rem;
  }
}

@media screen and (max-width: 1024px) {
  .myclass-col {
    #lnb + .content {
      padding-bottom: 0;
    }
  }

  .class-empty {
    &.myclass,
    &.history {
      padding: 24rem 0 0 0;

      p {
        width: 47rem;
      }
    }

    &.myclass .no-content {
      padding-bottom: 5rem;
      padding-inline: 2.8rem;
    }
    &.history .no-content {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &.expiration {
      background-color: #fafafa;
      padding: 23.4rem 0 7.4rem;
      .title {
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 1.22;
        letter-spacing: -0.05rem;
        br {
          display: block;
        }
      }

      .desc {
        font-size: 2.8rem;
        margin-bottom: 23.4rem;
        br {
          display: block;
        }
      }

      .btn {
        width: 90%;
        padding: 2.5rem 0;
      }
    }
  }

  .myclass-tab-area {
    padding: 4.2rem 0 0;
    border-bottom: 1px solid $gray-400;

    ul {
      padding: 0 4.2rem;

      li {
        font-size: 2.9rem;

        a {
          color: $gray-700;
        }

        &.active {
          a {
            padding-bottom: 3.6rem;
            border-bottom-width: 0.8rem;
          }
        }

        + li {
          margin-left: 4.8rem;
        }
      }
    }
  }

  .myclass-content-box {
    .tab-content {
      p {
        strong {
          font-size: 3.5rem;
        }
      }

      .btn-membership {
        width: 36rem;
        margin-top: 3rem;
        padding: 3.4rem 0;
        font-size: 2.7rem;
        border-radius: 0.5rem;
      }

      .app-download {
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-top: 0;
        padding: 5rem 4.2rem;
        border: none;

        p {
          strong {
            font-size: 3.5rem;
          }

          span {
            margin-top: 3.4rem;
            font-size: 3.2rem;
            color: $gray-800;
          }
        }

        img {
          display: none;
        }
      }

      .download-btn-area {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: calc(100% - 8.4rem);
        margin-top: 3rem;

        button {
          flex: 0 1 auto;
          width: 49%;
          height: 8.8rem;
          font-weight: 400;
          font-size: 1.6rem;
          background-color: $warning;
          border-radius: 0.5rem;

          &.btn-aos {
            background: #000 url('#{$img-cdn}/img_google_play.png') no-repeat 50% 50% / 18.3rem auto;
          }

          &.btn-ios {
            background: #000 url('#{$img-cdn}/img_app_store.png') no-repeat 50% 50% / 17.3rem auto;
          }
        }
      }
    }
  }

  .class-content {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    background-color: $gray-100;
    padding-top: 4.2rem;

    div {
      border-bottom: none;
      background-color: $gray-100;
    }

    .mobile-title {
      display: block;
      padding: 4rem 4rem 0;
      font-weight: 700;
      font-size: 3.5rem;
    }

    > select {
      width: calc(100% - 8.4rem);
      display: block;
      margin-inline: auto;
      height: 12.8vw;
      max-height: 55px;
      border: 1px solid $gray-300;
      padding-inline: 2.8rem;
      background-color: #fff;
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/my/myclass/ico_check_36x21.png);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 2rem auto;
      color: #000;
    }
  }

  .class-box {
    &.myclass {
      padding-top: 8rem;

      .class-list-area {
        display: block;
      }

      .inner {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-size: 16.4rem;
        background-position: top center;
        height: auto;

        > p {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding-top: 20rem;
          font-size: 3.2rem;
          margin-bottom: 3rem;
          word-break: keep-all;
          text-align: center;
          padding-inline: 3.1rem;
          box-sizing: border-box;

          strong {
            font-size: 4rem;
            margin-bottom: 1.6rem;
          }
        }
      }

      .btn-toggle {
        display: none;
      }
    }

    &.history {
      background-color: #f1f1f9;
    }
  }

  .class-detail-area {
    border-top: none;
  }

  .toggle-btn-area {
    margin-bottom: 3.2rem;

    .user-name-area {
      font-size: 2.5rem;
    }

    .toggle-menu {
      .toggle-item {
        width: 12.8rem;
        padding: 1.65rem 0 1.6rem;
        font-size: 2.5rem;
      }
    }
  }
}

@media (max-width: 750px) {
  .class-content > select {
    font-size: 3.867vw;
  }
}
