.fileDownLoadLink {
  display: inline-flex;
  align-items: center;
  width: 38.2rem;
  height: 6rem;
  margin-top: 1.5rem;
  padding: 1.9rem 3rem;
  border: 1px solid #dfdfea;
  border-radius: 79rem;
  background: #fff
    url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_download_48x48.png) 94% 50%
    no-repeat;
  background-size: 2.4rem auto;
  box-sizing: border-box;
  cursor: pointer;

  strong {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 3rem;
    font-weight: 700;
    font-size: 1.6rem;
    word-break: break-all;
  }

  span {
    margin-left: 0.9rem;
    padding-top: 0.2rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: #b7b7c8;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 8.4rem;
    margin-top: 3rem;
    padding: 2.1rem 3.8rem;
    border-radius: 120rem;
    background-size: 4rem auto;

    strong {
      padding-right: 5rem;
      font-size: 2.4rem;
    }

    span {
      margin-left: 1.9rem;
      font-size: 2rem;
    }
  }
}
