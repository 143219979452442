.loadingBox {
  position: relative;
  max-width: 120rem;
  margin: 5rem auto 2rem;

  .classTit {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 0;
    box-sizing: border-box;
  }
}

.loader {
  width: 28px;
  margin: 25rem auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, rgb(255, 185, 0) 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, rgb(255, 185, 0));
  mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
