@import './variables.scss';

.container {
  position: fixed;
  max-width: 100vw;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  padding: 228px 23px 90px;
  background: url($HOST_CDN + 'bg-exam-full-loading.png') no-repeat center;
  background-size: cover;
  backdrop-filter: blur(2.27vw);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  &.quiz {
    b {
      color: #ff6f0f;
    }
  }
  &.conversation {
    background: url($HOST_CDN + 'bg-exam-full-loading-conversation.png') no-repeat center;
    background-size: cover;
    b {
      color: #5199ff;
    }
  }
}
.title {
  font-weight: 700;
  letter-spacing: -0.27vw;
  font-size: 46px;
  line-height: 62px;
}
.description {
  margin-top: 24px;
  font-size: 28px;
  line-height: 34px;
  color: #c2c2c2;
  text-align: center;
  font-weight: 700;
}
.loading {
  width: 466px;
  height: 466px;
  margin: 64px auto 0;
  position: relative;
}
.loadingBg {
  width: 466px;
}
.loadingAudrey {
  max-width: initial;
  width: 502.5px;
  top: -123px;
  position: absolute;
  left: 50%;
  transform: translate(-51%, 0);
}

.loadingSpinner {
  color: #fff;
  text-align: center;
  line-height: 62px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 7px;
  img {
    display: inline-block;
    width: 24.27vw;
    height: 24.27vw;
  }

  img {
    width: 182px;
    height: 182px;
  }
}
.loadingText {
  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cancelButton {
  padding: 15px 24px;
  border-radius: 44.002px;
  background: var(--Accent-Red---FF5647, #ff5647);
  color: var(--GrayScale-0---FFFFFF, #fff);
  text-align: center;
  font-size: 32.003px;
  font-weight: 500;
  line-height: 39.998px;
  letter-spacing: -0.503px;
  display: flex;
  align-items: center;
  gap: 9.998px;
  svg {
    width: 32.003px;
  }
}

@media screen and (max-width: 750px) {
  .container {
    max-width: 100vw;
    padding: 30.4vw 3.067vw 12vw;
    backdrop-filter: blur(2.27vw);
  }
  .title {
    letter-spacing: -2.002px;
    font-size: 6.133vw;
    line-height: 8.267vw;
  }
  .description {
    margin-top: 3.2vw;
    font-size: 3.733vw;
    line-height: 4.533vw;
  }
  .loading {
    width: 62.133vw;
    height: 62.133vw;
    margin: 8.533vw auto 0;
  }
  .loadingBg {
    width: 62.133vw;
  }
  .loadingAudrey {
    width: 67vw;
    top: -16.3vw;
  }

  .loadingSpinner {
    line-height: 8.267vw;
    margin-top: 0.933vw;
    img {
      width: 182.002px;
      height: 182.002px;
    }

    img {
      width: 24.267vw;
      height: 24.267vw;
    }
  }
  .loadingText {
    text-shadow: 0vw 0.533vw 1.867vw rgba(0, 0, 0, 0.25);
    font-size: 2.667vw;
  }

  .cancelButton {
    padding: 2vw 3.2vw;
    border-radius: 5.867vw;
    font-size: 4.267vw;
    line-height: 5.333vw;
    letter-spacing: -0.067vw;
    gap: 1.333vw;
    svg {
      width: 4.267vw;
      height: 4.267vw;
    }
  }
}
