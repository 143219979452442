$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
.course-col {
  padding-top: 6rem;

  .product-list:last-child {
    padding-bottom: 12rem;
  }

  .product-tab {
    z-index: 8;
    width: 100%;
    padding: 3rem 0 0;
    background-color: #fff;
    box-sizing: border-box;

    &.active {
      position: fixed;
      top: 9.8rem;
      width: 100%;
      max-width: 100%;
      padding-bottom: 2rem;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
      transition: top 0.3s ease;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      margin-top: 0;
      transition: all 0.6s;
      gap: 12px;
      box-sizing: border-box;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }

      li {
        background-color: #f7f7fc;
        border-radius: 6px;

        &.active {
          background-color: #ffb900;
        }

        button {
          min-width: 10rem;
          padding: 1.4rem 0 1.3rem;
          font-size: 1.6rem;
        }
      }
    }
  }

  .course-title {
    display: block;
    font-weight: 700;
    font-size: 2.5rem;
  }

  .course-desc {
    margin-top: 0.6rem;
    font-weight: 400;
    font-size: 2rem;
    color: #a0a0b6;
  }

  .course-lists {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5rem 3.1rem;
    margin-top: 3rem;
  }

  .inner-col {
    background-color: inherit;
  }

  .level-txt {
    span {
      display: inline-block;
      font-weight: 400;
      font-size: 1.6rem;
      vertical-align: middle;
      color: #b7b7c8;

      &.level {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 2.2rem;
        color: #fc5d6a;
      }

      + span {
        margin-left: 1.4rem;
      }
    }

    + strong {
      display: block;
      font-size: 3.2rem;
      margin-top: 2.2rem;
    }
  }

  .product-list {
    padding: 10rem 0;
    &:nth-of-type(even) {
      background-color: #f7f7fc;
    }
    &:first-child {
      padding-top: 7rem;
    }

    ul {
      li {
        strong {
          padding-right: 5rem;
          font-size: 3.2rem;
          word-break: keep-all;
        }
      }
    }

    h3 {
      font-size: 3.8rem;
      font-weight: 700;
      &::before {
        display: none;
      }

      + p {
        margin-top: 1.4rem;
      }
    }

    p {
      margin-top: 7.6rem;
      font-weight: 700;
      font-size: 2.5rem;
    }

    .contents {
      height: 44.8rem;
      padding: 0;
      border: 1px solid #cfcfda;
      border-bottom: 0;
      border-radius: 0;
      border-top-left-radius: 1.6rem;
      border-top-right-radius: 1.6rem;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 50% bottom;
      overflow: hidden;
      background-color: #fff;

      .txt-area {
        position: relative;
        z-index: 2;
        padding: 3.8rem 3.1rem 0.8rem 3.1rem;
      }

      .course-sub-tit {
        margin-top: 1.4rem;

        p {
          margin-top: 0;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.2rem;
          color: #717188;
        }
      }
    }

    button {
      display: block;
      position: relative;
      width: 100%;
      padding: 2.5rem 3.5rem;
      font-size: 1.6rem;
      font-weight: 700;
      border: 1px solid #cfcfda;
      border-bottom-left-radius: 1.6rem;
      border-bottom-right-radius: 1.6rem;
      text-align: left;
      margin: 0;
      color: #fff;
      background-color: #fc5d6a;

      &:hover {
        background-color: #28284b !important;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 3.1rem;
        margin-top: -1.8rem;
        width: 3.6rem;
        height: 3.6rem;
        background: url('#{$img-cdn}/ico_arrow_right108x108w.png') 50% 50% no-repeat;
        background-size: 100% auto;
      }
    }
  }

  .move2 {
    .level-txt {
      .level {
        color: #ff8148;
      }
    }

    &.product-list,
    &.product-list-slide {
      button {
        background-color: #ff8148;
      }
    }
  }

  .move3 {
    .level-txt {
      .level {
        color: #ffc700;
      }
    }

    &.product-list,
    &.product-list-slide {
      button {
        background-color: #ffc700;
      }
    }
  }

  .move4 {
    .level-txt {
      .level {
        color: #4dcb69;
      }
    }

    &.product-list,
    &.product-list-slide {
      button {
        background-color: #4dcb69;
      }
    }
  }

  .move5 {
    .level-txt {
      .level {
        color: #3cb7f0;
      }
    }

    &.product-list,
    &.product-list-slide {
      button {
        background-color: #3cb7f0;
      }
    }
  }

  .move6 {
    .level-txt {
      .level {
        color: #6574f9;
      }
    }

    &.product-list,
    &.product-list-slide {
      button {
        background-color: #6574f9;
      }
    }
  }

  .move7 {
    .level-txt {
      .level {
        color: #a179f8;
      }
    }

    &.product-list,
    &.product-list-slide {
      button {
        background-color: #a179f8;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .course-col {
    padding-top: 5rem;

    .product-tab {
      padding: 3rem 0;
      border: none;
      &.active {
        top: 18.5rem;
      }

      ul {
        padding: 0 4.2rem;

        .btn-product {
          min-width: 15rem;
          padding: 1.6rem 0;
          font-size: 2.4rem;
        }
      }
    }

    .course-title {
      padding-left: 4.2rem;
      font-size: 3.2rem;
    }

    .course-desc {
      margin-top: 2rem;
      padding-left: 4.2rem;
      font-size: 2.4rem;
    }

    .level-txt {
      span {
        font-size: 2.4rem;

        &.level {
          font-size: 3rem;
        }

        + span {
          margin-left: 1.4rem;
        }
      }

      + strong {
        margin-top: 2.4rem;
      }
    }

    .product-list-slide {
      padding: 0 4.2rem 13rem;
      overflow: hidden;

      &:nth-of-type(even) {
        background-color: #f7f7fc;
      }

      &:last-child {
        padding-bottom: 17rem;
      }

      h3 {
        margin-top: 9rem;
        font-size: 4.4rem;

        &::before {
          display: none;
        }
      }

      p {
        margin-top: 1.4rem;
        font-weight: 700;
        font-size: 2.5rem;
      }

      .mySwiper {
        padding-top: 3rem;
        overflow: visible;
      }

      .contents {
        min-height: 63rem;
        height: auto;
        padding: 0;
        border: 1px solid #cfcfda;
        border-bottom: 0;
        border-radius: 0;
        border-top-left-radius: 1.6rem;
        border-top-right-radius: 1.6rem;

        .txt-area {
          position: relative;
          z-index: 3;
          height: 25rem;
          padding: 4.6rem 4.6rem 1.3rem 4.6rem;
          box-sizing: border-box;
        }

        .course-sub-tit {
          margin-top: 2rem;

          p {
            margin-top: 0;
            font-weight: 400;
            font-size: 2.4rem;
            line-height: 3.2rem;
            color: #717188;
          }
        }

        .course-img {
          display: block;
          min-height: 38rem;
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;

          &::after {
            content: '';
            display: block;
            padding-top: 70%;
          }
        }

        strong {
          padding-right: 5rem;
          font-size: 4.2rem;
          word-break: keep-all;
        }

        p {
          font-size: 2.4rem;
        }
      }

      button {
        display: block;
        position: relative;
        width: 100%;
        margin: 0;
        padding: 2.9rem 4.7rem 2.6rem;
        font-size: 3rem;
        font-weight: 700;
        color: #fff;
        border: 1px solid #cfcfda;
        border-bottom-left-radius: 1.6rem;
        border-bottom-right-radius: 1.6rem;
        background-color: #fc5d6a;
        text-align: left;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 2.5rem;
          width: 7.4rem;
          height: 7.4rem;
          margin-top: -3.7rem;
          background: url('#{$img-cdn}/ico_arrow_right108x108w.png') 50% 50% no-repeat;
          background-size: 100% auto;
        }
      }

      .swiper-container {
        + p {
          margin-top: 13rem;
        }

        .swiper-pagination {
          display: block;
          width: 100%;
          bottom: -5.2rem;
        }

        .swiper-pagination-bullet {
          width: 1.2rem;
          height: 1.2rem;
          margin: 0 0.8rem;
          background-color: #cfcfda;
          opacity: 0.6;
          cursor: pointer;

          &.swiper-pagination-bullet-active {
            width: 4.8rem;
            height: 1.2rem;
            background-color: #cfcfda;
            opacity: 1;
            border-radius: 1.5rem;
          }
        }
      }
    }
  }
}
