.container {
  position: relative;
  width: 440px;
  aspect-ratio: 11/12;
  height: 480px;
  img {
    width: 100%;
  }

  @media all and (max-width: 750px) {
    width: 79.2vw;
    height: 86.4vw;
  }
}
