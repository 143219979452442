$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';

.csWrap {
  box-sizing: border-box;
  padding: 84px 0 0;
  background-color: #fff;

  form,
  input[type='text'] {
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1024px) {
  .csWrap {
    padding: 168px 0 0;
  }
}
@media screen and (max-width: 750px) {
  .csWrap {
    padding: 22.4vw 0 0;
  }
}
