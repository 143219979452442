.notFound {
  background: linear-gradient(180deg, #0e0e1b 8.7%, #181830 91.94%);

  &::before {
    content: '';
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/notFound/bg_star_pc.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 240px;
    box-sizing: border-box;
    z-index: 2;
  }

  hgroup {
    margin-bottom: 40px;
    text-align: center;
  }

  h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: -0.5px;
    margin-top: 0;
    margin-bottom: 6px;
  }

  p {
    color: #a0a0b6;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.5px;
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 33px;
    width: 200px;
    height: 48px;
    border-radius: 4px;
    background-color: #ffb900;
    color: #000;
    font-size: 16px;
    outline: none;
    border: none;
    text-decoration: none;
  }

  .notFoundImg {
    width: 665px;

    img {
      width: 100%;
    }
  }
}

@media all and (max-width: 768px) {
  .notFound {
    &::before {
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/notFound/bg_star_mo.png);
    }

    .inner {
      padding-block: 33.333vw;
      justify-content: flex-start;
    }

    hgroup {
      margin-bottom: 10vh;
    }

    h2 {
      font-size: 5.333vw;
      line-height: 1.2;
      letter-spacing: -0.067vw;
      margin-bottom: 3.733vw;
    }

    p {
      font-size: 3.733vw;
      line-height: 1.2;
    }

    a {
      width: 88.8vw;
      height: 12.8vw;
      font-size: 3.733vw;
      margin-top: auto;
    }

    .notFoundImg {
      width: 100%;
    }
  }
}
