.ShareSection {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    font-size: 44px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-align: center;
  }
  .download {
    text-align: center;
  }
  button {
    margin: 32px auto 0;

    text-align: center;
    height: 96px;
    width: 492px;
    border-radius: 18px;
    font-size: 32px;
    font-weight: 500;
    line-height: 57px;
    img {
      margin-right: 10px;
      width: 56px;
      height: 56px;
    }
  }
}
.quiz {
  background-color: #0a1521;
  color: #fff;
  button {
    border: solid 2px #585d62;
  }
}
.conversation {
  background-color: #eef1f6;
  color: #000;
  button {
    border: solid 2px #ced3d9;
    background-color: #fff;
  }
  margin-bottom: 110px;
}
@media all and (max-width: 750px) {
  .ShareSection {
    padding: 10.6667vw 0;
    h4 {
      font-size: 5.8667vw;
      line-height: 7.4667vw;
      letter-spacing: -0.0667vw;
    }

    button {
      margin: 4.2667vw auto 0;
      height: 12.8vw;
      width: 65.6vw;
      border-radius: 2.4vw;
      font-size: 4.2667vw;
      line-height: 7.6vw;
      img {
        margin-right: 1.3333vw;
        width: 7.467vw;
        height: 7.467vw;
      }
    }
  }
  .conversation {
    margin-bottom: 14.6667vw;
  }
}
