@import 'variable';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.payment-complete-box {
  padding-top: 8rem;
}

.payment-complete-box {
  .deposit-box {
    padding: 9.3rem 0;
    border-top: 0.3rem solid #000;
    border-bottom: 0.1rem solid #b7b7c8;
    text-align: center;

    .ico-check {
      width: 24rem;
      height: 18rem;
      background: url('#{$img-cdn}/ico_check_green.png') no-repeat center / 100% 100%;
    }

    p {
      margin-top: 2.3rem;
      font-weight: 700;
      font-size: 2.5rem;
    }

    span {
      font-size: 1.8rem;
    }

    .info {
      margin: 5.9rem 0 2.3rem;
    }

    dl {
      display: flex;
      justify-content: center;
      font-size: 1.6rem;
      line-height: 1.6;

      dt {
        &::after {
          content: ':';
          display: inline-block;
          margin: 0 0.3rem;
        }
      }
    }
  }

  .order-box {
    position: relative;

    h3 {
      margin: 8rem 0 3.2rem;
    }

    .info {
      border-top: 0.3rem solid #000;
      border-bottom: 0.1rem solid #b7b7c8;
    }

    dl {
      display: flex;
      padding: 2.3rem 2.5rem;
      font-size: 1.6rem;

      + dl {
        border-top: 0.1rem solid #e7e7f0;
      }

      dt {
        flex: 0 0 auto;
        width: 7.3rem;
        margin-right: 15.9rem;
      }

      dd {
        font-weight: 700;
      }
    }

    .app-download {
      position: absolute;
      top: -1.6rem;
      left: 10.5rem;
      padding: 1.6rem 4.3rem 1.4rem 2.3rem;
      font-weight: 700;
      font-size: 1.6rem;
      color: #fff;
      background: #28284b url('#{$img-cdn}/ico_arrow_27x38w.png') no-repeat 95% 50% / 0.8rem auto;
      border-radius: 0.5rem;

      strong {
        font-weight: 700;
        color: #ffb900;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: -2rem;
        width: 0;
        height: 0;
        border-top: 0.6rem solid transparent;
        border-bottom: 0.6rem solid transparent;
        border-right: 2rem solid #28284b;
        transform: translateY(-0.6rem);
      }
    }
  }

  .btn-flex-form {
    width: 49.5rem;
    margin: 6rem auto 9.4rem;

    a {
      width: 12rem;
      padding: 2.2rem 0;
      font-size: 2rem;
      border: 1px solid $gray-500;
      border-radius: 0.5rem;
      text-align: center;

      & + a {
        background-color: $warning;
        border-color: transparent;
      }
    }
  }

  .app-download-mobile {
    display: none;
  }

  .banner-col {
    margin-bottom: 8rem;
  }
}

.modal-content {
  &.app-download {
    padding-top: 10rem;
    // background: url('#{$img-cdn}/bg_app_download.png') no-repeat 50% 100% / 100% auto;
    border-top: 0.1rem solid #dfdfea;
    text-align: center;

    p {
      font-size: 2rem;
      line-height: 1.5;

      strong {
        display: block;
        font-weight: 700;
      }
    }

    .qrcode {
      width: 26.7rem;
      height: 26.7rem;
      margin-top: 3.9rem;
    }

    .description {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 4rem 0;
      background: #28284b;
      font-weight: 300;
      font-size: 2rem;
      line-height: 1.4;
      text-align: center;
      color: #fff;

      strong {
        font-weight: 300;
        color: #ffb900;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 40rem;
      right: 0;
      width: 33.5rem;
      height: 17rem;
      background: url('#{$img-cdn}/img_download_hands.png') no-repeat top center / 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .payment-complete-box {
    padding: 0;

    .deposit-box {
      padding: 16.1rem 0 10rem;
      border: none;

      .ico-check {
        width: 37.3rem;
        height: 28rem;
      }

      p {
        margin-top: 2.7rem;
        font-size: 3.5rem;
      }

      span {
        font-size: 2.7rem;
      }

      .info {
        margin: 6rem 0 2.7rem;
      }

      dl {
        font-size: 2.4rem;
      }
    }

    .order-box {
      display: none;
    }

    .btn-flex-form {
      width: 80%;
      margin: 0 auto 16.1rem;

      a {
        padding: 3.3rem 0;
        font-size: 2.7rem;
      }
    }

    .app-download-mobile {
      display: block;

      dl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 4.2rem;
        background-color: #f7f7fc;

        dt {
          flex: 0 1 auto;
          width: 8rem;
          height: 8rem;
          margin-right: 3.3rem;
        }

        dd {
          display: flex;
          align-items: center;
          flex: 1 0 auto;

          p {
            flex: 1 0 auto;
            font-size: 2.2rem;
            line-height: 1.6;
          }

          span {
            display: block;
            font-weight: 700;
            font-size: 1.8rem;
          }

          button {
            flex: 0 1 auto;
            width: 12.8rem;
            height: 6rem;
            padding: 1.5rem 0 1rem;
            font-weight: 400;
            border: 0.1rem solid #28284b;
            border-radius: 0.5rem;

            span {
              font-size: 2.2rem;
            }
          }
        }
      }
    }

    .banner-col {
      margin-bottom: 0;
    }
  }
}
