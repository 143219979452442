$white: #fff;
$dark: #000;
$warning: #ffb900;
$info: #269cff;
$danger: #ff5647;
$success: #17c624;

$gray-100: #f7f7fc;
$gray-200: #ececf6;
$gray-300: #e7e7f0;
$gray-400: #dfdfea;
$gray-500: #cfcfda;
$gray-600: #b7b7c8;
$gray-700: #a0a0b6;
$gray-800: #717188;
$gray-900: #28284b;

$default: #8d8da0;

$dark-warning: #ffb900;
$dark-gray-100: #e9e9f5;

$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

@mixin button($width, $height, $color, $bg, $size, $radius) {
  position: relative;
  width: $width;
  height: $height;
  color: $color;
  background-color: $bg;
  font-weight: 400;
  font-size: $size;
  border: 1px solid $bg;
  border-radius: $radius;
  cursor: pointer;
  box-sizing: border-box;

  span {
    position: relative;
  }

  &-block {
    display: block;
    width: 100%;
    height: 10rem;
    color: $color;
    background-color: $bg;
    font-size: 2.7rem;
    border-radius: $radius;
    line-height: 2.5rem;
  }

  &-xl {
    display: inline-block;
    width: 49.5rem;
    height: 6.8rem;
    color: $color;
    background-color: $bg;
    font-weight: 700;
    font-size: 2rem;
    border-radius: $radius;
    line-height: 6.5rem;
  }

  &.white,
  &.default {
    background-color: $white;

    &:hover,
    &.active {
      background-color: $gray-100;
      border-color: $gray-500;
    }
  }

  &.disabled {
    color: $white;
    background-color: $gray-500;
    border-color: transparent;
    cursor: default;

    &.active {
      color: $dark;
      background-color: $warning;
      cursor: pointer;
    }
  }

  &.warning {
    background-color: $warning;

    &:hover,
    &.active {
      background-color: $dark-warning;
    }
  }

  &.danger {
    &:hover,
    &.active {
      background-color: $dark-warning;
    }
  }

  &.dark {
    background-color: $gray-900;

    &:hover,
    &.active {
      background-color: $dark;
    }
  }

  &.stroke {
    background-color: $white;
    border-color: $gray-500;

    &:hover,
    &.active {
      background-color: $gray-100;
      border-color: $gray-500;
    }
  }

  &.alpha {
    opacity: 1;

    &:hover,
    &.active {
      opacity: 0.4;
    }
  }
}

@mixin blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

@mixin frm-default {
  dl {
    display: block;
    width: 100%;
    margin-top: 7.3rem;
    font-size: 2.5rem;

    .mark {
      position: relative;

      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        left: -1.5rem;
        color: $danger;
        font-weight: 400;
      }
    }
  }

  dt {
    label {
      font-size: 2.5rem;
    }
  }

  dd {
    input {
      width: 100%;
      margin-top: 0.3rem;
      padding: 2.1rem 2.5rem;
      font-size: 2.9rem;
      border-bottom: 1px solid $gray-300;
      box-sizing: border-box;

      &::placeholder {
        color: $gray-600;
      }
    }
  }
}

@mixin chk-default($width, $height, $mr) {
  display: inline-block;
  position: relative;
  width: $width;
  height: $height;
  margin-right: $mr;
  border-radius: 50%;
  background: $gray-500;
  vertical-align: middle;
  cursor: pointer;
}

@mixin sct-default($width, $height, $pl, $bg, $x, $size, $bd, $radius) {
  display: inline-block;
  position: relative;
  width: $width;
  height: $height;
  padding-left: $pl;
  background: $bg url('#{$img-cdn}/ico_arrow_63x63_b.png') no-repeat;
  background-position-x: $x;
  background-position-y: center;
  background-size: $size;
  border: 1px solid $bd;
  border-radius: $radius;
}

@mixin btn-xl($color, $bg, $radius) {
}

@mixin price {
  &:after {
    content: '원';
  }
}

@mixin item-img-box($width, $height) {
  display: inline-block;
  position: relative;
  width: $width;
  height: $height;
  border-radius: 0.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  &.delivery:after {
    content: '배송상품';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.2rem;
    background-color: $warning;
    font-size: 1.1rem;
    line-height: 2.2rem;
    text-align: center;
  }
}

@mixin item-info-box($size) {
  font-size: $size;

  em,
  span,
  strong {
    display: inline-block;
  }

  em {
    color: $gray-700;

    & + em {
      margin-left: 1.4rem;
    }
  }

  span {
    color: $gray-900;
  }

  strong {
    color: $dark;
    font-size: 2.7rem;
  }
}
