.csSearchForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;

  border-radius: 10px;
  border: 1px solid #000;
  background-color: #fff;
  max-width: 696px;
  margin-top: 22px;
  margin-bottom: 20px;
  padding-left: 24px;
  box-sizing: border-box;

  svg {
    width: 32px;
    height: 32px;
  }

  input[type='text'] {
    flex: 1;
    width: 100%;
    height: 58px;
    flex-shrink: 0;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;

    &::placeholder {
      color: #a0a0b6;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.5px;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
    padding-right: 12px;
  }
}

@media screen and (max-width: 1280px) {
  .csSearchForm {
    margin-top: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .csSearchForm {
    gap: 25px;
    max-width: 100%;
    margin-top: 36px;
    margin-bottom: 24px;
    padding-left: 25px;

    svg {
      width: 40px;
      height: 40px;
    }

    input[type='text'] {
      height: 100px;
      font-size: 28px;
      line-height: 34px;
      &::placeholder {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .csSearchForm {
    gap: 3.333vw;
    margin-top: 4.8vw;
    margin-bottom: 3.2vw;
    padding-left: 3.333vw;
    border-radius: 1.333vw;

    svg {
      width: 5.333vw;
      height: 5.333vw;
    }

    input[type='text'] {
      height: 13.333vw;
      font-size: 3.733vw;
      line-height: 4.533vw;
      &::placeholder {
        font-size: 3.733vw;
      }
    }

    &.reverse {
      padding-right: 1.6vw;
    }
  }
}
