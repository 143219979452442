.loadingBox {
  margin: 0 auto;

  ul {
    display: flex;
    max-width: 120rem;
    flex-wrap: wrap;
    padding: 0 4.2rem;
    margin: 8rem auto 2rem;

    li {
      width: calc(25%);
      padding-left: 2rem;
      padding-bottom: 2rem;
      flex-shrink: 0;
      box-sizing: border-box;

      span {
        margin-bottom: 1rem;
      }

      &:nth-child(4n - 3) {
        padding-left: 0;
      }
    }
  }

  @media all and (max-width: 1024px) {
    ul {
      justify-content: center;
      padding: 0;

      li {
        width: calc(50% - 2rem);
        padding-right: 2rem;

        &:nth-child(4n - 3) {
          padding-left: 2rem;
        }
      }
    }
  }
}
