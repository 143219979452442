$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';

.csSurvey {
  background-color: #fffae3;
  margin-top: 65px;
  .surveyCont {
    padding: 60px 0;
    background-image: url('#{$img-cdn}img_pc_survey.png');
    background-repeat: no-repeat;
    background-position: right 25px;
    background-size: 242px;

    span {
      color: #8d8da0;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
    h4 {
      color: #000;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: -0.5px;
      margin-top: 11px;
    }
    .btnSurvey {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 156px;
      height: 48px;
      flex-shrink: 0;
      border-radius: 44px;
      background: #ffb900;
      color: #000;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 32px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .csSurvey {
    margin-top: 120px;

    .surveyCont {
      padding: 130px 0 50px;
      background-image: url('#{$img-cdn}img_m_survey.png');
      background-position: center 24px;
      background-size: 83px;

      span {
        display: none;
      }
      h4 {
        text-align: center;
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        margin-top: 0;
      }
      .btnSurvey {
        width: 330px;
        height: 72px;
        border-radius: 64px;
        font-size: 24px;
        line-height: 30px;
        margin: 45px auto 0;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .csSurvey {
    margin-top: 16vw;
    .surveyCont {
      padding: 17.333vw 0 6.667vw;
      background-position: center 3.2vw;
      background-size: 11.067vw;

      h4 {
        font-size: 3.733vw;
        line-height: 4.533vw;
      }
      .btnSurvey {
        width: 44vw;
        height: 9.6vw;
        border-radius: 8.533vw;
        font-size: 3.2vw;
        line-height: 4vw;
        margin: 6vw auto 0;
      }
    }
  }
}
