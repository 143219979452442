.audioPlayerBox {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 38.2rem;
  height: 6rem;
  margin-top: 1.5rem;
  padding: 1.8rem 2.4rem 1.8rem 0.8rem;
  border: 1px solid #dfdfea;
  border-radius: 79rem;
  box-sizing: border-box;
  background-color: #fff;

  time {
    font-weight: 400;
    font-size: 1.6rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 8.4rem;
    margin-top: 3rem;
    padding: 2.1rem 4.2rem 2.1rem 1.4rem;
    border-radius: 120rem;

    time {
      flex: 0 0 auto;
      font-size: 2.2rem;
    }
  }
}

.audioButton {
  width: 5.2rem;
  height: 5.2rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 1.6rem auto;

  &.pause {
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pause_216x216g.png);
  }

  &.play {
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_play_216x216g.png);
  }

  @media screen and (max-width: 1024px) {
    flex: 0 0 auto;
    width: 7rem;
    height: 7rem;
    background-size: 2.1rem auto;
  }
}

.progressSlider {
  position: relative;
  width: 23.2rem;
  height: 0.9rem;
  border-radius: 148rem;
  margin: 0 1.3rem 0 0.5rem;
  overflow: hidden;

  progress {
    width: 100%;
    height: 100%;
    appearance: none;
    -webkit-appearance: none;
    vertical-align: 0;

    &::-webkit-progress-bar {
      background-color: #ececf6;
    }

    &::-webkit-progress-value {
      background-color: #ffb900;
    }
  }

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    border-radius: inherit;
    cursor: pointer;

    &[type='range']:focus {
      outline: none;
    }

    &[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: #ffb900;
      cursor: pointer;
      height: 0.9rem;
      width: 0.5rem;
    }
  }

  @media screen and (max-width: 1024px) {
    flex: 1 1 auto;
    height: 1.4rem;
    border-radius: 210rem;

    progress {
      vertical-align: top;
    }
  }
}

.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}
