@import './variable.scss';

.certified-code-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .sub-title {
    width: 100%;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1.6rem;
  }

  input[type='text'] {
    flex: 1 0 auto;
    margin-top: 0.3rem;
    padding: 1.6rem 1.6rem 1.4rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #e7e7f0;
    box-sizing: border-box;
  }

  .btn {
    &.btn-certified-code {
      width: 9.4rem;
      margin-left: 2.2rem;
      padding: 2rem 0;
      font-size: 1.7rem;
      box-sizing: border-box;
    }
  }

  .timer {
    position: absolute;
    top: 50%;
    right: 9.5rem;
    font-size: 1.5rem;
    color: $danger;
  }
}

@media screen and (max-width: 1024px) {
  .certified-code-form {
    .sub-title {
      font-size: 2.5rem;
    }

    input[type='text'] {
      width: 0;
      padding: 3rem 2.7rem;
      font-size: 2.9rem;
    }

    .btn {
      &.btn-certified-code {
        width: 14.6rem;
        padding: 3.1rem 0;
        font-size: 2.7rem;
      }
    }

    .timer {
      //   right: 17rem;
      font-size: 2.3rem;
      right: 22rem;
      top: 63%;
    }
  }
}
