.inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input {
  width: 663px;
  height: 64px;
  padding-left: 20px;
  border-radius: 50px;
  border: 1px solid var(--GrayScale-100---E7E7F0, #e7e7f0);
  background: var(--GrayScale-20---F7F7FC, #f7f7fc);
  box-sizing: border-box;
  color: var(--GrayScale-900---000000, #000);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  caret-color: #ffb900;
}
.input:disabled {
  background-color: #ececf6;
}
.input::placeholder {
  color: var(--GrayScale-600---8D8DA0, #8d8da0);
}
.btnSend {
  width: 52px;
  height: 52px;
  margin-left: 27px;
  /* background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_send.svg');
  background-position: center;
  background-size: cover;
  background-color: #dfdfea;
  background-repeat: no-repeat;
  border-radius: 50%; */
}
.btnSend svg {
  width: 100%;
  height: 100%;
}
.btnSend .btnDisable {
  transition: all 0.3s;
  fill: #dfdfea;
}
.btnSend .btnActive {
  transition: all 0.3s;
  fill: #28284b;
}

.feedList {
  padding: 0 0 40px 0;
}
.feedList li.User,
.feedList li.Error {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.feedItem {
  margin-bottom: 8px;
}
.feedItem:last-child {
  margin-bottom: 0;
}
.feedItem.User {
  border: 0;
}

/* message */
.aiMessage {
  display: grid;
  grid-template-columns: 38px 1fr;
  gap: 10px;
}
.aiMessage dl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.msgName {
  color: var(--GrayScale-500---A0A0B6, #a0a0b6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.message {
  display: inline-flex;
  padding: 18px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 24px;
  background: var(--GrayScale-50---ECECF6, #ececf6);
  color: var(--GrayScale-900---000000, #000);
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px 0 0 6px;
  max-width: 358px;
  position: relative;
  white-space: wrap;
  word-break: break-word;
  word-wrap: break-word;
  min-width: 115px;
  box-sizing: border-box;
}
.aiMessage .message::before {
  display: block;
  width: 14px;
  height: 27px;
  position: absolute;
  left: -6px;
  top: 7px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ai_msg_tail.svg') center
    no-repeat;
  background-size: 14px 27px;
  content: '';
}
.lottieBox {
  width: 32px;
  height: 18px;
}
.lottieBox > div {
  width: 100%;
  height: 100%;
}
.lottieBox svg {
  vertical-align: top;
}
.userMessage {
  border-radius: 24px;
  background: var(--Primary-Starlight-Yellow---FFB900, #ffb900);
}
.errorMessage {
  border-radius: 24px;
  background-color: #f7f7fc;
  color: #717188 !important;
  white-space: pre-line;
}
.userMessage::after,
.errorMessage::after {
  display: block;
  width: 14px;
  height: 27px;
  position: absolute;
  right: -6px;
  top: 7px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/user_msg_tail.svg')
    center no-repeat;
  background-size: 14px 27px;
  content: '';
}
.errorMessage::after {
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/error_msg_tail.svg')
    center no-repeat;
}

/* ETD-3489 last update */
.translateText {
  display: inline-flex;
  max-width: 60%;
  padding: 14px 22px;
  border: 1px solid #dfdfea;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #28284b;
  margin-top: 10px;
  margin-left: 6px;
  background-color: #fff;
  transition: all 0.3s;
}

/* added to audio constrols */
.btnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .input {
    width: 610px;
    height: 80px;
    padding-left: 20px;
    border-radius: 50px;
    font-size: 28px;
    font-weight: 400;
    line-height: 38px;
  }
  .btnSend {
    width: 66px;
    height: 66px;
    margin-left: 10px;
  }
  .feedList {
    padding: 0 0 58px 0;
  }
  .aiMessage {
    grid-template-columns: 56px 1fr;
    gap: 22px;
  }
  .msgName {
    color: var(--GrayScale-500---A0A0B6, #a0a0b6);
    font-size: 21px;
    font-weight: 400;
    line-height: 38px;
  }
  .message {
    color: var(--GrayScale-900---000000, #000);
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    margin: 5px 0 0 0;
    padding: 26px;
    border-radius: 32px;
    max-width: 490px;
  }
  .lottieBox {
    width: 42px;
    height: 24px;
  }
  .translateText {
    padding: 24px 26px;
    border-radius: 20px;
    font-size: 22px;
    line-height: 34px;
    margin-left: 0;
  }
}
@media screen and (max-width: 750px) {
  .input {
    width: 81.333vw;
    height: 10.667vw;
    padding-left: 2.667vw;
    border-radius: 6.667vw;
    font-size: 3.733vw;
    line-height: 5.067vw;
  }
  .btnSend {
    width: 8.8vw;
    height: 8.8vw;
    margin-left: 1.333vw;
  }
  .feedList {
    padding: 0 0 7.733vw 0;
  }
  .aiMessage {
    grid-template-columns: 7.467vw 1fr;
    gap: 2.933vw;
  }
  .msgName {
    color: var(--GrayScale-500---A0A0B6, #a0a0b6);
    font-size: 2.8vw;
    font-weight: 400;
    line-height: 5.067vw;
  }
  .message {
    color: var(--GrayScale-900---000000, #000);
    font-size: 3.2vw;
    font-weight: 400;
    line-height: 4vw;
    margin: 0.667vw 0 0 0;
    padding: 3.467vw;
    border-radius: 4.267vw;
    max-width: 70%;
  }
  .aiMessage .message::before {
    width: 1.867vw;
    height: 3.6vw;
    left: -0.8vw;
    top: 0.933vw;
    background-size: 1.867vw 3.6vw;
  }
  .userMessage::after,
  .errorMessage::after {
    width: 1.867vw;
    height: 3.6vw;
    right: -0.8vw;
    top: 0.933vw;
    background-size: 1.867vw 3.6vw;
  }
  .lottieBox {
    width: 5.6vw;
    height: 3.2vw;
  }
  .translateText {
    padding: 3.2vw 3.467vw;
    border-radius: 2.667vw;
    font-size: 2.933vw;
    line-height: 4.533vw;
    margin-top: 1.333vw;
  }
}
