.loadingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  div {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: rgb(255, 185, 0);

    & + div {
      margin-left: 0.5rem;
    }
  }

  .lineOne {
    animation: loadingAnimation 0.6s 0.1s linear infinite;
  }

  .lineTwo {
    animation: loadingAnimation 0.6s 0.2s linear infinite;
  }

  .lineThree {
    animation: loadingAnimation 0.6s 0.3s linear infinite;
  }
}

@keyframes loadingAnimation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 1.5rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 1024px) {
  .loadingBox {
    div {
      width: 2rem;
      height: 2rem;

      & + div {
        margin-left: 1rem;
      }
    }
  }
}
