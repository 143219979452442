.csTabNav {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #000;
    content: '';
  }

  .csTabBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    box-sizing: border-box;

    border-radius: 10px 10px 0 0;
    border: 1px solid #ececf6;
    background: #f7f7fc;

    color: #a0a0b6;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1px;

    &.csTabActive {
      background-color: #fff;
      border-color: #000;

      color: #000;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1024px) {
  .csTabNav {
    margin-top: 30px;

    .csTabBtn {
      height: 80px;
      font-size: 28px;
      line-height: 34px;
    }
  }
}

@media screen and (max-width: 750px) {
  .csTabNav {
    margin-top: 4vw;

    .csTabBtn {
      height: 10.667vw;
      font-size: 3.733vw;
      line-height: 4.533vw;
    }
  }
}
