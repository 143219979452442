.reviewRating {
  padding: 32px 0;
  background-color: #fff;
  margin-bottom: 8px;
  h3 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  p {
    font-size: 14px;
    text-align: center;
    color: #b7b7c8;
  }
  .ratingBox {
    display: flex;
    flex-direction: row;
    max-width: 250px;
    margin: 0 auto 8px;
    background-color: #ececf6;
    position: relative;
    overflow: hidden;

    &::before {
      width: 0;
      height: 100%;
      background-color: #ffb900;
      position: absolute;
      z-index: 1;
      transition: all 0.3s;
      content: '';
    }

    li {
      flex: 1;
      z-index: 2;
    }
  }
  .point1::before {
    width: 20%;
  }
  .point2::before {
    width: 40%;
  }
  .point3::before {
    width: 60%;
  }
  .point4::before {
    width: 80%;
  }
  .point5::before {
    width: 100%;
  }
}
