.banner-col {
  .banner-tit {
    display: block;
    font-weight: 700;
    font-size: 2rem;

    & + .banner-slide {
      margin-top: 2rem;
    }
  }

  .banner-slide {
    .swiper-container {
      padding-bottom: 2rem;

      .swiper-pagination {
        display: block;
        width: 100%;
        bottom: 0;
      }

      .swiper-pagination-bullet {
        width: 0.6rem;
        height: 0.6rem;
        margin: 0 0.5rem;
        background-color: #000;
      }
    }
  }

  .slide-item{
    display: block;

    img{
      display: block;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1024px) {

}