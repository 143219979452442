.styledLink {
  display: block;
  width: 27rem;
  margin: 7rem auto 0;
  padding: 1.9rem 0;
  font-size: 2rem;
  color: #000;
  border: 1px solid #cfcfda;
  border-radius: 5px;
  text-align: center;

  @media (max-width: 1024px) {
    width: 32.4rem;
    margin: 5rem auto 0;
    padding: 2.8rem 0;
    font-size: 2.7rem;
  }
}

.detailBoardBox {
  padding: 8rem 0 12rem;
  background-color: #f7f7fc;

  .pagination {
    padding: 4rem 0;
  }

  @media (max-width: 1024px) {
    padding: 10rem 4.2rem;

    h3 {
      display: none;
    }

    .pagination {
      padding: 8rem 0;
    }
  }
}

.boardListReviewBox {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 49.9rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  overflow: hidden;

  & + div {
    border-top: none;
  }

  @media (max-width: 1024px) {
    box-shadow: 0.1rem 0.1rem 2.4rem rgba(0, 0, 0, 0.1);
  }
}

.cardInner {
  position: relative;
  max-width: 866px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 0;
  }
}
