.paymentTypeBox {
  padding-top: 8rem;

  h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }

  @media (max-width: 1024px) {
    padding: 5rem 4.2rem;
    border-top: 1.2rem solid #ececf6;
  }
}

.paymentTypeList {
  width: 100%;
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 0.3rem solid #000;

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    li {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      width: var(--list-width);
      height: 9.5rem;
      border-top: var(--border-top);
      border-right: 0.1rem solid #cfcfda;
      border-bottom: 0.1rem solid #cfcfda;
      box-sizing: border-box;
      flex-shrink: 0;
      flex-basis: var(--flex-basis);

      &:nth-child(1),
      &:nth-child(2) {
        border-top: 0.1rem solid #cfcfda;
      }

      &:nth-child(3) {
        border-top: 0.1rem solid #cfcfda;
        @media (max-width: 1024px) {
          border-top: 0;
        }
      }

      &:nth-child(3n + 1) {
        border-left: var(--border-left);
        @media (max-width: 1024px) {
          border-left: 0;
        }
      }

      &.mobileNone {
        display: none;
      }

      &.itemOne {
        justify-content: center;
        border: 0;
        border-left: 0 !important;

        label {
          width: 49.5rem;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: auto;
          border: 0.1rem solid #cfcfda;
          box-sizing: border-box;
        }

        @media (max-width: 1024px) {
          width: 100%;
          border-top: 0 !important;
          label {
            width: 100%;
          }
        }
      }

      &:first-child {
        border-left: 0.1rem solid #cfcfda;
      }

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin: 0;
        padding: 0;
        font-size: 2rem;
        color: #000;
        height: 100%;
        cursor: pointer;

        strong {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          font-weight: 400;
          box-sizing: border-box;
          border: 0.2rem solid transparent;
          transition: 0.15s;
        }

        input:checked + strong {
          background-color: #ffefc8 !important;
          border: 0.2rem solid #ffb900 !important;
        }

        em {
          display: block;
          width: 100%;
          height: 100%;
          text-indent: -9999rem;

          &.toss {
            background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pay_toss_246x102.png)
              no-repeat 50% 50% / 9.7rem auto;
            @media (max-width: 1024px) {
              background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pay_toss_246x102.png)
                no-repeat 50% 50% / 12rem auto;
            }
          }

          &.naver {
            background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pay_naver_246x102.png)
              no-repeat 50% 50% / 9.7rem auto;
            @media (max-width: 1024px) {
              background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pay_naver_246x102.png)
                no-repeat 50% 50% / 13rem auto;
            }
          }

          &.kakaopay {
            background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pay_kakao_170x60.png)
              no-repeat 50% 50% / 8rem auto;
            @media (max-width: 1024px) {
              background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pay_kakao_170x60.png)
                no-repeat 50% 50% / 12rem auto;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-top: 3.7rem;
    padding-top: 0;
    border-top: 0;

    ul {
      flex-wrap: wrap;
      li {
        flex-basis: auto;
        flex-wrap: wrap;
        flex-grow: inherit;
        width: 50%;
        height: 13.6rem;
        border-top: 0;

        &:first-child,
        &:nth-child(2) {
          border-top: 0.1rem solid #cfcfda;
        }

        &:nth-child(2n + 1) {
          border-left: 0.1rem solid #cfcfda;
        }

        label {
          font-size: 2.8rem;
        }
      }
    }
  }
}
