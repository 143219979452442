.inputPhoneWrap {
  width: 100%;
  position: relative;

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 80px;
    font-size: 18px;
    font-weight: 400;
    color: #b7b7c8;
  }

  &.bottomFixed {
    .placeholder {
      padding: 0;
      top: 0;
      left: 45px;
      font-size: 14px;
    }
  }

  &.modal {
    .placeholder {
      padding: 0;
      top: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  .inputPhoneWrap {
    &.modal {
      .placeholder {
        font-size: 2.4vw;
        left: 7.333vw;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .inputPhoneWrap {
    .placeholder {
      top: 0.533vw;
      left: 10.667vw;
      font-size: 3.6vw;
    }
  }
}
