.csCategory {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  padding: 30px 0 0 0;

  .csCateBtn {
    display: flex;
    height: 38px;
    padding: 0 14px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: #ececf6;

    color: #28284b;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.5px;
    white-space: nowrap;

    span {
      margin-top: 2px;
    }

    &.csCateActive {
      background: #28284b;
      color: #fff;
    }
  }
}
@media screen and (max-width: 1024px) {
  .csCategory {
    gap: 20px 8px;
    margin: 0 -40px;
    padding: 50px 40px 0;

    .csCateBtn {
      height: 60px;
      padding: 0 24px;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.48px;
    }
  }
}
@media screen and (max-width: 750px) {
  .csCategory {
    gap: 2.667vw 1.067vw;
    margin: 0;
    padding: 6.667vw 5.333vw 0;

    .csCateBtn {
      height: 8vw;
      padding: 0 3.2vw;
      font-size: 3.2vw;
      line-height: 4vw;
      letter-spacing: -0.064vw;
    }
  }
}
