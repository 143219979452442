@import './variables.scss';

.container {
  padding: 0 42px 0;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    height: 247px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &.quiz {
    &::before {
      background: linear-gradient(180deg, #ffead7 0%, #fff 100%);
    }
    .title {
      b {
        color: #ff6f0f;
      }
    }
  }
  &.conversation {
    &::before {
      background: linear-gradient(180deg, #d7e8ff 0%, #fff 100%);
    }
    .title {
      b {
        color: #5199ff;
      }
    }
  }
}
.topImage {
  position: relative;
  z-index: 2;
  width: 554px;
}
.title {
  color: #161c22;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px;
  margin-top: 26px;
}
.conversation {
  form label input[type='checkbox']:checked + em {
    background-color: #5199ff;
    border-color: #5199ff;
    background-image: url($HOST_CDN + 'icon-analyzing-check-conversation.png');
  }
  .submitButton {
    border: 1.943px solid;
    border-image-source: linear-gradient(
      129.07deg,
      rgba(255, 255, 255, 0.3) 28.34%,
      rgba(255, 255, 255, 0) 71.09%
    );
    border-image-slice: 1;
    background: linear-gradient(100deg, #5199ff 2.72%, #1d4de8 95.29%);
  }
}
.agree {
  display: flex;
  flex-direction: column;
  gap: 22px;
  max-width: 480px;
  margin: 52px auto 0;

  li {
    &:first-of-type {
      label {
        font-weight: 700;
        font-size: 32.003px;
        input[type='checkbox'] + em {
          width: 36px;
          height: 36px;
          margin-right: 8.002px;
        }
      }
    }
    &:nth-of-type(2) {
      border-top: 1px solid #dfdfea;
      padding-top: 20px;
    }
  }

  label {
    display: block;
    text-align: left;
    cursor: pointer;
    color: #161c22;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;

    input[type='checkbox'] {
      width: 0;
      padding: 0;
      margin: 0;
      border: 0;

      + em {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #cfcfd8;
        background: none;
      }

      &:checked + em {
        background-color: #ff6f0f;
        border-color: #ff6f0f;
        background-image: url($HOST_CDN + 'icon-analyzing-check.png');
        background-size: cover;
      }
    }

    button {
      color: #a0a0b6;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.5px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      margin-left: 17px;
    }
  }
}
.submitButton {
  margin-top: 80px;
  border-radius: 18px;
  border: 1.94px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(100deg, #ff7214 2.72%, #ff3c00 95.29%);
  width: 100%;
  height: 122px;
  color: var(--GrayScale-0---FFFFFF, #fff);
  text-align: center;
  box-sizing: border-box;
  p {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }
  em {
    margin-top: 4px;
    display: block;
    opacity: 0.6;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }
}
.textButton {
  margin: 36px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #161c22;
  font-weight: 500;
  svg {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding: 0 5.6vw 0;
    &::before {
      height: 32.933vw;
    }
  }
  .conversation {
    .submitButton {
      border: 0.5vw solid;
      border-image-source: linear-gradient(
        129.07deg,
        rgba(255, 255, 255, 0.3) 28.34%,
        rgba(255, 255, 255, 0) 71.09%
      );

      border-image-slice: 1;
    }
  }
  .topImage {
    width: 73.867vw;
  }
  .title {
    font-size: 5.333vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
    margin-top: 3.467vw;
  }

  .agree {
    gap: 2.933vw;
    max-width: 64vw;
    margin: 6.933vw auto 0;

    li {
      &:first-of-type {
        label {
          font-weight: 700;
          font-size: 4.267vw;
          input[type='checkbox'] + em {
            width: 4.8vw;
            height: 4.8vw;
            margin-right: 1.067vw;
          }
        }
      }
      &:nth-of-type(2) {
        border-top: 0.133vw solid #dfdfea;
        padding-top: 2.667vw;
      }
    }
    label {
      font-size: 3.2vw;
      line-height: 4vw;
      letter-spacing: -0.067vw;

      input[type='checkbox'] {
        + em {
          width: 4.267vw;
          height: 4.267vw;
          margin-right: 1.6vw;
          border: 0.267vw solid #cfcfd8;
        }
      }

      button {
        font-size: 3.2vw;
        line-height: 4vw;
        letter-spacing: -0.067vw;
        margin-left: 2.267vw;
      }
    }
  }
  .submitButton {
    margin-top: 10.667vw;
    border-radius: 2.4vw;
    height: 16.267vw;
    border: 0.259vw solid rgba(255, 255, 255, 0.3);
    p {
      font-size: 4.8vw;
      line-height: 5.867vw;
    }
    em {
      margin-top: 0.533vw;
      font-size: 2.667vw;
      line-height: 3.467vw;
    }
  }
  .textButton {
    margin: 4.8vw auto 0;
    font-size: 3.2vw;
    svg {
      width: 2.667vw;
      height: 2.667vw;
    }
  }
}
