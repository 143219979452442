@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.login-wrap {
  padding: 4.6rem 0;
  background-color: #f7f7fc;

  .inner {
    display: flex;
    flex-direction: column;
    width: 48rem;
    margin: 0 auto;
    padding: 7rem 3.2rem 4rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 0 0.7rem rgb(0 0 0 / 7%);
    box-sizing: border-box;

    .login-select-box {
      margin-top: 17rem;
    }

    .option-box {
      padding: 0;
    }
  }

  &.frameless {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    .inner {
      box-shadow: none;
    }
  }
}

.login-box {
  .login-input {
    + .login-input {
      margin-top: 2.4rem;
    }
  }

  label {
    display: block;
  }

  input {
    width: 100%;
    padding: 2.4rem 1.6rem 1.3rem;
    font-size: 1.9rem;
    color: $dark;
    border-bottom: 1px solid #e5e5e5;
    box-sizing: border-box;

    &::placeholder {
      color: #b7b7c8;
    }
  }

  .btn-login {
    margin-top: 4rem;

    span {
      font-weight: 700;
    }
  }
}

.social-login {
  .no-content {
    padding: 23em 0;
  }
}

.google-captcha-box {
  width: 30.4rem;
  margin: 3rem auto 0;
}

.simple-captcha-box {
  margin-top: 4rem;

  .captcha-info {
    font-size: 1.6rem;
    border: 1px solid #ccc;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      #canv {
        // border: 1px solid #ccc;
        flex: 0 1 auto;
      }

      div {
        flex: 0 1 auto;
        // width: 25%;
        padding: 2rem 5rem;
        border-left: 1px solid #ccc;
        text-align: center;

        #reload_href {
          display: block;

          &::before {
            content: '';
            display: inline-block;
            width: 2.8rem;
            height: 2.8rem;
            background: url('#{$img-cdn}/ico_refresh_84x84.png') no-repeat 20% 60% / 100%;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .captcha-value {
    margin-top: 2rem;

    .captcha-input {
      width: 100%;
      padding: 1.6rem 1.3rem;
      font-size: 1.9rem;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;

      &::placeholder {
        color: #b7b7c8;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .login-wrap {
    background-color: #fff;

    .inner {
      width: 100%;
      padding: 10rem 4.2rem;
      box-shadow: initial;
      box-sizing: border-box;

      .login-select-box {
        margin-top: 27rem;
      }

      .option-box {
        padding: 0;

        label {
          .chk {
            width: 5rem;
            height: 5rem;

            &::before {
              width: 2.3rem;
              height: 2.1rem;
            }
          }
        }
      }

      .btn-flex-form {
        font-size: 2.6rem;
      }
    }
  }

  .login-box {
    .login-input {
      + .login-input {
        margin-top: 2.5rem;
      }
    }

    input {
      padding: 4.7rem 2.6rem 2.5rem;
      font-size: 2.9rem;
    }

    .btn-login {
      margin-top: 6.6rem;
    }
  }
  .google-captcha-box {
    width: 60.8rem;
    margin: 5rem auto 0;
  }

  .simple-captcha-box {
    margin-top: 4rem;

    .captcha-info {
      font-size: 2.5rem;

      > div {
        div {
          padding: 5rem;

          #reload_href {
            &::before {
              width: 4.4rem;
              height: 4.4rem;
            }
          }
        }
      }
    }
    .captcha-value {
      .captcha-input {
        padding: 2.5rem 2.6rem;
        font-size: 2.9rem;
      }
    }
  }
}
