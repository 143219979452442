.buttons {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 2.5rem;

  &.listType {
    border-top: 1px solid #ececf6;
    padding: 2.2rem 3.5rem 2.5rem;
  }

  .like {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    box-sizing: border-box;

    .heart {
      position: relative;
      width: 2.4rem;
      height: 2.4rem;
    }

    .likeAnimation {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: -1.4rem;
      left: -1.4rem;
      width: 5.2rem;
      height: 5.2rem;
    }

    &.isLiked {
      p {
        color: #ff5647;
      }
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    > p {
      font-weight: 500;
      font-size: 1.6rem;
      color: #a0a0b6;
      min-width: 1ch;
    }
  }

  .share {
    height: 2.4rem;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .comment {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    height: 2.4rem;

    p {
      color: #a0a0b6;
      font-size: 1.6rem;
      font-weight: 500;
      min-width: 1ch;
    }
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .date {
    color: #a0a0b6;
    font-size: 1.6rem;
    font-weight: 400;
    margin-left: auto;
  }

  @media (max-width: 1024px) {
    margin-top: 0;
    gap: 3.5rem;

    &.listType {
      padding: 2.5rem 4rem 3.2rem;
    }

    .like {
      gap: 0.4rem;

      .heart {
        width: 4.2rem;
        height: 4.2rem;
      }

      .likeAnimation {
        width: 4.2rem;
        height: 4.2rem;
        top: -2.2rem;
        left: -2.2rem;
        width: 8.6rem;
        height: 8.6rem;
      }

      svg {
        width: 4.2rem;
        height: 4.2rem;
      }

      > p {
        font-size: 2.6rem;
      }
    }

    .share {
      height: 4.2rem;
      svg {
        width: 4.2rem;
        height: 4.2rem;
      }
    }

    .comment {
      gap: 0.4rem;
      height: 4.2rem;

      p {
        font-size: 2.4rem;
        min-width: 1ch;
      }
      svg {
        width: 4.2rem;
        height: 4.2rem;
      }
    }

    .date {
      font-size: 2.4rem;
    }
  }
}
