:global body.fixed #root {
  padding-top: 0 !important;
}

.main {
  background-color: #fff;
  padding: 64px 0;
  min-height: 100vh;
  box-sizing: border-box;
}

.inner {
  width: 444px;
  margin-left: auto;
  margin-right: auto;
}

.hgroup {
  position: relative;
  text-align: center;
  margin-bottom: 73px;

  h3 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
  }
  button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.section {
  h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
  }
}

.article {
  border-top: 2px solid #000;
  border-bottom: 1px solid #b7b7c8;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  li {
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid #e7e7f0;
    }
  }
}

.caption {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding: 15px 0;
  border-bottom: 1px solid #b7b7c8;
}

.th {
  width: 85px;
  padding-left: 17px;
  display: flex;
  align-items: center;
  color: #717188;
  font-size: 16px;
  font-weight: 400;
}

.td {
  flex: 1;
  padding: 20px 0;
}

.param01 {
  color: #a0a0b6;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 12px;
  word-break: keep-all;
}

.param02 {
  color: #28284b;
  font-size: 18px;
  font-weight: 700;
  line-height: 122%;
  display: flex;
  align-items: flex-end;
  word-break: keep-all;
  line-height: 140%;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
  u {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

.param03 {
  color: #28284b;
  font-size: 18px;
  font-weight: 400;
  line-height: 122%;
  word-break: keep-all;
  line-height: 140%;

  u {
    text-decoration: none;
  }
}

.param04 {
  color: #717188;
  font-size: 16px;
  line-height: 137%;
  word-break: keep-all;
}

.completeSentence,
.basic {
  .param02 {
    margin-bottom: 4px;
  }
}

.video,
.conversation {
  > p {
    display: flex;
    align-items: flex-start;
    > span {
      &:first-child {
        width: 30px;
      }
      &:last-child {
        flex: 1;
      }
    }
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}

.videoCompleteSentence {
  .param03 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 6px;

    > span {
      &:first-child {
        width: 30px;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
  .param02 {
    margin-left: 26px;
    margin-bottom: 0;
  }
}

.choice {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 13px;

  &.block {
    grid-template-columns: repeat(1, 1fr);
  }

  p {
    color: #28284b;
    font-size: 18px;
    position: relative;
    padding-left: 37px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/levelTestAdvanced/2024/ico_check.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &.checked {
      &::before {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/levelTestAdvanced/2024/ico_check-active.svg);
      }
    }
  }
}

.answer {
  display: flex;
  align-items: center;
  gap: 0 22px;

  button {
    border-radius: 8px;
    border: 1px solid #dfdfea;
    width: 90px;
    height: 42px;
    display: block;
    color: #717188;
    font-size: 16px;
  }
}

@media all and (max-width: 750px) {
  .main {
    padding-top: 5.067vw;
    padding-left: 5.6vw;
    padding-right: 5.6vw;
  }
  .inner {
    width: 100%;
  }
  .hgroup {
    margin-bottom: 14.667vw;
    h3 {
      font-size: 4.267vw;
    }
    button {
      width: 5.333vw;
    }
  }

  .section {
    h3 {
      font-size: 4.267vw;
      margin-bottom: 2.533vw;
    }
  }

  .article {
    border-top-width: 0.4vw;
    &:not(:last-child) {
      margin-bottom: 6.4vw;
    }
  }

  .caption {
    font-size: 4vw;
    padding: 2.933vw 0;
  }

  .th {
    width: 16.933vw;
    font-size: 3.2vw;
  }

  .td {
    padding: 4.267vw 0;
  }

  .param01 {
    font-size: 3.2vw;
    margin-bottom: 2.4vw;
  }
  .param02 {
    font-size: 3.733vw;
    margin-bottom: 1.6vw;
  }
  .param03 {
    font-size: 3.733vw;
  }
  .param04 {
    font-size: 3.2vw;
  }

  .completeSentence,
  .basic {
    .param02 {
      margin-bottom: 0.8vw;
    }
  }

  .video,
  .conversation {
    > p {
      > span {
        &:first-child {
          width: 6vw;
        }
        &:last-child {
          flex: 1;
        }
      }
      &:not(:last-child) {
        margin-bottom: 1.067vw;
      }
    }
  }

  .videoCompleteSentence {
    .param03 {
      margin-bottom: 1.067vw;

      > span {
        &:first-child {
          width: 6vw;
        }
      }
    }
    .param02 {
      margin-left: 5.333vw;
    }
  }

  .choice {
    gap: 2.133vw;
    p {
      padding-left: 7.467vw;
      font-size: 3.733vw;
    }
    p::before {
      width: 5.333vw;
      height: 5.333vw;
    }
  }
  .answer {
    gap: 0 2vw;
    button {
      width: 18.133vw;
      height: 8.533vw;
      border-radius: 1.6vw;
      font-size: 3.2vw;
    }
  }
  .marker {
    img {
      width: 5.333vw;
    }
  }
}
