@import 'variable';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.notice-col {
  .notice-table-col {
    padding: 8rem 0 12rem;
  }

  h3 {
    font-weight: 700;
    font-size: 2rem;
  }

  .pagination {
    padding-top: 6rem;
  }

  .detail-question {
    table,
    th,
    tr,
    td {
      border: 1px solid $gray-500;
    }

    table {
      max-width: 100%;
      width: 100% !important;
    }

    th {
      background-color: $gray-100;
    }

    th,
    td {
      padding: 1.6rem 1.5rem 1.1rem;
      word-break: break-all;
    }
  }
}

.select-box-col {
  margin: 3rem 0 7rem;

  select {
    display: inline-block;
    width: 13.8rem;
    padding: 0.7rem 1.5rem 0.6rem;
    font-weight: 400;
    font-size: 1.4rem;
    border: 0.1rem solid #dfdfea;
    background: url('#{$img-cdn}/ico_check_36x21.png') 91% 50% / 1.2rem auto no-repeat;

    & + select {
      margin-left: 1rem;
    }
  }
}

.terms-col .select-box-col,
.policy-col .select-box-col {
  select {
    width: 33rem;
    background-position: 96% 50%;
  }
}

.service-content {
  padding: 0 2.8rem 8rem;
  font-size: 1.6rem;
  h2 {
    display: block;
    padding: 5rem 0 0.5rem;
    font-weight: 700;
    font-size: 1.8rem;
    text-align: left;
  }
  h3 {
    display: block;
    padding: 1rem 0 0.8rem;
    font-weight: 700;
    font-size: 1.6rem;
    text-align: left;
  }
  h4 {
    display: block;
    padding: 1rem 0 0.8rem;
    font-weight: 400;
    font-size: 1.6rem;
    text-align: left;
  }
  ul {
    padding: 0.5rem 0;
    li {
      padding: 0.5rem 1rem;
      font-size: 1.6rem;
      line-height: 1.4;
      ul {
        li {
          padding: 0.2rem 2rem;
          font-size: 1.6rem;
        }
      }
    }
  }
}
.policy-content {
  padding: 3rem 2.8rem 8rem;
  p {
    padding: 0.5rem 0;
    font-size: 1.6rem;
    line-height: 1.4;
  }
  table {
    width: 100%;
    max-width: 100rem;
    margin: 3rem 0;
    tr {
      th {
        padding: 1.5rem 1rem;
        font-size: 1.6rem;
        color: #3a3a3a;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        &:last-child {
          border-right: 1px solid #ccc;
        }
      }
      td {
        padding: 1.5rem 1rem;
        font-size: 1.6rem;
        line-height: 1.4;
        color: #3a3a3a;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        em {
          color: #ff5647;
        }
        &:last-child {
          border-right: 1px solid #ccc;
        }
        &.line-bottom {
          border-bottom: 1px solid #ccc;
        }
      }
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .notice-col {
    .notice-table-col {
      padding: 0;
    }

    h3 {
      display: none;
    }

    .pagination {
      padding: 8rem 0 15rem;
    }
  }

  .select-box-col {
    margin: 3rem 0 7rem;
    padding: 0 4.3rem;
    select {
      width: 100%;
      margin: 0 auto;
      padding: 2.7rem;
      font-size: 2.9rem;
      box-sizing: border-box;
      background-size: 2rem auto;
      background-position: 97% 50%;
    }
  }

  .terms-col .select-box-col,
  .policy-col .select-box-col {
    select {
      width: 100%;
      background-position: 98% 50%;
    }
  }

  .service-content {
    padding: 0 2.8rem 6rem;
    font-size: 2.8rem;
    h2 {
      display: block;
      padding: 5rem 0 0.5rem;
      font-weight: 700;
      font-size: 3rem;
      text-align: left;
    }
    h3 {
      display: block;
      padding: 1rem 0 0.8rem;
      font-weight: 700;
      font-size: 2.8rem;
      text-align: left;
    }
    h4 {
      display: block;
      padding: 1rem 0 0.8rem;
      font-weight: 400;
      font-size: 2.8rem;
      text-align: left;
    }
    ul {
      padding: 0.5rem 0;
      li {
        padding: 0.5rem 1rem;
        font-size: 2.8rem;
        line-height: 1.4;
        ul {
          li {
            padding: 0.2rem 2rem;
            font-size: 2.6rem;
          }
        }
      }
    }
  }
  .policy-content {
    padding: 6rem 4.8rem;
    p {
      padding: 0.2rem 0;
      font-size: 2.6rem;
    }
    table {
      width: 100%;
      max-width: 160rem;
      margin: 5rem 0;
      tr {
        th {
          padding: 2.5rem 2rem;
          font-size: 2.6rem;
          color: #3a3a3a;
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          &:last-child {
            border-right: 1px solid #ccc;
          }
        }
        td {
          padding: 2.5rem 2rem;
          font-size: 2.4rem;
          line-height: 1.4;
          color: #3a3a3a;
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          em {
            color: #ff5647;
          }
          &:last-child {
            border-right: 1px solid #ccc;
          }
          &.line-bottom {
            border-bottom: 1px solid #ccc;
          }
        }
        &:last-child {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}
