.recommendCore {
  background-color: #fff;
  padding: 120px 0;
  .subTitle {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    text-align: center;
  }
  .title {
    font-size: 44px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 16px;
  }
  .productList {
    margin-top: 56px;
    margin: 0 105px;
    .whiteText {
      color: #fff;
    }
    .product {
      margin-top: 48px;
      position: relative;
      .productText {
        padding: 0 52px;
        position: absolute;
        bottom: 72px;
        .name {
          font-size: 44px;
          font-weight: 700;
          line-height: 66px;
          letter-spacing: -0.8px;
          text-align: left;
          margin-bottom: 14px;
          white-space: pre-line;
        }
        .description {
          font-size: 32px;
          font-weight: 400;
          line-height: 42px;
          text-align: left;
        }
      }
    }
  }
}
@media all and (max-width: 750px) {
  .recommendCore {
    padding: 16vw 0;
    .subTitle {
      font-size: 3.7333vw;
      line-height: 4.5333vw;
    }
    .title {
      font-size: 5.8667vw;
      line-height: 7.4667vw;
      letter-spacing: -0.0667vw;
      margin-top: 2.1333vw;
    }
    .productList {
      margin-top: 7.4667vw;
      margin: 0 14vw;
      .product {
        margin-top: 6.4vw;
        .productText {
          padding: 0 6.9333vw;

          bottom: 9.6vw;
          .name {
            font-size: 5.8667vw;
            line-height: 8.8vw;
            letter-spacing: -0.1067vw;
            margin-bottom: 1.8667vw;
          }
          .description {
            font-size: 4.2667vw;
            line-height: 5.6vw;
          }
        }
      }
    }
  }
}
