.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: calc(100% - 9.333vw);
  background: #fff;
  border-radius: 3.2vw 3.2vw 0vw 0vw;
  text-align: center;
  padding: 8.667vw 6.267vw 4.8vw;
  box-sizing: border-box;
  overflow-y: auto;
}
.title {
  color: var(--GrayScale-900---000000, #000);
  font-size: 5.333vw;
  font-weight: 700;
  line-height: 6.4vw;
  letter-spacing: -0.067vw;
  b {
    color: #ff5647;
  }
}
.description {
  color: var(--GrayScale-800---28284B, #28284b);
  text-align: center;
  font-size: 3.2vw;
  line-height: 4vw;
  margin-top: 2.533vw;
}
.infoBox {
  margin-top: 4.8vw;
  display: flex;
  padding: 8vw 4.933vw 9.333vw 4.933vw;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 7.467vw;
  border-radius: 2.667vw;
  border: 0.267vw solid var(--GrayScale-200---DFDFEA, #dfdfea);
  background: var(--GrayScale-20---F7F7FC, #f7f7fc);
  span {
    padding: 1.333vw 2.933vw;
    gap: 1.333vw;
    border-radius: 6.067vw;
    background: var(--GrayScale-700---717188, #717188);
    color: var(--GrayScale-0---FFFFFF, #fff);
    font-size: 3.733vw;
    font-weight: 700;
    line-height: 4.533vw;
    box-sizing: border-box;
    display: inline-block;
  }
  p {
    margin-top: 2.133vw;
    color: #3f3f3f;
    font-size: 3.733vw;
    line-height: 4.533vw;
  }
}
.infoText {
  margin-top: 4.8vw;
  font-size: 3.733vw;
  font-weight: 500;
  line-height: 4.533vw;
  color: var(--Primary-Universe-Navy---28284B, #28284b);
  b {
    color: var(--Accent-Green---17C624, #17c624);
    font-weight: 500;
  }
}
.buttonWrap {
  margin-top: 7.067vw;
  display: flex;
  gap: 2.667vw;
  button {
    width: 50%;
    border-radius: 2.4vw;
    height: 14.933vw;
    background: var(--GrayScale-0---FFFFFF, #fff);
    color: #161c22;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 5.333vw;
    letter-spacing: -0.5px;

    &:first-of-type {
      border: 0.267vw solid #d5dae1;
    }
    &:last-of-type {
      color: #fff;
      background: #5199ff;
    }
  }
}
