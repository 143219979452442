.article {
  border-top: 2px solid #000;
  border-bottom: 1px solid #b7b7c8;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  li {
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid #e7e7f0;
    }
  }
}
.caption {
  color: #000;
  font-size: 30px;
  font-weight: 500;
  line-height: 36.31px;
  text-align: center;
  padding: 22px 0;
  border-bottom: 1px solid #b7b7c8;
}
.th {
  width: 101px;
  padding-left: 26px;
  display: flex;
  align-items: center;
  color: #717188;
  font-size: 24px;
  font-weight: 400;
  line-height: 35.52px;
  text-align: left;
}
.td {
  flex: 1;
  padding: 32px 0;
  padding-right: 26px;
}
.param01 {
  color: #a0a0b6;
  font-size: 24px;
  line-height: 125%;
  margin-bottom: 12px;
  word-break: keep-all;
  width: 400px;
}
.param02 {
  color: #28284b;
  font-size: 28px;
  font-weight: 700;
  line-height: 122%;
  display: flex;
  align-items: flex-end;
  word-break: keep-all;
  line-height: 140%;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
  u {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
.param03 {
  color: #28284b;
  font-size: 28px;
  font-weight: 400;
  line-height: 122%;
  word-break: keep-all;
  line-height: 140%;

  u {
    text-decoration: none;
  }
}
.param04 {
  color: #717188;
  font-size: 24px;
  line-height: 137%;
  word-break: keep-all;
}

.completeSentence,
.basic {
  .param02 {
    margin-bottom: 4px;
  }
}
.choice {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  img {
    width: 40px;
    height: 40px;
  }
  &.block {
    grid-template-columns: repeat(1, 1fr);
  }

  p {
    color: #28284b;
    font-size: 28px;
    position: relative;
    padding-left: 56px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 40px;
      height: 40px;
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/levelTestAdvanced/2024/ico_check.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &.checked {
      &::before {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/levelTestAdvanced/2024/ico_check-active.svg);
      }
    }
  }
}
.answer {
  display: flex;
  align-items: center;
  gap: 0 32px;
  img {
    width: 40px;
    height: 40px;
  }
  button {
    border-radius: 8px;
    border: 1px solid #dfdfea;
    width: 136px;
    height: 64px;
    display: block;
    color: #717188;
    font-size: 24px;
  }
}
.correct {
  background: #e8f9e9;
}
.incorrect {
  background: #ffeeed;
}
.marker {
  .resultMessage {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    text-align: left;
  }
}
@media all and (max-width: 750px) {
  .article {
    border-top-width: 0.4vw;
    &:not(:last-child) {
      margin-bottom: 4.2667vw;
    }
  }

  .caption {
    font-size: 4vw;
    line-height: 4.8413vw;
    padding: 2.9333vw 0;
    border-bottom: 0.1333vw solid #b7b7c8;
  }

  .th {
    width: 13.4667vw;
    padding-left: 3.4667vw;
    font-size: 3.2vw;
    line-height: 4.736vw;
  }

  .td {
    padding: 4.2667vw 0;
    padding-right: 3.4667vw;
  }

  .param01 {
    font-size: 3.2vw;
    margin-bottom: 1.6vw;
    width: 53.3333vw;
  }
  .param02 {
    font-size: 3.7333vw;
    line-height: 122%;
    margin-bottom: 1.6vw;
  }
  .param03 {
    font-size: 3.733vw;
  }
  .param04 {
    font-size: 3.2vw;
  }

  .completeSentence,
  .basic {
    .param02 {
      margin-bottom: 0.5333vw;
    }
  }

  .choice {
    gap: 2.1333vw;
    img {
      width: 5.3333vw;
      height: 5.3333vw;
    }
    p {
      font-size: 3.7333vw;
      padding-left: 7.4667vw;
    }
    p::before {
      width: 5.333vw;
      height: 5.333vw;
    }
  }
  .answer {
    img {
      width: 5.3333vw;
      height: 5.3333vw;
    }
    gap: 0 4.2667vw;
    button {
      border-radius: 1.0667vw;
      width: 18.1333vw;
      height: 8.5333vw;
      font-size: 3.2vw;
    }
  }
  .marker {
    .resultMessage {
      font-size: 3.2vw;

      line-height: 4.5333vw;
    }
  }
}
