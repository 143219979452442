.container {
  padding: 80px 42px 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  box-sizing: border-box;
}
.index {
  color: #717b8d;
  font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.5px;
}
.question {
  margin-top: 24px;
  color: #161c22;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.5px;
  white-space: pre-line;
  font-weight: 700;
  em {
    font-weight: 400;
    display: block;
  }
}
.buttons {
  margin-top: 56px;
  display: grid;
  gap: 18px;
  &.image {
    grid-template-columns: repeat(2, 1fr);
    .button {
      height: 448px;
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 180px;
        margin-bottom: 20px;
      }
    }
  }
  &.description {
    .button {
      padding: 31.5px 42px;
    }
    .buttonText {
      font-size: 28px;
      font-weight: 400;
      b {
        display: block;
        font-size: 32px;
        font-weight: 700;
      }
    }
  }
  button {
    text-align: left;
    padding: 34px 42px;
    background: #eef1f6;
    border-radius: 16px;
    border: 2px solid #d5dae1;
    &.selected {
      border: 2px solid #ffcba9;
      background: #ffebdd;
      &.conversation {
        border: 2px solid #7ebcff;
        background: #e3f2ff;
      }
      p {
        color: #161c22;
      }
    }
  }
  .buttonText {
    color: #717b8d;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.5px;
  }
}
.bottomButtons {
  margin-top: 80px;
  display: flex;
  gap: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 60%);
  padding: 37px 36px 37px;
  box-sizing: border-box;
  max-width: 750px;
  button {
    height: 112px;
    width: 100%;
    border-radius: 18px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.5px;

    &:first-of-type {
      width: 164px;
      border: 2px solid #d5dae1;
      background: var(--GrayScale-0---FFFFFF, #fff);
      color: #161c22;
      flex: none;
    }
    &:last-of-type {
      background: #161c22;
      color: #fff;
    }
    &:disabled {
      background: #dce0e5;
      color: #fff;
    }
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding: 10.667vw 5.6vw 30.667vw;
  }
  .index {
    font-size: 6.4vw;
    line-height: 7.467vw;
    letter-spacing: -0.067vw;
  }
  .question {
    margin-top: 3.2vw;
    font-size: 6.4vw;
    line-height: 7.467vw;
    letter-spacing: -0.067vw;
  }
  .buttons {
    margin-top: 7.467vw;
    gap: 2.4vw;
    &.image {
      .button {
        height: 59.733vw;
        border-radius: 4.267vw;
        img {
          width: 24vw;
          margin-bottom: 2.667vw;
        }
      }
    }
    &.description {
      .button {
        padding: 4.2vw 5.6vw;
      }
      .buttonText {
        font-size: 3.733vw;
        b {
          font-size: 4.267vw;
          font-weight: 700;
        }
      }
    }
    button {
      padding: 4.533vw 5.6vw;
      border-radius: 2.133vw;
      border: 0.267vw solid #d5dae1;
      &.selected {
        border: 0.267vw solid #ffcba9;
        &.conversation {
          border: 0.267vw solid #7ebcff;
        }
      }
    }
    .buttonText {
      font-size: 4.8vw;
      line-height: 5.867vw;
      letter-spacing: -0.067vw;
    }
  }
  .bottomButtons {
    margin-top: 10.67vw;
    gap: 2.67vw;
    padding: 4.93vw 4.8vw 4.93vw;
    button {
      height: 14.933vw;
      border-radius: 2.4vw;
      font-size: 4.267vw;
      line-height: 5.333vw;
      letter-spacing: -0.067vw;

      &:first-of-type {
        width: 21.867vw;
        border: 0.267vw solid #d5dae1;
      }
    }
  }
}
