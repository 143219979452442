.container {
  background: #f3f5f9;
}
.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 75px;
  box-sizing: border-box;
  display: flex;
  gap: 154px;
}
.title {
  display: flex;
  gap: 16px;
  flex: none;
  img {
    width: 68px;
    height: 68px;
  }
  b {
    color: #ff6f0f;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  span {
    display: block;
    color: #161c22;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.5px;
  }
}
.list {
  li {
    & + li {
      margin-top: 24px;
    }
    span {
      display: block;
      color: #161c22;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: -0.5px;
    }
    p {
      margin-top: 9px;
      color: #324053;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      white-space: pre-line;
      &:before {
        content: '';
        display: inline-block;
        width: 3.998px;
        height: 3.998px;
        border-radius: 50%;
        background: #324053;
        flex: none;
        margin: 9px 8px 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    flex-direction: column;
    gap: 30px;
    padding: 100px 25px;
  }
}
@media screen and (max-width: 750px) {
  .container {
    background: #f3f5f9;
  }
  .inner {
    max-width: 100vw;
    margin: 0 auto;
    padding: 16vw 8vw;
    box-sizing: border-box;
    gap: 7.467vw;
  }
  .title {
    display: flex;
    gap: 4.267vw;
    align-items: center;
    img {
      width: 14.933vw;
      height: 14.933vw;
    }
    b {
      display: block;
      color: #ff6f0f;
      font-size: 3.733vw;
      font-weight: 700;
      letter-spacing: -0.149vw;
    }
    span {
      margin-top: 1.067vw;
      display: block;
      color: #161c22;
      font-size: 5.6vw;
      font-weight: 700;
      line-height: 6.4vw;
      letter-spacing: -0.067vw;
    }
  }
  .list {
    li {
      & + li {
        margin-top: 4.8vw;
      }
      span {
        display: block;
        color: #161c22;
        font-size: 3.733vw;
        font-weight: 700;
        line-height: 4.533vw;
      }
      p {
        margin-top: 1.6vw;
        color: #324053;
        font-size: 3.2vw;
        line-height: 4vw;
        display: flex;
        white-space: initial;
        & + p {
          margin-top: 0;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 0.7vw;
          height: 0.7vw;
          border-radius: 50%;
          background: #324053;
          flex: none;
          margin: 1.5vw 2vw 0;
        }
      }
    }
  }
}
