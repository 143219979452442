.reviewWrite {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  background-color: #f7f7f8;

  .contentBox {
    height: 100%;
    width: 100%;

    box-sizing: border-box;
  }

  .productTitle {
    padding: 16px;
    margin-bottom: 8px;
    background-color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    word-break: break-all;
    color: #000;
  }

  .reviewButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 16px 24px;
    background-color: #28284b;

    button {
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      font-size: 14px;
      color: #000;
      background-color: #ffb900;
      border-radius: 4px;

      &:disabled {
        background-color: #ececf6;
      }
    }
  }

  @media screen and (min-width: 768px) and (min-height: 700px) {
    .contentBox {
      max-width: 360px;
      max-height: 640px;
      overflow-y: auto;
      border-radius: 16px;
      border: 1px solid #ddd;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
    }
  }
}
