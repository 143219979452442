.csNotice {
  margin-top: 65px;
  .btnMore {
    margin-top: 55px;
  }
}

@media screen and (max-width: 1024px) {
  .csNotice {
    margin-top: 120px;
    .btnMore {
      margin-top: 52px;
    }
  }
}

@media screen and (max-width: 750px) {
  .csNotice {
    margin-top: 16vw;
    .btnMore {
      margin-top: 6.933vw;
    }
  }
}
