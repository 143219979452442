.commentReviewBox {
  position: relative;
  padding: 3rem 3.2rem 3rem 1.8rem;
  border-top: 1px solid #dfdfea;

  @media (max-width: 1024px) {
    padding: 3.2rem 4rem 2.6rem 2.6rem;
  }
}

.writeCommentBox {
  position: relative;
  margin-top: auto;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -1.3rem;
    left: 0;
    width: 100%;
    height: 1.3rem;
    background: linear-gradient(#fff, 80%, #efefef);
  }

  p {
    position: relative;
    padding: 0.86rem 2.6rem 0.83rem;
    color: #717188;
    background-color: #f2f2fa;
    font-size: 1.6rem;

    strong {
      font-weight: 700;

      &.disabled {
        font-weight: 400;

        &::after {
          display: none;
        }
      }

      &::after {
        content: '님에게 댓글 남기는 중';
        display: inline-block;
        font-weight: 400;
      }
    }

    button {
      &::before {
        content: '·';
        display: inline-block;
        margin: auto 0.66rem auto 0.83rem;
      }
    }
  }

  form {
    padding: 2.2rem 2.6rem;

    div {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      height: 4.6rem;
      padding: 0.9rem 1.8rem;
      border: 0.2rem solid #ececf6;
      border-radius: 0.5rem;
      box-sizing: border-box;
    }

    input {
      flex-grow: 1;
      line-height: 1.9rem;
      padding: 0;
      font-size: 1.6rem;

      &::placeholder {
        color: #b7b7c8;
      }

      & + button {
        flex: 0 0 3.2rem;
        align-self: center;
        width: 3.2rem;
        height: 3.2rem;
        background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_send_off_32x32.png)
          0 0/100% no-repeat;
      }

      &.active + button {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_send_on_32x32.png);
      }
    }
  }

  @media (max-width: 1024px) {
    p {
      padding: 1.3rem 2.8rem 1.5rem;
      font-size: 2.6rem;

      button {
        &::before {
          margin: auto 1rem;
        }
      }
    }

    form {
      padding: 2.2rem 2.6rem;

      div {
        height: 6.8rem;
        padding: 0.9rem 1.8rem;
        border: 0.2rem solid #ececf6;
      }

      input {
        line-height: 4rem;
        font-size: 2.6rem;

        & + button {
          flex: 0 0 4.8rem;
          width: 4.8rem;
          height: 4.8rem;
        }
      }
    }
  }
}
