.boardBox {
  padding: 8rem 0;
  .boardInfo {
    display: flex;
    margin-bottom: 2rem;
    justify-content: flex-start;
    align-items: end;
    p {
      flex: 1 auto;
      font-weight: 400;
      font-size: 1.4rem;
      color: #717188;
      &:before {
        content: '- ';
      }
    }
  }
  .pagination {
    padding-bottom: 6rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 0 3.2rem;
    .boardInfo {
      display: none;
    }
  }
}

.boardList {
  display: block;
  width: 100%;
  border-top: 0.3rem solid #000;
  .noContent {
    &.searchMode {
      padding-bottom: 2rem;
      margin-bottom: 6rem;
      border-bottom: 1px solid #b7b7c8;
    }
  }
  @media screen and (max-width: 1024px) {
    border-top: 0.1rem solid #e7e7f0;
    .noContent {
      padding: 1rem 0 2rem;
      &.searchMode {
        border-bottom: 0;
      }
    }
  }
}

.smInnerBox {
  position: relative;
  max-width: 948px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 0;
  }
}
