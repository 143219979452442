@import 'variable';

header {
  .mypage-box {
    display: inline-block;
    position: relative;
    padding: 0px 0px 0px 16px;

    ul {
      display: none;
      position: absolute;
      top: 4rem;
      right: 0rem;
      width: 12.5rem;
      padding: 2rem 0px 0.4em;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
      box-sizing: border-box;
      z-index: 2;

      li {
        a,
        button {
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          display: block;
          height: unset;
          padding: 0rem 2rem 1.5rem;
          font-weight: 500;
          font-size: 15px;
          text-align: right;

          &:hover {
            font-weight: 700;
          }
        }
      }
    }

    &.active {
      ul {
        display: block;
      }
    }
    button {
      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0px;
        margin-top: -9px;
        background-color: rgb(207, 207, 218);
      }
    }
    .btn-login {
      display: inline-block;
      width: 7.8rem;
      height: 3.6rem;
      font-size: 1.4rem;
      background-color: $warning;
      border-radius: 0.3rem;
    }

    .btn-mypage {
      .user-thumb-box {
        display: block;
        width: 3.6rem;
        height: 3.6rem;
        background-color: $gray-500;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 50%;
        border: 1px solid $white;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .user-thumb-box {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    box-sizing: border-box;
    border-radius: 100%;
  }
}
