@charset "UTF-8";
@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';

.modal-wrap {
  &.class-review {
    .img-thumb-area {
      font-size: 1.6rem;
      color: $gray-700;
      background-color: $gray-100;

      em {
        &::before {
          content: '';
          display: inline-block;
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.5rem;
          background: url('#{$img-cdn}/ico_image_81x81.png') no-repeat 50% 0 / 100%;
          vertical-align: middle;
        }
      }

      ul {
        background-color: $white;
        white-space: nowrap;
        overflow-x: scroll;

        li {
          display: inline-block;
          position: relative;
          width: 9.3rem;
          height: 9.3rem;
          background-color: $gray-400;
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto 100%;

          button {
            position: absolute;
            top: 0;
            right: 0;
            width: 3.3rem;
            height: 3.3rem;
            background: url('#{$img-cdn}/ico_trash.png') no-repeat 50% 0 / 100%;
          }

          + li {
            margin-left: 1rem;
          }
        }

        &.active {
          padding: 1.7rem 1.3rem;
          box-shadow: 0 -0.5rem 1rem rgb(0 0 0 / 6%);
        }
      }

      .btn-cancel {
        width: 1.6rem;
        height: 1.6rem;
        top: 0.3rem;
        right: 0.3rem;
        background: rgba(0, 0, 0, 0.5) url('#{$img-cdn}/ico_cancel_28x28_w.png') no-repeat 50% 50% /
          0.7rem;
        border-radius: 50%;
      }
    }

    .notice-box {
      background-color: $gray-900;

      ul > li,
      ul > li::before,
      strong {
        color: #e7e7f0;
      }
    }
  }
}

.modal-content {
  .class-info-box {
    display: flex;
    padding: 2.5rem 3.3rem;
    border-bottom: 8px solid #e7e7f0;
    align-items: center;

    .class-img {
      display: inline-block;
      width: 5.1rem;
      height: 5.1rem;
      margin-right: 1.6rem;
      border-radius: 0.3rem;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      flex: none;
    }

    p {
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 1.3;
      color: #000;

      .class-name {
        display: -webkit-box;
        font-size: 18px;
        font-weight: 500;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
      .class-description {
        margin-top: 2px;
        color: var(--GrayScale-700---717188, #717188);
        font-size: 14px;
        line-height: 20px;
        display: block;
      }

      strong {
        display: block;
        margin-top: 1.3rem;
        font-weight: 400;
        font-size: 1.6rem;
        text-align: left;
        color: #a0a0b6;

        + strong {
          &::before {
            content: '·';
            margin: 0 0.3rem;
          }
        }
      }
    }
  }

  .review-box {
    dl {
      padding: 4rem 0;

      dt {
        padding-bottom: 2rem;
        font-size: 2.8rem;
        color: #000;
        text-align: center;
      }
      dd {
        font-weight: 400;
        font-size: 1.6rem;
        color: #b7b7c8;
        text-align: center;

        .point-star-box {
          display: block;
          position: relative;
          width: 35rem;
          height: 7rem;
          margin: 0 auto 2.1rem;
          background-color: #ececf6;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('#{$img-cdn}/bg_star_215x215.png') repeat 0 0;
            background-size: 20%;
            z-index: 2;
          }

          .btn-group {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;

            button {
              float: left;
              width: 20%;
              height: 100%;
              padding: 0;
              margin: 0;
              background-color: transparent;
              text-indent: -9999rem;
              outline: 0;
              border: 0;
            }
          }

          span {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #ff6b00;
            transition: 0.5s;
          }
        }
      }
    }
    textarea {
      width: 100%;
      height: 31rem;
      padding: 3.2rem;
      font-weight: 400;
      font-size: 1.7rem;
      line-height: 1.4;
      border-top: 0.1rem solid #dfdfea;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      outline: 0;
      resize: none;
      box-sizing: border-box;
    }
    strong {
      display: block;
      padding: 1rem 2.6rem 3.3rem;
      font-weight: 400;
      font-size: 1.6rem;
      color: #b7b7c8;
      text-align: right;
      box-sizing: border-box;

      span {
        color: #000;
      }
    }
  }
  .text-box-label {
    color: var(--TextColor-Primary, #000);
    font-size: 16px;
    line-height: 15px;
    margin: 0 32px 8px;
    display: block;
  }
  .text-box {
    margin: 0 32px;
    border: 1px solid var(--GrayScale-100---E7E7F0, #e7e7f0);
    textarea {
      height: 200px;
      border: 0;
      padding: 20px 16px;
    }
    strong {
      padding: 16px;
    }
  }
  .notice-box {
    padding: 3.7rem 2.3rem;
    background-color: #f7f7fc;

    strong {
      display: block;
      font-weight: 500;
      font-size: 1.6rem;
      color: #717188;
    }

    ul {
      margin-top: 2rem;

      li {
        position: relative;
        padding-left: 1rem;
        font-weight: 400;
        font-size: 1.6rem;
        color: #717188;

        & + li {
          margin-top: 0.5rem;
        }

        &::before {
          content: '-';
          position: absolute;
          top: 0;
          left: 0;
          color: #717188;
        }
      }
    }

    .btn-flex-form {
      margin-top: 4rem;

      .button {
        @include button(20.4rem, 6.4rem, $dark, $white, 1.7rem, 0.3rem);

        &.disabled {
          &.active {
            border-color: $warning;
          }
        }

        & + .button {
          margin-left: 0.8rem;
        }
      }
    }
  }
}

.select-btn {
  display: block;
  padding: 2rem 3.1rem;
}

input[type='file'] {
  display: none;
}
@media screen and (max-width: 1024px) {
  .modal-wrap {
    &.class-review {
      .img-thumb-area {
        font-size: 2.6rem;

        em {
          &::before {
            width: 3.8rem;
            height: 3.8rem;
            vertical-align: middle;
          }
        }

        ul {
          li {
            width: 14.6rem;
            height: 14.6rem;

            button {
              width: 5.2rem;
              height: 5.2rem;
            }

            + li {
              margin-left: 1.3rem;
            }
          }

          &.active {
            padding: 1.9rem 2.7rem;
          }
        }

        .btn-cancel {
          width: 2.6rem;
          height: 2.6rem;
          top: 0.4rem;
          right: 0.4rem;
          background-size: 1.1rem;
        }
      }
    }
  }

  .modal-content {
    .class-info-box {
      padding: 4rem 4.2rem;
      flex-direction: initial;
      border-bottom: 1.3rem solid #dfdfea;
      .class-img {
        width: 8rem;
        height: 8rem;
        margin-right: 2.5rem;
        border-radius: 0.5rem;
      }

      p {
        font-size: 4rem;

        strong {
          margin-top: 2.1rem;
          font-size: 2.6rem;
        }
      }
    }

    .review-box {
      dl {
        padding: 6.2rem 0;

        dt {
          padding-bottom: 4rem;
          font-size: 4.6rem;
        }
        dd {
          font-size: 2.6rem;

          .point-star-box {
            width: 55rem;
            height: 11rem;
            margin: 1rem auto;
          }
        }
      }
      textarea {
        height: 50rem;
        padding: 2.7rem 5.2rem;
        font-size: 2.6rem;
      }
      strong {
        padding: 2rem 2.4rem 2.4rem;
        font-size: 2.5rem;
      }
    }

    .notice-box {
      padding: 6rem 4.2rem;

      strong {
        font-size: 2.7rem;
      }

      ul {
        margin-top: 4rem;

        li {
          padding-left: 2rem;
          font-size: 2.5rem;

          & + li {
            margin-top: 1rem;
          }
        }
      }

      .btn-flex-form {
        padding-top: 7rem;

        .button {
          width: 32.4rem;
          height: 10rem;
          font-size: 2.7rem;
          border-radius: 0.5rem;
          border: 0;

          & + .button {
            margin-left: 1.8rem;
          }
        }
      }
    }
  }

  .select-btn {
    padding: 3rem 5.4rem;
  }
}

@media screen and (max-width: 750px) {
  .modal-content {
    .text-box-label {
      line-height: 4vw;
      margin: 0 5.6vw 8px;
      font-size: 3.2vw;
    }
    .text-box {
      margin: 0 5.6vw;
      textarea {
        height: 30vw;
        padding: 4.133vw 3.2vw;
        font-size: 3.733vw;
      }
    }
    .review-box dl {
      dt {
        padding-bottom: 3.2vw;
      }
      dd {
        font-size: 3.2vw;
        .point-star-box {
          width: 72.267vw;
          height: 13.6vw;
          margin: 0 auto 2.667vw;
        }
      }
    }
    .class-info-box {
      .class-img {
        width: 10.667vw;
        height: 10.667vw;
      }
      p {
        .class-name {
          font-size: 3.733vw;
          line-height: 4.533vw;
        }
        .class-description {
          color: var(--GrayScale-700---717188, #717188);
          font-size: 3.2vw;
          line-height: 4vw;
          margin-top: 1.067vw;
        }
      }
    }
    .notice-box {
      strong {
        font-size: 3.733vw;
      }
      ul > li {
        font-size: 3.2vw;
        line-height: 4vw;
        color: #e7e7f0;
      }
      .btn-flex-form {
        margin-top: 0;
        .button {
          font-size: 3.733vw;
          height: 12.8vw;
        }
      }
    }
  }
}
