.bannerBox {
  position: relative;
  width: 100%;
  margin-bottom: 7rem;

  @media all and (max-width: 1024px) {
    margin-bottom: 6rem;
  }

  picture {
    width: 100%;
    display: inline-block;
    height: 100%;
    text-align: center;
  }

  img {
    height: 100%;
    max-width: 1920px;
    object-fit: cover;
  }

  :global(.swiper-pagination) {
    bottom: 2.6rem !important;
  }

  :global(.swiper-slide) {
    height: 100%;

    a {
      display: block;
      height: 100%;
    }

    @media all and (max-width: 1024px) {
      height: auto;

      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  :global(.swiper-pagination-bullet) {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.4rem;
    background-color: #fff;
    opacity: 0.6;
    border-radius: 0.6rem;
    transition: 0.2s;

    &:global(.swiper-pagination-bullet-active) {
      width: 3.2rem;
      opacity: 1;
    }

    @media (max) {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0 1.6rem;

      &:global(.swiper-pagination-bullet-active) {
        width: 4.8rem;
      }
    }
  }
}
