@import 'variable';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
/* detail card */
.hide {
  display: none !important;
}
.detail-card-col {
  display: none;

  .swiper-slide img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
  .swiper-container {
    position: relative;

    .swiper-pagination-fraction {
      display: inline-block;
      width: auto;
      min-width: 9.7rem;
      padding: 0.8rem 2rem;
      left: auto;
      bottom: 2rem;
      right: 2rem;
      font-size: 2rem;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0.3rem;

      span {
        font-weight: 700;
        font-size: 2rem;
      }
    }
  }

  .slide-item {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 68%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
.tag-lists {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.ico-tag {
  display: inline-block;
  padding: 1.1rem 2.2rem 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  color: #fff;
  background-color: #28284b;

  &.red {
    background-color: #ff5647;
  }
}

.product-col {
  padding-top: 2.9rem;

  .inner-col {
    display: flex;
  }

  .product-content-col {
    max-width: 78.6rem;
    width: 100%;
    padding-bottom: 12rem;

    .banner-col {
      margin: 4rem 0 8rem;
      box-sizing: border-box;
    }

    .product-detail {
      padding-top: 10rem;

      h4 {
        margin: 3.2rem 0 0.5rem;
        font-size: 2rem;
      }

      h5 {
        margin: 3.2rem 0 0.5rem;
        font-size: 1.8rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 1.5;
      }

      a {
        display: inline-block;
        font-size: 1.6rem;
        text-decoration: underline;
        line-height: 1.5;
      }

      ol,
      ul {
        margin-top: 1.2rem;

        li {
          font-size: 1.6rem;
          line-height: 1.5;

          & + li {
            margin-top: 0.6rem;
          }
        }
      }

      ul {
        padding-left: 1.6rem;

        li {
          list-style: disc;
        }
      }

      ol {
        padding-left: 1.6rem;

        li {
          list-style: decimal;
        }
      }

      table,
      th,
      tr,
      td {
        font-size: 1.6rem;
        border: 1px solid $gray-500;
      }

      table {
        max-width: 100%;
        width: 100% !important;
      }

      th {
        background-color: $gray-100;
      }

      th,
      td {
        padding: 1.6rem 1.5rem 1.1rem;
        word-break: break-all;
      }

      img {
        width: 100% !important;
        height: auto !important;
      }

      &.active {
        height: auto;

        & ~ .btn-detail-more {
          display: none;
        }
      }

      & ~ .btn-detail-more {
        display: block;
        position: relative;
        width: 100%;
        padding: 2rem 2rem 2rem 0;
        font-size: 1.5rem;
        font-weight: 700;
        border: 1px solid #24252b;
        border-radius: 0.5rem;
        box-shadow: 0 0 1rem rgba($dark, 0.3);

        span {
          &::after {
            content: '';
            display: inline-block;
            width: 1.6rem;
            height: 1.5rem;
            margin-left: 1rem;
            background: $white url('#{$img-cdn}/ico_more_48x48.png') 60% 46% no-repeat;
            background-size: 1.6rem;
          }
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -5.3rem;
          width: 100%;
          height: 5.2rem;
          background: linear-gradient(to top, rgba($white, 0.6), rgba($white, 0));
        }
      }
    }
  }

  .price-info-col {
    position: relative;
    z-index: 1;
    width: 32rem;
    min-width: 32rem;
    height: calc(100vh - 12rem);
    padding-bottom: 2.9rem;
    margin-left: 6rem;
    transition: all 0.3s;

    &.top {
      padding-top: 3.3rem;
    }

    em {
      display: inline-block;
      font-size: 1.3rem;
      color: #a0a0b6;
    }

    strong {
      display: inline-block;
      font-size: 1.8rem;
    }

    & > p > strong {
      margin: 1.7rem 0 1.4rem;
    }

    .early {
      display: inline-block;
      box-sizing: border-box;
      width: 6.8rem;
      padding: 0.4rem 0 0.3rem;
      margin-right: 1rem;
      font-weight: 700;
      font-size: 1.3rem;
      color: #a0a0b6;
      background-color: #fff;
      border-radius: 0.3rem;
      border: 1px solid #e7e7f0;
      text-align: center;
    }

    .leader:before {
      content: '|';
      display: inline-block;
      margin: 0 0.45rem;
      font-size: 1rem;
      color: #cfcfda;
      vertical-align: middle;
    }

    .score {
      span,
      em {
        display: inline-block;
        font-size: 1.4rem;
        color: #28284b;
        vertical-align: middle;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin: -0.3rem 0.7rem 0 0;
        background: url('#{$img-cdn}/ico_star_40x40_orange.png') 0 0 /100% no-repeat;
        vertical-align: middle;
      }
      em {
        margin: 0 0 0 0.3rem;
        &:before {
          content: '(';
          display: inline-block;
        }
        &:after {
          content: ')';
          display: inline-block;
        }
      }
    }

    .btn-group {
      display: flex;
      flex-wrap: wrap;
      margin: 2.1rem 0 0 0;

      button {
        flex: 0 0 82%;
        max-width: 82%;
        height: 5rem;
        margin-top: 0.7rem;
        font-weight: 400;
        font-size: 1.5rem;
        border: 1px solid #cfcfda;
        border-radius: 0.5rem;
        cursor: pointer;
      }

      .btn-default {
        color: $white;
        background-color: $gray-500;
      }

      .btn-cart {
        background-color: #fff;

        &.active {
          color: #000;
          border-color: #ffb900;
          background-color: #ffb900;
        }
      }

      button:last-child {
        display: block;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        color: #fff;
        background-color: #cfcfda;
        background-image: none;
        border: 0;
        text-indent: 0;
      }

      button:last-child {
        color: $white;
        background-color: #cfcfda;
      }

      button.btn-share {
        flex: 0 0 16%;
        max-width: 16%;
        margin-left: 0.6rem;
        border-color: #f7f7fc;
        background: #f7f7fc url('#{$img-cdn}/ico_share_164x164.png') 0 0 /100% no-repeat;
        text-indent: -9999rem;
      }

      button.btn-soldout,
      button.btn-waiting {
        cursor: default;
      }

      button.btn-soldout {
        color: $white;
        border-color: #ff5647;
        background-color: #ff5647;
      }

      button.btn-ready {
        color: $dark;
        border-color: #ffb900;
        background-color: #ffb900;
      }
    }
  }
}

body {
  &.scroll-bottom {
    .inner-col {
      align-items: end !important;
      .price-info-col {
        .info-inner {
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }
  &.fixed {
    .price-info-col {
      .info-inner {
        position: fixed;
        width: 32rem;
        top: 14.2rem;
        left: calc(50% + 24.6rem);
        @media screen and (max-width: 1200px) {
          left: auto;
          right: 3rem;
        }
        @media screen and (max-width: 1024px) {
          position: relative;
          top: auto;
          right: auto;
          left: auto;
          width: auto;
        }
      }
    }
  }
}

.price-box {
  mark {
    margin-right: 0.5rem;
    background: 0;
    font-weight: 700;
    font-size: 1.8rem;
    color: #ff5647;
  }

  strong {
    font-weight: 700;
  }

  em:after,
  strong:after {
    content: '원';
  }

  strong:after {
    font-size: 1.4rem;
  }

  p:before {
    content: '';
    font-size: 1.4rem;
    color: #28284b;
  }

  .discount {
    color: #b7b7c8;
    text-decoration: line-through;
  }
  .type {
    p {
      position: relative;
      text-align: right;
      vertical-align: middle;
    }

    &.real {
      p {
        &:first-child {
          &::before {
            content: '일반/입학 회원';
          }
        }
      }
    }

    &.early-bird {
      p {
        &:first-child {
          &::before {
            content: '얼리버드';
          }
        }

        &:last-child {
          &::before {
            content: '입학 회원';
          }
        }
      }
    }

    p:before {
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 1.6rem;
      margin: auto 0;
    }

    p:first-child {
      margin-top: 1.5rem;
    }

    p:last-child strong {
      font-size: 3rem;

      &::after {
        font-size: 2rem;
      }
    }

    & > div:nth-of-type(2) {
      font-size: 1.4rem;

      p {
        margin-bottom: 1.9rem;
        font-weight: 700;
      }

      p:before {
        content: '기본구성';
        display: inline-block;
        width: 9rem;
        padding-right: 1.7rem;
        font-weight: 400;
      }

      p:nth-child(2):before {
        content: '수강기간';
      }

      p:nth-child(3):before {
        content: '대상';
      }
    }

    &.material {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin: 2.4rem 0 2.6rem;
      padding: 1rem 0;
      font-weight: 700;
      font-size: 1.4rem;
      border-top: 1px solid #e7e7e0;
      border-bottom: 1px solid #e7e7e0;

      div,
      span {
        display: inline-block;
        flex: 0 0 30%;
        color: #000;
      }

      .material-counter {
        flex: 70% !important;
        font-weight: 400;
        text-align: right;
      }

      & button {
        display: inline-block;
        width: 3.8rem;
        height: 3.8rem;
        padding: 0 0 0.5rem;
        font-size: 2.3rem;
        line-height: 2rem;
        color: #000;
        background-color: #f7f7fc;
        border: 1px solid #e7e7f0;
        border-radius: 0;
      }

      div span {
        width: 5.7rem;
        text-align: center;
      }
    }

    &.membership {
      dl {
        display: flex;
        justify-content: space-between;
        align-items: center;

        dd strong {
          &:after {
            font-size: inherit;
          }
        }

        &:first-child {
          margin-top: 3.3rem;

          dd strong {
            color: #8d8da0;
            font-size: 1.8rem;
            text-decoration: line-through;
          }
        }

        &:last-child {
          dd {
            text-align: right;
            mark {
              font-size: 1.8rem;
              font-weight: 700;
              vertical-align: middle;
            }

            strong {
              font-size: 2.5rem;
              vertical-align: middle;

              &:not(.no-month):before {
                content: '월';
                display: inline-block;
                margin-right: 0.5rem;
              }
            }

            span {
              display: inline-block;
              padding-bottom: 0.7rem;
              font-weight: 700;
              font-size: 1.8rem;
              color: #8d8da0;
              &.percent {
                padding-bottom: 0;
                color: #ff5647;
                vertical-align: middle;
              }
            }

            em {
              display: block;
              color: #717188;
              font-size: 1.3rem;
              text-align: right;

              &:after {
                content: '개월 할부';
              }
            }
          }
        }

        & + dl {
          margin-top: 1.2rem;
          padding-top: 1.5rem;
          border-top: 1px solid #e7e7e0;
          align-items: baseline;
        }

        dt {
          font-size: 1.4rem;
        }
      }

      p:nth-child(1):before {
        content: '일반 회원';
      }

      &.entrance {
        p {
          &::before {
            display: none;
          }
        }
      }

      p {
        strong {
          font-size: 1.8rem;

          &::after {
            font-size: 1.4rem;
          }
        }
      }

      p:nth-child(2):before {
        content: '입학 회원';
        padding-top: 2rem;
      }

      p:nth-child(2) {
        margin-top: 0.5rem;

        strong {
          font-size: 3rem;

          &::after {
            font-size: 2rem;
          }
        }
      }

      p:last-child strong {
        margin-top: 1.2rem;
      }
    }
  }
}

.btn-ynd {
  display: block;
  position: relative;
  width: 100%;
  min-height: 4.2rem;
  margin-top: 2.4rem;
  padding: 1.55rem 0 1.4rem 1.6rem;
  border-radius: 0.3rem;
  background-color: #000;
  box-sizing: border-box;
  overflow: hidden;

  &.benefit {
    padding-left: 3.5rem;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0.8rem;
      bottom: 0;
      width: 2.2rem;
      height: 2.2rem;
      margin: auto 0;
      background: url('#{$img-cdn}/ico_gift_66x66.png') no-repeat 50% 50% / 100% auto;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 1.6rem;
    bottom: 0;
    width: 1.8rem;
    height: 1.8rem;
    margin: auto 0;
    background: url('#{$img-cdn}/ico_arrow_round_54x54g.png') no-repeat 50% 50% / 100% auto;
    letter-spacing: -1px;
  }

  span {
    display: inline-block;
    position: relative;
    padding-right: 6.1rem;
    font-weight: 700;
    font-size: 1.1rem;
    color: #fff;
    white-space: nowrap;

    em {
      padding-left: 0.25rem;
      font-size: 1.1rem !important;
      font-weight: 400;
      color: #fff !important;
    }

    .point {
      font-size: 1.1rem;
      font-weight: 700;
      color: #ffb900;
    }
  }

  + .coupon-box {
    margin-top: 1rem;
  }

  & ~ .curriculum-box {
    padding-top: 0;
    border-top: none;
  }

  & + .btn-group {
    margin-top: 0.3rem !important;
  }
}

.ynd-banner {
  margin-top: 3rem;
}

.coupon-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.4rem;
  padding: 0.6rem 1rem 0.6rem 1.6rem;
  background-color: #f8f8fc;
  border-radius: 0.3rem;

  & ~ .curriculum-box {
    padding-top: 0;
    border-top: none;
  }

  p {
    font-weight: 700;
    font-size: 1.2rem;
    color: #28284b;
  }

  button {
    padding: 0.8rem 0.7rem 0.7rem;
    background-color: #28284b;
    border-radius: 0.3rem;

    span {
      display: inline-block;
      font-weight: 400;
      font-size: 1.2rem;
      color: #fff;

      &::after {
        content: '';
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;
        vertical-align: middle;
        background: url('#{$img-cdn}/ico_download_type2_48x42w.png') 50% 50% / 100% auto no-repeat;
        image-rendering: -webkit-optimize-contrast;
        transform: translateZ(0);
        backface-visibility: hidden;
      }
    }
  }
}

.curriculum-box {
  padding-top: 2.4rem;
  border-top: 0;
  font-size: 1.4rem;
  line-height: 1.3;

  &.alone-box {
    margin-top: 0;
    padding-top: 3rem;
    border-top: 0;
  }

  p {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.9rem;
    font-weight: 400;

    &::before {
      content: '기본구성';
      flex: 0 0 auto;
      width: 7rem;
      padding-left: 2.5rem;
      font-weight: 400;
      background: url('#{$img-cdn}/ico_pack_item01.png') 0 0 /2rem auto no-repeat;
      image-rendering: -webkit-optimize-contrast;
      transform: translateZ(0);
      backface-visibility: hidden;
    }

    &.period-info {
      &::before {
        content: '수강기간';
        background-image: url('#{$img-cdn}/ico_pack_item03.png');
      }
    }

    &.class-level-info {
      &::before {
        content: '대상';
        background-image: url('#{$img-cdn}/ico_pack_item02.png');
      }
    }
  }
}

.detail-thumb-col {
  .detail-thumb {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 68%;
    background-repeat: no-repeat;
    background-size: cover;
    background: #f7f7fc url('#{$img-cdn}/ico_logo_96x96.png') 50% 50% no-repeat;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .detail-thumb-lists {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;
    border-color: #e7e7f0;
    border-width: 1px;
    border-style: solid;
    border-top: none;

    li {
      width: 8rem;
      height: 8rem;

      + li {
        margin-left: 0.2rem;
      }

      button {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &.active {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2.3rem;
            height: 1.7rem;
            transform: translate(-50%, -50%);
            background: url('#{$img-cdn}/ico_check_216x216w.png') 50% 50% / 100% auto no-repeat;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.product-notice-box {
  margin-top: 10rem;

  .inner {
    border: 1px solid #e7e7f0;
  }

  .btn {
    padding: 2.8rem 2.5rem 2.1rem;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 0;
    border-width: 0;
    text-align: left;

    &:after {
      content: '';
      position: absolute;
      top: 2.8rem;
      right: 2.5rem;
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      background: url('#{$img-cdn}/ico_arrow_63x63_b.png') no-repeat 0 0 / 100%;
    }
  }

  .notice-content {
    display: none;
  }

  &.active {
    .btn:after {
      top: 3.2rem;
      transform: rotate(180deg);
    }

    .notice-content {
      display: block;
      padding: 3.2rem 2.5rem;
      font-size: 1.6rem;
      font-family: 'NotoSansCJKkr', sans-serif;
      border-top: 1px solid #e7e7f0;
      word-break: keep-all;

      ul {
        padding-left: 1.6rem;

        li {
          list-style: disc;
          line-height: 1.5;
        }
      }

      ol {
        padding-left: 1.6rem;

        li {
          list-style: decimal;
          line-height: 1.5;
        }
      }

      table,
      th,
      tr,
      td {
        border: 1px solid $gray-500;
      }

      table {
        max-width: 100%;
        width: 100% !important;
      }

      th {
        background-color: $gray-100;
      }

      th,
      td {
        padding: 1.6rem 1.5rem 1.1rem;
        word-break: break-all;
      }

      p {
        line-height: 1.5;
      }

      h4 {
        font-size: 1.6rem;
      }

      h5 {
        margin: 3.2rem 0 0.5rem;
        font-size: 1.6rem;
      }

      img {
        display: block;
        margin: 1rem 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ico-tag {
    padding: 1.4rem 3.2rem;
    font-size: 2rem;
  }

  .detail-card-col {
    display: block;
    position: relative;
    top: 0;
    width: 100%;
    padding-top: 68%;
    background-repeat: no-repeat;
    background-size: cover;
    background: #f7f7fc url('#{$img-cdn}/ico_logo_96x96.png') 50% 50% no-repeat;

    & > div {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .detail-thumb-col {
    display: none;
  }

  .product-col {
    padding: 0;

    .inner-col {
      display: block;
      padding: 0;
    }

    .product-content-col {
      max-width: 100%;
      padding-bottom: 10rem;

      .banner-col {
        margin: 4rem 2.4rem 8rem;

        .banner-slide {
          margin: 0 -2.4rem;

          .swiper-container {
            padding: 0 2.4rem;
            box-sizing: border-box;
          }
        }
      }

      .product-detail {
        padding: 10rem 4.2rem 0;

        h4 {
          margin: 5.7rem 0 0.5rem;
          font-size: 3rem;
        }

        h5 {
          margin: 5.7rem 0 0.5rem;
          font-size: 2.8rem;
        }

        p {
          font-size: 2.6rem;
        }

        a {
          font-size: 2.6rem;
        }

        ol,
        ul {
          margin-top: 2.4rem;

          li {
            font-size: 2.6rem;

            & + li {
              margin-top: 1.2rem;
            }
          }
        }

        table,
        th,
        tr,
        td {
          font-size: 2.6rem;
        }

        & ~ .btn-detail-more {
          padding: 2.9rem 2rem 2.9rem 0;
          margin: 0 auto;
          font-size: 2.6rem;
        }
      }
    }

    .price-info-col {
      position: relative;
      top: auto;
      z-index: 10;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 4rem;
      box-sizing: border-box;

      em {
        font-size: 2.2rem;
      }

      strong {
        font-size: 2.9rem;
      }

      .early {
        width: 11.2rem;
        padding: 0.6rem 0 0.4rem;
        margin-right: 1.5rem;
        font-size: 2.2rem;
        border: 0.2rem solid #e7e7f0;
        border-radius: 0.5rem;
      }

      .score {
        span,
        em {
          font-size: 2.7rem;
        }

        &:before {
          width: 4rem;
          height: 4rem;
          margin-right: 0.7rem;
        }
      }

      .mobile-fixed-box {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;

        .price-box {
          position: relative;
          z-index: 1;
          transform: translateY(100%);
          padding: 3rem 4rem 0;
          background-color: #fff;
          transition: all 0.3s;

          &.active {
            transform: none;
            box-shadow: 1rem 1rem 3rem rgb(134, 134, 134);

            & ~ .btn-group {
              box-shadow: none;
            }

            & ~ .btn-open {
              bottom: 100%;

              &::before {
                transform: none;
              }
            }
          }

          .type {
            &.material {
              border-top: 0;
              margin-top: 1rem;
            }
          }
        }

        .btn-group {
          display: block;
          position: relative;
          bottom: 0;
          left: 0;
          z-index: 10;
          width: 100%;
          margin: 0;
          padding: 2.6rem 3.2rem;
          background-color: #fff;
          box-shadow: 1rem 1rem 3rem rgb(134, 134, 134);
          box-sizing: border-box;

          .btn-flex-form {
            margin-top: 0;
          }

          button {
            flex: 1 1 50%;
            width: auto;
            height: 8.2rem;
            margin: 0;
            font-weight: 400;
            font-size: 2.5rem;
            border: 1px solid #cfcfda;
            border-radius: 0.5rem;

            & + button {
              margin-left: 1.2rem;
            }

            &.btn-ready,
            &.btn-soldout,
            &.btn-waiting {
              border: none;
            }
          }

          .btn-share {
            flex: 0 0 auto;
            width: 8.2rem;
            margin-left: 0;
            background: #f7f7fc url('#{$img-cdn}/ico_share_164x164.png') center / contain no-repeat;
            border: 0;
            text-indent: -9999rem;
            image-rendering: -webkit-optimize-contrast;
            transform: translateZ(0);
            backface-visibility: hidden;
          }

          .btn-cart {
            &.active {
              max-width: 90% !important;
            }
          }
        }

        .btn-open {
          position: absolute;
          bottom: 13.4rem;
          left: 50%;
          z-index: 10;
          width: 14rem;
          height: 4.3rem;
          margin-left: -6.1rem;
          background-color: #fff;
          background: url('#{$img-cdn}/ico_trapezoid_540x180w.png') center / 100% auto no-repeat;

          &::before {
            content: '';
            position: absolute;
            top: 10%;
            left: 50%;
            width: 5rem;
            height: 5rem;
            margin-left: -2.5rem;
            background: url('#{$img-cdn}/ico_arrow_50x50g.png') center / 100% auto no-repeat;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .price-box {
    mark {
      margin-right: 1rem;
      font-size: 2.7rem;
    }

    p:before {
      content: '';
      font-size: 2.5rem;
      color: #28284b;
    }

    .discount {
      font-size: 2rem;
    }

    .type {
      p:before {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 1.6rem;
        margin: auto 0;
      }
      p:first-child {
        margin-top: 2.5rem;
      }

      p:last-child strong {
        font-size: 4.4rem;

        &::after {
          font-size: 3rem;
        }
      }

      &.material {
        margin: 4.4rem 0 5.6rem;
        padding: 2rem 0;
        font-size: 2.9rem;

        div,
        span {
          display: inline-block;
          flex: 0 0 30%;
          font-size: 2.9rem;
          color: #000;
        }

        .material-counter {
          flex: 70% !important;
          font-weight: 400;
          text-align: right;
        }

        & button {
          display: inline-block;
          width: 7.8rem;
          height: 7.8rem;
          padding: 0 0 0.5rem;
          font-size: 5rem;
          line-height: 2rem;
          background-color: #f7f7fc;
          border: 1px solid #e7e7f0;
        }

        div span {
          width: 12.4rem;
          text-align: center;
          font-size: 2.6rem;
        }
      }

      &.membership {
        dl {
          display: flex;
          justify-content: space-between;
          align-items: center;

          dd strong {
            &:after {
              font-size: inherit;
            }
          }

          &:first-child {
            margin-top: 3.3rem;

            dd strong {
              font-size: 2.9rem;
            }
          }

          &:last-child {
            dd {
              mark {
                font-size: 2.7rem;
              }

              strong {
                font-size: 4.2rem;
              }
              span {
                font-size: 2.9rem;
                &.percent {
                  font-size: 2.7rem;
                }
              }

              em {
                font-size: 2rem;
              }
            }
          }

          & + dl {
            margin-top: 2rem;
            padding-top: 2rem;
          }

          dt {
            font-size: 2.5rem;
          }
        }

        p {
          strong {
            color: #8d8da0;
            font-size: 2.9rem;
            text-decoration: line-through;

            &::after {
              font-size: 2.9rem;
            }
          }

          .month {
            position: absolute;
            bottom: -3rem;
            right: 0;
            color: #717188;
            font-size: 2rem;

            &::after {
              content: '개월 할부';
            }
          }
        }
        p:nth-child(2):before {
          content: '일반 회원';
        }

        p:last-child:before {
          content: '입학 회원';
          padding-top: 2rem;
        }

        p:nth-child(2) strong {
          color: #000;
          font-size: 4.4rem;
          text-decoration: none;

          &::after {
            font-size: 4.4rem;
          }
        }

        p:last-child strong {
          margin-top: 1.2rem;
        }
      }
    }
  }

  .btn-ynd {
    margin-top: 3.2rem;
    padding: 2.9rem 3rem 2.7rem 3.3rem;
    border-radius: 0.5rem;
    text-align: left;

    &.benefit {
      padding-left: 7.3rem;

      &::after {
        left: 2.2rem;
        width: 4.4rem;
        height: 4.4rem;
      }
    }

    &::before {
      right: 3.4rem;
      width: 3.8rem;
      height: 3.8rem;
    }

    span {
      padding-right: 5.2rem;
      font-size: 2.3rem;
      font-weight: 700;
      letter-spacing: -0.05rem;

      em {
        padding-left: 0.5rem;
        font-size: 2.3rem !important;
      }

      .point {
        font-size: 2.3rem;
        font-weight: 700;
      }
    }

    + .coupon-box {
      margin-top: 2.5rem;
    }
  }

  .coupon-box {
    margin-top: 3rem;
    padding: 1.5rem 2rem 1.5rem 3rem;
    border-radius: 0.5rem;

    p {
      font-size: 2.4rem;
    }

    button {
      padding: 1.5rem 1.7rem;
      background-color: #28284b;
      border-radius: 0.5rem;

      span {
        font-size: 2.4rem;

        &::after {
          width: 3rem;
          height: 3rem;
          margin-left: 1rem;
        }
      }
    }
  }

  .curriculum-box {
    padding-top: 3.9rem;
    margin-top: 3.3rem;
    font-size: 2.5rem;
    border-top: 1px solid #e7e7e0;

    p {
      margin-bottom: 3.6rem;
      font-weight: 400;
    }

    p:before {
      width: 11.7rem;
      padding-left: 5.8rem;
      background-size: 3.5rem;
    }

    p:nth-child(2):before {
      content: '수강기간';
      background-image: url('#{$img-cdn}/ico_pack_item03.png');
    }

    p:nth-child(3):before {
      content: '대상';
      background-image: url('#{$img-cdn}/ico_pack_item02.png');
    }
  }

  .product-notice-box {
    margin-top: 8rem;
    padding: 0 4rem;

    .btn {
      padding: 4.3rem 3.5rem 3.7rem;
      font-size: 2.6rem;

      &:after {
        top: 4.5rem;
        right: 3.5rem;
        width: 2.2rem;
        height: 2.2rem;
      }
    }

    &.active {
      .btn:after {
        top: 5.1rem;
      }

      .notice-content {
        padding: 4.4rem 3.5rem;
        font-size: 2.6rem;

        ul,
        ol {
          padding-left: 2.7rem;
        }

        h4 {
          font-size: 2.6rem;
        }

        h5 {
          margin-top: 5.7rem;
          font-size: 2.6rem;
        }
      }
    }
  }
}
