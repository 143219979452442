.priceInfoBox {
  padding-top: 8rem;

  h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }

  @media screen and (max-width: 1024px) {
    padding: 5rem 4.2rem;
    border-top: 1.2rem solid #ececf6;
  }
}

.priceBox {
  display: flex;
  width: 100%;
  margin-top: 3rem;
  border-top: 0.3rem solid #000;

  dl {
    flex: 1;

    &.plus {
      dd {
        &:before {
          content: '+';
        }
      }
    }

    &.minus {
      dd {
        &:before {
          content: '-';
        }
      }
    }

    &.result {
      dd {
        &:before {
          content: '=';
        }
      }
    }

    dt {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      height: 7rem;
      border-bottom: 0.1rem solid #e7e7f0;

      strong {
        font-weight: 400;
      }
    }

    dd {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 1.6rem;
      height: 14.6rem;
      border-bottom: 0.1rem solid #e7e7f0;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1.2rem;
        margin: auto;
        width: 2.4rem;
        height: 2.4rem;
        font-size: 2.4rem;
        color: #999;
      }

      strong {
        display: block;
        width: 100%;
        position: relative;
        font-size: 2.6rem;

        &:after {
          content: '원';
          font-size: 2.2rem;
        }
      }

      :global(.point) {
        color: #ff5647;
      }

      span {
        display: block;
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin: 0.5rem 0 0;
        color: #717188;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
    border-top: 0;

    dl {
      display: flex;
      flex: auto;
      flex-shrink: 0;
      width: 100%;
      box-sizing: border-box;

      &.plus {
        dd {
          &:before {
            display: none;
          }
        }
      }

      &.minus {
        dd {
          strong {
            &:before {
              content: '-';
            }
          }
          &:before {
            display: none;
          }
        }
      }

      &.result {
        border-top: 0.1rem solid #dfdfea;
        margin-top: 3.4rem;
        padding-top: 3.4rem;

        dd {
          &:before {
            display: none;
          }

          strong {
            font-size: 4rem;

            &:after {
              font-size: 4rem;
            }

            span {
              margin-top: 1rem;
              font-size: 2.2rem;
            }
          }
        }
      }

      dt {
        border-bottom: 0;
        justify-content: flex-start;
        font-size: 2.6rem;

        strong {
          font-weight: 700;
          font-size: 3rem;
        }
      }

      dd {
        flex: auto 1;
        justify-content: flex-end;
        height: auto;
        border-bottom: 0;

        strong {
          display: inline-block;
          width: auto;
          min-width: 44rem;
          font-weight: 700;
          font-size: 2.6rem;
          text-align: right;
        }
      }
    }
  }
}
