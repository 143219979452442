.deviceSection {
  padding-top: 3.8rem;

  .deviceTitle {
    font-size: 1.4rem;
    color: #9999aa;

    span {
      color: #000;

      & + span {
        display: inline-block;
        margin-left: 0.25rem;
      }
    }
  }

  .deviceBox {
    position: relative;

    ul + ul {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      margin: 0;
      box-sizing: border-box;
      box-shadow: 0 0 1rem #eee;
    }
  }

  .deviceList {
    margin-top: 1.5rem;

    li {
      position: relative;
      min-height: 9.6rem;
      font-size: 1.6rem;
      color: #a0a0b6;
      border: 0.2rem dashed #cfcfda;
      border-radius: 0.6rem;
      box-sizing: border-box;
      text-align: center;

      & + li {
        margin-top: 1.5rem;
      }

      &.active {
        border: none;
      }

      p {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1.8rem;
        margin: auto;
      }

      label {
        display: flex;
        align-items: center;
        position: relative;
        height: 9.6rem;
        padding: 0 3.5rem 0 3.6rem;
        background-color: #fff;
        border-radius: 0.6rem;
        box-sizing: border-box;
        cursor: pointer;

        input[type='checkbox'] {
          display: none;

          & + span {
            flex: 0 1 auto;
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1.8rem;
            background-color: #ececf6;
            background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: auto 1rem;
            border: 0.1rem solid #ececf6;
            border-radius: 50%;
          }

          &:checked + span {
            background-color: #ffb900;
            background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/jan/ico_check.svg);
            border-color: #ffb900;
          }

          &:disabled + span {
            background-color: #fff;
            border-color: #ececf6;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 2.2rem;
          bottom: 0;
          width: 6.5rem;
          height: 6.5rem;
          margin: auto;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 100%;
        }

        &.progress dt::before {
          content: '처리중';
          color: #ff5647;
        }
      }

      &.phone label::before {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/my/device/ico_device_phone_65x65.png);
      }

      &.tablet label::before {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/my/device/ico_device_tablet_65x65.png);
      }

      &.desktop label::before {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/my/device/ico_device_desktop_65x65.png);
      }

      dl {
        flex: 1;
        font-weight: 700;
        text-align: left;

        dt {
          margin-top: 0.4rem;
          font-size: 1.4rem;
          color: #000;

          &::before {
            content: '정상';
            display: block;
            margin-bottom: 0.3rem;
            font-weight: 700;
            font-size: 1.2rem;
            color: #a0a0b6;
          }
        }

        dd {
          margin-top: 0.7rem;
          font-weight: 400;
          font-size: 1.1rem;
        }
      }
    }
  }

  .deviceNoticeBox {
    margin-top: 3.7rem;
    color: #717188;

    p {
      font-weight: 700;
      font-size: 1.6rem;
    }

    ul {
      margin-top: 1.5rem;

      li {
        font-size: 1.2rem;

        &::before {
          content: '-';
          display: inline-block;
          margin-right: 0.2rem;
          line-height: 1.8;
        }
      }
    }
  }

  .btnReset {
    display: block;
    width: 27rem;
    margin: 5rem auto 0;
    padding: 2.2rem 0;
    font-weight: 400;
    font-size: 2rem;
    background-color: #ffb900;
    border-radius: 0.3rem;
  }

  @media all and (max-width: 1024px) {
    .deviceTitle {
      display: none;
    }

    .deviceList {
      padding: 0 3.1rem;

      li {
        min-height: 20rem;
        border-width: 0.3rem;
        border-radius: 1.4rem;

        p {
          height: 3.2rem;
          font-size: 2.7rem;
        }

        label {
          height: 20rem;
          padding: 0 3.8rem;
          border-radius: 1.4rem;

          input[type='checkbox'] + span {
            display: block;
            width: 5rem;
            height: 5rem;
            margin-right: 3rem;
            background-size: auto 2.1rem;
            border-width: 0.4rem;
          }

          &::before {
            right: 4.4rem;
            width: 12.5rem;
            height: 12.5rem;
          }
        }

        dl {
          dt {
            font-size: 2.9rem;

            &::before {
              margin-bottom: 0.8rem;
              font-size: 2.5rem;
            }
          }

          dd {
            margin-top: 1.4rem;
            font-size: 2.3rem;
          }
        }

        & + li {
          margin-top: 2rem;
        }
      }
    }

    .deviceNoticeBox {
      margin-top: 11.7rem;
      padding: 0 5.2rem;

      p {
        font-size: 2.9rem;
      }

      ul li {
        font-size: 2.5rem;
      }
    }

    .btnReset {
      width: 88%;
      padding: 3.4rem 0;
      font-size: 2.7rem;
    }
  }
}
