$logoRebrand: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/img_logo_474x113.png';
$icoClose: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_close_201x201.png';
$HOST_CDN: 'https://english.yanadoocdn.com/upload/yanadoo/new/';

.header {
  position: sticky;
  width: 100%;
  height: auto;
  font-size: 15px;
  z-index: 21;
  top: 0;
  transition: top 0.3s ease;
  :global .btn-login {
    display: block;
    position: relative;
    padding: 16px 0 16px 16px;
    font-size: 15px;
    font-weight: 500;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -9px;
      background-color: #cfcfda;
    }
  }
}
.innerCol {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-items: center;
}
.logo {
  width: 16rem;
  height: 3.9rem;
  background: url($logoRebrand) no-repeat 0 0 / 100%;

  a {
    display: block;
    height: inherit;
  }
}
.B2bLink {
  display: block;
  padding: 16px;
  position: relative;
  font-size: 15px;
  font-weight: 500;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -9px;
    background-color: #cfcfda;
  }
}
.sideMenu {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  > button {
    flex: none;
  }
}
@media only screen and (max-width: 1024px) {
  .logo {
    width: 24.8rem;
    height: 5.7rem;
    margin: 0 0 4.9rem;
  }
  .innerCol {
    flex-direction: column;
    align-items: flex-start;
  }
  .sideMenu {
    right: 21px;
    top: 16px;
    position: absolute;
    z-index: 10;
  }
  .B2bLink {
    display: none;
  }
}
