.couponBox {
  padding: 4rem 3.2rem;
  height: auto;

  strong {
    font-weight: 700;
    font-size: 2.2rem;
  }

  div {
    margin-top: 1.8rem;
    min-height: 7rem;

    p {
      font-size: 1.9rem;

      & + p {
        margin-top: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 5.5rem 4.2rem;

    strong {
      font-size: 3.6rem;
    }

    div {
      margin-top: 3.2rem;
      min-height: 21rem;

      p {
        font-size: 3rem;

        & + p {
          margin-top: 1.8rem;
        }
      }
    }
  }
}

.urlButton {
  width: 100%;
  max-width: 100%;
  height: 6.4rem;
  margin-top: 2.9rem;
  font-weight: 400;
  font-size: 1.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #000;
  border-color: #ffb900;
  background-color: #ffb900;

  @media screen and (max-width: 1024px) {
    font-size: 2.7rem;
    height: 10rem;
    margin-top: 6rem;
  }
}
