.termsContent {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.45;
}
.termsContent h2 {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
.termsContent h3 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}
.termsContent strong[data-renderer-mark='true'] {
  display: block;
}
