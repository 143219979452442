$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

section.product {
  margin: 0 auto;
  padding: 0 0 20rem;
}

/* 상품상세 탭 */
.product-list-col {
  position: relative;
  z-index: 8;
  transition: all 0.3s;

  &.fixed {
    position: fixed;
    top: 7.2rem;
    max-width: 78.6rem;
    width: calc(100% - 44rem);
  }

  ul {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    li {
      flex: 1;
      border-right: 1px solid #ddd;
      background-color: #fff;
      text-align: center;

      &:first-child {
        border-left: 1px solid #ddd;
      }

      button {
        display: block;
        padding: 1.6rem 0;
        width: 100%;
        font-weight: 400;
        font-size: 1.5rem;
      }

      &.active {
        background: #28284b;
        border-color: #28284b;

        button {
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}

.point-curriculum,
.point-review,
.point-question {
  width: 100%;
  height: 0;
  z-index: -1;
}
/* 상품 구성 */
.composition-col {
  padding: 10rem 0 0;

  h3 {
    margin: 0 0 2rem;
    font-size: 2.5rem;
    font-weight: 700;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      position: relative;
      flex-basis: 49.5%;
      max-width: 49.5%;
      min-height: 10rem;
      margin: 0 1% 1% 0;
      border: 1px solid #ddd;
      padding: 2rem 3rem;
      border-radius: 0.5rem;
      box-sizing: border-box;
      overflow: hidden;

      &:nth-child(even) {
        margin-right: 0;
      }

      .thumbnail {
        flex: 0 0 6.6rem;
        width: 6.6rem;
        height: 6.6rem;
        border-radius: 50%;
        vertical-align: middle;
        overflow: hidden;
      }

      .content {
        flex: 0 1 auto;
        padding: 0;
        vertical-align: middle;

        span {
          display: inline-block;
          font-size: 1.4rem;
          color: #717188;

          &.teacher {
            &:before {
              content: '';
              display: inline-block;
              height: 0.8rem;
              border-left: 1px solid #dfdfea;
              margin: 0 0 0 0.5rem;
              padding: 0 0.5rem 0 0;
              vertical-align: middle;
            }
          }

          &:first-of-type:before {
            display: none;
          }
        }

        strong {
          font-size: 1.6rem;
        }

        p {
          display: -webkit-box;
          font-size: 1.6rem;
          font-weight: 700;
          color: #28284b;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .price {
          span {
            display: inline-block;
            margin: 0 0 0 0.5rem;
            font-size: 1.2rem;
            font-weight: 400;
            color: #b7b7c8;
            text-decoration: line-through;
          }

          font-size: 1.6rem;
          font-weight: 700;
          color: #000;
        }

        *:last-child {
          margin: 0.6rem 0 0;
        }
      }

      label {
        display: flex;
        width: 90%;
        align-items: center;
        cursor: pointer;
      }

      input[type='checkbox'] {
        + em {
          display: block;
          position: absolute;
          top: 0.8rem;
          right: 0.8rem;
          width: 2.2rem;
          height: 2.2rem;
          font-size: 0;
          background: url('#{$img-cdn}/ico_check_off_96x96.png') no-repeat 0 0 / 100%;
        }

        &:checked + em {
          background: url('#{$img-cdn}/ico_check_on_96x96.png') no-repeat 0 0 / 100%;
        }

        display: none;
      }
    }
  }

  .list-more-box .btn-more {
    margin-top: 1rem;
    padding: 1.6rem 0;
  }
}

/* .composition-col .btn-more:after {display:inline-block; content:''; width:1.4rem; height:1.4rem; margin:0 0 0 0.5rem; background:url('#{$img-cdn}/ico_arrow_14x14b.png') no-repeat 0 0 / 100%; transform:rotate(-180deg)} */

/* 강사 소개 */

.teacher-col {
  position: relative;
  padding-top: 10rem;

  h3 {
    padding: 0 0 1.8rem;
    font-size: 2.5rem;
    font-weight: 700;
    border-bottom: 0.3rem solid #000;
  }

  .tab-menu-area {
    float: right;
    width: 60%;
    margin-top: 2.5rem;
    text-align: right;
    &.tab-slide {
      position: absolute;
      right: 0;
      top: 15rem;
    }
  }

  .tab-menu {
    display: block;
    float: none;
    position: relative;
    padding: 0 3.7rem;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;

    .swiper-slide {
      text-align: center;
    }

    .btn-slide {
      position: absolute;
      top: 50%;
      width: 1.7rem;
      height: 2.1rem;
      padding: 0.45rem;
      margin-top: -0.7rem;
      text-indent: -9999em;
      background-image: url('#{$img-cdn}/ico_arrow_8x14b.png');
      background-size: 0.8rem auto;
      background-position: 50% 50%;
      background-repeat: no-repeat;

      &.prev {
        left: 0.45rem;
        transform: rotate(180deg);
        transform-origin: center;
      }

      &.next {
        right: 0.45rem;
      }
    }

    li {
      position: relative;
      display: inline-block;
      width: 8rem;
      height: 8rem;
      font-size: 1.4rem;
      font-weight: 700;
      color: #fff;
      line-height: 8rem;
      border: 1px solid #ddd;
      border-radius: 50%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      text-align: center;
      cursor: pointer;
      vertical-align: middle;
      box-sizing: border-box;

      & + li {
        margin-left: 2rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #28284b;
        opacity: 0.7;
        border-radius: 50%;
      }

      &.active {
        font-size: 0;

        &::before {
          display: none;
        }
      }

      button {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 100%;
        color: #fff;
      }
    }
  }

  .tab-content {
    display: none;
    position: relative;

    &.active {
      display: block;
    }

    h4 {
      display: table;
      table-layout: fixed;
      max-width: 37%;
      min-height: 13rem;
      padding: 2rem 2.5rem;
      font-weight: 300;
      font-size: 2.2rem;
      box-sizing: border-box;

      strong {
        display: table-cell;
        position: relative;
        vertical-align: middle;
        font-size: 2.4rem;
        font-weight: 700;

        &:before {
          content: '';
          display: block;
          width: 2.5rem;
          height: 2.5rem;
          margin: 0 0 1rem;
          background: url('#{$img-cdn}/ico_balloon_75x75.png') no-repeat 0 0 / 100%;
        }
      }
    }

    .history {
      padding: 2.5rem;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.5;
      border-width: 1px 0;
      background: #f7f7fc;

      div {
        & + div {
          margin-top: 2.8rem;
        }
      }
    }
  }
}

/* 커리큘럼 */
.curriculum-col {
  padding-top: 10rem;

  .curriculum-lists {
    & + .curriculum-lists {
      margin-top: 7rem;
    }
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 700;
    padding: 0 0 1.8rem;
    border-bottom: 0.3rem solid #000;
  }

  h4 {
    padding: 1rem 0 1.8rem;
    font-size: 2rem;
    font-weight: 700;
    color: #a0a0b6;
    border-bottom: 0.3rem solid #000;
  }

  .item {
    border-bottom: 1px solid #e7e7f0;
  }

  .accordion-item {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .item {
    .title {
      position: relative;
      display: flex;
      align-items: center;
      width: 95%;
      padding: 2.6rem 2rem;
      background-color: #fff;
      box-sizing: border-box;
      cursor: pointer;
      outline: none;
      transition: background-color 0.6s ease;

      strong {
        width: 9rem;
        font-weight: 700;
        font-size: 1.6rem;
        color: #000;
      }

      span {
        display: inline-block;
        margin: 0 1.1rem 0 0;
        font-weight: 700;
        font-size: 1.6rem;
        color: #000;
      }

      &.open + .accordion-item {
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;
      }
    }

    table {
      table-layout: fixed;
      width: 100%;
      position: relative;
      background: #f7f7fc;
      text-align: center;

      tr {
        &:first-child {
          td {
            padding-top: 2.5rem;
          }
        }

        &:last-child {
          td {
            padding-bottom: 1.5rem;
          }
        }
      }

      td {
        padding: 0.9rem;
        font-weight: 700;
        font-size: 1.3rem;
        color: #717188;
        vertical-align: top;

        &.tit {
          font-weight: 400;
          color: #000;
          text-align: left;
        }
      }
    }

    .title {
      &:after {
        content: '';
        position: absolute;
        top: 2rem;
        right: -2.5rem;
        display: block;
        width: 3rem;
        height: 3rem;
        background: url('#{$img-cdn}/ico_arrow_down_108x108.png') no-repeat 0 0 / 100%;
        transform: rotate(0deg);
      }

      &.open:after {
        transform: rotate(180deg);
      }
    }
  }

  .btn-free-vod {
    position: relative;
    font-size: 1.3rem;
    font-weight: 700;
    color: #717188;

    &:before {
      content: '';
      display: inline-block;
      width: 3.5rem;
      height: 2.5rem;
      background: url('#{$img-cdn}/ico_free_vod_36x25_v2.png') no-repeat 0 0 / 100%;
      vertical-align: middle;
    }
  }
}

@media all and (max-width: 1024px) {
  .product-list-col {
    &.fixed {
      position: fixed;
      top: 23.4rem;
      width: 100%;
      max-width: 100%;
      z-index: 10;
    }

    ul {
      li {
        button {
          padding: 3rem 0;
          font-size: 2.4rem;
        }
      }
    }
  }

  .composition-col {
    padding: 10rem 4rem 0;

    h3 {
      font-size: 3.2rem;
    }

    ul {
      display: flex;
      flex-direction: column;

      li {
        position: relative;
        flex-basis: auto;
        max-width: 100%;
        min-height: 18.8rem;
        /*flex-grow: 1;*/

        padding: 5.6rem 4rem;

        .thumbnail {
          flex: 0 0 11.4rem;
          width: 11.4rem;
          height: 11.4rem;
        }

        .content {
          span {
            font-size: 2.4rem;
          }

          strong {
            font-size: 2.8rem;
          }

          p {
            font-size: 2.8rem;
          }

          .price {
            span {
              font-size: 2.2rem;
            }

            font-size: 2.8rem;
          }

          *:last-child {
            margin: 1.4rem 0 0;
          }
        }

        input[type='checkbox'] {
          + em {
            display: block;
            position: absolute;
            top: 50%;
            right: 2.8rem;
            width: 4rem;
            height: 4rem;
            background: url('#{$img-cdn}/ico_check_off_96x96.png') no-repeat 0 0 / 100%;
            transform: translateY(-50%);
          }

          &:checked + em {
            background: url('#{$img-cdn}/ico_check_on_96x96.png') no-repeat 0 0 / 100%;
          }
        }
      }
    }

    .list-more-box {
      padding: 0;

      .btn-more {
        padding: 1.9rem 0;
      }
    }
  }

  /* .composition-col .btn-more {height:8rem; padding:2.8rem 0; font-size:2.6rem}
    .composition-col .btn-more:after {display:inline-block; content:''; width:1.4rem; height:1.4rem; margin:0 0 0 0.5rem; background:url('#{$img-cdn}/ico_arrow_14x14b.png') no-repeat 0 0 / 100%; transform:rotate(-180deg)} */

  .teacher-col {
    padding: 10rem 4rem 0;

    h3 {
      padding-bottom: 2.4rem;
      font-size: 3.2rem;
      border-bottom: 0.3rem solid #000;
    }

    .tab-menu-area {
      width: 63%;
      margin-top: 4rem;

      &.tab-slide {
        display: block;
        position: relative;
        top: auto;
        right: auto;
        float: none;
        width: 100%;

        & ~ .tab-content {
          h4 {
            width: 100%;
            max-width: 100%;
            padding: 2rem 3rem;
          }
        }
      }

      .tab-menu {
        padding: 0 2.5rem;

        li {
          width: 10rem;
          height: 10rem;
          line-height: 10rem;

          & + li {
            margin-left: 2.9rem;
          }
        }

        .btn-slide {
          display: none;
        }
      }
    }

    .tab-content {
      h4 {
        min-height: 18rem;
        font-size: 2.6rem;

        strong {
          font-size: 3rem;

          &::before {
            width: 3.3rem;
            height: 3rem;
            margin-bottom: 1.9rem;
          }
        }
      }

      .history {
        padding: 3.5rem;
        font-size: 2.2rem;
      }
    }
  }

  .curriculum-col {
    padding: 10rem 4rem 0;

    .curriculum-lists {
      & + .curriculum-lists {
        margin-top: 3.8rem;
      }
    }

    h3 {
      font-size: 3.2rem;
    }

    h4 {
      padding: 2.2rem 0 3rem;
      font-size: 2.6rem;
      font-weight: 700;
    }

    .item {
      .title {
        padding: 4.2rem 2.5rem 4.2rem 0;

        strong {
          width: 14.7rem;
          font-size: 2.6rem;
        }

        span {
          font-size: 2.6rem;
        }

        &::after {
          top: 3rem;
          right: -4.5rem;
          width: 5.4rem;
          height: 5.4rem;
        }
      }

      table {
        tr {
          &:first-child {
            td {
              padding-top: 4rem;
            }
          }

          &:last-child {
            td {
              padding-bottom: 2.2rem;
            }

            width: 10%;
          }
        }

        td {
          padding: 1.7rem;
          font-size: 2.2rem;
          vertical-align: middle;
        }
      }
    }

    .btn-free-vod {
      top: -0.4rem;
      right: 2rem;
      font-size: 0;

      &:before {
        width: 6rem;
        height: 4rem;
      }
    }
  }
}
