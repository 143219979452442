.buttonClose {
  position: absolute;
  top: 1.4rem;
  right: 2rem;
  z-index: 10;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0;
  background-size: 100% auto;
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_close_72x72b.png);
  background-position: 50% 50%;
  background-color: transparent;

  @media screen and (max-width: 1024px) {
    top: 1.8rem;
    right: 3.4rem;
    width: 7.2rem;
    height: 7.2rem;
  }
}

.buttonAll {
  position: absolute;
  top: 1.5rem;
  right: 8.2rem;
  padding: 1rem 1.6rem 1rem 3.65rem;
  line-height: 1.5;
  background-repeat: no-repeat;
  background-size: 18% auto;
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_all_w.png);
  background-position: 18% 54%;
  background-color: #28284b;
  border-radius: 0.4rem;

  span {
    font-size: 1.4rem;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    top: 1.8rem;
    left: 3.4rem;
    right: auto;
    width: 7.2rem;
    height: 7.2rem;
    padding: 0;
    background-size: 100% auto;
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_all_b.png);
    background-position: 50% 50%;
    background-color: transparent;

    span {
      display: none;
    }
  }
}

.imageDetailBox {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3.2rem;

  @media screen and (max-width: 1024px) {
    display: block;
    padding: 0;
  }
}

.thumbButton {
  flex: 0 0 40.7rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 40.7rem;
}

.swiperBox {
  position: relative;
  overflow: hidden;

  :global(.swiper-pagination) {
    display: inline-block;
    width: 8rem;
    position: absolute;
    left: auto;
    bottom: 2rem;
    right: 2rem;
    padding: 1.1rem 0 0.9rem;
    background-color: rgba(40, 40, 75, 0.65);
    border-radius: 0.4rem;
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    white-space: nowrap;
  }

  :global(.swiper-slide) {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }
}

.prevNextBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 3.2rem 3.4rem;
  background-color: #28284b;
  box-sizing: border-box;

  button {
    position: relative;
    font-size: 2.7rem;
    color: #fff;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 3.6rem;
      height: 3.6rem;
      margin-top: -1.8rem;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_arrow_60x60w.png)
        50% 50% / 100% auto no-repeat;
    }

    &.prev {
      padding-left: 3.6rem;

      &::after {
        left: 0;
        transform: rotate(180deg);
      }
    }

    &.next {
      padding-right: 3.6rem;

      &::after {
        right: 0;
      }
    }
  }
}

.detailBox {
  flex: 1 1 auto;
  margin-left: 2.6rem;
  box-sizing: border-box;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 2.7rem;
    font-size: 1.6rem;
    line-height: 1.5;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    word-break: break-word !important;
  }

  a {
    display: inline-block;
    margin-top: 2.9rem;
    padding: 1.6rem 6.55rem 1.3rem;
    background-color: #ffb900;
    border-radius: 0.4rem;
    font-size: 1.6rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
    padding: 3rem 4.2rem;
    font-size: 2.6rem;

    p {
      margin-top: 6.5rem;
      font-size: 2.6rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }

    a {
      margin-top: 3rem;
      padding: 1.9rem 4.8rem 1.7rem;
      font-size: 2.4rem;
    }
  }
}
.imageLists {
  display: flex;
  margin-top: 2.3rem;

  li {
    flex: 0 1 7rem;

    button {
      display: block;
      position: relative;
      width: 100%;
      height: 7rem;
      background-repeat: no-repeat;
      background-size: cover;

      &.active {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: rgba(40, 40, 75, 0.65)
            url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_216x216w.png)
            50% 50% no-repeat;
          background-size: 33% auto;
        }
      }
    }

    & + li {
      margin-left: 0.5rem;
    }
  }
}

.rollingImageBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14.8rem;
  padding: 0 3.2rem;
  background-color: #f7f7fc;
  box-sizing: border-box;

  > button {
    flex: 0 0 2.4rem;
    height: 2.4rem;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_arrow_24x42_b.png)
      50% 50% no-repeat;
    background-size: 46% auto;

    &:disabled {
      opacity: 0.15;
      cursor: default;
    }
  }

  .btnPrev {
    margin-right: 1.5rem;
  }

  .btnNext {
    margin-left: 1.5rem;
    transform: rotate(180deg);
  }

  ul {
    display: flex;
    flex: 0 1 auto;
    width: 100%;

    li {
      flex: 0 1 8.4rem;
      margin: 0.4rem;

      button {
        display: block;
        position: relative;
        width: 100%;
        height: 8.4rem;
        background-repeat: no-repeat;
        background-size: cover;

        &.active {
          &::after {
            content: '';
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            left: -0.5rem;
            bottom: -0.5rem;
            border: 0.5rem solid #ffb900;
          }
        }

        span {
          position: absolute;
          bottom: 0;
          right: 0;
          min-width: 2.8rem;
          padding: 0.64rem 0 0.5rem;
          font-family: 'Inter';
          font-weight: 700;
          font-size: 1.4rem;
          color: #fff;
          background-color: rgba(40, 40, 75, 0.6);
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }
}
