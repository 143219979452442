.agree {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
  text-align: left;

  label {
    cursor: pointer;
  }

  input[type='checkbox'] {
    + span {
      font-size: 16px;
      line-height: 24px;

      &::before {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        background-color: #cfcfda;
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_38x30w.png);
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        vertical-align: top;
        content: '';
      }
    }

    &:checked + span::before {
      background-color: #ffb900;
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_38x30b.png);
    }
  }

  hgroup {
    padding-bottom: 16px;

    + ul {
      padding-top: 24px;
      border-top: 1px solid #cfcfda;

      li {
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }

        .summary {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          background-color: #f8f8f8;
          margin-top: 12px;

          p {
            font-size: 12px;
            line-height: 18px;
            flex: 1;
            padding-right: 16px;
            color: #646464;
          }

          button {
            display: block;
            width: 120px;
            font-size: 12px;
            line-height: 32px;
            background-color: #ccc;
            text-align: center;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .hide {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .agree input[type='checkbox'] + span {
    font-size: 14px;
  }
}

@media screen and (max-width: 430px) {
  .agree hgroup + ul li .summary {
    display: block;

    button {
      width: 100%;
      line-height: 24px;
      margin-top: 8px;
      border-radius: 4px;
    }
  }
}
