@charset "UTF-8";
@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';

.modal-wrap {
  input {
    &::placeholder {
      color: #b7b7c8;
    }
  }

  input[type='checkbox'] {
    & + .chk {
      width: 3.2rem;
      height: 3.2rem;
      margin-right: 1.3rem;
    }
  }

  .btn-cancel {
    position: absolute;
    top: 3.9rem;
    right: 3.9rem;
    width: 3.4rem;
    height: 3.4rem;
    background: url('#{$img-cdn}/ico_cancel_112x112.png') no-repeat 50% 0 / 100%;
    border: none;

    span {
      display: block;
      text-indent: -999rem;
    }
  }

  .modal-content {
    p {
      word-break: keep-all;
    }
  }

  .btn-group {
    // margin-top: 5rem;
    font-size: 1.3rem;

    button {
      background-color: #ffb914;
      border: 1px solid #ffb914;
      width: 100%;
      padding: 1.6rem;
      border-radius: 10rem;

      span {
        position: relative;
      }
    }
  }

  &.review-modal {
    .modal-inner {
      min-height: auto;
    }

    .modal-content {
      padding: 0;
      text-align: left;
    }

    .modal-title {
      padding: 2.3rem 0;
      font-weight: 700;
      font-size: 1.9rem;
      text-align: center;
      border-bottom: 1px solid #e0dfea;
    }

    .btn-cancel {
      top: 2.5rem;
      right: 3.1rem;
      width: 2.5rem;
      height: 2.5rem;
      border: none;
    }

    .user-thumb-box {
      padding: 1.8rem 3.2rem;
      border-bottom: 1px solid #e0dfea;

      dd {
        line-height: 1.1;

        strong {
          font-size: 1.7rem;
        }

        span {
          font-size: 1.3rem;
        }
      }
    }
    textarea {
      width: 100%;
      height: 36rem;
      padding: 1.7rem 3.2rem;
      font-size: 1.7rem;
      line-height: 1.5;
      border: none;
      box-sizing: border-box;
      resize: none;
    }

    .img-thumb-area {
      // padding: 2rem 3.1rem;
      font-size: 1.6rem;
      color: $gray-700;
      background-color: $gray-100;

      em {
        &::before {
          content: '';
          display: inline-block;
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.5rem;
          background: url('#{$img-cdn}/ico_image_81x81.png') no-repeat 50% 0 / 100%;
          vertical-align: middle;
        }
      }
    }

    .btn-group {
      margin: 0;
      padding: 2.5rem 0;

      button {
        display: block;
        width: 41.6rem;
        margin: auto;
        padding: 1.9rem 0;
        font-weight: 700;
        font-size: 1.7rem;
        background-color: $warning;
        border: none;
        border-radius: 0rem;
      }
    }

    .img-thumb-area {
      ul {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        li {
          flex: 0 1 auto;
          position: relative;
          width: 7.8rem;
          height: 7.8rem;
          background-color: $gray-400;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: auto 100%;

          + li {
            margin-left: 0.7rem;
          }
        }

        &.active {
          padding: 0 3.1rem 2rem;
        }
      }

      .btn-cancel {
        width: 1.6rem;
        height: 1.6rem;
        top: 0.3rem;
        right: 0.3rem;
        background: rgba(0, 0, 0, 0.5) url('#{$img-cdn}/ico_cancel_28x28_w.png') no-repeat 50% 50% /
          0.7rem;
        border-radius: 50%;
      }
    }

    .select-btn {
      display: block;
      padding: 2rem 3.1rem;
    }

    input[type='file'] {
      display: none;
    }
  }

  &.event-winner-modal {
    &.alert-type {
      .modal-header {
        border-bottom: none;
      }

      .modal-inner {
        max-height: 35.4rem;
        min-height: 32.4rem;
      }

      .title {
        background-color: $white;
        border-bottom: none;

        &.no-win {
          h1 {
            padding: 7rem 0 13rem;
            background-image: none;
          }
        }
      }
    }

    .title {
      width: 100%;
      background-color: $gray-100;
      border-bottom: 1px solid $gray-400;

      h1 {
        width: 100%;
        padding: 13.3rem 0 3.5rem;
        font-weight: 400;
        font-size: 2rem;
        line-height: 1.3;
        color: $dark;
        background: url('#{$img-cdn}/img_event_complete_360x247.png') no-repeat 50% 3rem;
        background-size: 12rem;
        text-align: center;

        strong {
          display: block;
          padding: 1rem 0;
          font-weight: 700;
          font-size: 2.4rem;
          text-align: center;
        }
      }
    }

    .winner-list-box {
      width: 100%;
      max-width: 750px;
      margin: 0 auto;
      padding: 1rem 3.2rem;
      box-sizing: border-box;

      input[type='text'] {
        width: 100%;
        padding: 1.2rem;
        font-size: 1.9rem;
        border: none;
        border-bottom: 1px solid $gray-400;
        box-sizing: border-box;

        &:focus {
          border-color: $dark;
          outline: 0;
        }

        &.input-value {
          color: #b7b7c8;
        }

        &.active {
          color: $dark;
        }
      }

      dl {
        width: 100%;
        margin-top: 2.6rem;
      }

      dt {
        margin-bottom: 0.5rem;
        font-weight: 400;
        font-size: 1.6rem;

        &::before {
          content: '*';
          display: inline-block;
          color: $danger;
        }
      }

      dd {
        position: relative;
        padding: 1.5rem 0;
        font-size: 1.9rem;

        .btn-address {
          margin-left: 2rem;
          padding: 2rem 2.4rem;
          font-weight: 400;
          font-size: 1.7rem;
          color: $dark;
          background-color: $warning;
          border-radius: 0.5rem;
        }
      }

      .address-box {
        input[type='text'] {
          margin-top: 2rem;
        }
      }

      .flex-box {
        display: flex;

        input[type='text'] {
          flex: 1;
          width: auto;
          margin-top: 0;
        }
      }

      .agree-box {
        position: relative;
        padding: 1.5rem 0;

        button {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          font-size: 1.6rem;
        }

        label {
          display: flex;
          align-items: center;
          padding: 1rem 0;
          font-size: 1.6rem;
          cursor: pointer;

          input[type='checkbox'] {
            & + .chk {
              flex: 0 1 auto;

              + p {
                color: $gray-800;
              }
            }
          }
        }
      }

      .btn-underline {
        height: 3rem;
        font-weight: 400;
        font-size: 1.4rem;
        color: #b7b7c8;
        text-decoration: underline;
      }

      .btn-complete {
        width: 100%;
        height: 6.4rem;
        margin: 2rem auto;
        padding: 1rem 2rem;
        font-weight: 400;
        font-size: 1.7rem;
        border-radius: 0.3rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal-wrap {
    input[type='checkbox'] {
      & + .chk {
        width: 5rem;
        height: 5rem;
        margin-right: 0;

        &:before {
          width: 2.3rem;
          height: 2.1rem;
        }
      }
    }

    &.review-modal {
      .modal-inner {
        min-height: calc(100% - 8.7rem);
      }

      .modal-content {
        display: flex;

        > form {
          flex: 1 1 auto;
        }

        .inner {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }

      .btn-cancel {
        width: 3.4rem;
        height: 3.4rem;
        top: 3.7rem;
        right: 5.3rem;
      }

      .modal-title {
        padding: 4.1rem 0;
        font-size: 3rem;
      }

      .modal-desc {
        flex: 1 1 auto;
      }

      .user-thumb-box {
        flex: 0 1 auto;
        padding: 2.9rem 4.2rem;

        dt {
          width: 8.2rem;
          height: 8.2rem;
        }

        dd {
          line-height: 1.4;

          strong {
            font-size: 2.6rem;
          }

          span {
            font-size: 2rem;
          }
        }
      }

      textarea {
        padding: 3rem 4.2rem;
        font-size: 2.6rem;
      }

      .img-thumb-area {
        font-size: 2.6rem;

        em {
          &::before {
            width: 3.8rem;
            height: 3.8rem;
            vertical-align: middle;
          }
        }

        ul {
          li {
            width: 12.3rem;
            height: 12.3rem;

            + li {
              margin-left: 1.3rem;
            }
          }

          &.active {
            padding: 0 4.2rem 4.2rem;
          }
        }

        .btn-cancel {
          width: 2.6rem;
          height: 2.6rem;
          top: 0.4rem;
          right: 0.4rem;
          background-size: 1.1rem;
        }
      }

      .select-btn {
        padding: 3.1rem 4.2rem 4.2rem;
      }

      .btn-group {
        flex: 0 1 auto;
        padding: 3.6rem 0;

        button {
          width: 88.8%;
          // padding: 3.8rem 0;
          font-size: 2.7rem;
        }
      }

      textarea {
        min-height: calc(100% - 8.7rem);
        max-height: calc(100% - 8.7rem);
        height: 50rem;
      }
    }

    &.event-winner-modal {
      &.alert-type {
        align-items: center;

        .modal-inner {
          width: 64rem;
          max-height: 100%;
          min-height: auto;
          margin: 0 auto;
          border-radius: 2rem;
        }

        .title {
          h1 {
            padding: 17.3rem 0 6.3rem;
            background-position: 50% 3rem;
          }
        }
      }

      .title {
        h1 {
          padding: 21.3rem 0 6.3rem;
          font-size: 3rem;
          background-position: 50% 7rem;
          background-size: 15.5rem;

          strong {
            padding: 1.5rem 0;
            font-size: 3.6rem;
          }
        }
      }

      .winner-list-box {
        input[type='text'] {
          font-size: 2.9rem;
        }

        dl {
          margin-top: 5.4rem;
        }

        dt {
          margin-bottom: 0;
          font-size: 2.5rem;
        }

        dd {
          padding: 2rem 0;

          .btn-address {
            padding: 3.2rem 2.4rem;
            font-size: 2.7rem;
          }
        }

        .address-box {
          input[type='text'] {
            margin-top: 6rem;
          }
        }

        .agree-box {
          padding: 4.5rem 0;

          button {
            font-size: 2.5rem;
          }

          label {
            input[type='checkbox'] {
              & + .chk {
                margin-right: 2rem;

                + p {
                  font-size: 2.5rem;
                }
              }
            }
          }
        }

        .btn-complete {
          height: 10rem;
          font-size: 2.7rem;
        }
      }
    }
  }
}
