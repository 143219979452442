.container {
  background: #0a1521;
  padding: 100px 25px;
  box-sizing: border-box;
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  color: #69a4ff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.5px;
}
.description {
  margin-top: 13px;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px;
}
.list {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  li {
    border-radius: 22px;
    padding: 24px 48px;
    background: rgba(255, 255, 255, 0.12);
    color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 32px;
    img {
      width: 112px;
    }
  }
}
.featureTitle {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.7;
}
.featureDescription {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-top: 8px;
  white-space: pre-line;
}

@media screen and (max-width: 1024px) {
  .list {
    grid-template-columns: none;
    width: 100%;
    li {
      width: 100%;
    }
  }
}
@media screen and (max-width: 750px) {
  .container {
    background: #0a1521;
    padding: 16vw 5.6vw;
  }
  .title {
    color: #69a4ff;
    font-size: 4.8vw;
    letter-spacing: -0.192vw;
    line-height: 7.067vw;
  }
  .description {
    font-size: 7.467vw;
    line-height: 8.533vw;
    letter-spacing: -0.067vw;
  }
  .list {
    margin-top: 10.667vw;
    gap: 3.2vw;
    li {
      border-radius: 3.333vw;
      padding: 5.333vw 6vw;
      background: rgba(255, 255, 255, 0.12);
      color: #fff;
      display: flex;
      align-items: center;
      gap: 6.667vw;
      img {
        width: 19.733vw;
      }
    }
  }
  .featureTitle {
    color: #fff;
    font-size: 3.2vw;
    line-height: 4vw;
    white-space: pre-line;
    opacity: 0.7;
  }
  .featureDescription {
    margin-top: 1.333vw;
    color: #fff;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 5.333vw;
    letter-spacing: -0.067vw;
    white-space: initial;
    word-break: keep-all;
  }
}
