$image-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/';
$travel-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/aiTravel/';

/* Chat */
.chatLayout {
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  * {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
  }

  .chatContainer {
    // height: calc(100% - 56px); TODO: 9/4
    height: calc(100% - 86px);
    overflow-x: hidden;
    overflow-y: auto;
    .contentInner {
      max-width: 750px;
      margin: 0 auto;
      box-sizing: border-box;
    }
    .feedbackWrap {
      padding: 0 12px 32px;
    }
    .feedback {
      overflow: hidden;
      border-radius: 5px;
      background-color: #f7f7fc;

      .feedbackContent {
        padding: 18px 16px;

        ol {
          padding-left: 20px;
          > li {
            list-style: decimal;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        ul {
          padding-left: 20px;
          > li {
            list-style: disc;
            color: #717188;
            font-family: 'Noto Sans CJK KR';
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        p {
          color: #000;
          font-family: 'Noto Sans CJK KR';
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        ol,
        ul,
        p {
          margin-bottom: 20px;
        }
      }

      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        background-color: #17c624;

        .ico {
          display: block;
          width: 16px;
          height: 16px;
          background-image: url('#{$travel-path}ico_feedback.svg');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
      }
    }
    .btnFeedback {
      display: grid;
      place-items: center;
      width: 100px;
      height: 26px;
      border-radius: 50px;
      background-color: #cfcfda;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      line-height: normal;
      position: fixed;
      left: 50%;
      // bottom: 64px; TODO: 9/4
      bottom: 94px;
      transform: translateX(-50%);
      &.active {
        background: #17c624;
      }
    }
  }

  /* chat header */
  .chatHeader {
    position: relative;
    background-color: #fff;
    &::after {
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: 0;
      content: '';
    }
    hgroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 750px;
      height: 84px;
      margin: 0 auto;
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--GrayScale-900---000000, #000);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.18px;
        &::before {
          display: block;
          width: 34px;
          height: 34px;
          margin: 0 12px 0 0;
          background-image: url('#{$image-path}ico_chat.svg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          content: '';
        }
      }
      .hdBtns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      .btnClose {
        color: #8d8da0;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
      }
      .btnTranslate {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
      }
      .btnBlockbar {
        display: block;
        width: 1px;
        height: 12px;
        background-color: #cfcfda;
        margin: 0 16px;
      }
    }
    @media screen and (max-width: 1024px) {
      hgroup {
        height: 104px;
        padding: 0 40px;
        h3 {
          color: var(--GrayScale-900---000000, #000);
          font-size: 28px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: -0.28px;
          padding-top: 10px;
          &::before {
            width: 44px;
            height: 44px;
            margin: -5px 12px 0 0;
          }
        }
        .btnClose {
          font-size: 25px;
          line-height: 38px;
        }
        .btnTranslate {
          font-size: 25px;
          line-height: 38px;
        }
        .btnBlockbar {
          width: 2px;
          height: 18px;
        }
      }
    }
    @media screen and (max-width: 750px) {
      hgroup {
        height: 13.867vw;
        padding: 0 5.333vw;
        h3 {
          color: var(--GrayScale-900---000000, #000);
          font-size: 3.733vw;
          font-weight: 500;
          line-height: 4.267vw;
          letter-spacing: -0.037vw;
          padding-top: 0.667vw;
          &::before {
            width: 5.867vw;
            height: 5.867vw;
            margin: -0.667vw 1.6vw 0 0;
          }
        }
        .btnClose {
          font-size: 3.333vw;
          line-height: 5.067vw;
        }
        .btnTranslate {
          font-size: 3.333vw;
          line-height: 5.067vw;
        }
        .btnBlockbar {
          width: 0.267vw;
          height: 2.4vw;
        }
      }
    }
  }

  /* chat footer */
  .chatFooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    // padding: 6px 0; TODO: 9/4
    padding: 6px 0 36px;
    background-color: #fff;
    z-index: 2;

    .footerInner {
      max-width: 750px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 13px 0 20px;
    }
  }

  /* chat form */
  .chatForm {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .chatInput {
      width: auto;
      height: 44px;
      border: 1px solid #e7e7f0;
      background: #f7f7fc;
      box-sizing: border-box;
      color: #000;

      padding-left: 16px;
      border-radius: 50px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      flex: 1;
      caret-color: #ffb900;

      &::placeholder {
        color: #8d8da0;
      }
    }
    .btnSubmit {
      width: 36px;
      height: 36px;
      svg {
        width: 100%;
        height: 100%;
      }
      .btnDisable {
        transition: all 0.3s;
        fill: #dfdfea;
      }
      .btnActive {
        transition: all 0.3s;
        fill: #28284b;
      }
    }
  }

  /* feed list */
  .feedList {
    padding: 34px 12px 68px;
    &.feedbackActive {
      padding: 34px 12px 34px;
    }
    .feedItem {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &.user {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        border: 0;
      }
    }

    // message common
    .msgThumb {
      margin-top: -4px;
    }
    .msgName {
      color: #a0a0b6;
      // font-family: Inter;
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
      margin-left: 4px;
    }
    .message {
      display: inline-flex;
      padding: 14px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 14px;
      background: #ececf6;
      margin: 4px 0 0 6px;
      max-width: 60%;
      min-width: 145px;
      position: relative;
      white-space: wrap;
      word-break: break-word;
      word-wrap: break-word;

      color: #000;
      // font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    .aiMessage {
      display: grid;
      grid-template-columns: 26px 1fr;
      gap: 6px;

      dl {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .message::before {
        display: block;
        width: 14px;
        height: 27px;
        position: absolute;
        left: -6px;
        top: 7px;
        background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ai_msg_tail.svg')
          center no-repeat;
        background-size: 14px 27px;
        content: '';
      }
    }

    .userMessage {
      background: #ffb900;
      margin: 0;

      &::after {
        display: block;
        width: 14px;
        height: 27px;
        position: absolute;
        right: -6px;
        top: 7px;
        background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/user_msg_tail.svg')
          center no-repeat;
        background-size: 14px 27px;
        content: '';
      }
    }

    .translateText {
      display: inline-flex;
      max-width: 60%;
      padding: 14px;
      border: 1px solid #dfdfea;
      border-radius: 10px;
      margin-top: 5px;
      margin-left: 6px;
      background-color: #fff;
      transition: all 0.3s;

      color: #28284b;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .lottieBox {
    width: 32px;
    height: 18px;
  }
  .lottieBox svg {
    vertical-align: top;
  }
  .lottieBox > div {
    width: 100%;
    height: 100%;
  }

  .btnGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
