$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';

.productItem {
  &:hover {
    button {
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 1.5rem;
        bottom: 1.8rem;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 50%;
        z-index: -1;
        background: #ffb900;
      }
    }
  }

  .thumb {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;

    &:after {
      content: '';
      display: block;
      padding-top: 57%;
    }
  }

  .contents {
    position: relative;
    padding: 4rem 3.5rem 0;
    height: 30rem;
    background: #fff;
    border: 1px solid #cfcfda;
    border-top: 0;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    box-sizing: border-box;
    white-space: break-spaces;

    strong {
      font-size: 2.3rem;
      font-weight: 700;
      line-height: 1.3;
    }
    p {
      margin-top: 1.6rem;
      //height: 7rem;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.5;
      color: #717188;
      //overflow-y: scroll;
    }
    button {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 2.5rem 3.5rem;
      font-size: 1.6rem;
      font-weight: 700;
      border-top: 1px solid #cfcfda;
      text-align: left;
      z-index: 0;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        right: 2.5rem;
        width: 1.8rem;
        height: 1.2rem;
        background: url('#{$img-cdn}/ico_arrow_bar_35x24.png') no-repeat top center / 100%;
      }
    }
  }

  .testInfo {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    &:hover {
      button {
        &::after {
          display: none;
        }
      }
    }

    .thumb {
      border-radius: 1.5rem 1.5rem 0 0;
    }

    .contents {
      padding: 4rem 4.7rem 0;
      height: 43rem;

      strong {
        font-size: 3.8rem;
      }
      p {
        font-size: 2.6rem;
      }
      button {
        padding: 3.3rem 4.7rem 3rem;
        font-size: 3rem;

        &::before {
          position: absolute;
          top: 50%;
          right: 4rem;
          transform: translateY(-50%);
          width: 3.4rem;
          height: 2.5rem;
        }
      }
    }
  }
}
