.tagsBox {
  margin-top: 43px;
  text-align: center;

  > strong {
    font-size: 14px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px 5px;
    margin-top: 16px;

    label {
      span {
        cursor: pointer;
        display: inline-block;
        padding: 7px 12px;
        font-size: 12px;
        color: #28284b;
        border-radius: 20em;
        border: 1px solid #dfdfea;
      }

      input[type='checkbox']:checked + span {
        color: #fff;
        background-color: #28284b;
        border-color: #28284b;
      }
    }
  }
}
