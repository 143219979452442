.counselCompleteModal .popYndContent {
  width: 55rem;
  height: 35.8rem;
  background-color: #fff;
  position: relative;
  p {
    padding: 6rem 0 0 0;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 1.3;
    letter-spacing: -0.1rem;
    text-align: center;
    strong {
      display: block;
      padding-bottom: 3rem;
      font-weight: 700;
      font-size: 3.2rem;
    }
  }
}
.submitButton {
  background: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/applePackage/2022/nov/btn_pc_pop_ynd_content.png);
  background-size: cover;
  color: #fff;
  display: block;
  font-size: 2.6rem;
  font-weight: 700;
  letter-spacing: -1px;
  outline: 0;
  text-align: center;
  width: 100%;
  z-index: 2;
  bottom: 0;
  height: 10rem;
  left: 0;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .submitButton {
    background-color: #ff3000;
    bottom: 0;
    color: #fff;
    font-size: 2.6rem;
    font-weight: 700;
    height: 10rem;
    left: 0;
    letter-spacing: -1px;
    outline: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
    &:after {
      display: none;
    }
  }
}
