.viewBtn {
  display: flex;
  width: 100%;
  padding: var(--padding);
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    padding: var(--padding-mobile);
  }
}

.itemThumbSpan {
  width: 7rem;
  height: 7rem;

  & + & {
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 1024px) {
    flex: 0 0 auto;
    width: 12.6rem;
    height: 12.6rem;

    & + & {
      margin-left: 0.9rem;
    }
  }
}
