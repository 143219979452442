.boardItemBox {
  border-bottom: 0.1rem solid #e7e7f0;

  a {
    display: block;
    position: relative;
    padding: 2.5rem;
    cursor: pointer;

    &.best {
      &::before {
        content: 'Best 질문';
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
        font-size: 1.4rem;
        color: #269cff;
      }
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      margin-bottom: 1.6rem;
      font-weight: 700;
      font-size: 1.6rem;
      color: #000;
      letter-spacing: -0.05rem;

      strong {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 32rem;
        margin-bottom: 1.4rem;
        font-weight: 700;
        font-size: 1.4rem;
        color: #a0a0b6;

        span {
          font-weight: 400;
        }
      }

      em {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: 400;
        color: #a0a0b6;
      }
    }
  }

  .itemInfo {
    display: flex;
    justify-content: flex-start;

    strong {
      flex: 1 auto;
      font-weight: 400;
      font-size: 1.4rem;
      color: #a0a0b6;

      em {
        font-weight: 700;
        color: #17c624;
      }

      span {
        margin-right: 1.2rem;
      }
    }

    & > span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 25rem;
      font-weight: 400;
      font-size: 1.4rem;
      color: #a0a0b6;
      text-align: right;
    }
  }

  @media screen and (max-width: 1024px) {
    a {
      padding: 4.2rem;

      &.best {
        &::before {
          top: 4.2rem;
          right: 4.2rem;
          font-size: 2.5rem;
        }
      }

      p {
        margin-bottom: 2.4rem;
        font-size: 2.9rem;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;

        strong {
          font-size: 2.5rem;
          margin-bottom: 1.5rem;
        }
      }
    }

    .itemInfo {
      strong {
        font-size: 2.5rem;

        em {
          margin-right: 2rem;
        }
      }

      & > span {
        max-width: 30rem;
        font-size: 2.3rem;
        line-height: 1.4;
      }
    }
  }
}
