.toastAlert {
  position: fixed;
  bottom: 66px;
  left: 0;
  right: 0;
  z-index: 999999;
  text-align: center;

  .message {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    display: inline-flex;
    padding: 12px 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    border-radius: 22px;
    background: rgba(40, 40, 75, 0.8);
  }
}
