.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .btnIsMuted {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;

    width: 42px;
    height: 42px;
    overflow: hidden;
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_off_sound.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    white-space: nowrap;
    text-indent: -1000px;

    &.soundOn {
      background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_on_sound.svg');
    }
  }

  @media screen and (max-width: 1024px) {
    .btnIsMuted {
      top: 22px;
      left: 22px;
      width: 62px;
      height: 62px;
    }
  }
  @media screen and (max-width: 750px) {
    .btnIsMuted {
      top: 2.933vw;
      left: 2.933vw;
      width: 8.267vw;
      height: 8.267vw;
    }
  }
}
