.buttonClose {
  position: absolute;
  top: 1.4rem;
  right: 2rem;
  z-index: 10;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0;
  background-size: 100% auto;
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_close_72x72b.png);
  background-position: 50% 50%;
  background-color: transparent;

  @media screen and (max-width: 1024px) {
    top: 1.8rem;
    right: 3.4rem;
    width: 7.2rem;
    height: 7.2rem;
  }
}

.cardLists {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 2.8rem;
  box-sizing: border-box;

  li {
    flex: 0 1 13.4%;
    position: relative;
    margin: 0.4rem;
    padding-bottom: 13.49%;
    background-color: #e7e7f0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    span {
      position: absolute;
      bottom: 0;
      right: 0;
      min-width: 2.8rem;
      padding: 0.64rem 0 0.5rem;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 1.4rem;
      color: #fff;
      background-color: rgba(40, 40, 75, 0.6);
      text-align: center;
      box-sizing: border-box;
    }

    button {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      cursor: pointer;
    }
  }

  & + div {
    height: 1px;
  }

  @media screen and (max-width: 1024px) {
    padding: 3.8rem;

    li {
      flex: 0 1 32.05%;
      padding-bottom: 32.05%;

      span {
        min-width: 4.4rem;
        padding: 1rem 1rem;
        font-size: 2rem;
      }
    }
  }
}
