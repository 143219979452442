@import './variable.scss';

.modal-content {
  &.certificate {
    background-color: #ececf6;

    .notice-box {
      padding: 3rem 3rem 6rem;
      background-color: #ececf6;

      ul {
        margin-top: 1rem;

        li {
          word-break: keep-all;
          color: #717188;
          a {
            color: #717188;
          }
        }
      }
    }
  }
}
.modal-wrap .modal-inner {
  width: 516px;
}

.certificate-check-box {
  padding: 2.2rem 0 3.1rem;
  background-color: #fff;
  text-align: center;
  .check-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
  ul {
    margin: 2rem auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0.5rem;
    row-gap: 0.8rem;

    &:has(input[name='payment']) {
      max-width: 30rem;
    }

    li {
      label {
        span {
          display: inline-block;
          padding: 6px 16px;
          border-radius: 1.6rem;
          border: 1px solid #dfdfea;
          font-size: 14px;
          color: #000;
        }
        input[type='checkbox']:checked + span {
          position: relative;
          background-color: #28284b;
          border-color: #28284b;
          color: #fff;
          &::before {
            content: '';
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 2px;
            background: url('https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_w.svg')
              no-repeat center;
          }
        }
      }
    }
  }
}

.certificate-box {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  .certification-inner {
    padding: 3.8rem 3rem 3rem;
    width: 440px;
  }
  dl {
    position: relative;
    width: 100%;
    padding: 9rem 0 1.4rem;
    background-color: #fff;
    border-radius: 1.2rem;
    box-shadow: 0.1rem 0.1rem 3.4rem rgba(0, 0, 0, 0.1);
    z-index: 2;

    .certificate-ribbon {
      position: absolute;
      display: inline-block;
      top: -37px;
      right: 0;
      left: 0;
      width: 98px;
      height: 69px;
      margin: auto;
      background-size: 100%;
      z-index: 2;
    }

    dt {
      strong {
        display: block;
        width: 100%;
        padding: 0 2rem 0.5rem;
        font-weight: 700;
        font-size: 3.2rem;
        letter-spacing: 0.6rem;
        color: #000;
        box-sizing: border-box;
      }
      em {
        font-weight: 400;
        font-size: 1.6rem;
        color: #000;
      }
    }

    dd {
      position: relative;
      padding: 3rem 0 0rem;
      font-weight: 400;
      font-size: 1.6rem;
      color: #000;

      ul {
        width: 32rem;
        margin: auto;
        padding-left: 3rem;
        text-align: left;

        li {
          display: flex;
          padding-bottom: 14px;
          font-size: 1.6rem;

          &:last-child {
            padding-bottom: 0;
          }

          span {
            flex-basis: 76px;
            display: inline-block;
            min-width: 7.6rem;
            height: 2.2rem;
            margin-right: 44px;
            font-weight: 400;
            color: #717188;
            text-align: justify;

            &::after {
              content: '';
              display: inline-block;
              width: 100%;
            }
            &.word-7 {
              flex-grow: 0.13;
              margin-right: 18px;
            }
          }
          strong {
            font-weight: 400;
            word-break: keep-all;
            flex: 1 1;
            letter-spacing: -0.5px;
          }
        }
      }
      p {
        padding-top: 1.7rem;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.6;
        color: #000;

        strong {
          display: block;
          margin-top: 10px;
          font-weight: 400;
          font-size: 1.3rem;
          color: #717188;
        }
      }
    }
  }
  .image-stamp {
    margin: 20px auto 0;
    width: 122px;
  }

  .btn-flex-form {
    padding: 0 3rem;
  }

  .button {
    @include button(41.6rem, 6.4rem, $dark, $warning, 1.7rem, 0.3rem);
  }
}

@media screen and (max-width: 1024px) {
  .modal-content {
    &.certificate {
      .notice-box {
        padding: 7rem 5.2rem 10rem;
        ul {
          margin-top: 3.1rem;
        }
      }
    }
  }
  .modal-wrap .modal-inner {
    width: 100%;
  }

  .certificate-check-box {
    padding: 3.5rem 0 4.1rem;
    ul {
      margin: 3.6rem auto 0;
      column-gap: 0.8rem;
      row-gap: 1.4rem;

      &:has(input[name='payment']) {
        max-width: 68vw;
      }
      li {
        label {
          span {
            padding: 1.3rem 3rem;
            border-radius: 2.8rem;
            font-size: 2.4rem;
            line-height: 3rem;
          }
          input[type='checkbox']:checked + span {
            &::before {
              width: 1.6rem;
              height: 1.6rem;
              margin-right: 0.4rem;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  .certificate-box {
    .certification-inner {
      width: auto;
      padding: 6.1rem 3.2rem 3rem;
    }

    dl {
      padding: 14.6rem 0 5rem;
      border-radius: 2rem;
      box-shadow: 0.1rem 0.1rem 3.4rem rgba(0, 0, 0, 0.1);
      padding: 15rem 0 0;
      background-size: 68rem;

      .certificate-ribbon {
        top: -6.1rem;
      }

      dt {
        strong {
          padding: 0 3rem 0.9rem;
          font-size: 5rem;
          letter-spacing: 1rem;
        }
        em {
          font-size: 2.5rem;
        }
      }

      dd {
        padding: 6rem 0 2rem;
        font-size: 1.6rem;

        ul {
          width: 50rem;
          padding-left: 3rem;

          li {
            padding-bottom: 2.3rem;
            font-size: 2.5rem;

            span {
              flex-basis: 11.8rem;
              height: 3.5rem;
              margin-right: 5.7rem;
              &.word-7 {
                flex-grow: 0.11;
                margin-right: 2.2rem;
              }
            }
          }
        }
        p {
          padding-top: 7.4rem;
          font-size: 2.5rem;

          strong {
            font-size: 2.1rem;
          }
        }
        &:before {
          bottom: 4.1rem;
          width: 9.5rem;
          height: 2.5rem;
        }
        &:after {
          left: 15rem;
          width: 9.1rem;
          height: 10.4rem;
        }
      }
    }

    .btn-flex-form {
      padding: 0 4.2rem;
    }

    .button {
      width: 66.6rem;
      height: 10rem;
      font-size: 2.7rem;
    }
  }
}
