.thumbnailBox {
  position: relative;
  border-radius: 5px;
  background: #f7f7fc;
  overflow: hidden;
  width: 100%;
}
.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.thumbnail img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.saleStatusBox {
  position: relative;
}
.saleStatusBox::after {
  content: "";
  display: block;
  padding-bottom: 67%;
}
.saleStatusBox > div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.saleStatusText {
  display: inline-block;
  width: 11rem;
  padding: 0.8rem 0;
  font-weight: 700;
  font-size: 1.8rem;
  color: #fff;
  background-color: #ff5647;
  border-radius: 2rem;
  text-align: center;
}
.saleBefore {
  color: #28284b;
  background: #fff;
}
.classLength {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.8rem;
  font-size: 1.2rem;
  line-height: 3.8rem;
  color: #fff;
  background: rgba(59, 59, 77, 0.9);
  text-align: center;
  transform: translateY(100%);
  transition: all 0.3s;
  z-index: 5;
}
.item:hover .classLength {
  transform: none;
}
.earlyBirdText {
  position: absolute;
  right: 0.6rem;
  bottom: 0.6rem;
  width: 6.4rem;
  height: 2.6rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.6rem;
  color: #a0a0b6;
  background-color: #fff;
  border-radius: 0.2rem;
  border: 0.1rem solid #e7e7f0;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
}
.categoryBox {
  margin-bottom: 1rem;
}
.row {
  margin-top: 12px;
  color: #a0a0b6;
  font-size: 12px;
  line-height: 17px;
}
.packageName {
  margin-top: 12px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .picture img {
    height: auto;
    min-height: 22rem;
  }
  .saleStatusBox::after {
    padding-bottom: 68%;
  }

  .saleStatusBox > div {
    height: auto;
  }
  .saleStatusText {
    width: 13.8rem;
    padding: 0.8rem 0;
    font-size: 2.2rem;
  }
  .classLength {
    display: none;
  }
  .earlyBirdText {
    bottom: 1.2rem;
    right: 1.2rem;
    width: 10rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 2.2rem;
    border: 0.2rem solid #e7e7f0;
    border-radius: 0.2rem;
  }
  .packageName {
    margin-top: 0.7vw;
    font-size: 2.9vw;
    line-height: 4.3vw;
  }
  .description {
    font-size: 2.9vw;
    margin-top: 2.1vw;
    line-height: 4.3vw;
  }
}
