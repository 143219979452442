.userBox {
  display: flex;
  height: 7rem;
  border-top: 0.3rem solid #000;
  border-bottom: 0.1rem solid #b7b7c8;

  dl {
    display: flex;
    align-items: center;
    width: 50%;

    dt {
      width: 19rem;
      padding: 0 2.5rem;
      font-size: 1.6rem;
      text-align: left;
    }

    dd {
      flex: 1 auto;
      justify-content: flex-start;
      font-size: 1.6rem;
      text-align: left;

      input {
        font-size: 1.6rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
    padding-top: 2.4rem;
    border-top: 0;
    border-bottom: 0;

    dl {
      display: block;

      dt {
        display: none;
      }

      dd {
        display: block;
        padding-bottom: 0.5rem;

        input {
          font-size: 2.6rem;
          color: #717188;
        }
      }
    }
  }
}

.userInfoBox {
  padding-top: 8rem;

  h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }

  @media screen and (max-width: 1024px) {
    padding: 4.5rem 4.2rem;

    h1 {
      padding-bottom: 2rem;
      font-weight: 700;
      font-size: 3rem;
      color: #000;
    }

    table {
      border-top: 0 !important;

      th {
        display: none;
      }

      td {
        width: 100%;
        font-size: 2.6rem;
      }
    }
  }
}

.infoBox {
  text-align: right;
  padding: 0.4rem 0 1rem;
  font-size: 1.4rem;
  color: #28284b;

  em {
    display: inline-block;

    &:before {
      content: '※ ';
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
