@charset "UTF-8";
@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.modal-wrap {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999999;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #00000066;
  box-sizing: border-box;

  .dimmed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.admin {
    .modal-inner {
      width: 55rem;
      max-height: 70rem;
      min-height: 70rem;
      background-color: transparent;
      border-radius: 0;
      overflow: inherit;

      .btn-close-text {
        padding-top: 0.1rem;
        font-size: 1.4rem;
        color: #fff;
        text-align: left;
      }

      .btn-modal-close {
        top: -1.6rem;
        right: -1.6rem;
      }
    }
  }

  &.detail {
    .modal-inner {
      width: auto;
      min-height: 0;
      max-width: 90rem;
      max-height: 82rem;
      flex-direction: row;
      background-color: transparent;
      border-radius: 0;
      overflow: inherit;
    }

    .modal-image-content {
      max-width: 90rem;
      max-height: 82rem;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &.button {
    width: 100%;
    padding: 5rem;
    justify-content: center;
  }

  &.course {
    .modal-inner {
      //background: #000 url('#{$img-cdn}/bg_course.png') 50% 0 / 100% auto no-repeat;
      background: $dark url('#{$img-cdn}/bg_night.png') no-repeat top center / 100% auto;
      text-align: center;

      .btn-modal-close {
        top: 2.4rem;
        right: 3.6rem;
        width: 2rem;
        height: 2rem;
      }

      .modal-content {
        background-color: transparent;
      }

      .btn-flex-form {
        display: block;
        margin-top: 6.7rem;

        button,
        a {
          display: block;
          width: 100%;
        }

        .btn-yta {
          margin: 3.5rem 0 0 0;
          font-weight: 700;
          font-size: 1.9rem;
          color: #fff;
          text-decoration: underline;
        }
      }

      .course-txt {
        padding: 5rem 0;

        strong {
          display: block;
          font-weight: 700;
          font-size: 3rem;
          color: #fff;

          span {
            font-weight: 700;
            color: $warning;
          }
        }

        p {
          margin-top: 2.3rem;
          font-weight: 400;
          font-size: 2rem;
          line-height: 1.5;
          color: #fff;

          & + p {
            margin: 5rem 0 1.5em;

            span {
              border-bottom: 1px solid $white;
            }
          }
        }
      }

      .month-price-txt {
        display: block;
        margin: 3rem 0 0;
        font-size: 1.5rem;
        color: $gray-100;
      }
    }
  }

  &.vod {
    .modal-inner {
      width: 77rem;
      max-height: 43.5rem;
      min-height: 43.5rem;
      overflow: inherit;
      background-color: transparent;

      .modal-content {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        padding-bottom: 57%;
        overflow: hidden;
        border-radius: none;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.share-modal {
    align-items: center;

    .modal-inner {
      max-height: 100%;
      min-height: auto;
    }

    .modal-content {
      padding: 8.5rem 0;
      text-align: center;

      p {
        font-weight: 400;
        font-size: 2.6rem;
        line-height: 1.3;
      }
    }

    .share-item {
      display: flex;
      justify-content: center;

      li {
        margin: 4rem 1.3rem 0;

        a,
        button {
          width: 8rem;
          height: 8rem;
        }
      }
    }
  }

  &.apply-coupon {
    .modal-inner {
      min-height: 35rem;
    }
  }

  &.modal-image {
    .modal-inner {
      width: 98rem;
      max-height: 69rem;
      min-height: 69rem;
    }

    .modal-header {
      text-align: left;
    }
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 48rem;
    max-height: 81rem;
    min-height: 81rem;
    background-color: #fff;
    border-radius: 1rem;
    box-sizing: border-box;
    overflow: hidden;
  }

  .modal-header {
    flex: 0 0 auto;
    padding: 2.3rem;
    text-align: center;
    border-bottom: 0.1rem solid #dfdfea;

    strong {
      font-weight: 400;
      font-size: 2rem;
    }
  }

  .modal-order-info {
    padding: 4.2rem 3.1rem;

    dl {
      display: flex;

      dt {
        flex: 0 0 auto;
        font-weight: 400;
        font-size: 1.9rem;
        color: #a0a0b6;
      }

      dd {
        flex: 0 1 auto;
        margin-left: 3.2rem;
        font-weight: 700;
        font-size: 1.9rem;
      }

      & + dl {
        margin-top: 1.7rem;
      }
    }
  }

  .modal-order-cancle {
    padding: 3.7rem 3.1rem;
    border-top: 1px solid #dfdfea;

    strong {
      display: block;
      font-weight: 400;
      font-size: 1.6rem;
      text-align: left;
    }

    .basic-textarea {
      margin-top: -1px;
      height: 20.2rem;
      border-top: none;
    }

    .btn {
      margin-top: 2.5rem;
    }
  }
}

.modal-btn-inner {
  width: 48rem;
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;

  .button {
    @include button(100%, 8rem, #000, #fff, 2rem, 0.5rem);
    border-color: $gray-300;
  }

  .btn-lists {
    border-radius: 1rem;
    padding: 4.2rem 3.2rem;
    overflow: hidden;

    .button {
      margin-top: 2rem;
      //   & + .button {

      //   }
    }

    & + .button {
      border-radius: 0;
    }

    label {
      &.button {
        display: block;
        position: relative;
        width: 100%;
        height: 8rem;
        padding: 2.7rem 0;
        color: #000;
        background-color: #fff;
        font-weight: 700;
        font-size: 2rem;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 0.5rem;
        cursor: pointer;
        box-sizing: border-box;
        border-color: #e7e7f0;
      }
    }
  }
}

.modal-content {
  position: relative;
  flex: 1 1 auto;
  max-height: 74rem;
  overflow: auto;
  box-sizing: border-box;
  .installments {
    padding: 0 3.2rem;
    padding-top: 3.7rem;
    font-size: 1.6em;
    p {
      padding-bottom: 2.2rem;
      text-indent: -1rem;
      padding-left: 1rem;
    }
  }
  &.type-fixed-button {
    background-color: #f7f7fc;

    &.white {
      background-color: $white;
    }

    .btn-flex-form {
      padding: 0 3rem;
      background-color: inherit;
    }

    &.register {
      background-color: #fff;

      .coupon-lists {
        padding: 3.2rem;
      }
    }
  }

  .modal-center-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 62rem;
    padding: 0 3rem;
    box-sizing: border-box;

    .modal-center-inner {
      width: 100%;
    }
  }

  .product-lists {
    height: 62rem;
    padding-bottom: 5rem;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      border-bottom: 0.1rem solid $gray-400;

      label {
        display: block;
        position: relative;
        width: 100%;
        padding: 3rem 9.7rem 3rem 3rem;
        box-sizing: border-box;
        cursor: pointer;

        p {
          font-weight: 400;
          font-size: 1.9rem;
        }

        .chk {
          position: absolute;
          top: 50%;
          right: 3.3rem;
          transform: translateY(-50%);
          margin-right: 0;
        }
      }
    }
  }

  .coupon-lists {
    height: 56rem;
    padding: 1rem 2rem 5rem 2rem;
    overflow: auto;
    -ms-overflow-style: none;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      & + li {
        margin-top: 1.5rem;
      }
    }

    & ~ .btn-flex-form {
      margin-top: 0;
      padding-top: 2rem;
      border-top: 1px solid #dfdfea;

      .btn {
        &.disabled {
          cursor: default;
        }
      }
    }
  }

  .register-box {
    position: relative;

    .coupon-code {
      display: block;
      width: 100%;
      padding: 1.8rem 0;
      font-weight: 400;
      font-size: 2.5rem;
      border-bottom: 0.1rem solid #000;
      caret-color: #ffb900;

      &::placeholder {
        color: #b7b7c8;
      }

      &.active {
        border-bottom-color: #ffb900;
      }
    }

    .warning {
      display: none;
      position: absolute;
      bottom: -3.5rem;
      left: 0;
      font-weight: 400;
      font-size: 1.6rem;
      color: #ff5647;

      &.active {
        display: block;
      }
    }
  }

  .coupon-notice {
    margin-top: 19.7rem;

    strong {
      display: block;
      font-weight: 700;
      font-size: 1.7rem;
      color: #717188;
    }

    ul {
      margin-top: 2.5rem;

      li {
        position: relative;
        padding-left: 1rem;
        font-weight: 400;
        font-size: 1.6rem;
        color: #717188;
        line-height: 1.3;

        & + li {
          margin-top: 0.5rem;
        }

        &::before {
          content: '-';
          position: absolute;
          top: 0;
          left: 0;
          color: #717188;
        }
      }
    }
  }
}

.basic-textarea {
  display: block;
  width: 100%;
  height: 25.6rem;
  padding: 1.8rem 1.5rem;
  font-weight: 400;
  font-size: 1.9rem;
  border: 1px solid #cfcfda;
  box-sizing: border-box;
  border-radius: 0;
  resize: none;
  outline: 0;

  &::placeholder {
    color: #b7b7c8;
  }
}

.mobile-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background-color: #757575;

  .inner {
    position: relative;
    height: 100%;

    .swiper-container {
      height: 100%;
      padding-top: 10.8rem;
    }

    .slide-item {
      display: block;
      height: 100%;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .swiper-pagination {
    top: 3.6rem;
    bottom: auto;
    font-weight: 400;
    font-size: 3.1rem;
    color: #fff;
  }

  .swiper-pagination-current {
    font-weight: 700;
  }
}

.modal-delivery-info {
  padding: 4rem 3.2rem;
  background: #f7f7fc;

  dl {
    dt {
      font-weight: 700;
      font-size: 1.9rem;
    }

    dd {
      margin-top: 2rem;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9;
      color: #717188;
    }

    & + dl {
      margin-top: 3.8rem;
    }
  }
}

.modal-form-col {
  padding: 3.8rem 3.2rem;

  > strong {
    display: block;
    font-weight: 700;
    font-size: 1.9rem;
  }

  > dl {
    &:first-child {
      margin-top: 0;
    }
  }

  dl {
    margin-top: 4rem;

    dt {
      position: relative;
      font-weight: 400;
      font-size: 1.6rem;

      &.ico-red-dot {
        &::before {
          content: '*';
          position: absolute;
          top: 0%;
          left: -1.2rem;
          color: #ff5647;
        }
      }
    }

    dd {
      position: relative;
      padding: 1.5rem 0 0;
    }

    .flex-form {
      display: flex;

      input[type='text'] {
        flex: 1 1 auto;
      }

      .txt {
        width: 100%;
        flex: 1 1 auto;
        padding: 2rem 1.2rem;
        font-weight: 400;
        font-size: 1.9rem;
        color: #b7b7c8;
        border-bottom: 0.1rem solid #dfdfea;
        cursor: default;

        &.active {
          color: #000;
        }
      }

      .btn {
        flex: 1 0 auto;
        width: 9.4rem;
        margin-left: 2.4rem;
        white-space: nowrap;
      }
    }

    input[type='text'] {
      width: 100%;
      padding: 1.2rem;
      font-size: 1.9rem;
      border: none;
      border-bottom: 0.1rem solid #dfdfea;
      box-sizing: border-box;

      &:focus {
        border-color: #000;
        outline: 0;
      }

      &::placeholder {
        color: #b7b7c8;
      }
    }

    .scroll-box {
      max-height: 16rem;
      padding: 1.5rem;
      font-weight: 400;
      font-size: 1.9rem;
      color: #000;
      line-height: 1.5;
      border: 0.1rem solid #e7e7f0;
      overflow-y: scroll;
    }
  }

  .btn-flex-form {
    margin-top: 3.8rem;
  }

  .noti {
    padding-top: 0;

    p {
      position: relative;
      margin-top: 1.9rem;
      padding-left: 2rem;
      font-weight: 400;
      font-size: 1.6rem;
      color: #717188;
      line-height: 1.6;

      &::before {
        content: '※';
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 400;
        font-size: 1.6rem;
        color: #717188;
      }
    }
  }
}

.modal-ico-col {
  padding: 5.5rem 5rem 4rem;
  border-top: 0.1rem solid #ececf6;
  background-color: #fff;
  text-align: center;

  .ico-check {
    width: 9rem;
    height: 9rem;
    border: 0.5rem solid #17c624;
    border-radius: 50%;
    background-image: url('#{$img-cdn}/ico_check_99x81_green.png');
    background-size: 3rem auto;
  }

  .txt {
    margin-top: 3.8rem;

    strong {
      display: block;
      font-weight: 700;
      font-size: 2.2rem;
    }

    p {
      margin-top: 0.5rem;
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
}

.post-code {
  display: none !important;
}

.basic-input-check {
  display: block;
  margin-top: 1.9rem;
  cursor: pointer;

  .ico-checkbox {
    position: relative;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    background-color: #ececf6;
    vertical-align: middle;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.3rem;
      height: 1.1rem;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-image: url('#{$img-cdn}/ico_check_38x30b.png');
    }
  }

  input {
    &[type='checkbox'] {
      display: none;

      &:checked {
        + .ico-checkbox {
          background-color: #ffb900;
        }
      }
    }
  }

  span {
    display: inline-block;
    margin-left: 0.9rem;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1.7rem;
  }

  + .btn-flex-form {
    margin-top: 4.5rem;
  }
}

input[type='file'] {
  @include blind;
}

.modal-select-box {
  display: block;
  position: relative;
  width: 100%;
  margin-top: 1.8rem;
  padding: 2rem 5rem 2rem 1.5rem;
  font-weight: 400;
  font-size: 1.9rem;
  color: #b7b7c8;
  border: 1px solid #cfcfda;
  border-radius: 0;
  box-sizing: border-box;
  background: url('#{$img-cdn}/ico_arrow_36x21b.png') 95% 50% / 1.2rem 0.7rem no-repeat;
}
.modal-select-box.active {
  color: #000;
}

.alert-modal-content {
  position: relative;
  width: 48rem;
  min-height: 35rem;
  background-color: #fff;
  border-radius: 1rem;
  z-index: 2;
  overflow: hidden;

  .modal-table-form {
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: 30.2rem;
    padding: 4rem 2rem;
    box-sizing: border-box;
  }

  .table-form-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  img {
    width: 15rem;
    margin: 0 auto;

    & + strong {
      margin-top: 4rem;
    }
  }

  strong {
    display: block;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 1.3;

    & + p {
      margin-top: 1.4rem;
    }

    &.sub-title {
      font-size: 2.4rem;
    }

    &.grey-title {
      font-weight: 700;
      font-size: 1.9rem;
      color: #a0a0b6;

      & + p {
        margin-top: 3rem;
      }
    }
  }

  p {
    padding: 0 3rem;
    font-size: 2.2rem;
    line-height: 1.3;
    text-align: center;
    word-break: keep-all;
    white-space: pre-wrap;

    &.sub-msg {
      font-size: 1.9rem;

      & + .sub-msg {
        margin-top: 1.2rem;
      }
    }
  }

  .btn-flex-form {
    width: 100%;
    flex-shrink: 0;
    margin-top: auto;

    .btn {
      padding: 3.3rem 1rem 3rem;
      font-size: 2rem;
      border-bottom: none;
      border-left: none;
      border-radius: 0 0 0 1rem;
      border-color: #dfdfea;

      &:last-child {
        margin-left: 0;
        border-left: none;
        border-radius: 0 0 1rem 0;
        border-right: none;
      }
    }
  }
}

.btn-modal-close {
  position: absolute;
  top: 0;
  right: 1rem;
  z-index: 10;
  width: 7rem;
  height: 7rem;

  span {
    @include blind;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    left: 0;
    width: 3.4rem;
    height: 1px;
    margin: auto;
    border-bottom: 1px solid #000;
    transform: rotate(45deg);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 3.4rem;
    height: 1px;
    margin: auto;
    border-bottom: 1px solid #000;
    transform: rotate(-45deg);
  }

  &.white {
    top: -6rem;
    right: 0;
    width: 3rem;
    height: 3rem;
    padding: 1rem;
    background: url('#{$img-cdn}/ico_close_90x90w.png') 50% 50% / 100% auto no-repeat;

    &::before,
    &::after {
      display: none;
    }
  }
  &.new {
    &::before,
    &::after {
      display: none;
    }
  }
}

.profile {
  .modal-header {
    border-bottom: none;
  }

  .profile-edit-col {
    padding: 2.3rem 3rem;
    text-align: center;
    box-sizing: border-box;

    p {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 1.4;
      text-align: left;

      span {
        font-weight: 400;
        font-size: 1.7rem;
        color: #a0a0b6;
      }

      + p {
        margin-top: 1.4rem;
      }
    }

    .user-thumb {
      width: 18rem;
      height: 18rem;
      margin-top: 7.3rem;

      &::before {
        display: none;
      }

      button {
        position: absolute;
        width: 6.5rem;
        height: 6.5rem;
        background: url('#{$img-cdn}/ico_pencil_194x194.png') 50% 50% / 100% auto no-repeat;

        &.register {
          bottom: -1.2rem;
          right: -0.8rem;
          z-index: 1;
        }

        &.delete {
          display: none;
          bottom: -2.8rem;
          left: -2.8rem;
          z-index: 1;
        }
      }
    }

    .profile-input-box {
      position: relative;
      margin-top: 3.7rem;

      > span {
        position: absolute;
        top: -1rem;

        display: block;
        font-size: 1.3rem;
        color: $info;
        text-align: left;
      }

      input {
        display: block;
        width: 100%;
        padding: 1.5rem 0;
        font-weight: 400;
        font-size: 2.3rem;
        border-bottom: 0.1rem solid #000;
        caret-color: #ffb900;

        &::placeholder {
          color: #b7b7c8;
        }

        &.active {
          border-bottom-color: #ffb900;
        }
      }

      .valid-box {
        display: flex;
        justify-content: space-between;
        height: 8rem;
        margin-top: 1rem;
      }

      .valid-txt {
        // position: absolute;
        // top: 7.2rem;
        // bottom: -4.4rem;
        // left: 0;
        font-weight: 400;
        font-size: 1.3rem;
        color: $gray-700;
        white-space: pre-wrap;

        &.warning {
          color: #ff5647;
        }
      }

      .input-length {
        // position: absolute;
        // bottom: -2.5rem;
        // right: 0;
        font-size: 1.3em;
        color: #a0a0b6;
      }

      .btn-remove {
        position: absolute;
        right: 0;
        top: 1.5rem;
        // bottom: 1.5rem;
        width: 2.7rem;
        height: 2.7rem;
        // margin: auto;
        background-image: url('#{$img-cdn}/ico_cancel_81x81.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
      }
    }

    .profile-random-box {
      // margin-top: 7.2rem;

      button {
        width: 20.8rem;
        padding: 1.2rem 0;
        font-weight: 700;
        font-size: 1.7rem;
        border: 1px solid #e7e7f0;
        border-radius: 7rem;

        span {
          &::before {
            content: '';
            display: inline-block;
            width: 3.7rem;
            height: 3.7rem;
            margin-right: 0.6rem;
            background: url('#{$img-cdn}/ico_profile_emoticon.png') no-repeat 40% 50% / 100%;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .modal-content {
    max-height: none;
  }
  .btn-flex-form {
    margin-top: 25px;
  }
}

.toggle-box {
  cursor: pointer;

  span {
    display: inline-block;
    position: relative;
    width: 6.2rem;
    height: 3.6rem;
    background-color: #dfdfea;
    border-radius: 6.6rem;
    transition: all 0.6s;

    &::before {
      content: '';
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      bottom: 0.2rem;
      width: 3rem;
      background-color: #fff;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }

  input {
    display: none;

    &:checked {
      + span {
        background-color: #ffb900;

        &::before {
          left: 47%;
        }
      }
    }
  }
}

.coupon-discount-col {
  padding: 5rem 4.2rem 8rem;

  dl {
    & + dl {
      margin-top: 8.4rem;
    }

    dt {
      display: block;
      font-weight: 700;
      font-size: 3rem;

      & + dd {
        margin-top: 3.4rem;
      }
    }

    dd {
      margin-top: 6.8rem;
    }

    .goods-coupon {
      display: flex;
      align-items: flex-start;
      overflow: hidden;

      .thumb {
        position: relative;
        width: 10rem;
        height: 10rem;
        flex: 0 0 auto;
        border-radius: 0.5rem;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(https://dimg.donga.com/wps/NEWS/IMAGE/2020/02/09/99608854.4.png);

        .txt {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 1rem 0;
          font-weight: 700;
          font-size: 2rem;
          background-color: $warning;
          text-align: center;
        }
      }

      .desc {
        flex: 0 1 auto;
        margin-left: 3.6rem;
        overflow: hidden;

        .tit {
          font-weight: 400;
          font-size: 2.2rem;
          color: $gray-900;

          span {
            color: $gray-700;
          }
        }

        .sub-tit {
          margin-top: 1.3rem;
          font-weight: 700;
          font-size: 2.7rem;
        }

        .noti {
          margin-top: 1.4rem;
          font-weight: 400;
          font-size: 2.2rem;
          color: $gray-700;
        }
      }

      &.cart {
        .thumb {
          background-size: 4.7rem 4.8rem;
          background-image: url('#{$img-cdn}/ico_cart_143x147w.png');
          background-color: $gray-900;
        }
      }
    }

    .modal-select-box {
      margin-top: 3.4rem;
    }
  }
}

.toast-box {
  position: absolute;
  right: 0;
  bottom: -2rem;
  left: 0;
  width: 40rem;
  min-height: 5.8rem;
  margin: 0 auto;
  padding: 2rem 0 1.8rem;
  font-size: 1.7rem;
  color: $white;
  background-color: #6b6b7f;
  border-radius: 5rem;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.6s;
  opacity: 0;
  z-index: 1;

  &.active {
    bottom: 4rem;
    transition: all 0.6s;
    opacity: 1;
    z-index: 1;
  }

  p {
    line-height: 1.5;
  }

  &.coupon {
    left: 50%;
    right: auto;
    display: inline-block;
    width: auto;
    min-width: 28rem;
    min-height: 4rem;
    padding: 0.6rem 2rem 0.8rem;
    border-radius: 10rem;
    background-color: #ff5647;
    transform: translateX(-50%);

    &.active {
      bottom: 11.6rem;
    }

    p {
      font-size: 1.8rem;
      white-space: nowrap;

      strong {
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .alert-modal-content {
    width: 64rem;

    strong {
      padding: 1rem;
      font-size: 3.6rem;

      &.sub-title {
        padding: 0;
        font-size: 3.2rem;
      }

      &.grey-title {
        padding: 0;
        font-size: 2.6rem;

        & + p {
          margin-top: 4.5rem;
          font-size: 2.6rem;
        }
      }
    }

    p {
      margin-top: 2rem;
      font-size: 3.2rem;

      &.sub-msg {
        font-size: 2.6rem;
      }
    }

    .btn-flex-form {
      width: 100%;
      flex-shrink: 0;
      margin-top: auto;

      .btn {
        padding: 4.3rem 1rem 4rem;
        font-size: 2.9rem;
      }
    }
  }

  .modal-content {
    max-height: 100%;

    .product-lists {
      height: 100%;
      padding-bottom: 20rem;

      li {
        border-bottom-width: 0.2rem;

        label {
          padding: 5.9rem 13.4rem 5.9rem 4.2rem;

          p {
            font-size: 2.9rem;
          }

          .chk {
            right: 4.2rem;
            width: 5rem;
            height: 5rem;

            &::before {
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
      }
    }

    .coupon-lists {
      height: 100%;
      padding: 2.5rem 3.1rem 20rem;

      & ~ .btn-flex-form {
        padding-top: 3.5rem !important;
      }
    }

    &.type-fixed-button {
      .modal-center-box {
        height: 70vh;
        padding: 0 4rem;
      }

      .btn-flex-form {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        padding: 0 4rem 6rem;
      }

      .no-content {
        margin: 0;
        padding: 0;
        height: calc(100vh - 45rem);
      }

      .register-box {
        .coupon-code {
          display: block;
          width: 100%;
          padding: 1.8rem 0;
          font-weight: 400;
          font-size: 4rem;
          border-bottom: 0.1rem solid #000;

          &::placeholder {
            color: #b7b7c8;
          }
        }

        .warning {
          display: none;
          margin-top: 0.5rem;
          font-weight: 400;
          font-size: 1.6rem;
          color: #ff5647;

          &.active {
            display: block;
          }
        }
      }

      .coupon-notice {
        margin-top: 19.3rem;

        strong {
          font-size: 2.7rem;
        }

        ul {
          margin-top: 2rem;

          li {
            position: relative;
            padding-left: 2rem;
            font-weight: 400;
            font-size: 2.5rem;
            color: #717188;

            & + li {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }

  .btn-modal-close {
    top: 2rem;
    right: 3.4rem;

    &::after {
      width: 4.5rem;
    }

    &::before {
      width: 4.5rem;
    }

    &.white {
      top: 3.8rem;
      right: 4.8rem;
      width: 3.4rem;
      height: 3.4rem;
    }
  }

  .modal-wrap {
    align-items: flex-end;
    justify-content: flex-end;

    &.admin {
      align-items: center;
      justify-content: center;

      .modal-inner {
        width: 57.8rem;
        max-height: 63rem;
        min-height: 64rem;

        .btn-modal-close {
          top: -0.6rem;
          right: -0.6rem;
        }

        .btn-close-text {
          font-size: 2.8rem;
        }
      }
    }

    &.alert {
      align-items: center;
      justify-content: center;
    }

    &.detail {
      display: none !important;
    }

    &.course {
      .modal-inner {
        .btn-modal-close {
          top: 3.7rem;
          right: 5.4rem;
          width: 3.3rem;
          height: 3.3rem;
        }

        .btn-flex-form {
          margin-top: 6.7rem;

          .btn-yta {
            margin-top: 4rem;
            font-size: 3rem;
          }
        }

        .course-txt {
          padding-bottom: 8.1rem;

          strong {
            font-size: 4.8rem;
          }

          p {
            margin-top: 3.9rem;
            font-size: 3.2rem;
          }
        }
      }
    }

    &.vod {
      align-items: center;
      justify-content: center;

      .btn-modal-close {
        top: -6rem;
        right: 1rem;
      }

      .modal-inner {
        width: 80vw;
        max-height: 100%;
        min-height: auto;

        .modal-content {
          padding-bottom: 67%;
        }
      }
    }

    &.share-modal {
      .modal-inner {
        width: 65rem;
        margin: 0 auto;
        border-radius: 2rem;
      }

      .modal-content {
        margin: 0 auto;
        padding: 11rem 0;

        p {
          font-size: 3.5rem;
          line-height: 1.5;
        }
      }

      .share-item {
        width: 90%;
        margin: 0 auto;

        li {
          margin: 3.8rem 1.6rem 0;

          a,
          button {
            width: 10.8rem;
            height: 11rem;
          }
        }
      }
    }

    &.apply-coupon {
      .modal-inner {
        min-height: 67rem;
      }
    }

    &.modal-image {
      .modal-inner {
        width: 100%;
        max-height: 100%;
        height: 100%;
        border-radius: 0;
      }

      .modal-header {
        text-align: center;
      }
    }

    .modal-inner {
      width: 100%;
      min-height: calc(100% - 8.7rem);
      max-height: calc(100% - 8.7rem);
      height: auto;
      border-radius: 2rem 2rem 0 0;
    }

    .modal-header {
      padding: 3.6rem;

      strong {
        font-size: 3.1rem;
      }
    }

    .modal-order-info {
      padding: 5.8rem 4rem;

      dl {
        dt {
          font-size: 3rem;
        }

        dd {
          margin-left: 4.2rem;
          font-size: 3rem;
        }

        & + dl {
          margin-top: 2.8rem;
        }
      }
    }

    .modal-order-cancle {
      padding: 5.4rem 4rem;

      .basic-textarea {
        height: 31.5rem;
      }

      strong {
        font-size: 2.5rem;
      }

      .btn {
        margin-top: 5rem;
      }
    }
  }

  .basic-textarea {
    padding: 3rem 5rem 3rem 3rem;
    font-size: 3rem;
  }

  .modal-select-box {
    margin-top: 2.3rem;
    padding: 3rem 5rem 3rem 3rem;
    font-size: 3rem;
    background-size: 2rem auto;
  }

  .modal-delivery-info {
    padding: 6rem 4.3rem;

    dl {
      dt {
        font-size: 3rem;
      }

      dd {
        font-size: 2.6rem;
      }

      & + dl {
        margin-top: 5.8rem;
      }
    }
  }

  .modal-form-col {
    padding: 5.8rem 4.3rem;

    strong {
      font-size: 3rem;
    }

    dl {
      margin-top: 6rem;

      dt {
        font-size: 2.5rem;

        &.ico-red-dot {
          &::before {
            left: -2rem;
          }
        }
      }

      dd {
        input[type='text'] {
          padding: 2.2rem;
          font-size: 2.9rem;
        }
      }

      .flex-form {
        .btn {
          width: 14.6rem;
        }

        .txt {
          padding: 3.2rem 2.2rem;
          font-size: 2.9rem;
        }

        & + dd {
          margin-top: 1.9rem;
        }
      }
    }

    .noti {
      p {
        padding-left: 3rem;
        font-size: 2.5rem;

        &::before {
          font-size: 2.5rem;
        }
      }
    }

    .scroll-box {
      max-height: 26rem;
      padding: 3.3rem 2.6rem 1.7rem;
      font-size: 2.9rem;
      border: 0.2rem solid #e7e7f0;
    }
  }

  .basic-textarea {
    height: 40rem;
  }

  .basic-input-check {
    margin-top: 3rem;

    .ico-checkbox {
      width: 4rem;
      height: 4rem;

      &::before {
        width: 2.1rem;
        height: 1.7rem;
      }
    }

    span {
      margin-left: 1.5rem;
      font-size: 2.6rem;
    }

    + .btn-flex-form {
      margin-top: 7rem;
    }
  }

  .modal-ico-col {
    padding: 5.5rem 10rem 4rem;

    .ico-check {
      width: 9rem;
      height: 9rem;
      border: 0.5rem solid #17c624;
      background-size: 3rem auto;
    }

    .txt {
      margin-top: 3.8rem;

      strong {
        font-size: 3.4rem;
      }

      p {
        margin-top: 0.5rem;
        font-size: 2.6rem;
      }
    }
  }

  .modal-btn-inner {
    width: 100%;
    background-color: transparent;

    .button {
      height: 11.5rem;
      font-size: 2.9rem;
      border-radius: 1.5rem;
      border-color: #fff;
    }

    .btn-lists {
      position: relative;
      border-radius: 1.5rem;
      padding: 0;

      .button {
        margin-top: 0;
        border-radius: 0;

        &:hover,
        &.active {
          & + .button {
            border-top-color: transparent;
          }
        }

        & + .button {
          margin-top: 0;
          border-top-color: $gray-300;
        }
      }

      & + .button {
        margin-top: 2.1rem;
        border-radius: 1.5rem;
      }

      label {
        &.button {
          height: 11.5rem;
          padding: 4.1rem 0;
          font-size: 2.9rem;
          border-radius: 0;
          border-color: #fff;
          border-bottom-color: $gray-300;
        }
      }
    }
  }

  .profile {
    .profile-edit-col {
      height: 100%;

      p {
        font-size: 4rem;

        span {
          font-size: 2.7rem;
        }

        + p {
          margin-top: 2rem;
        }
      }

      .user-thumb {
        width: 28.2rem;
        height: 28.2rem;

        // &::before {
        //     width: 15.6rem;
        //     height: 15.6rem;
        //     bottom: -4.8rem;
        //     right: -4.8rem;
        // }

        button {
          position: absolute;
          width: 10.2rem;
          height: 10.2rem;

          &.register {
            bottom: -2rem;
            right: -0.8rem;
            z-index: 1;
          }

          &.delete {
            bottom: -4.8rem;
            left: -4.8rem;
            z-index: 1;
          }
        }
      }

      .profile-input-box {
        position: relative;
        margin-top: 5.5rem;

        input {
          padding: 2.8rem 0;
          font-size: 3.5rem;
          border-bottom: 0.2rem solid #000;
        }

        .warning {
          bottom: -4.5rem;
          font-size: 2.1rem;
        }

        .input-length {
          bottom: -4.5rem;
          font-size: 2.4rem;
        }

        .valid-box {
          height: 11rem;
          margin-top: 2.5rem;
        }

        .valid-txt {
          bottom: -7.4rem;
          font-size: 2.1rem;
        }

        .btn-remove {
          width: 4.2rem;
          height: 4.2rem;
          top: 3rem;
        }

        > span {
          font-size: 2.1rem;
        }
      }

      .profile-random-box {
        // margin-top: 11.2rem;

        button {
          width: 32.5rem;
          padding: 1.8rem 0;
          font-size: 2.7rem;

          span {
            &::before {
              width: 5.8rem;
              height: 5.8rem;
            }
          }
        }
      }
    }

    .modal-content {
      &.type-fixed-button {
        .btn-flex-form {
          padding-bottom: 4.9rem;
        }
      }
    }
  }

  .toggle-box {
    span {
      width: 9.5rem;
      height: 5.6rem;
      border-radius: 10.4rem;

      &::before {
        top: 0.4rem;
        left: 0.4rem;
        bottom: 0.4rem;
        width: 4.7rem;
      }
    }
  }

  .toast-box {
    bottom: -3.5rem;
    width: 95%;
    min-height: 9.2rem;
    padding: 2.9rem 0;
    font-size: 2.7rem;

    &.active {
      bottom: 6.3rem;
      z-index: 11;
    }

    &.coupon {
      padding: 1.4rem 4rem 1.6rem;
      border-radius: 4rem;

      &.active {
        bottom: 19rem;
      }

      p {
        font-size: 2.8rem;
      }
    }
  }
}

@media all and (max-width: 750px) {
  .profile {
    .profile-edit-col {
      padding-inline: 5.6vw;
      padding-bottom: 5.6vw;
    }
    .btn-flex-form {
      margin-top: 12.8vw;
    }
  }
}
