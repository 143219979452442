.selectBox {
  display: flex;

  button {
    display: flex;
    font-weight: 500;
    font-size: 1.4rem;
    color: #b7b7c8;
    align-items: center;

    &:first-child {
      &::after {
        content: '';
        display: inline-block;
        width: 0.1rem;
        height: 1.6rem;
        margin-right: 1.8rem;
        margin-left: 1.8rem;
        background: #cfcfda;
      }
    }
  }
  .active {
    font-weight: 700;
    color: #000;
  }

  @media screen and (max-width: 1024px) {
    button {
      font-size: 2.4rem;
      align-items: center;
      &:first-child {
        &::after {
          width: 0.2rem;
          height: 1.8rem;
          margin-right: 1.4rem;
          margin-left: 2.4rem;
        }
      }
    }
  }
}
