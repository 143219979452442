.couponLabel {
  display: block;
  position: relative;

  &.isDisabled {
    > div {
      opacity: 0.6;
      cursor: default;
    }
  }

  input {
    &:checked {
      + div {
        opacity: 1 !important;

        &::before,
        &::after {
          background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_coupon_line.png);
        }

        > div {
          &::before {
            background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_coupon_line.png);
          }
        }
      }
    }

    &:disabled {
      + div {
        opacity: 0.6;
      }
    }
  }
}

.couponItem {
  display: block;
  position: relative;
  width: 100%;
  height: 19.5rem;
  text-align: left;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 2.6rem;
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_coupon_v4.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    box-sizing: border-box;
  }

  &::before {
    left: 0;
    background-position: 0 0;
  }

  &::after {
    right: 0;
    background-position: 100% 0;
  }

  > div {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2.6rem;
      left: 2.6rem;
      z-index: 1;
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_coupon_v4.png);
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: -2.6rem 0;
    }
  }

  &.isDisabled {
    box-shadow: none;

    &::before,
    &::after {
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_coupon_used_v4.png);
    }

    > div {
      &::before {
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_coupon_used_v4.png);
      }

      div:first-child {
        strong,
        p {
          color: #a0a0b6;
          + p {
            color: #a0a0b6;
          }
        }

        span {
          opacity: 0.5;
        }
      }

      div:last-child {
        strong {
          color: #a0a0b6;
          background: #dfdfea;
        }
      }
    }
  }

  &.isDown {
    height: 21rem;

    > div {
      padding-bottom: 1.4rem;
      cursor: default;

      div:first-child {
        strong {
          margin-bottom: 1.8rem;
        }

        p {
          font-size: 1.4rem;
        }

        span {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    height: 33rem;

    &.isDown {
      height: 33rem;
      > div {
        padding-bottom: 2.2rem;

        div:first-child {
          strong {
            margin-bottom: 2rem;
          }

          p {
            font-size: 2.4rem;
          }

          span {
            font-size: 2.4rem;
          }
        }
      }
    }
  }
}

.couponBox {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2.6rem 3.2rem 2.2rem;
  box-sizing: border-box;

  > div {
    position: relative;
    z-index: 1;
  }

  &.isDisabled {
    cursor: default;
  }

  div:first-child {
    flex: 0 0 auto;
    overflow: hidden;

    strong {
      display: inline-block;
      position: relative;
      margin-bottom: 1.4rem;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 1.2;

      &.isChecked {
        &::after {
          content: '';
          position: absolute;
          top: 0.2rem;
          right: -3.3rem;
          width: 2rem;
          height: 2rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_38x30b.png)
            50% 50% no-repeat;
          background-size: 100% auto;
        }
      }
    }

    p {
      font-weight: 700;
      font-size: 1.2rem;
      color: #cfcfda;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      white-space: normal;

      & + p {
        margin-top: 0.2rem;
        font-weight: 400;
        color: #28284b;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        line-height: 1.2;
      }
    }

    span {
      position: absolute;
      top: -2rem;
      right: -2rem;
      padding: 2rem;
      font-weight: 700;
      font-size: 1.4rem;
      color: #28284b;
      text-decoration: underline;

      &.isUsed,
      &.isExpired {
        font-weight: 700;
        font-size: 1.8rem;
        text-decoration: none;
      }

      &.isUsed {
        color: #269cff;
      }

      &.isExpired {
        color: #ff5647;
      }
    }
  }

  div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    strong {
      display: inline-block;
      padding: 0.5rem 0.8rem;
      font-weight: 700;
      font-size: 1.2rem;
      color: #269cff;
      background: #f7f7fc;
      border-radius: 0.4rem;
      vertical-align: middle;

      &.isDisabled {
        color: #717188;
      }
    }

    span {
      display: inline-block;
      margin-left: 1rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: #a0a0b6;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 4rem 5.2rem 3.4rem 5.2rem;

    div:first-child {
      strong {
        margin-bottom: 2rem;
        font-size: 4rem;

        &.isChecked {
          &::after {
            top: 0.2rem;
            right: -5.3rem;
            width: 3.4rem;
            height: 3.4rem;
          }
        }
      }

      p {
        font-size: 2.4rem;
      }

      span {
        top: -4rem;
        right: -4rem;
        padding: 4rem;
        font-size: 2.4rem;

        &.isUsed,
        &.isExpired {
          font-size: 3.2rem;
        }
      }
    }

    div:last-child {
      strong {
        padding: 0.8rem 1.2rem;
        font-size: 2rem;
        border-radius: 0.6rem;
      }

      span {
        margin-left: 1.4rem;
        font-size: 2rem;
      }
    }
  }
}

.couponDownButton {
  margin-left: auto;
  padding: 0.9rem 1.7rem 0.7rem;
  font-weight: 700;
  font-size: 1.4rem;
  color: #fff;
  background-color: #269cff;
  border-radius: 10rem;

  &.isDisabled {
    cursor: auto;
    background-color: #cfcfda;
  }

  @media screen and (max-width: 1024px) {
    padding: 0.9rem 2.6rem 1.1rem;
    font-size: 2.4rem;
    border-radius: 15rem;
  }
}
