.questionIndex {
  margin-top: 63px;
  font-size: 20px;
  font-weight: 700;
  color: #a0a0b6;
}
.circleIndex {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  color: #171720;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.33px;
  margin-right: 14px;
  flex: none;
}
.questionText {
  margin: 11px 0 43px;
  font-size: 20px;
  line-height: 24px;
  color: #a0a0b6;
  white-space: pre-line;
  letter-spacing: -0.33px;
}
.h1 {
  font-size: 32px;
  line-height: 37px;
  letter-spacing: -0.33px;
  font-weight: 700;
  color: #fff;
  white-space: pre-line;
  display: flex;
}
.h2 {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.43px;
  font-weight: 700;
  color: #fff;
  display: flex;
  white-space: pre-line;
}
.h2 u {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}
.typography {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.33px;
  color: #fff;
  white-space: pre-line;
}
.typographyGap {
  margin-top: 17px;
}
.typography u {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}
.button {
  background: #2b2b3b;
  border-radius: 12px;
  height: 80px;
  padding: 27px 20px 27px 28px;
  font-size: 20px;
  letter-spacing: -0.33px;
  font-weight: 700;
  color: #a0a0b6;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttonActive {
  background: #4a93ff;
  color: #fff;
}
.answerInput {
  margin: 17px 0 80px;
  border-bottom: 3px solid #fff;
  font-size: 32px;
  color: #4a93ff;
  width: 100%;
  font-weight: 700;
  letter-spacing: -0.33px;
  height: 48px;
  line-height: 48px;
}
.buttonRow {
  display: grid;
  gap: 16px 0;
  margin-top: 80px;
}
.buttonGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 12px;
}
.buttonGrid button + button {
  margin: 0;
}
.deleteIcon {
  width: 20px;
  height: 20px;
}
.imageWrapper {
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 412px;
  height: 52px;
  margin: 76px auto 0;
}
.imageWrapper + .buttonRow {
  margin-top: 76px;
}
.soundPlayButton {
  position: absolute;
  width: 68px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.audio {
  visibility: hidden;
  height: 0;
}
.video {
  width: 100%;
  margin-top: -7px;
  border-radius: 12px;
  height: 254px;
  border-bottom: 1px solid #171720;
}
.video + .answerInput {
  margin-top: 34px;
}
.video + .buttonRow {
  margin-top: 34px;
}
.introContainer {
  padding: 92px 0 192px;
  color: #fff;
  text-align: center;
}
.introTitle {
  color: #74abff;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  font-weight: 700;
}
.introDescription {
  margin-top: 16px;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -1px;
  font-weight: 700;
  word-break: keep-all;
}
:global .display-m {
  display: none;
}
.introSubDescription {
  margin-top: 24px;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-line;
  text-align: left;
  display: inline-block;
}
.introStartButton {
  margin: -60px auto 0;
  background: #000;
  line-height: 64px;
  border-radius: 32px;
  width: 320px;
  background: #fff;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  display: block;
  z-index: 999;
  position: relative;
}
.introRelativeClass {
  margin-top: 36px;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.4;
  white-space: pre-line;
}
.introImage {
  height: 393px;
  margin-top: 36px;
  max-width: 420px;
}

@media screen and (max-width: 750px) {
  .questionIndex {
    margin-top: 12.4vw;
    font-size: 3.733vw;
    line-height: 4.533vw;
  }
  .circleIndex {
    width: 5.867vw;
    height: 5.867vw;
    font-size: 3.733vw;
    line-height: 6vw;
    letter-spacing: -0.067vw;
    margin-right: 2.667vw;
  }
  .questionText {
    margin: 2.133vw 0 8.933vw;
    font-size: 3.733vw;
    line-height: 4.533vw;
  }
  .h1 {
    font-size: 6.4vw;
    line-height: 7.467vw;
    letter-spacing: -0.067vw;
  }
  .h2 {
    font-size: 5.333vw;
    line-height: 6.667vw;
    letter-spacing: -0.067vw;
  }
  .typography {
    font-size: 4.267vw;
    line-height: 5.6vw;
    letter-spacing: -0.067vw;
  }
  .typographyGap {
    margin-top: 3.2vw;
  }
  .button {
    border-radius: 2.133vw;
    height: 16vw;
    padding: 5.333vw 4.267vw 5.333vw 5.6vw;
    font-size: 4.267vw;
    letter-spacing: -0.067vw;
  }
  .answerInput {
    margin: 3.2vw 0 16vw;
    border-bottom: 0.667vw solid #fff;
    font-size: 6.4vw;
    letter-spacing: -0.503px;
    height: 9.6vw;
    line-height: 9.6vw;
  }
  .buttonRow {
    margin-top: 16vw;
    gap: 3.2vw;
  }
  .buttonGrid {
    gap: 3.2vw 2.4vw;
  }
  .deleteIcon {
    width: 4.267vw;
    height: 4.267vw;
  }
  .imageWrapper {
    max-width: 100%;
    padding: 0 3.333vw;
    height: 10.4vw;
    box-sizing: border-box;
    margin-top: 16.267vw;
  }
  .imageWrapper + .buttonRow {
    margin-top: 15.6vw;
  }
  .soundPlayButton {
    width: 13.333vw;
    height: 13.333vw;
  }
  .video {
    width: 100%;
    height: 50vw;
    border-radius: 2.133vw;
    margin-top: -1.333vw;
  }
  .video + .answerInput {
    margin-top: 7.067vw;
  }
  .video + .buttonRow {
    margin-top: 7.067vw;
  }
  .introContainer {
    text-align: left;
    padding: 17.6vw 0 8.8vw;
  }
  .introTitle {
    font-size: 4vw;
    line-height: 5.2vw;
    border-left-width: -0.1vw;
  }
  .introDescription {
    margin-top: 2.667vw;
    font-size: 8vw;
    line-height: 9.6vw;
    border-left-width: -0.1vw;
  }
  :global .display-m {
    display: block;
  }
  .introSubDescription {
    margin-top: 2.667vw;
    font-size: 2.8vw;
    line-height: 4vw;
    position: relative;
    z-index: 2;
  }
  .introStartButton {
    line-height: 12.8vw;
    font-size: 3.733vw;
    max-width: 64vw;
    width: 100%;
    margin-top: -21.333vw;
    border-radius: 6.4vw;
  }
  .introRelativeClass {
    margin-top: 5.867vw;
    text-align: center;
    font-size: 2.8vw;
    line-height: 4vw;
    position: relative;
    z-index: 2;
  }
  .introImage {
    width: 111.467vw;
    max-width: initial;
    left: 50%;
    margin-left: -22vw;
    position: relative;
    margin-top: -10.667vw;
    z-index: 1;
    min-height: 50vh;
    height: auto;
  }
}
