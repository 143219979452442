.searchSection {
  display: flex;
  justify-content: center;
  padding: 0;
  select {
    display: inline-block;
    width: 13.8rem;
    margin-right: 1rem;
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: #000;
    border: 0.1rem solid #dfdfea;
    background: #fff
      url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_36x21.png)
      calc(100% - 1.3rem) 50% / 1.2rem auto no-repeat;
  }
  input {
    width: 28.6rem;
    height: 3rem;
    padding: 0 1.6rem;
    font-size: 1.4rem;
    background-color: #fff;
    border: 0.1rem solid #dfdfea;
    box-sizing: border-box;
    &::placeholder {
      display: block;
      position: absolute;
      color: #b7b7c8;
    }
  }
  button {
    margin-left: 1rem;
  }
  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    align-items: center;
    margin: 0 4.2rem 0.5rem;
    padding: 1rem;
    background-color: #f7f7fc;
    box-sizing: border-box;
    select {
      width: 18rem;
      padding: 0.5rem 2.7rem;
      font-size: 2.9rem;
      background-color: transparent;
      background-size: 2rem auto;
      border: 0;
    }
    input {
      flex: 1 1 auto;
      height: auto;
      padding: 2.6rem 2rem;
      font-size: 2.9rem;
      background-color: transparent;
      border: 0;
      &::placeholder {
        position: relative;
        padding-top: 0;
      }
    }
    button {
      width: 8rem;
      height: 8rem;
      text-indent: -9999px;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_search_100x100.png)
        no-repeat 50% 50% / 6rem auto !important;
    }
  }
}
