.listDetailSection {
  padding: 8.2rem 0 0;

  pre {
    font-size: 1.6rem;
    line-height: 1.7;
    white-space: pre-wrap;
  }

  .detailContent {
    border-bottom: 0.1rem solid #b7b7c8;
  }

  .detailQuestion {
    padding: 2.9rem 2.8rem;
    font-size: 1.6rem;
    white-space: pre-line;

    img {
      display: block;
    }
  }

  .detailAnswer {
    padding: 2.9rem 2.8rem;
    background-color: #f7f7fc;

    .thumbArea {
      display: flex;
      align-items: center;

      .thumb {
        width: 5.4rem;
        height: 5.4rem;
        margin-right: 1.5rem;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 50%;
        overflow: hidden;
      }

      .desc {
        strong {
          display: block;
          font-weight: 700;
          font-size: 1.7rem;
        }

        p {
          margin-top: 0.6rem;
          font-weight: 400;
          font-size: 1.3rem;
          color: #a0a0b6;
        }
      }
    }
  }

  .detailDownload {
    padding: 1.4rem 2rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: #28284b;
    background-color: #f7f7fc;
    text-align: right;

    &::before {
      content: '첨부파일';
      display: inline-block;
    }

    a {
      margin-left: 1rem;
      font-weight: 400;

      &::after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_download_54x54b.png);
        vertical-align: middle;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0;

    .titleCol {
      display: none;
    }

    .detailContent {
      &.lineUnset {
        border-bottom: none;
      }
    }

    .detailQuestion {
      font-size: 2.6rem;
    }

    .detailAnswer {
      padding: 2.9rem 2.8rem;
      background-color: #f7f7fc;

      .thumbArea {
        .thumb {
          width: 8.2rem;
          height: 8.2rem;
          margin-right: 2.2rem;
        }

        .desc {
          strong {
            font-size: 2.6rem;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }

    .detailDownload {
      padding: 3.7rem 5.2rem;
      font-weight: 400;
      font-size: 2.7rem;

      &::before {
        content: '';
      }

      a {
        display: inline-block;
        position: relative;
        width: 100%;
        margin-left: 0;
        font-weight: 400;
        text-align: left;

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: -1rem;
          right: 0;
          width: 5rem;
          height: 5rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_download_54x54b.png);
        }
      }
    }

    pre {
      font-size: 2.6rem;

      table {
        width: auto !important;
      }
    }

    .detailContent {
      border-bottom: none;
    }

    .qnaListsCol {
      padding: 0;

      .boardBtnGroup {
        width: 90%;
      }
    }
  }
}

.titleBox {
  padding: 0 0 2rem;
  border-bottom: 0.3rem solid #000;

  strong {
    font-weight: 700;
    font-size: 2rem;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.detailTitleBox {
  position: relative;
  padding: 2.5rem 2.8rem;
  border-bottom: 0.1rem solid #e7e7f0;

  .thumbNail {
    width: 7rem;
    height: 7rem;
  }

  .titFlexForm {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subTit {
      flex: 0 1 70%;
      font-weight: 700;
      font-size: 1.6rem;
      color: #28284b;

      &.black {
        font-size: 1.8rem;
        color: #000;
      }
    }

    .info {
      .check {
        font-weight: 700;
        font-size: 1.6rem;
        color: #a0a0b6;

        & + .date {
          margin-left: 1rem;
        }

        &.completed {
          color: #17c624;
        }
      }

      .date {
        font-weight: 400;
        font-size: 1.2rem;
        color: #a0a0b6;
      }
    }
  }

  .mainTit {
    margin-top: 0.5rem;
    font-weight: 700;
    font-size: 2rem;
  }

  .btnGroup {
    text-align: right;

    button {
      width: auto;
      height: auto;
      font-weight: 700;
      font-size: 1.6rem;
      background-color: transparent;
      border: none;

      &.btnDelete {
        color: #ff5647;
      }

      & + button {
        &::before {
          content: '';
          display: inline-block;
          width: 0.1rem;
          height: 1.1rem;
          margin: 0 1.2rem;
          background-color: #cfcfda;
        }
      }
    }
  }

  &.board {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .thumbArea {
      flex: 0 1 auto;

      span {
        display: block;
        width: 7rem;
        height: 7rem;
        border-radius: 3px;
        background-color: #b7b7c8;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        overflow: hidden;
      }

      + .infoArea {
        flex: 1 1 auto;
        width: 80%;
        margin-left: 1.9rem;
      }
    }

    .infoArea {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
      width: 100%;

      .mainTit {
        display: inline-block;
        max-width: 80%;
      }

      .starPoint {
        height: 1.8rem;
        margin-top: 1rem;
      }

      .class {
        flex: 0 1 auto;
        width: 72%;

        .mainTit {
          display: block;
          max-width: 90%;
        }

        .subTit {
          font-size: 1.9rem;
          color: #a0a0b6;
        }
      }

      .replyModify {
        flex: 1 1 auto;
        text-align: right;

        .modify {
          margin-top: 1rem;
        }
      }

      .reply {
        strong {
          font-weight: 700;
          font-size: 1.6rem;
          color: #a0a0b6;

          & + .date {
            margin-left: 1rem;
          }

          &.completed {
            color: #17c624;
          }
        }
      }

      .date {
        font-weight: 400;
        font-size: 1.2rem;
        color: #a0a0b6;
      }

      button {
        font-weight: 700;
        font-size: 1.6rem;

        &.btnDelete {
          color: #ff5647;
        }

        & + button {
          &::before {
            content: '';
            display: inline-block;
            width: 0.1rem;
            height: 1.1rem;
            margin: 0 1.2rem;
            background-color: #cfcfda;
          }
        }
      }
    }
  }

  &.inquiry {
    .infoArea {
      align-items: flex-start;

      .replyModify {
        .modify {
          margin-top: 3.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 5rem 4.2rem;

    .titFlexForm {
      flex-direction: column;
      align-items: flex-start;

      .subTit {
        font-size: 2.5rem;

        &.black {
          order: 2;
          margin-top: 2.2rem;
          font-size: 4rem;
        }
      }

      .info {
        .check {
          font-size: 2.5rem;
        }
        .date {
          font-size: 2.3rem;
        }
      }
    }

    .btnGroup {
      button {
        font-size: 2.5rem;
      }
    }

    &.board {
      min-height: 14rem;

      .thumbArea {
        span {
          width: 13.2rem;
          height: 13.2rem;
        }
      }

      .mainTit {
        font-size: 2.7rem;
      }

      .info {
        position: absolute;
        bottom: 5rem;
        font-size: 2.5rem;
      }

      .infoArea {
        flex-direction: column;
        align-items: initial;

        .class {
          position: relative;
          width: 100%;

          .subTit {
            font-size: 2.5rem;
          }
        }

        .replyModify {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 3.5rem;

          .reply {
            strong {
              font-size: 2.2rem;
            }
          }

          .modify {
            flex: 1 1 auto;
            margin-top: 0;
          }
        }

        .date {
          font-size: 2.4rem;
        }

        .starPoint {
          top: 1rem;
          right: auto;
          left: 0;
          width: 17rem;
          height: 3rem;
          margin: auto;
        }

        .btnGroup {
          position: static;
        }

        button {
          font-size: 2.5rem;
        }
      }
    }

    &.inquiry {
      align-items: flex-start;
      min-height: 12rem;
    }
  }
}

.detailContBox {
  border-bottom: 0.1rem solid #b7b7c8;

  @media screen and (max-width: 1024px) {
    &.lineUnset {
      border-bottom: none;
    }
  }
}

.detailContQuestionBox {
  padding: 2.9rem 2.8rem;
  font-size: 1.6rem;
  white-space: pre-line;
  line-height: 1.5;

  img {
    display: block;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2.6rem;
  }
}

.detailContAnswerBox {
  padding: 2.9rem 2.8rem;
  background-color: #f7f7fc;
  white-space: pre-line;

  .thumbArea {
    display: flex;
    align-items: center;

    .thumb {
      width: 5.4rem;
      height: 5.4rem;
      margin-right: 1.5rem;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      border-radius: 50%;
      overflow: hidden;
    }

    .desc {
      strong {
        display: block;
        font-weight: 700;
        font-size: 1.7rem;
      }

      p {
        margin-top: 0.6rem;
        font-weight: 400;
        font-size: 1.3rem;
        color: #a0a0b6;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 2.9rem 2.8rem;
    background-color: #f7f7fc;

    .thumbArea {
      .thumb {
        width: 8.2rem;
        height: 8.2rem;
        margin-right: 2.2rem;
      }

      .desc {
        strong {
          font-size: 2.6rem;
        }

        p {
          font-size: 2rem;
        }
      }
    }
  }
}
