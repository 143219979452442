@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.mypage-col {
  background-color: $gray-100;

  .inner-col {
    background-color: inherit;
  }

  .class-list-area {
    padding: 0;
  }
}

.mypage-main-col {
  padding-bottom: 8rem;

  > div {
    margin-top: 8rem;
  }

  .title-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    strong {
      font-weight: 700;
      font-size: 2rem;
    }

    a {
      font-weight: 700;
      font-size: 1.5rem;
      color: $gray-900;
    }
  }
}

.myclass-order-lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  li {
    width: 48.3%;

    &:first-child:nth-last-child(1) {
      width: 100%;
    }
  }

  .myclass-order-box {
    padding: 2.1rem 2.2rem 2.3rem 2rem;
    background-color: $white;
    border-radius: 0.4rem;
    box-sizing: border-box;
    box-shadow: 0 0 1.7rem rgba(0, 0, 0, 0.07);

    .tit {
      display: inline-block;
      position: relative;
      font-weight: 700;
      font-size: 1.6rem;
      color: $gray-900;

      &.ico-red-dot {
        &::before {
          content: '';
          position: absolute;
          top: -0.2rem;
          right: -0.9rem;
          width: 0.4rem;
          height: 0.4rem;
          background-color: $danger;
          border-radius: 50%;
        }
      }
    }

    .order-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 2rem;
      overflow: hidden;

      .thumb {
        flex: 0 0 auto;
        width: 9rem;
        height: 9rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-image: url('https://english.yanadoocdn.com/upload/2021/04/pc_1619161419806.png');
        border-radius: 0.5rem;
      }

      .desc {
        flex: 1 1 auto;
        margin-left: 1.4rem;
        overflow: hidden;

        .order-state {
          color: $gray-700;

          strong {
            display: inline-block;
            font-weight: 700;
            font-size: 1.6rem;
            vertical-align: middle;
          }

          .date {
            display: inline-block;
            margin-left: 0.4rem;
            font-weight: 400;
            font-size: 1.2rem;
            vertical-align: middle;
          }
        }

        .title {
          display: flex;
          margin-top: 1.1rem;
          font-weight: 700;
          font-size: 1.5rem;
          align-items: center;
          overflow: hidden;

          strong {
            flex: 0 1 auto;
            overflow: hidden;

            span {
              display: block;
            }
          }

          .num {
            flex: 0 0 4.2rem;
            padding-left: 0.5rem;
          }
        }

        .price {
          display: block;
          margin-top: 0.4rem;
          font-weight: 700;
          font-size: 1.5rem;
        }
      }
    }

    .btn-flex-form {
      margin-top: 1.5rem;

      .btn {
        padding: 1.2rem 1rem 1rem;
        font-weight: 400;
        font-size: 1.4rem;
        border-color: $gray-400;

        span {
          font-weight: 400;
        }
      }
    }
  }
}

.none {
  padding: 10rem 0;
  font-size: 1.6rem;
  color: $gray-700;
  text-align: center;
  border: 1px dashed $gray-500;
}

.qna-col {
  .qna-lists-col {
    padding: 8.2rem 0 12.6rem;

    .tab-menu-col {
      li {
        & + li {
          margin-left: 3rem;
        }
      }
    }

    .board-btn-group {
      width: 27rem;
      margin: 5rem auto 0;
    }
  }

  .tab-content {
    display: none;

    &.active {
      display: block;
    }
  }
}

.search-box-col {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;

  .search-txt {
    li {
      position: relative;
      padding-left: 1rem;
      font-weight: 400;
      font-size: 1.4rem;
      color: $gray-800;
      line-height: 1.55;

      &::before {
        content: '-';
        position: absolute;
        top: 0;
        left: 0;
        color: $gray-800;
      }
    }
  }

  .search-box {
    select {
      display: inline-block;
      width: 13.8rem;
      padding: 0.7rem 1.5rem 0.6rem;
      font-weight: 400;
      font-size: 1.4rem;
      border: 0.1rem solid $gray-400;
      background: url('#{$img-cdn}/ico_check_36x21.png') 91% 50% / 1.2rem auto no-repeat;

      & + select {
        margin-left: 1rem;
      }
    }
  }

  & + .table-lists-col {
    margin-top: 2rem;
  }
}

.table-form {
  width: 100%;
  margin-top: 2rem;
  table-layout: fixed;
  border-bottom: 0.1rem solid $gray-600;

  .tal {
    text-align: left;
  }

  .tar {
    text-align: right;
  }

  thead {
    border-top: 0.3rem solid $dark;

    th {
      padding: 1.6rem;
      font-weight: 400;
      font-size: 1.4rem;
    }
  }

  tbody {
    tr {
      border-top: 0.1rem solid $gray-300;

      td {
        position: relative;
        padding: 2.6rem 2.5rem;
        text-align: center;
        font-size: 1.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .ico-new {
          &::before {
            content: 'N';
            position: absolute;
            top: 50%;
            right: 0;
            width: 2rem;
            height: 2rem;
            padding: 0.4rem 0;
            margin-top: -1rem;
            font-weight: 700;
            font-size: 1rem;
            color: $white;
            background-color: $danger;
            border-radius: 50%;
            text-align: center;
            box-sizing: border-box;
          }
        }

        a {
          display: block;

          &:active {
            text-decoration: underline;
          }
        }

        p {
          font-weight: 400;
          color: $gray-800;

          &.check {
            font-weight: 700;
            color: $gray-700;

            &.completed {
              color: $success;
            }
          }
        }

        strong {
          display: block;
          position: relative;
          //max-width:90%;
          padding: 0.5rem;
          font-weight: 700;
          color: $dark;

          &.ico-new {
            display: inline-block;
            max-width: calc(100% - 1rem);
            padding-right: 2.5rem;
          }
        }
      }
    }
  }

  .no-content {
    padding: 14rem 0 20rem;
  }
}

.table-form-mobile {
  display: none;

  .tal {
    text-align: left;
  }

  .tar {
    text-align: right;
  }

  .table-lists {
    border-bottom: 0.1rem solid $gray-400;

    li {
      border-top: 0.1rem solid $gray-400;

      a {
        display: block;
        position: relative;
        padding: 4rem 4.2rem 4.4rem;

        span {
          display: block;
          font-weight: 700;
          font-size: 2.5rem;
          color: $gray-700;
        }

        strong {
          position: relative;
          display: -webkit-box;
          max-width: 80%;
          max-height: 8rem;
          margin-bottom: 2rem;
          padding: 2.3rem 6.5rem 0 0;
          font-weight: 700;
          font-size: 2.9rem;
          line-height: 1.4;
        }

        .info {
          position: relative;

          span {
            display: inline-block;
            vertical-align: middle;

            & + span {
              margin-left: 1.2rem;
            }
          }

          .completed {
            color: $success;
          }
        }

        .date {
          font-weight: 400;
          font-size: 2.3rem;
        }
      }

      .ico-arrow {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 6.4rem;
          width: 1.4rem;
          height: 2.6rem;
          margin-top: -0.7rem;
          background: url('#{$img-cdn}/ico_arrow_10x14g.png') 50% 50% / 100% auto no-repeat;
        }
      }

      .ico-new {
        &::before {
          content: 'N';
          position: absolute;
          top: 50%;
          right: 0;
          width: 5rem;
          height: 5rem;
          padding: 1.1rem 0;
          margin-top: -2.5rem;
          font-weight: 700;
          font-size: 2.5rem;
          color: $white;
          background: $danger;
          border-radius: 50%;
          text-align: center;
          box-sizing: border-box;
        }
      }

      .star-point {
        position: absolute;
        top: 4rem;
        right: 4.2rem;
        height: 0.8rem;
      }
    }
  }
}

// 리스트 디테일 페이지
.list-detail-col {
  padding: 8.2rem 0 0;

  .title-col {
    padding: 0 0 2rem;
    border-bottom: 0.3rem solid $dark;

    strong {
      font-weight: 700;
      font-size: 2rem;
    }
  }

  pre {
    font-size: 1.6rem;
    font-family: 'Inter', 'NotoSansCJKkr', sans-serif;
    line-height: 1.7;
    white-space: pre-wrap;
  }

  .detail-title-area {
    position: relative;
    padding: 2.5rem 2.8rem;
    border-bottom: 0.1rem solid $gray-300;

    .thumb-nail {
      width: 7rem;
      height: 7rem;
    }

    .tit-flex-form {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sub-tit {
        flex: 0 1 70%;
        font-weight: 700;
        font-size: 1.6rem;
        color: $gray-900;

        &.black {
          font-size: 1.8rem;
          color: $dark;
        }
      }

      .info {
        .check {
          font-weight: 700;
          font-size: 1.6rem;
          color: $gray-700;

          & + .date {
            margin-left: 1rem;
          }

          &.completed {
            color: $success;
          }
        }

        .date {
          font-weight: 400;
          font-size: 1.2rem;
          color: $gray-700;
        }
      }
    }

    .main-tit {
      margin-top: 0.5rem;
      font-weight: 700;
      font-size: 2rem;
    }

    .btn-group {
      //position: absolute;
      //bottom: 2.5rem;
      //right: 2.8rem;
      text-align: right;

      button {
        width: auto;
        height: auto;
        font-weight: 700;
        font-size: 1.6rem;
        background-color: transparent;
        border: none;

        &.btn-delete {
          color: $danger;
        }

        & + button {
          &::before {
            content: '';
            display: inline-block;
            width: 0.1rem;
            height: 1.1rem;
            margin: 0 1.2rem;
            background-color: $gray-500;
          }
        }
      }
    }

    &.board {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .thumb-area {
        flex: 0 1 auto;

        // width: 7rem;
        // height: 7rem;

        span {
          display: block;
          width: 7rem;
          height: 7rem;
          border-radius: 3px;
          background-color: $gray-600;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          overflow: hidden;
        }

        + .info-area {
          flex: 1 1 auto;
          width: 80%;
          margin-left: 1.9rem;
        }
      }

      .info-area {
        display: flex;
        align-items: center;
        flex: 0 1 auto;
        width: 100%;

        .main-tit {
          display: inline-block;
          max-width: 80%;
        }

        .star-point {
          height: 1.8rem;
          margin-top: 1rem;
        }

        .class {
          flex: 0 1 auto;
          width: 72%;

          .main-tit {
            display: block;
            max-width: 90%;
          }

          .sub-tit {
            font-size: 1.9rem;
            color: $gray-700;
          }
        }

        .reply-modify {
          flex: 1 1 auto;
          text-align: right;

          .modify {
            margin-top: 1rem;
          }
        }

        .reply {
          strong {
            font-weight: 700;
            font-size: 1.6rem;
            color: $gray-700;

            & + .date {
              margin-left: 1rem;
            }

            &.completed {
              color: $success;
            }
          }
        }

        .date {
          font-weight: 400;
          font-size: 1.2rem;
          color: $gray-700;
        }

        button {
          font-weight: 700;
          font-size: 1.6rem;

          &.btn-delete {
            color: $danger;
          }

          & + button {
            &::before {
              content: '';
              display: inline-block;
              width: 0.1rem;
              height: 1.1rem;
              margin: 0 1.2rem;
              background-color: $gray-500;
            }
          }
        }
      }
    }

    &.inquiry {
      .info-area {
        align-items: flex-start;

        .reply-modify {
          .modify {
            margin-top: 3.5rem;
          }
        }
      }
    }
  }

  .detail-attached-files {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 28px;
    border-bottom: 1px solid #e7e7f0;

    span {
      display: block;
      width: 120px;
      font-size: 14px;
    }
    ul {
      flex: 1;
      li {
        font-size: 14px;
        line-height: 24px;
        a {
          color: #646464;
        }
      }
    }
  }

  .detail-content {
    border-bottom: 0.1rem solid $gray-600;
  }

  .detail-question {
    padding: 2.9rem 2.8rem;
    font-size: 1.6rem;

    img {
      display: block;
    }
  }

  .detail-answer {
    padding: 2.9rem 2.8rem;
    background-color: $gray-100;

    .thumb-area {
      display: flex;
      align-items: center;

      .thumb {
        width: 5.4rem;
        height: 5.4rem;
        margin-right: 1.5rem;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 50%;
        overflow: hidden;
      }

      .desc {
        strong {
          display: block;
          font-weight: 700;
          font-size: 1.7rem;
        }

        p {
          margin-top: 0.6rem;
          font-weight: 400;
          font-size: 1.3rem;
          color: $gray-700;
        }
      }
    }
  }

  .detail-download {
    padding: 1.4rem 2rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: $gray-900;
    background-color: $gray-100;
    text-align: right;

    &::before {
      content: '첨부파일';
      display: inline-block;
    }

    a {
      margin-left: 1rem;
      font-weight: 400;

      &::after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-image: url('#{$img-cdn}/ico_download_54x54b.png');
        vertical-align: middle;
      }
    }
  }
}

.modal-content .write-form {
  padding: 3.8rem 0 0;

  .modal-select-box {
    margin-top: 0;
  }

  dl {
    padding: 0 3.2rem;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 5.8rem;
    }
    &.inquiry-area {
      margin-bottom: 14px;
    }
    dt {
      font-size: 1.7rem;
    }

    dd {
      position: relative;

      textarea {
        width: 100%;
        height: 26.3rem;
        padding: 1.5rem 1.5rem 0;
        font-size: 1.9rem;
        line-height: 1.5;
        border: 1px solid $gray-500;
        box-sizing: border-box;
        border-radius: 0;
        resize: none;
      }

      p {
        position: absolute;
        bottom: 1rem;
        right: 0.5rem;
        padding: 0 1rem;
        font-size: 1.6rem;
        color: $gray-600;

        span {
          color: $dark;
        }
      }
    }

    .mark {
      position: relative;

      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        left: -1.5rem;
        color: $danger;
        font-weight: 400;
      }
    }
  }
  .notice {
    margin-top: 1.5rem;
    padding: 3.7rem 3.2rem 6.5rem;
    font-size: 1.6rem;
    color: #e7e7f0;
    background-color: #28284b;

    em,
    strong {
      display: block;

      &::before {
        content: '※';
        display: inline-block;
      }

      & + em,
      & + strong {
        margin-top: 1.5rem;
      }
    }

    strong {
      font-weight: 400;
    }

    ul {
      margin-top: 0.5rem;

      li {
        padding-left: 1.3rem;
        text-indent: -0.5rem;
        line-height: 1.7;

        &::before {
          content: '-';
          display: inline-block;
          margin-right: 0.3rem;
        }
      }
    }
  }
}

//별점
.star-point {
  display: inline-block;
  position: relative;
  width: 9rem;
  height: 1rem;
  background-color: $gray-300;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('#{$img-cdn}/bg_star_100x100_v2.png') repeat-x 0 0;
    background-size: 20%;
    z-index: 2;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    background-color: rgb(255, 107, 0);
    text-indent: -9999px;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 5.4rem;
  button {
    display: block;
    position: relative;
    width: 23.7rem;
    padding: 1.5rem 0px 1.3rem;
    font-size: 1.4rem;
    border: 1px solid #e7e7f0;
    margin-left: -1px;
    box-sizing: border-box;
    &.active {
      background-color: #ffb900;
      font-weight: 700;
    }
  }
}

.refund-content {
  font-family: 'NotoSansCJKkr', sans-serif;
  white-space: normal;
  h2 {
    margin: 4rem 0;
    font-size: 2.2rem;
  }
  h3 {
    margin: 3rem 0 1.25rem;
    font-size: 1.9rem;
  }
  h4 {
    margin: 2rem 0 1.25rem 0;
    font-size: 1.75rem;
  }
  ul {
    margin: 1rem 0;

    li {
      line-height: 1.5;
      word-break: keep-all;
    }

    ul {
      margin-top: 0;
      margin-left: 1em;
    }
  }
}

// 테이블 스타일에서 데이터 없을때
.none-data {
  border-bottom: none;
}
@media screen and (max-width: 1024px) {
  .table-form {
    display: none;
  }

  .table-form-mobile {
    display: block;

    .no-content {
      padding: 12.1rem 0 17.8rem;
    }
  }

  .qna-col {
    .detail-content {
      border-bottom: none;
    }

    .qna-lists-col {
      padding: 0;

      .board-btn-group {
        width: 90%;
      }
    }
  }

  .search-box-col {
    display: block;
    margin-top: 0;

    .search-txt {
      padding: 3rem 3rem 3rem 4.2rem;
      background-color: $gray-100;

      li {
        font-size: 2.5rem;
      }
    }

    .search-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 3rem 4.2rem;

      select {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        width: auto;
        padding: 3.6rem 2.7rem 3.5rem;
        font-size: 2.9rem;
        background-size: 2rem auto;
        background-position: 95% 50%;

        & + select {
          margin-left: 1.8rem;
        }
      }

      #sortSelect {
        //display: none;
      }
    }
  }

  .list-detail-col {
    padding: 0;

    .title-col {
      display: none;
    }

    .detail-title-area {
      padding: 5rem 4.2rem;

      .tit-flex-form {
        flex-direction: column;
        align-items: flex-start;

        .sub-tit {
          font-size: 2.5rem;

          &.black {
            order: 2;
            margin-top: 2.2rem;
            font-size: 4rem;
          }
        }

        .info {
          .check {
            font-size: 2.5rem;
          }
          .date {
            font-size: 2.3rem;
          }
        }
      }

      .btn-group {
        button {
          font-size: 2.5rem;
        }
      }

      &.board {
        min-height: 14rem;

        .thumb-area {
          span {
            width: 13.2rem;
            height: 13.2rem;
          }
        }

        .main-tit {
          font-size: 2.7rem;
        }

        .info {
          position: absolute;
          bottom: 5rem;
          font-size: 2.5rem;
        }

        .info-area {
          flex-direction: column;
          align-items: initial;

          .class {
            position: relative;
            width: 100%;

            .sub-tit {
              font-size: 2.5rem;
            }
          }

          .reply-modify {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3.5rem;

            .reply {
              strong {
                font-size: 2.2rem;
              }
            }

            .modify {
              flex: 1 1 auto;
              margin-top: 0;
            }
          }

          .date {
            font-size: 2.4rem;
          }

          .star-point {
            top: 1rem;
            right: auto;
            left: 0;
            width: 17rem;
            height: 3rem;
            margin: auto;
          }

          .btn-group {
            position: static;
          }

          button {
            font-size: 2.5rem;
          }
        }
      }

      &.inquiry {
        align-items: flex-start;
        min-height: 12rem;
      }
    }

    .detail-attached-files {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 16px 4.2rem;
      border-bottom: 1px solid #e7e7f0;

      span {
        width: auto;
      }
      ul {
        flex: 1;
        width: 100%;
        padding: 8px;
        margin-top: 8px;
        border-radius: 8px;
        background-color: #f7f7f7;
        box-sizing: border-box;

        li {
          font-size: 12px;
          line-height: 20px;
          a {
            color: #646464;
          }
        }
      }
    }

    .detail-content {
      &.line-unset {
        border-bottom: none;
      }
    }

    .detail-question {
      font-size: 2.6rem;

      img {
        width: 100%;
        height: auto !important;
      }
    }

    .detail-answer {
      padding: 2.9rem 2.8rem;
      background-color: $gray-100;

      .thumb-area {
        .thumb {
          width: 8.2rem;
          height: 8.2rem;
          margin-right: 2.2rem;
        }

        .desc {
          strong {
            font-size: 2.6rem;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }

    .detail-download {
      padding: 3.7rem 5.2rem;
      font-weight: 400;
      font-size: 2.7rem;

      &::before {
        content: '';
      }

      a {
        display: inline-block;
        position: relative;
        width: 100%;
        margin-left: 0;
        font-weight: 400;
        text-align: left;

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: -1rem;
          right: 0;
          width: 5rem;
          height: 5rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          background-image: url('#{$img-cdn}/ico_download_54x54b.png');
        }
      }
    }

    pre {
      font-size: 2.6rem;

      table {
        width: auto !important;
      }
    }
  }

  .modal-content {
    .write-form {
      padding-top: 5.8rem;

      dl {
        padding: 0 4.2rem;

        dt {
          font-size: 2.5rem;
        }

        dd {
          padding-top: 2.3rem;

          textarea {
            height: 40rem;
            padding: 2.5rem;
            font-size: 2.9rem;
          }

          p {
            bottom: 2.5rem;
            right: 2rem;
            font-size: 2.5rem;
          }
        }
      }

      .notice {
        padding: 6rem 4.2rem;
        font-size: 2.5rem;

        strong {
          font-size: 2.5rem;
        }
      }
    }
  }

  .refund-content {
    h2 {
      margin: 4rem 0;
      font-size: 4.2rem;
    }
    h3 {
      margin: 4rem 0 1.5rem;
      font-size: 3.25rem;
    }
    h4 {
      margin: 2rem 0 1.25rem 0;
      font-size: 2.75rem;
    }
    ul {
      margin: 1rem 0;

      li {
        line-height: 1.5;
      }
    }
  }

  .tabs {
    padding-left: 0;
    margin-top: -1px;
    margin-bottom: 0;
    button {
      flex: 1;
      padding: 3rem 0px;
      font-size: 2.7rem;
    }
  }
}
