.boardItemBox {
  position: relative;
  padding: 3.5rem 3.5rem 3.2rem 3.5rem;

  // 이미지 후기 스타일
  &.image {
    padding: 0;

    > div {
      flex-direction: column;
      align-items: flex-start;

      .rightBox {
        margin-top: 1.3rem;
        align-items: flex-start;
      }
    }
  }

  & + button {
    padding-top: 0;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 4rem;

    // 이미지 후기 스타일
    &.image {
      > div {
        flex-direction: row;

        .rightBox {
          margin-top: 1.3rem;
          align-items: flex-start;

          p {
            top: auto;
            left: 0;
            bottom: -5.5rem;
          }
        }
      }
    }
  }
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 1.5rem;

    p {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }

  .leftBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      flex: 1 1 auto;
      margin-right: 1.5rem;
      overflow: hidden;

      strong {
        font-weight: 700;
        font-size: 1.6rem;
      }

      p {
        margin-top: 0.8rem;
        font-weight: 400;
        font-size: 1.2rem;
        color: #a0a0b6;
        line-height: 1;
      }
    }
  }

  .rightBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;

    p {
      margin-top: 1rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: #717188;
    }
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-start;

    & + div {
      margin-top: 6.5rem;

      p {
        font-size: 2.6rem;
      }
    }

    .leftBox {
      div {
        margin-right: 2.2rem;

        strong {
          font-size: 2.6rem;
        }

        p {
          margin-top: 0.4rem;
          font-size: 2rem;
        }
      }
    }

    .rightBox {
      p {
        position: absolute;
        top: 14.7rem;
        left: 4rem;
        margin-top: 0;
        font-weight: 500;
        font-size: 2rem;
        color: #8d8da0;
      }
    }
  }
}

.starPoint {
  position: relative;
  flex: 0 0 auto;
  width: 10rem;
  height: 2rem;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_star_120x24g.png)
      0 0 no-repeat;
    background-size: auto 100%;
    z-index: 2;
  }

  span {
    display: inline-block;
    position: absolute;
    z-index: 5;
    width: var(--star-percentage);
    height: 100%;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_star_120x24_orange.png)
      0 0 no-repeat;
    background-size: auto 100%;
    max-width: 100%;
    text-indent: -9999rem;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: 12rem;
    height: 2.4rem;
  }
}
