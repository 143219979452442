.categoryBox {
  display: flex;
  gap: 1rem;
  margin-bottom: 5rem;
  line-height: 1.4;

  .categoryList {
    margin: 0 auto;

    li {
      display: inline-block;
      vertical-align: middle;

      & + li {
        margin-left: 3.6rem;
      }
    }

    button {
      display: block;
      font-size: 1.6rem;
      font-weight: 500;
      color: #717188;

      &.active {
        font-weight: 700;
        padding: 1.2rem 3rem;
        color: #000;
        background-color: #ffb900;
        border-color: #ffb900;
        border-radius: 3.4rem;
      }
    }
  }

  @media all and (max-width: 1024px) {
    margin-bottom: 6rem;

    .categoryList {
      margin: 0 -4.2rem;
      padding-left: 4.2rem;
      white-space: nowrap;
      overflow: auto;

      li {
        margin-right: 4.8rem;

        & + li {
          margin-left: 0;
        }
      }

      button {
        font-size: 2.8rem;

        &.active {
          padding: 1.5rem 3.2rem;
        }
      }
    }
  }
}
