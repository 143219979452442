.noticeBox {
  padding: 1.7rem 2rem 1.5rem;
  background-color: #269cff;

  & + .modalContent {
    .couponLists {
      height: 56.8rem;
    }
  }

  p {
    position: relative;
    padding-left: 2.4rem;
    font-weight: 500;
    color: #fff;
    font-size: 1.6rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
      margin: -0.9rem 0 0 0;
      background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_notice_line_30x30w.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 2.9rem 3rem 2.9rem;

    & + .modalContent {
      .couponLists {
        height: 100%;
        padding: 3.5rem 3rem 26rem;
      }
    }

    p {
      padding-left: 4.2rem;
      font-weight: 700;
      font-size: 2.8rem;

      &::before {
        width: 3rem;
        height: 3rem;
        margin: -1.6rem 0 0 0;
      }
    }
  }
}
