.chatCol {
  display: flex;
  padding-top: 2.3rem;

  @media all and (max-width: 1024px) {
    padding-top: 2.6rem;
  }
}

.profileBox {
  flex: 0 0 4.6rem;
  width: 4.6rem;
  height: 4.6rem;
  background-color: #eee;
  border-radius: 50%;
  overflow: hidden;

  &.reply {
    flex: 0 0 3.6rem;
    width: 3.6rem;
    height: 3.6rem;
    margin-left: 5.8rem;
  }

  div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    box-sizing: border-box;
  }

  @media all and (max-width: 1024px) {
    flex: 0 0 6.8rem;
    width: 6.8rem;
    height: 6.8rem;

    &.reply {
      flex: 0 0 5.2rem;
      width: 5.2rem;
      height: 5.2rem;
      margin-left: 8rem;
    }
  }
}

.chatBox {
  flex: 1;
  margin-left: 1.2rem;

  @media all and (max-width: 1024px) {
    margin-left: 1.4rem;
  }
}

.chatTextBox {
  width: 100%;
  padding: 1.8rem;
  background-color: #f7f7fc;
  font-size: 1.6rem;
  border-radius: 1rem;
  line-height: 1.5;
  box-sizing: border-box;
  word-break: break-all;

  strong {
    display: inline-block;
    margin-right: 1rem;
    font-weight: 700;
  }

  @media all and (max-width: 1024px) {
    font-size: 2.6rem;
  }
}

.chatInfoBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0.7rem 0 0.7rem;
  color: #8d8da0;
  font-size: 1.4rem;

  div {
    button {
      position: relative;

      & + button,
      & + span {
        &::before {
          content: '·';
          display: inline-block;
          margin: auto 0.3rem;
        }
      }
    }

    & + button {
      padding: 0 1.6rem;

      span {
        display: inline-block;
        position: relative;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        background-color: #a0a0b6;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: #a0a0b6;
        }

        &::before {
          left: -0.6rem;
        }

        &::after {
          right: -0.6rem;
        }
      }
    }
  }

  @media all and (max-width: 1024px) {
    font-size: 2.2rem;

    div {
      & + button {
        span {
          width: 0.5rem;
          height: 0.5rem;

          &::before,
          &::after {
            width: 0.5rem;
            height: 0.5rem;
          }

          &::before {
            left: -1rem;
          }

          &::after {
            right: -1rem;
          }
        }
      }
    }
  }
}

.commentWrite {
  margin-top: 3rem;

  form > div:first-child {
    position: relative;
    color: #717188;
    background-color: #f2f2fa;
    font-size: 1.6rem;
    border-radius: 0.6rem 0.6rem 0 0;
    transform: translateY(0.4rem);
    padding-bottom: 0.4rem;
    box-sizing: border-box;
    z-index: 1;

    & > div {
      padding: 0.86rem 2.6rem 0.83rem;
    }

    strong {
      font-weight: 700;
      line-height: normal;

      &:after {
        content: '님에게 답글 남기는 중';
        font-weight: 400;
        line-height: normal;
      }
    }

    button {
      color: #8d8da0;
      line-height: normal;

      &:before {
        content: '·';
        display: inline-block;
        margin: auto 0.66rem auto 0.83rem;
      }
    }
  }

  div + div {
    position: relative;
    z-index: 2;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.9rem 1.8rem;
      border: 0.2rem solid #ececf6;
      background-color: #fff;
      border-radius: 0.5rem;
      box-sizing: border-box;
      gap: 2.8rem;

      textarea {
        flex-grow: 1;
        padding: 0;
        font-size: 1.6rem;
        border: 0;
        min-height: 1lh;
        height: 2.4rem;
        max-height: 9.4rem;
        overflow: hidden;
        line-height: 1.6;
        resize: none;
        &::placeholder {
          color: #b7b7c8;
          line-height: 1.6;
        }

        & + button {
          flex: 0 0 3.2rem;
          width: 3.2rem;
          align-self: flex-end;
          height: 3.2rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_send_off_32x32.png)
            0 0/100% no-repeat;
          text-indent: -9999rem;
        }

        &.active + button {
          background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_send_on_32x32.png);
        }
      }
    }
  }

  @media all and (max-width: 1024px) {
    margin-top: 7rem;

    form > div:first-child {
      font-size: 2.6rem;
      border-radius: 0.8rem 0.8rem 0 0;

      & > div {
        padding: 1.3rem 2.8rem 1.5rem;
      }

      button {
        &:before {
          margin: auto 1rem;
        }
      }
    }

    div + div {
      div {
        gap: 2.9rem;
        padding-block: 1.7rem;
        border-radius: 0.8rem;
        textarea {
          font-size: 2.6rem;
          max-height: 17.4rem;

          & + button {
            flex: 0 0 4.8rem;
            width: 4.8rem;
            height: 4.8rem;
          }
        }
      }
    }
  }
}
