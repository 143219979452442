$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/aiTravel/';
.fixedMissions {
  * {
    box-sizing: border-box;
  }
  position: fixed;
  top: 52px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #28284b;

  .titleGroup {
    display: flex;
    height: 52px;
    align-items: center;
    position: relative;
    h4 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.5px;
      padding-left: 24px;
      .count {
        color: #ffb900;
      }
      .all {
        color: #17c624;
      }
    }
    .btnArrow {
      display: block;
      width: 24px;
      height: 24px;
      white-space: nowrap;
      text-indent: -1000px;
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -12px;
      background-image: url('#{$img-path}ico_arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      &.down {
        transform: rotate(180deg);
      }
    }
  }

  .missionList {
    display: none;
    padding: 0 16px 16px;
    &.open {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      height: 78px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: #333354;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      padding: 14px 12px 0;
      position: relative;
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(1) {
        .mission {
          &::before {
            content: '1';
          }
        }
      }
      &:nth-child(2) {
        .mission {
          &::before {
            content: '2';
          }
        }
      }
      &:nth-child(3) {
        .mission {
          &::before {
            content: '3';
          }
        }
      }
      &.active {
        background-color: #fff;
        .mission {
          &::before {
            background-color: #17c624;
            background-image: url('#{$img-path}ico_checked.svg');
            background-size: cover;
            content: '';
          }
          dt,
          dd {
            color: #000;
          }
        }
      }
    }
    .mission {
      padding-left: 26px;
      &::before {
        display: grid;
        place-items: center;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background-color: #8d8da0;
        position: absolute;
        top: 13px;
        left: 13px;
        color: #fff;
        font-family: Roboto;
        font-size: 10px;
        font-weight: 700;
        content: '';
      }
      dt {
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 4px;
      }
      dd {
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    top: 75px;
  }
}
