.pop-agree-marketing {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8011;
  text-align: left;
}

.pop-agree-marketing .pop-ynd-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pop-agree-marketing .pop-ynd-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 760px;
  height: 600px;
  margin: auto;
  padding: 2em;
  background-color: #fff;
  font-size: 20px;
  box-sizing: content-box;
}

.pop-agree-marketing .pop-ynd-close {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  background-color: #fff;
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/pc/common/ico/ico_close_22x22.png);
  background-position: center;
  background-repeat: no-repeat;
  filter: alpha(opacity=0);
  text-indent: -9999px;
  outline: 0;
  opacity: 1;
  cursor: pointer;
}

.pop-agree-marketing h3 {
  display: block;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  padding: 0.2em 0;
  font-size: 150%;
  font-weight: 400;
  letter-spacing: -2px;
  color: #000;
  border-bottom: 2px solid #000;
}

.pop-agree-marketing .content-scroll {
  width: 96%;
  height: 54rem;
  margin: 0.5em auto 1em auto;
  padding: 0 2%;
  color: #111;
  border-bottom: 1px solid #ededed;
  overflow-y: auto;
}

.pop-agree-marketing .content-scroll p {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #3a3a3a;
}

@media screen and (max-width: 1024px) {
  .pop-agree-marketing {
    font-size: 14px;
  }

  .pop-agree-marketing h3 {
    width: 90%;
    margin: 20px auto 10px;
    padding-bottom: 5px;
    font-weight: 400;
    font-size: 100%;
    letter-spacing: -2px;
    color: #000;
    border-bottom: 2px solid #000;
  }

  .pop-agree-marketing .pop-ynd-content {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 320px;
    height: 500px;
    margin: -230px 0 0 -160px;
    padding: unset;
    font-size: 140%;
    z-index: 5001;
  }

  .pop-agree-marketing .content-scroll {
    width: 90%;
    height: 24em;
    margin: auto;
    font-size: 80%;
    overflow-y: scroll;
  }

  .pop-agree-marketing .content-scroll p {
    font-size: 85%;
  }

  .pop-agree-marketing .button-group {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .pop-agree-marketing .button-group button {
    width: 100%;
    padding: 1em 0;
    font-size: 85%;
    letter-spacing: -1px;
    color: #151515;
    background-color: #f0f0f0;
    border-top: 0;
    border-right: 1px solid #ddd;
    border-bottom: 0;
    border-left: 0;
    outline: none;
    z-index: 2;
  }
}