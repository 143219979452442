.container {
  padding: 118px 0 42px;
  text-align: center;
  color: #fff;
}

.subTitle {
  color: #74abff;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.title {
  margin-top: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

.loadinerContainer {
  position: relative;
  margin-top: 54px;
}

.loadingCircle {
  width: 217px;
  height: 217px;
  border-radius: 50%;
  stroke-dasharray: 653.12;
  transform: rotate(-270deg) scaleX(-1);
  z-index: 2;
  position: relative;
}

.frame {
  fill: none;
  stroke: #2b2b3b;
  stroke-width: 6;
}

.bar {
  stroke: #4a93ff;
  stroke-linecap: round;
  fill: none;
  stroke-width: 6;
  animation: spin 5s ease-in;
}

@keyframes spin {
  0% {
    stroke-dashoffset: 653.12;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.imgContainer {
  background: #2b2b3b;
  width: 182px;
  height: 182px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 106px;
  }
}

.description {
  margin-top: 55px;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
}

.reviewContainer {
  overflow: hidden;
  position: relative;
  margin-top: 16px;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 180px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(23, 23, 32, 0.04) 0, rgba(23, 23, 32, 1) 42%);
    z-index: 99;
  }
}

.reviewSwiper {
  height: 376px;
  overflow: visible !important;
}

.reviewSlide {
  background: rgba(43, 43, 59, 1);
  box-shadow: rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  padding: 27px 27px 24px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}

.reviewTitle {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

.reviewDescription {
  margin-top: 14px;
  color: #a0a0b6;
  font-size: 16px;
  line-height: 20px;
  word-break: keep-all;
  white-space: pre-line;
}

.reviewWriter {
  margin-top: 13px;
  color: #717188;
  font-size: 16px;
  line-height: 20px;
}

.star {
  position: absolute;
  top: 30px;
  right: 27px;
  width: 80px;
  height: auto;
}

/* AnalyzingCompleteModal */

.modal {
  border-radius: 14px;
  width: 434px;
  background: #2b2b3b;
  color: #fff;
  text-align: center;
  z-index: 999;
}

.modalHeader {
  height: 176px;
  background: #4a93ff;
  position: relative;
  border-radius: 14px 14px 0 0;

  img {
    width: 300px;
    margin-inline: auto;
    transform: translateY(-21px);
  }
}

.modalBody {
  padding: 43px 43px 56px;
}

.modalTitle {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
  font-weight: 700;
}

.modalDescription {
  margin: 8px 0 31px;
  font-size: 14px;
  line-height: 20px;
}

.modalDescription2 {
  color: #ff887e;
  font-size: 12px;
  line-height: 1.33;
  margin-bottom: 8px;
}

.modalButton {
  height: 70px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  width: 100%;
}

.modalButtonHighlight {
  background: #fff;
  color: #000;
  border: 0;
  margin-bottom: 10px;
}

.modalButtonDescription {
  color: #269cff;
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
}

.select {
  margin-top: 12px;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 13px;

  label {
    color: #000;
    font-size: 18px;
    font-weight: 400;
  }

  select {
    border: none;
    height: 46px;
    cursor: pointer;
    color: #b7b7c8;
    font-size: 18px;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_63x63_b.png)
      no-repeat 100% 45%/13px;
    appearance: none;
    padding-right: 20px;
    outline: none;

    &::-ms-expand {
      display: none;
    }
  }
}

.agree {
  margin-top: 38px;

  li:nth-of-type(1) {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #c1c1c1;
  }

  li:nth-of-type(2) {
    margin-bottom: 7px;
  }

  label {
    font-size: 16px;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;

    input[type='checkbox'] {
      width: 0;
      padding: 0;
      margin: 0;
      border: 0;

      + em {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        vertical-align: middle;
        cursor: pointer;
        background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png)
          no-repeat 42% 50% / 15px auto;
        border-radius: 50%;
        border: 1px solid #cfcfd8;
        background-color: #fff;
      }

      &:checked + em {
        background-color: #ffb800;
        border-color: #ffb800;
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/jan/ico_check.svg);
      }
    }

    button {
      color: #269cff;
      text-decoration: underline;
      text-underline-offset: 3px;
      margin-left: 8px;
    }
  }
}

.onlyResult {
  margin-inline: auto;
  margin-top: 45px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-bottom: 1px solid #fff;

  svg {
    width: 12px;
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding-top: 16vw;
    overflow: hidden;
  }

  .subTitle {
    font-size: 3.467vw;
    line-height: 5.067vw;
  }

  .title {
    margin-top: 2.133vw;
    font-size: 7.467vw;
    line-height: 8.8vw;
  }

  .loadinerContainer {
    margin-top: 10.933vw;
  }

  .loadingCircle {
    width: 320px;
    height: 320px;
    border-radius: 50%;
    stroke-dasharray: 973;
  }

  .frame,
  .bar {
    stroke-width: 9px;
  }

  @keyframes spin {
    0% {
      stroke-dashoffset: 973;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  .imgContainer {
    width: 272px;
    height: 272px;

    img {
      width: 159px;
    }
  }

  .description {
    margin-top: 11.733vw;
    font-size: 3.2vw;
    line-height: 4vw;
  }

  .reviewContainer {
    margin: 3.2vw 0 -15vw;

    &:after {
      height: 33vw;
    }
  }

  .reviewSwiper {
    height: 75.2vw;
  }

  .reviewSlide {
    border-radius: 2.667vw;
    padding: 5.333vw 5.333vw 4.8vw;
  }

  .reviewTitle {
    font-size: 3.733vw;
    line-height: 4.533vw;
  }

  .reviewDescription,
  .reviewWriter {
    margin-top: 2.667vw;
    font-size: 3.2vw;
    line-height: 4vw;
  }

  .star {
    top: 6vw;
    right: 5.333vw;
    width: 16vw;
  }

  /* AnalyzingCompleteModal */
  .modal {
    border-radius: 2.667vw;
    width: 100%;
    max-width: calc(100% - 13.333vw);
  }

  .modalHeader {
    height: 33.6vw;

    img {
      width: 56.8vw;
      transform: translateY(-3.655vw);
    }
  }

  .modalBody {
    padding: 8vw 8.667vw 5.6vw 8.667vw;
  }

  .modalTitle {
    font-size: 5.333vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
  }

  .modalDescription {
    margin-top: 2.667vw;
    margin-bottom: 5.333vw;
    font-size: 2.667vw;
    line-height: 3.467vw;
  }

  .modalDescription2 {
    font-size: 2.4vw;
    margin-top: 5.333vw;
    margin-bottom: 3.2vw;
  }

  .modalButton {
    height: 12.267vw;
    border-radius: 1.6vw;
    font-size: 3.733vw;
  }

  .modalButtonHighlight {
    margin-bottom: 2.133vw;
  }

  .modalButtonDescription {
    font-size: 2.667vw;
  }

  .select {
    margin-top: 2.667vw;
    padding-inline: 3.733vw;

    label {
      font-size: 3.2vw;
    }

    select {
      height: 8.8vw;
      font-size: 3.2vw;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_63x63_b.png)
        no-repeat 100% 45%/2.667vw;
      padding-right: 2.667vw;
    }
  }

  .agree {
    margin-top: 3.733vw;

    li:nth-of-type(1) {
      padding-bottom: 2.8vw;
      margin-bottom: 2.8vw;
    }

    li:nth-of-type(2) {
      margin-bottom: 2.133vw;
    }

    label {
      font-size: 3.2vw;

      input[type='checkbox'] {
        + em {
          width: 4.267vw;
          height: 4.267vw;
          margin-right: 0.933vw;
        }

        &:checked + em {
          background-size: 2.667vw;
        }
      }

      button {
        text-underline-offset: 0.8vw;
        margin-left: 2.267vw;
      }
    }
  }

  .onlyResult {
    margin-top: 5.867vw;
    font-size: 3.2vw;
    letter-spacing: -0.067vw;
    gap: 0.8vw;
    font-weight: 500;

    svg {
      width: 2vw;
    }
  }
}

@media screen and (max-width: 425px) {
  .loadingCircle {
    width: 166px;
    height: 166px;
    border-radius: 50%;
    stroke-dasharray: 489;
  }

  .frame,
  .bar {
    stroke-width: 6px;
  }

  @keyframes spin {
    0% {
      stroke-dashoffset: 483.56;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  .imgContainer {
    width: 136px;
    height: 136px;

    img {
      width: 80px;
    }
  }
}
