@import 'reset';
@import 'variable';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';

/* common css */
html,
body {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font: normal normal 10px/1.2 'NotoSansCJKkr', 'Inter', sans-serif;
  &.scroll-fixed {
    height: 100vh;
    overflow: hidden;
  }
}

button,
input,
select,
table,
pre,
textarea {
  font-family: 'NotoSansCJKkr', 'Inter', sans-serif;
}

body.fixed #root {
  padding-top: 11.18rem;
}
body.header-none header {
  display: none !important;
}

.h2 {
  display: none;
}
main {
  position: relative;
  min-height: 80vh;
}
img {
  max-width: 100%;
  vertical-align: top;
  -webkit-user-drag: none !important;
}
button {
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
}
a:link {
  color: $dark;
  text-decoration: none;
}
a:visited {
  color: $dark;
  text-decoration: none;
}
a:hover {
  color: $dark;
  text-decoration: none;
}
input {
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input::placeholder {
  color: $dark;
}
input:-ms-input-placeholder {
  color: $dark;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  white-space: normal;
}
.fixed header {
  position: fixed;
  top: 0;
  z-index: 15;
}
.inner-col {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  background-color: $white;
}

.adn-none {
  div[id^='adn_panel_'] {
    display: none !important;
  }
}

/* ico */
span[class^='ico'],
i[class^='ico'] {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50% 50%;
  box-sizing: border-box;
}
.nav-global span[class^='ico'] {
  width: 4.2rem;
  height: 4.2rem;
}
.ico-user {
  background-image: url('#{$img-cdn}/ico_user_100x100.png');
}
.ico-cart {
  position: relative;
  background-image: url('#{$img-cdn}/ico_cart_100x100.png');
}
.ico-cart em {
  display: inline-block;
  position: absolute;
  right: 0.4rem;
  bottom: 0.3rem;
  min-width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.5rem;
  font-size: 8.4px;
  font-weight: 700;
  color: $white;
  background-color: $danger;
  border-radius: 1rem;
  box-sizing: border-box;
  line-height: 15.8px;
  text-align: center;
}
.ico-search {
  display: inline-block;
  width: 4.2rem;
  height: 4.2rem;
  background: url('#{$img-cdn}/ico_search_100x100.png') no-repeat 50% 50%/100%;
}

.ico-mark {
  display: inline-block;
  position: relative;
  margin-left: 2.5rem;
  padding: 0 0.8rem 0 0.3rem;
  height: 2.6rem;
  color: $white;
  background: $gray-900;
  font-weight: 700;
  font-size: 1.4rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  line-height: 2.6rem;
  vertical-align: middle;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -1.35rem;
    width: 0;
    height: 0;
    border-bottom: 1.39rem solid $gray-900;
    border-left: 1.39rem solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    top: 1.25rem;
    left: -1.35rem;
    width: 0;
    height: 0;
    border-top: 1.39rem solid $gray-900;
    border-left: 1.39rem solid transparent;
  }
}

.ico-member {
  display: inline-block;
  position: relative;
  height: 2rem;
  font-weight: 700;
  font-style: normal;
  line-height: 2.2rem;
  text-align: center;
  vertical-align: middle;

  &:before {
    content: 'M';
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-right: 0.7rem;
    padding: 0 0.4rem;
    color: $warning;
    background-color: $dark;
    font-weight: 400;
    font-size: 1.3rem;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
  }
}

.modal-open {
  overflow: hidden;
}
/* button */
.btn-flex-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  button,
  a {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    width: auto;

    span {
      font-weight: 400;
    }

    & + button,
    & + a {
      margin-left: 1.5rem;
    }
  }
}

.btn {
  position: relative;
  width: 100%;
  padding: 2.2rem 1rem 1.9rem;
  font-weight: 400;
  font-size: 1.8rem;
  color: $dark;
  background-color: transparent;
  background-repeat: no-repeat;
  border-width: 1px;
  border-radius: 0.3rem;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  background-color: $white;
  box-sizing: border-box;
}
.btn.positive {
  color: $white;
  border-color: #28284b;
  background-color: #28284b;
}
.btn.disabled {
  color: $white;
  border-color: #cfcfda;
  background-color: #cfcfda;
}
.btn.active {
  border-color: $warning;
  background-color: $warning;
}
.btn.stroke {
  border-color: $gray-500;
  background-color: $white;
}
.btn span {
  position: relative;
}
.btn .call {
  display: inline-block;
  width: 2rem;
  margin: 0 1rem 0 0;
  background: url('#{$img-cdn}/ico_call_141x142.png') no-repeat center 20% / 100%;
  line-height: 1;
  text-indent: -99999px;
}

/* no content */
.no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  padding: 140px 0 240px;

  .ico {
    width: 18rem;
    height: 18rem;
    margin-bottom: 1.2rem;
  }

  .title {
    font-weight: 700;
    font-size: 2.2rem;
    color: #000;
  }
  .desc {
    margin: 1rem 0 0;
    font-size: 1.6rem;
    color: $gray-700;
  }
}

/* header */
header {
  position: relative;
  z-index: 20;
  width: 100%;
  height: 8.8rem;
  font-size: 1.5rem;
  background-color: $white;
  border-bottom: 1px solid $gray-300;
  // transition: all 0.3s;

  &.remove {
    top: -8.9rem;
  }

  .logo {
    display: block;
    width: 7.7rem;
    height: 2.1rem;
    background: url('#{$img-cdn}/img_logo_634x150.png') no-repeat 0 0 / 100%;
  }

  .inner-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 8.8rem;
    margin: 0 auto;
  }

  .nav-group {
    flex: 1 1 auto;

    h1 {
      flex: 0 0 8rem;
      margin-right: 1rem;
    }

    button {
      display: none;
    }
  }

  .nav-item {
    display: flex;
    align-items: center;
  }

  .nav-global {
    flex: 0 1 14rem;
    text-align: right;

    a,
    button {
      display: inline-block;
      width: 4.2rem;
      height: 4.2rem;
      margin-left: 1rem;
      vertical-align: middle;
    }
  }

  & .nav-group:hover {
    &:after {
      content: '';
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      width: 100%;
      height: 38.5rem;
      background: $white;
      border-top: 0.1rem solid $gray-200;
      border-bottom: 0.1rem solid $gray-200;
      visibility: visible;
      opacity: 1;
      z-index: 9;
    }
    nav {
      ul ul {
        display: block;
        width: 100%;
      }
    }
  }
}

nav {
  width: 100%;
  padding: 0 0 0 3rem;
  box-sizing: border-box;

  ul {
    //overflow:hidden;
    &::after {
      content: '';
      display: block;
      clear: both;
    }

    &:hover {
      display: block;
    }
  }
  li {
    li {
      width: 100%;
      text-align: left;
      letter-spacing: -1px;
      word-break: keep-all;

      a {
        padding: 2rem 0;
        line-height: 1;

        &.active {
          font-weight: 700;
        }
      }

      &.new {
        a::after {
          content: '';
          display: inline-block;
          position: relative;
          width: 6rem;
          height: 3rem;
          margin-bottom: 0.3rem;
          margin-left: 1rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_new_60x30.png)
            0 50% / auto 100%;
          vertical-align: middle;
        }
      }
      &.hot {
        a::after {
          content: '';
          display: inline-block;
          position: relative;
          width: 6rem;
          height: 3rem;
          margin-bottom: 0.3rem;
          margin-left: 1rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_hot_60x30.png)
            0 50% / auto 100%;
          vertical-align: middle;
        }
      }
      &.best {
        a::after {
          content: '';
          display: inline-block;
          position: relative;
          width: 6rem;
          height: 3rem;
          margin-bottom: 0.3rem;
          margin-left: 1rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_best_60x30.png)
            0 50% / auto 100%;
          vertical-align: middle;
        }
      }
      &.premium {
        a::after {
          content: '';
          display: inline-block;
          position: relative;
          width: 10.6rem;
          height: 3rem;
          margin-bottom: 0.3rem;
          margin-left: 1rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_premium_102x28.png)
            0 50% / auto 100%;
          vertical-align: middle;
        }
      }
      &.event {
        a::after {
          content: '';
          display: inline-block;
          position: relative;
          width: 7rem;
          height: 3rem;
          margin-bottom: 0.3rem;
          margin-left: 1rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_event_70x30.png)
            0 50% / auto 100%;
          vertical-align: middle;
        }
      }
      &.soldout {
        a::after {
          content: '';
          display: inline-block;
          position: relative;
          width: 5rem;
          height: 3rem;
          margin-bottom: 0.3rem;
          margin-left: 1rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_soldout_46x28.png)
            0 50% / auto 100%;
          vertical-align: middle;
        }
      }
      &.free {
        a::after {
          content: '';
          display: inline-block;
          position: relative;
          width: 8rem;
          height: 3rem;
          margin-bottom: 0.3rem;
          margin-left: 1rem;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_free.png)
            0 50% / auto 100%;
          vertical-align: middle;
        }
      }
    }

    & > ul {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      z-index: 10;
      box-sizing: border-box;
    }

    &.active {
      & > a {
        font-weight: 700;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: 1px solid $dark;
          transform: translateX(0%);
        }
      }
    }
  }
  a {
    display: block;

    &:hover {
      font-weight: 700;
    }
  }

  & > ul > li {
    display: inline-block;
    position: relative;
    padding-right: 6rem;
    box-sizing: border-box;

    & > a {
      display: block;
      position: relative;
      height: auto;
      padding: 3.45rem 0;
      text-align: left;
    }

    &.yafit > a {
      &::before {
        content: '';
        position: absolute;
        top: 1.5rem;
        left: 0;
        width: 2.8rem;
        height: 1.8rem;
        background: url('#{$img-cdn}/ico_yafit_96x54.png') no-repeat left top / 3.2rem auto;
      }
    }
  }
}

.header-prev {
  display: none;
}

/* footer */
footer {
  border-top: 1px solid #cfcfda;

  &.isOpen {
    .inner-col {
      padding-bottom: 7rem;
    }
  }

  .inner-col {
    padding: 6.9rem 3rem 20rem;
  }

  .footer-logo {
    position: absolute;
    top: 6.9rem;
    left: 3rem;
    z-index: 9;

    a {
      display: inline-block;
      width: 14.8rem;
      height: 3.5rem;

      img {
        width: 100%;
      }
    }
  }

  .footer-nav-menu {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 50px;

    dl dd {
      margin-top: 3.5rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    dl + dl {
      margin-left: 15rem;
    }

    dl dt {
      font-weight: 700;
      font-size: 1.6rem;
    }

    dd + dd {
      margin-top: 3.1rem;
    }

    .footer-nav-menu__info {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-left: 0;

      dd {
        margin-top: 0.7rem;
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 700;
        color: #717188;

        + dd {
          margin-top: 0;
        }

        span {
          font-weight: 400;
          color: #b7b7c8;
          margin-left: 4px;
        }
      }
    }
  }

  .footer-info {
    strong {
      display: none;
    }

    .tel {
      display: inline-block;
      font-weight: 700;
      font-size: 3.5rem;
    }

    span {
      display: block;
      margin-top: 1.5rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: $gray-700;
    }

    &,
    span + span {
      margin-top: 0.8rem;
    }
  }

  .footer-utility {
    position: absolute;
    top: 75px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 280px;
    gap: 20px;

    .utility-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 15px;
    }

    a {
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 2.2rem;
      color: #333;

      span {
        color: #ff5647;
      }
    }
  }

  .footer-cs-title {
    margin-top: 25px;
    dl {
      display: flex;
      flex-direction: row;
      dt,
      dd {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.22px;
      }

      dt {
        font-size: 16px;
        font-weight: 700;
        margin-right: 15px;
      }
    }
  }

  .btn-corp-toggle {
    display: inline-flex;
    flex-direction: row;
    margin-left: 6px;
    line-height: 1;

    &::after {
      display: block;
      width: 10px;
      height: 10px;
      margin-left: 4px;
      background-image: url('#{$img-cdn}/ico_arrow_50x50g.png');
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      content: '';
    }
  }

  address {
    position: relative;
    z-index: 2;
    margin-top: -4rem;

    > div {
      margin-top: 16px;
    }

    p {
      font-weight: 400;
      font-size: 1.2rem;
      font-style: normal;
      color: $gray-700;
      line-height: 1.5;
    }

    & > p:first-child {
      font-size: 1.3rem;
    }
  }

  #footerSelect {
    width: 21.9rem;
    padding: 1.1rem 1.5rem 0.9rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: $dark;
    border: 1px solid $gray-300;
    border-radius: 0;
    background: url('#{$img-cdn}/ico_check_36x21.png') 91% 50% / 1.2rem auto no-repeat;
  }

  .footer-link-area {
    position: absolute;
    z-index: 3;
    top: 30.6rem;
    right: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .footer-link-area__flex {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: flex-start;
      margin-top: 2.5rem;
    }

    a {
      display: inline-block;
      width: 2.8rem;
      height: 2.8rem;

      img {
        width: 100%;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -ms-optimize-contrast;
      }
    }

    a + a {
      margin-left: 1.1rem;
    }

    .link-lists + .link-lists {
      margin-left: 3rem;
    }
  }

  .confirm-list-box {
    h2 {
      margin-top: 3rem;
      font-size: 2.5rem;
    }

    ul {
      li {
        position: relative;
        margin-top: 1rem;
        padding-left: 1rem;
        font-size: 1.5rem;

        &::before {
          content: '-';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          font-weight: 700;
        }
      }
    }
  }

  .footer-notice {
    margin: 0.75rem 0 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3;
    color: #b7b7c8;
  }

  .footer-call {
    margin-top: 2.2rem;
    a,
    p {
      color: #8d8da0;
      font-size: 12px;
      line-height: 1.4;
    }
    strong {
      color: #717188;
      font-weight: 700;
    }
  }
}

.material-counter {
  button {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    color: $gray-700;
    border: 1px solid $gray-700;
    border-radius: 50%;
  }

  span {
    display: inline-block;
    width: 3.6rem;
    color: $gray-900;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 5px;
  }

  body {
    min-width: 360px;

    &.fixed {
      #root {
        padding-top: 27.6rem;
      }
      &.gnb-main {
        #root {
          padding-top: 10rem;
        }
      }
    }
  }

  .fixed body {
    overflow: hidden;
  }

  .inner-col {
    padding: 0;
  }

  /* no content */
  .no-content {
    padding: 130px 0 190px;
    .ico {
      width: 28rem;
      height: 28rem;
      margin-bottom: 1.3rem;
    }

    .title {
      font-size: 3rem;
    }
  }

  /* ico */
  .nav-global span[class^='ico'] {
    width: 5rem;
    height: 5rem;
  }

  .ico-cart em {
    right: -0.2rem;
    bottom: 0;
    min-width: 2.3rem;
    height: 2.3rem;
    padding: 0 0.7rem;
    font-size: 1.4rem;
    line-height: 12.5px;
    border-radius: 1.2rem;
  }

  .ico-hamburger {
    width: inherit;
    height: inherit;
    background-image: url('#{$img-cdn}/ico_hamburger_100x100.png');
  }

  /* header */
  header {
    height: 10.8rem;

    &:hover:after {
      content: '';
      display: none;
    }

    &.remove {
      top: -10.8rem;
    }

    &.line-none {
      border-bottom: 1px solid $white;
    }

    .inner-col {
      width: 100%;
      height: inherit;
      padding: 0 4rem;
      box-sizing: border-box;
    }

    .logo {
      width: 11.2rem;
      height: 3rem;
    }

    .nav-group {
      button {
        display: inline-block;
        position: relative;
        width: 24px;
        height: 24px;
        // margin: auto 4.5rem auto 3.2rem;
      }

      &:hover::after {
        display: none;
      }
    }

    .nav-global {
      flex: 0 0 50%;

      a,
      button {
        width: 5rem;
        height: 5rem;
        align-self: flex-end;
      }

      a:last-child {
        margin-right: 2rem;
      }
    }

    nav {
      display: none;

      li {
        margin: 0;

        &:last-child {
          border: 0;
        }

        a {
          height: inherit;
        }

        ul > li {
          display: none;

          font-size: 2.8rem;
          text-align: left;

          // &:first-child {
          //   padding-top: 2rem;
          // }
        }

        &.active {
          ul {
            display: block;
            position: static;
            left: unset;
            transform: unset;
            width: 100%;
            margin: 0;
            padding: 2rem 6rem;
            border: 0;
            box-sizing: border-box;
            background: #f7f7fc;
          }

          li {
            display: block;
          }

          > a {
            font-weight: 700;

            &:after {
              width: 5rem;
              transform: rotate(180deg) translateY(50%);
              border: unset;
              left: unset;
            }
          }
        }
      }

      & > ul > li {
        padding-right: 0;
      }

      > ul > li {
        &:last-child {
          border: 0;
        }

        > a {
          padding: 3.8rem 4.2rem;
          font-size: 2.8rem;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 6rem;
            width: 5rem;
            height: 5rem;
            background: url('#{$img-cdn}/ico_arrow_50x50g.png') no-repeat 0 0 / 100%;
            transform: rotate(0deg) translateY(-50%);
          }
        }
      }
    }

    &.active-mobile {
      nav {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 80%;
        height: 100%;
        padding: 16rem 0 8rem;
        font-size: 3rem;
        background-color: #fff;
        border-right: 1px solid $gray-300;
        box-sizing: border-box;
        overflow-y: scroll;
        z-index: 4;

        .logo {
          position: absolute;
          top: 7rem;
          left: 4rem;
          width: 14rem;
          height: 4rem;
        }

        > ul > li {
          display: block;
          width: 100%;

          a {
            text-align: left;
          }

          &.yafit > a {
            &::before {
              top: 0.5rem;
              left: 5rem;
              width: 5rem;
              height: 2.8rem;
              background-size: 5rem auto;
            }
          }
        }

        .btn-join-group {
          margin: 5rem 0 0;
          padding: 0 4.2rem;

          button {
            display: block;
            width: 100%;
            height: 9.6rem;
            margin: 0 0 1.7rem;
          }
        }

        .btn-app-download {
          margin: 16.5rem 0 0;
          padding: 0 4.2rem 0;

          .title {
            display: block;
            margin: 0 0 2.5rem;
          }

          button {
            width: 10rem;
            height: 10rem;
          }
        }
      }

      .nav-close {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background: #000;
        opacity: 0.7;
        z-index: 3;

        span {
          display: block;
          position: absolute;
          top: 50%;
          right: 10%;
          width: 7rem;
          height: 7rem;
          background: #000 url('#{$img-cdn}/ico_close_201x201.png') no-repeat right (center / 100%);
        }
      }
    }
  }

  .nav-close {
    display: none;
  }

  .header-prev {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 10.6rem;
    background-color: #fff;
    z-index: 22;
    button {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 11rem;
      height: 100%;
      margin: auto;
      text-indent: -9999rem;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 4rem;
        width: 5rem;
        height: 5rem;
        margin: auto;
        background: url('#{$img-cdn}/ico_btn_back_100x100b.png') no-repeat 50% 50% / 100% auto;
      }
      & + strong {
        margin-left: 12rem;
      }
    }
    strong {
      display: inline-block;
      flex: 1;
      padding-top: 0.2rem;
      margin-left: 4rem;
      font-weight: 400;
      font-size: 3.1rem;
      line-height: 1;
      color: #000;
    }
  }
  .fixed .header-prev {
    position: fixed;
    top: 0;
    left: 0;
  }

  /* button */
  .btn-flex-form .btn + .btn {
    margin-left: 1.8rem;
  }

  .btn {
    padding: 3.3rem 1rem 3.1rem;
    font-size: 2.7rem;
  }

  /* footer */
  footer {
    &.isOpen .inner-col,
    .inner-col {
      padding: 8rem 4.2rem 12rem;
    }
    .footer-logo {
      top: 8rem;
      right: 4.2rem;
      left: auto;

      a {
        width: 18rem;
        height: 4.4rem;

        img {
          width: 100%;
        }
      }
    }

    .footer-nav-menu {
      display: block;
      position: static;
      padding-bottom: 8rem;

      dl + dl {
        margin-top: 8rem;
        margin-left: 0;
      }

      dl dt {
        font-size: 2.6rem;
      }

      dl dd {
        margin-top: 4rem;
        font-size: 2.2rem;
      }

      dl dd + dd {
        margin-top: 4rem;
      }

      .footer-nav-menu__info {
        position: static;

        dd {
          margin-top: 4rem;
          font-size: 2.2rem;
          font-weight: 400;
          color: #333;

          + dd {
            margin-top: 1.6rem;
          }

          span {
            font-weight: 400;
            color: #b7b7c8;
            margin-left: 4px;
          }
        }
      }
    }

    .footer-info {
      margin-top: 8rem;

      strong {
        display: block;
        font-weight: 700;
        font-size: 2.2rem;
      }

      .tel {
        margin-top: 2rem;
        font-size: 4rem;
        color: #1f1f1f;
      }

      span {
        margin-top: 1.8rem;
        font-weight: 400;
        font-size: 2.2rem;
      }

      span + span {
        margin-top: 1rem;
      }
    }

    .footer-link-area {
      position: static;
      flex-direction: column-reverse;
      align-items: flex-start;

      .footer-link-area__flex {
        margin-top: 0;
        margin-bottom: 8rem;
      }

      a {
        width: 7rem;
        height: 7rem;
      }

      a + a {
        margin-left: 1.5rem;
      }

      .link-lists + .link-lists {
        margin-left: 5rem;
      }
    }

    #footerSelect {
      position: static;
      display: block;
      width: 100%;
      padding: 3rem 3rem 2.6rem;
      font-size: 2rem;
      background-size: 2rem auto;
      background-position: 97% 50%;
    }
    .footer-utility {
      top: 109rem;
      left: 4.2rem;
      right: 4.2rem;
      margin-top: 5rem;

      a {
        font-size: 2rem;

        span {
          color: #ff5647;
        }
      }

      a + a {
        &:before {
          margin-right: 2.8rem;
          width: 0.6rem;
          height: 0.6rem;
        }
      }
    }

    address {
      margin-top: 23rem;

      > div {
        margin-top: 2.5rem;
      }

      p {
        font-size: 2rem;
        line-height: 1.4;
      }

      & > p:first-child {
        font-size: 2.2rem;
      }
    }

    .footer-notice {
      margin: 2rem 0 0;
      font-size: 1.8rem;

      br {
        display: none;
      }
    }

    .footer-call {
      margin-top: 3.5rem;
      a,
      p {
        font-size: 2rem;
        line-height: 1.5;
      }
    }
  }

  .ico-tag {
    padding: 1.2rem 2rem;
    font-size: 2rem;
  }

  .footer-logo a {
    width: 10.2rem;
    height: 2.7rem;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -ms-optimize-contrast;
  }
  .material-counter {
    font-size: 2.2rem;

    button {
      width: 3.6rem;
      height: 3.6rem;
    }

    span {
      width: 6.4rem;
    }
  }
}

@media screen and (max-width: 750px) {
  /* no content */
  .no-content {
    padding: 17.333vw 0 25.333vw;
  }
}

input[type='checkbox'],
input[type='radio'] {
  @include blind();

  & + .chk {
    @include chk-default(2.6rem, 2.6rem, 1rem);

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.3rem;
      height: 1.1rem;
    }

    & + span {
      font-size: 1.4rem;
    }
  }

  &:checked + .chk {
    background-color: $warning;
  }
}
input[type='checkbox'] {
  & + .chk {
    &:before {
      background: url('#{$img-cdn}/ico_check_38x30w.png') 50% 50%/100% auto no-repeat;
    }
  }

  &:checked + .chk {
    &:before {
      background-image: url('#{$img-cdn}/ico_check_38x30b.png');
    }
  }
}
input[type='radio'] {
  & + .chk {
    background-color: $white;
    border: 2px solid $gray-500;

    &:before {
      width: 1rem;
      height: 1rem;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &:checked + .chk {
    border: 2px solid $warning;

    &:before {
      background-color: $dark;
    }
  }
}
select {
  &.sct {
    @include sct-default(100%, 4rem, 1rem, $white, 95%, 1.2rem, $gray-400, 0.5rem);
  }
}

i {
  &.blind {
    @include blind();
  }
}

.frm {
  @include frm-default();
}

.btn-block {
  @include button(100%, 10rem, $dark, $warning, 2.7rem, 0.5rem);

  display: block;
}

.btn-group {
  width: 100%;
  margin: 0 auto;

  button {
    @include button(49.5%, 7.2rem, $dark, $warning, 2.2rem, 0.5rem);
  }

  button + button {
    margin-left: 1%;
  }
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header-overflow {
    display: none;
  }
  input[type='checkbox'],
  input[type='radio'] {
    & + .chk {
      @include chk-default(3.6rem, 3.6rem, 1rem);
      &:before {
        width: 2rem;
        height: 1.8rem;
      }
    }
  }
}

.sub-header-inner {
  max-width: 1920px;
  padding: 0 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}
.sub-header-nav {
  max-width: 100%;
  width: 100%;
  position: relative;
  z-index: 4;
}
.right-menu.active {
  z-index: 3;
}
.scroll-nav {
  position: absolute;
  width: auto;
  left: 236px;
}
@media screen and (max-width: 1920px) {
  .sub-header-nav {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -40px;
      width: 236px;
      height: 4.8em;
      background-color: #fff;
      z-index: 5;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -260px;
      width: 236px;
      height: 4.8em;
      background-color: #fff;
      z-index: 4;
    }
  }
  .sub-header-wrap {
    &:before {
      width: calc(50% - (50% - 336px));
    }
    &:after {
      width: calc(50% - (50% - 325px));
    }
  }
}
@media screen and (max-width: 1440px) {
  .sub-header-wrap {
    &:before {
      width: calc(50% - (50% - 276px));
    }
    &:after {
      width: calc(50% - (50% - 260px));
    }
  }
  .sub-header-inner {
    padding: 0 40px;
    contain: layout;
  }
}
@media screen and (max-width: 1024px) {
  .sub-header-nav {
    &:before,
    &:after {
      display: none;
    }
  }
  .right-menu.active {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .sub-header-inner {
    padding: 0;
    contain: initial;
  }
  .scroll-nav {
    position: static;
  }
}

@media all and (max-width: 540px) {
  /* footer */
  footer {
    &.isOpen .inner-col {
      padding-bottom: 10.4vw;
    }
    .inner-col {
      padding-block: 10.4vw 14.667vw;
    }
    .footer-nav-menu dl dd {
      margin-top: 4.267vw;
    }
    address {
      margin-top: 30.533vw;

      > div {
        margin-top: 3.333vw;
      }

      p {
        font-size: 2.475vw;
      }

      & > p:first-child {
        font-size: 2.933vw;
      }
    }

    .footer-notice {
      margin: 2.667vw 0 0;
      font-size: 2.15vw;
    }

    .footer-call {
      margin-top: 4.667vw;
      a,
      p {
        font-size: 2.5vw;
      }
    }
  }
}
