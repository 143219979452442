.modalWrap {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;

  & > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media all and (max-width: 1024px) {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.modalInner {
  position: relative;
  width: 48rem;
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;

  .icoClose {
    position: absolute;
    top: 0.6rem;
    right: 1.2rem;
    width: 5rem;
    height: 5rem;
    text-indent: -9999rem;
    border: 0;
    outline: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 2.5rem;
      height: 0.1rem;
      margin: auto;
      border-top: 0.1rem solid #000;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 2.5rem;
      height: 0.1rem;
      margin: auto;
      border-top: 0.1rem solid #000;
      transform: rotate(-45deg);
    }

    &:hover {
      background-color: #fff;
    }
  }

  button {
    position: relative;
    width: 100%;
    height: 8rem;
    color: #000;
    font-weight: 400;
    font-size: 2rem;
    border: 1px solid #e7e7f0;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #f7f7fc;
    }

    & + button {
      margin-top: 2rem;
      color: #ff5647;
    }
  }

  @media all and (max-width: 1024px) {
    width: 100%;
    min-height: calc(100% - 8.7rem);
    max-height: calc(100% - 8.7rem);
    border-radius: 2rem 2rem 0 0;
    overflow: auto;

    .icoClose {
      top: 1.2rem;
      right: 2.4rem;
      width: 10rem;
      height: 10rem;

      &:before {
        width: 5rem;
        border-top: 0.1rem solid #000;
      }

      &:after {
        width: 5rem;
        border-top: 0.1rem solid #000;
      }
    }
  }
}

.collegeBox {
  padding: 10.3rem 0 3.2rem;

  dl {
    dt {
      font-size: 2rem;
      line-height: 1.3;
      color: #000;
      text-align: center;

      img {
        display: block;
        margin: 2.4rem auto;
        width: 75%;
      }

      strong {
        display: block;
        padding-bottom: 2rem;
        font-weight: 700;
        font-size: 3rem;
        color: #000;
        text-align: center;
      }
    }

    dd {
      padding: 1.5rem 3.2rem;
      box-sizing: border-box;

      input {
        width: 100%;
        padding: 1rem 1.8rem;
        font-size: 1.9rem;
        border-bottom: 0.1rem solid #e3e3ed;
        box-sizing: border-box;

        &::placeholder {
          color: #b7b7c8;
        }
      }
    }
  }

  .btnComplete {
    display: block;
    width: calc(100% - 6.4rem);
    height: 6.4rem;
    margin: 3rem auto 0;
    font-size: 1.7rem;
    color: #fff;
    background-color: #cfcfda;
    border: 0;
    outline: 0;
    border-radius: 0.3rem;

    &:hover {
      background-color: #cfcfda;
    }

    &.active {
      color: #000;
      background-color: #ffb900;
    }
  }

  @media all and (max-width: 1024px) {
    padding: 13.5rem 0 3.2rem;

    dl {
      dt {
        font-size: 3.1rem;

        strong {
          padding-bottom: 3.2rem;
          font-size: 4.7rem;
        }

        img {
          margin: 3rem auto;
          width: 75%;
          max-width: 60rem;
        }
      }

      dd {
        padding: 3rem 4.2rem;

        input {
          width: 100%;
          padding: 2rem 2.2rem;
          font-size: 2.9rem;
          border-bottom: 0.1rem solid #e3e3ed;
        }
      }
    }

    .btnComplete {
      height: 10rem;
      font-size: 2.7rem;
      border-radius: 0.4rem;
    }
  }
}
