.chatTopSrr {
  padding: 40px 12px 32px;

  .srrImg {
    display: block;
    width: 74px;
    height: 74px;
    margin: 0 auto;
  }

  .titleGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-top: 11px;

    .title {
      color: #000;
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
    }
    .description {
      color: #717188;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      text-align: center;
    }
  }

  .tips {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 3px;
    width: 100%;
    border-radius: 24px;
    padding: 12px;
    background: #eff7ff;
    box-sizing: border-box;
    margin-top: 24px;

    .tipImg {
      display: block;
      width: 24px;
      margin-top: -2px;
    }

    .tipTxt {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #28284b;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;

      span {
        color: #269cff;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 52px 40px;
    .srrImg {
      width: 120px;
      height: 120px;
    }
    .titleGroup {
      margin-top: 20px;
      .title {
        font-size: 27px;
        line-height: 36px;
      }

      .description {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.5px;
      }
    }

    .tips {
      border-radius: 38px;
      margin-top: 32px;
      padding: 18px;

      .tipImg {
        width: 32px;
        margin-top: -3px;
      }
      .tipTxt {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.5px;
      }
    }
  }
}
