.feedbackSection {
  padding: 0 48px 120px;
  background-color: #fff;
  .download {
    margin-top: 22px;
    text-align: center;
    p {
      margin-top: 36px;
      color: #717188;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
    }
    .quiz {
      background: #28284b;
    }

    .lv1 {
      background-color: #ff4635;
    }

    .lv2 {
      background-color: #ff7700;
    }

    .lv3 {
      background-color: #06c000;
    }

    .lv4 {
      background-color: #00bab7;
    }

    .lv5 {
      background-color: #107dff;
    }

    .lv6 {
      background-color: #9c46ff;
    }
    button {
      height: 112px;
      width: 492px;
      border-radius: 18px;
      font-weight: 500;
      letter-spacing: -0.5px;
      span {
        color: #fff;
        font-size: 32px;
        img {
          margin-top: 1px;
          margin-right: 13px;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
.feedback {
  margin-bottom: 100px;
  h4 {
    font-size: 44px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.7px;
    text-align: center;
    margin: 24px 0 36px;
  }
  .quizDescription {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    color: #28284b;
    br {
      content: '';
      display: block;
      margin-bottom: 14px;
    }
  }
  .conDescription {
    li {
      margin-bottom: 44px;
    }
    .question {
      font-size: 28px;
      font-weight: 700;
      line-height: 40px;
      color: #030617;
    }
    .answer,
    .questionFeedback,
    .learningGuide,
    .totalFeedback {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: #717188;
    }
    .answer,
    .questionFeedback {
      list-style-type: disc;
      list-style-position: outside;
      display: list-item;
      margin-left: 37px;
    }
    .totalFeedback {
      margin-left: 33px;
    }
    .totalFeedbackTitle {
      font-size: 28px;
      font-weight: 700;
      line-height: 40px;
      color: #030617;
      list-style-type: disc;
      list-style-position: outside;
      display: list-item;
      margin-left: 35px;
      margin-top: 5px;
    }
    .learningGuideBox {
      margin-top: 0;
    }
    .learningGuide {
      margin-top: 0;
      margin-bottom: 2px;
      list-style-type: decimal;
      list-style-position: outside;
      display: list-item;
      margin-left: 65px;
    }
    h5 {
      font-size: 28px;
      font-weight: 700;
      line-height: 40px;
      color: #030617;
    }
  }
  .badge {
    padding: 8px 27px;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    border-radius: 30px;

    width: fit-content;
    margin: 0 auto;
  }
  .lv1 {
    background-color: #ffe1df;
    color: #ff4635;
  }
  .lv2 {
    background-color: #ffe7c3;
    color: #ff7700;
  }
  .lv3 {
    background-color: #e0f1cc;
    color: #06c000;
  }
  .lv4 {
    background-color: #d9efef;
    color: #00bab7;
  }
  .lv5 {
    background-color: #d9ebff;
    color: #107dff;
  }
  .lv6 {
    background-color: #e9dff5;
    color: #9c46ff;
  }
}
@media all and (max-width: 750px) {
  .feedbackSection {
    padding: 0 6.4vw 16vw;
    .download {
      margin-top: 2.9333vw;

      p {
        margin-top: 4.8vw;

        font-size: 3.2vw;

        line-height: 4vw;
      }

      button {
        height: 14.9333vw;
        width: 65.6vw;
        border-radius: 2.4vw;
        letter-spacing: -0.0667vw;
        span {
          font-size: 4.2667vw;
          img {
            margin-top: 0.1333vw;
            margin-right: 1.7333vw;
            width: 4.2667vw;
            height: 4.2667vw;
          }
        }
      }
    }
  }
  .feedback {
    margin-bottom: 13.3333vw;
    h4 {
      font-size: 5.8667vw;
      line-height: 7.4667vw;
      letter-spacing: -0.0933vw;
      margin: 3.2vw 0 4.8vw;
    }
    .quizDescription {
      font-size: 3.7333vw;
      line-height: 5.3333vw;
      br {
        margin-bottom: 1.8667vw;
      }
    }
    .conDescription {
      li {
        margin-bottom: 5.8667vw;
      }
      .question {
        font-size: 3.7333vw;
        line-height: 5.3333vw;
      }
      .answer,
      .questionFeedback,
      .learningGuide,
      .totalFeedback {
        font-size: 3.7333vw;
        line-height: 5.3333vw;
      }
      .answer,
      .questionFeedback {
        margin-left: 4.9333vw;
      }
      .totalFeedback {
        margin-left: 4.4vw;
      }
      .totalFeedbackTitle {
        font-size: 3.7333vw;
        line-height: 5.3333vw;
        margin-left: 4.6667vw;
        margin-top: 0.6667vw;
      }
      .learningGuide {
        margin-left: 8.6667vw;
        margin-bottom: 0.2667vw;
      }
      h5 {
        font-size: 3.7333vw;
        line-height: 5.3333vw;
      }
    }
    .badge {
      padding: 1.0667vw 3.6vw;
      font-size: 4.2667vw;
      line-height: 5.164vw;
      border-radius: 4vw;
    }
  }
}
