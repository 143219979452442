@charset "UTF-8";
@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.member-drop-col {
  #lnb + section {
    padding: 8rem 0;
  }

  .notice {
    //display: block;
    padding: 4rem;
    font-size: 2rem;
    background: $gray-100;
    text-align: center;

    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 5rem;
      height: 4.4rem;
      margin: 0 2rem 0 0;
      background: url('#{$img-cdn}/ico_red_triangle_150x132.png') no-repeat 0 0 / 100%;
      vertical-align: middle;
    }
  }

  label {
    display: block;
    font-size: 1.6rem;
    color: $dark;
    cursor: pointer;
  }

  .mobile-progress {
    display: none;
  }

  & [class*='step-'] {
    .title {
      padding: 2.6rem 0;
      font-weight: 400;
      font-size: 2rem;
      color: $dark;
      text-align: center;
      border-top: 0.3rem solid $dark;
      border-bottom: 0.1rem solid $gray-300;

      span {
        display: block;
        width: 2.6rem;
        height: 2.6rem;
        margin: 0 auto 1.6rem;
        font-size: 1.4rem;
        line-height: 2.6rem;
        background: $warning;
        border-radius: 50%;
        text-align: center;
      }

      p {
        margin: 0.4rem 0 0;
        font-weight: 400;
        font-size: 1.2rem;
        color: $gray-800;

        &:before {
          content: '※';
          display: inline-block;
        }
      }
    }
  }

  .step-01 {
    li {
      padding: 2.5rem;
      font-weight: 400;
      font-size: 1.6rem;
      color: $dark;
      border-top: 0.1rem solid $gray-300;

      &:first-child {
        border: 0;
      }

      span {
        display: inline-block;
        width: 4.4rem;
        padding: 0.4rem 0;
        margin: 0 2rem 0 0;
        font-weight: 400;
        font-size: 1.2rem;
        color: $white;
        background: $gray-900;
        border-radius: 0.5rem;
        text-align: center;
      }

      strong {
        font-weight: 400;
      }
    }
  }

  .step-02 {
    .content {
      padding: 3rem 0 4rem;

      li {
        padding: 2rem 2.5rem;
        font-weight: 400;
        font-size: 1.6rem;

        &:before {
          content: '※';
          display: inline-block;
          margin: 0 0.5rem 0 0;
        }
      }
    }
  }

  .step-03 {
    .content {
      padding: 3rem 0 4rem;

      li {
        padding: 1.5rem;
        font-size: 1.6rem;

        label {
          height: 3.7rem;
          line-height: 3.7rem;
          box-sizing: border-box;
        }
      }
    }

    .etc-wrap {
      display: inline-block;
      width: 70%;
    }
  }

  .step-04 {
    .title {
      display: none;
    }
    .sub-title {
      border-top: 0.3rem solid $dark;
      padding: 2rem 3rem;
      font-weight: 400;
      font-size: 1.6rem;
      background: $gray-100;
    }

    .sub-content {
      padding: 2rem 3rem;
      font-size: 1.6rem;
      border: 0.1rem solid $gray-300;

      textarea {
        font-size: 1.6rem;
        width: 100%;
        border: 0;
        outline: 0;
        resize: none;

        &::placeholder {
          color: $gray-500;
          opacity: 1;
        }

        &::-webkit-input-placeholder {
          color: $gray-500;
        }

        &:-ms-input-placeholder {
          color: $gray-500;
        }

        &:-moz-placeholder {
          color: $gray-500;
        }
      }
    }
  }

  .final-confirm {
    margin: 7rem 0 0;
    font-weight: 400;
    font-size: 1.6rem;
    text-align: center;

    .btn {
      width: 49.5rem;
      margin: 3rem auto 0;
      font-size: 2rem;
      color: $white;
      background: $gray-500;
    }
    .active {
      color: $dark;
      background: $warning;
    }
  }

  .confirm-area {
    display: block;
    margin-top: 7rem;
    text-align: center;

    .btn {
      display: block;
      width: 100%;
      margin: 0 auto;

      &.btn-withdrawal {
        width: 49.5rem;
        margin-top: 3.3rem;
        color: $white;
        background-color: $gray-500;
        border: $gray-500;
      }

      &.active {
        color: $dark;
        border-color: $warning;
        background-color: $warning;
      }
    }
  }

  .etc-textarea {
    width: 100%;
    margin: 0 0 0 2rem;
    padding: 1.1rem 1.5rem;
    font-size: 1.6rem;
    border: 0.1rem solid $gray-300;
    color: $dark;
    outline: none;
    resize: none;

    &::placeholder {
      color: $gray-500;
      opacity: 1;
    }

    &::-webkit-input-placeholder {
      color: $gray-500;
    }

    &:-ms-input-placeholder {
      color: $gray-500;
    }

    &:-moz-placeholder {
      color: $gray-500;
    }
  }

  input[type='checkbox'] {
    display: none;

    + em {
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 1.5rem 0 0;
      font-size: 0;
      background: url('#{$img-cdn}/ico_check_off_101x100.png') no-repeat 0 0 / 100%;
      vertical-align: middle;
    }

    &:checked + em {
      background: url('#{$img-cdn}/ico_check_on_101x101.png') no-repeat 0 0 / 100%;
    }
  }

  .agree {
    display: flex;
    align-items: center;
    justify-content: center;

    input[type='checkbox'] {
      + em {
        flex: 0 0 auto;
      }
    }

    p {
      flex: 0 1 auto;
    }
  }

  // 모바일 탈퇴화면
  .step-component.mobile {
    display: flex;
    flex-direction: column;

    .step-02 {
      order: 1;
    }

    .step-03 {
      order: 2;
    }

    .step-01 {
      order: 4;
    }

    .step-04 {
      order: 3;
    }
  }
}

.member-auth-col {
  #lnb + section {
    padding: 8rem 0;
  }

  h2 {
    font-weight: 700;
    font-size: 2rem;
  }

  .info {
    margin: 2rem 0 0;
    padding: 3rem;
    font-weight: 400;
    font-size: 2rem;
    background: $gray-100;

    ul {
      margin: 2rem 0 0;

      li {
        font-size: 1.2rem;
        color: $gray-800;
        line-height: 1.7;

        &:before {
          content: '※';
          display: inline-block;
        }
      }
    }
  }

  .wrap {
    margin: 5rem 0 0;
    border-top: 0.3rem solid $dark;
    border-bottom: 0.1rem solid $gray-500;

    div {
      //   display: flex;
      //   flex-wrap: initial;
      //   align-items: center;
      //   padding: 2rem;
      //   border-top: 0.1rem solid $gray-300;

      //   &:first-child {
      //     border: 0;
      //   }

      &.certified-box {
        padding: 0;
      }
    }

    dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.1rem 2.5rem;

      + dl {
        border-top: 1px solid #e7e7f0;
      }

      dt {
        flex: 0 0 auto;
        width: 9rem;
        // margin-right: 6.5rem;
        font-weight: 400;
        font-size: 1.6rem;
        color: $dark;
      }

      dd {
        display: flex;
        flex: 0 1 75%;
      }

      .get-country {
        flex: 1 1 30%;
        //   width: 30rem;
        height: auto;
        padding: 1.6rem 1.3rem;
        border-radius: 0;
      }

      .certified-code-form {
        .sub-title {
          display: none;
          // width: 21rem;
          // flex: 1 1 auto;
        }

        input[type='text'] {
          flex: 1 1 64%;
        }

        .btn {
          &.btn-certified-code {
            flex: 0 1 auto;
            width: 8rem;
            height: 5rem;
            margin-left: 0;
            padding: 0;
            font-size: 1.4rem;
          }
        }

        .timer {
          transform: translateY(-0.8rem);
          right: 10.5rem;
        }
      }
    }

    .sub-title {
      display: block;
      width: 21rem;
      // margin:0 12rem 0 0;
      flex: 1 1 auto;
      font-weight: 400;
      font-size: 1.6rem;
      color: $dark;
    }

    select,
    input[type='text'],
    input[type='tel'] {
      flex: 1 1 auto;
      margin: 0 1rem 0 0;
      padding: 1.6rem;
      font-size: 1.6rem;
      border: 0.1rem solid $gray-300;
      color: $dark;
      box-sizing: border-box;

      &::placeholder {
        color: $gray-500;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $gray-500;
      }
      &:-ms-input-placeholder {
        color: $gray-500;
      }
      &:-moz-placeholder {
        color: $gray-500;
      }
    }

    input[type='text'] {
      flex: 1 1 20%;
    }

    button {
      flex: 0 1 auto;
      width: 8rem;
      height: 5rem;
      padding: 0;
      font-size: 1.4rem;
      color: $white;
      background: $gray-500;
      border-radius: 0.2rem;
      box-sizing: border-box;

      &.active {
        background: $warning;
        color: $dark;
      }
    }
  }

  .final-confirm {
    margin: 7rem 0 0;
    font-weight: 400;
    font-size: 1.6rem;
    text-align: center;

    .btn {
      width: 49.5rem;
      margin: 3rem auto 0;
      font-size: 2rem;
      color: $white;
      background: $gray-500;
    }
    .active {
      color: $dark;
      background: $warning;
    }
  }

  label {
    display: block;
    cursor: pointer;
  }

  input[type='checkbox'] {
    display: none;

    + em {
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 1.5rem 0 0;
      font-size: 0;
      background: url('#{$img-cdn}/ico_check_off_101x100.png') no-repeat 0 0 / 100%;
      vertical-align: middle;
    }

    &:checked + em {
      background: url('#{$img-cdn}/ico_check_on_101x101.png') no-repeat 0 0 / 100%;
    }
  }

  .confirm-area {
    display: none;
  }
}

.step-component {
  & > div.hidden {
    display: block;
  }
}

.non-member-col {
  .class-empty {
    padding: 23rem 0;
  }
}

.nonmember {
  &.no-content {
    position: relative;
    min-height: 80vh;
  }
}

.modal-wrap {
  &.nonmember {
    .inner {
      padding: 0 3.2rem 3.2rem;
    }

    h3 {
      padding-top: 3.2rem;
      font-weight: 700;
      font-size: 2.9rem;
      line-height: 1.5;

      &::before {
        content: '';
        display: block;
        width: 3.5rem;
        height: 3.5rem;
        margin: 0 0 2.7rem;
        background: url('#{$img-cdn}/ico_notice_140x140.png') no-repeat 0 0 / 100%;
      }
    }

    .order-info {
      position: relative;
      margin-top: 7rem;

      .title {
        display: block;
        padding: 0 0 1.3rem;
        font-weight: 400;
        font-size: 1.7rem;
        background-color: transparent;
        color: $gray-800;
        border-bottom: 1px solid $dark;
      }

      .product {
        padding: 2.5rem 1.6rem;
        font-weight: 700;
        font-size: 1.8rem;
        color: $dark;
        border-bottom: 1px solid $gray-300;
      }

      .class-info {
        position: absolute;
        top: 0;
        right: 0;

        span {
          font-weight: 400;
          font-size: 1.7rem;
          color: $gray-800;
        }

        .date {
          margin: 0 0 0 2rem;
          color: $dark;
        }
      }

      & + .order-notice {
        min-height: 13rem;

        p {
          margin: 2rem 0 0;
          font-weight: 400;
          font-size: 1.5rem;
          color: $gray-800;
          line-height: 1.6;
        }
      }
    }

    .notice-col {
      margin: 6rem 0 2.5rem;
      padding: 2.5rem 2.2rem;
      background: $gray-100;

      h4 {
        font-weight: 700;
        font-size: 1.8rem;
        margin: 0 0 2rem;
      }

      ul {
        li {
          position: relative;
          margin: 0 0 1.3rem;
          padding: 0 0 0 0.7rem;
          font-weight: 400;
          font-size: 1.6rem;
          color: $gray-800;
          line-height: 1.6;

          &::before {
            content: '・';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            color: $gray-600;
          }

          strong {
            padding: 0 0.5rem;
            font-weight: 400;
            color: $danger;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .member-drop-col {
    letter-spacing: -0.02em;
    #lnb + section {
      padding: 0;
      border-top: 1px solid #dfdfea;
    }

    .notice {
      padding-top: 4.8rem;
      font-size: 2.9rem;

      &.mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;

        &::before {
          width: 8.8rem;
          height: 8.8rem;
          margin: 0;
        }

        strong {
          margin-top: 2.8rem;
          font-size: 4rem;
          line-height: 4.8rem;
        }

        p {
          margin-top: 0.83rem;
          font-size: 2.8rem;
          line-height: 3.4rem;
          color: #28284b;
        }
      }
      &.desktop {
        display: none;
      }
    }

    label {
      font-size: 2.7rem;

      &.mobile {
        position: relative;
        margin: 0 0 4rem;
        padding: 0 0 0 7rem;

        em {
          position: absolute;
          left: 0;
        }
      }
    }

    & [class*='step-'] {
      .step-title {
        display: flex;
        align-items: center;
        font-size: 3.2rem;
        span {
          display: inline-block;
          padding: 0 1.55rem;
          margin-right: 1.6rem;
          border-radius: 4.8rem;
          border: 1px solid $gray-900;
          font-size: 2.8rem;
          line-height: 4.4rem;
          font-weight: 700;
        }

        p {
          font-size: 2.4rem;
        }
      }
      .content {
        padding: 4.2rem;
        border-top: unset;
      }
    }

    /* 탈퇴 유의 사항 */
    .step-02 {
      background-color: $gray-100;
      .content {
        padding: 4.8rem 0 6.4rem;
        ul {
          margin: 0 7.1rem;
        }
        li {
          padding: 0;
          font-size: 2.4rem;
          line-height: 3.4rem;
          color: #717188;
        }
      }
    }

    /* 탈퇴 사유 */
    .step-03 {
      border-bottom: 1.3rem solid $gray-300;
      .content {
        padding-bottom: 7.2rem;
        ul {
          li {
            padding: 0;
            margin-top: 4.2rem;
            label {
              height: auto;
              font-size: 2.8rem;
              line-height: 4.8rem;
              box-sizing: border-box;

              &.textarea {
                height: auto;
              }
            }
          }
        }

        .etc-wrap {
          display: block;
          width: 100%;
          margin-top: 7.2rem;

          p {
            font-size: 2.4rem;
            line-height: 3rem;
          }

          .textarea-wrap {
            position: relative;
            height: 32rem;
            margin-top: 1.2rem;
            border: 1px solid $gray-300;

            .etc-textarea {
              display: block;
              width: 100%;
              height: 100%;
              margin: 0;
              max-height: 24rem;
              padding: 3.1rem 2.4rem;
              font-size: 2.8rem;
              line-height: 3.4rem;
              border: 0;
              color: $dark;
              box-sizing: border-box;

              &::placeholder {
                color: $gray-500;
                opacity: 1;
              }
              &::-webkit-input-placeholder {
                color: $gray-600;
              }
              &:-ms-input-placeholder {
                color: $gray-600;
              }
              &:-moz-placeholder {
                color: $gray-600;
              }
            }

            .etc-count {
              position: absolute;
              right: 2.4rem;
              bottom: 2.4rem;
              font-size: 2.4rem;
              line-height: 3rem;
              color: $gray-600;

              span {
                color: #000;
              }
            }
          }
        }
      }
    }

    /* 회원 혜택 */
    .step-01 {
      padding-top: 3rem;
      ul {
        margin: 2.4rem -4.2rem 0;
      }
      li {
        padding: 4.8rem 4.2rem;
        font-size: 2.5rem;
        border-color: $gray-400;
        border-width: 0.2rem;

        span {
          display: block;
          width: 7.9rem;
          height: 3.8rem;
          margin: 0 0 2.5rem;
          padding: 0.7rem 0;
          font-size: 2.1rem;
          box-sizing: border-box;
        }

        strong {
          font-weight: 700;
        }
      }
    }

    .confirm-area {
      display: block;
      margin: 0;
      padding: 7.2rem 4.2rem 4.2rem;
      border-top: 1px solid $gray-400;
      text-align: left;

      .btn {
        &.btn-withdrawal {
          width: 100%;
          margin-top: 6.8rem;
        }
      }
    }

    .agree {
      align-items: flex-start;
      justify-content: flex-start;
      line-height: 4.2rem;
      word-break: keep-all;
    }

    input[type='checkbox'] {
      display: none;

      + em {
        display: inline-block;
        width: 4.8rem;
        height: 4.8rem;
        margin: 0 1.5rem 0 0;
        border-radius: 50%;
        border: 1px solid $gray-500;
        font-size: 0;
        background-image: none;
        vertical-align: top;
        box-sizing: border-box;
      }

      &:checked + em {
        background: url('#{$img-cdn}/ico_check_on_101x101.png') no-repeat 0 0 / 100%;
        background-size: cover;
        border: 0;
      }
    }
  }

  .member-auth-col {
    #lnb + section {
      padding: 0 0 8rem;
    }

    h2 {
      display: none;
    }

    .info {
      margin: 0;
      padding: 8rem 4rem 8rem;
      font-weight: 400;
      font-size: 4.6rem;
      background: $gray-100;

      ul {
        margin: 2rem 0 0;

        li {
          font-size: 2.7rem;
          color: $gray-800;
          line-height: 1.7;

          &:before {
            content: '※';
            display: inline-block;
          }
        }
      }
    }
    .wrap {
      border: 0;

      dl {
        flex-wrap: wrap;
        padding: 2.5rem 4.2rem;

        + dl {
          border-top: 0;
        }

        dt {
          width: 100%;
          font-size: 2.5rem;
        }

        dd {
          flex-wrap: wrap;
          flex: 1 1 auto;
          margin-top: 1.7rem;
        }

        .get-country {
          flex: 0 0 auto;
          width: 100%;
          margin-top: 0;
          padding: 2.7rem 2.5rem;
        }

        .certified-code-form {
          .sub-title {
            display: none;
            // width: 21rem;
            // flex: 1 1 auto;
          }

          input[type='text'] {
            margin-right: 3.4rem;
          }

          .btn {
            &.btn-certified-code {
              flex: 0 1 auto;
              width: 14.6rem;
              height: 10rem;
              font-size: 2.7rem;
              border-radius: 0.4rem;
            }
          }

          .timer {
            top: 50%;
            right: 18.5rem;
          }
        }
      }

      label {
        display: block;
        width: 100%;
        margin: 0 0 1rem;
        font-weight: 400;
        font-size: 2.5rem;
        color: $dark;
      }

      select {
        width: 100%;
        font-size: 2.9rem;
        margin: 1rem 0 3rem;
        padding: 2.7rem;
        // background: $white;
        background-size: 2.2rem 2.2rem;
        background-position-x: 97%;
        border: 0.1rem solid $gray-300;
        color: $dark;
        box-sizing: border-box;
      }

      input[type='text'],
      input[type='tel'] {
        width: calc(100% - 21%) !important;
        padding: 3.2rem;
        font-size: 2.9rem;
        border-width: 0 0 0.1rem 0;
        border-bottom: 0.1rem solid $gray-300;

        &::placeholder {
          color: $gray-500;
          opacity: 1;
        }

        &::-webkit-input-placeholder {
          color: $gray-500;
        }

        &:-ms-input-placeholder {
          color: $gray-500;
        }

        &:-moz-placeholder {
          color: $gray-500;
        }
      }

      input[type='text'] {
        margin-right: 3.4rem;
      }

      button {
        // width: calc(100% - 91%);
        width: 14.6rem;
        height: 10rem;
        font-size: 2.7rem;
        color: $white;
        background: $gray-500;
        border-radius: 0.4rem;
        box-sizing: border-box;

        &.active {
          background: $warning;
          color: $dark;
        }
      }
    }
  }

  .step-component {
    & > div.hidden {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .modal-wrap {
    &.nonmember {
      h3 {
        margin: 0 0 11rem;
        font-size: 4.6rem;

        &::before {
          width: 5.4rem;
          height: 5.4rem;
          margin: 0 0 4.3rem;
        }
      }

      .order-info {
        .title {
          font-size: 2.7rem;
        }

        .product {
          padding: 3.9rem;
          font-size: 2.8rem;
        }

        .class-info {
          span {
            font-size: 2.6rem;
          }
        }

        & + .order-notice {
          min-height: 22rem;
          p {
            margin: 4rem 0 0;
            font-size: 2.4rem;
          }
        }
      }

      .notice-col {
        margin-bottom: 4rem;
        padding: 4.2rem 3.9rem;

        h4 {
          font-size: 2.8rem;
          margin: 0 0 3rem;
        }

        ul {
          li {
            padding: 0 0 0 2rem;
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .member-drop-col {
    .step-02 {
      .content {
        ul {
          margin: 0 9.47vw;
        }
        li {
          font-size: 3.2vw;
          line-height: 4.53vw;
        }
      }
    }
  }
}
