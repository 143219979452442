.faqContentsCategory {
  margin-bottom: 38px;
}

@media screen and (max-width: 1024px) {
  .faqContentsCategory {
    margin-bottom: 68px;
  }
}
@media screen and (max-width: 750px) {
  .faqContentsCategory {
    margin-bottom: 9.067vw;
  }
}
