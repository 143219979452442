$image-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/';

.scrollLock {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* chat header */
.chatHeader {
  position: relative;
  background-color: #fff;
  &::after {
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    content: '';
  }
  hgroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 750px;
    height: 84px;
    margin: 0 auto;
    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--GrayScale-900---000000, #000);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.18px;
      &::before {
        display: block;
        width: 34px;
        height: 34px;
        margin: 0 12px 0 0;
        background-image: url('#{$image-path}ico_chat.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        content: '';
      }
    }
    .hdBtns {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .btnClose {
      color: #8d8da0;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
    .btnTranslate {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
    .btnBlockbar {
      display: block;
      width: 1px;
      height: 12px;
      background-color: #cfcfda;
      margin: 0 16px;
    }
  }
  @media screen and (max-width: 1024px) {
    hgroup {
      height: 104px;
      padding: 0 40px;
      h3 {
        color: var(--GrayScale-900---000000, #000);
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.28px;
        padding-top: 10px;
        &::before {
          width: 44px;
          height: 44px;
          margin: -5px 12px 0 0;
        }
      }
      .btnClose {
        font-size: 25px;
        line-height: 38px;
      }
      .btnTranslate {
        font-size: 25px;
        line-height: 38px;
      }
      .btnBlockbar {
        width: 2px;
        height: 18px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    hgroup {
      height: 13.867vw;
      padding: 0 5.333vw;
      h3 {
        color: var(--GrayScale-900---000000, #000);
        font-size: 3.733vw;
        font-weight: 500;
        line-height: 4.267vw;
        letter-spacing: -0.037vw;
        padding-top: 0.667vw;
        &::before {
          width: 5.867vw;
          height: 5.867vw;
          margin: -0.667vw 1.6vw 0 0;
        }
      }
      .btnClose {
        font-size: 3.333vw;
        line-height: 5.067vw;
      }
      .btnTranslate {
        font-size: 3.333vw;
        line-height: 5.067vw;
      }
      .btnBlockbar {
        width: 0.267vw;
        height: 2.4vw;
      }
    }
  }
}

// chat mobile header
.faqWebTitle {
  width: 100%;

  .webTitleInner {
    height: 86px;
    max-width: 564px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
  }

  h3 {
    // display: flex;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.18px;
    display: none;

    &::before {
      display: block;
      width: 34px;
      height: 34px;
      background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_chat.svg');
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
    }
  }

  .titleBtns {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;

    span {
      width: 1px;
      height: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-indent: -1000px;
      background-color: #cfcfda;
    }
    button {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      color: #000;
      &.btnClose {
        color: #8d8da0;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    border-bottom: 1px solid #ececf6;
    .webTitleInner {
      justify-content: space-between;
    }
    h3 {
      display: flex;
    }
  }

  @media screen and (max-width: 750px) {
    .webTitleInner {
      max-width: 100%;
      height: 14.4vw;
      padding: 0 5.333vw;

      box-sizing: border-box;
    }

    h3 {
      gap: 1.6vw;
      font-size: 3.733vw;
      line-height: 4.267vw;
      letter-spacing: -0.037vw;

      span {
        display: block;
        padding-top: 0.533vw;
      }

      &::before {
        width: 5.867vw;
        height: 5.867vw;
      }
    }

    .titleBtns {
      gap: 3.067vw;
      span {
        width: 0.267vw;
        height: 2.4vw;
      }
      button {
        font-size: 3.333vw;
        line-height: 5.067vw;
      }
    }
  }
}
/* chat footer */
.chatFooter {
  // position: fixed;
  // left: 0;
  // bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;

  .footerInner {
    max-width: 564px;
    margin: 0 auto;
  }

  @media screen and (max-width: 750px) {
    .footerInner {
      max-width: 100%;
    }
  }
}

/* chat form */
.chatForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.333vw;
  padding: 1.333vw 3.2vw 2.667vw 5.333vw;

  .chatInput {
    flex: 1;
    box-sizing: border-box;
    height: 10.667vw;
    border-radius: 6.667vw;
    border: 1px solid #e7e7f0;
    background: #f7f7fc;
    padding: 0 5.333vw;

    font-size: 3.733vw;
    font-weight: 400;
    line-height: 4.533vw;
    color: #000;

    &::placeholder {
      color: #8d8da0;
    }
  }

  .btnSubmit {
    width: 8.8vw;
    height: 8.8vw;

    svg {
      width: 100%;
      height: 100%;
    }
    .btnDisable {
      transition: all 0.3s;
      fill: #dfdfea;
    }
    .btnActive {
      transition: all 0.3s;
      fill: #28284b;
    }
  }

  @media screen and (min-width: 751px) {
    gap: 22px;
    padding: 6px 0 36px;

    .chatInput {
      padding: 0 24px;
      height: 52px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }

    .btnSubmit {
      width: 50px;
      height: 50px;
    }
  }

  // @media screen and (max-width: 1024px) {
  //   .chatInput {
  //     width: 610px;
  //     height: 80px;
  //     padding-left: 20px;
  //     border-radius: 50px;
  //     font-size: 28px;
  //     font-weight: 400;
  //     line-height: 38px;
  //   }
  //   .btnSubmit {
  //     width: 66px;
  //     height: 66px;
  //     margin-left: 10px;
  //   }
  // }

  // @media screen and (max-width: 750px) {
  //   .chatInput {
  //     width: 81.333vw;
  //     height: 10.667vw;
  //     padding-left: 2.667vw;
  //     border-radius: 6.667vw;
  //     font-size: 3.733vw;
  //     line-height: 5.067vw;
  //   }
  //   .btnSubmit {
  //     width: 8.8vw;
  //     height: 8.8vw;
  //     margin-left: 1.333vw;
  //   }
  // }
}

/* feed list */
.feedList {
  padding: 0 0 60px 0;
}
.feedList li.user {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.feedItem {
  margin-bottom: 14px;
}
.feedItem:last-child {
  margin-bottom: 0;
}
.feedItem.user {
  border: 0;
}

/* message */
.aiMessage {
  display: grid;
  grid-template-columns: 38px 1fr;
  gap: 16px;
}
.aiMessage dl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.aiMessage a.link {
  color: blue;
  text-decoration: underline;
}
.msgName {
  color: #a0a0b6;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.message {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  max-width: 358px;
  padding: 18px 16px;
  background: #ececf6;
  border-radius: 24px;
  box-sizing: border-box;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000;

  white-space: wrap;
  word-break: break-word;
  word-wrap: break-word;
  min-width: auto;
}

.aiMessage .message::before {
  display: block;
  width: 14px;
  height: 27px;
  position: absolute;
  left: -6px;
  top: 7px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ai_msg_tail.svg') center
    no-repeat;
  background-size: 14px 27px;
  content: '';
}
.lottieBox {
  width: 32px;
  height: 18px;
}
.lottieBox > div {
  width: 100%;
  height: 100%;
}
.lottieBox svg {
  vertical-align: top;
}
.userMessage {
  border-radius: 32px;
  background: var(--Primary-Starlight-Yellow---FFB900, #ffb900);
}
.userMessage::after {
  display: block;
  width: 14px;
  height: 27px;
  position: absolute;
  right: -6px;
  top: 7px;
  background: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/user_msg_tail.svg')
    center no-repeat;
  background-size: 14px 27px;
  content: '';
}

.translateText {
  display: inline-flex;
  max-width: 60%;
  padding: 14px 22px;
  border: 1px solid #dfdfea;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #28284b;
  margin-top: 10px;
  margin-left: 6px;
  background-color: #fff;
  transition: all 0.3s;
}

.btnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.srrLoading {
  .aiMessage {
    .message {
      margin: 11px 0 0 6px;
      flex-direction: row;
      align-items: center;
      animation: fadeInOutHold 2.4s ease-in-out infinite;
      &::before {
        width: 20px;
        height: 20px;
        background: #ececf6;
        border-radius: 10px;
        left: -2px;
        top: -2px;
      }
      &::after {
        display: block;
        width: 8px;
        height: 8px;
        background: #ececf6;
        border-radius: 4px;
        position: absolute;
        left: -10px;
        top: -5px;
        content: '';
      }
    }
  }

  .lottieBox {
    width: 33px;
    height: 33px;
  }
}

// @media screen and (max-width: 1024px) {
//   .feedList {
//     padding: 0 0 58px;
//   }
//   .aiMessage {
//     grid-template-columns: 50px 1fr;
//     gap: 14px;
//   }
//   .msgName {
//     color: #a0a0b6;
//     font-size: 17px;
//     font-weight: 500;
//     line-height: 22px;
//   }
//   .message {
//     color: #000;
//     font-size: 19px;
//     font-weight: 500;
//     line-height: 24px;
//     margin: 9px 0 0 0;
//     padding: 26px;
//     border-radius: 32px;
//     max-width: 482px;
//     box-sizing: border-box;
//     letter-spacing: -0.5px;
//   }
//   .lottieBox {
//     width: 42px;
//     height: 24px;
//   }
//   .translateText {
//     padding: 24px 26px;
//     border-radius: 20px;
//     font-size: 22px;
//     line-height: 34px;
//     margin-left: 0;
//   }
// }
@media screen and (max-width: 750px) {
  .feedList {
    padding: 0 0 13.333vw;
  }
  .aiMessage {
    grid-template-columns: 7.467vw 1fr;
    gap: 2.667vw;
  }
  .msgName {
    font-size: 2.667vw;
    line-height: 3.467vw;
  }
  .message {
    max-width: 63.733vw;
    padding: 2.667vw;
    border-radius: 4.267vw;

    font-size: 3.2vw;
    line-height: 4vw;
  }
  .aiMessage .message::before {
    width: 1.867vw;
    height: 3.6vw;
    left: -0.8vw;
    top: 0.933vw;
    background-size: 1.867vw 3.6vw;
  }
  .userMessage::after {
    width: 1.867vw;
    height: 3.6vw;
    right: -0.8vw;
    top: 0.933vw;
    background-size: 1.867vw 3.6vw;
  }
  .lottieBox {
    width: 5.6vw;
    height: 3.2vw;
  }
  .translateText {
    padding: 3.2vw 3.467vw;
    border-radius: 2.667vw;
    font-size: 2.933vw;
    line-height: 4.533vw;
    margin-top: 1.333vw;
  }
}

/* Chat */
.chatLayout {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;

  &.webview {
    top: 52px;
  }

  .chatContainer {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .contentInner {
      margin: 0 auto;
      padding: 0 2.933vw;
      box-sizing: border-box;
    }
    .feedback {
      overflow: hidden;
      border-radius: 5px;
      background-color: #f7f7fc;

      .feedbackContent {
        padding: 18px 16px;

        ol {
          padding-left: 20px;
          > li {
            list-style: decimal;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        ul {
          padding-left: 20px;
          > li {
            list-style: disc;
            color: #717188;
            font-family: 'Noto Sans CJK KR';
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        p {
          color: #000;
          font-family: 'Noto Sans CJK KR';
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        ol,
        ul,
        p {
          margin-bottom: 20px;
        }
      }

      h6 {
        display: grid;
        place-items: center;
        height: 40px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        background-color: #17c624;
      }
    }
    .btnFeedback {
      display: grid;
      place-items: center;
      width: 100px;
      height: 26px;
      border-radius: 50px;
      background-color: #cfcfda;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      line-height: normal;
      position: fixed;
      left: 50%;
      bottom: 130px;
      transform: translateX(-50%);
      &.active {
        background: #17c624;
      }
    }
  }
  @media screen and (min-width: 751px) {
    &.webview {
      top: 75px;
    }
    .chatContainer {
      .contentInner {
        max-width: 564px;
        padding: 0;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    top: 108px;
  }
}

@keyframes fadeInOutHold {
  0% {
    opacity: 1;
  }
  16.67% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  66.67% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
