@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';

em {
  font-style: normal;
}
textarea::placeholder {
  color: $gray-600;
  opacity: 1;
}
textarea::-webkit-input-placeholder {
  color: $gray-600;
}
textarea:-ms-input-placeholder {
  color: $gray-600;
}
textarea:-moz-input-placeholder {
  color: $gray-600;
}
.btn {
  span {
    position: relative;
  }
}

.user-thumb-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: auto;
  height: auto;

  dt {
    width: 5.4rem;
    height: 5.4rem;
    margin-right: 1.5rem;
    background-color: $gray-400;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    border-radius: 50%;
  }

  dd {
    p {
      + p {
        margin-top: 0.5rem;
      }
    }
    strong {
      font-weight: 700;
      font-size: 1.7rem;
    }
    span {
      font-size: 1.3rem;
      color: $gray-700;

      + span {
        margin-left: 1rem;
      }
    }
  }
}

.share-item {
  li {
    display: inline-block;
    vertical-align: middle;

    + li {
      margin-left: 0.6rem;
    }

    a,
    button {
      display: block;
      width: 3rem;
      height: 3rem;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: 100%;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: -ms-optimize-contrast;
    }

    &.instagram {
      a {
        background-image: url('#{$img-cdn}/ico_instagram.png');
      }
    }

    &.kakaotalk {
      button {
        background-image: url('#{$img-cdn}/ico_kakao.png');
      }
    }

    &.facebook {
      button {
        background-image: url('#{$img-cdn}/ico_facebook.png');
      }
    }

    &.kakaostory {
      button {
        background-image: url('#{$img-cdn}/ico_kakaostory.png');
      }
    }

    &.band {
      button {
        background-image: url('#{$img-cdn}/ico_band.png');
      }
    }

    &.url {
      button {
        background-image: url('#{$img-cdn}/ico_url.png');
      }
    }
  }
}

.list-title-col {
  padding-top: 8rem;

  .title-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 3rem;
    padding-bottom: 2.5rem;
    border-bottom: 0.3rem solid $dark;
  }

  p {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    font-weight: 700;
    font-size: 3rem;
  }

  ul {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    font-weight: 700;
    font-size: 1.6rem;

    li {
      display: inline-block;
      position: relative;

      + li {
        margin-left: 3.1rem;
      }

      &::after {
        content: '';
        position: absolute;
        right: -1.5rem;
        bottom: 0.5rem;
        width: 1px;
        height: 1rem;
        margin: auto;
        background-color: $gray-400;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      a {
        display: block;
        padding-top: 1.6rem;
        color: $gray-700;
      }

      &.active {
        a {
          font-weight: 700;
          color: $dark;
        }
      }
    }
  }
}

.list-box {
  .tab-content {
    display: none;

    &.active {
      display: block;
    }

    li {
      display: inline-block;
      width: 48.5%;
      margin-bottom: 3.8rem;
      vertical-align: top;

      &:nth-of-type(odd) {
        margin-right: 2.9%;
      }

      a {
        display: block;
      }

      .thumb-box {
        position: relative;
        height: auto;
        padding-bottom: 48%;
        overflow: hidden;

        .thumb {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
        }

        em {
          position: absolute;
          width: 7.8rem;
          padding: 0.6rem 0;
          background-color: $danger;
          font-weight: 400;
          font-size: 2rem;
          color: $white;
          text-align: center;

          &::before {
            content: 'D-';
            display: inline-block;
          }
        }
      }

      .list-desc {
        margin-top: 2rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.4;
        color: $gray-700;

        p {
          &:first-of-type {
            font-weight: 700;
          }
          &:nth-of-type(2) {
            margin: 1rem 0 0.7rem;
          }

          strong {
            display: -webkit-box;
            // height: 5rem;
            font-size: 1.9rem;
            color: $dark;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            white-space: normal;
            overflow: hidden;
          }
        }

        .list-announcement {
          display: none;
        }
      }
    }

    &.end-event {
      li {
        .thumb-box {
          .thumb {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.6);
            }
          }

          em {
            display: none;
          }
        }

        .button-box {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;

          a {
            -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            width: 15.6rem;
            padding: 1.3rem 0;
            font-weight: 400;
            font-size: 2rem;
            border: none;
            border-radius: 0.3rem;

            &.btn-detail {
              background-color: $white;
            }

            &.btn-winner {
              background-color: $warning;
            }

            + a {
              margin-left: 0.9rem;
            }
          }
        }

        .list-desc {
          .list-announcement {
            display: block;

            &::before {
              content: '발표';
              display: inline-block;
              margin-right: 0.7rem;
              color: $gray-900;
            }
          }

          .list-period {
            &::before {
              content: '기간';
              display: inline-block;
              margin-right: 0.7rem;
              color: $gray-900;
            }
          }
        }
      }
    }

    .no-content {
      padding: 14rem 0 20rem;
      text-align: center;

      p {
        font-weight: 700;
        font-size: 2.2rem;
      }
    }
  }
}

.detail-title-col {
  .list-detail-title {
    font-weight: 700;
    font-size: 2.4rem;
    border-bottom: 1px solid $gray-400;

    .title-share-area {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: 1rem;

      p {
        flex: 0 1 75%;
        height: 6.6rem;
        line-height: 1.4;
      }
    }
  }

  .share-item {
    flex: 0 1 auto;
  }

  .detail-info-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 2.7rem;
    font-size: 1.4rem;
    color: #9b9b9b;

    .dday {
      flex: 0 0 auto;
      margin-right: 2rem;

      em {
        display: inline-block;
        width: 7.8rem;
        padding: 0.6rem 0;
        font-weight: 400;
        font-size: 2rem;
        background-color: #ff5647;
        color: $white;
        text-align: center;

        &::before {
          content: 'D-';
          display: inline-block;
        }
      }
    }

    .period {
      flex: 1;
      font-weight: 400;
    }

    .announcement-date {
      flex: 0 0 auto;
      font-weight: 400;

      &::before {
        content: '당첨자 발표';
        display: inline-block;
        margin-right: 0.7rem;
        color: $dark;
      }
    }

    .btn-share {
      display: none;
    }
  }

  .btn-go-event {
    position: absolute;
    top: 3rem;
    right: 3rem;
    width: 17.6rem;
    padding: 1.1rem 0;
    font-weight: 400;
    font-size: 2rem;
    color: $dark;
    border: 1px solid $gray-500;
    border-radius: 0.4rem;
    text-align: center;
  }
}

.event-participate-col {
  .event-area {
    margin-top: 5rem;
    padding-bottom: 11rem;

    div {
      margin-top: 3rem;
      font-size: 1.8rem;
      font-family: 'Inter', 'NotoSansCJKkr', sans-serif;
      word-break: keep-all;

      p {
        position: relative;
        line-height: 1.7;
      }

      img {
        display: block;
        width: 780px !important;
        height: auto !important;
        margin: 0 auto;
      }

      a {
        display: block;
        width: 780px;
        margin: 0 auto;
      }
    }
  }

  .participate-area {
    position: relative;

    p {
      flex: 1;
      font-weight: 700;
      font-size: 3rem;

      span {
        &::before {
          content: '(';
          display: inline-block;
        }

        &::after {
          content: ')';
          display: inline-block;
        }
      }
    }

    .btn-register {
      position: absolute;
      top: 0;
      right: 0;
      width: 12.6rem;
      padding: 1.2rem 0;
      font-weight: 400;
      font-size: 2rem;
      color: $white;
      background-color: $gray-900;
      border: none;
      border-radius: 0.3rem;
    }

    .reply-box {
      flex: 0 0 auto;
      width: 100%;
      margin-top: 2rem;
      border: 0.2rem solid $gray-300;

      textarea {
        width: 100%;
        height: 22.7rem;
        padding: 2.2rem;
        font-size: 1.6rem;
        border: none;
        box-sizing: border-box;
        resize: none;
      }
    }
  }
}

.participate-list {
  ul {
    margin-top: 3rem;

    li {
      width: 100%;
      padding: 4rem 1.5rem;
      border-top: 1px solid $gray-300;
      box-sizing: border-box;

      em {
        display: block;
        margin-top: 2.2rem;
        font-size: 1.6rem;
        line-height: 1.6;
      }
    }
  }

  &.line {
    ul {
      border-bottom: 1px solid $gray-300;
    }
  }
}

.participate-type {
  &.board {
    .reply-box {
      display: none;
    }

    .participate-list {
      .review-list-area {
        min-height: 20rem;
        max-height: 20rem;
        margin-bottom: 3.5rem;

        .review-img-box {
          display: block;
          width: 100%;
          height: 20rem;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          text-align: center;
          overflow: hidden;
        }
      }
      ul {
        li {
          display: inline-block;
          width: 31.5%;
          min-height: 48.5rem;
          margin-right: 2.7%;
          margin-bottom: 4rem;
          padding: 0 0 2rem;
          border: 1px solid $gray-300;
          vertical-align: top;

          &:nth-of-type(3n) {
            margin-right: 0;
          }

          em {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-clamp: 5;
            white-space: normal;
            text-overflow: ellipsis;
            height: 12.5rem;
            margin: 0;
            padding: 0 2rem;
            overflow: hidden;
          }

          .swiper-review {
            padding-bottom: 3rem;

            // img {
            //     width: 100%;
            // }

            .swiper-pagination-bullet {
              width: 0.5rem;
              height: 0.5rem;
              bottom: 2.2rem;
              margin: 0 0.2rem;
            }

            .swiper-pagination-bullet-active {
              background-color: $dark;
            }
          }
        }
      }

      dl {
        padding: 2.1rem;

        dd {
          strong {
            font-size: 1.6rem;
          }

          span {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.winner-result-area {
  .btn-my-result {
    display: block;
    width: 27rem;
    margin: auto;
    padding: 2.2rem 0;
    font-weight: 400;
    font-size: 2rem;
    background-color: $warning;
    border: none;
    border-radius: 0.5rem;
  }

  .winner-list {
    margin-top: 10rem;
    font-size: 1.6rem;
    border-top: 0.3rem solid $dark;

    .btn-all-result {
      position: relative;
      width: 100%;
      padding: 2.7rem 2.2rem 2.3rem;
      font-weight: 700;
      font-size: 2.2rem;
      border-top: none;
      border-right: none;
      border-bottom: 1px solid $gray-300;
      border-left: none;
      text-align: left;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 2.2rem;
        bottom: 0;
        width: 1.1rem;
        height: 1.1rem;
        margin: auto;
        background: url('#{$img-cdn}/ico_arrow_63x63_b.png') no-repeat 0 50% / 100%;
      }

      &.active {
        background-color: $white;
        border: none;

        &::after {
          top: 0.5rem;
          transform: rotate(180deg);
        }
      }
    }

    div {
      display: none;
      width: 100%;
      padding: 4.1rem 2.5rem;
      background-color: $gray-100;
      border-bottom: 1px solid $gray-300;
      box-sizing: border-box;

      &.active {
        display: block;
      }
    }

    em {
      color: $gray-700;
    }

    ul {
      margin-top: 1rem;
      li {
        display: inline-block;
        width: 10%;
        margin-top: 3rem;
        text-align: left;

        span {
          &::before {
            content: '/';
            display: inline-block;
          }
        }
      }
    }
  }
}

.list-btn-col {
  padding: 5rem 0 12rem;

  .btn-go-list {
    display: block;
    width: 27rem;
    margin: auto;
    padding: 2.1rem 0;
    font-weight: 400;
    font-size: 2rem;
    border: 1px solid $gray-500;
    border-radius: 0.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .list-title-col {
    padding-top: 0;

    .title-area {
      margin: 0;
      padding: 0;
      border-color: $gray-400;
    }

    p {
      display: none;
    }

    ul {
      padding: 0 4.2rem;
      font-size: 3rem;

      li {
        + li {
          margin-left: 5.4rem;
        }

        &::after {
          display: none;
        }

        a {
          padding: 3.6rem 0;
        }

        &.active {
          a {
            border-bottom: 0.5rem solid $warning;
          }
        }
      }
    }
  }

  .list-box {
    padding-bottom: 15rem;

    .tab-content {
      li {
        width: 100%;
        margin-bottom: 7rem;

        &:nth-of-type(odd) {
          margin-right: 0;
        }

        .thumb-box {
          .thumb {
            background-position: 50% 0;
            background-size: 100%;
          }

          em {
            width: 9.8rem;
            padding: 0.7rem 0;
            font-size: 2.5rem;
          }

          img {
            width: 100%;
          }
        }

        .list-desc {
          margin-top: 3.6rem;
          padding: 0 4.2rem;
          font-size: 2.6rem;

          p {
            strong {
              height: auto;
              font-size: 3.2rem;
            }
          }
        }
      }
      &.end-event {
        li {
          .button-box {
            a {
              width: 19rem;
              padding: 1.6rem 0;
              font-size: 2.6rem;
            }
          }
        }
      }

      .no-content {
        padding: 30rem 0;

        span {
          margin-bottom: 4.5rem;
        }

        p {
          font-size: 3.5rem;
        }
      }
    }
  }

  .detail-title-col {
    .list-detail-title {
      padding: 4rem 4.2rem 0;

      .title-share-area {
        font-size: 3.2rem;

        p {
          flex: 0 1 auto;
          height: 9rem;
          line-height: 1.5;
        }
      }
    }

    .share-item {
      display: none;
    }

    .detail-info-area {
      margin-top: 1.7rem;
      padding-bottom: 4rem;
      font-size: 2.5rem;

      .announcement-date {
        display: none;
      }

      .btn-share {
        display: block;
        flex: 0 0 auto;
        width: 5.4rem;
        height: 5.4rem;
        background: url('#{$img-cdn}/ico_share.png') no-repeat 50% 0 / 100%;
        border: none;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -ms-optimize-contrast;

        span {
          display: block;
          text-indent: -999rem;
        }
      }

      .dday {
        em {
          width: 9.8rem;
          padding: 0.8rem 0;
          font-size: 2.5rem;
        }
      }
    }

    &.end {
      .btn-share {
        display: none;
      }

      .btn-go-event {
        width: 23rem;
        padding: 1.3rem 0;
        font-size: 2.8rem;
      }

      .title-share-area {
        margin-bottom: 0;
      }

      .detail-info-area {
        margin-top: 2rem;
      }
    }

    &.winner {
      .title-share-area {
        margin-bottom: 0;
      }
    }

    .btn-go-event {
      top: 11rem;
    }
  }

  .event-participate-col {
    .event-area {
      margin-top: 3rem;
      padding: 0 4.2rem 6rem;

      div {
        font-size: 2.5rem;

        img,
        a {
          width: 100% !important;
        }
      }
    }

    .participate-type {
      padding: 0 4.2rem;
    }

    .participate-area {
      p {
        font-size: 3.2rem;

        span {
          display: none;
        }
      }

      .btn-register {
        width: 16.2rem;
        top: -2rem;
        padding: 1.6rem 0;
        font-size: 2.5rem;
      }

      .reply-box {
        border-top: 0.3rem solid $dark;

        textarea {
          height: 26.2rem;
          font-size: 2.7rem;
        }
      }
    }
  }

  .participate-list {
    ul {
      li {
        em {
          font-size: 2.6rem;
        }
      }
    }
  }

  .list-btn-col {
    .inner-col {
      background-color: inherit;
    }

    .btn-go-list {
      width: 31.8rem;
      padding: 2.3rem 0;
      font-size: 2.6rem;
      background-color: #f7f7fc;
    }
  }

  .participate-type {
    &.board {
      .participate-list {
        .review-list-area {
          position: relative;
          max-height: inherit;
          min-height: inherit;
          .review-img-box {
            // min-height: 22rem;
            height: 30em;
          }
        }
        ul {
          li {
            width: 100%;
            .swiper-review {
              position: static;
              padding-bottom: 7rem;

              .swiper-pagination-bullet {
                width: 0.8rem;
                height: 0.8rem;
                margin: 0 0.7rem;
              }
            }

            em {
              height: 20rem;
            }
          }
        }

        dl {
          dt {
            width: 8rem;
            height: 8rem;
            margin-right: 2rem;
          }

          dd {
            strong {
              font-size: 2.5rem;
            }

            span {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }

  .winner-result-area {
    padding: 0 4.2rem;

    .btn-my-result {
      width: 31.8rem;
      padding: 2.4rem 0;
      font-size: 2.6rem;
    }

    .winner-list {
      .btn-all-result {
        padding: 2.6rem 2.5rem 2.6rem;
        font-size: 2.4rem;
        background-color: $white;

        &::after {
          width: 1.8rem;
          height: 1.8rem;
          right: 2.5rem;
        }

        &.active {
          border: none;
        }
      }

      em {
        font-size: 2.2rem;
      }

      ul {
        font-size: 2.5rem;

        li {
          width: 33%;
        }
      }
    }
  }
}
