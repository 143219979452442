.couponDownloadBox {
  display: flex;
  padding: 2rem 2rem 1rem;

  form {
    display: flex;
    width: 100%;
  }

  input {
    flex: 1 auto;
    height: 5rem;
    padding: 0 2rem;
    background-color: #fff;
    border: 0.1rem solid #dfdfea;
    border-radius: 0.3rem;
    box-sizing: border-box;
  }

  button {
    width: 11.3rem;
    height: 5rem;
    margin-left: 1.2rem;
    padding: 0;
    font-size: 1.4rem;
    color: #fff;
    background-color: #cfcfda;
    border-radius: 0.3rem;
    transition: 0.3s;
    outline: 0;
    border: 0;

    &.active {
      background-color: #28284b;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 3rem 3.1rem 1rem;

    input {
      min-width: 2rem;
      height: 10rem;
      padding: 0 5.2rem;
      font-size: 2.7rem;
      border-radius: 0.5rem;
    }

    button {
      width: 21rem;
      height: 10rem;
      margin-left: 1.8rem;
      font-size: 2.7rem;
      border-radius: 0.5rem;
    }
  }
}
