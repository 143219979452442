@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}

.modal-wrap {
  &.modal-form {
    .modal-content {
      max-height: 72rem;
      overflow-x: hidden;
      overflow-y: auto;

      .inner {
        position: relative;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }

      &.type-fixed-button {
        background-color: $white;

        .btn-flex-form {
          padding-bottom: 0;
        }
      }
    }

    .certified-code-form {
      margin-top: 1.8rem;

      .btn {
        flex: 0 1 auto;
        width: 8rem;
        margin-left: 1.2rem;
        padding: 1.4rem 0;
        font-size: 1.6rem;
        white-space: nowrap;
      }

      + .certified-code-form {
        margin-top: 3.5rem;
      }

      &.disabled {
        input[type='text'] {
          background-color: $gray-100;
        }
      }
    }

    .email-box {
      .disabled {
        input[type='text'] {
          background-color: $gray-100;
        }
      }
    }

    .btn-block {
      height: 6.4rem;
      margin-bottom: 1.1rem;
      font-size: 1.9rem;

      &.btn-complete {
        padding-left: 1.5rem;
        color: #000;
        background-color: #fff;
        border: 0.1rem solid #e7e7f0;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: 4rem;
          height: 4rem;
          margin: auto 0 auto -4rem;
          background: url('#{$img-cdn}/ico_check_99x81_green.png') no-repeat 50% 50% / 1.7rem auto;
        }
      }

      + em {
        font-weight: 400;
        font-size: 1.5rem;
      }
    }

    dl {
      margin-top: 4rem;
      font-size: 1.6rem;
      box-sizing: border-box;

      input[type='text'],
      select {
        font-size: 1.6rem;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
      }

      input[type='text'] {
        width: 100%;
        margin-top: 0;
        padding: 1.6rem 1.6rem 1.4rem;
        caret-color: $dark-warning;

        &.warning {
          border-bottom: 1px solid $danger;
          &:focus {
            border-bottom: 1px solid $danger;
          }
        }

        &.success {
          border-bottom: 1px solid $info;
          &:focus {
            border-bottom: 1px solid $gray-300;
          }
        }

        &:focus {
          border-bottom: 1px solid $dark;
        }
      }

      select {
        height: 6.2rem;
        width: 100%;
        padding: 1.75rem 1.5rem;
        border: none;
      }

      .mark {
        &::before {
          left: 2.4rem;
        }
      }

      label {
        display: inline-block;
        margin-top: 2.6rem;
        font-size: 1.6rem;
        color: $gray-800;
        cursor: pointer;

        input[type='checkbox'] {
          & + .chk {
            width: 2.7rem;
            height: 2.7rem;
            margin-right: 1.3rem;
          }
        }

        &.lg-chk {
          input[type='checkbox'] {
            & + .chk {
              width: 3.2rem;
              height: 3.2rem;

              &:before {
                width: 1.4rem;
                height: 1.1rem;
              }
            }
          }
        }

        input[type='radio'] {
          & + .chk {
            width: 2.7rem;
            height: 2.7rem;
            margin-right: 1.3rem;
          }
        }

        p {
          display: inline-block;
          line-height: 1.5;
        }
      }

      dt {
        display: block;
        width: 100%;
        padding: 0 3.3rem;
        font-weight: 400;
        box-sizing: border-box;
      }

      dd {
        display: inline-block;
        position: relative;
        width: 100%;
        padding: 1rem 3.2rem 0;
        vertical-align: middle;
        box-sizing: border-box;
      }

      &.line {
        margin-top: 5rem;
        padding-top: 4rem;
        border-top: 0.8rem solid $gray-300;
      }

      &.agree-flex-form {
        dt {
          line-height: 1.5;
          color: #717188;
        }

        .checkbox-flex-form {
          display: inline-flex;

          + .checkbox-flex-form {
            margin-left: 3rem;
          }
        }
      }
    }

    span {
      &.valid-text {
        display: none;
        position: absolute;
        bottom: -2.5rem;
        right: 3.4rem;
        font-size: 1.1rem;

        &.active {
          display: block;

          &.warning {
            color: $danger;
          }

          &.success {
            color: $info;
          }
        }
      }
    }

    .checkbox-flex-form {
      display: flex;
      align-items: center;

      input[type='checkbox'] {
        + span {
          flex: 0 0 auto;
        }
      }

      p {
        flex: 0 1 auto;
        padding: 0;
      }
    }

    .btn-flex-form {
      flex-wrap: wrap;
      padding: 0 3.2rem 2rem;

      button {
        flex: 1 1 auto;
        width: 100%;
        font-weight: 400;

        + button {
          margin-top: 2.8rem;
          font-size: 1.7rem;
          text-decoration: underline;
        }
      }

      &.btn-group-bottom {
        button:last-child {
          margin-bottom: 2.8rem;
          margin-left: 0;
        }
      }

      .btn-edit-submit {
        display: block;
        margin: 0 auto;
        padding: 2rem 0;
        font-weight: 400;
        font-size: 2rem;
        border: none;
        border-radius: 0.3rem;

        &.active {
          background-color: $warning;
        }
      }

      &.group {
        margin-top: 4.8rem;
      }
    }

    .title {
      padding: 5rem 3.2rem 3.8rem;
      background-color: $gray-100;

      p {
        font-size: 1.6rem;
        line-height: 1.6;
        color: $gray-800;

        strong {
          display: block;
          margin-bottom: 0.8rem;
          font-weight: 700;
          font-size: 2.7rem;
        }
      }
    }

    .form-box {
      .phone-box {
        input[type='text'] {
          flex: 1 0 auto;
          width: 0;
        }

        .get-country {
          border: 1px solid $gray-300;
        }
      }

      .email-box {
        div {
          position: relative;
        }
        .valid-text {
          bottom: -2.5rem;
          right: 0;
        }
      }

      .gender-box {
        label {
          width: 50%;
          margin-top: 0;
          padding: 2rem 0;
          font-size: 1.6rem;
          color: $dark;
          border: 1px solid $gray-300;
          border-right: none;
          text-align: center;
          box-sizing: border-box;

          + label {
            border-left: 1px solid $gray-300;
            border-right: 1px solid $gray-300;
          }

          input[type='radio'] {
            display: none;
          }

          &.active {
            background-color: $warning;
            border-color: $warning;

            + label {
              border-left: none;
            }
          }
        }
      }

      .birthday-box {
        display: inline-block;
        width: 100%;
        border: 1px solid $gray-300;

        select {
          width: 30%;
          padding: 2rem 0 1.6rem 1.5rem;
          background-position-x: 81%;

          + select {
            border-left: 1px solid $gray-300;
          }
        }

        .year-select {
          width: 40%;
        }
      }

      .address-box {
        input[type='text'] {
          width: 100%;
          margin-top: 1.2rem;
          padding: 1.6rem 1.5rem;
          border: 1px solid $gray-300;
          box-sizing: border-box;

          &.zone-code {
            flex: 0 1 auto;
            width: 27.8rem;
            margin-right: 1rem;
            margin-top: 0;
          }

          &.input-value {
            color: $gray-600;
          }

          &.active {
            color: $dark;
          }
        }
      }

      .join-select-box {
        .inner {
          border: 1px solid $gray-300;

          + label {
            margin-top: 2.6rem;
            color: $gray-800;
          }
        }

        label {
          margin-top: 0;
          color: $dark;
        }

        .select-title {
          padding: 1.9rem 1.5rem;
          background-color: $gray-100;
          border-bottom: 1px solid $gray-300;

          p {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              flex: 1 0 auto;

              + span {
                flex: 0 1 auto;
                font-size: 1.6rem;
                color: $gray-800;
              }
            }
          }
        }

        ul {
          padding: 1.4rem 1.5rem;

          &.join-path-box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            li {
              flex: 1 1 auto;
              width: 50%;
              margin: 1.3rem 0;
            }
          }
        }

        &.agree {
          ul {
            padding: 2.7rem 1.5rem;

            li {
              display: flex;
              justify-content: space-between;
              align-items: center;

              + li {
                margin-top: 2.6rem;
              }

              label {
                flex: 1 0 auto;

                p {
                  flex: 1 1 auto;
                }
              }

              a {
                font-size: 1.6rem;
                color: $gray-600;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .btn-password {
        width: 24rem;
        padding: 1.8rem 0;
        font-weight: 700;
        font-size: 1.4rem;
        color: $white;
        background-color: $gray-900;
        border-radius: 0.3rem;
      }

      .notice {
        display: block;
        margin-top: 0.8rem;
        font-size: 1.2rem;
        line-height: 1.4;
        color: $gray-800;

        &::before {
          content: '※';
          display: inline-block;
          margin-right: 0.3rem;
        }
      }
    }

    // &.join {
    //   .modal-header {
    //     background-color: $gray-100;
    //     border-radius: 1rem 1rem 0 0;

    //     h3 {
    //       height: 2.9rem;
    //       background: url('#{$img-cdn}/img_logo_154x42.png') no-repeat 50% 50% /
    //         9.3rem auto;
    //     }
    //   }

    //   .required-display {
    //     padding: 3.2rem 3.2rem 0;
    //     font-weight: 300;
    //     font-size: 1.6rem;
    //     text-align: right;

    //     span {
    //       &::before {
    //         content: '*';
    //         display: inline-block;
    //         color: $danger;
    //       }
    //     }
    //   }

    //   dl {
    //     &:first-child {
    //       margin-top: 2.6rem;
    //     }
    //   }

    //   .certified-code-form {
    //     .timer {
    //       right: 9.5rem;
    //     }
    //   }

    //   .dormant {
    //     .modal-header {
    //       background-color: $white;
    //     }

    //     &.step-1 {
    //       .modal-header {
    //         padding: 3.5rem;
    //         background-color: #46465f;
    //         border: none;
    //       }

    //       .btn-modal-close {
    //         &.white {
    //           top: 2rem;
    //           right: 3rem;
    //           background-size: 2.5rem;
    //         }
    //       }
    //     }

    //     &.step-2 {
    //       dl {
    //         margin-top: 4rem;

    //         + dl {
    //           margin-top: 4.8rem;
    //         }
    //       }
    //     }

    //     .title {
    //       padding: 1rem 3.2rem 5.1rem;
    //       background-color: #46465f;

    //       p {
    //         font-weight: 400;
    //         font-size: 2.9rem;
    //         line-height: 1.5;
    //         color: $gray-700;

    //         + p {
    //           margin-top: 3.4rem;
    //           font-size: 1.7rem;
    //           color: $gray-700;
    //         }
    //       }

    //       strong {
    //         font-weight: 700;
    //         color: $white;

    //         span {
    //           color: $warning;
    //         }
    //       }
    //     }

    //     dl {
    //       label {
    //         margin-top: 3rem;
    //       }
    //     }

    //     .phone-box {
    //       .phone-certified-form {
    //         margin-top: 4.8rem;
    //       }
    //     }

    //     .btn-dormant {
    //       display: block;
    //       width: 87%;
    //       margin: 3rem auto 0;

    //       &.release {
    //         position: absolute;
    //         bottom: 1rem;
    //         right: 0;
    //         left: 0;
    //       }
    //     }
    //   }
    // }
  }

  .social {
    &.type-fixed-button {
      background-color: $white;

      .btn-flex-form {
        padding-bottom: 0;
      }
    }

    .social-join-check {
      height: 43rem;

      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3.2rem;
        border-bottom: 1px solid $gray-300;
        cursor: pointer;
      }

      input[type='radio'] {
        flex: 1 0 auto;
      }

      p {
        flex: 0 1 auto;
        font-size: 1.6rem;
        line-height: 1.5;
        color: $gray-700;

        strong {
          display: block;
          font-weight: 700;
          font-size: 1.9rem;
          color: $dark;
        }
      }
    }

    input[type='radio'] {
      & + .chk {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }

  &.join {
    .modal-header {
      background-color: $gray-100;
      border-radius: 1rem 1rem 0 0;

      h3 {
        height: 3.1rem;
        background: url('#{$img-cdn}/img_logo_396x94.png') no-repeat 50% 50% / 13.2rem auto;
      }
    }

    .required-display {
      padding: 3.2rem 3.2rem 0;
      font-weight: 400;
      font-size: 1.6rem;
      text-align: right;

      span {
        &::before {
          content: '*';
          display: inline-block;
          color: $danger;
        }
      }
    }

    dl {
      &:first-child {
        margin-top: 2.6rem;
      }
    }

    .certified-code-form {
      .timer {
        right: 9.5rem;
      }
    }

    .dormant {
      .modal-header {
        background-color: $white;
      }

      &.step-1 {
        .modal-header {
          padding: 3.5rem;
          background-color: #28284b;
          border: none;
        }

        .btn-modal-close {
          &.white {
            top: 2rem;
            right: 3rem;
            background-size: 2.5rem;
          }
        }
      }

      &.step-2 {
        dl {
          margin-top: 4rem;

          + dl {
            margin-top: 4.8rem;
          }
        }
      }

      .title {
        padding: 1rem 3.2rem 5.1rem;
        background-color: #28284b;

        p {
          font-weight: 400;
          font-size: 2.9rem;
          line-height: 1.5;
          color: $gray-700;

          + p {
            margin-top: 3.4rem;
            font-size: 1.7rem;
            color: $gray-700;
          }
        }

        strong {
          font-weight: 700;
          color: $white;

          span {
            color: $warning;
          }
        }
      }

      dl {
        label {
          margin-top: 3rem;
        }
      }

      .phone-box {
        .phone-certified-form {
          margin-top: 4.8rem;
        }
      }

      .btn-dormant {
        display: block;
        width: 87%;
        margin: 3rem auto 0;

        &.release {
          position: absolute;
          bottom: 1rem;
          right: 0;
          left: 0;
        }
      }
    }
  }

  &.complete {
    .modal-header {
      padding: 3.5rem;
      background-color: $white;
      border: none;
    }

    .modal-center-box {
      height: 64rem;
    }

    .complete-box {
      padding: 0;
      dl {
        margin-top: 2rem;
        padding: 0 3rem;
        box-sizing: border-box;

        dt {
          font-weight: 700;
          font-size: 2.9rem;
          line-height: 1.5;
          color: $dark;
        }

        dd {
          padding: 0;
          font-size: 1.7rem;
          text-align: left;
        }
      }

      ul {
        margin-top: 5.6rem;
        border-top: 1px solid $dark;

        li {
          padding: 2.6rem 0;
          font-weight: 700;
          border-bottom: 1px solid #e7e7f0;

          span {
            display: inline-block;
            width: 8.5rem;
            margin-right: 1.6rem;
            font-weight: 400;
            vertical-align: top;
          }
        }
      }
    }

    .complete-info-box {
      position: absolute;
      right: 0;
      bottom: 2rem;
      left: 0;
      margin: auto;
      padding: 0 3rem;
      & > strong {
        display: block;
        margin-bottom: 2rem;
        font-weight: 700;
        font-size: 1.7rem;
        color: #717188;
        text-align: left;
      }
      dl {
        display: flex;
        margin-top: 1rem;
        dt {
          width: 10rem;
          padding: 0;
          font-weight: 400;
          font-size: 1.6rem;
          color: #717188;
        }
        dd {
          padding: 0;
          font-weight: 400;
          font-size: 1.6rem;
          color: #717188;
          p {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }

  .complete-box {
    dl {
      width: 100%;
      text-align: center;

      dt {
        font-size: 1.9rem;
        color: $gray-800;

        &::before {
          content: '';
          display: block;
          width: 8rem;
          height: 8rem;
          margin: 0 auto 3.2rem;
          background: url('#{$img-cdn}/ico_check_99x81_green.png') no-repeat 50% 50% / auto 2.4rem;
          border-radius: 50%;
          border: 0.5rem solid #6dd75f;
        }
      }

      dd {
        p {
          margin-top: 4rem;
          line-height: 1.5;
        }

        strong {
          display: block;
          font-weight: 700;
          font-size: 2.9rem;
          color: $dark;
        }

        span {
          font-size: 1.6rem;
          color: $gray-700;
        }
      }
    }

    + .btn-flex-form {
      margin-top: 0;
    }
  }
}

.modal-login {
  .modal-header {
    padding: 3.5rem;
    border-bottom: none;
  }

  .login-box {
    padding: 0 3.2rem;
    box-sizing: border-box;

    .btn-flex-form {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal-wrap {
    &.modal-form {
      .modal-content {
        max-height: 100%;

        &.type-fixed-button {
          .btn-flex-form {
            padding-bottom: 6rem;
          }
        }
      }

      .certified-code-form {
        margin-top: 3.2rem;

        .btn {
          width: 14.6rem;
          margin-left: 2.2rem;
          padding: 3.1rem 0;
          font-size: 2.7rem;
        }

        + .certified-code-form {
          margin-top: 5.3rem;
        }
      }

      .btn-block {
        height: 10rem;
        margin-bottom: 2.4rem;
        font-size: 2.7rem;

        &.btn-complete {
          &::before {
            width: 6rem;
            height: 6rem;
            margin: auto 0 auto -6rem;
            background-size: 2.4rem auto;
          }
        }

        + em {
          font-size: 2.3rem;
        }
      }

      dl {
        margin-top: 5.2rem;
        border-bottom: none;

        input[type='text'],
        select {
          font-size: 2.9rem;
        }

        input[type='text'] {
          // padding: 1.5rem 2.6rem 2.4rem;
          padding: 3rem 2.7rem;
        }

        select {
          height: unset;
          color: $dark;
          background-size: 2.2rem 2.2rem;
          background-position-x: 97%;
        }

        label {
          margin-top: 5.2rem;
          font-size: 2.5rem;

          input[type='checkbox'] {
            & + .chk {
              width: 4.2rem;
              height: 4.2rem;
            }
          }

          &.lg-chk {
            input[type='checkbox'] {
              & + .chk {
                width: 5rem;
                height: 5rem;

                &:before {
                  width: 2.2rem;
                  height: 1.7rem;
                }
              }
            }
          }

          input[type='radio'] {
            & + .chk {
              width: 5rem;
              height: 5rem;

              &::before {
                width: 1.7rem;
                height: 1.7rem;
              }
            }
          }

          p {
            font-size: 2.5rem;
          }
        }

        dt {
          display: block;
          width: 100%;
          padding: 0 4.2rem;
          font-size: 2.5rem;
        }

        dd {
          width: 100%;
          padding: 1.6rem 4.2rem 0;
          font-size: 2.9rem;
          box-sizing: border-box;
        }

        + dl {
          margin-top: 7rem;
        }

        &.line {
          margin-top: 8.5rem;
        }

        &.agree-flex-form {
          .checkbox-flex-form {
            margin-top: 3rem;

            + .checkbox-flex-form {
              margin-left: 4rem;
            }
          }
        }
      }

      span {
        &.valid-text {
          bottom: -4.5rem;
          right: 4.4rem;
          font-size: 2.3rem;
        }
      }

      .btn-flex-form {
        padding: 0 4.2rem 6rem;

        button {
          + button {
            margin-top: 4.2rem;
            font-size: 2.7rem;
          }
        }

        .btn-edit-submit {
          // margin-bottom: 6rem;
          padding: 3.4rem 0;
          font-size: 2.7rem;
          border-radius: 0.5rem;
        }

        &.group {
          margin-top: 5.6rem;
        }

        &.btn-group-bottom {
          button:last-child {
            margin-bottom: 0;
          }
        }
      }

      .title {
        padding: 5.1rem 4.4rem 4.8rem;

        p {
          font-size: 2.5rem;

          strong {
            font-size: 4.2rem;
          }
        }
      }

      .form-box {
        border-top: none;

        .phone-box {
          input[type='text'] {
            padding: 3rem 2.7rem;
          }

          .get-country {
            padding: 3rem 2.5rem;
          }
        }

        .email-box {
          .valid-text {
            bottom: -4.5rem;
          }
        }

        .gender-box {
          label {
            width: 50%;
            padding: 2.9rem 0;
            font-size: 2.9rem;
          }
        }

        .birthday-box {
          width: 100%;

          select {
            width: 27.5%;
            padding: 3.2rem 0 3.2rem 2.7rem;
            color: $dark;
            background-position-x: 88%;
          }

          .year-select {
            width: 45%;
          }
        }

        .address-box {
          input[type='text'] {
            margin-top: 6.1rem;
            border-width: 0;
            border-bottom-width: 1px;

            &.zone-code {
              flex: 1 0 auto;
              margin-right: 4.2rem;
            }
          }
        }

        .join-select-box {
          .select-title {
            padding: 2.9rem 2.5rem 3.1rem;

            p {
              span {
                + span {
                  font-size: 2.5rem;
                }
              }
            }
          }

          ul {
            padding: 2rem 2.5rem;

            &.join-path-box {
              li {
                margin: 2rem 0;
              }
            }
          }

          select {
            padding: 2.8rem 2.5rem 2.6rem;
            color: $dark;
          }

          &.agree {
            ul {
              padding: 4.1rem 2.5rem;

              li {
                + li {
                  margin-top: 4.2rem;
                }

                a {
                  font-size: 2.5rem;
                }
              }
            }
          }
        }

        .btn-password {
          width: 100%;
          padding: 3.1rem 0;
          font-size: 2.9rem;
        }

        .notice {
          margin-top: 3rem;
          font-size: 2.5rem;
        }
      }

      &.join {
        .modal-header {
          border-radius: 2rem 2rem 0 0;

          h3 {
            height: 3.8rem;
            background-size: 16.4rem auto;
          }
        }

        .required-display {
          padding: 4.6rem 4.2rem 0;
          font-size: 2.3rem;
        }

        .dormant {
          &.step-1 {
            .btn-modal-close {
              &.white {
                top: 3.7rem;
                right: 5rem;
                background-size: 3.2rem;
              }
            }
          }

          &.step-2 {
            dl {
              margin-top: 6.5rem;

              + dl {
                margin-top: 7.3rem;
              }
            }
          }

          .title {
            padding: 3.7rem 4.2rem 7.4rem;

            p {
              font-size: 4.6rem;

              + p {
                margin-top: 5.5rem;
                font-size: 2.7rem;
              }
            }
          }

          dl {
            label {
              margin-top: 5.3rem;
            }
          }

          .btn-dormant {
            &.release {
              bottom: 7rem;
            }
          }
        }
      }
    }

    .social {
      &.type-fixed-button {
        .btn-flex-form {
          padding: 0 4rem 6rem;
        }
      }

      .social-join-check {
        height: 100%;

        label {
          padding: 4.7rem 4.2rem;
        }

        p {
          font-size: 2.5rem;

          strong {
            font-size: 2.9rem;
          }
        }
      }

      input[type='radio'] {
        & + .chk {
          width: 5rem;
          height: 5rem;

          &:before {
            width: 1.7rem;
            height: 1.7rem;
          }
        }
      }
    }

    &.join {
      dl {
        &:first-child {
          margin-top: 4.3rem;
        }
      }

      .certified-code-form {
        .timer {
          right: 16.5rem;
        }
      }
    }

    &.complete {
      .modal-header {
        padding: 5.5rem 3.5rem;
      }

      .modal-center-box {
        height: 70vh;
      }

      .complete-box {
        dl {
          margin-top: 0;
          padding: 0 4.2rem;

          dt {
            font-size: 4.6rem;
          }

          dd {
            font-size: 2.7rem;
          }
        }

        ul {
          margin-top: 8.2rem;

          li {
            padding: 4rem 0;

            span {
              width: 15.9rem;
              margin-right: 5.1rem;
            }
          }
        }
      }

      .complete-info-box {
        position: relative;
        right: auto;
        bottom: auto;
        left: auto;
        margin: 8.5rem auto 0;
        padding: 0 4.2rem 3rem;
        & > strong {
          margin-bottom: 4.2rem;
          font-size: 2.7rem;
        }
        dl {
          display: flex;
          margin-top: 1rem;
          dt {
            width: 14rem;
            font-size: 2.5rem;
          }
          dd {
            font-size: 2.5rem;
            p {
              padding-bottom: 1rem;
            }
          }
        }
      }
    }

    .complete-box {
      dl {
        dt {
          font-size: 2.9rem;

          &::before {
            width: 12.5rem;
            height: 12.5rem;
            margin: 0 auto 5.2rem;
            background-size: auto 3.8rem;
            border-width: 0.8rem;
          }
        }

        dd {
          p {
            margin-top: 6.3rem;
          }

          strong {
            font-size: 4.6rem;
          }

          span {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}
