:global body.fixed #root {
  padding-top: 0 !important;
}

.container {
  background: #171720;
  min-height: 100vh;
}
.inner {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 28px;
  position: relative;
  box-sizing: border-box;
}
.bubbleMessage {
  position: absolute;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  right: 0;
  top: 59px;
  background: #2e2e41;
  border-radius: 66px;
  padding: 0 13px;
  line-height: 32px;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 42px;
    bottom: -10px;
    width: 12px;
    height: 16px;
    background: url('https://english.yanadoocdn.com/upload/yanadoo/new/levelTestAdvanced/2024/icon-time-alert-bubble.png');
    background-size: contain;
  }
}
.progress {
  padding: 64px 0 0;
  text-align: center;
  color: #717188;
  font-size: 20px;
  font-weight: 500;

  .bar {
    background: #2e2e41;
    border-radius: 3px;
    height: 5px;
    width: 100%;
    margin-top: 25px;
    position: relative;
  }

  .bar span {
    height: 100%;
    background: #fff;
    display: inline-block;
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0 64px;
  position: relative;
  .button {
    color: #fff;
    font-size: 20px;
  }
  .button:disabled {
    visibility: hidden;
  }
  .button img {
    width: 24px;
  }
}

.timer {
  width: 86px;
  height: 86px;
  position: relative;
  .timerCircle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: rotate(-270deg) scaleX(-1);
  }
  .timerText {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .frame {
    fill: none;
    stroke: #2e2e41;
  }
  .bar {
    stroke: #4a93ff;
    stroke-linecap: round;
    fill: none;
  }
  .timeLimit {
    color: red;
  }
}

@media screen and (max-width: 750px) {
  .inner {
    padding: 0 5.6vw;
    max-width: 100%;
  }
  .bubbleMessage {
    top: 80px;
    font-size: 3.2vw;
    line-height: 4.5vw;
    padding: 1.2vw 2.667vw;
    &:after {
      right: 8.6vw;
      bottom: -2.1vw;
    }
  }
  .progress {
    padding-top: 5.067vw;
    font-size: 4.267vw;
    line-height: 4.533vw;
    .bar {
      margin-top: 5.067vw;
      height: 1.067vw;
      border-radius: 0.533vw;
      span {
        border-radius: 0.533vw;
      }
    }
  }

  .navigation {
    padding: 16vw 0 7.467vw;

    .button {
      font-size: 4.267vw;
    }
    .button img {
      width: 4.8vw;
    }
  }
  .timer {
    width: 128px;
    height: 128px;

    .timerText {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 500px) {
  .navigation {
    box-sizing: border-box;
    width: 100%;
  }
  .inner {
    max-width: 100%;
  }
  .timer {
    width: 64px;
    height: 64px;
    .timerText {
      font-size: 12px;
    }
  }
  .bubbleMessage {
    top: 43px;
    &:after {
      bottom: -2.1vw;
    }
  }
}
