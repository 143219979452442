.commentBox {
  position: relative;
  padding: 4rem 2.5rem;

  & + & {
    border-top: 1px solid #e7e7f0;
  }

  &.reply {
    padding-top: 0;
    padding-left: 5.9rem;
    padding-bottom: 3.3rem;
    border-top: none !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 2.5rem;
      width: 2rem;
      height: 2rem;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_reply_40x40.png)
        50% 50% no-repeat;
      background-size: 100% auto;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 5rem 0;

    &.reply {
      padding-left: 5rem;
      padding-bottom: 5rem;

      &::before {
        left: 0;
        width: 4rem;
        height: 4rem;
      }
    }

    .attachmentBox {
      width: calc(100% - 4.9rem);
    }
  }
}

.profileBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    margin-left: 1.5rem;

    strong {
      display: block;
      font-weight: 700;
      font-size: 1.7rem;
    }

    span {
      display: block;
      margin-top: 0.3rem;
      font-weight: 400;
      font-size: 1.3rem;
      color: #a0a0b6;
    }
  }

  @media screen and (max-width: 1024px) {
    p {
      margin-left: 2.2rem;

      strong {
        font-size: 2.6rem;
      }

      span {
        font-size: 2rem;
      }
    }
  }
}

.descBox {
  padding: 1.5rem 0 0 0;

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  .reviewThumbsBox > button {
    padding: 1.5rem 0 0 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 2.5rem 0 0 0;

    p {
      font-size: 2.6rem;
    }

    .reviewThumbsBox > button {
      padding: 3rem 0 0 0;
    }
  }
}
