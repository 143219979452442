.errorUI {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76rem;

  p {
    padding-bottom: 3rem;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    line-height: 1.6em;

    strong {
      display: block;
      width: 9.5rem;
      height: 9.5rem;
      margin: 0 auto 0.6rem;
      background: url('https://english.yanadoocdn.com/upload/yanadoo/new/mall/ico_mall_error.png')
        no-repeat 50% 0 / 100% auto;
      text-indent: -9999rem;
    }

    @media all and (max-width: 1024px) {
      font-size: 3.2rem;

      strong {
        width: 13rem;
        height: 13rem;
        margin: 0 auto 2rem;
      }
    }
  }
}
