.timer {
  border-radius: 400px;
  background: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.09);
  position: fixed;
  top: 38px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  display: inline-flex;
  padding: 8px 0;
  align-items: center;
  gap: 9px;
  justify-content: center;
  transition: all ease 0.3s;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
  width: 149px;
  box-sizing: border-box;
  text-align: center;
  svg {
    width: 44px;
    height: 44px;
    transition: all ease 0.3s;
  }
  span {
    color: #5199ff;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -0.8px;
    transition: all ease 0.3s;
  }
  &.timeOver {
    svg path {
      stroke: #ff5647;
    }
    span {
      color: #ff5647;
    }
  }
}
.toast {
  display: flex;
  padding: 29px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background: rgba(22, 28, 34, 0.8);
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  left: 50%;
  margin-left: -262px;
  top: 34px;
  position: fixed;
  width: 524px;
  z-index: 99;
  box-sizing: border-box;
  transform: translateY(-150%);
  &.slideUp {
    animation: slideUp 0.8s forwards;
  }
  &.slideDown {
    animation: slideDown 0.8s forwards;
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-150%);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 750px) {
  .timer {
    border-radius: 53.333vw;
    box-shadow: 0vw 0vw 0.933vw 0vw rgba(0, 0, 0, 0.09);
    top: 5.067vw;
    padding: 1.067vw 0;
    gap: 1.2vw;
    width: 19.867vw;
    svg {
      width: 5.867vw;
      height: 5.867vw;
    }
    span {
      font-size: 5.333vw;
      line-height: 6.933vw;
      letter-spacing: -0.107vw;
      width: 6.133vw;
    }
  }
  .toast {
    padding: 3.867vw 0;
    gap: 1.333vw;
    border-radius: 2.667vw;
    border: 0.267vw solid rgba(0, 0, 0, 0.2);
    font-size: 3.733vw;
    line-height: 5.067vw;
    margin-left: -34.933vw;
    top: 4.533vw;
    width: 69.867vw;
  }
}
