$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';

.csFaqItem {
  dt,
  dd {
    box-sizing: border-box;
  }
  .faqTitle {
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    padding-left: 42px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    background-image: url('#{$img-cdn}ico_q.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 36px;
    border-bottom: 1px solid #e7e7f0;
    cursor: pointer;
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 28px;
      display: block;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      background-image: url('#{$img-cdn}ico_arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: all 0.3s;
      content: '';
    }

    &.faqAnswerOpen {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  .faqAnswer {
    display: none;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    padding: 34px 42px 42px;
    background-color: #f7f7fc;
    &.faqAnswerOpen {
      display: block;
    }
  }
}

@media screen and (max-width: 1024px) {
  .csFaqItem {
    .faqTitle {
      height: auto;
      padding: 34px 100px 34px 52px;
      font-size: 28px;
      line-height: 34px;
      background-position: 0px 29px;
      background-size: 44px;

      &::after {
        top: 39px;
        right: 28px;
        width: 24px;
        height: 24px;
        margin-top: 0;
      }
    }

    .faqAnswer {
      font-size: 26px;
      line-height: 48px;
      letter-spacing: -0.78px;
      padding: 40px 42px 54px;
    }
  }
}

@media screen and (max-width: 750px) {
  .csFaqItem {
    .faqTitle {
      padding: 4.533vw 13.333vw 4.533vw 12.267vw;
      font-size: 3.733vw;
      line-height: 4.533vw;
      background-position: 5.333vw 3.867vw;
      background-size: 5.867vw;

      &::after {
        top: 5.2vw;
        right: 9.067vw;
        width: 3.2vw;
        height: 3.2vw;
      }
    }
    .faqAnswer {
      font-size: 3.467vw;
      line-height: 6.4vw;
      letter-spacing: -0.104vw;
      padding: 5.333vw 5.6vw 7.2vw;
    }
  }
}
