.starPoint {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;

  strong {
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.05rem;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 0 !important;
    align-items: center;
    font-size: 2.7rem;

    strong {
      font-size: 3.1rem;
      transform: translateY(0.2rem);
    }

    svg {
      width: 4rem;
      height: 4rem;
      display: inline-block;
      margin-right: 0.75rem;
    }
  }
}

.flexBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .leftBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      flex: 1 1 auto;
      margin-left: 1.6rem;
      overflow: hidden;

      strong {
        font-size: 2.4rem;
        font-weight: 700;
        letter-spacing: -0.05rem;
        span {
          color: #78a0dc;
        }
      }
    }

    & + div {
      p,
      div {
        vertical-align: middle;
      }

      p strong {
        font-weight: 400;
        font-size: 1.4rem;
        color: #8d8da0;
      }
    }
  }

  .rightBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0.5rem;
    overflow: hidden;

    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      margin: 0.8rem 0 0;
      font-size: 1.6rem;
      white-space: normal;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
    }

    &.active {
      height: auto;
      max-height: 100%;

      p {
        -webkit-line-clamp: initial;
        line-clamp: initial;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 2.6rem;

    .leftBox {
      order: 2;
      div {
        margin-left: 1.6rem;

        strong {
          font-size: 3.6rem;
        }
      }

      & + div {
        margin: 0 !important;

        p {
          position: absolute;
          top: 14.7rem;
          left: 4rem;

          strong {
            font-size: 2.2rem;
          }
        }

        div {
          margin-top: 1.2rem;
        }
      }
    }

    .rightBox {
      order: 1;
      margin-top: 0;
    }
  }
}

.thumbSpan {
  flex: 0 0 auto;
  width: 4.6rem;
  height: 4.6rem;
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  i {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: 6.8rem;
    height: 6.8rem;
  }
}

.detailView {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80.6rem;
  min-height: 49.9rem;
  margin: 0 auto 2.4rem;
  background-color: #fff;
  padding: 0.8rem 2.5rem 0;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
}

.contents {
  margin: 3.5rem 0 0;

  > p {
    font-size: 1.6rem;
    line-height: 1.5;
    white-space: pre-line;
    word-break: break-all;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1024px) {
    margin: 4.8rem 0 0;

    > p {
      font-size: 3rem;
      line-height: 1.8;
      margin-bottom: 1.6rem;
    }
  }
}

.reviewItem {
  margin-bottom: 1rem;
  > p {
    color: #a0a0b6;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 142%;
  }

  @media (max-width: 1024px) {
    margin-bottom: 4.8rem;
    > p {
      color: #a0a0b6;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 125%;
    }
  }
}

.commentTotal {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.05rem;
  margin-top: 6rem;
  margin-bottom: 3rem;

  &.hasNoComment {
    margin-bottom: 0.7rem;
  }

  @media (max-width: 1024px) {
    margin-top: 8.2rem;
    margin-bottom: 4.2rem;
    font-size: 3.6rem;
    line-height: 122%;

    &.hasNoComment {
      margin-bottom: 1.6rem;
    }
  }
}

.adminAnswer {
  display: flex;
  gap: 1.2rem;
  .thumbnail {
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .answer {
    background-color: #ebf1ff;
    border-radius: 1rem;
    padding: 1.8rem;
    box-sizing: border-box;
    flex: 1;
  }
  .date {
    font-size: 1.2rem;
    font-weight: 400;
    opacity: 0.3;
    margin-top: 1rem;
  }
  .writer {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.05rem;
    line-height: 150%;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 1024px) {
    gap: 1.4rem;
    .thumbnail {
      width: 6.8rem;
      height: 6.8rem;

      svg {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
    .writer {
      font-size: 2.6rem;
    }
    .answer {
      padding: 2.4rem;
      border-radius: 1.5rem;
    }
    .date {
      font-size: 2.2rem;
      line-height: 83.333%;
      margin-top: 1.5rem;
    }
  }
}
