.aiInfo {
  padding: 126px 0 36px;

  .colDescription {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .description {
      .name {
        font-family: 'Inter';
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.5px;
        color: #000;
      }
      .profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 6px;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #000;

        svg {
          width: 2px;
          height: 2px;
        }
      }
      .hashBlock {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 20px;
        padding-bottom: 42px;

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 28px;
          padding: 0 12px;

          border-radius: 6px;
          border: 1px solid #cfcfda;
          background: #fff;

          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #28284b;
        }
      }
    }
    .figure {
      width: 86px;
      height: 86px;
    }
  }

  .colTips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    &::before,
    &::after {
      display: block;
      height: 1px;
      background-color: #e7e7f0;
      flex: 1;
      content: '';
    }

    p {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      white-space: nowrap;
      color: #a0a0b6;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 129px 0 58px;

    .colDescription {
      gap: 20px;

      .description {
        .name {
          font-size: 30px;
          line-height: 40px;
          letter-spacing: -0.5px;
        }
        .profile {
          gap: 12px;
          margin-top: 9px;

          font-size: 16px;
          line-height: 22px;
        }
        .hashBlock {
          gap: 8px;
          margin-top: 35px;
          padding-bottom: 48px;

          span {
            height: 40px;
            padding: 0 16px;

            font-size: 20px;
            line-height: 26px;
          }
        }
      }
      .figure {
        width: 106px;
        height: 106px;
      }
    }

    .colTips {
      gap: 29px;

      p {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 17.2vw 0 7.733vw;

    &.webviewTopInfo {
      padding-top: 7.733vw;
    }

    .colDescription {
      gap: 2.667vw;

      .description {
        .name {
          font-size: 4vw;
          line-height: 5.333vw;
          letter-spacing: -0.067vw;
        }
        .profile {
          gap: 1.6vw;
          margin-top: 1.2vw;

          font-size: 2.133vw;
          line-height: 2.933vw;
        }
        .hashBlock {
          gap: 1.067vw;
          margin-top: 4.667vw;
          padding-bottom: 6.4vw;

          span {
            height: 5.333vw;
            padding: 0 2.133vw;

            font-size: 2.667vw;
            line-height: 3.467vw;
          }
        }
      }
      .figure {
        width: 14.133vw;
        height: 14.133vw;
      }
    }

    .colTips {
      gap: 3.867vw;

      p {
        font-size: 2.667vw;
        line-height: 3.467vw;
      }
    }
  }
}
