.floorBtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #161c22;

  .bannerButton {
    width: 100%;
    padding: 35px 0;
    background-color: transparent;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.5px;
    text-align: center;
  }
  img {
    width: 32px;
    height: 32px;
    margin-top: 4px;
    margin-left: 4px;
  }
}
@media all and (max-width: 750px) {
  .floorBtn {
    .bannerButton {
      padding: 4.6667vw 0;
      font-size: 4.2667vw;
      line-height: 5.3333vw;
      letter-spacing: -0.0667vw;
    }
    img {
      width: 4.2667vw;
      height: 4.2667vw;
      margin-top: 0.5333vw;
      margin-left: 0.5333vw;
    }
  }
}
