input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.pop-counseling .pop-ynd-content .bannerArea {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding: 0;
  background-position: center;
  background-size: cover;
}
.bannerArea span {
  display: none;
}

.pop-ynd-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(https://english.yanadoocdn.com/upload/yanadoo/pc/common/bg/mask_80.png);
}

.pop-ynd-content button {
  position: relative;
  display: block;
  width: 100%;
  height: 9.2rem;
  outline: 0;
  font-weight: 700;
  font-size: 2.6em;
  color: #fff;
  text-align: center;
  letter-spacing: -1px;
  z-index: 2;
}

/* pop-temp */
.pop-temp {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
}
.pop-temp .pop-ynd-content {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 95%;
  width: 57em;
  font-size: 10px;
  transform: translate(-50%, -50%);
  overflow: auto;
}
.pop-temp .pop-ynd-content > .display-pc {
  display: block;
  width: 100%;
}

/* pop-counseling */
.pop-counseling .pop-ynd-content {
  width: 55rem !important;
  background-color: #fff;
}
.pop-counseling .pop-ynd-content .top-banner {
  width: 100%;
  height: 23rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
}
.pop-counseling dl {
  display: grid;
  grid-template-columns: 16rem auto;
  align-items: center;
}
.pop-counseling .pop-ynd-content .formContainer {
  position: relative;
  width: 100%;
  padding: 22px 28px 44px;
  margin: 0 auto;
  box-sizing: border-box;
  color: #000;
  text-align: left;
  line-height: 1.3;
  letter-spacing: -1px;
  background-color: #fff;
}
.pop-counseling div h3 {
  font-weight: 700;
  font-size: 3.5rem;
  text-align: center;
}
.pop-counseling dd {
  display: inline-block;
  margin: 0 0 1rem;
  vertical-align: middle;
}
.pop-counseling dt {
  font-size: 2rem;
}
.pop-counseling dt em {
  color: red;
}
.pop-counseling dt p {
  margin-top: 0.3rem;
  font-size: 1.5rem;
  color: #ababab;
}
.pop-counseling dd select,
.pop-counseling dd input {
  width: 100%;
  height: 7rem;
  padding: 0 0.7rem;
  font-size: 1.8rem;
  color: #000;
  letter-spacing: -1px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.pop-counseling dd p {
  color: #0038de;
  font-size: 1.5rem;
}
.pop-counseling select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/pc/sub/mypage/icon_select_btn.png);
  background-repeat: no-repeat;
  background-position: 96% 50%;
  background-size: 9px;
}
.pop-counseling select::-ms-expand {
  display: none;
}
.pop-counseling select[name*='hp'],
.pop-counseling input[name*='hp'] {
  width: 33.3%;
  vertical-align: middle;
}
.pop-counseling select[name*='hp'] {
  background-position: 90% 50%;
}
.pop-counseling input[name='hp2'] {
  width: 33.4%;
  border-left: 0;
  border-right: 0;
}
.pop-counseling .agree-box label {
  display: inline-block;
  cursor: pointer;
}
.pop-counseling .agree-box label span {
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding-left: 4rem;
  font-size: 1.7rem;
}
.pop-counseling label input[type='checkbox'] + span:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 2.8rem;
  height: 2.8rem;
  background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png)
    no-repeat 50% 50% / 1.6rem auto;
  border: 1px solid #cfcfda;
  border-radius: 100%;
  transform: translateY(-50%);
}
.pop-counseling .agree-box label input[type='checkbox']:checked + span:before {
  background-color: #ffe700;
  border-color: #ffe700;
  background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/jan/ico_check.svg);
}
.pop-counseling ul.agree-box {
  margin-top: 2.8rem;
  margin-left: 135px;
}
.pop-counseling ul.agree-box li + li {
  margin-top: 2rem;
}
.pop-counseling ul.agree-box li:nth-child(2) {
  padding-top: 1.4rem;
  margin-top: 1.5rem;
  border-top: 1px solid #ddd;
}
.pop-counseling ul.agree-box li:first-child label span {
  font-size: 2.1rem;
  font-weight: 700;
}
.pop-counseling ul.agree-box.active li:nth-child(2),
.pop-counseling ul.agree-box.active li:nth-child(3) {
  display: none;
}
.pop-counseling .btn-agree-detail {
  display: inline-block;
  vertical-align: top;
  width: auto;
  height: auto;
  padding: 0;
  font-weight: 500;
  font-size: 1.6rem;
  color: #2e61f6;
  background-color: transparent;
  border-bottom: 0.1rem solid #2e61f6;
  margin-left: 3px;
}
.pop-counseling .btn-agree-detail:after {
  display: none;
}
.pop-counseling button {
  margin: 0;
  padding: 3rem 0;
  font-size: 2.2rem;
}

@media screen and (max-width: 1024px) {
  .pop-counseling .pop-ynd-content {
    width: 34em !important;
    max-height: 580px;
  }
  .pop-counseling .pop-ynd-content .top-banner {
    height: 14.76em;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/apr/img_m_bestawards_db_banner.png)
      no-repeat center 50%/ 100%;
  }
  .pop-counseling .top-banner + strong {
    margin: 1.8em 0;
    font-size: 1.9em;
  }
  .pop-counseling .pop-ynd-content .top-content h3 {
    font-size: 2.7em;
  }
  .pop-counseling .pop-ynd-content .top-content h3::before {
    width: 1.35em;
    height: 1.35em;
    margin: 0 auto 0.5em;
  }
  .pop-counseling .pop-ynd-content .top-content h3 + p {
    margin-top: 1em;
    font-size: 1.3em;
  }

  .pop-counseling .top-banner em {
    padding: 1rem 2rem;
    font-size: 2.3rem;
    border-radius: 2rem;
  }
  .pop-counseling .top-banner strong {
    margin-top: 1.6rem;
    font-size: 4rem;
  }
  .pop-counseling .top-banner span {
    display: inline-block;
    font-weight: 500;
    font-size: 2.4rem;
    color: #000;
  }
  .pop-counseling dl {
    overflow: hidden;
    grid-template-columns: 11em 1fr;
  }
  .pop-counseling .pop-ynd-content .formContainer {
    position: relative;
    width: 100%;
    padding: 2.5em 1.5em;
    margin: 0 auto;
    box-sizing: border-box;
    color: #000;
    text-align: left;
    line-height: 1.3;
    letter-spacing: -1px;
    background-color: #fff;
  }
  .pop-counseling div h3 {
    font-size: 4.3rem;
  }
  .pop-counseling dd {
    display: inline-block;
    margin: 0 0 1rem;
    vertical-align: middle;
  }
  .pop-counseling dt {
    font-size: 2.6rem;
  }
  .pop-counseling dt p {
    margin-top: 0.3rem;
    font-size: 2.4rem;
  }
  .pop-counseling dd select,
  .pop-counseling dd input {
    height: 8rem;
    font-size: 2.4rem;
  }
  .pop-counseling select {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/pc/sub/mypage/icon_select_btn.png);
    background-repeat: no-repeat;
    background-position: 96% 50%;
    background-size: 9px;
  }
  .pop-counseling ul.agree-box {
    margin: 1.6em 0 0 18rem;
  }
  .pop-counseling ul.agree-box label span {
    padding: 0em 0 0 5rem;
    font-size: 1.06em;
  }
  .pop-counseling ul.agree-box label input[type='checkbox'] + span:before {
    width: 3.2rem;
    height: 3.2rem;
  }
  .pop-counseling ul.agree-box li:nth-child(2) {
    margin-top: 1em;
    padding-top: 1em;
  }
  .pop-counseling ul.agree-box li:first-child label span {
    font-size: 1.2em;
  }
  .pop-counseling .btn-agree-detial {
    height: auto;
    font-size: 2.4rem;
    border-bottom: 0.2rem solid #2e61f6;
  }
  .pop-counseling .btn-counsel-pop-submit {
    height: 12rem;
    padding: 0;
    font-size: 3.2rem;
  }
  .pop-counseling .btn-counsel-pop-submit:after {
    bottom: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
    margin: auto 0 auto 1.4rem;
    border-right: 0.4rem solid #fff;
    border-bottom: 0.4rem solid #fff;
  }
}
