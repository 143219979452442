.circleList {
  position: absolute;
  top: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.circleList.leftList {
  right: 540px;
}
.circleList.rightList {
  left: 540px;
}
.circleList.leftList li {
  margin-left: 28px;
}
.circleList.rightList li {
  margin-right: 28px;
}
.circleList.leftList li:nth-child(2),
.circleList.rightList li:nth-child(3) {
  width: 54px;
  opacity: 0.2;
}
.circleList.leftList li:nth-child(3),
.circleList.rightList li:nth-child(2) {
  width: 82px;
  opacity: 0.3;
}
.circleList.leftList li:nth-child(4),
.circleList.rightList li:nth-child(1) {
  width: 182px;
  opacity: 0.8;
}
.circleList.leftList li:first-child,
.circleList.rightList li:last-child {
  display: none;
}

@media screen and (max-width: 1024px) {
  .circleList {
    top: 377px;
  }
  .circleList.leftList {
    right: 563px;
  }
  .circleList.rightList {
    left: 563px;
  }
}
@media screen and (max-width: 750px) {
  .circleList {
    top: 50.267vw;
  }
  .circleList.leftList {
    right: 75.067vw;
  }
  .circleList.rightList {
    left: 75.067vw;
  }
  .circleList.leftList li:nth-child(4),
  .circleList.rightList li:nth-child(1) {
    width: 24.267vw;
  }
}
