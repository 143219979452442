.btnGroupBottom {
  width: 80%;
  padding: 4.2rem;
  background-color: #fff;
  text-align: left;
  box-sizing: border-box;
  position: absolute;

  a,
  button {
    display: inline-block;
    padding: 2rem 0;
    width: unset !important;
    height: unset !important;
    font-size: 2.8rem;
  }
  button {
    margin-left: 15px;
  }
}

.leftCompanyCol {
  margin: 0;
  border-bottom: 1px solid #ddd;
  .tabMenu {
    li {
      padding-right: 4.8rem;
    }
  }
  & > ul {
    margin-left: 4.2rem;
    text-align: left;

    li {
      display: inline-block;
      position: relative;
      padding-right: 2rem;
      font-weight: 500;
      font-size: 2.8rem;

      &:last-child {
        padding-right: 0;
      }

      button {
        width: unset;
        height: unset;
        margin: unset;
        padding: 3.3rem 0 2.9rem;
        border-bottom: 0 solid transparent;
      }
      &.active {
        button {
          font-weight: 700;
          border-bottom: 0.4rem solid transparent;
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    ul {
      margin-left: 4rem;
      li {
        padding-right: 1.5rem;
      }
    }
    .tabMenu {
      li {
        padding-right: 2.5rem;
        font-size: 13px;
      }
    }
  }
}

.nav {
  &.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    padding: 19rem 0 8rem;
    font-size: 3rem;
    background-color: #fff;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 20;
    min-width: 290px;

    .logo {
      position: absolute;
      top: 7rem;
      left: 4rem;
      width: 21rem;
      height: 5rem;
    }

    > ul > li {
      display: block;
      width: 100%;

      &.nochild {
        a {
          &:after {
            display: none !important;
          }
        }
      }
    }
  }

  .lnbMBtnGroup {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    .lnbMBtnGroup {
      display: block;
      position: absolute;
      right: 5px;
      top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .lnbMBtnGroup a {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      display: block;
      padding: 16px 14px;
      position: relative;
    }
    .lnbMBtnGroup .btnMEvents:after {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -8px;
      background-color: #cfcfda;
    }
  }
  @media only screen and (max-width: 425px) {
    .lnbMBtnGroup {
      a {
        font-size: 13px;
      }
    }
  }
}

.menu2dList {
  display: none;
  min-height: calc(100% - 26rem);
  &.active {
    display: block;
  }
  .menuName {
    padding-left: 4.2rem;
    font-size: 2.8rem;
  }
  @media only screen and (max-width: 425px) {
    &.active {
      ul {
        padding-left: 4rem;
        li {
          padding-right: 1.5rem;
        }
      }
      .menu-name {
        padding: 3.4rem 4rem;
        font-size: 13px;
      }
      .depthMenuName {
        font-size: 12px;
      }
    }
  }
}
