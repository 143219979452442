.attachLink {
  display: inline-block;
  width: 38.2rem;
  margin-top: 2rem;

  span {
    display: block;
    width: 100%;
    height: 15rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  div {
    min-height: 9rem;
    padding: 1.2rem 2.2rem;
    border: 1px solid #dfdfea;
    border-left: 2px solid #fded52;
    box-sizing: border-box;

    strong {
      font-weight: 700;
      font-size: 1.6rem;
    }

    p {
      margin-top: 0.4rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: #a0a0b6;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 5rem;

    span {
      width: 100%;
      height: 26rem;
    }

    div {
      min-height: 15rem;
      padding: 2.4rem 3.8rem;

      strong {
        font-size: 2.8rem;
      }

      p {
        margin-top: 0.6rem;
        font-size: 2rem;
      }
    }
  }
}
