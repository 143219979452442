.container {
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  padding: 125px 42px 0;
  position: relative;
  text-align: center;
}
.backButton {
  position: absolute;
  top: 4.533vw;
  left: 4.533vw;
  width: 9.6vw;
  height: 9.6vw;
}
.label {
  border-radius: 460.95px;
  background: linear-gradient(90deg, #ffd7b3 0%, #deecff 100%);
  display: inline-flex;
  padding: 10px 28px;
  justify-content: center;
  align-items: center;
  gap: 7.37px;
  span {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.72px;
    background: linear-gradient(90deg, #ff8238 0%, #4f91f5 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.title {
  color: #030618;
  text-align: center;
  font-size: 90px;
  font-weight: 500;
  line-height: 108px;
  letter-spacing: -1.8px;
  margin-top: 30px;
}
.description {
  margin-top: 8px;
  color: #000;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
}
.buttonWrap {
  display: flex;
  margin-top: 54px;
  gap: 18px;
  align-items: flex-start;
  button {
    color: var(--GrayScale-900---000000, #000);
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.5px;
    width: 50%;
  }
}

.buttonImage {
  position: relative;
  .lottie {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    width: 232.5px;
  }
}
.buttonText {
  margin-top: -26.25px;
  span {
    margin-top: 12px;
    color: var(--GrayScale-700---717188, #717188);
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.5px;
    display: block;
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding: 16.67vw 5.6vw 0;
  }

  .label {
    border-radius: 61.46vw;
    background: linear-gradient(90deg, #ffd7b3 0%, #deecff 100%);
    padding: 1.33vw 3.73vw;
    gap: 0.98vw;
    span {
      font-size: 3.2vw;
      font-weight: 500;
      letter-spacing: -0.1vw;
    }
  }
  .title {
    color: #030618;
    font-size: 12vw;
    line-height: 14.4vw;
    letter-spacing: -0.24vw;
    margin-top: 4vw;
  }
  .description {
    margin-top: 1.07vw;
    font-size: 3.73vw;
    line-height: 4.53vw;
  }
  .buttonWrap {
    margin-top: 7.2vw;
    gap: 2.4vw;
    button {
      color: var(--GrayScale-900---000000, #000);
      font-size: 4.8vw;
      font-weight: 700;
      line-height: 5.87vw;
      letter-spacing: -0.07vw;
      width: 50%;
    }
  }

  .buttonImage {
    .lottie {
      top: 6vw;
      left: 50%;
      transform: translateX(-50%);
      width: 31vw;
    }
  }
  .buttonText {
    margin-top: -3.5vw;
    span {
      margin-top: 1.6vw;
      font-size: 4.27vw;
      line-height: 5.33vw;
      letter-spacing: -0.07vw;
    }
  }
}
