.bannerFullBox {
  height: 20rem;

  & > div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  h1 {
    padding-bottom: 0.8rem;
    font-weight: 700;
    font-size: 3.2rem;
    color: #28284b;
  }

  pre {
    font-weight: 400;
    font-size: 1.8rem;
    color: #28284b;
    white-space: inherit;

    br {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 21.4rem;

    & > div {
      padding: 0 4.2rem;
      background-position: calc(100% - 6rem) 50% !important;

      h1 {
        font-size: 3.6rem;
        padding-bottom: 1.8rem;
      }

      pre {
        font-size: 2.4rem;
        white-space: pre-wrap;
      }
    }
  }
}

.smInner {
  position: relative;
  max-width: 948px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 0;
  }
}
