$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/aiTravel/';
.aiTravel {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    -webkit-text-size-adjust: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  .btnCloseAppview {
    width: 24px;
    height: 24px;
    overflow: hidden;
    text-indent: -1000px;
    white-space: nowrap;
    background-image: url('#{$img-path}ico_close.png');
    background-repeat: no-repeat;
    background-size: 24px;
    position: fixed;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .tipContainer {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    padding: 24px 0 0;

    .tipInner {
      height: 100%;
      padding: 0 24px 32px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .titleGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .tipImg {
        box-sizing: border-box;
        width: 84px;
        padding: 6px;
      }
      h3 {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.5px;
        margin-top: 2px;
      }
    }

    .scenario {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      margin-top: 24px;

      dt {
        display: grid;
        place-items: center;
        width: 32px;
        height: 20px;
        color: #269cff;
        font-size: 10px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        border-radius: 2px;
        background-color: #e3f2fe;
      }
      dd {
        p {
          color: #269cff;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;

          &:last-child {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            margin-top: 6px;
          }
        }
      }
    }

    .missions {
      margin-top: 20px;
      border-radius: 5px;
      overflow: hidden;
      h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        background: #28284b;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .missionList {
        padding: 20px 16px;
        background-color: #f7f7fc;
        display: flex;
        flex-direction: column;
        gap: 16px;

        li {
          position: relative;
          padding-left: 24px;

          &::before {
            position: absolute;
            left: 0;
            top: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-color: #28284b;
            color: #fff;
            font-family: Roboto;
            font-size: 10px;
            font-weight: 700;
            line-height: 10px;
            content: '';
          }

          &:nth-child(1)::before {
            content: '1';
          }
          &:nth-child(2)::before {
            content: '2';
          }
          &:nth-child(3)::before {
            content: '3';
          }
        }

        .sample {
          dt {
            color: #28284b;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 4px;
          }
          dd {
            color: #28284b;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .noti {
      margin-top: 12px;
      ul {
        padding-left: 24px;
        position: relative;
        background-image: url('#{$img-path}info.png');
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: 0 0;
        li {
          color: #a0a0b6;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          margin-top: 6px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .btnEnter {
      display: grid;
      place-items: center;
      height: 48px;
      width: 100%;
      border-radius: 24px;
      background-color: #ffb900;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      margin-top: 45px;
    }
  }
}
