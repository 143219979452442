@import './variable.scss';
.password-box {
  font-size: 1.6rem;

  + dl {
    margin-top: 4rem;
  }

  dt {
    font-weight: 400;
  }

  dd {
    position: relative;
  }

  input[type='password'] {
    width: 100%;
    padding: 1.6rem 1.6rem 1.4rem;
    font-size: 1.6rem;
    color: $dark;
    border-bottom: 1px solid $gray-300;
    box-sizing: border-box;
    caret-color: $dark-warning;

    &::placeholder {
      color: $gray-600;
    }

    &.warning {
      border-bottom: 1px solid $danger;
      &:focus {
        border-bottom: 1px solid $danger;
      }
    }

    &.success {
      border-bottom: 1px solid $info;
      &:focus {
        border-bottom: 1px solid $gray-300;
      }
    }

    &:focus {
      border-bottom: 1px solid $dark;
    }
  }

  span {
    &.warning {
      display: none;
      font-size: 1.1rem;

      &.active {
        display: block;

        &.warning {
          color: $danger;
        }

        &.success {
          color: $info;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .password-box {
    + dl {
      margin-top: 7rem;
    }

    dt {
      font-size: 2.5rem;
    }

    dd {
      font-size: 2.9rem;
    }

    input[type='password'] {
      padding: 1.5rem 2.6rem 2.4rem;
      font-size: 2.9rem;
    }

    span {
      &.warning {
        bottom: -4.5rem;
        right: 4.4rem;
        // margin-top: 1.6rem;
        font-size: 2.3rem;
      }
    }
  }
}
