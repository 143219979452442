$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';
.btnMore {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 201px;
  height: 48px;
  border-radius: 54px;
  background: #ececf6;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-left: auto;
  margin-right: auto;
  &::after {
    display: block;
    width: 12px;
    height: 12px;
    background-image: url('#{$img-cdn}ico_more.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    content: '';
  }
}
@media screen and (max-width: 1024px) {
  .btnMore {
    width: 100%;
    height: 80px;
    font-size: 24px;
    line-height: 30px;
    border-radius: 4px;
    &::after {
      width: 16px;
      height: 16px;
      background-size: 16px;
    }
  }
}
@media screen and (max-width: 750px) {
  .btnMore {
    height: 10.667vw;
    font-size: 3.2vw;
    line-height: 4vw;
    &::after {
      width: 2.133vw;
      height: 2.133vw;
      background-size: 2.133vw;
    }
  }
}
