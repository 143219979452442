.modalInner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 48rem;
  max-height: 81rem;
  min-height: 81rem;
  background-color: #fff;
  border-radius: 1rem;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;

  .modalContent {
    max-height: 100%;
  }

  .btnFlexForm {
    margin-top: 1.4rem;
  }

  .default {
    color: #fff;
    background-color: #cfcfda;

    &:disabled {
      cursor: default;
    }
  }

  .active {
    background-color: #ffb900;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: calc(100% - 8.7rem);
    max-height: calc(100% - 8.7rem);
    height: auto;
    border-radius: 2rem 2rem 0 0;
  }
}

.yndUnivBox {
  padding-top: 10rem;
  font-size: 2rem;
  line-height: 1.4;

  h4 {
    margin: 0 0 2.1rem;
  }

  strong {
    font-size: 3rem;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 13.5rem;
    font-size: 3.2rem;

    strong {
      font-size: 4.8rem;
    }
  }
}

.goodsItemBox {
  strong {
    display: inline-block;
    padding-right: 2.1rem;
    font-size: 2rem;
    font-weight: 700;
  }

  em {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 700;
    color: #b7b7b7;

    &:before {
      content: '*';
    }
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6.3rem 0 5.9rem;
  }

  li {
    position: relative;
    flex: 0 1 auto;

    div {
      width: 20.4rem;
      border-radius: 0.5rem;
      overflow: hidden;
      font-size: 2rem;
      font-weight: 700;
    }

    & + li {
      margin-left: 1.4rem;
    }

    label {
      display: block;
      width: inherit;
      height: inherit;

      input[type='radio'] {
        + .chk {
          width: 4rem;
          height: 4rem;
          background-color: #ececf6;
          border: 0;

          &:before {
            display: inline-block;
            width: 3.8rem;
            height: 3rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_38x30w.png)
              50% 50%/60% no-repeat;
          }
        }

        &:checked + .chk {
          background-color: #ffb900;

          &:before {
            background-image: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_38x30b.png);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    strong {
      padding-right: 3.5rem;
    }

    em {
      font-size: 2.4rem;
    }

    ul {
      margin: 8.7rem 0 6.7rem;
    }

    li {
      div {
        width: 32rem;
        font-size: 3.2rem;
      }

      label {
        display: block;
        width: inherit;
        height: inherit;

        input[type='radio'] {
          + .chk {
            width: 6.2rem;
            height: 6.2rem;

            &:before {
              display: inline-block;
              width: 4.5rem;
              height: 3.7rem;
            }
          }
        }
      }
    }
  }
}

.goodsThumb {
  display: inline-block;
  margin-bottom: 1.2rem;
  padding-top: 19.6rem;
  background-size: 100%;
  background-repeat: no-repeat;

  @media screen and (max-width: 1024px) {
    padding-top: 30.7rem;
  }
}
