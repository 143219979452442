@charset "UTF-8";
@import './variable.scss';
$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';

.info-edit-box {
  margin-bottom: 12rem;
  padding-top: 8rem;

  input[type='password'] {
    width: 100%;
    font-size: 1.6rem;
    color: $dark;
    box-sizing: border-box;

    &::placeholder {
      color: $gray-600;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 700;
    font-size: 2rem;

    strong {
      flex: 1 0 auto;
      font-weight: 700;
    }

    span {
      flex: 0 1 auto;
      font-weight: 400;
      font-size: 1.6rem;

      &::before {
        content: '*';
        display: inline-block;
        color: #ff5647;
      }
    }
  }

  .btn-edit-submit {
    display: block;
    margin: 7rem auto 0;
    width: 49.5rem;
    padding: 2rem 0;
    font-weight: 400;
    font-size: 2rem;
    border: none;
    border-radius: 0.3rem;

    &.active {
      background-color: $warning;
    }
  }

  .withdrawal-area {
    display: flex;
    align-items: center;
    width: 49.5rem;
    margin: 5.3rem auto 0;
    font-weight: 700;
    font-size: 1.6rem;

    p {
      flex: 1 0 auto;
    }

    .btn-withdrawal {
      flex: 0 1 auto;
      font-weight: 400;
      font-size: 1.4rem;
      color: $gray-900;
      text-decoration: underline;
    }
  }
}

span {
  &.valid-text {
    display: none;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: right;

    &.active {
      display: block;

      &.warning {
        color: $danger;
      }

      &.success {
        color: $info;
      }
    }
  }
}

.edit-form {
  margin-top: 2rem;
  border-top: 0.3rem solid $dark;

  input::placeholder {
    color: $gray-600;
  }

  dl {
    position: relative;
    font-size: 1.6rem;
    border-bottom: 0.1rem solid $gray-300;
    box-sizing: border-box;

    input[type='text'],
    select {
      width: 100%;
      height: auto;
      font-size: 1.6rem;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }

    .flex-form {
      display: flex;

      .btn {
        flex: 0 1 auto;
        width: 8rem;
        padding: 1.4rem 0;
        font-size: 1.6rem;
        white-space: nowrap;
      }
    }

    label {
      display: inline-block;
      margin-top: 1.5rem;
      font-size: 1.4rem;
      color: $gray-800;
      cursor: pointer;

      input[type='checkbox'] {
        & + .chk {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.3rem;

          &:before {
            width: 1.4rem;
            height: 1rem;
          }
        }
      }

      // input[type='checkbox'] {
      //   & + .chk {
      //     width: 2.4rem;
      //     height: 2.4rem;

      //     &:before {
      //       width: 1.4rem;
      //       height: 1rem;
      //     }
      //   }
      // }

      // &.lg-chk {
      //   input[type='checkbox'] {
      //     & + .chk {
      //       width: 2.4rem;
      //       height: 2.4rem;

      //       &:before {
      //         width: 1.4rem;
      //         height: 1rem;
      //       }
      //     }
      //   }
      // }

      input[type='radio'] {
        & + .chk {
          width: 2.7rem;
          height: 2.7rem;
          margin-right: 1.3rem;
        }
      }

      p {
        display: inline-block;
        line-height: 1.4;
      }
    }

    dt {
      display: inline-block;
      width: 26%;
      padding: 2.2rem 0 2.2rem 2.5rem;
      font-weight: 400;
      box-sizing: border-box;
      vertical-align: middle;

      &.required {
        &::before {
          content: '*';
          display: inline-block;
          margin-right: 0.2rem;
          color: #ff5647;
        }
      }
    }

    dd {
      display: inline-block;
      width: 74%;
      padding: 2rem 2.5rem 2rem 0;
      vertical-align: middle;
      box-sizing: border-box;
    }
  }

  .phone-box {
    .flex-form {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      border: 0.1rem solid $gray-300;
    }

    input[type='text'] {
      flex: 1 0 auto;
      padding: 1.6rem 2rem;

      &.country-input {
        flex: 0 1 auto;
        width: 15%;
        padding: 1.6rem 1.5rem;
        box-sizing: border-box;
      }
    }

    .get-country {
      flex: 0 1 auto;
      width: 10%;
      padding: 1.75rem 0;
      background: url('#{$img-cdn}/ico_arrow_63x63_b.png') 50% 50% / 1rem 1rem no-repeat;
      color: rgba(255, 255, 255, 0);

      option {
        color: $dark;
      }
    }

    .btn {
      &.btn-phone-code {
        width: 15%;
        margin-right: 1.5rem;
        padding: 1rem;
        font-size: 1.4rem;
        box-sizing: border-box;
      }
    }
  }

  .email-box {
    input[type='text'] {
      padding: 1.6rem 1.5rem;
      border: 0.1rem solid $gray-300;
      box-sizing: border-box;
    }
  }

  .agree-flex-form {
    margin-top: 1.1rem;
    p {
      font-size: 1.4rem;
      line-height: 1.5;
      color: #717188;
    }

    .checkbox-flex-form {
      display: flex;
      font-size: 1.4rem;
    }
  }

  .gender-box {
    label {
      width: 18.5rem;
      margin-top: 0;
      padding: 1.6rem 0;
      font-size: 1.6rem;
      color: $dark;
      border: 0.1rem solid $gray-300;
      border-right: none;
      text-align: center;
      box-sizing: border-box;

      + label {
        border-left: 0.1rem solid $gray-300;
        border-right: 0.1rem solid $gray-300;
      }

      input[type='radio'] {
        display: none;
      }

      &.active {
        background-color: $warning;
        border-color: $warning;

        + label {
          border-left: none;
        }
      }
    }
  }

  .birthday-box {
    display: inline-block;
    border: 0.1rem solid $gray-300;

    select {
      width: 10.2rem;
      padding: 2rem 0 1.6rem 1.5rem;
      background: url('#{$img-cdn}/ico_arrow_63x63_b.png') 90% 50% / 1rem 1rem no-repeat;
      box-sizing: border-box;

      + select {
        border-left: 0.1rem solid $gray-300;
      }
    }

    .year-select {
      width: 16rem;
    }
  }

  .address-box {
    input[type='text'] {
      width: 100%;
      margin-top: 1.2rem;
      padding: 1.6rem 1.5rem;
      border: 0.1rem solid $gray-300;
      box-sizing: border-box;

      &.zone-code {
        flex: 0 1 auto;
        width: 27.8rem;
        margin-right: 1rem;
        margin-top: 0;
      }

      &.input-value {
        color: $gray-600;
      }

      &.active {
        color: $dark;
      }
    }
  }

  .join-path-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      flex: 1 1 auto;
      width: 50%;
    }
  }

  .btn-password {
    width: 24rem;
    padding: 1.8rem 0;
    font-weight: 400;
    font-size: 1.4rem;
    color: $white;
    background-color: $gray-900;
    border-radius: 0.3rem;
  }

  .notice {
    display: block;
    margin-top: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.4;
    color: $gray-800;

    &::before {
      content: '※';
      display: inline-block;
      margin-right: 0.3rem;
    }
  }

  span {
    .warning {
      display: none;
      width: 100%;
      margin-top: 1rem;
      font-size: 1.1rem;
      text-align: right;

      &.active {
        display: block;

        &.warning {
          color: #ff5647;
        }

        &.success {
          color: $info;
        }
      }
    }
  }

  .checkbox-flex-form {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
      + span {
        flex: 0 0 auto;
      }
    }

    p {
      flex: 0 1 auto;
    }
  }
}

.modal-form-col {
  &.password {
    // input[type="password"]{
    //     width: 100%;
    //     padding: 1.6rem 2.6rem 1.4rem;
    //     font-size: 1.9rem;
    //     box-sizing: border-box;
    //     border-bottom: .1rem solid $gray-300;
    // }

    dl {
      min-height: 14rem;
      margin-top: 0;

      .valid {
        display: block;
        margin-top: 1.9rem;
        font-size: 1.5rem;
        color: $info;
        text-align: right;
      }

      &.warning {
        input[type='password'] {
          border-color: #ff5647;
        }

        .valid {
          color: #ff5647;
        }
      }
    }

    .btn-flex-form {
      margin-top: 18rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .info-edit-box {
    padding-top: 4.5rem;

    input[type='password'] {
      padding: 2.6rem 2.6rem 2.2rem;
      font-size: 2.9rem;
      border-bottom: 0.1rem solid $gray-300;
    }

    .title {
      padding: 0 4.2rem;

      strong {
        display: none;
      }

      span {
        flex: 1;
        font-size: 2.3rem;
        text-align: right;
      }
    }

    .btn-edit-submit {
      width: 92%;
      padding: 3.4rem 0;
      font-size: 2.7rem;
    }

    .withdrawal-area {
      flex-direction: column;
      padding-top: 0;
      font-size: 2.7rem;

      p {
        flex: 1 0 auto;
      }

      .btn-withdrawal {
        flex: 0 1 auto;
        margin-top: 2.2rem;
        font-size: 2.7rem;
      }
    }
  }

  .edit-form {
    border-top: none;

    dl {
      border-bottom: none;

      input[type='text'],
      select {
        font-size: 2.9rem;
      }

      input[type='text'] {
        width: 100%;
        padding: 2.6rem 2.6rem 2.2rem;
        border-bottom: 0.1rem solid $gray-300;
        box-sizing: border-box;
      }

      .flex-form {
        .btn {
          width: 14.6rem;
          padding: 3.1rem 0;
          font-size: 2.7rem;
        }
      }

      label {
        input[type='checkbox'] {
          + span {
            display: block;
            width: 5rem;
            height: 5rem;
            margin-right: 2rem;
            background-size: auto 1.6rem;
          }
        }

        input[type='checkbox'] {
          & + .chk {
            width: 5rem;
            height: 5rem;
            margin-right: 2.1rem;

            &:before {
              width: 2.1rem;
              height: 1.6rem;
            }
          }
        }
      }

      dt {
        display: block;
        width: 100%;
        padding: 0 4.2rem;
        font-size: 2.5rem;
      }

      dd {
        width: 100%;
        padding: 2.5rem 4.2rem 0;
        font-size: 2.9rem;
        box-sizing: border-box;
      }

      + dl {
        margin-top: 7rem;
      }

      &.line {
        padding-top: 6rem;
        border-top: 1.3rem solid $gray-300;
      }
    }

    .phone-box {
      input[type='text'] {
        width: auto;
        padding: 3.1rem 2.7rem;
        border-bottom: none;

        &.country-input {
          padding: 3.1rem 2.7rem;
        }
      }

      .get-country {
        background-size: 2.2rem 2.2rem;
      }
    }

    .email-box {
      input[type='text'] {
        padding: 3.3rem 2.7rem 2.9rem;
      }

      .agree-flex-form {
        margin-top: 6.7rem;
        p {
          font-size: 2.5rem;
        }

        .checkbox-flex-form {
          display: inline-flex;
          font-size: 1.4rem;
          margin-right: 6.8rem;
          & + .checkbox-flex-form {
            margin-right: 0;
          }
        }
      }

      .email-input {
        padding: 3.1rem 2.7rem;
      }

      .email-select {
        padding: 3.1rem 2.7rem;
        background-size: 2.2rem 2.2rem;
      }

      .agree {
        margin-top: 4.5rem;
      }
    }

    .gender-box {
      label {
        width: 50%;
        padding: 3.1rem 0;
        font-size: 2.9rem;
      }
    }

    .birthday-box {
      width: 100%;

      select {
        width: 27.5%;
        padding: 3.2rem 0 3.2rem 2.7rem;
        background-size: 2.2rem 2.2rem;
      }

      .year-select {
        width: 45%;
      }
    }

    .address-box {
      input[type='text'] {
        margin-top: 6.1rem;
        border-width: 0;
        border-bottom-width: 0.1rem;

        &.zone-code {
          flex: 1 0 auto;
          margin-right: 4.2rem;
        }
      }
    }

    .btn-password {
      width: 100%;
      padding: 3.1rem 0;
      font-size: 2.9rem;
    }

    .notice {
      margin-top: 3rem;
      font-size: 2.5rem;
    }
  }

  .modal-form-col {
    &.password {
      input[type='password'] {
        padding: 2.6rem 2.6rem 2.2rem;
        font-size: 2.9rem;
      }

      dl {
        min-height: 19rem;

        .valid {
          font-size: 2.3rem;
        }
      }

      .btn-flex-form {
        margin-top: 21rem;
      }
    }
  }
}
