.reviewPrecaution {
  padding: 24px 16px;
  background-color: #28284b;
  dl {
    dt {
      margin-bottom: 12px;
      font-size: 14px;
      color: #fff;
    }

    dd {
      display: flex;
      flex-direction: row;
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      color: #717188;

      span {
        flex: 1;
      }
      &::before {
        display: block;
        width: 14px;
        content: '-';
      }
    }
  }
}
