.innerCol {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  background-color: #fff;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }
}

.brandTabsTemp {
  display: inline-block;
  margin: 8rem 0 5.4rem;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: baseline;
    border: 1px solid #e7e7f0;

    & > li {
      width: inherit;
      height: inherit;
      box-sizing: border-box;

      & + li {
        border-left: 1px solid #e7e7f0;
      }

      & > button {
        display: block;
        position: relative;
        width: 23.7rem;
        padding: 1.5rem 0 1.3rem;
        font-size: 1.4rem;

        &.active {
          background-color: #ffb900;
          font-weight: 700;

          & + section {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
    width: 100%;
    margin: 0;

    & > ul {
      display: table;
      table-layout: fixed;
      width: 100%;
      border: 0;
      border-bottom: 1px solid #e7e7f0;

      & > li {
        display: table-cell;

        button {
          width: inherit;
          padding: 3rem 0;
          font-size: 2.7rem;
        }
      }
    }
  }
}

.serviceTabsTemp {
  display: none;
  position: absolute;
  left: 3rem;
  width: 100%;
  margin-bottom: 3.1rem;
  padding-top: 5.4rem;
  font-size: 2rem;
  text-align: left;

  ul {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    li {
      flex: 0 0 auto;
      & + li {
        margin-left: 4.8rem;
      }

      button {
        position: relative;
        display: block;
        color: #a0a0b6;
        font-weight: 700;
        padding: 0;
        font-size: 2rem;
        padding-bottom: 1rem;

        &.active {
          color: #000;

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 0;
            width: 100%;
            border-bottom: 0.3rem solid #ffb900;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    font-size: 2.9rem;

    ul {
      li {
        margin: 0 0 0 4rem;

        &:first-child {
          margin: 0 0 0 4rem;
        }

        .yafit_rider_club_service {
          margin-right: 6rem;
        }

        button {
          display: inline-block;
          padding: 3.7rem 0 3.5rem;
          font-size: 2.9rem;

          &.active:before {
            border-width: 0.4rem;
          }
        }
      }
    }
  }
}

.termsFormTemp {
  padding-top: 5.5rem;
  text-align: left;

  @media screen and (max-width: 1024px) {
    padding-top: 10.4rem;
  }
}

.formBoxTemp {
  margin-bottom: 2.7rem;
  border-top: 0.3rem solid #000;
  border-bottom: 1px solid #b7b7c8;
  font-family: 'NotoSansCJKkr';

  h3 {
    padding: 2.1rem 3rem 2rem;
    font-size: 1.8rem;
    border-bottom: 1px solid #e7e7f0;
  }

  & > div {
    width: 100%;
    margin: 0 auto;
    padding: 3rem 7rem 3rem 3rem;
    overflow-y: auto;
    box-sizing: border-box;
    word-break: keep-all;

    & > div {
      max-width: 109.4rem;
      width: 100%;
      height: 100%;
      font-size: 1.6rem;
      line-height: 1.6;
    }
  }

  @media screen and (max-width: 1024px) {
    border-top: 1px solid #dfdfea;
    border-bottom: 0;

    h3 {
      display: none;
    }

    & > div {
      padding: 6rem 4.2rem 0;
      div {
        font-size: 3rem;
      }
    }
  }
}

.termsHistoryTemp {
  margin-bottom: 6.7rem;
  text-align: left;

  select {
    display: inline-block;
    width: 48rem;
    padding: 0.4rem 1.6rem 0.5rem;
    color: #000;
    background: #fff
      url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_check_36x21.png) 96% 50%/1.2rem
      auto no-repeat;
    font-weight: 400;
    font-size: 1.4rem;
    border: 1px solid #dfdfea;
  }

  @media screen and (max-width: 1024px) {
    margin: 7.8rem 0 6rem;
    padding: 0 4.2rem 0 4.3rem;
    box-sizing: border-box;

    select {
      width: 100%;
      padding: 2.6rem 2.7rem 2.9rem;
      background-size: 2rem;
      font-size: 2.9rem;
    }
  }
}
