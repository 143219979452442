.recommendSurvey {
  background-color: #fff;
  padding: 100px 48px 120px;
  .analytic {
    .level {
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.5px;
    }
    .lv1 {
      color: #ff4635;
    }
    .lv2 {
      color: #ff7700;
    }
    .lv3 {
      color: #06c000;
    }
    .lv4 {
      color: #00bab7;
    }
    .lv5 {
      color: #107dff;
    }
    .lv6 {
      color: #9c46ff;
    }

    h4 {
      font-size: 44px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -0.5px;
      text-align: center;
      margin: 24px 0 36px;
    }
    p {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      text-align: left;
      color: #28284b;
      span {
        display: block;
        margin-top: -26px;
      }
    }
  }
}
@media all and (max-width: 750px) {
  .recommendSurvey {
    padding: 13.3333vw 6.4vw 16vw;
    .analytic {
      .level {
        font-size: 5.3333vw;
        line-height: 6.4vw;
        letter-spacing: -0.0667vw;
      }
      h4 {
        font-size: 5.8667vw;

        line-height: 7.4667vw;
        letter-spacing: -0.0667vw;

        margin: 3.2vw 0 4.8vw;
      }
      p {
        font-size: 3.7333vw;
        line-height: 5.3333vw;
        span {
          margin-top: -3.4667vw;
        }
      }
    }
  }
}
