.couponDownloadBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
  padding: 0.6rem 1rem 0.6rem 1.6rem;
  background-color: #f8f8fc;
  border-radius: 0.3rem;

  p {
    font-weight: 700;
    font-size: 1.2rem;
    color: #28284b;
  }

  @media (max-width: 1024px) {
    margin-top: 2.4rem;
    padding: 2rem 2rem 2rem 3rem;
    border-radius: 0.5rem;

    p {
      font-size: 2.4rem;
    }
  }
}

.btnDownload {
  position: relative;
  height: 3rem;
  padding: 0.2rem 4rem 0 1.5rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #269cff;
  background: #fff
    url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_button_download.png)
    no-repeat calc(100% - 1rem) 50% / 1.7rem auto;
  border: 0.1rem solid #269cff;
  border-radius: 0.3rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    height: 5.2rem;
    padding: 0.2rem 5.4rem 0 2rem;
    font-size: 2rem;
    border: 0.2rem solid #269cff;
    border-radius: 0.6rem;
    background: #fff
      url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_button_download.png)
      no-repeat calc(100% - 1rem) 50% / 2.5rem auto;
  }
}

.priceInfoTitle {
  p {
    strong {
      display: inline-block;
      padding: 0.5rem 0;
      font-size: 2rem;
      line-height: 1.4;
    }

    &.score {
      margin-top: 0.5rem;
    }
  }
}

.salePrice {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0 !important;
  border-top: 0 !important;
  padding-top: 1rem !important;

  dt {
    flex: 1 auto;

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 6.8rem;
      height: 2.4rem;
      padding-bottom: 0 !important;
      background-color: #ff5647;
      font-weight: 500 !important;
      font-size: 1.3rem !important;
      color: #fff !important;
      line-height: 1;
      border-radius: 0.3rem;
      box-sizing: border-box;

      @media (max-width: 1024px) {
        width: 11.2rem;
        height: 4rem;
        font-size: 2.2rem !important;
        border-radius: 0.5rem;
      }
    }
  }

  dd {
    strong {
      font-weight: 400;
      font-size: 1.4rem !important;
      color: #717188;

      &:before,
      &:after {
        display: none !important;
      }

      @media (max-width: 1024px) {
        font-size: 2.2rem !important;
      }
    }
  }
}

.periodInfo {
  display: flex;
  align-items: center;
  padding-top: 2rem;

  dt {
    flex: 1 auto;
    display: inline-block;
    padding: 0.5rem 0 0.3rem 2.5rem;
    font-weight: 400;
    font-size: 1.4rem !important;
    box-sizing: border-box;

    &.period {
      background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pack_item03.png)
        no-repeat 0 50% / 2rem auto;
    }
  }

  dd {
    padding: 0.5rem 0.5rem 0.3rem 0;
    box-sizing: border-box;
    font-size: 1.4rem;
  }

  @media (max-width: 1024px) {
    dt {
      font-size: 2.5rem !important;
      padding: 0.5rem 0 0.3rem 3.5rem;
      &.period {
        background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_pack_item03.png)
          no-repeat 0 50% / 3rem auto;
      }
    }
    dd {
      font-size: 2.5rem !important;
    }
  }
}

.priceStrong {
  font-weight: 700 !important;
  font-size: 2.8rem !important;
  color: #000;

  &:after {
    font-weight: 700;
    font-size: 2.8rem;
    content: '원';
  }

  @media (max-width: 1024px) {
    font-size: 4.4rem !important;
  }
}

.btnInstallmentsInfo {
  width: 100%;
  height: 4.2rem;
  margin-top: 3rem;
  border: 0.1rem solid #e7e7f0;
  border-radius: 0.3rem;

  span {
    display: inline-block;
    padding: 0.2rem 2rem 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1;
    color: #717188;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_info_28x28.png)
      no-repeat 99% 50% / 1.4rem auto;
  }

  @media (max-width: 1024px) {
    height: 9rem;
    border-radius: 0.5rem;
    border: 0.2rem solid #e7e7f0;

    span {
      font-size: 2.2rem;
      padding: 0.2rem 3.4rem 0;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_info_28x28.png)
        no-repeat 99% 50% / 2.2rem auto;
    }
  }
}

.defaultPriceDl {
  border-top: 0 !important;
  margin-top: 0 !important;

  dd {
    span {
      display: inline-block;
      padding-right: 0.5rem;
      color: #8d8da0;
      font-weight: 700;
      font-size: 1.8rem;

      @media (max-width: 1024px) {
        font-size: 2.9rem;
      }
    }

    strong {
      color: #8d8da0;
      font-size: 1.8rem;
      text-decoration: line-through;
    }
  }

  &.lineTop {
    @media (max-width: 1024px) {
      border-top: 0.2rem solid #e7e7f0;
    }
  }
}

.bottomPriceInfo {
  display: flex;
  width: 100%;
  padding: 2rem 0;

  &.lineTop {
    border-top: 0.2rem solid #e7e7f0;
  }

  &.itemMiddle {
    padding-top: 1rem;

    dd {
      span {
        display: inline-block;
        padding-right: 0.5rem;
        font-weight: 700;
        font-size: 2.9rem !important;
      }
    }
  }

  dt {
    flex: 1 auto;
    font-weight: 400;
    font-size: 2.5rem;
  }

  dd {
    color: #8d8da0;

    strong {
      text-decoration: line-through;
    }

    &.total {
      strong {
        font-size: 4.4rem !important;
        text-decoration: none !important;

        &:after {
          font-size: 4.4rem !important;
        }
      }
    }
  }
}
