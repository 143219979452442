.storeTitle {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
  strong {
    flex: 1 auto;
    font-size: 2.5rem;
    color: #000;
    @media screen and (max-width: 1024px) {
      font-size: 2.8rem;
    }
  }
}

.thumbBox {
  position: relative;
  border-radius: 0.5rem;
  background: #f7f7fc;
  overflow: hidden;
}

.picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    min-height: 22rem;
  }
}

.saleStatusBox {
  &::after {
    content: '';
    display: block;
    padding-bottom: 67%;
  }

  &.before {
    & > div > span {
      color: #28284b;
      background-color: #fff;
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;

    & > span {
      display: inline-block;
      width: 11rem;
      padding: 0.8rem 0;
      font-weight: 700;
      font-size: 1.8rem;
      color: #fff;
      background-color: #ff5647;
      border-radius: 2rem;
      text-align: center;
    }
  }

  @media screen and (max-width: 1024px) {
    &::after {
      padding-bottom: 68%;
    }

    & > div {
      height: auto;

      & > span {
        width: 13.8rem;
        padding: 0.8rem 0;
        font-size: 2.2rem;
      }
    }
  }
}

.categoryBox {
  margin-bottom: 1rem;
  p {
    height: 4.4rem;
    line-height: 1.4;
    font-weight: 700;
  }

  div {
    color: #a0a0b6;
    flex: 0 0 30%;
    display: flex;
    margin: 1.1rem 0 0.9rem 0;
    flex-wrap: wrap;

    span {
      display: inline-block;
      font-size: 1.2rem;
      color: #a0a0b6;
    }
  }
  @media screen and (max-width: 1024px) {
    p {
      height: 6rem;
      font-size: 2.2rem;
    }
    div {
      display: flex;
      flex: 1;
      display: block;
      margin: 1.6rem 0 0.5rem 0;
      position: relative;
      span {
        padding: 0.5rem 0;
        font-size: 2.2rem;
      }
    }
  }
}
