.ratingBox {
  .ratingTitle {
    margin-bottom: 12px;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.05em;
  }

  .starsBox {
    display: flex;
    justify-content: center;
    gap: 8px;

    span {
      cursor: pointer;
    }
  }
}
