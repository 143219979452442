.textBox {
  margin-top: 22px;

  .innerText {
    position: relative;
  }

  textarea {
    resize: none;
    width: 100%;
    min-height: 122px;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #e7e7f0;
    box-sizing: border-box;
  }

  .textLength {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: 12px;
    color: #b7b7c8;

    span {
      color: #000;
    }
  }

  .textCaution {
    margin-top: 8px;
    font-size: 12px;
    color: #ff5647;
  }
}
