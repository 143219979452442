$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';
.customerUtils {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 6px 0;

  i {
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    text-indent: 1000px;
    white-space: nowrap;
    background-image: url('#{$img-path}ic_question.png');
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
  }

  .utilButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    button {
      height: 36px;
      padding: 0 16px;
      border: 1px solid #cfcfda;
      border-radius: 40px;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 750px) {
    gap: 1.6vw;
    padding: 2.133vw 4.267vw;

    i {
      width: 4.8vw;
      height: 4.8vw;
      background-size: cover;
    }
    .utilButtons {
      gap: 1.067vw;

      button {
        height: 8.8vw;
        padding: 0 2.933vw;
        border-radius: 5.333vw;

        font-size: 3.2vw;
        line-height: 4vw;
      }
    }
  }
}
