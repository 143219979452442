.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;

  & + table {
    margin-top: 2rem;
  }

  @media all and (max-width: 1024px) {
    display: block;
    margin-top: 0;
  }
}

.searchTxtList {
  @media all and (max-width: 1024px) {
    padding: 3rem 3rem 3rem 4.2rem;
    background-color: #f7f7fc;
  }
}

.searchTxtItem {
  position: relative;
  padding-left: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #717188;
  line-height: 1.55;

  &::before {
    content: '-';
    position: absolute;
    top: 0;
    left: 0;
    color: #717188;
  }

  @media all and (max-width: 1024px) {
    font-size: 2.5rem;
  }
}

.searchSelectBox {
  select {
    display: inline-block;
    width: 13.8rem;
    padding: 0.7rem 1.5rem 0.6rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: #000;
    border: 0.1rem solid #dfdfea;
    background-color: #fff;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/my/myclass/ico_check_36x21.png)
      91% 50% / 1.2rem auto no-repeat;

    & + select {
      margin-left: 1rem;
    }
  }

  @media all and (max-width: 1024px) {
    display: flex;
    align-items: center;
    padding: 3rem 4.2rem;

    select {
      flex: 1 1 50%;
      width: auto;
      padding: 3.6rem 2.7rem 3.5rem;
      font-size: 2.9rem;
      background-size: 2rem auto;
      background-position: 95% 50%;

      & + select {
        margin-left: 1.8rem;
      }
    }
  }

  @media all and (max-width: 750px) {
    select {
      padding-block: 0;
      height: 12.8vw;
      background-position: 91% 50%;
      & + select {
        margin-left: 2.4vw;
      }
    }
  }
}

.tableBox {
  width: 100%;
  margin-top: 2rem;
  table-layout: fixed;

  &[data-empty='true'] {
    border-bottom: none;
  }

  .tal {
    text-align: left;
  }

  .tar {
    text-align: right;
  }

  thead {
    border-top: 0.3rem solid #000;

    th {
      padding: 1.6rem;
      font-weight: 400;
      font-size: 1.4rem;
    }
  }

  tbody {
    tr {
      border-top: 0.1rem solid #e7e7f0;

      td {
        position: relative;
        padding: 2.6rem 2.5rem;
        text-align: center;
        font-size: 1.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .icoNew {
          &::before {
            content: 'N';
            position: absolute;
            top: 50%;
            right: 0;
            width: 2rem;
            height: 2rem;
            padding: 0.4rem 0;
            margin-top: -1rem;
            font-weight: 700;
            font-size: 1rem;
            color: #fff;
            background-color: #ff5647;
            border-radius: 50%;
            text-align: center;
            box-sizing: border-box;
          }
        }

        a {
          display: block;

          &:active {
            text-decoration: underline;
          }
        }

        p {
          font-weight: 400;
          color: #717188;

          &.check {
            font-weight: 700;
            color: #a0a0b6;

            &.completed {
              color: #17c624;
            }
          }
        }

        strong {
          display: block;
          position: relative;
          padding: 0.5rem;
          font-weight: 700;
          color: #000;

          &.icoNew {
            display: inline-block;
            max-width: calc(100% - 1rem);
            padding-right: 2.5rem;
          }
        }
      }
    }
  }

  :global .no-content {
    padding: 14rem 0 20rem;
  }
}

.qnaListSection {
  padding: 8.2rem 0 12.6rem;

  @media all and (max-width: 1024px) {
    padding: 0;
  }
}
