.form {
  max-width: 500px;
  position: relative;
  padding: 63px 40px 60px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 24px;
  box-sizing: border-box;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 40px 0;

  > li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    cursor: pointer;
    font-size: 17px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-size: 0.8em;
      color: #0166ff;
    }
  }

  input {
    &[type='text'],
    &[type='tel'] {
      width: 100%;
      padding: 20px 20px 15px 12px;
      font-size: 18px;
      box-sizing: border-box;
      border-bottom: 1px solid #e7e7f0;
    }

    &[type='text']::placeholder,
    &[type='tel']::placeholder {
      color: #b7b7c8;
    }
  }

  select {
    width: 100%;
    padding: 16px 20px;
    font-size: 18px;
    border: none;
    background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_63x63_b.png)
      no-repeat 95% 50% / 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }
}

.selectContainer {
  border: 1px solid #efeff7;
  margin-top: 12px;
  width: 100%;
}

.counselTitle {
  padding: 18px 20px;
  font-size: 18px;
  background-color: #f7f7fc;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span + span {
    font-size: 17px;
    color: #969dac;
  }
}

.formAgree {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 45px;

  label {
    display: block;
    font-size: 18px;
    letter-spacing: -1px;
    text-align: left;
    cursor: pointer;

    input[type='checkbox'] {
      width: 0;
      padding: 0;
      margin: 0;
      border: 0;

      + em {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        vertical-align: middle;
        cursor: pointer;
        background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png)
          no-repeat 42% 50% / 14px auto;
        border-radius: 50%;
        border: 1px solid #cfcfd8;
      }

      &:checked + em {
        background-color: #ffb800;
        border-color: #ffb800;
        background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/jan/ico_check.svg);
      }
    }

    button {
      color: #0166ff;
      margin-left: 8px;
    }
  }
}

.submitButton {
  position: relative;
  width: 341px;
  height: 73px;
  color: #fff;
  margin-inline: auto;
  font-weight: 600;
  font-size: 26px;
  background: #0c1733;
  border-radius: 10px;
  display: block;

  &:after {
    border: solid #fff;
    border-width: 2.5px 2.5px 0 0;
    content: '';
    display: inline-block;
    height: 10px;
    margin: -5px 0 0 5px;
    transform: rotate(45deg);
    vertical-align: middle;
    width: 10px;
  }
}

@media all and (max-width: 500px) {
  .form {
    padding: 12.6vw 8vw 12vw;
    border-radius: 4.8vw;
  }

  .fields {
    gap: 8vw 0;

    label {
      font-size: 3.4vw;
      flex-direction: column;
      align-items: flex-start;
      gap: 1vw;
    }

    input {
      &[type='text'],
      &[type='tel'] {
        padding: 4vw 4vw 3vw 2.4vw;
        font-size: 3.6vw;
        box-sizing: border-box;
      }
    }

    select {
      padding: 3.2vw 4vw;
      font-size: 3.6vw;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_63x63_b.png)
        no-repeat 95% 50% / 2.6vw;
    }
  }

  .counselContainer {
    margin-top: 2.4vw;
  }

  .counselTitle {
    padding: 3.6vw 4vw;
    font-size: 3.6vw;

    span + span {
      font-size: 3.4vw;
    }
  }

  .formAgree {
    margin-top: 4.8vw;
    flex-direction: column;
    gap: 3.2vw;
    margin-bottom: 9vw;

    label {
      font-size: 3.6vw;
      letter-spacing: -0.2vw;

      input[type='checkbox'] {
        + em {
          width: 4.8vw !important;
          height: 4.8vw !important;
          margin-right: 1vw;
          background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_check_38x30w.png)
            no-repeat 42% 50% / 2.667vw auto;
        }
      }

      button {
        margin-left: 1vw;
      }
    }
  }

  .submitButton {
    width: 68.2vw;
    height: 14.6vw;
    font-size: 5.2vw;
    border-radius: 2vw;

    &:after {
      height: 2vw;
      margin: -1vw 0 0 1vw;
      width: 2vw;
    }
  }
}
