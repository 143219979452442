@import './variable.scss';

.modal-wrap {
  &.find {
    .modal-header {
      background-color: $white;
    }

    .modal-center-box {
      height: 59rem;
    }

    .type-check-box {
      padding: 4.8rem 3.2rem 2.2rem;

      label {
        font-size: 1.6rem;
        cursor: pointer;

        input[type='radio'] {
          & + .chk {
            width: 3.2rem;
            height: 3.2rem;
            margin-right: 1.4rem;
          }
        }

        + label {
          margin-left: 4.7rem;
        }
      }
    }

    .form-box {
      dl {
        .email-box {
          .valid-text {
            position: static;
            margin-top: 1rem;
            text-align: right;
          }

          .btn-certified-code {
            margin-top: 4.8rem;
          }
        }

        .phone-box {
          .certified-code-form {
            padding: 0;
          }
        }

        + .certified-code-form {
          width: 87%;
          margin: 3.5rem auto 0;

          .timer {
            top: 63%;
          }
        }
      }
    }

    .complete-box {
      &.several {
        margin-top: 5.5rem;
        margin-bottom: 2rem;

        dl {
          dt {
            margin-bottom: 3.3rem;
          }

          dd {
            p {
              margin-top: 0;
            }

            strong {
              font-size: 1.9rem;
            }

            span {
              font-size: 1.6rem;
            }
          }

          ul {
            height: 34rem;
            overflow-y: scroll;

            li {
              padding: 3.1rem 3.2rem;
              border-bottom: 1px solid $gray-400;
              text-align: left;

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    .btn-flex-form {
      margin-top: 4.8rem;

      &.btn-group-bottom {
        margin-top: 3rem;
      }
    }
  }

  .password {
    background-color: $white;
    .title {
      p {
        font-size: 2.9rem;
        line-height: 1.5;

        + p {
          margin-top: 3rem;
          font-size: 1.7rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal-wrap {
    &.find {
      .modal-center-box {
        height: 70vh;
      }

      .type-check-box {
        padding: 7.8rem 3.2rem 5.2rem;

        label {
          font-size: 2.5rem;

          input[type='radio'] {
            & + .chk {
              width: 5rem;
              height: 5rem;
              margin-right: 2.1rem;

              &::before {
                width: 1.7rem;
                height: 1.7rem;
              }
            }
          }

          + label {
            margin-left: 4.7rem;
          }
        }
      }

      .certified-code-form {
        padding: 0 4.2rem;
      }

      .btn-flex-form {
        margin-top: 0;

        .btn-edit-submit {
          margin-top: 7rem;
        }
      }

      .form-box {
        dl {
          + .certified-code-form {
            width: 100%;
            margin-top: 5.3rem;
            padding: 2.5rem 4.2rem 0;
          }

          .email-box {
            .btn-certified-code {
              margin-top: 6.7rem;
            }
          }
        }
      }

      .complete-box {
        &.several {
          align-items: flex-start;
          margin-top: 8.7rem;

          dl {
            dt {
              margin-bottom: 3.6rem;
            }

            dd {
              strong {
                font-size: 2.9rem;
              }

              span {
                font-size: 2.5rem;
              }
            }

            ul {
              height: 53rem;

              li {
                padding: 4.9rem 4.2rem;
              }
            }
          }
        }
      }
    }

    .password {
      .title {
        p {
          font-size: 4.6rem;

          + p {
            margin-top: 3rem;
            font-size: 2.7rem;
          }
        }
      }
    }
  }
}
