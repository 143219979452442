.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 645px;
  background: #fff;
  border-radius: 40px 40px 0px 0px;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 142px 42px 0;
  box-sizing: border-box;
}
.present {
  position: absolute;
  top: -120px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 200px;
}
.title {
  color: #161c22;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px;
}
.description {
  margin-top: 16px;
  color: #717b8d;
  font-size: 28px;
  line-height: 34px;
}
.button {
  width: 100%;
  height: 112px;
  border-radius: 18px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #8d8da0;
  &:first-of-type {
    background: #000;
    color: #fff;
  }
}
.name {
  margin-top: 36px;
  color: #161c22;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 54px;
  span {
    color: #717b8d;
  }
}

@media screen and (max-width: 750px) {
  .modal {
    height: 86vw;
    border-radius: 5.333vw 5.333vw 0vw 0vw;
    box-shadow: 0vw 0.533vw 3.6vw 0vw rgba(0, 0, 0, 0.25);
    padding: 18.933vw 5.6vw 0;
  }
  .present {
    top: -16vw;
    width: 26.667vw;
  }
  .title {
    font-size: 5.333vw;
    line-height: 6.4vw;
    letter-spacing: -0.067vw;
  }
  .description {
    margin-top: 2.133vw;
    color: #717b8d;
    font-size: 3.733vw;
    line-height: 4.533vw;
  }
  .button {
    height: 14.933vw;
    border-radius: 2.4vw;
    font-size: 4.267vw;
    line-height: 5.333vw;
    letter-spacing: -0.067vw;
  }
  .name {
    margin-top: 4.8vw;
    color: #161c22;
    font-size: 3.2vw;
    line-height: 4vw;
    margin-bottom: 7.2vw;
    span {
      color: #717b8d;
    }
  }
}
