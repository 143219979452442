.btn {
  &.btn-more {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 2.1rem 0;
    font-weight: 700;
    font-size: 1.5rem;
    background-color: #f7f7fc;
    border: none;
    border-radius: 0.5rem;

    span {
      display: inline-block;
      position: relative;
      padding-right: 1.8rem;
      font-weight: 700;
      font-size: 1.5rem;
      color: #000;

      &::before {
        content: '';
        position: absolute;
        top: 0.2rem;
        right: 0;
        width: 0.7rem;
        height: 0.7rem;
        border-right: 0.2rem solid #000;
        border-bottom: 0.2rem solid #000;
        transform: rotate(45deg);
      }
    }
  }

  &.plus {
    span {
      &:before {
        content: '';
        top: -0.45rem;
        width: 1.24rem;
        height: 1.24rem;
        border-right: 0;
        transform: rotate(0deg);
      }
      &:after {
        content: '';
        position: absolute;
        top: 0.38rem;
        right: 0.48rem;
        width: 1.15rem;
        height: 1.15rem;
        border-right: 0.2rem solid #000;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .btn {
    &.btn-more {
      display: block;
      width: 100%;
      padding: 2.4rem 0;
      font-size: 2.6rem;

      span {
        padding-right: 2.5rem;
        font-size: 2.6rem;

        &::before {
          top: 0.5rem;
          width: 1rem;
          height: 1rem;
          border-right: 0.3rem solid #000;
          border-bottom: 0.3rem solid #000;
        }
      }
    }

    &.plus {
      span {
        &:before {
          content: '';
          top: -0.45rem;
          width: 2rem;
          height: 2rem;
          border-right: 0;
          transform: rotate(0deg);
        }
        &:after {
          content: '';
          position: absolute;
          top: 0.82rem;
          right: 0.85rem;
          width: 1.7rem;
          height: 2rem;
          border-right: 0.3rem solid #000;
        }
      }
    }
  }
}
