.faqSearchResult {
  padding-bottom: 76px;
}

@media screen and (max-width: 1024px) {
  .faqSearchResult {
    padding-top: 108px;
  }
}
@media screen and (max-width: 750px) {
  .faqSearchResult {
    padding-top: 14.4vw;
  }
}
