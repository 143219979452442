.csHelp {
  margin-top: 65px;

  .counselInner {
    display: flex;
    flex-wrap: wrap;
  }

  .counselTitle {
    margin-bottom: 0;
  }

  .counselBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;

    p {
      color: #8d8da0;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.333;
    }

    button {
      position: relative;
      color: #28284b;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.375;
      padding-inline: 22px 14px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/ico_inquiry.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 20px;
        height: 20px;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/ico_inquiry-arrow.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 12px;
        height: 12px;
      }
    }
  }

  .helpAi {
    width: 100%;
    border-radius: 14px;
    background: radial-gradient(229.15% 145.73% at 5.08% 0%, #d5edff 0%, #dcdcff 100%);
    margin-top: 28px;

    .helpAiInner {
      padding: 40px;
      background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/bg_ai.png');
      background-repeat: no-repeat;
      background-size: 272px;
      background-position: 97% 100%;
    }

    h5 {
      color: #717188;
      font-size: 18px;
      line-height: 1.33;
      margin-bottom: 10px;
      font-weight: 400;
    }
    h3 {
      color: #28284b;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.25;
      letter-spacing: -0.5px;

      strong {
        color: #5e74f1;
      }
    }

    .btns {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 24px;
    }

    .btnAiCounsel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 224px;
      height: 64px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 4px;
      background: linear-gradient(94deg, #6060fa 12.75%, #5c7ceb 98.22%);
    }
  }
}
@media screen and (max-width: 1024px) {
  .csHelp {
    margin-top: 120px;
    .counselInner {
      padding: 0;
    }

    .counselTitle {
      width: 100%;
      text-align: center;
    }

    .counselBtn {
      order: 3;
      margin-inline: auto;
      margin-top: 55px;

      p {
        font-size: 24px;
        line-height: 1.25;
      }

      button {
        font-size: 25px;
        line-height: 1.375;
        padding-inline: 42px 18px;
        &::before {
          width: 40px;
          height: 40px;
        }
        &::after {
          width: 16px;
          height: 16px;
        }
      }
    }

    .helpAi {
      border-radius: 0;
      box-sizing: border-box;
      border-radius: 16px;
      background: radial-gradient(229.15% 145.73% at 5.08% 0%, #d5edff 0%, #dcdcff 100%);

      .helpAiInner {
        padding: 68px 32px 66px;
        text-align: center;
        background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/bg_ai_mo.png');
        background-position: 100% 100%;
        background-size: 50%;
      }

      h5 {
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 8px;
        padding-top: 146px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 52%;
          transform: translateX(-50%);
          width: 126px;
          height: 126px;
          background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/ico_ai-avartar.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }

      h3 {
        font-size: 28px;
        line-height: 1.25;
      }

      .btns {
        margin-top: 50px;
      }

      .btnAiCounsel {
        width: 50%;
        height: 80px;
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .csHelp {
    margin-top: 16vw;
    .counselInner {
      padding-inline: 5.333vw;
    }

    .counselBtn {
      margin-top: 7.333vw;
      gap: 1.333vw;

      p {
        font-size: 3.2vw;
      }

      button {
        font-size: 3.333vw;
        padding-inline: 5.6vw 2.4vw;
        &::before {
          width: 5.333vw;
          height: 5.333vw;
        }
        &::after {
          width: 2.133vw;
          height: 2.133vw;
        }
      }
    }

    .helpAi {
      margin-top: 3.467vw;
      border-radius: 2.133vw;

      .helpAiInner {
        padding: 9.067vw 4.267vw 8.8vw;
      }

      h5 {
        font-size: 3.2vw;
        margin-bottom: 1.067vw;
        padding-top: 19.467vw;
        &::before {
          width: 16.8vw;
          height: 16.8vw;
        }
      }

      h3 {
        font-size: 3.733vw;
      }

      .btns {
        margin-top: 6.667vw;
        gap: 1.067vw;
      }

      .btnAiCounsel {
        height: 10.667vw;
        font-size: 3.2vw;
        line-height: 4vw;
      }
    }
  }
}
