.adminAnswerBox {
  padding: 3rem 2.5rem;

  &.hasType {
    padding: 3.5rem;

    > div {
      padding-left: 3.4rem;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 3rem;
        height: 3rem;
        background: url(https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_reply_90x90.png)
          50% 50% no-repeat;
        background-size: 100% auto;
      }
    }
  }

  > div {
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 0;

    p {
      margin-left: 1.5rem;

      strong {
        display: block;
        font-weight: 700;
        font-size: 1.7rem;
      }

      span {
        display: block;
        margin-top: 0.6rem;
        font-weight: 400;
        font-size: 1.3rem;
        color: #a0a0b6;
      }
    }
  }

  pre {
    margin-top: 1.9rem;
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 1.6rem;

    & + img {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 7rem 4.2rem;

    &.hasType {
      padding: 4rem;

      > div {
        padding-left: 5.6rem;

        &::before {
          width: 5.5rem;
          height: 5.5rem;
        }
      }
    }

    > div {
      p {
        margin-left: 2.2rem;

        strong {
          font-size: 2.6rem;
        }

        span {
          margin-top: 0.8rem;
          font-size: 2rem;
        }
      }
    }

    pre {
      margin-top: 5rem;
      font-size: 2.6rem;

      & + img {
        margin-top: 5rem;
      }
    }
  }
}
