.csFaq {
  margin-top: 65px;
  h3 {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.5px;
  }
  .csTabNav {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #000;
      content: '';
    }

    .csTabBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      box-sizing: border-box;

      border-radius: 10px 10px 0 0;
      border: 1px solid #ececf6;
      background: #f7f7fc;

      color: #a0a0b6;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -1px;

      &.csTabActive {
        background-color: #fff;
        border-color: #000;

        color: #000;
        font-weight: 700;
      }
    }
  }
  .csTabContent {
    margin-top: 38px;
    .btnMore {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .csFaq {
    margin-top: 120px;
    .csInner {
      padding: 0;
    }
    h3 {
      text-align: center;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.5px;
    }
    .csTabNav {
      margin-top: 30px;

      .csTabBtn {
        height: 80px;
        font-size: 28px;
        line-height: 34px;
      }
    }
    .csTabContent {
      margin-top: 37px;
      .wrapBtnMore {
        padding: 0 40px;
      }
      .btnMore {
        margin-top: 56px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .csFaq {
    margin-top: 13.333vw;
    h3 {
      text-align: center;
      font-size: 5.333vw;
      line-height: 6.4vw;
      letter-spacing: -0.067vw;
    }
    .csTabNav {
      margin-top: 4vw;

      .csTabBtn {
        height: 10.667vw;
        font-size: 3.733vw;
        line-height: 4.533vw;
      }
    }
    .csTabContent {
      margin-top: 4.933vw;
      .wrapBtnMore {
        padding: 0 5.333vw;
      }
      .btnMore {
        margin-top: 7.467vw;
      }
    }
  }
}
