.coachMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.8);

  .coachContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 564px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;

    .imgMask {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      // padding: 68px 0 29px 0;
      position: relative;
      width: 100%;
      overflow: hidden;

      picture {
        position: absolute;
        left: 0;
        &:first-child {
          top: 68px;
        }
        &:last-child {
          bottom: 29px;
        }
      }
    }
    &.step2 {
      .imgMask {
        // padding: 0 0 36px;
        picture {
          &:first-child {
            top: 0;
          }
          &:last-child {
            bottom: 36px;
          }
        }
      }
    }

    .closeButtons {
      position: absolute;
      bottom: 70px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;

      .btnCheck {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;

        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #fff;

        &::before {
          display: block;
          width: 32px;
          height: 32px;
          border: 1px solid #cfcfda;
          border-radius: 20px;
          content: '';
        }

        &.checked {
          &::before {
            background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/ico_checked.svg');
            background-size: cover;
            background-repeat: no-repeat;
            border-color: rgba(0, 0, 0, 0);
          }
        }
      }

      svg {
        width: 32px;
        height: 32px;
        path {
          stroke: #fff;
        }
      }
    }
  }

  .btnNextStep {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 72px;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.5px;
    color: #000;
    background-color: #ffb900;
  }

  @media screen and (max-width: 1024px) {
    .coachContent {
      max-width: 750px;
      .imgMask {
        // padding: 105px 0 32px;
        picture {
          &:first-child {
            top: 105px;
          }
          &:last-child {
            bottom: 32px;
          }
        }
      }

      &.step2 {
        .imgMask {
          // padding: 22px 0 42px;
          picture {
            &:first-child {
              top: 22px;
            }
            &:last-child {
              bottom: 42px;
            }
          }
        }
      }

      .closeButtons {
        bottom: 48px;
        padding: 0 40px;

        .btnCheck {
          gap: 14px;

          font-size: 24px;
          line-height: 30px;

          &::before {
            width: 40px;
            height: 40px;
          }
        }

        svg {
          width: 40px;
          height: 40px;
        }
      }
    }

    .btnNextStep {
      height: 96px;
      font-size: 28px;
      line-height: 34px;
    }
  }
  @media screen and (max-width: 750px) {
    .coachContent {
      .imgMask {
        // padding: 14vw 0 4.267vw;
        picture {
          &:first-child {
            top: 14vw;
          }
          &:last-child {
            bottom: 4.267vw;
          }
        }
      }
      &.step2 {
        .imgMask {
          // padding: 2.933vw 0 5.6vw;
          picture {
            &:first-child {
              top: 2.933vw;
            }
            &:last-child {
              bottom: 5.6vw;
            }
          }
        }
      }

      .closeButtons {
        bottom: 6.4vw;
        padding: 0 5.333vw;

        .btnCheck {
          gap: 1.867vw;

          font-size: 3.2vw;
          line-height: 4vw;

          &::before {
            width: 5.333vw;
            height: 5.333vw;
          }
        }

        svg {
          width: 5.333vw;
          height: 5.333vw;
        }
      }
    }
    .btnNextStep {
      height: 12.8vw;
      font-size: 3.733vw;
      line-height: 4.533vw;
    }
  }
}
