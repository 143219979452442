@import '../../exam//styles/variables.scss';

.container {
  display: block;
  text-align: center;
  padding: 80.002px 0;
  position: relative;
  height: 1296px;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 750px;
  margin: 0 auto;
  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url($HOST_CDN + 'bg-start-gradation.png') no-repeat center;
    background-size: cover;
  }
}
.description {
  color: #324053;
  font-size: 27.998px;
  line-height: 33.998px;
  letter-spacing: -2.002px;
}
.title {
  color: #161c22;
  text-align: center;
  font-size: 87.998px;
  font-weight: 700;
  letter-spacing: -3px;
  margin-top: 9.998px;
}
.textWrap {
  position: relative;
  z-index: 2;
}
.bubble {
  position: absolute;
  top: 288px;
  right: 50%;
  transform: translateX(150%);
  width: 188.002px;
  z-index: 2;
}
.video {
  z-index: 1;
  width: 100%;
  margin-top: -135px;
  video {
    width: 108%;
    transform: translateX(-4%);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
}
.bottom {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 8;
  p {
    display: flex;
    margin-top: 32.003px;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    background: linear-gradient(90deg, #464441 0%, #6c421a 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 0 0 30px;
    gap: 12px;
    & + p {
      margin-top: 8.002px;
    }
    img {
      flex: none;
      width: 27.998px;
      height: 27.998px;
    }
  }
}
.startButton {
  border-radius: 18px;
  background: linear-gradient(99deg, #324053 6.56%, #030617 55.87%);
  width: 600px;
  height: 111.997px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  .text {
    width: 267.998px;
    display: inline-block;
  }
  .img {
    width: 39.998px;
  }
}
@media screen and (max-width: 750px) {
  .container {
    display: block;
    text-align: center;
    padding: 10.667vw 0;
    position: relative;
    height: 172.8vw;
    box-sizing: border-box;
    overflow: hidden;
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url($HOST_CDN + 'bg-start-gradation.png') no-repeat center;
      background-size: cover;
    }
  }
  .description {
    color: #324053;
    font-size: 3.733vw;
    line-height: 4.533vw;
    letter-spacing: -0.267vw;
  }
  .title {
    color: #161c22;
    text-align: center;
    font-size: 11.733vw;
    font-weight: 700;
    letter-spacing: -0.4vw;
    margin-top: 1.333vw;
  }
  .textWrap {
    position: relative;
    z-index: 2;
  }
  .bubble {
    position: absolute;
    top: 38.4vw;
    right: 50%;
    transform: translateX(150%);
    width: 25.067vw;
  }
  .video {
    z-index: 1;
    width: 100%;
    margin-top: -18vw;
    overflow: hidden;
    video {
      width: 108%;
      transform: translate(-4%, 0);
    }
  }
  .bottom {
    position: absolute;
    bottom: 8vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: 8;
    p {
      display: flex;
      margin-top: 4.267vw;
      font-size: 3.2vw;
      line-height: 4vw;
      text-align: left;
      background: linear-gradient(90deg, #464441 0%, #6c421a 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0 0 0 4vw;
      gap: 1.6vw;
      & + p {
        margin-top: 1.067vw;
      }
      img {
        flex: none;
        width: 3.733vw;
        height: 3.733vw;
      }
    }
  }
  .startButton {
    border-radius: 2.4vw;
    background: linear-gradient(99deg, #324053 6.56%, #030617 55.87%);
    width: 80vw;
    height: 14.933vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.6vw;
    .text {
      width: 35.733vw;
      display: inline-block;
    }
    .img {
      width: 5.333vw;
    }
  }
}
