$img-path: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/';
.customerTitleBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid #e7e7f0;
  box-sizing: border-box;

  h1 {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding-top: 4px;
  }
  .btnBack {
    display: block;
    width: 40px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-indent: -1000px;
    position: absolute;
    top: 6px;
    left: 12px;
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/btn_back.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .btnInfo {
    position: absolute;
    top: 6px;
    right: 12px;
    width: 40px;
    height: 40px;
    background-image: url('https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/ico_notice_mark.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    white-space: nowrap;
    text-indent: -10000px;
  }

  @media screen and (min-width: 768px) {
    height: 75px;
    h1 {
      font-size: 21px;
      line-height: 26px;
      letter-spacing: -0.5px;
    }
    .btnBack {
      width: 32px;
      height: 32px;
      background-size: 32px;
      top: 22px;
      left: 40px;
    }
    .btnInfo {
      width: 32px;
      height: 32px;
      background-size: 32px;
      top: 22px;
      right: 40px;
    }
  }
}

.customerFooter {
  padding: 20px 20px 30px;
  border-top: 1px solid #dfdfea;

  dl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    dt {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 8px;
      color: #8d8da0;
    }

    dd {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      color: #717188;
    }
  }
}

.buttonNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;

  .arrowR {
    display: block;
    width: 16px;
    height: 16px;
  }

  + button {
    border-top: 1px solid #dfdfea;
  }

  strong {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 60px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #000;
  }

  dl {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 90px;

    dt {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: #000;
    }
    dd {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      color: #8d8da0;
      margin-top: 8px;
    }
  }
}

.faqSearchForm {
  position: relative;
  border-radius: 4px;
  border: 1px solid #000;
  background-color: #fff;

  input {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 0 48px 0 12px;
    border: 0;
    outline: 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000;

    &::placeholder {
      color: #b7b7c8;
    }
  }

  button {
    background-image: url('#{$img-path}ico_search.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100px;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.customerWrap {
  .sectionTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.5px;
    color: #000;
    margin-bottom: 14px;
  }
  .resultTitle {
    h3 {
      margin-bottom: 4px;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      color: #000;
      em {
        font-style: normal;
        color: #ffb900;
      }
    }
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #000;
    }
  }
  .customerInner {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: #e7e7f0;

    > section {
      background-color: #fff;
    }
  }

  .faqCategory {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 8px;
    margin-top: 15px;

    button {
      height: 36px;
      padding: 0 12px;
      background-color: #ececf6;
      border-radius: 40px;

      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      color: #000;

      &.active {
        background-color: #000;
        color: #fff;
      }
    }
  }

  // section
  .sectionIntro {
    padding: 27px 20px 29px;

    .faqBestList {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 20px;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 29px;
        padding-left: 33px;

        background-image: url('#{$img-path}ico_q.svg');
        background-repeat: no-repeat;
        background-size: 29px;
        background-position: 0 0;

        font-size: 14px;
        line-height: 16px;

        cursor: pointer;
      }
    }

    .faqTextLink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;
      margin-top: 26px;
      margin-left: 4px;

      span {
        display: block;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: #269cff;
      }

      svg {
        width: 12px;
        height: 12px;
        path {
          stroke: #269cff;
        }
      }
    }

    .backTextLink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;
      height: 16px;
      margin-top: 20px;

      svg {
        width: 12px;
        height: 12px;
        transform: rotate(180deg);
        margin-top: -2px;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #a0a0b6;
      }
    }
  }

  .customerFaqList {
    dl {
      dt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 58px;
        padding: 0 20px;

        font-size: 14px;
        line-height: 20px;
        color: #000;
        cursor: pointer;

        > div {
          flex: 1;
          padding-right: 12px;
        }

        &::after {
          display: block;
          width: 12px;
          height: 12px;
          background-image: url('#{$img-path}ico_arrow.svg');
          background-size: 12px;
          background-repeat: no-repeat;

          content: '';
        }
      }

      dd {
        display: none;
        padding: 24px 20px;
        background-color: #f7f7fc;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      + dl {
        border-top: 1px solid #ececf6;
      }

      &.open {
        dt {
          color: #3e53a0;
          &::after {
            transform: rotate(180deg);
          }
        }
        dd {
          display: block;
        }
      }
    }
  }
  .notFind {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 150px 0;

    svg {
      width: 47px;
      height: 47px;
    }

    .notFindText {
      text-align: center;
      h5 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: #000;
      }
      p {
        margin-top: 12px;
        font-size: 12px;
        line-height: 16px;
        color: #717188;
      }
    }

    .helpAiButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;

      width: 206px;
      height: 44px;
      border-radius: 4px;
      background: radial-gradient(229.15% 145.73% at 5.08% 0%, #d5edff 0%, #dcdcff 100%);

      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      &::before {
        display: block;

        width: 16px;
        height: 16px;

        background-image: url('#{$img-path}ico_star.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;

        content: '';
      }
    }
  }

  .notFindTips {
    padding: 20px 20px 60px;
    dt {
      color: #717188;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 12px;
    }

    dd {
      display: flex;
      flex-direction: row;
      align-items: center;

      color: #717188;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;

      &::before {
        display: block;
        width: 2px;
        height: 2px;
        border-radius: 2px;
        background-color: #717188;
        margin: 0 8px 0 4px;

        content: '';
      }
    }
  }

  .floatAiButton {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-image: url('#{$img-path}ai_talk_button.png');
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      display: block;
      width: 234px;
      height: 60px;
      position: absolute;
      top: -8px;
      right: 60px;

      background-image: url('#{$img-path}ai_talk_box.png');
      background-size: cover;
      background-repeat: no-repeat;

      content: '';
    }
  }
}
