$img-cdn: 'https://english.yanadoocdn.com/upload/yanadoo/assets/images';
// #{$img-cdn}
.pagination {
  display: block;
  width: 100%;
  padding: 4.1rem 0 8rem;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: middle;
    a,
    button {
      display: block;
      width: 4rem;
      height: 4rem;
      padding: 1rem 0;
      font-weight: 700;
      font-size: 1.7rem;
      color: #b7b7c8;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: auto 1.6rem;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
    }

    &.active {
      a,
      button {
        font-weight: 700;
        color: #000;
        background-color: #ececf6;
      }
    }

    &.prev {
      a,
      button {
        background-image: url('#{$img-cdn}/ico_arrow_24x42_b.png');
        transform: rotate(0deg);
      }

      &.jump {
        a,
        button {
          background-image: url('#{$img-cdn}/ico_arrow_next_double.png') !important;
          background-size: 100% auto;
          transform: rotate(180deg);
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &.next {
      a,
      button {
        background-image: url('#{$img-cdn}/ico_arrow_24x42_b.png');
        transform: rotate(180deg);
      }

      &.jump {
        a,
        button {
          background-image: url('#{$img-cdn}/ico_arrow_next_double.png') !important;
          background-size: 100% auto;
          transform: rotate(0);
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    + li {
      margin-left: 1.9rem;
    }
  }

  &.side-btn-full {
    li {
      a,
      button {
        color: #28284b;
      }

      &.active {
        a,
        button {
          background-color: #28284b;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .pagination {
    display: block;

    li {
      a,
      button {
        width: 6.4rem;
        height: 6.4rem;
        padding: 1.5rem 0;
        font-size: 3rem;
        background-size: auto 2.6rem;
      }

      + li {
        margin-left: 3rem;
      }
    }

    &.side-btn-full {
      li {
        & + li {
          margin-left: 1rem;
        }

        a,
        button {
          padding: 1.4rem 0 1.6rem;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .pagination {
    &.side-btn-full {
      li {
        & + li {
          margin-left: 0;
        }
      }
    }
  }
}
